<script setup lang="ts">
// import FileListManagement from "@/components/shared/FileListManagement.vue";
import { useSessionStore } from "@/store/session";
import api from "@/api";

const sessionStore = useSessionStore();
const gPermissions = sessionStore.gPermissions;

// interface IFile {
// 	id: number;
// 	fileName?: string;
// }

interface IDeleteRequest {
	files: { id: number }[];
	include: string;
}

interface IModalProps {
	clientId?: number;
	tempId?: number;
	files: any[];
}

const props = defineProps<{ modalProps: IModalProps }>();
const emit = defineEmits(["close-modal"]);

const deleteDialogActive = ref(false);
const files = ref<any[]>([]);
const deleteRequest = ref<IDeleteRequest | null>(null);
const afterFileDeleteResponse = ref<any>(null);

const fileToBeDeleted = ref<number | null>(null);
const pendingDeleteFiles = ref<number[]>([]);
const fileLink = ref<string>("");
// const previousModal = ref<Record<string, string>>({});

const canDeleteFiles = computed(() => {
	if (props.modalProps.clientId) {
		return gPermissions.canJournalClientEdit;
	} else if (props.modalProps.tempId) {
		return gPermissions.canJournalTempEdit;
	}
	return false;
});

const canOpenFiles = computed(() => true);

onMounted(() => {
	files.value = props.modalProps.files;
});

function onDeleteFile(fileId: number) {
	confirmDelete(fileId);
}

function confirmDelete(fileId: number) {
	deleteDialogActive.value = true;
	fileToBeDeleted.value = fileId;

	deleteRequest.value = {
		files: [{ id: fileId }],
		include: "files",
	};

	// console.error("DELETE REQUEST: ", deleteRequest.value);
}

async function deleteFile() {
	if (!fileToBeDeleted.value || !deleteRequest.value) return;

	const idx = files.value.findIndex(
		(file) => file.id === fileToBeDeleted.value,
	);
	if (idx === -1) return;

	pendingDeleteFiles.value = [fileToBeDeleted.value];

	for (const file of deleteRequest.value.files) {
		const params = {
			id: file.id,
			for_client_journal: Boolean(props.modalProps.clientId),
		};
		try {
			const response = await api.deleteNoteFile(params);
			afterFileDeleteResponse.value = response.data;
			files.value.splice(idx, 1);

			deleteDialogActive.value = false;
			pendingDeleteFiles.value = [];
		} catch (err) {
			console.warn((err as Error).message);
		}
	}
}

function revokeLink() {
	if (fileLink.value) {
		console.log(">>> Revoking link");
		URL.revokeObjectURL(fileLink.value);
	}
}

function callModalClose() {
	revokeLink();
	emit("close-modal");
}

async function onOpenFile(evt: any, fileObj: any) {
	console.log(evt, fileObj);

	try {
		const res = await api.getFileBlob(evt);
		const file = res.data;
		fileLink.value = URL.createObjectURL(file);
		window.open(fileLink.value);
	} catch (err) {
		console.warn((err as Error).message);
	}
}

async function onDownloadFile(evt: any, fileObj: any) {
	console.log(evt, fileObj);

	try {
		const res = await api.getFileBlob(evt);
		const file = res.data;
		const url = URL.createObjectURL(file);

		const a = document.createElement("a");
		document.body.append(a);
		a.href = url;
		a.download = fileObj.fileName || "download";
		a.click();
		a.remove();
	} catch (err) {
		console.warn((err as Error).message);
	}
}

// function blobToFile(theBlob: Blob, fileName: string) {
// 	return new File([theBlob], fileName, {});
// }
</script>

<template>
	<div class="wrap">
		<file-list-management
			:files="files"
			:fileLink="fileLink"
			:pendingDeleteFiles="pendingDeleteFiles"
			:canDeleteFiles="canDeleteFiles"
			:canOpenFile="canOpenFiles"
			@open-file="onOpenFile"
			@download-file="onDownloadFile"
			@delete-file="onDeleteFile"
		></file-list-management>
		<Transition
			name="fadeLeft"
			mode="out-in"
		>
			<div
				v-if="deleteDialogActive"
				class="delete-section"
			>
				<span class="action-message">
					Are you sure you want to delete this file?
				</span>
				<span class="action-buttons">
					<a
						class="button is-small is-confirm"
						@click.once="deleteFile"
					>
						YES
					</a>
					<a
						class="button is-small is-deny"
						@click="
							deleteDialogActive = false;
							fileToBeDeleted = null;
						"
					>
						NO
					</a>
				</span>
			</div>
		</Transition>
		<footer>
			<button
				class="button is-generic-app-blue is-outlined is-low"
				@click="callModalClose"
			>
				CANCEL
			</button>
		</footer>
	</div>
</template>

<style lang="scss" scoped>
.wrap {
	height: 100%;
	gap: 20px;
	width: 600px;
	padding: 0 40px 40px;
}

.fade-enter-to,
.fade-leave {
	max-height: 500px;
}

.fade-enter,
.fade-leave-to {
	max-height: 0;
}

.caption {
	padding: 1em;
	display: flex;
	justify-content: center;
}

.is-confirm,
.is-deny {
	font-weight: 900;
	font-size: 1.1em;
}

.is-confirm {
	color: $red-cpl;
}

.is-deny {
	color: $blue-cpl;
}

div.delete-section {
	display: flex;
	border-top: 1px solid rgba(#405168, 0.15);
	border-bottom: 1px solid rgba(#405168, 0.15);
	align-items: center;
	justify-content: center;

	.action-message {
		margin-right: 1em;
		color: $blue-grey;
	}

	.action-buttons {
		a.button {
			border: none;
			margin: 0 0.3em;
		}
	}
}

footer {
	padding: 1.5em 0;
	display: flex;
	justify-content: center;
}
</style>
