import EditUser from "@/pages/admin/EditUser.vue";
import type { RouteRecordRaw } from "vue-router";

export const admin_routes: RouteRecordRaw[] = [
	{
		path: "/dashboard",
		name: "admin.dashboard",
		component: () => import("@/pages/DashboardPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-dashboard",
			fail: "/error",
		},
	},
	{
		path: "/submit-shift",
		redirect: "/submit-shift/single",
		name: "admin.shift-submit",
		component: () => import("@/pages/admin/SubmitShift.vue"),
		meta: {
			requiresAuth: true,
			// permission: "view-shifts",
			permission: "create-shift-request",
			fail: "/dashboard",
		},
		children: [
			{
				path: "single",
				name: "admin.shift-submit.single",
				component: () => import("@/pages/admin/book-shift/SubmitShiftForm.vue"),
				meta: {
					requiresAuth: true,
					// permission: "view-shifts",
					permission: "create-shift-request",
					fail: "/dashboard",
				},
			},
			{
				path: "line",
				name: "admin.shift-submit.single.submit-shift-line",
				component: () =>
					import("@/pages/admin/book-shift/SubmitMultipleShiftsForm.vue"),
				meta: {
					requiresAuth: true,
					// permission: "view-shifts",
					permission: "create-shift-request",
					fail: "/dashboard",
				},
			},
			{
				path: "timesheet",
				name: "admin.shift-submit.single.submit-shift-timesheet",
				component: () => import("@/pages/admin/book-shift/TimesheetEntry.vue"),
				meta: {
					requiresAuth: true,
					// permission: 'create-shift',
					permission: "create-timesheet-line-of-shifts",
					fail: "/dashboard",
				},
			},
		],
	},
	{
		path: "/shift-schedule",
		name: "admin.shift-schedule",
		component: () =>
			import("@/pages/admin/shift-schedule/ShiftSchedulePage.vue"),
		children: [
			{
				path: "",
				name: "admin.shift-schedule.shifts",
				component: () =>
					import("@/pages/admin/shift-schedule/subpages/ShiftScheduleTabs.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-shifts|view-temp-shifts",
					fail: "/error",
				},
			},
			{
				name: "admin.shift-schedule.details",
				path: "details/:shiftId",
				component: () =>
					import("@/pages/admin/shift-schedule/subpages/ShiftDetailsTabs.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-shifts|view-temp-shifts",
					fail: "/error",
				},
				children: [
					{
						name: "admin.shift-schedule.details.regular",
						path: "regular",
						component: () =>
							import(
								"@/pages/admin/shift-schedule/subpages/ShiftScheduleRegularShifts.vue"
							),
						meta: {
							requiresAuth: true,
							permission: "view-shifts|view-temp-shifts",
							fail: "/error",
						},
					},
					{
						name: "admin.shift-schedule.details.corrections",
						path: "corrections",
						component: () =>
							import(
								"@/pages/admin/shift-schedule/subpages/ShiftScheduleCorrections.vue"
							),
						meta: {
							requiresAuth: true,
							permission: "view-payroll-shift-logs",
							fail: "/error",
							systemPermissions: "can_use_nexus_middleware",
						},
					},
					{
						name: "admin.shift-schedule.details.history-log",
						path: "external-history-log",
						component: () =>
							import(
								"@/pages/admin/shift-schedule/subpages/ShiftScheduleExternalHistoryLog.vue"
							),
						meta: {
							requiresAuth: true,
							permission: "view-calculation-external-information",
							fail: "/error",
							systemPermissions: "can_use_nexus_middleware",
						},
					},
				],
			},
		],
	},
	{
		path: "/manage-rates",
		redirect: "/manage-rates/categories",
		name: "admin.manage-rates",
		component: () =>
			import("@/pages/admin/rate-management/RateManagementPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "manage-rates",
			fail: "/error",
		},
		children: [
			{
				path: "categories",
				name: "admin.manage-rates.categories",
				component: () =>
					import(
						"@/pages/admin/rate-management/subpages/ManageCategoriesPage.vue"
					),
				meta: {
					requiresAuth: true,
					permission: "manage-rates",
					fail: "/error",
				},
			},
			{
				path: "increments",
				name: "admin.manage-rates.increments",
				component: () =>
					import(
						"@/pages/admin/rate-management/subpages/ManageIncrementsPage.vue"
					),
				meta: {
					requiresAuth: true,
					permission: "manage-rates",
					fail: "/error",
				},
			},
			{
				path: "rates",
				// path: "rates/:type?/:client_id?/:cat_id?",
				name: "admin.manage-rates.rates",
				component: () =>
					import("@/pages/admin/rate-management/subpages/ManageRatesPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-rates",
					fail: "/error",
				},
			},
			{
				path: "expenses/:code?",
				name: "admin.manage-rates.expenses",
				component: () =>
					import(
						"@/pages/admin/rate-management/subpages/ManageExpenseRatesPage.vue"
					),
				meta: {
					requiresAuth: true,
					permission: "manage-expense-rates",
					fail: "/error",
				},
			},
			{
				path: "public",
				name: "admin.manage-rates.public-holidays",
				component: () =>
					import(
						"@/pages/admin/rate-management/subpages/ManagePublicHolidaysPage.vue"
					),
				meta: {
					requiresAuth: true,
					permission: "manage-rates",
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/documents",
		name: "admin.documents",
		component: () => import("@/pages/admin/compliances/DocumentsPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-documents",
			fail: "/error",
		},
	},
	{
		path: "/compliance",
		name: "admin.compliance",
		component: () => import("@/pages/admin/compliances/CompliancePage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-documents",
			fail: "/error",
		},
	},
	{
		path: "/location-client-management",
		name: "admin.client-management",
		component: () => import("@/pages/admin/LocationClientManagementPage.vue"),
		// LocationClientManagementPage
		meta: {
			// "has-keep-alive": ["LocationClientManagementPage"],
			requiresAuth: true,
			permission: "view-client-management-page",
			fail: "/error",
		},
	},
	{
		path: "/temp-compliance/:tempId",
		name: "admin.temp-compliance",
		component: () => import("@/pages/admin/compliances/TempCompliancePage.vue"),
		meta: {
			"has-keep-alive": ["AllCandidatesPage", "CompliancePage"],
			requiresAuth: true,
			permission: "view-documents",
			fail: "/error",
		},
	},
	{
		path: "/settings",
		redirect: "/settings/admin-deactivation",
		name: "admin.security",
		component: () => import("@/pages/admin/settings/SettingsMainPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "manage-security",
			fail: "/error",
		},
		children: [
			{
				path: "admin-deactivation",
				name: "admin.security.admin-deactivation",
				component: () =>
					import("@/pages/admin/settings/subpages/AdminDeactivationPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
			},
			{
				path: "contacts",
				name: "admin.security.contacts",
				component: () =>
					import("@/pages/admin/settings/subpages/ContactTitlePage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
			},
			{
				path: "tags",
				name: "admin.security.tags",
				component: () =>
					import("@/pages/admin/settings/subpages/CandidateTagsPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
			},
			{
				path: "journal",
				name: "admin.security.journal",
				component: () =>
					import("@/pages/admin/settings/subpages/JournalPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
			},
			{
				path: "email-from",
				name: "admin.security.email-from",
				component: () =>
					import("@/pages/admin/settings/subpages/EmailFromPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
			},
			{
				path: "increment-weeks",
				name: "admin.security.increment-weeks",
				component: () =>
					import("@/pages/admin/settings/subpages/WeeklyIncrementCounter.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
			},
			{
				path: "holiday-request",
				name: "admin.security.holiday-request",
				component: () =>
					import("@/pages/admin/settings/subpages/HolidayRequestPage.vue"),
				meta: {
					requestAuth: true,
					permission: "manage-security",
					systemPermissions: ["can_use_holiday_requests"],
					fail: "/error",
				},
			},
			{
				path: "permissions",
				name: "admin.security.permissions",
				component: () =>
					import("@/pages/admin/settings/subpages/PermissionsPage.vue"),
				meta: {
					requestAuth: true,
					permission: "manage-security",
					fail: "/error",
				},
				children: [
					{
						path: ":id",
						name: "admin.security.permissions.manage",
						component: () =>
							import(
								"@/pages/admin/settings/subpages/permissions/PermissionsListSubpage.vue"
							),
						meta: {
							requestAuth: true,
							permission: "manage-security",
							fail: "/error",
						},
					},
				],
			},
			{
				path: "general-receipts",
				name: "admin.security.general-reciepts",
				component: () =>
					import("@/pages/admin/settings/subpages/GeneralReceiptPage.vue"),
				meta: {
					requestAuth: true,
					permission:
						"manage-general-receipts|view-expenses|view-temp-expenses",
					// systemPermissions: ["can_use_expenses"],
					fail: "/error",
				},
			},
			{
				path: "annual-leave",
				name: "admin.security.annual-leave",
				component: () =>
					import("@/pages/admin/settings/subpages/AlAcumulationPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "manage-security",
					systemPermissions: ["can_use_accumulation"],
				},
			},
			{
				path: "shift-activity-tags",
				name: "admin.security.shift-activity-tags",
				component: () =>
					import("@/pages/admin/settings/subpages/ShiftsActivityTags.vue"),
				meta: {
					requestAuth: true,
					permission: "",
					// systemPermissions: ["can_use_expenses"],
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/file-upload",
		redirect: "/file-upload/timesheet",
		name: "admin.file-upload",
		component: () => import("@/pages/admin/file-upload/FileuploadPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "bulk-upload-timesheet",
			fail: "/error",
		},
		children: [
			{
				path: "timesheet",
				name: "admin.file-upload.timesheet",
				component: () =>
					import("@/pages/admin/file-upload/BulkUploadTimesheetPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "bulk-upload-timesheet",
					fail: "/error",
				},
			},
			{
				path: "open-shifts",
				name: "admin.file-upload.open-shifts",
				component: () =>
					import("@/pages/admin/file-upload/BulkUploadOpenShiftPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "bulk-upload-open-shifts",
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/invoices",
		name: "admin.invoices",
		component: () => import("@/pages/admin/invoices/InvoicesPage.vue"),
		meta: {
			requiresAuth: true,
			permission:
				"view-billing|view-holiday-requests-billing|view-expenses-billing|release-premium-holiday-pay",
			fail: "/error",
		},
		children: [
			{
				path: "main",
				name: "admin.invoices.tab",
				component: () =>
					import("@/pages/admin/invoices/subpages/PayAndBillPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-billing",
					fail: "/error",
				},
			},
			{
				path: "holiday-balances",
				name: "admin.invoices.holiday-balances",
				component: () =>
					import("@/pages/admin/invoices/subpages/HolidayBalancesPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-holiday-requests-billing",
					systemPermissions: ["can_use_holiday_requests"],
					fail: "/error",
				},
			},
			{
				path: "expenses",
				name: "admin.invoices.expenses",
				component: () =>
					import("@/pages/admin/invoices/subpages/ExpenseInvoicesPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-expenses-billing",
					systemPermissions: ["can_use_expenses"],
					fail: "/error",
				},
			},
			{
				path: "annual-leave",
				name: "admin.invoices.annual-leave",
				component: () =>
					import("@/pages/admin/invoices/subpages/AnnualLeaveInvoicesPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "release-premium-holiday-pay",
					systemPermissions: ["can_use_accumulation"],
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/reports",
		name: "admin.reports",
		component: () => import("@/pages/admin/ReportsPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-report|schedule-report",
			fail: "/error",
		},
	},
	{
		path: "/all-candidates",
		name: "admin.all-candidates",
		component: () =>
			import("@/pages/admin/all-candidates/AllCandidatesPage.vue"),
		// component: {
		// 	render() {
		// 		return h("h1", "Not yet available. Thank you for your patience.");
		// 	},
		// },
		props: (route) => ({
			page: route.query.page,
		}),
		meta: {
			"has-keep-alive": ["CandidatePage"],
			requiresAuth: true,
			permission: "view-all-candidates-page",
			fail: "/error",
		},
	},
	{
		path: "/temp-calendar/:tempId?",
		name: "admin.temp-calendar",
		component: () => import("@/pages/TempCalendarPage.vue"),
		meta: {
			"has-keep-alive": ["AllCandidatesPage"],
			requiresAuth: true,
			permission: "view-temp-calendar",
			fail: "/error",
		},
	},
	{
		path: "/candidate/:tempId",
		redirect: { name: "admin.candidate.edit" },
		name: "admin.candidate",
		component: () => import("@/pages/admin/candidate/CandidatePage.vue"),
		meta: {
			"has-keep-alive": ["AllCandidatesPage"],
			requiresAuth: true,
			permission: "view-temp-details",
			fail: "/error",
		},
		children: [
			{
				path: "profile",
				name: "admin.candidate.edit",
				component: () =>
					import("@/pages/admin/candidate/subpages/CandidateProfile.vue"),
				meta: {
					"has-keep-alive": ["AllCandidatesPage"],
					requiresAuth: true,
					permission: "view-temp-details",
					fail: "/error",
				},
			},
			{
				path: "journals",
				name: "admin.candidate.journals",
				component: () =>
					import("@/pages/admin/client-details-page/ClientJournalInfo.vue"),

				redirect: {
					name: "admin.candidate.journals.system",
				},
				meta: {
					"has-keep-alive": ["AllCandidatesPage"],
					requiresAuth: true,
					permission: "view-temp-journal",
					fail: "/error",
				},
				children: [
					{
						path: "system",
						name: "admin.candidate.journals.system",
						component: () =>
							import(
								"@/pages/admin/client-details-page/client-journal-info/CandidateJournalsSystem.vue"
							),

						meta: {
							"has-keep-alive": ["AllCandidatesPage"],
							requiresAuth: true,
							permission: "view-temp-journal",
							fail: "/error",
						},
					},
					{
						path: "logs",
						name: "admin.candidate.journals.logs",
						component: () =>
							import(
								"@/pages/admin/client-details-page/client-journal-info/CandidateJournalLogs.vue"
							),

						meta: {
							"has-keep-alive": ["AllCandidatesPage"],
							requiresAuth: true,
							permission: "view-temp-journal",
							fail: "/error",
						},
					},
				],
			},
			{
				path: "holiday-requests",
				name: "admin.candidate.holiday-requests",
				component: () =>
					import(
						"@/pages/admin/candidate/subpages/CandidateHolidayRequest.vue"
					),

				meta: {
					"has-keep-alive": ["AllCandidatesPage"],
					requiresAuth: true,
					permission: "view-holiday-requests",
					systemPermissions: ["can_use_holiday_requests"],
					fail: "/error",
				},
			},
			{
				path: "annual-leave",
				name: "admin.candidate.annual-leave",
				component: () =>
					import("@/pages/admin/candidate/subpages/CandidateAl.vue"),

				meta: {
					"has-keep-alive": ["AllCandidatesPage"],
					requiresAuth: true,
					permission: "view-accumulation-transactions",
					systemPermissions: ["can_use_accumulation"],
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/client-user-management",
		name: "admin.user-management",
		component: () => import("@/pages/admin/ClientUserManagementPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "create-user",
			fail: "/error",
		},
	},
	{
		path: "/calendar",
		name: "admin.calendar",
		component: () => import("@/pages/CalendarPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-calendar",
			fail: "/error",
		},
	},
	{
		path: "/edit-user/:userId",
		name: "admin.edit-user",
		component: EditUser,
		meta: {
			"has-keep-alive": ["ClientUserManagementPage"],
			requiresAuth: true,
			permission: "edit-user",
			fail: "/error",
		},
	},
	{
		path: "/client/:id",
		// redirect: "/client/:id/info",
		redirect: {
			name: "admin.client-details.info",
		},
		name: "admin.client-details",
		component: () => import("@/pages/admin/ClientDetailsPage.vue"),
		meta: {
			"has-keep-alive": ["LocationClientManagementPage"],
			requiresAuth: true,
			permission: "view-client",
			fail: "/error",
		},
		children: [
			{
				path: "info",
				name: "admin.client-details.info",
				component: () =>
					import("@/pages/admin/client-details-page/ClientProfileInfo.vue"),

				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "view-client",
					fail: "/error",
				},
			},
			{
				path: "approvers",
				name: "admin.client-details.approvers",
				component: () =>
					import("@/pages/admin/client-details-page/ClientApproversInfo.vue"),
				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "view-client-admins",
					fail: "/error",
				},
			},
			{
				path: "documents",
				name: "admin.client-details.documents",
				component: () =>
					import("@/pages/admin/client-details-page/ClientDocumentsInfo.vue"),
				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "view-documents-on-client-page",
					fail: "/error",
				},
			},
			{
				path: "contacts",
				name: "admin.client-details.contacts",
				component: () =>
					import("@/pages/admin/client-details-page/ClientContactsInfo.vue"),

				redirect: {
					name: "admin.client-details.contacts.all",
				},
				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "view-contacts",
					fail: "/error",
				},
				children: [
					{
						path: "all",
						name: "admin.client-details.contacts.all",
						component: () =>
							import(
								"@/pages/admin/client-details-page/ClientContactsDefaultPage.vue"
							),

						meta: {
							"has-keep-alive": ["LocationClientManagementPage"],
							requiresAuth: true,
							permission: "view-contacts",
							fail: "/error",
						},
					},
					{
						path: "shift-confirmation",
						name: "admin.client-details.contacts.sift-confirmation",
						component: () =>
							import(
								"@/pages/admin/client-details-page/ClientContactsShiftConfirmPage.vue"
							),
						meta: {
							"has-keep-alive": ["LocationClientManagementPage"],
							requiresAuth: true,
							permission: "view-contacts",
							fail: "/error",
						},
					},
				],
			},
			{
				path: "finances/:categoryId?",
				name: "admin.client-details.finances",
				component: () =>
					import("@/pages/admin/client-details-page/ClientFinancesInfo.vue"),

				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "edit-service-fees",
					fail: "/error",
				},
			},
			{
				path: "invoices",
				name: "admin.client-details.invoices",
				component: () =>
					import("@/pages/admin/client-details-page/ClientInvoiceInfo.vue"),
				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "",
					// permission: "split-invoice-by-subcategory",
					altPermissions: [
						{
							permissions: ["edit-client-invoice-addresses"],
							systemPermissions: ["can_use_nexus_middleware"],
						},
						{
							permissions: ["split-invoice-by-subcategory"],
							notSystemPermissions: ["can_use_nexus_middleware"],
						},
					],
					fail: "/error",
				},
			},
			{
				path: "journals",
				name: "admin.client-details.journals", // ClientJournalInfo
				component: () =>
					import("@/pages/admin/client-details-page/ClientJournalInfo.vue"),
				redirect: {
					name: "admin.client-details.journals.system",
				},
				meta: {
					"has-keep-alive": ["LocationClientManagementPage"],
					requiresAuth: true,
					permission: "view-client-journal",
					fail: "/error",
				},
				children: [
					{
						path: "system",
						name: "admin.client-details.journals.system",
						// ClientCandidateJournalsSystem
						component: () =>
							import(
								"@/pages/admin/client-details-page/client-journal-info/CandidateJournalsSystem.vue"
							),
						meta: {
							"has-keep-alive": ["LocationClientManagementPage"],
							requiresAuth: true,
							permission: "view-temp-journal",
							fail: "/error",
						},
					},
					{
						path: "logs",
						name: "admin.client-details.journals.logs",
						// ClientCandidateJournalLogs
						component: () =>
							import(
								"@/pages/admin/client-details-page/client-journal-info/CandidateJournalLogs.vue"
							),
						meta: {
							"has-keep-alive": ["LocationClientManagementPage"],
							requiresAuth: true,
							permission: "view-temp-journal",
							fail: "/error",
						},
					},
				],
			},
		],
	},
	{
		path: "/notification-centre",
		redirect: "/notification-centre/email",
		name: "admin.notifications",
		component: () =>
			import("@/pages/admin/notifications-center/NotificationsCenterPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-notificationcentre-messages",
			fail: "/error",
		},
		children: [
			{
				path: "email",
				name: "admin.notifications.email",
				component: () =>
					import(
						"@/pages/admin/notifications-center/NotificationsCenterPage.vue"
					),
				props: { isEmail: true },
				meta: {
					requiresAuth: true,
					permission: "view-notificationcentre-messages",
					fail: "/error",
				},
			},
			{
				path: "message-centre",
				name: "admin.notifications.messages",
				component: () =>
					import(
						"@/pages/admin/notifications-center/NotificationsCenterPage.vue"
					),
				props: { isPushMessage: true },
				meta: {
					requiresAuth: true,
					permission: "view-notificationcentre-messages",
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/expenses",
		name: "admin.expenses",
		component: () => import("@/pages/admin/expenses/ExpensesPage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-expenses|view-temp-expenses",
			fail: "/error",
		},
		children: [
			{
				path: "/expenses/awaiting",
				name: "admin.expenses.awaiting",
				component: () => import("@/pages/admin/expenses/ExpensesPage.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-expenses|view-temp-expenses",
					fail: "/error",
				},
			},
			{
				path: "/expenses/approved",
				name: "admin.expenses.approved",
				component: {
					render() {
						return h("h1", "Not yet implemented APPROVED");
					},
				},
				meta: {
					requiresAuth: true,
					permission: "view-expenses|view-temp-expenses",
					fail: "/error",
				},
			},
			{
				path: "/expenses/rejected",
				name: "admin.expenses.rejected",
				component: {
					render() {
						return h("h1", "Not yet implemented REJECTED");
					},
				},
				meta: {
					requiresAuth: true,
					permission: "view-expenses|view-temp-expenses",
					fail: "/error",
				},
			},
		],
	},
	{
		path: "/backpay",
		name: "admin.backpay",
		component: () => import("@/pages/admin/BackpayPage.vue"),
		meta: {
			requiresAuth: true,
			systemPermissions: ["can_use_nexus_middleware"],
			permission: "view-backpay-page",
			fail: "/error",
		},
	},
	{
		path: "/nexus",
		name: "admin.nexus",
		component: () => import("@/pages/admin/nexus/NexusPage.vue"),
		meta: {
			requiresAuth: true,
			systemPermissions: ["can_use_nexus_middleware"],
			permission: "view-nexus-module-page",
			fail: "/error",
		},
		children: [
			{
				path: "nexus-reports",
				name: "admin.nexus.reports",
				component: () =>
					import("@/pages/admin/nexus/subpages/NexusReports.vue"),
				meta: {
					requiresAuth: true,
					permission: "view-nexus-report",
					fail: "/nexus",
				},
			},
		],
	},
];
