<script setup lang="ts">
import api from "@/api/";
import type { IAnnualLeaveTransactionPost } from "@/api/types";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();

type TOptParams = "description" | "value" | "date" | "type" | "by_admin";
type TTypeOptions = {
	ubt_id: number;
	agn_id: number;
	ubt_code: "WROF" | "ADJM" | "PAY";
	ubt_name: string;
	ubt_selectable: number;
	params: TOptParams[];
};

const emit = defineEmits(["close-modal", "confirm"]);

const form = reactive<{
	date?: string;
	description?: string;
	amount?: string;
	type: TTypeOptions | null;
}>({
	date: undefined,
	description: undefined,
	amount: undefined,
	type: null,
});
const dateConfig = reactive({
	altFormat: "d/m/Y",
	dateFormat: "Y-m-d",
	enableTime: false,
	minDate: "today",
});
const route = useRoute();
const typeOptions = ref<TTypeOptions[]>([]);
const isLoadingSave = ref(false);
const currentRoute = computed(() => route);
const currentTempId = computed(
	() => currentRoute.value.params.tempId as string,
);
const isInvalidForm = computed(() => {
	const isAmountGood = Boolean(form.amount) || form.amount === "0";
	return !isAmountGood;
});

async function onClickUpdate() {
	if (!currentTempId.value) {
		console.warn("No temp id", currentRoute.value);
		return;
	}
	if (isInvalidForm.value) {
		console.error("Form invalid", form);
		return;
	}

	isLoadingSave.value = true;

	const payload: IAnnualLeaveTransactionPost = {
		id: currentTempId.value,
		by_admin: form.type?.params.includes("by_admin") ? true : undefined,
		description: form.description || undefined,
		value: parseFloat(form.amount) || 0,
		date: form.date || undefined,
		type: form.type?.ubt_id || -1,
	};

	try {
		const res =
			form.type?.ubt_code === "PAY"
				? await api.alTempHolidayReqPost(payload)
				: await api.alTempTransacationPost(payload);
		console.warn(res.data);
		onClickCancel();
		emit("confirm");
	} catch (err: any) {
		console.warn(err.message);
		const errMes = parseErrors(err);
		if (Array.isArray(errMes)) {
			for (const er of errMes) {
				toastStore.openToastError(er);
			}
			return;
		}
		toastStore.openToastError(errMes);
	} finally {
		isLoadingSave.value = false;
	}
}
function onClickCancel() {
	emit("close-modal");
}

async function fetchAnnualTypes() {
	try {
		const res = await api.alTempTypeGet();
		typeOptions.value = res.data;
	} catch (err: any) {
		console.warn(err.message);
	}
}
function handleNumberInput(e: Event) {
	const target = e.target as HTMLInputElement;
	if (!target.value) {
		form.amount = undefined;
		return;
	}
	console.log(/^-?\d*(\.\d*)?$/g.test(target.value), "aaaaaaaaaaa");
	//  // /^\d+(\.\d*)?$/g. OLD
	if (/^-?\d*(\.\d*)?$/g.test(target.value)) {
		// form.amount = parseFloat(target.value);
		form.amount = target.value;
	} else {
		target.value = (form.amount || "") + "" || "";
	}
}

onMounted(() => {
	fetchAnnualTypes();
});
</script>

<template>
	<div class="modal-content">
		<div class="input-group type">
			<label>Type</label>
			<multiselect-form
				v-model="form.type"
				:options="typeOptions"
				:multiple="false"
				:close-on-select="true"
				track-by="ubt_id"
				value-prop="ubt_id"
				label="ubt_name"
				placeholder="Choose type"
			></multiselect-form>
		</div>
		<div
			v-if="form.type?.params.includes('date')"
			class="input-group date"
		>
			<label>Date</label>
			<modern-date-picker
				v-model="form.date"
				placeholder="Date"
				:isDisabled="false"
				:propConfig="dateConfig"
				:enable-time-picker="false"
				auto-apply
				min-date="today"
			></modern-date-picker>
		</div>
		<div
			v-if="form.type?.params.includes('description')"
			class="input-group desc"
		>
			<label>Description</label>
			<input
				v-model="form.description"
				class="input"
				placeholder="Descriptions"
			/>
		</div>
		<div
			v-if="form.type?.params.includes('value')"
			class="input-group amount"
		>
			<label>Amount</label>
			<input
				:value="form.amount || ''"
				class="input"
				placeholder="Amount"
				@input="handleNumberInput"
			/>
		</div>
		<div class="action-group">
			<button
				class="button is-success"
				:class="{ 'is-loading': isLoadingSave }"
				:disabled="isLoadingSave || isInvalidForm"
				@click="onClickUpdate"
			>
				<span>Update</span>
			</button>
			<button
				class="button is-generic-app-blue"
				@click="onClickCancel"
			>
				<span>Cancel</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.modal-content {
	display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 10px;
	min-height: 240px;
	min-width: 400px;
	justify-content: space-between;

	.input-group {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;

		label {
			min-width: 120px;
		}

		input {
			height: 40px;
		}

		&.desc {
			input {
				width: 100%;
			}
		}
		&.date {
			:deep() {
				input {
					height: 40px;
				}
			}
		}

		&.amount {
			width: 300px;
		}
	}

	.action-group {
		display: flex;
		gap: 20px;
		align-items: center;
		margin-top: 10px;
		justify-content: center;
	}
}
</style>
