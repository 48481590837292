<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors";
import api from "@/api";
const emit = defineEmits(["close-modal", "confirm"]);
const props = withDefaults(
	defineProps<{
		calculationMessage: string;
		params: any;
		statusCode?: number;
	}>(),
	{
		statusCode: 200,
	},
);

const canStartProcess = ref(false);
const message = ref("");
// const loading = ref(false);

const succesfulyMessage = ref(false);
const errorMessage = ref(false);

const isStatusCodeOk = computed(() => {
	return props.statusCode === 200 ? true : false;
});

async function startRecalculation() {
	canStartProcess.value = true;
	try {
		const res = await api.startMassRecalculation(props.params);
		succesfulyMessage.value = true;
		message.value = res.data.message;
		// this.$toasted.success("Successfully added").goAway(2500);
		setTimeout(() => {
			// this.$emit("start-recalculation");
			emit("confirm", true);
			emit("close-modal");
			canStartProcess.value = false;
		}, 3000);
	} catch (err) {
		console.log("Error", err.message);
		const errs = parseErrors(err);
		if (errs) {
			errorMessage.value = true;
			message.value = errs;
			// this.$toasted.error(errs).goAway(1500);

			setTimeout(() => {
				// this.callModalClose();
				onClickReturn();
			}, 3000);
		}
		canStartProcess.value = false;
	}
}

function onClickReturn() {
	emit("confirm", false);
	emit("close-modal");
}
onMounted(() => {
	message.value = props.calculationMessage;
	// if (props.statusCode !== 200)
	// 	setTimeout(() => {
	// 		// this.callModalClose();
	// 		onClickReturn();
	// 	}, 3000);
});
</script>

<template>
	<div class="confirm-modal">
		<div class="content">
			<div
				:class="{
					message: true,
					succesMessage: succesfulyMessage,
					errMessage: errorMessage || !isStatusCodeOk,
				}"
			>
				{{ message }}
			</div>
			<div class="wrapp-buttons">
				<span
					v-if="canStartProcess"
					class="loading-recalculate"
				>
					<img
						src="@/assets/image/loader.svg"
						alt=""
					/>
				</span>
				<button
					class="alt"
					:disabled="canStartProcess"
					@click="onClickReturn()"
				>
					Cancel
				</button>
				<button
					class="btn"
					:disabled="canStartProcess || props.statusCode !== 200"
					@click="startRecalculation()"
				>
					Start
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	min-height: 130px;
	gap: 10px;
	color: $text-color;

	.content {
		margin-top: 25px;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		.warningTxt {
			font-size: 2em;
		}
		.message {
			text-align: center;
			font-size: 1rem;
			// color: $success-color;
			transition: 2s;
		}

		.succesMessage {
			font-size: 1rem;
			color: $success-color;
			transition: 2s;
		}
		.errMessage {
			font-size: 1rem;
			color: $error-color;
			transition: 2s;
		}
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		// flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
}
</style>
