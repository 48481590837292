import api from "@/api";
import { type TCategory, type TTemp } from "@/assets/js/globalTypes";
import { useRoute } from "vue-router";

export const useTempStore = defineStore("temp", () => {
	const currentTemp = ref<Partial<TTemp>>();

	const tempFullProfile = ref<TTemp & { categories?: TCategory[] }>();

	function setCurrentTemp(temp: TTemp, full_profile = false) {
		currentTemp.value = temp;
		if (full_profile) {
			tempFullProfile.value = temp;
		}
	}
	const route = useRoute();

	const tempRouteId = computed(() => {
		if (!route) {
			return;
		}
		const tempIdParam = route.params?.tempId;
		if (!tempIdParam) {
			return;
		}

		const tempId = parseInt(tempIdParam as string);
		if (!tempId) {
			return;
		}
		return tempId;
	});

	let fetchingTemp = false;

	const getSelectedTemp = computed(() => currentTemp.value);

	watch(
		tempRouteId,
		(val, oldVal) => {
			if (!val || val === oldVal) {
				return;
			}
			fetchTemp(val);
		},
		{ immediate: true },
	);

	async function fetchTemp(tempId: number) {
		if (fetchingTemp && tempRouteId && tempRouteId.value === tempId) {
			return;
		}
		try {
			fetchingTemp = true;
			const temp = await api.fetchTempProfile({
				tempId,
				include:
					"region,subcategories,specialities,clients.incrementRates,categories.increments,increments,wtd,preferredRegions,tags,holidayBalance,gnibConfig,tempOnCallInfo,tempSleepoverInfo,tempExpensesInfo,nationality,relatives,mileageTraveled",
			});
			setCurrentTemp(temp.data.data, true);
			return temp;
		} catch (e) {
			console.log(e);
		} finally {
			fetchingTemp = false;
		}
	}
	function getTempLocations(tempId?: undefined | null);
	function getTempLocations(tempId: number[]);
	function getTempLocations(tempId: number);
	function getTempLocations(tempId: number | number[]) {
		const payload = {
			include: "locations,withArchived",
			tempId,
		};
		if (tempId) {
			payload["temps[]"] = Array.isArray(tempId) ? tempId : [tempId];
		}
		return api.getCandidates(payload);
	}

	async function updateTempIncrements() {
		try {
			const temp = await api.fetchTempProfile({
				tempId: tempRouteId.value,
				include:
					"subcategories,clients.incrementRates,categories.increments,increments",
			});
			if (temp.data.data.increments) {
				tempFullProfile.value.increments = temp.data.data.increments;
			}
		} catch (e) {
			console.log(e);
		}
	}
	const getFullProfile = computed(() => {
		return tempFullProfile.value;
	});
	return {
		setCurrentTemp,
		getFullProfile,
		getSelectedTemp,
		fetchTemp,
		getTempLocations,
		tempId: tempRouteId,
		updateTempIncrements,
	};
});
