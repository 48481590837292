<script lang="ts" setup>
/**
 * this modal should be directly added to modal component
 * e.g. add component in ExpenseSignOffModal.vue
    <modal-in-modal
        :isModalOpen="isModalOpen"
        :initialModalInModalOpen="initialModalInModalOpen"
        :confirmMessage="'Are you sure you want to reject this expense?'"
        @confirm-action="rejectSolutions"
        @close-modal="isModalOpen=false"
        @initial-model-open="initialModalInModalOpen=isModalOpen"
    />
 */
const emit = defineEmits([
	"confirm-action",
	"cancel-action",
	"close-modal",
	"initial-model-open",
]);

const props = withDefaults(
	defineProps<{
		isModalOpen: boolean;
		initialModalInModalOpen: boolean; // this is added because of the click outside the modal event
		confirmMessage: string;
		confirmButtonText?: string;
		cancelButtonText?: string;
	}>(),
	{
		isModalOpen: false,
		confirmButtonText: "Confirm",
		cancelButtonText: "Cancel",
	},
);
const submitClicked = ref<boolean>(false);
function confirmAction() {
	submitClicked.value = true;
	emit("confirm-action");
	closeModal();
}
function cancelAction() {
	submitClicked.value = true;
	emit("cancel-action");
	closeModal();
}
function closeModal() {
	submitClicked.value = false;
	emit("close-modal");
}
const modalRef = ref<HTMLElement | null>(null);

const handleClickOutside = (event: MouseEvent) => {
	if (
		props.initialModalInModalOpen &&
		modalRef.value &&
		!modalRef.value.contains(event.target as Node)
	) {
		closeModal();
	}
	emit("initial-model-open", props.isModalOpen);
};
onMounted(() => {
	document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
	document.removeEventListener("click", handleClickOutside);
});
</script>

<template>
	<div
		v-if="props.isModalOpen"
		class="overlay"
	>
		<div
			ref="modalRef"
			class="confirm-modal flex-column-center"
		>
			<div>
				<img
					src="@/assets/image/warning.svg"
					style="width: 110px"
					alt=""
				/>
				<div class="warningTxt text-centered">Warning</div>
			</div>
			<div class="flex-column-center flex-space-between">
				<div
					style="text-align: center; font-size: 1rem"
					class="mg-5"
				>
					<slot name="modal-confirm-message">
						{{ props.confirmMessage }}
					</slot>
				</div>
				<div class="wrapp-buttons">
					<button
						class="alt"
						:disabled="submitClicked"
						@click.prevent="cancelAction"
					>
						{{ props.cancelButtonText }}
					</button>
					<button
						class="btn"
						:disabled="submitClicked"
						@click.prevent="confirmAction"
					>
						{{ props.confirmButtonText }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$modal-overlay: $modal-overlay;
.overlay {
	position: fixed;
	inset: 0;
	background: $modal-overlay;
	z-index: 101;

	&.transparent {
		background: transparent;
	}
	.confirm-modal {
		position: absolute;
		padding: 50px 65px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 400px;
		max-height: 500px;
		z-index: 102;
		background: $white-color;
		border-radius: 10px;
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		// flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
	.warningTxt {
		font-size: 2em;
	}
}
</style>
