<script setup lang="ts">
const emit = defineEmits(["close-modal", "confirm"]);

function confirm() {
	emit("confirm");
	emit("close-modal");
}
function closeModal() {
	emit("close-modal");
}

// export default {
// 	methods: {
// 		confirm() {
// 			Evt.fire("confirmShift");
// 		},
// 		closeModal() {
// 			// this.$emit('close-modal')
// 			this.$emit("cancel-modal");
// 			Evt.fire("disposeModal");
// 		},
// 	},
// };
</script>

<template>
	<div class="time-warn">
		<div class="body">
			<p class="info">
				You are about to create a shift that lasts for more than 24 hours. Do
				you want to proceed?
			</p>
			<div class="options">
				<button
					class="button is-danger"
					@click="confirm"
				>
					<span>Yes</span>
				</button>
				<button
					class="button is-generic-app-blue"
					@click="closeModal"
				>
					<span>No</span>
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.time-warn {
	height: 180px;
	padding: 0 20px 20px;
	width: 460px;

	.body {
		padding: 2.5em;
		font-size: 1.2em;

		.info {
			text-align: center;
		}

		.options {
			margin-top: 1em;
			text-align: center;

			.button {
				margin: 5px;
				min-width: 8em;
			}
		}
	}
}
</style>
