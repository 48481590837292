import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import InlineSvg from "vue-inline-svg";
import Toast, { type PluginOptions, POSITION } from "vue-toastification";
// import { VueReCaptcha } from "vue-recaptcha-v3";
import { Field, Form, ErrorMessage } from "vee-validate";
import inputRemoveSpacesDirective from "./assets/js/inputRemoveSpacesDirective";
import inputOnlyNumbersAndCommasDirective from "./assets/js/inputOnlyNumbersCommasDirective";
import inputOnlyNumbersDotsDirective from "./assets/js/inputOnlyNumbersDotsDirective";
import inputOnlyNumbersDirective from "./assets/js/inputOnlyNumbersDirective";
import clickOutsideDirective from "./assets/js/clickedOutsideTheElementDirective";
import { QuillEditor } from "@vueup/vue-quill";
import { TooltipDirective, TooltipComponent } from "vue3-tooltip";
import "vue3-tooltip/tooltip.css";

// import "virtual:uno.css";
import "vue-toastification/dist/index.css";

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);

app.component("InlineSvg", InlineSvg);
app.component("FieldComp", Field);
app.component("FormComp", Form);
app.component("ErrorMessage", ErrorMessage);
app.component("QuillEditor", QuillEditor);
app.component("TooltipComp", TooltipComponent);

app.directive("tooltip", TooltipDirective);
app.directive("remove-spaces-from-input", inputRemoveSpacesDirective);
app.directive("only-numbers-commas", inputOnlyNumbersAndCommasDirective);
app.directive("only-numbers-dots", inputOnlyNumbersDotsDirective);
app.directive("only-numbers", inputOnlyNumbersDirective);
app.directive("click-outside", clickOutsideDirective);

const options: PluginOptions = {
	position: POSITION.TOP_RIGHT,
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: false,
	icon: false,
	rtl: false,
	toastClassName: "my-custom-toast-class",
};
app.use(Toast, options);
// if (import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY) {
// 	app.use(VueReCaptcha, {
// 		siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,
// 		loaderOptions: {
// 			autoHideBadge: false,
// 		},
// 	});
// }

app.mount("#app");
