<script lang="ts" setup>
import api from "@/api";
import { Errors } from "@/assets/js/errors";
// import { getFormattedTime } from "@/assets/js/helpers";
import { SHIFT_TYPES } from "@/assets/js/mapData";
import { parseErrors } from "@/assets/js/parseErrors";
import { useDefaultStore } from "@/store";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const shiftsStore = useShiftsStore();
const toastStore = useToastStore();
const store = useDefaultStore();
const initialStatus = store.initialStatus;
const props = defineProps<{
	modalProps: any;
}>();
const emit = defineEmits(["close-modal", "has-changed", "multiple-submitted"]);

const localShifts = ref<any>([]);
const oldLocalShifts = ref<any>([]);
const errors = ref<any>(new Errors());
const disableButton = ref<boolean>(false);
const isDataChanged = ref<boolean>(false);
const isAllShiftsSubmittedSuccessfully = ref<boolean>(false);

const isAnyShiftOnCall = computed(() => {
	return localShifts.value.some(
		(shift) => shift.type === SHIFT_TYPES.oncall.key,
	);
});
const isAnyShiftSleepover = computed(() => {
	return localShifts.value.some(
		(shift) => shift.type === SHIFT_TYPES.sleepover.key,
	);
});
const canShowWarningMessage = computed(
	() => initialStatus.shift_sign_off_warning,
);
const isAnyShiftAwaken = computed(() => {
	return localShifts.value.some((shift) => shift.isAwaken);
});
watch(
	isDataChanged,
	(newValue) => {
		emit("has-changed", newValue);
	},
	{ deep: true },
);
function init() {
	const editedShifts = props.modalProps.selectedShiftObjs.map((el) => {
		// ONCALL
		if (el.type === SHIFT_TYPES.oncall.key) {
			const oncallPartObj = el.shiftParts.find(
				(partObj) => partObj.type === SHIFT_TYPES.oncall.key,
			);
			if (oncallPartObj) {
				el.hasOnCall = true;
			}
		}
		// SLEEPOVER / AWAKEN
		if (el.type === SHIFT_TYPES.sleepover.key) {
			const awakenPartObj = el.shiftParts.find(
				(partObj) => partObj.type === SHIFT_TYPES.awaken.key,
			);
			if (awakenPartObj) {
				el.isAwaken = true;
			}
			const sleepoverPartObj = el.shiftParts.find(
				(partObj) => partObj.type === SHIFT_TYPES.sleepover.key,
			);
			if (sleepoverPartObj) {
				el.hasSleepover = true;
			}
		}
		return el;
	});
	localShifts.value = JSON.parse(JSON.stringify(editedShifts));
	oldLocalShifts.value = JSON.parse(JSON.stringify(localShifts.value));
}
// function formatDate(time: string, format = "DD/MM/YYYY HH:mm") {
// 	return getFormattedTime(time, format);
// }
function parsePartDate(shift, key, shiftType) {
	const partTimeObj = shift.shiftParts.find(
		(partObj) => partObj.type === shiftType,
	);
	let dateTime;
	if (partTimeObj) {
		dateTime = partTimeObj[key]?.date;
	}
	return dateTime;
	// return dateTime && formatDate(dateTime);
}
function onChangeCostCentre(shift, evt, index) {
	shift.location = {
		client: shift.location.client,
		...evt,
	};
	//check if data is changed
	isDataChanged.value =
		oldLocalShifts.value[index].location.id ===
		localShifts.value[index].location.id
			? false
			: true;
}
function setBreakMinutes(index, newVal) {
	localShifts.value[index].breakMinutes = newVal;
	//check if data is changed
	isDataChanged.value =
		oldLocalShifts.value[index].breakMinutes ==
		localShifts.value[index].breakMinutes
			? false
			: true;
}
function generateParamsForSave(obj) {
	const payload: any = {
		start_time: obj.startTime.date,
		end_time: obj.endTime.date,
		location_id: obj.location && obj.location.id,
		break_minutes: obj.breakMinutes,
		subcategories: obj.shiftRequest.subcategories.map((subcat) => ({
			id: subcat.id,
		})),
		checkSixMonthsOld: true,
	};

	if (
		[SHIFT_TYPES.oncall.key, SHIFT_TYPES.sleepover.key].includes(obj.type) &&
		obj.shiftParts[0]
	) {
		payload.shift_parts = [];
		for (const partObj of obj.shiftParts) {
			// special check if awaken part is filled but the checkbox is false
			if (!obj.isAwaken && partObj.type === SHIFT_TYPES.awaken.key) continue;

			payload.shift_parts.push({
				from: partObj?.from?.date,
				to: partObj?.to?.date,
				type: partObj?.type,
			});
		}
	}

	return payload;
}
// const getActiveTab = computed(() => shiftsStore.getActiveTab);
async function submitShifts() {
	let errorsCount = 0;
	for (const obj of localShifts.value) {
		delete obj.success;
		delete obj.error;
		try {
			const params = {
				url: obj.signedOffAt
					? `/shifts/${obj.id}/confirm-sign-off`
					: `/shifts/${obj.id}/sign-off`,
				data: generateParamsForSave(obj),
				// tab: getActiveTab.value.name,
			};
			const res = await api.clientSignOff(params.url, params.data);
			shiftsStore.dispatchReplaceShift(res.data.data);
			toastStore.openToastSuccess("Shift signed off");
			obj.success = "Shift signed off";
		} catch (error) {
			errorsCount++;
			const responseObject = error.response.data;
			const errorObject = {
				message: parseErrors(error),
				time: 2500,
			};

			if (responseObject.errors) {
				errors.value.record(responseObject.errors);
			} else {
				errorObject.message = responseObject.message;
				errorObject.time = 10_000;
			}
			obj.error = Array.isArray(errorObject.message)
				? errorObject.message[0]
				: errorObject.message;
		}
	}

	emit("multiple-submitted");
	isAllShiftsSubmittedSuccessfully.value = errorsCount === 0 ? true : false;
}
async function onSubmitShifts() {
	disableButton.value = true;
	await submitShifts();
	disableButton.value = false;
}
const warningMessageText = ref<any>({});
async function fetchMultipleShiftsDay(shift) {
	if (!canShowWarningMessage.value) {
		// Agency denied
		return;
	}

	const params = {
		shift_id: shift.id,
		start_time: shift.startTime.date,
	};
	try {
		const res = await api.postMultipleShiftsDay(params);
		const responseData = res.data.data;
		warningMessageText.value =
			Array.isArray(responseData) || !responseData ? {} : res.data.data;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}
function setDate(index, key, newDate) {
	localShifts.value[index][key].date = newDate;

	//check if data is changed
	isDataChanged.value =
		oldLocalShifts.value[index][key].date === localShifts.value[index][key].date
			? false
			: true;
}
function onChangeStartDate(index, key, newDate) {
	setDate(index, key, newDate);
	fetchMultipleShiftsDay(localShifts.value[index]);
}
function onChangeDatePart(index, key, newDate, shiftType) {
	const shiftPartObj = localShifts.value[index].shiftParts.find(
		(partObj) => partObj.type === shiftType,
	);
	if (shiftPartObj) {
		// ITS MUTATING OBJ
		shiftPartObj[key] = {
			date: newDate,
			// Below hardcoded
			timezone: "Europe/London",
			timezone_type: 3,
		};
	} else {
		const obj: any = {
			type: shiftType,
		};
		obj[key] = {
			date: newDate,
			// Below hardcoded
			timezone: "Europe/London",
			timezone_type: 3,
		};
		localShifts.value[index].shiftParts.push(obj);
	}
}
onMounted(async () => {
	init();
});
</script>
<template>
	<div class="multiple-wrap">
		<div
			ref="list"
			class="table-wrapper"
		>
			<table class="table">
				<thead>
					<tr>
						<th><span>Shift ID</span></th>
						<th><span>Name</span></th>
						<th><span>Client</span></th>
						<th style="width: 210px"><span>Ward</span></th>
						<th><span>Subcategories</span></th>
						<th style="width: 200px"><span>Start Time</span></th>
						<th style="width: 200px"><span>End Time</span></th>
						<template v-if="isAnyShiftOnCall">
							<th style="width: 200px"><span>On Call Start Time</span></th>
							<th style="width: 200px"><span>On Call End Time</span></th>
						</template>
						<template v-if="isAnyShiftSleepover">
							<th style="width: 200px"><span>Sleepover Start Time</span></th>
							<th style="width: 200px"><span>Sleepover End Time</span></th>
							<th><span>Was the sleeping time interrupted?</span></th>
						</template>
						<template v-if="isAnyShiftAwaken">
							<th style="width: 200px"><span>Awaken Start Time</span></th>
							<th style="width: 200px"><span>Awaken End Time</span></th>
						</template>
						<th><span>Break</span></th>
						<th><span>Status</span></th>
					</tr>
				</thead>
				<tbody v-if="localShifts && localShifts.length > 0">
					<tr
						v-for="(shift, index) in localShifts"
						:key="shift.id"
					>
						<td>
							<span>{{ shift?.id }}</span>
						</td>
						<td :title="shift?.temp?.name">
							<span>{{ shift?.temp?.name }}</span>
						</td>
						<td :title="shift?.location?.client?.name">
							<span>{{ shift?.location?.client?.name }}</span>
						</td>
						<td style="overflow: visible; width: 220px">
							<p class="control">
								<multiselect-form
									:model-value="shift.location"
									:options="shift.location.client.locations"
									:close-on-select="true"
									style="width: 200px"
									:searchable="true"
									:loading="false"
									value-prop="id"
									track-by="name"
									label="name"
									placeholder="Select ward"
									@select="onChangeCostCentre(shift, $event, index)"
								/>
							</p>
						</td>
						<td
							:title="
								Boolean(shift?.shiftRequest?.subcategories)
									? shift.shiftRequest.subcategories
											.map((subCat) => subCat.name)
											.join(', ')
									: ''
							"
						>
							<span>
								{{
									Boolean(shift?.shiftRequest?.subcategories)
										? shift.shiftRequest.subcategories
												.map((subCat) => subCat.name)
												.join(", ")
										: ""
								}}
							</span>
						</td>
						<td style="width: 180px !important">
							<modern-date-picker
								v-model="shift.startTime.date"
								:clearable="false"
								:enable-time-picker="true"
								style="width: 180px"
								class="flex-center"
								:format="'dd/MM/yyyy HH:mm'"
								outputFormatString="YYYY-MM-DD HH:mm:ss"
								@update:model-value="
									onChangeStartDate(index, 'startTime', $event)
								"
							/>
						</td>
						<td style="width: 180px !important">
							<modern-date-picker
								v-model="shift.endTime.date"
								:isDisabled="shift.endTime.date === null"
								:clearable="false"
								:enable-time-picker="true"
								style="width: 180px"
								class="flex-center"
								:format="'dd/MM/yyyy HH:mm'"
								outputFormatString="YYYY-MM-DD HH:mm:ss"
								@update:model-value="setDate(index, 'endTime', $event)"
							/>
						</td>
						<template v-if="isAnyShiftOnCall">
							<td style="width: 180px !important">
								<modern-date-picker
									v-if="shift.type === SHIFT_TYPES.oncall.key"
									:model-value="
										parsePartDate(shift, 'from', SHIFT_TYPES.oncall.key)
									"
									style="width: 180px"
									class="flex-center"
									:clearable="false"
									:enable-time-picker="true"
									:format="'dd/MM/yyyy HH:mm'"
									outputFormatString="YYYY-MM-DD HH:mm:ss"
									@update:model-value="
										onChangeDatePart(
											index,
											'from',
											$event,
											SHIFT_TYPES.oncall.key,
										)
									"
								/>
							</td>
							<td style="width: 180px !important">
								<modern-date-picker
									v-if="shift.type === SHIFT_TYPES.oncall.key"
									:model-value="
										parsePartDate(shift, 'to', SHIFT_TYPES.oncall.key)
									"
									style="width: 180px"
									class="flex-center"
									:clearable="false"
									:enable-time-picker="true"
									:format="'dd/MM/yyyy HH:mm'"
									outputFormatString="YYYY-MM-DD HH:mm:ss"
									@update:model-value="
										onChangeDatePart(
											index,
											'to',
											$event,
											SHIFT_TYPES.oncall.key,
										)
									"
								/>
							</td>
						</template>
						<template v-if="isAnyShiftSleepover">
							<td style="width: 180px !important">
								<modern-date-picker
									v-if="shift.type === SHIFT_TYPES.sleepover.key"
									:model-value="
										parsePartDate(shift, 'from', SHIFT_TYPES.sleepover.key)
									"
									:clearable="false"
									:enable-time-picker="true"
									style="width: 180px"
									class="flex-center"
									:format="'dd/MM/yyyy HH:mm'"
									outputFormatString="YYYY-MM-DD HH:mm:ss"
									@update:model-value="
										onChangeDatePart(
											index,
											'from',
											$event,
											SHIFT_TYPES.sleepover.key,
										)
									"
								/>
							</td>
							<td style="width: 180px !important">
								<modern-date-picker
									v-if="shift.type === SHIFT_TYPES.sleepover.key"
									:model-value="
										parsePartDate(shift, 'to', SHIFT_TYPES.sleepover.key)
									"
									:clearable="false"
									:enable-time-picker="true"
									style="width: 180px"
									class="flex-center"
									:format="'dd/MM/yyyy HH:mm'"
									outputFormatString="YYYY-MM-DD HH:mm:ss"
									@update:model-value="
										onChangeDatePart(
											index,
											'to',
											$event,
											SHIFT_TYPES.sleepover.key,
										)
									"
								/>
							</td>
						</template>
						<template v-if="isAnyShiftSleepover">
							<td style="text-align: center">
								<input
									v-if="shift.type === SHIFT_TYPES.sleepover.key"
									v-model="shift.isAwaken"
									type="checkbox"
								/>
							</td>
						</template>
						<template v-if="isAnyShiftAwaken">
							<td style="width: 180px !important">
								<modern-date-picker
									v-if="shift.isAwaken"
									:model-value="
										parsePartDate(shift, 'from', SHIFT_TYPES.awaken.key)
									"
									style="width: 180px"
									class="flex-center"
									:clearable="false"
									:enable-time-picker="true"
									:format="'dd/MM/yyyy HH:mm'"
									outputFormatString="YYYY-MM-DD HH:mm:ss"
									@update:model-value="
										onChangeDatePart(
											index,
											'from',
											$event,
											SHIFT_TYPES.awaken.key,
										)
									"
								/>
							</td>
							<td style="width: 180px !important">
								<modern-date-picker
									v-if="shift.isAwaken"
									:model-value="
										parsePartDate(shift, 'to', SHIFT_TYPES.awaken.key)
									"
									style="width: 180px"
									class="flex-center"
									:clearable="false"
									:enable-time-picker="true"
									:format="'dd/MM/yyyy HH:mm'"
									outputFormatString="YYYY-MM-DD HH:mm:ss"
									@update:model-value="
										onChangeDatePart(
											index,
											'to',
											$event,
											SHIFT_TYPES.awaken.key,
										)
									"
								/>
							</td>
						</template>
						<td>
							<div class="control">
								<input
									class="input"
									type="number"
									:value="shift.breakMinutes"
									:disabled="
										shift.type === SHIFT_TYPES.oncall.key ||
										shift.type === SHIFT_TYPES.sleepover.key
									"
									:style="{ width: '70px' }"
									@input="
										setBreakMinutes(
											index,
											($event.target as HTMLInputElement).value,
										)
									"
								/>
								<span class="mgl-5 is-suffix">in minutes</span>
							</div>
						</td>
						<td>
							<span
								v-if="shift.error && !shift.cancelledAt"
								class="error-text"
								style="
									display: block;
									word-wrap: break-word;
									white-space: normal;
									min-width: 150px;
								"
							>
								{{ shift.error }}
							</span>
							<span
								v-if="shift.success && !shift.cancelledAt"
								class="success-text"
								style="
									display: block;
									word-wrap: break-word;
									white-space: normal;
									min-width: 150px;
								"
							>
								{{ shift.success }}
							</span>
						</td>
					</tr>
				</tbody>
				<tbody v-if="localShifts.length === 0">
					<tr>
						<td>No data in table</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="buttons-centered">
			<button
				class="alt button is-outlined is-caps-lock"
				@click.prevent="emit('close-modal')"
			>
				{{ isAllShiftsSubmittedSuccessfully ? "Ok" : "Cancel" }}
			</button>
			<button
				v-if="!isAllShiftsSubmittedSuccessfully"
				class="button is-generic-app-blue is-caps-lock"
				:disabled="disableButton"
				@click.prevent="onSubmitShifts"
			>
				<img
					v-if="disableButton"
					class="btn-loader"
					src="@/assets/image/loader.svg"
				/>
				<span>Sign Off Shifts</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.multiple-wrap {
	width: 91vw;
	max-height: 80vh;
	padding: 20px;
	overflow: auto;
}

.is-suffix {
	white-space: nowrap;
}

// thead {
// 	position: sticky;
// 	top: 0;
// 	z-index: 1;
// }
</style>
<style lang="scss" scoped>
.table-wrapper {
	$text-color-lighter: $label-color;
	$scrollbar-color: $scrollbar-color;
	$scrollbar-color-thumb: $scrollbar-color-thumb;
	$table-header-background: #f2f7fa;
	$tr-hover-background: #f5f5f5;
	$td-text-color: #405168;

	--row-back: white;
	--row-back-odd: var(--vt-c-white-soft);

	display: flex;
	gap: 10px;
	width: 100%;
	// overflow: auto;

	&.overflow {
		overflow: auto;
	}

	@document url-prefix() {
		// Only for Firefox
		* {
			scrollbar-color: $scrollbar-color $scrollbar-color-thumb;
			scrollbar-width: thin;
		}
	}

	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color-thumb;
		border-radius: 5px;
	}

	table {
		overflow: auto;
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #dde6eb;
		td {
			font-size: 0.9rem;
			padding: 7px 10px;
			border-top: 1px solid #dde6eb;
			color: $td-text-color;
			&:not(.extra-row) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 300px;
			}
		}

		thead {
			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}
		}

		tbody {
			tr {
				height: 40px;
				&:hover {
					background: $tr-hover-background;
				}
			}

			&.has-extra-row {
				tr {
					&:nth-child(4n + 1),
					&:nth-child(4n + 2) {
						background-color: var(--row-back-odd);
					}
					&:hover {
						background: $tr-hover-background;
					}
				}
			}

			&:not(&.has-extra-row) {
				tr {
					&:nth-child(2n + 1) {
						background-color: var(--row-back-odd);
					}
					&:hover {
						background: $tr-hover-background;
					}
				}
			}
			.extra-row {
				height: unset;
			}
		}

		&.border {
			border: 1px solid gray;

			th,
			td {
				border: 1px solid gray;
			}
		}

		&.sticky {
			th {
				position: sticky;
				top: 0;
			}
		}
	}
}
</style>
