import api from "@/api";
export const usePasswordValidationStore = defineStore("password", () => {
	const passwordValidation = ref({
		length: { isValid: false },
		upperCase: { isValid: false },
		lowerCase: { isValid: false },
		number: { isValid: false },
		specialCharacter: { isValid: false },
	});

	async function checkPasswordValidate(data: string) {
		try {
			const params = { password: data };
			const res = await api.validatePassword(params);
			passwordValidation.value = res.data?.data?.passwordValidation;
			return res;
		} catch {}
	}

	return {
		passwordValidation,
		checkPasswordValidate,
	};
});
