<script setup lang="ts">
import { Errors } from "@/assets/js/errors";
// import auth from "../../lib/auth.js";
// import PasswordPolicy from "./PasswordPolicyValidation.vue";
import api from "@/api";
import { useToastStore } from "@/store/toast";

import { useSessionStore } from "@/store/session";
import PasswordPolicyValidation from "@/components/helper/PasswordPolicyValidation.vue";

const toast = useToastStore();
const sessionStore = useSessionStore();
// const instance = getCurrentInstance();
const passwordConformationMessage = `The "Save and close" button will become available once the password criteria is met and the identical password is entered in both fields.`;
// const isShowMessagePasswordConfirmation = ref(false);
const isHideMessagePasswordConfirmation = ref(true);
const submitDisabled = ref(false);
const emit = defineEmits(["close-modal"]);
const success = ref(false);
const settings = ref({
	name: null as string | null,
	email: null as string | null,
	current_password: null as string | null,
	password: null as string | null,
	password_confirmation: null as string | null,
	current_pin: null as string | null,
	pin: null as string | null,
	pin_confirmation: null as string | null,
	password_is_valid: false,
});

const errors = ref(new Errors());
const commonInputStyle = ["input", "is-medium", "is-fullwidth"];

watch(success, (newVal) => {
	if (newVal) {
		setTimeout(() => {
			callModalClose();
		}, 1000);
	}
});

onMounted(() => {
	console.log(sessionStore.userData);
	settings.value.name = sessionStore.userData?.name;
	settings.value.email = sessionStore.userData?.email;
});

const disableConfirm = computed(() => {
	// if (!settings.value.password) {
	// 	// isShowMessagePasswordConfirmation.value = false;
	// 	return false;
	// } else {
	// 	// isShowMessagePasswordConfirmation.value = true;
	// 	if (
	// 		!(
	// 			settings.value.password_is_valid &&
	// 			settings.value.password_confirmation === settings.value.password
	// 		)
	// 	) {
	// 		// isShowMessagePasswordConfirmation.value = true;
	// 		return true;
	// 	} else {
	// 		// isShowMessagePasswordConfirmation.value = false;
	// 		return false;
	// 	}
	// }

	return settings.value.password &&
		!(
			settings.value.password_is_valid &&
			settings.value.password_confirmation === settings.value.password
		)
		? true
		: false;
});

function handlePasswordIsValid(data: { isPasswordValid: boolean }) {
	settings.value.password_is_valid = data.isPasswordValid;
}

function clearErrors(e: Event) {
	const target = e.target as HTMLInputElement;
	const fieldHasError = errors.value.get(target.name);
	if (fieldHasError) {
		errors.value.clear(target.name);
	}
}
async function changeSettings() {
	submitDisabled.value = true;
	const data: Record<string, string | null | boolean> = {};
	const excludeKeys = new Set(["email"]);

	for (const setting of Object.keys(settings.value)) {
		const value = settings.value[setting as keyof typeof settings.value];
		if (value !== null && value !== "" && !excludeKeys.has(setting)) {
			data[setting] = value;
		}
	}

	if (!data.current_pin && data.pin) {
		toast.openToastError("Current pin invalid");
		submitDisabled.value = false;
		return;
	}
	if (
		(data.pin && !data.pin_confirmation) ||
		data.pin !== data.pin_confirmation
	) {
		submitDisabled.value = false;
		toast.openToastError("Confirmation pin invalid");
		return;
	}

	// auth.changeSettings(instance, data);
	if (sessionStore.isLoggedIn) {
		try {
			const res = await api.editProfileSettings(data);
			console.log(res);
			success.value = true;
			sessionStore.userData.name = res.data.data.name;
		} catch (error) {
			errors.value.record(error.response.data.errors);
			submitDisabled.value = false;
		}
	}
}

function callModalClose() {
	setTimeout(() => {
		emit("close-modal");
	}, 1500);
}

const can = sessionStore.can;
</script>

<template>
	<div class="user-settings-form">
		<p
			v-if="errors.any() && !success"
			class="notification is-danger"
		>
			There was an error, unable to process request
		</p>

		<p
			v-if="success"
			class="notification is-success"
		>
			Operation completed successfully.
		</p>

		<form
			autocomplete="off"
			@submit.prevent="changeSettings"
			@keydown="clearErrors"
		>
			<label class="label">Name</label>
			<p class="control">
				<!--  edit profile used to identify if user is temp -->
				<input
					v-model="settings.name"
					:class="[...commonInputStyle, { 'is-danger': errors.get('name') }]"
					:disabled="can('edit-profile')"
					type="text"
					name="name"
				/>
			</p>
			<span
				v-if="errors.has('name')"
				class="help is-danger"
				v-html="errors.get('name')"
			></span>

			<label class="label">Change password</label>
			<p class="control">
				<input
					v-model="settings.current_password"
					:class="commonInputStyle"
					type="password"
					name="current_password"
					placeholder="Current password"
				/>
				<span
					v-if="errors.has('current_password')"
					class="help is-danger"
					v-html="errors.get('current_password')"
				></span>
			</p>

			<p
				class="control"
				style="margin-bottom: 5px"
			>
				<input
					v-model="settings.password"
					:class="commonInputStyle"
					type="password"
					name="password"
					placeholder="Enter new password"
				/>
				<span class="validation-new-password">
					<PasswordPolicyValidation
						:passwordCharacters="settings.password"
						@password-valid="handlePasswordIsValid"
					/>
				</span>
				<!-- Uncomment and modify if needed
        <span class="help is-danger" v-if="!passwordValidationStore.passwordValidation.length.isValid">
          {{ passwordValidationStore.passwordValidation.length.label }}
        </span>
        -->
			</p>

			<p class="control">
				<input
					v-model="settings.password_confirmation"
					:class="commonInputStyle"
					type="password"
					name="password"
					placeholder="Confirm new password"
				/>
				<span
					v-if="errors.has('password')"
					class="help is-danger"
					v-html="errors.get('password')"
				></span>
			</p>
			<!-- edit profile used to identify if user is temp -->
			<template v-if="!sessionStore.can('edit-profile')">
				<label class="label">Change PIN</label>
				<p class="control">
					<input
						v-model="settings.current_pin"
						:class="commonInputStyle"
						type="password"
						name="current_pin"
						placeholder="Current PIN"
					/>
					<span
						v-if="errors.has('current_pin')"
						class="help is-danger"
						v-html="errors.get('current_pin')"
					></span>
				</p>

				<p class="control">
					<input
						v-model="settings.pin"
						:class="commonInputStyle"
						type="password"
						name="pin"
						placeholder="Enter new PIN"
					/>
				</p>

				<p class="control">
					<input
						v-model="settings.pin_confirmation"
						:class="commonInputStyle"
						type="password"
						name="pin_confirmation"
						placeholder="Confirm new PIN"
					/>
					<span
						v-if="errors.has('pin')"
						class="help is-danger"
						v-html="errors.get('pin')"
					></span>
				</p>
			</template>

			<label class="label">Email</label>
			<p class="control">
				<input
					:class="['is-disabled', ...commonInputStyle]"
					type="email"
					:value="settings.email"
					disabled
				/>
			</p>

			<p
				:class="{
					showMessagePasswordConfirmation: disableConfirm,
					hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation,
				}"
			>
				{{ passwordConformationMessage }}
			</p>

			<div class="buttons-centered">
				<button
					class="button is-small is-generic-app-blue is-caps-lock"
					:class="{ 'is-loading': submitDisabled }"
					type="submit"
					:disabled="disableConfirm || submitDisabled"
				>
					Save &amp; Close
				</button>
			</div>
		</form>
	</div>
</template>

<style lang="scss">
.user-settings-form {
	box-sizing: border-box;
	padding: 0 20px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 460px;
	// width: fit-content;
	min-height: 410px;
	gap: 10px;
	color: $text-color;

	form {
		width: 100%;
		input {
			width: 100%;
		}
		.control {
			margin-bottom: 11px;
		}
	}
	.hideMessagePasswordConfirmation {
		color: $error-color;
		display: none;
	}
	.showMessagePasswordConfirmation {
		color: $error-color;
		display: block;
		text-align: center;
		font-size: 0.96em;
	}
	// .control {
	//   .validation-new-password {
	//     color: #c2c2c2;
	//     display: flex;
	//     .correct {
	//       color: #2cb117;
	//     }
	//   }
	// }
	// .validation-password {
	//   color: red;
	// }
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}
</style>
