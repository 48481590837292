import TestModal from "../TEST/modal/TestModal.vue";
import UserSettingsForm from "../shared/modals/UserSettingsForm.vue";
import AvailabilityModal from "../shared/modals/AvailabilityModal.vue";
import { type IGlobalModal } from "../shared/modal/ModernModal.vue";
import { useModalStore } from "@/store/modal";
import PatternModal from "../shared/modals/PatternModal.vue";
import ZipBackpayModal from "../shared/modals/ZipBackpayModal.vue";
import ErrorsModal from "../shared/modals/ErrorsModal.vue";
import ZipInvoicesModal from "../shared/modals/ZipInvoicesModal.vue";
import CreateInvoiceModal from "../shared/modals/CreateInvoiceModal.vue";
import AnnualLeaveReleaseModal from "../shared/modals/AnnualLeaveReleaseModal.vue";
import GlobalConfirm from "../shared/modals/GlobalConfirm.vue";
import CorrectionsMessage from "../shared/modals/CorrectionsMessage.vue";
import ReportPreview from "../shared/modals/reports/ReportPreview.vue";
import ChangeStatus from "../shared/modals/userManagement/ChangeStatus.vue";
import ManageRegionsModal from "../shared/modals/userManagement/ManageRegionsModal.vue";
import EditClientStatus from "../shared/modals/clientManagement/EditClientStatus.vue";
import DefaultShiftTimes from "../shared/modals/clientManagement/DefaultShiftTimes.vue";
import EditBreakTimes from "../shared/modals/clientManagement/EditBreakTimes.vue";
import EditShiftConfirmInterval from "../shared/modals/clientManagement/EditShiftConfirmInterval.vue";
import ChangeAutoRepush from "../shared/modals/clientManagement/ChangeAutoRepush.vue";
import NewClient from "../shared/modals/clientManagement/NewClient.vue";
import FinancePageRedirect from "../LocationClientManagement/FinancePageRedirect.vue";
import ClientContactEdit from "../shared/modals/clientManagement/ClientContactEdit.vue";
import ClientContactAdd from "../shared/modals/clientManagement/ClientContactAdd.vue";
import ClientCopyCostCenter from "../shared/modals/clientManagement/ClientCopyCostCenter.vue";
import ClientContactConfirmAdd from "../shared/modals/clientManagement/ClientContactConfirmAdd.vue";
import ClientContactConfirmEdit from "../shared/modals/clientManagement/ClientContactConfirmEdit.vue";
import ExpenseDetailsModal from "../shared/modals/ExpenseDetailsModal.vue";
import ExpenseSignOffModal from "../shared/modals/ExpenseSignOffModal.vue";
import JournalCheckNoteFiles from "../shared/modals/clientManagement/JournalCheckNoteFiles.vue";
import JournalEntryDetails from "../shared/modals/clientManagement/JournalEntryDetails.vue";
// const addUser = import("../../pages/admin/EditUser.vue");
import addUser from "../../pages/admin/EditUser.vue";

import NotificationCenterParsedHtmlModal from "../shared/modals/NotificationCenterParsedHtmlModal.vue";
import NotificationCenterListOfWorkersModal from "../shared/modals/NotificationCenterListOfWorkersModal.vue";
import TempComplianceSetExpiryModal from "../shared/modals/TempComplianceSetExpiryModal.vue";
import TempComplianceUploadDocumentsModal from "../shared/modals/TempComplianceUploadDocumentsModal.vue";
import TempComplianceRejectDocumentModal from "../shared/modals/TempComplianceRejectDocumentModal.vue";
import TempComplianceCheckFilesModal from "../shared/modals/TempComplianceCheckFilesModal.vue";
import NextOfKinModal from "../shared/modals/NextOfKinModal.vue";
import RateManagementBulkInputModal from "../shared/modals/RateManagementBulkInputModal.vue";
import TimeWarning from "../shared/modals/TimeWarning.vue";
import RateManagementFinalizeModal from "../shared/modals/RateManagementFinalizeModal.vue";
import EditTempStatusModal from "../shared/modals/EditTempStatusModal.vue";
import type { TCategory, TIncrement, TTemp } from "@/assets/js/globalTypes";
import ExpensesRateManagementFinalizeModal from "../shared/modals/ExpensesRateManagementFinalizeModal.vue";
import CreatePublicHolidayModal from "../shared/modals/publicHolidays/CreatePublicHolidayModal.vue";
import CopyPublicHolidaysModal from "../shared/modals/publicHolidays/CopyPublicHolidaysModal.vue";
import EditPublicHolidayModal from "../shared/modals/publicHolidays/EditPublicHolidayModal.vue";
import ManageTempModal from "../shared/modals/shifts/ManageTempModal.vue";
import ManageRepushForTempsModal from "../shared/modals/shifts/ManageRepushForTempsModal.vue";
import EditTimeModal from "../shared/modals/shifts/EditTimeModal.vue";
import AwaitingDialogModal from "../shared/modals/shifts/AwaitingDialogModal.vue";
import BeforeRecalculateModal from "../shared/modals/shifts/BeforeRecalculateModal.vue";
import AfterRecalculateModal from "../shared/modals/shifts/AfterRecalculateModal.vue";
import CancellationReasonModal from "../shared/modals/shifts/CancellationReasonModal.vue";
import CancellationMessagesCheckModal from "../shared/modals/shifts/CancellationMessagesCheckModal.vue";
import MultipleCancellationModal from "../shared/modals/shifts/MultipleCancellationModal.vue";
import MultipleSignOffModal from "../shared/modals/shifts/MultipleSignOffModal.vue";
import ShiftScheduleInvoiceErrorsModal from "../shared/modals/shifts/ShiftScheduleInvoiceErrorsModal.vue";
import ShiftConfirmDialogModal from "../shared/modals/shifts/ShiftConfirmDialogModal.vue";
import ShiftDetailsRoundModal from "../shared/modals/shifts/ShiftDetailsRoundModal.vue";
import LocationPrefModal from "../shared/modals/allCandidates/LocationPrefModal.vue";
import ConfirmBooking from "../calendar/ConfirmBooking.vue";
import AnnualLeaveRequestModal from "../shared/modals/candidatePage/AnnualLeaveRequestModal.vue";
import CandidateIncrementModal from "../shared/modals/candidatePage/CandidateIncrementModal.vue";
import RegisterNewTempModal from "../shared/modals/allCandidates/RegisterNewTempModal.vue";
import ConfrimRehireModal from "../shared/modals/allCandidates/ConfrimRehireModal.vue";

function openModal(
	comp: any,
	config?: Record<string, any>,
	formStyle?: Record<string, any>,
) {
	const modalStore = useModalStore();
	modalStore.updateModalData({
		comp,
		config,
		formStyle,
	} as IGlobalModal);
}

// Test modal
export function openTestModal(payload = {}) {
	const config = {
		title: "Test modal",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;

	openModal(TestModal, config);
}

export function openUserSettingsForm(payload = {}) {
	const config = {
		title: "Settings",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(UserSettingsForm, config);
}

export function openAvailabilityModal(payload = {}, onConfirm: any) {
	const config = {
		title: "Availability Settings",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(AvailabilityModal, config);
}
export function openPatternModal(payload, onConfirm) {
	const config = {
		title: "Manage pattern",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(PatternModal, config);
}

export function openZipBackpayModal(payload) {
	const config = {
		title: "Downloading...",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ZipBackpayModal, config);
}

export function openConfirmModal(payload, onConfirm) {
	const config = {
		title: payload?.title ?? "Please confirm",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(GlobalConfirm, config);
}

export function openRateManagementBulkInputModal(payload) {
	const config = {
		title: payload?.title ?? "Bulk input",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(RateManagementBulkInputModal, config);
}

export function openExpensesRateManagementFinalizeModal(payload) {
	const config = {
		title: payload?.title ?? "Finalize and apply changes",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ExpensesRateManagementFinalizeModal, config);
}

export function openShiftDetailsRoundModal(payload) {
	const config = {
		title: payload?.title ?? "Round 3 Quote Builder",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ShiftDetailsRoundModal, config);
}

export function openShiftConfirmDialogModal(payload) {
	const config = {
		title: payload?.title ?? "Shift confirm dialog",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ShiftConfirmDialogModal, config);
}

export function openShiftScheduleInvoiceErrorsModal(payload) {
	const config = {
		title: payload?.title ?? "Shift invoice statuses",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ShiftScheduleInvoiceErrorsModal, config);
}

export function openMultipleCancellationModal(payload) {
	const config = {
		title: payload?.title ?? "Multiple Cancellation",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(MultipleCancellationModal, config);
}

export function openMultipleSignOffModal(payload) {
	const config = {
		title: payload?.title ?? "Multiple Sign Off",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(MultipleSignOffModal, config);
}

export function openManageTempModal(payload) {
	const config = {
		title: payload?.title ?? "Edit Temp Name",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ManageTempModal, config);
}

export function openManageRepushForTempsModal(payload) {
	const config = {
		title: payload?.title ?? "Select agency workers",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ManageRepushForTempsModal, config);
}

export function openEditTimeModal(payload) {
	const config = {
		title: payload?.title ?? "Edit Time",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(EditTimeModal, config);
}

export function openAwaitingDialogModal(title, payload) {
	const config = {
		title: title ?? "Awaiting candidate",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(AwaitingDialogModal, config);
}

export function openBeforeRecalculateModal(payload) {
	const config = {
		title: payload?.title ?? "",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(BeforeRecalculateModal, config);
}

export function openAfterRecalculateModal(payload) {
	const config = {
		title: payload?.title ?? "",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(AfterRecalculateModal, config);
}

export function openCancellationReasonModal(payload) {
	const config = {
		title: payload?.title ?? "Cancellation Reason",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CancellationReasonModal, config);
}

export function openCancellationMessagesCheckModal(payload) {
	const config = {
		title: payload?.title ?? "Warning",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CancellationMessagesCheckModal, config);
}

export function openRateManagementFinalizeModal(payload) {
	const config = {
		title: payload?.title ?? "Finalize and activate rates",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(RateManagementFinalizeModal, config);
}

export function openCreatePublicHolidayModal(payload) {
	const config = {
		title: payload?.title ?? "Create a Public Holiday",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CreatePublicHolidayModal, config);
}

export function openCopyPublicHolidaysModal(payload) {
	const config = {
		title: payload?.title ?? "Copy Public Holidays",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CopyPublicHolidaysModal, config);
}

export function openEditPublicHolidayModal(payload) {
	const config = {
		title: payload?.title ?? "Edit Public Holiday",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(EditPublicHolidayModal, config);
}

export function openDeleteModal(payload, onConfirm) {
	const config = {
		title: "Delete" + (payload.entity || ""),
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(GlobalConfirm, config);
}

export function openGlobalConfirmModal(payload, onConfirm) {
	const config = {
		title: payload?.title ?? "Confirm",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(GlobalConfirm, config);
}

export function openCorrectionsMessage(payload, onConfirm: any) {
	const config = {
		title: "Confirm",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CorrectionsMessage, config);
}

export function openReportPreview(payload = {}, onConfirm: any) {
	const config = {
		title: "Report Preview",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ReportPreview, config);
}

export function openChangeStatus(payload = {}, onConfirm: any) {
	const config = {
		title: "User's status",
		slotProps: {
			onCloseModal: () => {},
			user: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ChangeStatus, config);
}

export function openEditClientStatus(payload = {}, onConfirm: any) {
	const config = {
		title: "Client's status",
		slotProps: {
			onCloseModal: () => {},
			client: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(EditClientStatus, config);
}

export function openErrorsModal(payload) {
	const config = {
		title: payload?.title ?? "",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ErrorsModal, config);
}

export function openZipInvoicesModal(payload) {
	const config = {
		title: "Downloading...",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ZipInvoicesModal, config);
}

export function openCreateInvoiceModal(payload) {
	const config = {
		title: "Create Invoice",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CreateInvoiceModal, config);
}

export function openAnnualLeaveReleaseModal(payload) {
	const config = {
		title: "Release premium holiday pay",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(AnnualLeaveReleaseModal, config);
}

export function openManageRegionsModal(payload: any, onConfirm: any) {
	const config = {
		title: "Manage Regions",
		slotProps: {
			onCloseModal: () => {},
			user: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ManageRegionsModal, config);
}

export function openAddUser(payload = {}, onConfirm: any) {
	const config = {
		title: "Create new user",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(addUser, config);
}

export function openDefaultShiftTimes(payload = {}, onConfirm: any) {
	const config = {
		title: "Default Shifts",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(DefaultShiftTimes, config);
}

export function openEditBreakTimes(payload = {}) {
	const config = {
		title: "Break times",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(EditBreakTimes, config);
}
export function openEditShiftConfirmInterval(payload = {}) {
	const config = {
		title: "Shift confirm interval",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(EditShiftConfirmInterval, config);
}

export function openExpenseDetailsModal(payload) {
	const config = {
		title: "Expense Details",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ExpenseDetailsModal, config);
}

export function openExpenseSignOffModal(payload) {
	const config = {
		title: "Sign Off",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ExpenseSignOffModal, config);
}

export function openChangeAutoRepush(payload = {}) {
	const config = {
		title: "Auto repush",
		slotProps: {
			onCloseModal: () => {},
			client: { ...payload },
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ChangeAutoRepush, config);
}
export function openNewClient() {
	const config = {
		title: "New Client Form",
		slotProps: {
			onCloseModal: () => {},
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(NewClient, config);
}

export function openFinancePageRedirect(payload = {}) {
	const config = {
		title: "New Client Form",
		transition: null,
		slotProps: {
			onCloseModal: () => {},
			clientData: payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(FinancePageRedirect, config);
}

export function openClientContactEdit(payload = {}, onConfirm: any) {
	const config = {
		title: "Edit a contact",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ClientContactEdit, config);
}
export function openClientContactAdd(payload = {}, onConfirm: any) {
	const config = {
		title: "Create a new contact",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ClientContactAdd, config);
}
export function openClientCopyCostCenter(payload = {}) {
	const config = {
		title: "Copy Ward",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ClientCopyCostCenter, config);
}

export function openClientContactConfirmAdd(payload = {}, onConfirm: any) {
	const config = {
		title: "Create a new shift confirmation contact",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ClientContactConfirmAdd, config);
}
export function openClientContactConfirmEdit(payload = {}, onConfirm) {
	const config = {
		title: "Edit a contact",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ClientContactConfirmEdit, config);
}

export function openJournalCheckNoteFiles(payload = {}, onConfirm) {
	const config = {
		title: "Check note files",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(JournalCheckNoteFiles, config);
}

export function openJournalEntryDetails(payload = {}) {
	const config = {
		title: "Journal entry details",
		slotProps: {
			onCloseModal: () => {},
			modalProps: { ...payload },
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(JournalEntryDetails, config);
}
export function openParsedHtmlModal(payload) {
	const config = {
		title: payload?.title ?? "Content",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(NotificationCenterParsedHtmlModal, config);
}

export function openNotificationCenterListOfWorkersModal(payload) {
	const config = {
		title: payload?.title ?? "List of workers",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(NotificationCenterListOfWorkersModal, config);
}

export function openTempComplianceSetExpiryModal(payload, onConfirm) {
	const config = {
		title: payload?.title ?? "Set New Expiry Date",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(TempComplianceSetExpiryModal, config);
}

export function openTempComplianceUploadDocumentsModal(payload, onConfirm) {
	const config = {
		title: payload?.title ?? "Upload temp documents",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(TempComplianceUploadDocumentsModal, config);
}

export function openTempComplianceRejectDocumentModal(
	payload,
	onConfirm,
	onSelectRejectReason,
) {
	const config = {
		title: payload?.title ?? "Reject Temp Document",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
			onSelectRejectReason,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(TempComplianceRejectDocumentModal, config);
}

export function openTempComplianceCheckFilesModal(payload, onConfirm) {
	const config = {
		title: payload?.title ?? "Check Temp Documents",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(TempComplianceCheckFilesModal, config);
}

export function openNextOfKinModal(payload) {
	const config = {
		title: "Next of kin management",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(NextOfKinModal, config);
}
export function openTimeWarning(onConfirm: any) {
	const config = {
		title: "",
		slotProps: {
			onCloseModal: () => {},
			// ...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(TimeWarning, config);
}
export function openConfirmBooking(payload: any, onConfirm: any) {
	const config = {
		title: "Confirm booking shift",
		slotProps: {
			onCloseModal: () => {},
			// ...payload,
			shift: payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ConfirmBooking, config);
}

export function openEditTempStatusModal(
	payload: { dnrStatus: number; tempData: Partial<TTemp> },
	onConfirm,
) {
	const config = {
		title: "Edit Temp Status",
		slotProps: {
			onCloseModal: () => {},
			...payload,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(EditTempStatusModal, config);
}

export function openLocationPrefModal(payload: {
	tempId: number;
	initialSectors?: any[];
	manageAction?: boolean;
	onConfirm?: () => void;
}) {
	const config = {
		title: "Location Preferences",
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(LocationPrefModal, config);
}

export function openAnnualLeaveRequestModal(onConfirm: (a: any) => any) {
	const config = {
		title: "Create Adjustment/Write Off/AL Request",
		slotProps: {
			onCloseModal: () => {},
			onConfirm,
		},
	};
	openModal(AnnualLeaveRequestModal, config);
}

export function openCandidateIncrementModal(payload: {
	title: string;
	onRefresh?: (a: any) => any;
	clickedIncrement?: TIncrement;
	tempIncrements?: TIncrement[];
	tempId: number;
	category?: TCategory;
}) {
	const config = {
		title: payload.title,
		slotProps: {
			onCloseModal: () => {},
			...payload,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(CandidateIncrementModal, config);
}

export function openRegisterNewTempModal(onConfirm: (a: any) => any) {
	const config = {
		title: "Register new temp",
		slotProps: {
			onCloseModal: () => {},
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(RegisterNewTempModal, config);
}

export function openRehireTempModal({ tempId, tempInfo, onConfirm }) {
	const config = {
		title: "Details Summary",
		slotProps: {
			onCloseModal: () => {},
			tempInfo,
			tempId,
			onConfirm,
		},
	} as Partial<IGlobalModal["config"]>;
	openModal(ConfrimRehireModal, config);
}
