<script setup lang="ts">
import api from "@/api";
import type { THoliday } from "@/assets/js/globalTypes";
import { removeSubstring } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

defineProps<{
	warnMsg: string;
}>();
const toastStore = useToastStore();
const emit = defineEmits(["cancel-modal", "ph-updated", "close-modal"]);
const phParams = ref<THoliday>({
	hld_date: "",
	hld_name: "",
});
const submitClicked = ref<boolean>(false);
const errors = ref<string | string[] | null>(null);
async function onSubmit() {
	submitClicked.value = true;
	try {
		const res = await api.postCreatePublicHoliday(phParams.value);
		emit("ph-updated", phParams.value.hld_date);
		toastStore.openToastSuccess(res.data.message);
		isModalOpen.value = false;
		initialModalInModalOpen.value = false;
		setTimeout(() => {
			submitClicked.value = false;
			closeModal();
		}, 300);
	} catch (err) {
		errors.value = parseErrors(err);
		if (Array.isArray(errors.value) && errors.value.length > 0) {
			for (const [index, err] of errors.value.entries()) {
				errors.value[index] = removeSubstring(err, "hld ");
				toastStore.openToastError(errors.value[index]);
			}
		} else if (errors.value) {
			toastStore.openToastError(
				removeSubstring(errors.value as string, "hld "),
			);
		}
		isModalOpen.value = false;
		initialModalInModalOpen.value = false;
		submitClicked.value = false;
	}
}
function closeModal() {
	emit("close-modal");
}
const createButtonDisabled = computed(() => {
	return (
		submitClicked.value ||
		phParams.value.hld_date.length === 0 ||
		phParams.value.hld_name.length === 0
	);
});
function confirmCreate() {
	isModalOpen.value = true;
}
const isModalOpen = ref<boolean>(false);
const initialModalInModalOpen = ref<boolean>(false);
</script>

<template>
	<div class="rates-change-value-modal">
		<modal-in-modal
			:isModalOpen="isModalOpen"
			:initialModalInModalOpen="initialModalInModalOpen"
			:confirmMessage="
				warnMsg ?? 'Changing holidays may affect shift calculations.'
			"
			@confirm-action="onSubmit"
			@close-modal="isModalOpen = false"
			@initial-model-open="initialModalInModalOpen = isModalOpen"
		/>
		<div class="inner-content">
			<ul>
				<li>
					Please type the name of the public holiday you wish to create. Then,
					select a date from the date picker. You cannot create a public holiday
					on a date which is already set up as public holiday in the system.
				</li>
			</ul>
		</div>

		<div class="flex-center mgt-10">
			<div>
				<label class="label label-color">Name</label>
				<div>
					<input
						v-model="phParams.hld_name"
						type="text"
						placeholder="Please enter holiday name"
						maxlength="170"
						class="wd-250"
						@focus="errors = null"
					/>
				</div>
			</div>
			<div>
				<label class="label pdl-5 label-color">Date</label>
				<modern-date-picker
					v-model="phParams.hld_date"
					:enable-time-picker="false"
					class="wd-250"
					:textInput="true"
					:clearable="false"
					style="margin: 0 3px; font-size: 0.85em"
					placeholder="Select Date"
					:format="'dd/MM/yyyy'"
					:outputFormatString="'YYYY-MM-DD'"
					auto-apply
					@focus="errors = null"
				/>
			</div>
		</div>
		<div class="flex-center">
			<transition name="slide-fade">
				<div v-if="errors">
					<div v-if="Array.isArray(errors)">
						<p
							v-for="(msg, idx) in errors"
							:key="idx"
							class="error-text"
						>
							{{ msg }}
							<br />
						</p>
					</div>
					<p
						v-else-if="typeof errors === 'string'"
						class="error-text"
					>
						{{ errors }}
					</p>
				</div>
			</transition>
		</div>
		<div class="buttons-centered mgb-20">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				type="submit"
				:disabled="createButtonDisabled"
				:class="{
					'is-loading': submitClicked,
					'not-allowed': createButtonDisabled,
				}"
				@click.prevent="confirmCreate"
			>
				Submit
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.rates-change-value-modal {
	max-height: 500px;
	width: 620px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 5px 10px;

	ul {
		// list-style-type: disc;
		// padding-left: 20px;
		padding-left: 5px;
		color: $label-color;
	}

	li {
		margin: 5px 0; /* Add spacing between list items */
	}

	.caret {
		transition: transform 0.3s ease;
	}

	.caret-up {
		transform: rotate(180deg);
	}

	.caret-up-rotated {
		transform: rotate(0deg);
	}
	.inner-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 10px;

		.action {
			display: flex;
			overflow: auto;
			max-height: 350px;
			gap: 10px;

			.checkbox-complete {
				overflow: auto;
				padding-right: 10px;
			}

			.cats {
				display: flex;
				flex-direction: column;
				gap: 5px;
				flex: 1;
			}

			.input-section {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}
}
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateY(20px);
	opacity: 0;
}
</style>
