<script lang="ts" setup>
import api from "@/api";
import { REPUSH_STATUS } from "@/assets/js/componentsConfig/shifts";
import { debounce } from "@/assets/js/helpers";
import { SHIFT_TYPES } from "@/assets/js/mapData";
import { parseErrors } from "@/assets/js/parseErrors";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
const shiftsStore = useShiftsStore();
const props = defineProps<{
	modalProps: {
		shift: any;
		index?: number;
	};
}>();
const emit = defineEmits(["close-modal", "is-save-loading"]);

const shift = ref<any>(null);
const isLoading = ref<boolean>(false);
const isButtonDisabled = ref<boolean>(false);
const selectedForPush = ref<any>([]);
const regardlessAvailability = ref<number>(2);
const searchField = ref<any>(null);

const tempsForShift = ref<any>([]);
async function fetchTempsForShift(params) {
	try {
		// get temps per shift (REPUSH action)
		const response = await api.fetchTempsForShift(params.id, params);
		// commit changed shift object into state (replace shift object)
		tempsForShift.value = response.data?.data;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}

async function fetchTemps(id) {
	isLoading.value = true;
	try {
		await fetchTempsForShift({
			id: shift.value.id,
			availability: regardlessAvailability.value,
			name: searchField.value,
			has_on_call: props.modalProps.shift.type === SHIFT_TYPES.oncall.key,
			has_sleepover: props.modalProps.shift.type === SHIFT_TYPES.sleepover.key,
			shift_type: props.modalProps.shift.type,
		});
		isLoading.value = false;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		isLoading.value = false;
	}
}
function closeModal() {
	emit("close-modal");
}
const searchWorkers = debounce(
	async () => {
		try {
			await fetchTempsForShift({
				id: shift.value.id,
				availability: regardlessAvailability.value,
				name: searchField.value,
				has_on_call: props.modalProps.shift.type === SHIFT_TYPES.oncall.key,
				has_sleepover:
					props.modalProps.shift.type === SHIFT_TYPES.sleepover.key,
				shift_type: props.modalProps.shift.type,
			});
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
		}
	},
	400,
	false,
);
function tempStatus(status) {
	const statusObj = REPUSH_STATUS.find(
		(statusObj) => statusObj.status === status,
	);
	if (statusObj) {
		return {
			hex: statusObj.hex,
			label: statusObj.label,
		};
	}
	return {
		hex: "",
		label: "",
	};
}
async function push() {
	isButtonDisabled.value = true;
	// push to checked temps
	// const temps = selectedForPush.value.map((id) => ({ id: id }));

	try {
		await shiftsStore.patchShift(
			props.modalProps.shift.id,
			{
				// IMPORTANT NOTE if null is sent as temp_id, that actually deletes the temp assigned for this shift
				temp_id: selectedForPush.value[0] || null,
			},
			props.modalProps?.index,
		);
		toastStore.openToastSuccess("Success adding a temp to this shift");
		isButtonDisabled.value = false;
		emit("is-save-loading", false);
		closeModal();
		return true;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		isButtonDisabled.value = false;
		emit("is-save-loading", false);
		return false;
	}
}
const selectedForPushBool = (temp) => {
	return (
		selectedForPush.value.length === 0 ||
		selectedForPush.value.includes(temp.id)
	);
};
onMounted(() => {
	shift.value = props.modalProps.shift;
	fetchTemps(shift.value.id);
});
// const temp = [
// 	{
// 		id: 8607,
// 		name: "Kinez kinez1 kinez1 (adasd)",
// 		first_name: "Kinez kinez1",
// 		last_name: "kinez1",
// 		is_temp_preferred: null,
// 	},
// ];
</script>
<template>
	<div class="dialog-container temp-shift-name-manage">
		<div class="action-bar">
			<input
				v-model="searchField"
				class="input wd-100-p"
				placeholder="Search by name"
				title="Search by name"
				type="text"
				@input="searchWorkers"
			/>
		</div>
		<div class="inputs">
			<div class="field">
				<p class="control"></p>
			</div>
		</div>
		<div class="table-wrap">
			<table class="table is-striped">
				<thead>
					<tr>
						<th>Select</th>
						<th>Name</th>
						<th>Response status</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="!isLoading && tempsForShift.length !== 0">
						<tr
							v-for="temp in tempsForShift"
							:key="temp.id"
						>
							<td>
								<input
									v-if="selectedForPushBool"
									v-model="selectedForPush"
									type="checkbox"
									:value="temp.id"
								/>
							</td>
							<td :class="{ preferred: temp.is_temp_preferred == 1 }">
								{{ temp.name }}
							</td>
							<td>
								<span
									class="generic-app-tag"
									:style="{ backgroundColor: tempStatus(temp.response).hex }"
								>
									{{ tempStatus(temp.response).label || "-" }}
								</span>
							</td>
						</tr>

						<!-- <tr
							v-for="temp in 20"
							:key="temp.id"
						>
							<td>
								<input
									v-if="selectedForPushBool"
									v-model="selectedForPush"
									type="checkbox"
									:value="temp.id"
								/>
							</td>
							<td :class="{ preferred: temp.is_temp_preferred == 1 }">
								{{ temp.name }}
							</td>
							<td>
								<span
									class="generic-app-tag"
									:style="{ backgroundColor: tempStatus(temp.response).hex }"
								>
									{{ tempStatus(temp.response).label || "-" }}
								</span>
							</td>
						</tr> -->
					</template>
					<tr v-if="!isLoading && !tempsForShift.length">
						<td
							colspan="3"
							class="text-centered"
						>
							No Available temps for this shift
						</td>
					</tr>
					<tr v-if="isLoading">
						<td colspan="3">
							<img
								class="mg-auto"
								src="@/assets/image/loader.svg"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="buttons-centered">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				:disabled="isButtonDisabled"
				@click.prevent="push"
			>
				Save
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$generic-app-tag: #dbe1e5;
$tr-hover-background: #f5f5f5;
$table-header-background: #f2f7fa;
$td-text-color: #405168;
.generic-app-tag {
	background-color: $generic-app-tag;
	border-radius: 3px;
	padding: 0.2em 0.5em;
}
.temp-shift-name-manage {
	min-height: 320px;
	display: flex;
	flex-direction: column;
}
.table-wrap {
	max-height: 50vh;
	width: 610px;
	overflow: auto;
}

.modal-content {
	overflow: visible;
}
table {
	overflow: auto;
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #dde6eb;
	th {
		color: $text-color-lighter;
		background: $table-header-background;
		text-align: left;
		padding: 7px 10px;
		z-index: 1;
		border-top: 1px solid #dde6eb;
		overflow: visible !important;
	}
	td {
		font-size: 0.9rem;
		padding: 7px 10px;
		border-top: 1px solid #dde6eb;
		color: $td-text-color;
		overflow: visible !important;
		&:not(.extra-row) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
		.flexer {
			display: flex;
			align-items: center;

			span {
				display: flex;
				align-items: center;
			}
			span:first-child {
				margin-right: auto;
			}
		}
	}
}
</style>
<style lang="scss" scoped>
.dialog-container {
	width: 100%;
	padding: 20px;
}

label {
	margin-top: 0.5em;
}

.action-bar {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 10px;

	label {
		margin: 0;
	}
}

.inputs {
	display: flex;
	justify-content: center;

	.field {
		width: 100%;
	}
}

.buttons-centered {
	margin-top: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}

td.preferred {
	font-weight: bold;
}
</style>
