<script setup lang="ts">
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";
import { Errors } from "@/assets/js/errors";
import type { TCategory, TIncrement } from "@/assets/js/globalTypes";
import { findRecordErrors, parseErrors } from "@/assets/js/parseErrors";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useTempStore } from "@/store/temp";
import { useToastStore } from "@/store/toast";

const emit = defineEmits<{
	"close-modal": [void];
	refresh: [void];
}>();

const store = useDefaultStore();
const toastStore = useToastStore();
const sessionStore = useSessionStore();

const errors = ref<Errors>(new Errors());

const gPermissions = computed(() => sessionStore.gPermissions);
const canUseIncrementCo = computed(
	() => store.initialStatus?.can_use_increment_counter,
);

const props = defineProps<{
	tempIncrements?: TIncrement[];
	category?: TCategory;
	clickedIncrement?: TIncrement;
}>();

const incrementWeekList = ref([]);

const newPendingIds = ref({
	cat: null,
	sub: null,
});

const subCatId = computed(
	() => props.clickedIncrement?.category?.id || newPendingIds.value.sub || null,
);
const categoryId = computed(
	() =>
		props.clickedIncrement?.category?.parent_id ||
		newPendingIds.value.cat ||
		null,
);

const apiLoading = ref(false);

const allIncrementsPoints = ref([]);

const showDeleted = ref(false);

const tempStore = useTempStore();
const temp = computed(() => tempStore.getFullProfile);

const currentIncremetData = computed(() => {
	if (subCatId.value) {
		const catInt = temp.value.increments.filter(
			(el) =>
				subCatId.value === el.category.id &&
				(!el.deletedAt || showDeleted.value),
		);
		return catInt;
	}
	return [];
});

const newRow = ref({
	startDate: "",
	name: "",
	createdAt: "",
	deletedAt: "",
	new: true,
});

const incrementsListTable = computed(() => {
	const res = currentIncremetData.value.map((el) => ({
		...el,
		_style: {
			backgroundColor: el.deletedAt ? "#f1f1f1" : "#fff",
		},
	}));
	res.sort((a, b) => {
		if (a.startDate < b.startDate) {
			return 1;
		} else if (b.startDate < a.startDate) {
			return -1;
		}
		return 0;
	});
	return [newRow.value, ...res];
});

const isModalSubCatName = computed(() => {
	const originalName = props.category && props.category.name;
	return originalName || incrementsListTable.value.length > 1;
});

async function fetchIncrementWeeksRange() {
	try {
		const res = await api.getIncrementWeeksCounter();
		const { from, to } = res.data.increment_counter_range || {};
		const leng = to - from + 1;
		incrementWeekList.value = Array.from({ length: leng }).map((val, ind) => {
			return {
				subcategory_id: subCatId.value,
				weeks_counter: ind + from + "",
			};
		});
	} catch (err) {
		console.warn(err.message);
	}
}
const loadingWeeksCoutnerValue = ref(false);
const incrementWeekValue = ref(null);
async function fetchIncrementWeeksCounterValue() {
	try {
		loadingWeeksCoutnerValue.value = true;
		const params = {
			id: temp.value.id,
			subcategory_id: subCatId.value,
		};
		const res = await api.getIncrementWeeksCounterValue(params);
		const { data } = res.data;
		incrementWeekValue.value = data;
		if (incrementWeekValue.value.length) {
			selectedIncrementWeek.value = incrementWeekList.value.find(
				(el) =>
					Number(el.weeks_counter) ===
					Number(incrementWeekValue.value[0].weeks_counter),
			);
		}
	} catch (err) {
		console.warn(err.message);
	} finally {
		loadingWeeksCoutnerValue.value = false;
	}
}

const optionsSubcategories = computed(() =>
	store.getCategoriesWithSubcategories(),
);

const selectedSubcategories = ref(null);
watch(
	selectedSubcategories,
	(val) => {
		newPendingIds.value = {
			cat: val && val.catParentId,
			sub: val && val.id,
		};
		if (newPendingIds.value.cat) {
			fetchIncrementsPoints(newPendingIds.value.cat);
		} else {
			// Clear increments
			newPendingIds.value = {
				cat: null,
				sub: null,
			};
			allIncrementsPoints.value = [];
		}
	},
	{ deep: true },
);

async function fetchIncrementsPoints(catId) {
	try {
		const params = {
			category_id: catId,
		};
		const res = await api.getIncrements(params);
		const { data } = res.data;
		allIncrementsPoints.value = data;
	} catch (err) {
		console.warn(err.message);
	}
}

const modalSubcategoryLabel = computed(() => {
	const hasOutCat = props.category && props.category.name;
	const selSubName =
		selectedSubcategories.value && selectedSubcategories.value.name;
	if (selSubName) {
		return selSubName;
	} else if (hasOutCat) {
		return hasOutCat;
	}
	return "";
});

async function removeSubcategory() {
	apiLoading.value = true;
	try {
		for (const inc of currentIncremetData.value) {
			const params = {
				incrementTempId: inc.incrementTempId,
				temp_id: temp.value.id,
			};
			await api.deleteIncrementTemp(params);
		}
		emit("refresh");
		emit("close-modal");
	} catch (err) {
		if (err.response?.data?.message) {
			toastStore.openToastError(err.response.data.message);
		} else {
			toastStore.openToastError(err.message);
		}
		console.warn(err.message);
	} finally {
		apiLoading.value = false;
	}
}

const table_columns = ref([
	{
		id: "startDate",
		label: "EFFECTIVE DATE",
	},
	{
		id: "name",
		label: "INCREMENT POINT",
	},
	{
		id: "createdAt",
		label: "CREATED AT",
	},
	{
		id: "deletedAt",
		label: "DELETED AT",
	},
	{
		id: "action",
		label: "ACTION",
	},
]);

const newPending = ref({
	incrementPoint: null,
	startDate: null,
});

function createdByText(incObjPerson, actText) {
	if (incObjPerson && typeof incObjPerson === "object") {
		return `${actText} by: ${
			incObjPerson.name || ""
		} [${incObjPerson.email || ""}]`;
	}
}

const modalInModalOpen = ref(false);
const confirmText = ref("test");

let action:
	| "add"
	| "delete"
	| "update"
	| "update-category"
	| "reset"
	| "delete-sub" = null;

let incrementToDelete = null;
function confrimDelete(row) {
	if (apiLoading.value) {
		return;
	}
	incrementToDelete = row;
	modalInModalOpen.value = true;
	confirmText.value = "Are you sure you want to delete this increment";
	action = "delete";
}

function confirmAddNewIncrement() {
	if (
		!newPending.value.startDate ||
		!newPending.value.incrementPoint ||
		apiLoading.value
	) {
		return;
	}
	modalInModalOpen.value = true;
	confirmText.value = "Are you sure you want to create a new increment";
	action = "add";
}

async function deleteIncrement() {
	try {
		apiLoading.value = true;
		const params = {
			incrementTempId: incrementToDelete.incrementTempId,
			temp_id: temp.value.id,
		};
		const res = await api.deleteIncrementTemp(params);
		emit("refresh");
		toastStore.openToastSuccess(res.data.message);
	} catch (err) {
		if (err.response?.data?.message) {
			toastStore.openToastError(err.response.data.message);
		} else {
			toastStore.openToastError(err.message);
		}
		console.warn(err.message);
	} finally {
		apiLoading.value = false;
	}
}

function handleAction() {
	switch (action) {
		case "add": {
			saveNewIncrement();
			break;
		}
		case "delete": {
			deleteIncrement();
			break;
		}
		case "update": {
			updateWeeksCounter();
			break;
		}
		case "update-category": {
			isWholeCategorySel.value = true;
			break;
		}
		case "reset": {
			try {
				errors.value.clear("increment_weeks");
				const firstEl = incrementWeekList.value?.[0];
				if (firstEl) {
					selectedIncrementWeek.value = firstEl;
					updateWeeksCounter();
				}
			} catch (e) {
				console.log(e);
			}
			break;
		}
		case "delete-sub": {
			removeSubcategory();
			break;
		}
		default: {
			break;
		}
	}
	action = null;
}
function confirmRemoveSubcategory() {
	modalInModalOpen.value = true;
	confirmText.value = "Are you sure you want to delete this subcategory";
	action = "delete-sub";
}

function confrimReset() {
	modalInModalOpen.value = true;
	confirmText.value = "Are you sure you want to reset increment counter";
	action = "reset";
}

const selectedIncrementWeek = ref(null);

async function saveNewIncrement() {
	try {
		apiLoading.value = true;
		const params = {
			start_date: DateInstance.getFormattedDate(
				newPending.value.startDate,
				"YYYY-MM-DD",
				false,
			),
			increment_id: newPending.value.incrementPoint.id,
			temp_id: temp.value.id,
			category_id: subCatId.value,
		};
		await api.postIncrementTemp(params);
		newPending.value = {
			incrementPoint: null,
			startDate: null,
		};
		emit("refresh");
	} catch (err) {
		if (err.response?.data?.message) {
			toastStore.openToastError(err.response.data.message);
		} else {
			toastStore.openToastError(err.message);
		}
		console.warn(err.message);
	} finally {
		apiLoading.value = false;
	}
}
const isDisabledIncWeekBtn = ref(false);
function confirmUpdate() {
	modalInModalOpen.value = true;
	confirmText.value = "Are you sure you want to update increment week";
	action = "update";
}

async function updateWeeksCounter() {
	try {
		isDisabledIncWeekBtn.value = true;
		const params = {
			temp_id: temp.value.id,
			subcategory_id: subCatId.value,
			weeks_counter: selectedIncrementWeek.value.weeks_counter,
			id: undefined,
		};
		if (incrementWeekValue.value?.[0]?.id) {
			params.id = incrementWeekValue.value?.[0]?.id;
		} else {
			delete params.id;
		}
		const res = await api.postIncrementWeeksCounterValue(params);
		await fetchIncrementWeeksCounterValue();
		toastStore.openToastSuccess(res.data.message);
	} catch (err) {
		console.warn(err.message);
		const error1 = findRecordErrors(err);
		if (errors) {
			errors.value.record(error1);
			toastStore.openToastError("Please correct the following errors.");
			for (const e of errors.value.all()) {
				toastStore.openToastError(e);
			}
		} else {
			const errs = parseErrors(
				err,
				"Error saving weekly increment counter",
				true,
			);
			console.warn(err.response.data.message);
			toastStore.openToastError(errs);
		}
	} finally {
		isDisabledIncWeekBtn.value = false;
	}
}

const errorText = ref([]);
const isWholeCategorySel = ref(false);

function changeForAllCategory(evt: Event) {
	const value = (evt.target as HTMLInputElement).checked;
	if (value) {
		evt.preventDefault();
		confirmText.value =
			"Are you sure you want to update increment week for whole category";
		modalInModalOpen.value = true;
		action = "update-category";
	} else {
		isWholeCategorySel.value = false;
	}
}

watch(isWholeCategorySel, (val) => {
	if (val) {
		updateWholeCategories();
	}
});

async function updateWholeCategories() {
	errorText.value = [];

	if (!newPending.value.startDate) {
		errorText.value.push("Effective date is not populated.");
	}

	const incPointId =
		newPending.value &&
		newPending.value.incrementPoint &&
		newPending.value.incrementPoint.id;
	if (!incPointId) {
		errorText.value.push("Increment point is not populated.");
	}

	if (errorText.value.length) {
		// Reset checkbox so it can be reselected when error is fixed

		isWholeCategorySel.value = false;
		return;
	}

	const startDate = DateInstance.getFormattedDate(
		newPending.value.startDate,
		"YYYY-MM-DD",
		false,
	);

	const params = {
		category_id: categoryId.value,
		start_date: startDate,
		increment_id: incPointId,
		temp_id: temp.value.id,
	};
	try {
		apiLoading.value = true;
		await api.postUpdateForCategory(params);
		emit("refresh");
		emit("close-modal");
	} catch (error) {
		toastStore.openToastError(error.response.data.message);
	} finally {
		apiLoading.value = false;
	}
}

async function fetchRangesAndValues() {
	await fetchIncrementWeeksRange();
	if (subCatId.value) {
		fetchIncrementWeeksCounterValue();
	}
}
onBeforeMount(() => {
	fetchRangesAndValues();
	store.fetchAllCategoriesList();
	if (categoryId.value) {
		fetchIncrementsPoints(categoryId.value);
	}
});
</script>
<template>
	<div class="candidate-increment-modal-wrap">
		<div
			v-if="gPermissions.canViewIncrementWeekCounter && canUseIncrementCo"
			class="top-part"
		>
			<div class="input-wrap">
				<label>Weekly Increment Counter</label>
				<multiselect-form
					v-model="selectedIncrementWeek"
					:options="incrementWeekList"
					placeholder="Select increment week"
					label="weeks_counter"
					value-prop="weeks_counter"
					:loading="loadingWeeksCoutnerValue"
				/>
			</div>
			<div class="button-wrap">
				<button
					:disabled="isDisabledIncWeekBtn"
					@click="confrimReset"
				>
					RESET
				</button>
				<button
					:disabled="isDisabledIncWeekBtn"
					@click="confirmUpdate"
				>
					UPDATE
				</button>
			</div>
		</div>
		<div
			v-if="!isModalSubCatName"
			class="picker"
		>
			<label class="label">Subcategories:</label>
			<multiselect-form
				v-model="selectedSubcategories"
				:options="optionsSubcategories"
				value-prop="id"
				label="name"
				mode="single"
				group-label="category"
				group-values="options"
				:groups="true"
				:group-select="false"
				searchable
				track-by="name"
				placeholder="Pick a subcategory"
				@select="errors.clear('subcategories')"
			/>
			<span
				v-if="errors.has('subcategories')"
				class="help is-danger"
				v-html="errors.get('subcategories')"
			></span>
		</div>
		<div v-if="subCatId">
			<label
				class="label"
				for="show-deleted-increments"
			>
				Show deleted increments:
			</label>
			<input
				id="show-deleted-increments"
				v-model="showDeleted"
				style="margin-left: 10px"
				type="checkbox"
			/>
		</div>

		<div
			v-if="isModalSubCatName"
			class="sub-label"
		>
			<label>{{ modalSubcategoryLabel }}</label>
			<button
				class="button is-generic-app-blue"
				data-cell="remove-subcategory"
				:disabled="apiLoading"
				@click="confirmRemoveSubcategory"
			>
				Remove subcategory
			</button>
		</div>
		<div class="data">
			<div
				v-if="apiLoading"
				style="margin: 10px auto"
			>
				<img
					class="wd-30 mx-auto"
					src="@/assets/image/loader.svg"
				/>
			</div>
			<modern-table
				:columns="table_columns"
				:rows="incrementsListTable"
				:is-sticky="false"
				:overflow="false"
			>
				<template #table-body-cell="{ column, row, rowIndex }">
					<template v-if="row.new">
						<td
							v-if="column.id === 'startDate'"
							class="base-table-cell"
						>
							<modern-date-picker
								v-model="newPending.startDate"
								placeholder="Select date"
								:enable-time-picker="false"
								format="dd/MM/yyyy"
								auto-apply
							/>
						</td>
						<td
							v-else-if="column.id === 'name'"
							class="base-table-cell"
							style="overflow: visible"
						>
							<multiselect-form
								v-model="newPending.incrementPoint"
								:options="allIncrementsPoints"
								value-prop="id"
								label="name"
								placeholder="Increment Point"
							/>
						</td>
						<td
							v-else-if="column.id === 'action'"
							class="base-table-cell"
							style="overflow: visible"
						>
							<span
								class="center"
								@click="confirmAddNewIncrement"
							>
								<i-fa4-plus></i-fa4-plus>
							</span>
						</td>
					</template>
					<template v-else>
						<td
							v-if="column.id === 'createdAt'"
							v-tooltip:top.accent="createdByText(row.created_by, 'Created')"
							class="base-table-cell"
							style="overflow: visible"
							:style="{ zIndex: 10 + rowIndex }"
						>
							<span class="created-at">
								{{ row.createdAt }}
								<i-fa4-info-circle></i-fa4-info-circle>
							</span>
						</td>
						<td
							v-else-if="column.id === 'deletedAt'"
							v-tooltip:top.accent="createdByText(row.deleted_by, 'Deleted')"
							class="base-table-cell"
							style="overflow: visible"
							:style="{ zIndex: 10 + rowIndex }"
						>
							<span
								v-if="row.deletedAt"
								class="created-at"
							>
								{{ row.deletedAt }}
								<i-fa4-info-circle></i-fa4-info-circle>
							</span>
						</td>
						<td
							v-else-if="column.id === 'action'"
							class="base-table-cell"
						>
							<template v-if="!row.deletedAt">
								<span
									class="center"
									@click="confrimDelete(row)"
								>
									<i-fa4-times></i-fa4-times>
								</span>
							</template>
						</td>
					</template>
				</template>
			</modern-table>
			<div
				v-if="isModalSubCatName"
				class="control"
				style="display: inline-block"
			>
				<label
					class="label"
					for="update-category-increments"
				>
					Update for whole category:
				</label>
				<input
					id="update-category-increments"
					style="margin-left: 10px"
					type="checkbox"
					:checked="isWholeCategorySel"
					@click="changeForAllCategory"
				/>
				<div class="text-error">
					<span
						v-for="(oneErrorText, index) in errorText"
						:key="index"
					>
						<div>{{ oneErrorText }}</div>
					</span>
				</div>
			</div>
		</div>
		<modal-in-modal
			:is-modal-open="modalInModalOpen"
			:confirm-message="confirmText"
			:initial-modal-in-modal-open="false"
			@confirm-action="handleAction"
			@close-modal="modalInModalOpen = false"
		></modal-in-modal>
		<div class="close-modal">
			<button @click="emit('close-modal')">CLOSE</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.candidate-increment-modal-wrap {
	padding: 20px;
	.top-part {
		display: flex;
		gap: 20px;
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 1px solid $grey;
		.button-wrap {
			display: flex;
			gap: 10px;
			align-self: flex-end;
			button {
				height: 32px;
			}
		}
	}
	.mx-auto {
		margin: 0 auto;
	}
	.sub-label {
		display: flex;
		justify-content: space-between;
		margin: 20px 0;
		label {
			user-select: auto !important;
		}
	}
	.data {
		margin: 20px 0;
		.created-at {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		.center {
			text-align: center;
			display: flex;
			justify-content: center;
			cursor: pointer;
		}
	}
	.close-modal {
		display: flex;
		justify-content: center;
	}
	.text-error {
		color: $red;
	}
}
</style>
