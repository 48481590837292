<script setup lang="ts">
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";

import { Errors } from "@/assets/js/errors";
import type { TGenericItem, TTemp } from "@/assets/js/globalTypes";
import { GENDER_MAP_DROPDOWN_ARRAY } from "@/assets/js/helpers";

import type { TNextOfKin } from "@/components/shared/modals/NextOfKinModal.vue";
import { useDefaultStore } from "@/store";
import { useClientManagementStore } from "@/store/locationClientManagement";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";

const emit = defineEmits<{
	"close-modal": [void];
	confirm: [void];
}>();
const toastStore = useToastStore();
const store = useDefaultStore();
const systemPermissions = useDefaultStore().systemPermissions;
const canUseNexusMiddleware = computed(
	() => systemPermissions.canUseNexusMiddleware,
);
const canUseSleepoverShifts = computed(() => systemPermissions.canUseSleepover);
const canUseOnCallShifts = computed(() => systemPermissions.canUseOnCall);
const canUseExpenses = computed(() => systemPermissions.canUseExpenses);
const hasAllowanceAgency = computed(() => systemPermissions.canViewAllowances);
const sessionStore = useSessionStore();
const gPermissions = computed(() => sessionStore.gPermissions);

const page = ref<"main-page" | "next-of-kin" | "increments" | "wards">(
	"main-page",
);

const can = sessionStore.can;

const clientManagementStore = useClientManagementStore();

const getEirCodeLabelKey = computed(
	() => clientManagementStore.getColumnNameLocl("usr_eircode"),
	// store.getters.getColumnNameLocl("usr_eircode"),
);

const sectors = computed(() => clientManagementStore.getFlatSectors());
const sectorsLoading = ref(true);

const initialSectors = computed(() => {
	const sectorCopy = JSON.parse(JSON.stringify(sectors.value));
	checkedIdObjects = setPreferredStatusAndCopyReasons(
		sectorCopy,
		inputsValues.value.locations,
	);
	// FIX - var sideeffect!!
	// eslint-disable-next-line no-var
	var checkedIdObjects = getCheckedCCs(
		sectorCopy,
		inputsValues.value.locations.map((el) => el.id),
	);

	return checkedIdObjects;
});

function setPreferredStatusAndCopyReasons(sectors, temp) {
	// Contains preferences in the following format
	// {tag: 'preferred', id: 2}
	// tag values: 'preferred', 'unpreferred', 'neither'
	const aggregatedPreferences = [];
	const dataToAppend = {};

	for (const location of temp) {
		if (location.isTempPreferred === true) {
			aggregatedPreferences.push({ tag: "preferred", id: location["id"] });
		} else if (location.isTempPreferred === false) {
			aggregatedPreferences.push({
				tag: "unpreferred",
				id: location["id"],
			});
		} else {
			aggregatedPreferences.push({ tag: "neither", id: location["id"] });
		}
		dataToAppend[location["id"]] = location["reason"];
	}

	for (const sector of sectors) {
		for (const client of sector.clients) {
			for (const location of client.locations) {
				// Find each respective preference
				for (const pref of aggregatedPreferences) {
					if (pref.id === location.id) {
						let valueToSet;
						switch (pref.tag) {
							case "preferred": {
								valueToSet = true;
								break;
							}
							case "unpreferred": {
								valueToSet = false;
								break;
							}
							case "neither": {
								valueToSet = null;
								break;
							}
							// No default
						}
						location.isTempPreferred = valueToSet;
					}
				}
				// If still undefined - set null anyway, so that it's undefined on the modal
				if (location.isTempPreferred === undefined) {
					location.isTempPreferred = null;
				}

				location.reason = dataToAppend[location.id];
			}
		}
	}
	return sectors;
}

function getCheckedCCs(sectors, ids = []) {
	const updatedSectors = [];
	for (const sector of sectors) {
		for (let j = 0; j < sector.clients.length; j++) {
			const client = sector.clients[j];

			for (let k = 0; k < client.locations.length; k++) {
				const location = client.locations[k];
				// Update the checked property based on ids
				location.checked = ids.includes(location.id);
			}
		}

		// Push the updated sector into the result array
		updatedSectors.push(sector);
	}

	return updatedSectors;
}

const errors = ref(new Errors());
const inputsValues = ref<
	Partial<TTemp> & {
		password?: string;
		confirmPassword?: string;
		locations?: any[];
	}
>({
	first_name: "",
	last_name: "",
	nickname: "",
	homeAddress: "",
	homeAddress2: "",
	homeAddress3: "",
	mobilePhone: "",
	homePhone: "",
	eircode: "",
	dateOfBirth: null,
	tssId: "",
	hasCommunityAllowance: false,
	canCreateOwnShift: false,
	contract_hours: "",
	contract_start_date: null,
	contract_start: null,
	email: "",
	external_id: "",
	external_id_2: "",
	password: "",
	confirmPassword: "",
	relatives: [],
	locations: [],
});

const dropDownValues = ref<{
	region: { id: number } | null;
	preferredRegions: { id: number } | null;
	tags: { id: number }[];
	nationality: { id: number } | null;
	gender: { value: string; label: string };
	gnib_max_week_hours: number | null;
	gnib_dtp_id: number | null;
	gnib_active_max_week_hours: number | null;
	selectedSubcategories: number[] | null;
}>({
	region: null,
	preferredRegions: null,
	tags: [],
	nationality: null,
	gender: null,
	gnib_max_week_hours: null,
	gnib_dtp_id: null,
	gnib_active_max_week_hours: null,
	selectedSubcategories: [],
});

const switchValues = ref<{
	canCreateOwnShift: boolean;
	hasCommunityAllowance: boolean;
	hasOnCall: boolean;
	hasExpenses: boolean;
	hasSleepover: boolean;
	sendEmail: boolean;
}>({
	canCreateOwnShift: false,
	hasCommunityAllowance: false,
	hasOnCall: false,
	hasExpenses: false,
	hasSleepover: false,
	sendEmail: false,
	// TODO ^ change to true
});

const disabledFields = ref();

async function getDisabledFields() {
	try {
		const res = await api.getExternalServiceDisabledFields();
		disabledFields.value = res.data?.data || res.data;
	} catch (error) {
		console.error(error);
	}
}

const counties = ref<TGenericItem[]>([]);
async function getCounties() {
	try {
		const response = await api.getCounties();
		counties.value = response.data.data;
	} catch (e) {
		console.log(e);
	}
}

const nationalities = ref<TGenericItem[]>([]);
async function getCountries() {
	try {
		const response = await api.getCountries();
		nationalities.value = response.data.data;
	} catch (e) {
		console.log(e);
	}
}

const tags = ref<TGenericItem[]>([]);
async function getTags() {
	try {
		const response = await api.getCandidateTags();
		tags.value = response.data.data;
	} catch (e) {
		console.log(e);
	}
}

const password_is_valid = ref(false);
function handlePasswordIsValid(params: {
	isPasswordValid: boolean;
	password: string;
}) {
	password_is_valid.value = params.isPasswordValid;
}

const optionsSubcategories = computed(() =>
	store.getCategoriesWithSubcategories(),
);

onMounted(() => {
	if (canUseNexusMiddleware.value) {
		getDisabledFields();
	}
	getCounties();
	getCountries();
	if (can("manage-temp-tags")) {
		getTags();
	}
	store.fetchAllCategoriesList();
	clientManagementStore.getColumnNamesLocl();
	clientManagementStore
		.fetchSectors({
			includes: ["clients", "clients.locations"].join(","),
		})
		// eslint-disable-next-line promise/prefer-await-to-then
		.finally(() => {
			sectorsLoading.value = false;
		});
});

const isFieldDisabled = (field: string) => {
	return disabledFields.value?.includes(field);
};

const incrementArray = ref([]);
// const newestIncrementArray = computed(() => {
// 	const grouped_increments = arrayGroupBy(
// 		inputsValues.value?.increments || [],
// 		"category.id",
// 	);

// 	const newsetIncrementArray: TIncrement[] = [];
// 	for (const key of Object.keys(grouped_increments)) {
// 		const increments = grouped_increments[key];
// 		const activeIncrement = getActiveInrementFromIncrementArray(increments);
// 		if (activeIncrement) {
// 			newsetIncrementArray.push(activeIncrement);
// 		}
// 	}
// 	return newsetIncrementArray;
// });

function setNewRelatives(relatives: TNextOfKin[]) {
	inputsValues.value.relatives = relatives;
	page.value = "main-page";
}
function callOpenNextOfKinModal() {
	if (inputsValues.value.relatives.length === 0) {
		inputsValues.value.relatives.push({
			name: "",
			relativetype_id: null,
			phone: "",
		});
	}
	page.value = "next-of-kin";
}

export type TRegisterIncrement = Partial<{
	startDate: string;
	name: string;
	incrementPoint: { id: number; name: string } | null;
	new: boolean;
}>;

const incrementData = ref<{
	subcategory: { id: number; name: string; catParentId: number } | null;
	increments: { subcategory_id: number; increments: TRegisterIncrement[] }[];
	selectedIncrements: TRegisterIncrement[];
	incrementWeeks: { weeks_counter: number; subcategory_id: number }[];
	selectedIncrementWeek: number | null;
}>({
	subcategory: null,
	increments: [],
	selectedIncrements: [],
	selectedIncrementWeek: null,
	incrementWeeks: [],
});

function openIncrementSettingModal(category) {
	if (!can("edit-temp")) {
		return;
	}

	incrementData.value.subcategory = category;
	const foundIndex = incrementData.value.increments.findIndex(
		(item) => item.subcategory_id === category.id,
	);

	const weekCounterIndex = incrementData.value.incrementWeeks.findIndex(
		(item) => item.subcategory_id === category.id,
	);
	incrementData.value.selectedIncrementWeek =
		weekCounterIndex === -1
			? null
			: incrementData.value.incrementWeeks[weekCounterIndex].weeks_counter;

	incrementData.value.selectedIncrements =
		foundIndex === -1
			? []
			: incrementData.value.increments[foundIndex].increments;

	page.value = "increments";
}

function handleIncrements({
	increments,
	weekCounter,
}: {
	increments: TRegisterIncrement[];
	weekCounter: string | null;
}) {
	if (weekCounter) {
		const weekCounterNum = Number(weekCounter);
		const index = incrementData.value.incrementWeeks.findIndex(
			(item) => item.subcategory_id === incrementData.value.subcategory.id,
		);
		if (index === -1) {
			incrementData.value.incrementWeeks.push({
				weeks_counter: weekCounterNum,
				subcategory_id: incrementData.value.subcategory.id,
			});
		} else {
			incrementData.value.incrementWeeks[index].weeks_counter = weekCounterNum;
		}
	}
	if (increments && increments.length) {
		const subcat_index = incrementData.value.increments.findIndex(
			(item) => item.subcategory_id === incrementData.value.subcategory.id,
		);
		if (subcat_index === -1) {
			incrementData.value.increments.push({
				subcategory_id: incrementData.value.subcategory.id,
				increments,
			});
		} else {
			incrementData.value.increments[subcat_index].increments = increments;
		}
	}
	page.value = "main-page";
}

export type TCandidateShiftData = {
	id: number;
	name: string;
	type: string;
	date: string;
};

const isModifiedGnibDocs = ref(false);
function onSelectGnibDoc(evtPayload) {
	isModifiedGnibDocs.value = true;
	dropDownValues.value.gnib_dtp_id = evtPayload.doc;
	dropDownValues.value.gnib_max_week_hours = evtPayload.hours;
}
function onRemoveGnibDoc(isModified = true) {
	dropDownValues.value.gnib_max_week_hours = null;
	dropDownValues.value.gnib_dtp_id = null;
	isModifiedGnibDocs.value = isModified;
}
function onInputGnib(evtPayload) {
	dropDownValues.value.gnib_max_week_hours = evtPayload.hours;
	if (!dropDownValues.value.gnib_dtp_id) {
		dropDownValues.value.gnib_dtp_id = evtPayload.doc;
	}
	errors.value.clear("gnib_max_week_hours");
}

const savingLoading = ref(false);

const passwordSameAndValid = computed(
	() =>
		password_is_valid.value &&
		inputsValues.value.password === inputsValues.value.confirmPassword,
);
const showPasswordMessage = computed(
	() =>
		!switchValues.value.sendEmail &&
		!!inputsValues.value.password &&
		!passwordSameAndValid.value,
);
const disableConfirm = computed(() => {
	if (savingLoading.value) {
		return true;
	}
	return showPasswordMessage.value;
});

const isSaveLoading = ref(false);
async function postWorker() {
	if (isSaveLoading.value) {
		return;
	}
	isSaveLoading.value = true;
	const increments = [];
	for (const inc of incrementData.value.increments) {
		for (const el of inc.increments) {
			increments.push({
				start_date: el.startDate,
				category_id: inc.subcategory_id,
				increment_id: el.incrementPoint.id,
			});
		}
	}

	const params: any = {
		first_name: inputsValues.value.first_name,
		last_name: inputsValues.value.last_name,
		nickname: inputsValues.value.nickname,
		home_address: inputsValues.value.homeAddress,
		home_address_2: inputsValues.value.homeAddress2,
		home_address_3: inputsValues.value.homeAddress3,
		nationality_id: dropDownValues.value.nationality?.id || null,
		mobile_phone: inputsValues.value.mobilePhone,
		home_phone: inputsValues.value.homePhone,
		eircode: inputsValues.value.eircode,
		email: inputsValues.value.email,
		date_of_birth: DateInstance.getFormattedDate(
			inputsValues.value.dateOfBirth,
			"YYYY-MM-DD",
			false,
		),
		gender: dropDownValues.value.gender?.value || null,
		region_id: dropDownValues.value.region?.id ?? null,
		preferred_regions: dropDownValues.value.preferredRegions
			? [{ id: dropDownValues.value.preferredRegions.id }]
			: [],
		tags: dropDownValues.value.tags.map((el) => ({ id: el })),
		subcategories:
			dropDownValues.value.selectedSubcategories.map((el) => ({ id: el })) ||
			[],
		has_community_allowance: switchValues.value.hasCommunityAllowance,
		can_create_own_shift: switchValues.value.canCreateOwnShift,
		locations: inputsValues.value.locations,
		increments: increments,
		autogenerate_password: switchValues.value.sendEmail,
	};
	if (!switchValues.value.sendEmail) {
		params.password = inputsValues.value.password;
		params.password_confirmation = inputsValues.value.confirmPassword;
	}
	if (incrementData.value.incrementWeeks) {
		params.userIncrementCounters = incrementData.value.incrementWeeks;
	}
	if (gPermissions.value.canRelativeView) {
		params.relatives = inputsValues.value.relatives;
	}

	if (!canUseNexusMiddleware.value) {
		if (inputsValues.value.tssId) {
			params.tss_id = inputsValues.value.tssId;
		}
		if (inputsValues.value.contract_start) {
			params.contract_start = inputsValues.value.contract_start;
		}
	}
	if (inputsValues.value.contract_hours) {
		params.contract_hours = inputsValues.value.contract_hours;
	}

	if (dropDownValues.value.gnib_dtp_id) {
		params.include = "gnibConfig";
		params.gnib_dtp_id = dropDownValues.value.gnib_dtp_id;
		if (dropDownValues.value.gnib_max_week_hours) {
			params.gnib_max_week_hours = dropDownValues.value.gnib_max_week_hours;
		}
	}
	if (canUseOnCallShifts) {
		params.has_on_call = switchValues.value.hasOnCall;
	}
	if (canUseSleepoverShifts) {
		params.has_sleepover = switchValues.value.hasSleepover;
	}
	if (canUseExpenses) {
		params.has_expenses = switchValues.value.hasExpenses;
	}
	if (gPermissions.value.canEditContractedHours) {
		params.contract_start_date = inputsValues.value.contract_start_date;
	}

	if (canUseNexusMiddleware.value) {
		params.external_id = inputsValues.value.external_id;
		// params.external_id_2 = inputsValues.value.external_id_2;
	}
	if (!params.external_id || !params.external_id.trim().length) {
		params.external_id = null;
	}

	// If value for nationality_id is null delete
	for (const [key, value] of Object.entries(params)) {
		if (value === null && key === "nationality_id") {
			delete params[key];
		}
	}

	try {
		await api.postTemp(params);
		toastStore.openToastSuccess("Temp registered successfully!");
		emit("confirm");
		emit("close-modal");
	} catch (error) {
		if (error.response.data && error.response.data.errors) {
			error = formatInvalidDateErrorMessage(error);
			errors.value.record(error.response.data.errors);
		} else {
			// For invalid email
			const errorMsg = {
				email: [error.response.data.message],
			};
			errors.value.record(errorMsg);
		}
		toastStore.openToastError("Please correct the following errors.");
		for (const e of errors.value.all()) {
			toastStore.openToastError(e);
		}
	} finally {
		isSaveLoading.value = false;
	}
}

function formatInvalidDateErrorMessage(error) {
	if (error.response.data.errors.date_of_birth) {
		for (let i = 0; i < error.response.data.errors.date_of_birth.length; i++) {
			const errDob = error.response.data.errors.date_of_birth[i];
			if (errDob === "The date of birth does not match the format Y-m-d.") {
				error.response.data.errors.date_of_birth[i] =
					"The date of birth does not match the format DD/MM/YYYY.";
			} else if (
				errDob.indexOf("The date of birth must be a date before") === 0
			) {
				// If starts with ...
				const stringToCut = "The date of birth must be a date before ";
				// Firefox and maybe other browsers don't handle datetime parsing from string
				const slicedDate = errDob.slice(stringToCut.length, -1);
				error.response.data.errors.date_of_birth[i] =
					`The date of birth must be a date before ${DateInstance.getFormattedDate(
						slicedDate,
						"DD/MM/YYYY",
						false,
					)}`;
			}
		}
	}

	return error;
}

// function test(e) {
// 	inputsValues.value.locations = e;
// 	page.value = "main-page";
// }

function tempPrefChanged(evtObj) {
	const foundObj = inputsValues.value.locations.find(
		(centre) => centre.id === evtObj.id,
	);
	if (foundObj) {
		// Has to be found
		if (evtObj.is_temp_preferred) {
			foundObj.is_temp_preferred = true;
		} else if (evtObj.is_temp_preferred === false) {
			foundObj.is_temp_preferred = false;
		} else {
			foundObj.is_temp_preferred = null;
		}
		foundObj.isTempPreferred = foundObj.is_temp_preferred;
	}
}
function locationCheckChanged(evtObj) {
	if (evtObj.value) {
		inputsValues.value.locations.push({
			id: evtObj.id,
			is_temp_preferred: null,
		});
	} else {
		inputsValues.value.locations = inputsValues.value.locations.filter(
			(centre) => centre.id !== evtObj.id,
		);
	}

	// Check errors
	// if (this.form.selectedLocations && this.form.selectedLocations.length) {
	//   this.errors.clear("locations");
	// }
}
function locationReasonChanged(evtObj) {
	const foundObj = inputsValues.value.locations.find(
		(centre) => centre.id === evtObj.id,
	);
	if (foundObj) {
		// Has to be found
		foundObj.reason = evtObj.reason;
	}
}

const tssIsLoading = ref(false);
async function pullFromExtSystem() {
	if (tssIsLoading.value || !validExternalId.value) {
		return;
	}
	tssIsLoading.value = true;
	const tssId = inputsValues.value.tssId;
	const externalId = inputsValues.value.external_id;
	let relativeTypes = null;
	try {
		const res = await api.getRelativeTypes();
		relativeTypes = res.data.data;
	} catch {}
	try {
		const res = await api.fetchTempInfoFromTss({
			id: canUseNexusMiddleware.value ? externalId : tssId,
		});
		const formattedData = formatDataFromTssFetch(res.data, relativeTypes);
		// reset this component data when external ID is pulled
		resetFormData();
		assignValues(formattedData);
		Object.assign(inputsValues.value, formattedData);
		errors.value.clear();
	} catch (err) {
		// reset this component data when external ID is pulled
		resetFormData();
		toastStore.openToastError(err.response.data.message);
	} finally {
		tssIsLoading.value = false;
	}
}

const validExternalId = computed(
	() => inputsValues.value.external_id || inputsValues.value.tssId,
);

function resetFormData() {
	inputsValues.value = {
		first_name: "",
		last_name: "",
		nickname: "",
		homeAddress: "",
		homeAddress2: "",
		homeAddress3: "",
		mobilePhone: "",
		homePhone: "",
		eircode: "",
		dateOfBirth: null,
		tssId: "",
		hasCommunityAllowance: false,
		canCreateOwnShift: false,
		contract_hours: "",
		contract_start_date: null,
		contract_start: null,
		email: "",
		external_id: "",
		external_id_2: "",
		password: "",
		confirmPassword: "",
		relatives: [],
		locations: [],
	};
	dropDownValues.value = {
		region: null,
		preferredRegions: null,
		tags: [],
		nationality: null,
		gender: null,
		gnib_max_week_hours: null,
		gnib_dtp_id: null,
		gnib_active_max_week_hours: null,
		selectedSubcategories: [],
	};
	switchValues.value = {
		canCreateOwnShift: false,
		hasCommunityAllowance: false,
		hasOnCall: false,
		hasExpenses: false,
		hasSleepover: false,
		sendEmail: false,
	};
}

function assignValues(data) {
	inputsValues.value.first_name = data.first_name;
	inputsValues.value.last_name = data.last_name;
	inputsValues.value.homeAddress = data.homeAddress;
	inputsValues.value.homeAddress2 = data.homeAddress2;
	inputsValues.value.homeAddress3 = data.homeAddress3;
	inputsValues.value.mobilePhone = data.mobilePhone;
	inputsValues.value.homePhone = data.homePhone;
	inputsValues.value.dateOfBirth = data.dateOfBirth;
	inputsValues.value.external_id = data.external_id;
	inputsValues.value.email = data.email;
	inputsValues.value.external_id_2 = data.external_id_2;
	dropDownValues.value.gender = data.gender;
	dropDownValues.value.nationality = data.nationality;
}

function formatDataFromTssFetch(data, relativeTypes) {
	return {
		homeAddress: data.home_address,
		homeAddress2: data.home_address_2,
		homeAddress3: data.home_address_3,
		mobilePhone: data.mobile_phone,
		homePhone: data.home_phone,
		...data,
		dateOfBirth: data.date_of_birth,
		nationality: nationalities.value.find(function (item) {
			return item.name.toLowerCase() === data.nationality_name?.toLowerCase();
		}),
		gender: GENDER_MAP_DROPDOWN_ARRAY.find(function (item) {
			return item.value.toLowerCase() === data.gender?.toLowerCase();
		}),
		relatives: relativeTypes
			? data.relatives.map((relative) => {
					return {
						name: relative.name,
						relativetype_id:
							relativeTypes.find(
								(type) => type.name === relative.relativetype_name,
							)?.id || null,
						phone: relative.phone,
					};
				})
			: data.relatives,
	};
}
</script>
<template>
	<div
		id="register-candidate-modal"
		:class="{ visible: page === 'increments' }"
	>
		<div v-show="page === 'main-page'">
			<div class="item">
				<label>First Name:</label>
				<input
					v-model="inputsValues.first_name"
					:disabled="isFieldDisabled('first_name')"
					placeholder="First Name"
					type="text"
				/>
				<span
					v-if="errors.has('first_name')"
					class="help is-danger"
					v-html="errors.get('first_name')"
				></span>
			</div>
			<div class="item">
				<label>Last Name:</label>
				<input
					v-model="inputsValues.last_name"
					:disabled="isFieldDisabled('last_name')"
					placeholder="Last Name"
					type="text"
				/>
				<span
					v-if="errors.has('last_name')"
					class="help is-danger"
					v-html="errors.get('last_name')"
				></span>
			</div>
			<div class="item-group">
				<div class="item grow">
					<label>Known as:</label>
					<input
						v-model="inputsValues.nickname"
						:disabled="isFieldDisabled('nickname')"
						placeholder="Known as"
						type="text"
					/>
					<span
						v-if="errors.has('nickname')"
						class="help is-danger"
						v-html="errors.get('nickname')"
					></span>
				</div>
				<div
					v-if="gPermissions.canViewTempContractedHours"
					class="item"
				>
					<label>Start date:</label>
					<div class="f flex-align-center">
						<modern-date-picker
							v-model="inputsValues.contract_start_date"
							:disabled="isFieldDisabled('contract_start_date')"
							format="dd/MM/yyyy"
							placeholder="Start date"
							:enable-time-picker="false"
						/>
						<span
							v-if="errors.has('contract_start_date')"
							class="help is-danger fix-bg"
							v-html="errors.get('contract_start_date')"
						></span>
					</div>
				</div>
			</div>
			<div class="item-group">
				<div
					v-if="canUseNexusMiddleware"
					class="item grow"
				>
					<label>Worker Reference ID::</label>
					<input
						v-model="inputsValues.external_id"
						:disabled="isFieldDisabled('tss_id')"
						placeholder="Worker Reference ID"
						type="text"
						@keydown.enter="pullFromExtSystem"
					/>
				</div>
				<div
					v-else
					class="item grow"
				>
					<label>External system id number</label>
					<input
						v-model="inputsValues.tssId"
						type="text"
						:disabled="isFieldDisabled('external_id')"
						placeholder="External system id number"
						@keydown.enter="pullFromExtSystem"
					/>
				</div>

				<div style="align-self: flex-end">
					<button
						:disabled="tssIsLoading || !validExternalId"
						@click="pullFromExtSystem"
					>
						<template v-if="tssIsLoading">
							<div class="loading-wrapper">
								<img src="@/assets/image/loader.svg" />
							</div>
						</template>
						<template v-else>Pull From ext. system</template>
					</button>
				</div>
			</div>
			<div class="item">
				<label>Date of birth:</label>
				<modern-date-picker
					v-model="inputsValues.dateOfBirth"
					:disabled="isFieldDisabled('date_of_birth')"
					format="dd/MM/yyyy"
					placeholder="Date of birth"
					:enable-time-picker="false"
				/>
				<span
					v-if="errors.has('date_of_birth')"
					class="help is-danger"
					v-html="errors.get('date_of_birth')"
				></span>
			</div>
			<div class="item">
				<label>Home Address Line 1:</label>
				<input
					v-model="inputsValues.homeAddress"
					:disabled="isFieldDisabled('home_address')"
					placeholder="Home Address Line 1"
					type="text"
				/>
				<span
					v-if="errors.has('home_address')"
					class="help is-danger"
					v-html="errors.get('home_address')"
				></span>
			</div>
			<div class="item">
				<label>Home Address Line 2:</label>
				<input
					v-model="inputsValues.homeAddress2"
					:disabled="isFieldDisabled('home_address_2')"
					placeholder="Home Address Line 2"
					type="text"
				/>
				<span
					v-if="errors.has('home_address_2')"
					class="help is-danger"
					v-html="errors.get('home_address_2')"
				></span>
			</div>
			<div class="item">
				<label>Home Address Line 3:</label>
				<input
					v-model="inputsValues.homeAddress3"
					:disabled="isFieldDisabled('home_address_3')"
					placeholder="Home Address Line 3"
					type="text"
				/>
				<span
					v-if="errors.has('home_address_3')"
					class="help is-danger"
					v-html="errors.get('home_address_3')"
				></span>
			</div>
			<div class="item">
				<label>County:</label>
				<multiselect-form
					v-model="dropDownValues.region"
					searchable
					:preselectFirst="false"
					:mode="'single'"
					:options="counties"
					value-prop="id"
					label="name"
					:loading="!counties.length"
					placeholder="Choose county"
					:disabled="isFieldDisabled('region_id')"
					@select="errors.clear('region_id')"
				></multiselect-form>
				<span
					v-if="errors.has('region_id')"
					class="is-danger help"
					v-html="errors.get('region_id')"
				></span>
			</div>
			<div class="item">
				<label>Prefered County:</label>
				<multiselect-form
					v-model="dropDownValues.preferredRegions"
					searchable
					:preselectFirst="false"
					:mode="'single'"
					:options="counties"
					value-prop="id"
					label="name"
					placeholder="Choose preferred county"
					:loading="!counties.length"
					:disabled="isFieldDisabled('preferred_regions')"
					@select="errors.clear('preferred_regions')"
				></multiselect-form>
				<span
					v-if="errors.has('preferred_regions')"
					class="is-danger help"
					v-html="errors.get('preferred_regions')"
				></span>
			</div>
			<div class="item">
				<label>{{ getEirCodeLabelKey }}:</label>
				<input
					v-model="inputsValues.eircode"
					type="text"
					:placeholder="getEirCodeLabelKey"
					:disabled="isFieldDisabled('eircode')"
				/>
			</div>
			<div class="item">
				<label>Nationality:</label>
				<multiselect-form
					v-model="dropDownValues.nationality"
					searchable
					:preselectFirst="false"
					:mode="'single'"
					:options="nationalities"
					value-prop="id"
					label="name"
					placeholder="Search Nationality"
					:loading="!nationalities.length"
					:disabled="isFieldDisabled('nationality_name')"
				></multiselect-form>
				<span
					v-if="errors.has('nationality_id')"
					class="is-danger help"
					v-html="errors.get('nationality_id')"
				></span>
			</div>
			<div class="item">
				<label>Mobile:</label>
				<input
					v-model="inputsValues.mobilePhone"
					:disabled="isFieldDisabled('mobile_phone')"
					placeholder="Mobile phone"
					type="text"
				/>
				<span
					v-if="errors.has('mobile_phone')"
					class="is-danger help"
					v-html="errors.get('mobile_phone')"
				></span>
			</div>
			<div class="item">
				<label>Phone:</label>
				<input
					v-model="inputsValues.homePhone"
					:disabled="isFieldDisabled('home_phone')"
					placeholder="Home phone"
					type="text"
				/>
				<span
					v-if="errors.has('home_phone')"
					class="is-danger help"
					v-html="errors.get('home_phone')"
				></span>
			</div>
			<div class="item">
				<label>Email:</label>
				<input
					v-model="inputsValues.email"
					type="text"
					placeholder="Email"
					:disabled="isFieldDisabled('email')"
				/>
				<span
					v-if="errors.has('email')"
					class="is-danger help"
					v-html="errors.get('email')"
				></span>
			</div>
			<div class="item">
				<label>Gender:</label>
				<multiselect-form
					v-model="dropDownValues.gender"
					searchable
					:preselectFirst="false"
					:mode="'single'"
					:options="GENDER_MAP_DROPDOWN_ARRAY"
					:disabled="isFieldDisabled('gender')"
					placeholder="Select gender"
					:loading="!dropDownValues.gender"
				></multiselect-form>
				<span
					v-if="errors.has('gender')"
					class="is-danger help"
					v-html="errors.get('gender')"
				></span>
			</div>
			<div class="item">
				<label>Tags:</label>
				<multiselect-form
					v-model="dropDownValues.tags"
					searchable
					:preselectFirst="false"
					:mode="'tags'"
					:options="tags"
					value-prop="id"
					label="name"
					placeholder="Select tags"
					:loading="!tags.length"
					:disabled="isFieldDisabled('tags')"
					@select="errors.clear('tags')"
				></multiselect-form>
				<span
					v-if="errors.has('tags')"
					class="is-danger help"
					v-html="errors.get('tags')"
				></span>
			</div>
			<div class="item">
				<label>Contracted hours:</label>
				<input
					v-model="inputsValues.contract_hours"
					:disabled="isFieldDisabled('contract_hours')"
					placeholder="Contracted hours"
					type="text"
				/>
				<span
					v-if="errors.has('contract_hours')"
					class="is-danger help"
					v-html="errors.get('contract_hours')"
				></span>
			</div>
			<div
				v-if="!canUseNexusMiddleware"
				class="item"
			>
				<label>Contracted start:</label>
				<input
					v-model="inputsValues.contract_start"
					:disabled="isFieldDisabled('contract_start_date')"
					placeholder="Contracted start"
					type="text"
				/>
				<span
					v-if="errors.has('contract_start')"
					class="is-danger help"
					v-html="errors.get('contract_start')"
				></span>
			</div>
			<div class="item">
				<label>Next of kin:</label>
				<div class="buttons">
					<template
						v-for="item in inputsValues.relatives"
						:key="item.id"
					>
						<highlight-button
							:text="item.name"
							@click="callOpenNextOfKinModal"
						></highlight-button>
					</template>
				</div>
				<highlight-button
					:text="'Add'"
					@click="callOpenNextOfKinModal"
				></highlight-button>
			</div>
			<div class="item">
				<div class="save-button">
					<button
						:disabled="sectorsLoading"
						@click="page = 'wards'"
					>
						<span v-if="!sectorsLoading">Choose from wards</span>
						<template v-else>
							<img src="@/assets/image/loader.svg" />
						</template>
					</button>
				</div>
				<span
					v-if="errors.has('locations')"
					class="is-danger help"
					v-html="errors.get('locations')"
				></span>
			</div>
			<div class="item">
				<div class="picker">
					<label class="label">Subcategories:</label>
					<multiselect-form
						v-model="dropDownValues.selectedSubcategories"
						searchable
						:options="optionsSubcategories"
						value-prop="id"
						label="name"
						mode="tags"
						group-label="category"
						group-values="options"
						:groups="true"
						:group-select="false"
						:close-on-select="false"
						track-by="name"
						:disabled="isFieldDisabled('subcategories')"
						placeholder="Choose from subcategories"
						@select="incrementArray.push($event)"
						@deselect="
							incrementArray = incrementArray.filter(
								(item) => item.id !== $event.id,
							)
						"
					/>
					<span
						v-if="errors.has('subcategories')"
						class="help is-danger"
						v-html="errors.get('subcategories')"
					></span>
				</div>
			</div>
			<div
				v-if="incrementArray.length"
				class="item"
			>
				<label>Increment point # / Subcategories:</label>
				<div class="buttons">
					<template
						v-for="item in incrementArray"
						:key="item.id"
					>
						<highlight-button
							:text="item.name"
							@click="openIncrementSettingModal(item)"
						></highlight-button>
					</template>
				</div>
				<span
					v-if="errors.has('increments')"
					class="is-danger help"
					v-html="errors.get('increments')"
				></span>
			</div>

			<div
				v-if="hasAllowanceAgency"
				class="item-group flex-align-center switches"
			>
				<label>Community allowance</label>
				<tri-state-switch
					:value="switchValues.hasCommunityAllowance"
					:color="switchValues.hasCommunityAllowance ? 'green' : 'default'"
					@input="switchValues.hasCommunityAllowance = Boolean($event)"
				></tri-state-switch>
			</div>
			<div class="item">
				<label>GNIB documents (max week hours)</label>
				<candidate-gnib-documents
					:is-modified-gnib-docs="isModifiedGnibDocs"
					:selected-gnib-doc-id="dropDownValues.gnib_dtp_id"
					:selected-gnib-max-week-value="dropDownValues.gnib_max_week_hours"
					:form="dropDownValues"
					:errors="errors"
					@input-gnib-hours="onInputGnib"
					@select-gnib-doc="onSelectGnibDoc"
					@remove-gnib-doc="onRemoveGnibDoc"
				/>
			</div>
			<div class="item-group flex-align-center switches">
				<label>Self submission of shifts</label>
				<tri-state-switch
					:value="switchValues.canCreateOwnShift"
					:color="switchValues.canCreateOwnShift ? 'green' : 'default'"
					@input="switchValues.canCreateOwnShift = Boolean($event)"
				></tri-state-switch>
			</div>

			<div
				v-if="canUseOnCallShifts"
				class="item-group flex-align-center switches"
			>
				<label>Standby / On call shifts</label>
				<tri-state-switch
					:value="switchValues.hasOnCall"
					:color="switchValues.hasOnCall ? 'green' : 'default'"
					@input="switchValues.hasOnCall = Boolean($event)"
				></tri-state-switch>
			</div>
			<div
				v-if="canUseSleepoverShifts"
				class="item-group flex-align-center switches"
			>
				<label>Sleepover shifts</label>
				<tri-state-switch
					:value="switchValues.hasSleepover"
					:color="switchValues.hasSleepover ? 'green' : 'default'"
					@input="switchValues.hasSleepover = Boolean($event)"
				></tri-state-switch>
			</div>
			<div
				v-if="canUseExpenses"
				class="item-group flex-align-center switches"
			>
				<label>Expenses</label>
				<tri-state-switch
					:value="switchValues.hasExpenses"
					:color="switchValues.hasExpenses ? 'green' : 'default'"
					@input="switchValues.hasExpenses = Boolean($event)"
				></tri-state-switch>
			</div>

			<div class="item-group flex-align-center switches">
				<label>Auto generate password and Send Email</label>
				<tri-state-switch
					:value="switchValues.sendEmail"
					:color="switchValues.sendEmail ? 'green' : 'default'"
					@input="switchValues.sendEmail = Boolean($event)"
				></tri-state-switch>
			</div>

			<template v-if="!switchValues.sendEmail">
				<div class="item">
					<label>Password:</label>
					<input
						v-model="inputsValues.password"
						class="input is-medium"
						type="password"
						placeholder="PASSWORD"
						autocomplete="new-password"
					/>

					<password-policy-validation
						:password-characters="inputsValues.password || ''"
						@password-valid="handlePasswordIsValid"
					/>
					<span
						v-if="errors.has('password')"
						class="help is-danger"
						v-html="errors.get('password')"
					></span>
				</div>
				<div class="item">
					<label>Confirm Password:</label>
					<input
						v-model="inputsValues.confirmPassword"
						type="password"
						placeholder="CONFIRM PASSWORD"
					/>
				</div>
			</template>
			<div
				v-if="can('edit-temp')"
				class="save-button"
			>
				<p
					v-if="showPasswordMessage"
					class="danger-text"
				>
					The "SAVE CHANGES" button will become available once the password
					criteria is met and the identical password is entered in both fields.
				</p>
				<button
					:disabled="disableConfirm || isSaveLoading"
					@click="postWorker"
				>
					<i-fa4-check></i-fa4-check>
					<span>
						{{
							switchValues.sendEmail
								? "Generate Password and Send Email"
								: "Create Worker"
						}}
					</span>
				</button>
			</div>
		</div>
		<template v-if="page === 'next-of-kin'">
			<next-of-kin-modal
				:relatives="inputsValues.relatives"
				@confirm="setNewRelatives"
				@close-modal="page = 'main-page'"
			></next-of-kin-modal>
		</template>
		<div v-if="page === 'wards'">
			<location-pref-modal
				:initial-sectors="initialSectors"
				:manage-action="true"
				@cost-centre-check-change="locationCheckChanged"
				@reason-change="locationReasonChanged"
				@temp-pref="tempPrefChanged"
				@close-modal="page = 'main-page'"
			/>
			<div class="buttons-centered">
				<button
					class="buttons-centered"
					@click="page = 'main-page'"
				>
					CLOSE
				</button>
			</div>
		</div>
		<template v-if="page === 'increments'">
			<register-temp-increment-page
				:subcategory="incrementData.subcategory"
				:increments="incrementData.selectedIncrements"
				:current-increment-week="incrementData.selectedIncrementWeek"
				@close-modal="page = 'main-page'"
				@confirm="handleIncrements"
			/>
		</template>
	</div>
</template>

<style scoped lang="scss">
#register-candidate-modal {
	padding: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 800px;
	max-width: 90vh;
	max-height: 85vh;
	.fix-bg {
		background-color: unset;
	}
	&.visible {
		overflow: visible;
	}
	label {
		display: block;
		color: $label-accent-color;
		font-size: 0.75rem;
		margin-bottom: 5px;
	}
	.item {
		input {
			width: 100%;
		}
		&.flex {
			display: flex;
			align-items: center;
		}
		&.grow {
			flex-grow: 1;
		}
		.with-icon {
			display: flex;
			gap: 5px;
			align-items: center;
			input {
				flex-grow: 1;
			}
		}
		.f {
			gap: 5px;
		}
	}
	.buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom: 10px;
	}
	.item-group {
		display: flex;
		gap: 10px;
		&.small {
			width: fit-content;
		}
		&.switches {
			justify-content: space-between;
		}
	}
	.save-button {
		width: 100%;
		margin: 20px 0;
		button {
			width: 100%;
		}
	}
	.shift-data {
		margin-top: 20px;
	}
	.danger-text {
		color: $red;
		text-align: center;
		margin-bottom: 10px;
		font-weight: 500;
		max-width: 360px;
	}
	:deep() {
		.highlight-button {
			width: unset;
			.highlight-button-text {
				padding: 5px 10px;
			}
		}
	}
}
</style>
