<script setup lang="ts">
import type { TTableColumn } from "../table/ModernTable.vue";
import api from "@/api";

const props = defineProps<{
	relatives: TNextOfKin[];
}>();

const newRelatives = ref<(TNextOfKin & { index: number; new: boolean })[]>([]);

const relativeChanges = ref<(TNextOfKin & { index: number; new: boolean })[]>(
	props.relatives.map((relative, index) => ({
		...relative,
		index,
		new: false,
	})),
);
const emit = defineEmits(["close-modal", "confirm"]);

const relativeValues = computed(() => {
	return relativeChanges.value.concat(newRelatives.value);
});
// function onClickConfirm() {
// 	emit("close-modal");
// }

export type TNextOfKin = {
	id?: number;
	name: string;
	relativetype_id: number;
	phone: string;
};
const relativeTypes = ref([]);

async function getRelativeTypes() {
	try {
		const res = await api.getRelativeTypes();
		relativeTypes.value = res.data.data;
	} catch (error) {
		console.log(error);
	}
}

const hasChanges = computed(() => {
	return (
		newRelatives.value.length ||
		relativeChanges.value.length !== props.relatives.length ||
		relativeChanges.value.some((relative) => {
			const originalRelative = props.relatives[relative.index];
			return (
				relative.name !== originalRelative.name ||
				relative.relativetype_id !== originalRelative.relativetype_id ||
				relative.phone !== originalRelative.phone
			);
		})
	);
});

const table_columns = ref<TTableColumn[]>([
	{
		id: "name",
		label: "Name",
	},
	{
		id: "relativetype_id",
		label: "Relation Type",
	},
	{
		id: "phone",
		label: "Phone",
	},
	{ id: "remove", label: "" },
]);

function addNewRow() {
	newRelatives.value.push({
		name: "",
		relativetype_id: null,
		phone: "",
		new: true,
		index: newRelatives.value.length,
	});
}

function handleConfirm() {
	emit("confirm", relativeValues.value);
	emit("close-modal");
}

function handleRemoveRow(row) {
	if (row.new) {
		newRelatives.value.splice(row.index, 1);
	} else {
		relativeChanges.value.splice(row.index, 1);
	}
}

onBeforeMount(() => {
	getRelativeTypes();
});
</script>

<template>
	<div class="confirm-modal">
		<div class="content">
			<modern-table
				:columns="table_columns"
				:rows="relativeValues"
				:overflow="false"
			>
				<template #table-body-cell="slotProps">
					<td v-if="slotProps.column.id === 'relativetype_id'">
						<!-- <button @click="test(slotProps)">test</button> -->
						<template v-if="slotProps.row.new">
							<multiselect-form
								v-model="newRelatives[slotProps.row.index].relativetype_id"
								valueProp="id"
								label="name"
								:options="relativeTypes"
								searchable
								@update:model-value="
									newRelatives[slotProps.row.index].relativetype_id = $event.id
								"
							/>
						</template>
						<template v-else>
							<multiselect-form
								v-model="relativeChanges[slotProps.row.index].relativetype_id"
								valueProp="id"
								label="name"
								:options="relativeTypes"
								searchable
								@update:model-value="
									relativeChanges[slotProps.row.index].relativetype_id =
										$event.id
								"
							/>
						</template>
					</td>
					<td v-else-if="slotProps.column.id === 'name'">
						<template v-if="slotProps.row.new">
							<input
								v-model="newRelatives[slotProps.row.index].name"
								type="text"
							/>
						</template>
						<template v-else>
							<input
								v-model="relativeChanges[slotProps.row.index].name"
								type="text"
							/>
						</template>
					</td>
					<td v-else-if="slotProps.column.id === 'phone'">
						<template v-if="slotProps.row.new">
							<input
								v-model="newRelatives[slotProps.row.index].phone"
								type="text"
							/>
						</template>
						<template v-else>
							<input
								v-model="relativeChanges[slotProps.row.index].phone"
								type="text"
							/>
						</template>
					</td>
					<td v-else>
						<i-fa4-times-circle-o
							class="danger"
							@click="handleRemoveRow(slotProps.row)"
						/>
					</td>
				</template>
			</modern-table>
			<div class="add-new-row-button">
				<button @click="addNewRow">Add new row</button>
			</div>
		</div>

		<div class="button-wrap">
			<button
				:disabled="!hasChanges"
				@click="handleConfirm"
			>
				CONFIRM
			</button>
			<button
				class="danger"
				@click="emit('close-modal')"
			>
				CANCEL
			</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
.confirm-modal {
	padding: 20px;
	.content {
		padding: 20px;

		td {
			margin: 10px;
			padding: 10px;
			.multiselect-form {
				min-width: 150px;
			}
			.danger {
				color: $red;
				cursor: pointer;
			}
		}
		.add-new-row-button {
			margin-top: 15px;
		}
	}
	.button-wrap {
		display: flex;
		justify-content: center;
		gap: 20px;
		width: 100%;
	}
}
</style>
