<script setup lang="ts">
// import { getFormattedTime } from "@/assets/js/helpers";

// Props definition
const props = defineProps({
	files: {
		type: Array,
		required: true,
	},
	pendingDeleteFiles: {
		type: Array,
		required: true,
	},
	canDeleteFiles: {
		type: Boolean,
		default: true,
	},
	canOpenFile: {
		type: Boolean,
		default: true,
	},
	groupedKeyList: {
		type: Array,
		default: () => [],
	},
});

// Emits definition
const emit = defineEmits(["open-file", "download-file", "delete-file"]);

// Reactive data
// const fileToBeDeleted = ref<null | string>(null);

// Computed property for filtered files
const filteredFiles = computed(() => props.files);

// Helper methods
const isFileBeingDeleted = (file: any) => {
	return props.pendingDeleteFiles.includes(file.id);
};

const onOpenFile = (file: any) => {
	// { fileUrl: string; id: string }
	if (isFileBeingDeleted(file) || !props.canOpenFile) {
		console.warn("Canceled action");
		return;
	}
	emit("open-file", { link: file.fileUrl }, file);
};

const onDownloadFile = (file: any) => {
	// { fileUrl: string; id: string }
	if (isFileBeingDeleted(file)) {
		console.warn("Canceled action");
		return;
	}
	emit("download-file", { link: file.fileUrl }, file);
};

const onDeleteFile = (file: any) => {
	// { id: string }
	if (isFileBeingDeleted(file)) {
		console.warn("Canceled action");
		return;
	}
	emit("delete-file", file.id);
};

// const getFormattedTimeFn = (createdAt?: string | null) => {
// 	return createdAt ? getFormattedTime(createdAt, "DD/MM/YYYY HH:mm") : null;
// };

const setGroupedClass = (item: Record<string, any>, currIndex: number) => {
	const hasInitialValues =
		props.groupedKeyList.length &&
		// @ts-ignore
		props.groupedKeyList.every((key) => item[key]); // !== undefined

	if (!hasInitialValues) {
		return [];
	}

	const checkSameParameter = (key: any, currentRow: Record<string, any>) => {
		return item[key] === currentRow[key];
	};

	const array = filteredFiles.value;
	const totalLeng = array.length;

	const classSet = new Set<string>();
	let hasPrepend = false;
	let hasPostpend = false;
	const startIndex = Math.max(currIndex - 1, 0);
	const endIndex = Math.min(currIndex + 2, totalLeng);

	for (let localIndex = startIndex; localIndex < endIndex; localIndex++) {
		if (localIndex === currIndex) continue;

		const currentRowEl = array[localIndex];
		const isAllKeysSame = props.groupedKeyList.every((key) =>
			checkSameParameter(key, currentRowEl),
		);

		if (isAllKeysSame) {
			classSet.add("grouped");
			if (currIndex < localIndex) {
				hasPrepend = true;
			} else if (currIndex > localIndex) {
				hasPostpend = true;
			}
		}
	}

	if (hasPrepend && !hasPostpend) {
		classSet.add("first");
	} else if (!hasPrepend && hasPostpend) {
		classSet.add("last");
	}

	return Array.from(classSet);
};
</script>

<template>
	<div class="file-list-management">
		<table class="table sticky">
			<thead>
				<tr>
					<th>NAME</th>
					<th>SIZE</th>
					<slot name="table-col"></slot>
					<th
						colspan="2"
						style="width: 80px"
					>
						ACTIONS
					</th>
				</tr>
			</thead>
			<tfoot v-show="false">
				<tr>
					<td
						colspan="4"
						style="text-align: center; padding: 1.5em 0"
					></td>
				</tr>
			</tfoot>
			<tbody>
				<template
					v-for="(file, index) in filteredFiles"
					:key="index"
				>
					<slot
						name="table-row"
						:item="file"
						:index="index"
					>
						<tr
							:key="index"
							class="fileRow"
							:class="[
								setGroupedClass(file, index),
								// @ts-ignore
								file.__rowClasses,
							]"
						>
							<td
								data-cell="name"
								:class="{ deleting: isFileBeingDeleted(file) }"
							>
								<span
									style="cursor: pointer"
									@click="onOpenFile(file)"
								>
									{{
										// @ts-ignore
										file.fileName
									}}
								</span>
							</td>
							<td data-cell="size">
								{{
									// @ts-ignore
									file.fileSize + " bytes"
								}}
							</td>
							<slot
								name="table-body-cell"
								:item="file"
								:index="index"
							></slot>
							<td
								class="has-icon"
								data-cell="download-col"
							>
								<span @click="onDownloadFile(file)">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewbox="0 0 24 24"
									>
										<path
											fill="#0468AF"
											d="M19.37 9.105c-.327-3.41-3.167-6.087-6.663-6.087-2.706 0-5.022 1.606-6.09 3.91-.32-.103-.656-.172-1.01-.172-1.858 0-3.364 1.507-3.364 3.364 0 .37.074.718.183 1.05C.98 12.01 0 13.557 0 15.35c0 2.685 2.175 4.86 4.86 4.86h13.454c3.097 0 5.605-2.51 5.605-5.607 0-2.733-1.96-5.003-4.55-5.498zm-3.846 4.844l-2.826 2.785c-.57.572-.922.553-1.476 0L8.395 13.95c-.405-.662-.074-1.203.738-1.203h1.332v-3c0-.413.335-.75.747-.75h1.495c.414 0 .748.337.748.75v3h1.33c.81 0 1.145.54.74 1.202z"
										></path>
									</svg>
								</span>
							</td>
							<td
								v-if="canDeleteFiles"
								class="has-icon"
								data-cell="delete-col"
							>
								<span @click="onDeleteFile(file)">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewbox="0 0 24 24"
									>
										<path
											fill="#FF3B3B"
											d="M11.987 3C7.024 3 3 7.025 3 11.99c0 4.963 4.024 8.987 8.987 8.987 4.966 0 8.99-4.025 8.99-8.988 0-4.965-4.024-8.99-8.99-8.99zm3.865 11.423l-1.432 1.43s-2.264-2.43-2.434-2.43c-.166 0-2.43 2.43-2.43 2.43l-1.434-1.43s2.433-2.232 2.433-2.43c0-.203-2.433-2.435-2.433-2.435l1.433-1.433s2.284 2.43 2.43 2.43c.15 0 2.435-2.43 2.435-2.43l1.432 1.433s-2.433 2.265-2.433 2.434c0 .162 2.432 2.43 2.432 2.43z"
										></path>
									</svg>
								</span>
							</td>
						</tr>
					</slot>
				</template>
			</tbody>
		</table>
	</div>
</template>

<style lang="scss" scoped>
$label-col: #7c91ae;
$table-header-background: #f2f7fa;

.has-icon {
	> span {
		cursor: pointer;
	}
}

.file-list-management {
	// height: 100%; // Don't use this, it breaks modal
	width: 100%;
	padding: 10px 0;
	table {
		width: 100%;
		margin-bottom: 0;
		border-radius: 0;

		tr {
			border: 1px solid #dde6eb;
		}
		th {
			background: $table-header-background;
			padding: 7px 10px;
			&.sort {
				text-wrap: nowrap;
			}
		}
		td {
			// border-bottom: 1px solid #dde6eb;
			padding: 7px 10px;
		}

		td.deleting {
			span {
				color: $red-cpl;
				text-decoration: line-through;
			}
		}

		thead td {
			color: $label-col;
			border: none;
			font-weight: 900;
			font-size: smaller;
		}
	}
	.fileRow {
		border: 1px solid #dde6eb;

		td:first-child {
			font-size: 1.1em;
		}
	}

	tr {
		&.grouped {
			&:not(.first, .last) {
				td {
					border-top: none;
					border-bottom: none;
				}
			}

			&.first {
				td {
					border-bottom: none;
				}
			}

			&.last {
				td {
					border-top: none;
				}
			}
		}
	}

	iframe {
		width: 100%;
		height: 100%;
	}
}
</style>
