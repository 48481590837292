<script lang="ts" setup>
import api from "@/api";
import { parseDateObjWithTimeZone } from "@/assets/js/dateHelper";
import { Errors } from "@/assets/js/errors";
import { parseErrors } from "@/assets/js/parseErrors";
import { handleFormatingShiftPriceOverwriteParamsForBackend } from "@/assets/js/shiftPriceOverwriteType";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const shiftsStore = useShiftsStore();
const toastStore = useToastStore();
const store = useDefaultStore();
const sessionStore = useSessionStore();
const can = sessionStore.can;
const canUseNexusMiddleware = store.initialStatus.can_use_nexus_middleware;
const props = defineProps<{
	modalProps: any;
	isUserTemp?: boolean;
}>();
const emit = defineEmits([
	"show-before-modal",
	"show-after-modal",
	"close-modal",
]);
const arrayWarrningsMessages = ref<any[]>([]);
const count = ref<number>(0);
const errorMessage = ref<string>("");
const shiftId = ref<number | null>(null);
type TDateTimePicker = {
	from: { time: string };
	to: { time: string };
};
const dt = ref<TDateTimePicker>({
	from: { time: "" },
	to: { time: "" },
});
const oncallDate = ref<TDateTimePicker>({
	from: { time: "" },
	to: { time: "" },
});
const sleepoverDate = ref<TDateTimePicker>({
	from: { time: "" },
	to: { time: "" },
});
const isAwaken = ref<boolean>(false);
const awakenDate = ref<TDateTimePicker>({
	from: { time: "" },
	to: { time: "" },
});
const selectedLocation = ref<any>(props.modalProps.location);
const selectedCategories = ref<any>(
	props.modalProps.shiftRequest.subcategories || [],
);
const selectedCategoriesTags = ref<any>(
	props.modalProps.shiftRequest.subcategories.map((subcat) => subcat.id),
);
const breakMinutes = ref<any>(props.modalProps.breakMinutes);
const errors = ref<any>(new Errors());
const url = ref<any>(props.modalProps.url);
const disableButton = ref<boolean>(false);
const isRecalc = ref<boolean>(false);
const optionsSubcategories = ref<any>([]);
const originalCategoryVals = ref<any>([
	...props.modalProps.shiftRequest.subcategories,
]);
const isWarningMessageVisible = ref<boolean>(false);
const warningMessageText = ref<any>({});
const isConfirmedSixMonthRule = ref<boolean>(false);
const shiftPriceOverwriteModalModalIsOpen = ref<boolean>(false);
const shiftPriceOverwriteData = ref<any>({});
const shiftPriceOverwriteShiftId = ref<number | null>(null);
const shiftPriceOverwriteDataIsLoading = ref<boolean>(false);
const shiftPriceOverwritePromtError = ref<any>("");
const checkShiftPriceOverwriteRes = ref<any>(null);
const shiftPriceOverwriteErrorKeys = ref<any>([]);
const shiftPriceOverwriteCalcitemsMeta = ref<any>({});
const canShowWarningMessage = computed(
	() => store.initialStatus.shift_sign_off_warning,
);
const locations = computed(() => props.modalProps.location?.client?.locations);
const startDateTimeParsed = computed(() =>
	parseDateObjWithTimeZone(props.modalProps.startDateTime),
);
const endDateTimeParsed = computed(() =>
	parseDateObjWithTimeZone(props.modalProps.endDateTime),
);
const isOnCall = computed(() => props.modalProps.shift.type === "ONCL");
const isSleepover = computed(() => props.modalProps.shift.type === "SLPO");

function getShiftPartTime(code, key) {
	const partDateObj = props.modalProps.shift.shiftParts?.find(
		(partObj) => partObj.type === code,
	);
	return props.modalProps.shift.shiftParts?.length > 0 && partDateObj
		? parseDateObjWithTimeZone(partDateObj[key])
		: "";
}

function setupShiftPartsPicker() {
	const fromTime = props.modalProps?.shift?.shiftParts?.[0]?.from?.date;
	const toTime = props.modalProps?.shift?.shiftParts?.[0]?.to?.date;
	if (fromTime) {
		console.warn(fromTime);
		oncallDate.value.from = { time: fromTime };
	}
	if (toTime) {
		oncallDate.value.to = { time: toTime };
	}
}

async function fetchShiftPriceOverwriteCheckInfo() {
	if (!can("can-use-shift-price-overwrite")) return;
	const params = {
		shift_id: props.modalProps.shiftId,
	};
	try {
		const res = await api.getShiftPriceOverwriteCheckInfo(params);
		checkShiftPriceOverwriteRes.value = res.data.data || null;
	} catch (err) {
		const errors = parseErrors(err);
		console.log(errors);
		// if (Array.isArray(errors) && errors.length > 0) {
		// 	errors.map((e) => {
		// 		toastStore.openToastError(e);
		// 	});
		// } else if (errors) {
		// 	toastStore.openToastError(errors);
		// }
	}
}
async function fetchViableSubcategories() {
	if (!can("recalculate-shift")) return;
	const params = {
		id: props.modalProps.shiftId,
		location_id: selectedLocation.value.id,
	};
	try {
		const res = await api.getViableSubcategories(params);
		optionsSubcategories.value = res.data.data || [];
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}
async function fetchMultipleShiftsDay() {
	if (!canShowWarningMessage.value) {
		// Agency denied
		return;
	}

	const params = {
		shift_id: shiftId.value,
		start_time: dt.value.from.time,
	};
	try {
		const res = await api.postMultipleShiftsDay(params);
		const responseData = res.data.data;
		warningMessageText.value =
			Array.isArray(responseData) || !responseData ? {} : res.data.data;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}
function onChangeStartDate(evt) {
	fetchMultipleShiftsDay();
}
async function onChangeSelLocationProp(evt) {
	// Filter only available subcategories
	selectedLocation.value = evt; // used because v-model delays commit
	await fetchViableSubcategories();
	// eslint-disable-next-line unicorn/prefer-set-has
	const availableSubsId = optionsSubcategories.value.map((subCat) => subCat.id);
	selectedCategories.value = selectedCategories.value.filter((selCat) => {
		return availableSubsId.includes(selCat.id);
	});
}
function onButtonSubmitTimes() {
	disableButton.value = true;
	if (
		!isRecalc.value &&
		canShowWarningMessage.value &&
		Object.keys(warningMessageText.value).length
	) {
		// Agency specific warning message
		arrayWarrningsMessages.value = Object.entries(warningMessageText.value);
		errorMessage.value = arrayWarrningsMessages.value[0][1][0];
		isWarningMessageVisible.value = true;
	} else {
		onSaveLogicFunc();
	}
}
function onSaveLogicFunc() {
	if (isRecalc.value) {
		isWarningMessageVisible.value = false;
		recalcShift();
	} else {
		isWarningMessageVisible.value = false;
		clientSignOffFunc();
	}
}
function generateParamsForSave() {
	const payload: any = {
		start_time: dt.value.from.time,
		end_time: dt.value.to.time,
		location_id: selectedLocation.value?.id,
		break_minutes: breakMinutes.value,
		shift_parts: [],
	};

	if (isOnCall.value) {
		const fromTime = oncallDate.value.from.time;
		const toTime = oncallDate.value.to.time;

		if (fromTime && toTime) {
			payload.shift_parts = [
				{
					from: fromTime,
					to: toTime,
					type: "ONCL",
				},
			];
		}
	} else if (isSleepover.value) {
		const fromTime = sleepoverDate.value.from.time;
		const toTime = sleepoverDate.value.to.time;

		if (fromTime && toTime) {
			payload.shift_parts = [
				{
					from: fromTime,
					to: toTime,
					type: "SLPO",
				},
			];
		}
		// dont include awke params if its turned off
		if (isAwaken.value) {
			const fromAwakenTime = awakenDate.value.from.time;
			const toAwakenTime = awakenDate.value.to.time;

			if (fromAwakenTime && toAwakenTime) {
				payload.shift_parts.push({
					from: fromAwakenTime,
					to: toAwakenTime,
					type: "AWKE",
				});
			}
		}
	}

	const originalCatIds = originalCategoryVals.value.map((cat) => cat.id);
	const newCatIds = selectedCategoriesTags.value;
	const isPendingSubcatChange =
		originalCatIds.length !== newCatIds.length ||
		!originalCatIds.every((catId) => newCatIds.includes(catId));
	if (!isRecalc.value || (isRecalc.value && isPendingSubcatChange)) {
		payload.subcategories = selectedCategoriesTags.value.map((catId) => {
			return { id: catId };
		}); // Multiple
	}

	return payload;
}
function callModalClose() {
	isConfirmedSixMonthRule.value = false;
	closeShiftPriceOverwriteModal();
	emit("close-modal");
}
async function clientSignOffFunc() {
	disableButton.value = true;
	/**
	 * API client sign off shift
	 * POST
	 * shiftId - id for a shift to be changed
	 * params - object that contains new start_time and end_time values and break minutes
	 */
	try {
		const params = {
			url: url.value,
			data: generateParamsForSave(),
		};
		const res = await shiftsStore.clientSignOff(
			params.url,
			params.data,
			props.modalProps.index,
		);
		if (res) toastStore.openToastSuccess("Shift signed off");
		disableButton.value = false;

		/**
		 * change like this, or propagate an event?
		 * update state for the affected shift object ASK QUESTION
		 */
		callModalClose();
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		disableButton.value = false;
	}
}
async function recalcShift() {
	const params = {
		url: url.value,
		data: generateParamsForSave(),
	};
	if (canUseNexusMiddleware && props.modalProps.checkRecalculateBefore) {
		callModalClose();
		emit("show-before-modal", props.modalProps, params);
		if (!props.modalProps.checkRecalculateAfter) {
			return;
		}
	}
	/**
	 * API client recalculate shift
	 * POST
	 * shiftId - id for a shift to be changed
	 * params - object that contains new start_time and end_time values and break minutes
	 */
	try {
		const res = await shiftsStore.clientRecalculateShift(
			params.url,
			params.data,
			props.modalProps.index,
		);
		if (res) {
			toastStore.openToastSuccess("Shift recalculated.");
			callModalClose();
			if (canUseNexusMiddleware && props.modalProps.checkRecalculateAfter) {
				emit("show-after-modal", props.modalProps);
			}
		}
		disableButton.value = false;
		/**
		 * change like this, or propagate an event?
		 * update state for the affected shift object ASK QUESTION
		 */
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		disableButton.value = false;
	}
}
async function handleOpenShiftPriceOverwriteModal(shiftId) {
	shiftPriceOverwriteModalModalIsOpen.value = true;
	await fetchShiftPriceOverwriteData(shiftId);
	await fetchShiftPriceOverwriteCalcitemsMeta(shiftId);
}
async function fetchShiftPriceOverwriteData(shiftId) {
	shiftPriceOverwriteData.value = {};
	const params = {
		shift_id: shiftId,
	};
	shiftPriceOverwriteDataIsLoading.value = true;
	shiftPriceOverwriteShiftId.value = shiftId;
	try {
		const res = await api.getShiftPriceOverwriteData(params);
		shiftPriceOverwriteData.value = res.data.data;
		shiftPriceOverwriteDataIsLoading.value = false;
	} catch (error) {
		shiftPriceOverwritePromtError.value = parseErrors(
			error,
			"Error overriding shift",
		);
		shiftPriceOverwriteModalModalIsOpen.value = true;
	}
}
async function fetchShiftPriceOverwriteCalcitemsMeta(shiftId) {
	const params = {
		shift_id: shiftId,
	};
	try {
		const res = await api.getShiftPriceOverwriteCalcitemsMeta(params);
		shiftPriceOverwriteCalcitemsMeta.value = res.data.data;
	} catch {
		console.error("Failed fetching shift price overwrite calcitems meta");
	}
}
function closeShiftPriceOverwritePrompt() {
	shiftPriceOverwritePromtError.value = "";
}
function closeShiftPriceOverwriteModal() {
	shiftPriceOverwriteModalModalIsOpen.value = false;
	shiftPriceOverwriteData.value = {};
	shiftPriceOverwriteShiftId.value = null;
	shiftPriceOverwritePromtError.value = "";
	shiftPriceOverwriteDataIsLoading.value = false;
}

function areYouSureSixMonths() {
	count.value++;
	if (count.value === arrayWarrningsMessages.value.length) {
		onSaveLogicFunc();
		count.value = 0;
	} else {
		errorMessage.value = arrayWarrningsMessages.value[count.value][1][0];
	}
}
async function confirmShiftPriceOverwriteCalculation() {
	const cloneData = JSON.parse(JSON.stringify(shiftPriceOverwriteData.value));
	// shiftPriceOverwriteData.value = {};
	shiftPriceOverwriteDataIsLoading.value = true;
	const params = handleFormatingShiftPriceOverwriteParamsForBackend(cloneData);
	params.shift_id = shiftPriceOverwriteShiftId.value;
	try {
		const res = await shiftsStore.confirmShiftPriceOverwriteCalculation(
			params,
			// props.modalProps.index,
		);
		// const shift = res.data.data;
		if (res) {
			toastStore.openToastSuccess("Shift successfully overriden.");
			shiftPriceOverwriteData.value = {};
			callModalClose();
		}
	} catch (error) {
		shiftPriceOverwritePromtError.value = parseErrors(
			error,
			"Error overriding shift",
		);
		shiftPriceOverwriteDataIsLoading.value = false;
		shiftPriceOverwriteData.value = cloneData;
	}
}

async function handleRecalculateShift() {
	const cloneData = JSON.parse(JSON.stringify(shiftPriceOverwriteData.value));
	shiftPriceOverwriteData.value = {};
	shiftPriceOverwriteDataIsLoading.value = true;
	const params = handleFormatingShiftPriceOverwriteParamsForBackend(cloneData);
	params.shift_id = shiftPriceOverwriteShiftId.value;
	try {
		const res = await api.getShiftPriceOverwriteCalculationPreview(params);
		shiftPriceOverwriteData.value = res.data.data;
		shiftPriceOverwriteDataIsLoading.value = false;
	} catch (error) {
		shiftPriceOverwritePromtError.value = parseErrors(
			error,
			"Error overriding shift",
		);
		shiftPriceOverwriteDataIsLoading.value = false;
		shiftPriceOverwriteData.value = cloneData;
	}
}

onMounted(async () => {
	dt.value.from.time = startDateTimeParsed.value;
	dt.value.to.time = endDateTimeParsed.value;
	oncallDate.value.from = { time: getShiftPartTime("ONCL", "from") };
	oncallDate.value.to = { time: getShiftPartTime("ONCL", "to") };
	sleepoverDate.value.from = { time: getShiftPartTime("SLPO", "from") };
	sleepoverDate.value.to = { time: getShiftPartTime("SLPO", "to") };
	awakenDate.value.from = { time: getShiftPartTime("AWKE", "from") };
	awakenDate.value.to = { time: getShiftPartTime("AWKE", "to") };
	if (awakenDate.value.from.time && awakenDate.value.to.time)
		isAwaken.value = true;
	setupShiftPartsPicker();
	shiftId.value = props.modalProps.shiftId;
	isRecalc.value = Boolean(props.modalProps.isRecalc);
	await fetchShiftPriceOverwriteCheckInfo();
	await fetchViableSubcategories();
	// It is not necessary for recalc but it must be in other awaiting dialogs.
	// Checking the modalprops which has only the recalc "checkRecalculateData".
	if (!props.modalProps.checkRecalculateData) {
		fetchMultipleShiftsDay();
	}
});
</script>
<template>
	<div class="awaiting-dialog">
		<shift-price-overwrite-modal
			v-if="canUseNexusMiddleware && can('can-use-shift-price-overwrite')"
			:modalIsOpen="shiftPriceOverwriteModalModalIsOpen"
			:shiftInitialData="shiftPriceOverwriteData"
			:shiftId="shiftPriceOverwriteShiftId"
			:shiftPriceOverwritePromtError="shiftPriceOverwritePromtError"
			:errorKeys="shiftPriceOverwriteErrorKeys"
			:calcitemsMeta="shiftPriceOverwriteCalcitemsMeta"
			@close-modal="closeShiftPriceOverwriteModal"
			@close-prompt="closeShiftPriceOverwritePrompt"
			@recalculate-shift-data="handleRecalculateShift"
			@override-shift="confirmShiftPriceOverwriteCalculation"
		/>
		<div class="inputs">
			<div class="field p-right10">
				<label class="label">Start time</label>
				<p class="control">
					<modern-date-picker
						v-model="dt.from.time"
						:enable-time-picker="true"
						placeholder="Date from"
						:clearable="false"
						format="dd/MM/yyyy HH:mm"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						style="width: 300px; border-radius: 21px; height: 40px"
						@change="onChangeStartDate"
					/>
					<span
						v-if="errors.has('start_time')"
						class="help is-danger"
						v-html="errors.get('start_time')"
					></span>
				</p>
			</div>
			<div class="field p-left10">
				<label class="label">End time</label>
				<p class="control">
					<modern-date-picker
						v-model="dt.to.time"
						:isDisabled="dt.to.time === null"
						:isFormatToLocal="false"
						:enable-time-picker="true"
						placeholder="Date to"
						:clearable="false"
						format="dd/MM/yyyy HH:mm"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						style="width: 300px; border-radius: 21px; height: 40px"
					/>
					<span
						v-if="errors.has('end_time')"
						class="help is-danger"
						v-html="errors.get('end_time')"
					></span>
				</p>
			</div>
		</div>
		<label class="label">Ward</label>
		<p class="control">
			<multiselect-form
				:model-value="selectedLocation"
				:options="locations"
				:close-on-select="true"
				:searchable="true"
				select-label=""
				value-prop="id"
				track-by="name"
				label="name"
				mode="single"
				placeholder="SELECT WARD"
				:internal-search="false"
				:canClear="false"
				:canDeselect="false"
				@select="onChangeSelLocationProp"
			/>
		</p>
		<p class="control">
			<label class="label">Subcategories</label>
			<multiselect-form
				v-model="selectedCategoriesTags"
				:options="optionsSubcategories"
				:disabled="!isRecalc"
				:close-on-select="false"
				:searchable="true"
				select-label=""
				value-prop="id"
				track-by="name"
				label="name"
				mode="tags"
				placeholder="PICK A SUBCATEGORY"
				:internal-search="false"
				:canClear="false"
				:canDeselect="false"
				:max="modalProps.shift.type === 'ONCL' ? 1 : 999"
				@select="errors.clear('subcategories')"
			/>
			<span
				v-if="errors.has('subcategories')"
				class="help is-danger"
				v-html="errors.get('subcategories')"
			></span>
		</p>
		<div
			v-if="isOnCall"
			class="inputs"
		>
			<div class="field p-right10">
				<label class="label">On Call Start time</label>
				<p class="control">
					<modern-date-picker
						v-model="oncallDate.from.time"
						:isFormatToLocal="false"
						:clearable="false"
						:enable-time-picker="true"
						format="dd/MM/yyyy HH:mm"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						style="width: 300px; border-radius: 21px; height: 40px"
					/>
					<span
						v-if="errors.has('start_time')"
						class="help is-danger"
						v-html="errors.get('start_time')"
					></span>
				</p>
			</div>
			<div class="field p-left10">
				<label class="label">On Call End time</label>
				<p class="control">
					<modern-date-picker
						v-model="oncallDate.to.time"
						:isDisabled="oncallDate.to.time === null"
						:isFormatToLocal="false"
						:clearable="false"
						:enable-time-picker="true"
						format="dd/MM/yyyy HH:mm"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						style="width: 300px; border-radius: 21px; height: 40px"
					/>
					<span
						v-if="errors.has('end_time')"
						class="help is-danger"
						v-html="errors.get('end_time')"
					></span>
				</p>
			</div>
		</div>
		<template v-if="isSleepover">
			<div class="inputs">
				<div class="field p-right10">
					<label class="label">Sleepover Start time</label>
					<p class="control">
						<modern-date-picker
							v-model="sleepoverDate.from.time"
							:isFormatToLocal="false"
							:clearable="false"
							:enable-time-picker="true"
							format="dd/MM/yyyy HH:mm"
							outputFormatString="YYYY-MM-DD HH:mm:ss"
							style="width: 300px; border-radius: 21px; height: 40px"
						/>
						<span
							v-if="errors.has('start_time')"
							class="help is-danger"
							v-html="errors.get('start_time')"
						></span>
					</p>
				</div>
				<div class="field p-left10">
					<label class="label">Sleepover End time</label>
					<p class="control">
						<modern-date-picker
							v-model="sleepoverDate.to.time"
							:isDisabled="sleepoverDate.to.time === null"
							:isFormatToLocal="false"
							:clearable="false"
							:enable-time-picker="true"
							format="dd/MM/yyyy HH:mm"
							outputFormatString="YYYY-MM-DD HH:mm:ss"
							style="width: 300px; border-radius: 21px; height: 40px"
						/>
						<span
							v-if="errors.has('end_time')"
							class="help is-danger"
							v-html="errors.get('end_time')"
						></span>
					</p>
				</div>
			</div>
			<div class="inputs is-awaken">
				<input
					id="awaken"
					v-model="isAwaken"
					type="checkbox"
				/>
				<label
					class="label"
					for="awaken"
				>
					Was the sleeping time interrupted?
				</label>
			</div>
		</template>
		<div
			v-if="isAwaken"
			class="inputs"
		>
			<div class="field p-right10">
				<label class="label">Awaken Start time</label>
				<p class="control">
					<modern-date-picker
						v-model="awakenDate.from.time"
						:isFormatToLocal="false"
						:clearable="false"
						:enable-time-picker="true"
						format="dd/MM/yyyy HH:mm"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						style="width: 300px; border-radius: 21px; height: 40px"
					/>
					<span
						v-if="errors.has('start_time')"
						class="help is-danger"
						v-html="errors.get('start_time')"
					></span>
				</p>
			</div>
			<div class="field p-left10">
				<label class="label">Awaken End time</label>
				<p class="control">
					<modern-date-picker
						v-model="awakenDate.to.time"
						:isDisabled="awakenDate.to.time === null"
						:isFormatToLocal="false"
						:clearable="false"
						:enable-time-picker="true"
						format="dd/MM/yyyy HH:mm"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						style="width: 300px; border-radius: 21px; height: 40px"
					/>
					<span
						v-if="errors.has('end_time')"
						class="help is-danger"
						v-html="errors.get('end_time')"
					></span>
				</p>
			</div>
		</div>
		<label class="label">Breaks</label>
		<p class="control"></p>
		<div class="has-suffix">
			<input
				v-model="breakMinutes"
				class="input"
				type="number"
				:disabled="isOnCall || isSleepover"
			/>
			<span class="is-suffix">in minutes</span>
		</div>
		<div
			v-if="!isWarningMessageVisible"
			class="buttons-centered"
		>
			<button
				v-if="canUseNexusMiddleware && can('can-use-shift-price-overwrite')"
				class="button is-generic-app-blue is-caps-lock"
				:disabled="
					shiftPriceOverwriteModalModalIsOpen ||
					!checkShiftPriceOverwriteRes?.can_be_overriden
				"
				:class="{ 'is-loading': shiftPriceOverwriteDataIsLoading }"
				@click.prevent="handleOpenShiftPriceOverwriteModal(shiftId)"
			>
				OVERRIDE
			</button>
			<button
				class="button is-generic-app-blue is-caps-lock"
				:disabled="disableButton"
				@click.prevent="onButtonSubmitTimes"
			>
				SUBMIT
			</button>
			<button
				class="button alt is-outlined is-caps-lock cancel-btn"
				@click.prevent="callModalClose"
			>
				CANCEL
			</button>
		</div>
		<div
			v-if="canShowWarningMessage && isWarningMessageVisible"
			class="delete-section"
		>
			<span class="action-message">
				<span v-if="errorMessage">{{ errorMessage }}</span>
			</span>
			<span class="action-buttons">
				<a
					class="button is-small is-confirm"
					@click="areYouSureSixMonths"
				>
					Accept
				</a>
				<a
					class="button is-small is-deny"
					@click="callModalClose"
				>
					Refuse
				</a>
			</span>
		</div>
	</div>
</template>
<style scoped lang="scss">
$is-confirm: #ff3b3b;
$is-deny: #2669b0;
$action-msg-color: #405168;
.inputs {
	display: flex;

	&.is-awaken {
		align-items: center;
		justify-content: flex-start;
		margin: 8px 0;

		input {
			cursor: pointer;
			margin-top: 6px;
			margin-right: 10px;
		}

		label {
			cursor: pointer;
		}
	}
}

.has-suffix {
	display: flex;
	align-items: center;
}

input + span.is-suffix {
	font-size: smaller;
	flex: 1 0 auto;
	margin-left: 1em;
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0;
		min-width: 90px;
		height: 30px;
	}
}

.p-left10 {
	padding-left: 10px;
}

.p-right10 {
	padding-right: 10px;
}

.delete-section {
	margin: 10px 0;
	padding: 10px 0;
	display: flex;
	flex-direction: column;
	border-top: 1px solid rgba($action-msg-color, 0.15);
	border-bottom: 1px solid rgba($action-msg-color, 0.15);
	align-items: center;
	justify-content: center;
	user-select: none;

	.action-message {
		display: flex;
		gap: 5px;
		margin-right: 1em;
		color: $action-msg-color;
		text-align: center;
		font-weight: 600;

		.user-name {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			max-width: 150px;
		}
	}

	.action-buttons {
		a.button {
			border: none;
			margin: 0 0.3em;
		}
	}

	.is-confirm,
	.is-deny {
		font-weight: 900;
		font-size: 1.1em;
	}

	.is-confirm {
		color: $is-confirm;
	}

	.is-deny {
		color: $is-deny;
	}
}

.fade-enter-to,
.fade-leave {
	max-height: 500px;
}

.fade-enter,
.fade-leave-to {
	max-height: 0;
}

.fade-enter-active {
	transition: all 20s ease;
}

.fade-leave-active {
	transition: all 0s ease;
}
</style>

<style lang="scss">
.awaiting-dialog {
	padding: 0 30px 30px;
	.cov-vue-date {
		width: 100%;
	}

	.cov-datepicker {
		width: 100%;
	}
}
.label {
	color: $label-accent-color-dark;
}
</style>
