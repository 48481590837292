<script setup lang="ts">
import { parseDateTime, parseExpenseValue } from "@/assets/js/helpers";
import { useDefaultStore } from "@/store";

// import { parseErrors } from "@/assets/js/parseErrors.js";

const store = useDefaultStore();
const canUseNexusMiddleware = store.initialStatus.can_use_nexus_middleware;
const monetarySymbol = store.initialStatus.monetary_symbol;

const emit = defineEmits(["close-modal"]);

const props = withDefaults(
	defineProps<{
		expense: any;
		activeTab: any;
	}>(),
	{},
);

function onCloseModal() {
	emit("close-modal");
}
function parseDistance(distance: string | null): string {
	return distance ? `${distance} km` : "";
}

const getAmountLabelFromActiveTab = computed(() => {
	switch (props.activeTab.id) {
		case 1: {
			return "Amount";
		}
		case 2: {
			return "Approved amount";
		}
		case 3: {
			return "Rejected amount";
		}
		default: {
			return "Amount";
		}
	}
});
</script>

<template>
	<div class="confirm-modal">
		<div class="table-wrapper">
			<table>
				<tr v-if="props.expense.id">
					<th>ID</th>
					<td>{{ props.expense.id }}</td>
				</tr>
				<tr v-if="props.expense?.client && props.expense?.client?.name">
					<th>Client Name</th>
					<td>{{ props.expense.client.name }}</td>
				</tr>
				<tr v-if="props.expense?.temp && props.expense?.temp?.name">
					<th>Temp Name</th>
					<td>{{ props.expense.temp.name }}</td>
				</tr>
				<tr v-if="props.expense?.date">
					<th>Date</th>
					<td>{{ parseDateTime(props.expense.date) }}</td>
				</tr>
				<tr v-if="props.expense?.startTime">
					<th>Start Time</th>
					<td>{{ parseDateTime(props.expense.startTime) }}</td>
				</tr>
				<tr v-if="props.expense?.endTime">
					<th>End Time</th>
					<td>{{ parseDateTime(props.expense.endTime) }}</td>
				</tr>
				<tr
					v-if="props.expense?.expenseType && props.expense?.expenseType?.label"
				>
					<th>Type</th>
					<td>{{ props.expense.expenseType.label }}</td>
				</tr>
				<tr v-if="props.expense?.engine_type">
					<th>Engine type</th>
					<td>{{ props.expense.engine_type }}</td>
				</tr>
				<tr v-if="props.expense?.description">
					<th>Description</th>
					<td>{{ props.expense.description }}</td>
				</tr>
				<tr v-if="props.expense?.locationfrom">
					<th>Location From</th>
					<td>{{ props.expense.locationfrom }}</td>
				</tr>
				<tr v-if="props.expense?.locationto">
					<th>Location To</th>
					<td>{{ props.expense.locationto }}</td>
				</tr>
				<tr v-if="props.expense?.location">
					<th>Location</th>
					<td>{{ props.expense.location }}</td>
				</tr>
				<tr v-if="props.expense?.distance">
					<th>Distance</th>
					<td>{{ parseDistance(props.expense.distance) }}</td>
				</tr>
				<tr v-if="props.expense?.status">
					<th>Status</th>
					<td>{{ props.expense.status }}</td>
				</tr>
				<tr v-if="props.expense?.userProcessed?.name">
					<th>Approver</th>
					<td>{{ props.expense.userProcessed.name }}</td>
				</tr>
				<tr v-if="props.expense?.userRejected?.name">
					<th>Rejected by</th>
					<td>{{ props.expense.userRejected.name }}</td>
				</tr>
				<tr v-if="props.expense?.reason">
					<th>Reason</th>
					<td>{{ props.expense.reason }}</td>
				</tr>
				<tr v-if="props.expense?.rejectreason">
					<th>Reject reason</th>
					<td>{{ props.expense.rejectreason }}</td>
				</tr>
				<tr v-if="props.expense?.old_value">
					<th>Provisionally calculated amount</th>
					<td>
						{{ parseExpenseValue(props.expense.old_value, monetarySymbol) }}
					</td>
				</tr>
				<tr v-if="props.expense?.value">
					<th>{{ getAmountLabelFromActiveTab }}</th>
					<td>{{ parseExpenseValue(props.expense.value, monetarySymbol) }}</td>
				</tr>
				<tr v-if="props.expense?.created_at">
					<th>Submitted at</th>
					<td>{{ parseDateTime(props.expense.created_at) }}</td>
				</tr>
				<tr v-if="canUseNexusMiddleware">
					<th>Batch Number</th>
					<td>{{ props.expense.external_batch_id }}</td>
				</tr>
				<tr v-if="canUseNexusMiddleware">
					<th>Timesheet ID</th>
					<td>{{ props.expense.external_id }}</td>
				</tr>
				<tr v-if="canUseNexusMiddleware">
					<th>Invoice ID</th>
					<td>{{ props.expense.invoice_id }}</td>
				</tr>
			</table>
		</div>
		<button @click="onCloseModal">Close</button>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 550px;
	min-height: 130px;
	max-height: 700px;
	gap: 10px;
	button {
		outline: none !important;
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		> div {
			margin: 5px;
		}
	}

	.table-wrapper {
		$text-color-lighter: $label-color;
		$scrollbar-color: $scrollbar-color;
		$scrollbar-color-thumb: $scrollbar-color-thumb;
		$table-header-background: #f2f7fa;
		$tr-hover-background: #f5f5f5;
		$td-text-color: #405168;

		--row-back: white;
		--row-back-odd: var(--vt-c-white-soft);

		display: flex;
		gap: 10px;
		overflow: auto;
		width: 100%;

		@document url-prefix() {
			// Only for Firefox
			* {
				scrollbar-color: $scrollbar-color $scrollbar-color-thumb;
				scrollbar-width: thin;
			}
		}

		&::-webkit-scrollbar {
			background-color: $scrollbar-color;
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $scrollbar-color-thumb;
			border-radius: 5px;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			border: 1px solid #dde6eb;
			td {
				font-size: 0.9rem;
				padding: 7px 10px;
				border-top: 1px solid #dde6eb;
				color: $td-text-color;
				&:not(.extra-row) {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 300px;
				}
			}

			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}

			tr {
				height: 40px;
				&:hover {
					background: $tr-hover-background;
				}
			}

			&.border {
				border: 1px solid gray;

				th,
				td {
					border: 1px solid gray;
				}
			}

			&.sticky {
				th {
					position: sticky;
					top: 0;
				}
			}
		}
	}
}
</style>
