import ForgotPassword from "@/pages/auth/ForgotPassword.vue";
import PasswordRecovery from "@/pages/auth/PasswordRecovery.vue";
import SignInPage from "@/pages/auth/SignInPage.vue";

export const auth_routes = [
	{
		path: "/auth/signin",
		name: "auth.signin",
		component: SignInPage,
		meta: {
			requiresGuest: true,
		},
	},
	{
		path: "/forgot-password",
		name: "auth.forgot-password",
		component: ForgotPassword,
		meta: {
			requiresGuest: true,
			permission: "",
			authComp: true,
			fail: "/error",
		},
	},
	{
		path: "/password/reset/:hash",
		name: "auth.password-recovery",
		component: PasswordRecovery,
		meta: {
			requiresGuest: true,
			permission: "",
			test: 1,
		},
	},
	{
		path: "/bank-details/verify-token",
		name: "auth.token-verification",
		component: {
			render() {
				return h("h1", "Not yet implemented");
			},
		},
		meta: {
			requiresGuest: false,
			permission: "",
			authComp: true,
			path: "/notification-centre/list-of-workers",
			name: "ListOfWorkers",
			component: {
				render() {
					return h("h1", "Not yet implemented");
				},
			},
			meta: {
				requiresAuth: true,
				permission: "",
				fail: "/error",
			},
		},
	},
];
