import type { Directive, DirectiveBinding } from "vue";

// Extend HTMLElement to add the custom property
interface IClickOutsideElement extends HTMLElement {
	clickOutsideHandler?: (event: MouseEvent) => void;
}

const clickOutsideDirective: Directive = {
	beforeMount(el: IClickOutsideElement, binding: DirectiveBinding) {
		// Define the handler function
		const handler = (event: MouseEvent) => {
			if (
				!(el === event.target || el.contains(event.target as Node)) &&
				typeof binding.value === "function"
			) {
				binding.value(event);
			}
		};

		// Attach the handler to the element
		el.clickOutsideHandler = handler;
	},
	mounted(el: IClickOutsideElement) {
		// Use a delay to prevent immediate triggering on mount
		setTimeout(() => {
			if (el.clickOutsideHandler) {
				document.addEventListener("click", el.clickOutsideHandler);
			}
		}, 0);
	},
	unmounted(el: IClickOutsideElement) {
		// Clean up the event listener when the element is removed
		if (el.clickOutsideHandler) {
			document.removeEventListener("click", el.clickOutsideHandler);
			delete el.clickOutsideHandler;
		}
	},
};

export default clickOutsideDirective;
