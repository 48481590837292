import api from "@/api";
import dayjs from "dayjs";
import { defineStore } from "pinia";

export const useClientManagementStore = defineStore("clientManagement", () => {
	const clients = ref<any>([]);
	const counties = ref<any>([]);
	const sectors = ref<any>([]);
	const agencies = ref<any>([]);
	const allowances = ref<any>([]);
	const serviceFeeTypes = ref<any>([]);
	const serviceCategoryFeeTypes = ref<any>([]);
	const defaultShiftTimes = ref<any>([]);
	const pagination = ref<any>(null);

	// help function
	function setClientCategoryType(client: any) {
		if (client.locations.length === 0) {
			client.category_type = "";
			return;
		}

		let initialCatId = 0;
		let name = "";
		for (let i = 0; i < client.locations.length; i++) {
			for (let j = 0; j < client.locations[i].categories.length; j++) {
				if (client.locations[i].categories[j].id !== initialCatId) {
					if (initialCatId === 0) {
						initialCatId = client.locations[i].categories[j].id;
						name = client.locations[i].categories[j].name;
					} else {
						client.category_type = "mixed";
						return;
					}
				}
			}
		}
		client.category_type = name;
	}

	// getters

	function getClients() {
		return clients.value;
	}
	function getCounties() {
		return counties.value;
	}
	function getSectors() {
		// const traverseSubs = (sectors: any) => {
		// 	return sectors.reduce((acc: any, curr: any) => {
		// 		if (curr.selectable) {
		// 			// Add obj sector
		// 			acc.push(curr);
		// 		} else if (curr.subsectors) {
		// 			const subSec = traverseSubs(curr.subsectors);

		// 			acc.push(...subSec);
		// 		}
		// 		return acc;
		// 	}, []);
		// };

		// const createSectorParents = (subs: any) => {
		// 	return subs.reduce((acc: any, curr: any) => {
		// 		if (!acc.includes(curr.sectorParent.name)) {
		// 			acc.push(curr.sectorParent.name);
		// 		}
		// 		return acc;
		// 	}, []);
		// };
		function traverseSubs(sectors: any[]): any[] {
			const acc: any[] = [];

			for (const curr of sectors) {
				if (curr.selectable) {
					// Add selectable sector to accumulator
					acc.push(curr);
				} else if (curr.subsectors) {
					// Recursively traverse subsectors and add them to the accumulator
					const subSec = traverseSubs(curr.subsectors);
					acc.push(...subSec);
				}
			}

			return acc;
		}

		function createSectorParents(subs: any[]): any[] {
			const acc: any[] = [];

			for (const curr of subs) {
				if (curr.sectorParent && !acc.includes(curr.sectorParent.name)) {
					// Add unique sector parent names
					acc.push(curr.sectorParent.name);
				}
			}

			return acc;
		}

		const subs = traverseSubs(sectors.value);
		const parentSubs = createSectorParents(subs);

		const getSubsWithParent = (parents: any) => {
			const allSubs = [];
			for (const parentName of parents) {
				const genSubs = [];
				for (const sub of subs) {
					if (sub.sectorParent.name === parentName) {
						genSubs.push(sub);
					}
				}
				const genSubObj = {
					name: parentName,
					subs: genSubs,
				};
				allSubs.push(genSubObj);
			}
			return allSubs;
		};
		return getSubsWithParent(parentSubs);
	}

	// function getFlatSectors() {
	// 	const traverseSubs = (sectors: any) => {
	// 		return sectors.reduce((acc: any, curr: any) => {
	// 			if (curr.selectable) {
	// 				// Add obj sector
	// 				acc.push(curr);
	// 			} else if (curr.subsectors) {
	// 				const subSec = traverseSubs(curr.subsectors);
	// 				// const subWithParent = { ...curr }
	// 				// subWithParent.subsectors = [...subSec]
	// 				acc.push(...subSec);
	// 			}
	// 			return acc;
	// 		}, []);
	// 	};

	// 	return traverseSubs(sectors.value);
	// }
	function getFlatSectors() {
		const traverseSubs = (sectors: any[]): any[] => {
			const acc: any[] = [];

			for (const curr of sectors) {
				if (curr.selectable) {
					// Add selectable sector to accumulator
					acc.push(curr);
				} else if (curr.subsectors) {
					// Traverse subsectors recursively
					const subSec = traverseSubs(curr.subsectors);
					acc.push(...subSec);
				}
			}

			return acc;
		};

		return traverseSubs(sectors.value);
	}

	function getAllSectors() {
		return sectors.value;
	}
	function getAgencies() {
		return agencies.value;
	}
	function getAllowances() {
		return allowances.value || [];
	}
	function resetAllowances() {
		allowances.value = [];
	}
	function getServiceFeeTypes() {
		return serviceFeeTypes.value;
	}
	function getPagination() {
		return pagination.value;
	}
	// getDefaultShiftTimes: (state) => state.defaultShiftTimes,
	// function getServiceCategoryFeeTypes() {
	// 	serviceCategoryFeeTypes.value.forEach((category: any) => {
	// 		category.service_fees.sort((a: any, b: any) => {
	// 			// @ts-ignore
	// 			return new Date(a.valid_from) - new Date(b.valid_from);
	// 		});
	// 	});
	// 	return serviceCategoryFeeTypes.value;
	// }
	function getServiceCategoryFeeTypes() {
		for (const category of serviceCategoryFeeTypes.value) {
			category.service_fees.sort((a: any, b: any) => {
				return (
					new Date(a.valid_from).getTime() - new Date(b.valid_from).getTime()
				);
			});
		}
		return serviceCategoryFeeTypes.value;
	}

	// end getters

	// actons
	async function fetchClients(q: any) {
		try {
			const res = await api.fetchClients(q);
			STORE_CLIENTS(res.data);
			SET_PAGINATION(res.data);
			return res;
		} catch {
			console.log("LCM: Something went terribly wrong.");
		}
	}

	async function fetchClientsOptimized(params: any = {}) {
		try {
			const res = await api.fetchClientsOptimized(params);
			STORE_CLIENTS(res.data);
			SET_PAGINATION(res.data);
		} catch {
			console.log("LCM: Something went terribly wrong.");
		}
	}

	async function fetchClientDetails(q: any) {
		try {
			const response = await api.fetchClientDetails(q);
			// commit("SET_CLIENTS", { add: response.data.data, id: q.id });
			SET_CLIENTS({ add: response.data.data, id: q.id });
			return response;
		} catch {
			console.log("LCM: Something went terribly wrong.");
		}
		return null;
	}

	async function fetchClientInfo(q) {
		try {
			return await api.fetchClientInfo(q);
		} catch (err) {
			console.log("LCM: Something went terribly wrong. 2");
			throw err;
		}
	}

	async function fetchRegions(q?: { includes?: string }) {
		const query = q || { includes: "" };
		try {
			const res = await api.fetchRegions(query);
			counties.value = res.data.data;
		} catch {
			console.log("LCM: Something went wrong while fetching counties.");
		}
	}

	async function fetchSectors(q?: { includes?: string }) {
		const query = q || { includes: "" };
		try {
			// const res = await api.fetchSectors(q); now its global
			const res = await api.fetchSectorsCliManagement(query);
			sectors.value = res.data.data;
		} catch {
			console.log("LCM: Something went wrong while fetching sectors.");
		}
	}

	async function fetchAllowances(q?: { includes?: string }) {
		const query = q || { includes: "" };
		try {
			const res = await api.fetchAllowances(query);
			allowances.value = res.data.data;
		} catch {
			console.log("LCM: Something went wrong while fetching allowances.");
		}
	}

	async function fetchServiceFeeTypes() {
		// fee category types for multiselect
		try {
			const res = await api.fetchServiceFeeTypes();
			serviceFeeTypes.value = res.data.data;
		} catch {}
	}

	async function fetchCategoryFees(params: any) {
		// fee per category for modal
		try {
			const res = await api.fetchCategoryFees(params);
			STORE_CATEGORY_SERVICE_FEE_TYPES(res.data);
			return res;
		} catch {}
	}

	async function fetchAgencies(q?: { includes?: string }) {
		const query = q || { includes: "" };
		try {
			const res = await api.fetchAgencies(query);
			agencies.value = res.data.data;
		} catch {
			console.log("LCM: Something went wrong while fetching allowances.");
		}
	}

	// async function createClient(clientData) {
	// 	return new Promise((resolve, reject) => {
	// 		api
	// 			.createClient(clientData)
	// 			.then((response) => {
	//               commit("ADD_NEW_CLIENT", response.data);
	// 				resolve(response.data);
	// 			})
	// 			.catch((error) => {
	// 				reject(error);
	// 			});
	// 	});
	// }

	async function createClient(clientData: any): Promise<any> {
		try {
			const response = await api.createClient(clientData);
			clients.value.push(response.data.data);
			return response.data; // Resolve with the response data
		} catch (error) {
			throw error; // Reject by throwing the error
		}
	}

	async function updateCostCenterSubcategories({ ccId, ids }) {
		console.log("Updating costcenter", ids);
		try {
			const res = await api.updateCostCenter(
				ccId,
				{
					subcategories: ids,
				},
				",categories.subcategories",
			);
			UPDATE_COSTCENTER_PROPERTY({
				data: { id: ccId, ids, type: "subcategories" },
				patch: res.data,
			});

			return "true";
		} catch (e) {
			console.log("Update costcenter failed for", ccId);
			throw e;
		}
	}

	async function updateCostCenterTemps({ ccId, ids }) {
		try {
			const res = await api.updateCostCenter(ccId, { temps: ids });
			UPDATE_COSTCENTER_PROPERTY({
				data: { id: ccId, ids, type: "temps" },
				patch: res.data,
			});
			return res;
		} catch (e) {
			console.log("Update costcenter failed for", ccId);
			throw e;
		}
	}

	async function updateCostCenterUsers({ ccId, ids }) {
		try {
			const res = await api.updateCostCenter(ccId, { users: ids });
			UPDATE_COSTCENTER_PROPERTY({
				data: { id: ccId, ids, type: "users" },
				patch: res.data,
			});
			return res;
		} catch (e) {
			console.log("ERR:: update Cost Center Users");
			throw e;
		}
	}

	async function updateCostCenter({ id, payload, include = "" }) {
		try {
			const response = await api.updateCostCenter(id, payload, include);
			UPDATE_COSTCENTER_PROPERTY({
				data: { id, type: "name" },
				patch: response.data,
			});
			return response;
		} catch (err) {
			console.log(err.message);
			throw err;
		}
	}

	async function createCostCenterForClient(params: any) {
		console.log(
			"Invoked createCostCenterForClient:",
			params.clientId,
			params.name,
		);

		try {
			const client_id = params.clientId;
			const res = await api.createCostCenter({ client_id, ...params });
			UPDATE_CLIENT_PROPERTY({
				data: { id: client_id, type: "newCostCenter" },
				patch: res.data.data,
			});
			return res;
		} catch (e) {
			console.log("ERR:: create Cost Center For Client", e);
			throw e;
		}
	}

	async function postCloneLocation(params: any) {
		try {
			const res = await api.postCloneLocation(params);
			UPDATE_CLIENT_PROPERTY({
				data: { id: params.clientId, type: "newCostCenter" },
				patch: res.data.data,
			});
		} catch (e) {
			console.log("ERR :: new Cost Center", e);
			throw e;
		}
	}

	async function updateClientAllowance({ clientId, calcengine_id }) {
		try {
			const response = await api.updateClient(clientId, { calcengine_id });
			UPDATE_CLIENT_PROPERTY({
				data: { id: clientId, type: "updateAllowance" },
				patch: response.data.data,
			});
			return response;
		} catch (e) {
			console.log("ERR :: update Allowance", e);
			throw e;
		}
	}

	async function updateClientAutoRepush(payload) {
		try {
			const response = await api.updateClient(payload.clientId, payload.params);
			UPDATE_CLIENT_PROPERTY({
				data: { id: payload.clientId, type: "updateAutoRepush" },
				patch: response.data.data,
			});
			return response;
		} catch (e) {
			console.log(e);
			throw e;
		}
	}

	async function putClientStatus({ clientId, params }) {
		try {
			const response = await api.updateClient(clientId, params);
			UPDATE_CLIENT_PROPERTY({
				data: { id: clientId, type: "updateClientStatus" },
				patch: response.data.data,
			});
			return response;
		} catch (error) {
			throw error.response.data.errors;
		}
	}

	async function putClientData({ clientId, params }) {
		try {
			const response = await api.updateClient(clientId, params);
			UPDATE_CLIENT({ patch: response.data.data });
			return response;
		} catch (error) {
			throw error.response.data.errors;
		}
	}

	async function refreshClientFromExternalInfo(data: any) {
		try {
			console.log(data);
			const res = await api.refreshClientFromExternalInfo(data);

			UPDATE_CLIENT({ patch: res.data.data });
			return res;
		} catch (error) {
			throw error.response.data.errors;
		}
	}

	async function updateCostCenterAllowance({ ccId, type, hasAllowanceParObj }) {
		try {
			const response = await api.updateCostCenter(ccId, hasAllowanceParObj);
			UPDATE_COSTCENTER_PROPERTY({
				data: { id: ccId, type },
				patch: response.data,
			});
			return response;
		} catch (e) {
			throw e;
		}
	}

	async function createDefaultShiftTime({ clientId, payload }) {
		// console.log("Invoked createDefaultShiftTime ACTION ::", clientId, payload);
		try {
			const response = await api.createDefaultShiftTime(clientId, payload);
			UPDATE_CLIENT_PROPERTY({
				data: { id: clientId, type: "defaultShiftTimes" },
				patch: response.data.data,
			});
			return response;
		} catch (e) {
			throw e;
		}
	}

	async function deleteDefaultShiftTime({ clientId, shiftId }) {
		try {
			const response = await api.deleteDefaultShiftTime(shiftId);
			UPDATE_CLIENT_PROPERTY({
				data: {
					id: clientId,
					shiftId: shiftId,
					type: "deleteDefaultShiftTimes",
				},
				patch: {},
			});
			return response;
		} catch (e) {
			console.log(e.response);
			throw e;
		}
		// const response = await api.deleteDefaultShiftTime(shiftId);
		// // TODO: Update location property
		// commit(UPDATE_CLIENT_PROPERTY, {
		// 	data: {
		// 		id: clientId,
		// 		shiftId: shiftId,
		// 		type: "deleteDefaultShiftTimes",
		// 	},
		// });
	}

	// end actions

	//  mutations
	function STORE_CLIENTS(payload: any) {
		clients.value = payload.data;

		// In case a client has no allowance, set allowance to null
		for (let i = 0; i < clients.value.length; i++) {
			const aClient = clients.value[i];

			// Check if the 'allowance' property exists on aClient
			if (!Object.prototype.hasOwnProperty.call(aClient, "allowance")) {
				aClient.allowance = null;
			}
		}
	}

	function SET_PAGINATION({ data, meta }) {
		pagination.value = meta.pagination;
	}
	// function  SET_CLIENTS( payload) {
	//     const clients = clients.value;
	//     clients.forEach((client) => {
	//       if (client.id === payload.id) {
	//         for (const prop in payload.add) {
	//           client[prop] = payload.add[prop];
	//         }
	//       }
	//     });
	//     console.log("STATE ", clients.value);
	//   }
	function SET_CLIENTS(payload: any) {
		// const clients = clients.value;

		for (let i = 0; i < clients.value.length; i++) {
			const client = clients.value[i];

			if (client.id === payload.id) {
				// Iterate over the properties of payload.add and update client properties
				for (const prop in payload.add) {
					if (Object.prototype.hasOwnProperty.call(payload.add, prop)) {
						client[prop] = payload.add[prop];
					}
				}
			}
		}

		console.log("STATE", clients.value);
	}

	// function STORE_CATEGORY_SERVICE_FEE_TYPES(payload: any) {
	// 	payload.data.forEach((category) => {
	// 		category.showMore = false;
	// 		category.service_fees.forEach((type) => {
	// 			type.old_valid_from = type.valid_from;
	// 			type.service_fee_calculation_type.old_id =
	// 				type.service_fee_calculation_type.id;
	// 			type.disbaled = false;
	// 			// Meta is used only for internal UI purposes and shouldn't be sent to the API
	// 			type.meta = {
	// 				dateHelper: {
	// 					time: moment(type.valid_from, "YYYY-MM-DD HH:mm:ss")
	// 						.format("DD/MM/YYYY HH:mm:ss")
	// 						.valueOf(),
	// 				},
	// 			};
	// 		});
	// 	});
	// 	serviceCategoryFeeTypes.value = payload.data;
	// }

	function STORE_CATEGORY_SERVICE_FEE_TYPES(payload: any) {
		const serviceCategoryFeeTypes = payload.data;

		for (const category of serviceCategoryFeeTypes) {
			category.showMore = false;

			for (let j = 0; j < category.service_fees.length; j++) {
				const type = category.service_fees[j];

				type.old_valid_from = type.valid_from;
				type.service_fee_calculation_type.old_id =
					type.service_fee_calculation_type.id;
				type.disabled = false;

				// Using Day.js for date formatting
				type.meta = {
					dateHelper: {
						time: dayjs(type.valid_from).format("DD/MM/YYYY HH:mm:ss"),
					},
				};
			}
		}

		serviceCategoryFeeTypes.value = payload.data;
	}

	function UPDATE_COSTCENTER_PROPERTY({ data, patch }) {
		// console.log("Patch :: ", patch, clients.value);
		let location: any = {};
		const categories = patch.data.categories;
		let clientChanged = null;
		// Find the location in all clients
		for (const client of clients.value) {
			if (!client.locations) continue;
			for (const tempCC of client.locations) {
				if (tempCC.id === data.id) {
					location = tempCC;
					clientChanged = client;
					break;
				}
			}
		}

		switch (data.type) {
			case "subcategories": {
				location["categories"] = categories;
				setClientCategoryType(clientChanged);

				break;
			}
			case "temps": {
				location["temps"] = patch.data.temps;

				break;
			}
			case "users": {
				location["users"] = patch.data.users;

				break;
			}
			case "hasLocationAllowance": {
				location["hasLocationAllowance"] = patch.data.hasLocationAllowance;

				break;
			}
			case "hasSecureAllowance": {
				location["hasSecureAllowance"] = patch.data.hasSecureAllowance;

				break;
			}
			case "hasCommunityAllowance": {
				location["hasCommunityAllowance"] = patch.data.hasCommunityAllowance;

				break;
			}
			default: {
				console.log("Patching other value:", data.type, patch);
				location.name = patch.data.name;
				location.archived = patch.data.archived;
				if (patch.data?.location_external_id)
					location.location_external_id = patch.data.location_external_id;
			}
		}
	}
	function UPDATE_CLIENT_PROPERTY({ data, patch }) {
		const client = clients.value.find((client) => client.id === data.id);
		console.log("CLIENT ::", client);
		if (!client) {
			return;
		}

		switch (data.type) {
			case "newCostCenter": {
				client.locations.push(patch);

				break;
			}
			case "updateAllowance": {
				client["allowance"] = patch.allowance;

				break;
			}
			case "updateAutoRepush": {
				client.autorepushactive = patch.autorepushactive;
				client.autorepushminutes = patch.autorepushminutes;

				break;
			}
			case "defaultShiftTimes": {
				if (client["defaultShiftTimes"] === undefined) {
					client["defaultShiftTimes"] = [];
				}
				client["defaultShiftTimes"].push(patch);

				break;
			}
			case "deleteDefaultShiftTimes": {
				// const ind = _.findIndex(client["defaultShiftTimes"], {
				// 	id: data.shiftId,
				// });
				const ind = client["defaultShiftTimes"].findIndex(
					(shift: any) => shift.id === data.shiftId,
				);

				client["defaultShiftTimes"].splice(ind, 1);

				break;
			}
			case "updateClientStatus": {
				client.active = patch.active;
				// client.status = patch.status

				break;
			}
			// No default
		}
	}

	function UPDATE_CLIENT({ patch }) {
		const fClientIndex = clients.value.findIndex(
			(client) => client.id === patch.id,
		);
		if (fClientIndex !== -1) {
			const mergedData = { ...clients.value[fClientIndex], ...patch };
			clients.value.splice(fClientIndex, 1, mergedData);
		}
	}

	function resetClientsList() {
		clients.value = [];
	}

	// end mutations

	// start columnNamesLocl
	const columnNamesLocl = ref<any>({
		default: {
			usr_eircode: "Eircode",
		},
		api: [],
	});
	async function getColumnNamesLocl(data: any = {}) {
		const res = await api.getColumnNamesLocl(data);
		// commit("SET_COLUMN_NAME_DATA", res.data.data);
		// SET_COLUMN_NAME_DATA(state, data) {
		// 	state.columnNamesLocl.api = data;
		//   },
		columnNamesLocl.value.api = res.data.data;
		return res;
	}
	function getColumnNameLocl(key: any) {
		if (key) {
			if (
				Array.isArray(columnNamesLocl.value.api) &&
				columnNamesLocl.value.api.length
			) {
				const foundObj = columnNamesLocl.value.api.find(
					(cnl) => cnl.key === key,
				);
				if (foundObj) {
					return foundObj.value;
				}
			}
			return columnNamesLocl.value.default[key];
		}
		console.error("Invalid key", key);

		return null;
	}
	// end columnNamesLocl

	return {
		clients,
		counties,
		sectors,
		agencies,
		allowances,
		serviceFeeTypes,
		serviceCategoryFeeTypes,
		defaultShiftTimes,
		pagination,
		columnNamesLocl,
		// geters
		getClients,
		getCounties,
		getSectors,
		getFlatSectors,
		getAllSectors,
		getAgencies,
		getAllowances,
		resetAllowances,
		getServiceFeeTypes,
		getPagination,
		getServiceCategoryFeeTypes,
		getColumnNameLocl, // get
		// action
		getColumnNamesLocl, // fetch
		fetchClients,
		fetchClientsOptimized,
		fetchClientDetails,
		fetchClientInfo,
		fetchRegions,
		fetchSectors,
		fetchAllowances,
		fetchServiceFeeTypes,
		fetchCategoryFees,
		fetchAgencies,
		createClient,
		updateCostCenterSubcategories,
		updateCostCenterTemps,
		updateCostCenterUsers,
		updateCostCenter,
		createCostCenterForClient,
		postCloneLocation,
		updateClientAllowance,
		updateClientAutoRepush,
		putClientStatus,
		putClientData,
		resetClientsList,
		refreshClientFromExternalInfo,
		updateCostCenterAllowance,
		createDefaultShiftTime,
		deleteDefaultShiftTime,
	};
});
