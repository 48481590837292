<script setup lang="ts">
const emit = defineEmits(["close-modal", "confirm"]);
const props = withDefaults(
	defineProps<{
		htmlString: string;
		confirmBtnTxt?: string;
		confirmBtnClass?: string;
		warning?: boolean;
	}>(),
	{
		confirmBtnTxt: "Okay",
		confirmBtnClass: "",
		warning: false,
	},
);

function onClickConfirm() {
	emit("close-modal");
}
</script>

<template>
	<div
		class="confirm-modal"
		:class="{ warning: warning }"
	>
		<div class="content">
			<div>
				<div v-html="props.htmlString"></div>
			</div>
		</div>
		<div class="wrapp-buttons">
			<button
				:class="confirmBtnClass"
				@click="onClickConfirm()"
			>
				{{ confirmBtnTxt }}
			</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 10px 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 800px;
	max-height: 700px;
	min-height: 250px;
	color: $text-color;

	.content {
		margin-top: 10px;
		padding: 10px;
		width: 700px;
		max-height: 620px;
		min-height: 250px;
		overflow: auto;
		border-radius: 22px;
		border: 1px solid $border-color1;
		.warningTxt {
			font-size: 2em;
		}
		.message {
			text-align: center;
			font-size: 1rem;
			transition: 2s;
		}
		.isError {
			color: $error-color;
		}
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
}
.confirm-modal.warning {
	max-width: 400px;
}
</style>
