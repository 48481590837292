<script setup lang="ts">
// import moment from "moment";
// import calendarOptions from "../../common/calendarOptions.js";

// import { Evt } from "../../../lib/helpers/Evt.js";
import { useSessionStore } from "@/store/session";
import { useClientManagementStore } from "@/store/locationClientManagement";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useToastStore } from "@/store/toast";

type TShiftTimes = {
	id: number;
	name: string;
	startTime: { hours: string; minutes: string };
	endTime: { hours: string; minutes: string };
};
// Props
const props = defineProps<{
	clientId: number;
	defaultShifts?: {
		id: number;
		name: string;
		startTime: string;
		endTime: string;
	}[];
}>();

// Emit events
const emit = defineEmits(["close-modal"]);

// Vuex store actions
// const { createDefaultShiftTime, deleteDefaultShiftTime } = store.dispatch;
const clientStore = useClientManagementStore();
const createDefaultShiftTime = clientStore.createDefaultShiftTime;
const deleteDefaultShiftTime = clientStore.deleteDefaultShiftTime;
const toast = useToastStore();

const sessionStore = useSessionStore();
const gPermissions = sessionStore.gPermissions;

// Data
const state = reactive({
	shiftName: "",
	startTime: "",
	endTime: "",
	currtime: new Date(),
	add_new: false,
	dateTimeOption: {
		format: "HH:mm",
	},
	shiftTimes: [] as TShiftTimes[],
	shiftTimesToDeleteIds: [] as number[],
	clientId: props.clientId,
});

onMounted(() => {
	// state.dateTimeOption.format = "HH:mm";

	// state.shiftTimes = props.defaultShifts
	// 	? JSON.parse(JSON.stringify(props.defaultShifts))
	// 	: [];

	if (props.defaultShifts.length > 0) {
		for (let i = 0; i < props.defaultShifts.length; i++) {
			const shift: TShiftTimes = {
				id: 0,
				name: "",
				startTime: { hours: "", minutes: "" },
				endTime: { hours: "", minutes: "" },
			};
			for (const key in props.defaultShifts[i]) {
				// Modify values as needed
				if (key === "startTime" || key === "endTime") {
					// Example of trimming the time string
					const arrTime = props.defaultShifts[i][key].split(":");
					// console.log(arrTime);
					if (arrTime.length > 0) {
						shift[key].hours =
							arrTime[0].length === 1 ? "0" + arrTime[0] : arrTime[0]; // props.defaultShifts[i][key].substring(0, 2);
						shift[key].minutes =
							arrTime[1].length === 1 ? "0" + arrTime[1] : arrTime[1]; // props.defaultShifts[i][key].substring(3, 5);
					}
				} else {
					shift[key] = props.defaultShifts[i][key];
				}
			}

			// const shift: any = props.defaultShifts[i];

			// console.log(shift, "-----");
			// shift.startTime.hours = shift.startTime.substring(0, 2);
			// shift.startTime.minutes = shift.startTime.substring(0, 2);
			// // shift.endTime = shift.endTime.substring(0, 5);

			state.shiftTimes.push(shift);
		}
	}

	if (state.shiftTimes.length === 0) {
		addDefaultShiftRow();
	}
});

// Methods
async function deleteDefaultShiftCall(shiftId: number) {
	console.log("Delete invoked for ::", shiftId);

	try {
		await deleteDefaultShiftTime({
			clientId: state.clientId,
			shiftId,
		});
	} catch (error) {
		toast.openToastError(error.response?.data?.message ?? "");
		// toast
	}
}

async function createNewDefaultShift(shift: {
	name: string;
	startTime: { hours: string; minutes: string };
	endTime: { hours: string; minutes: string };
}) {
	if (!shift.startTime || !shift.endTime) {
		return;
	}
	console.log("Creating new shift.", shift.startTime);
	// get num
	const startH = shift.startTime.hours.toString();
	const startM = shift.startTime.minutes.toString();

	const endH = shift.endTime.hours.toString();
	const endM = shift.endTime.minutes.toString();

	const payload = {
		name: shift.name,
		startTime: `${startH.length <= 1 ? "0" + startH : startH}:${startM.length <= 1 ? "0" + startM : startM}:00`,
		endTime: `${endH.length <= 1 ? "0" + endH : endH}:${endM.length <= 1 ? "0" + endM : endM}:00`,
	};

	try {
		await createDefaultShiftTime({
			clientId: state.clientId,
			payload,
		});
	} catch (error) {
		toast.openToastError(error.response?.data?.message ?? "");
	}
}

function addDefaultShiftRow() {
	console.log("Default shift add invoked");
	state.shiftTimes.push({
		id: -1,
		name: "",
		startTime: null,
		endTime: null,
	});
}

function deleteRow(index: number) {
	const aShift = state.shiftTimes[index];
	if (aShift.id !== -1) {
		state.shiftTimesToDeleteIds.push(aShift.id);
	}
	state.shiftTimes.splice(index, 1);
}

// function resetFields() {
// 	state.shiftName = "";
// 	state.startTime = "";
// 	state.endTime = "";
// }

function callModalClose() {
	emit("close-modal");
	// Evt.fire("disposeModal");
}

function defaultShiftsSubmit() {
	// console.log("Save fired.");
	for (const aShift of state.shiftTimes) {
		if (aShift.name.trim().length > 0 && aShift.id === -1) {
			// console.log("Saving new", aShift);
			createNewDefaultShift(aShift);
		}
		if (aShift.name.trim().length > 0 && aShift.id !== -1) {
			// console.log("Editing... NO API YET.", aShift);
		}
	}

	for (const shiftId of state.shiftTimesToDeleteIds) {
		console.log("Deleting", shiftId);
		deleteDefaultShiftCall(shiftId);
	}

	callModalClose();
}
</script>

<template>
	<div
		class="details default-shift-modal"
		style="margin-top: 20px"
		@click.stop=""
	>
		<template
			v-for="(shift, index) in state.shiftTimes"
			:key="index"
		>
			<div class="control is-grouped">
				<div class="field shift-name">
					<input
						v-model="shift.name"
						class="input"
						type="text"
						placeholder="Please enter Shift name here"
						:disabled="shift.id > 0"
					/>
				</div>
				<div class="field">
					<label
						class="label"
						for="start_time"
					>
						START TIME
					</label>

					<modern-date-picker
						v-model="shift.startTime"
						class="time-input"
						:format="state.dateTimeOption.format"
						outputFormatString="HH:mm"
						time-picker
						:enable-time-picker="true"
						placeholder="When?"
						:disabled="shift.id > 0"
						:text-input="false"
						:clearable="false"
						hide-input-icon
					/>

					<vue-date-picker
						v-if="false"
						v-model="shift.startTime"
						:format="state.dateTimeOption.format"
						time-picker
						:enable-time-picker="true"
						class="time-input"
						:disabled="shift.id > 0"
						placeholder="When?"
					>
						<template #input-icon></template>
					</vue-date-picker>

					<!-- {{ shift.startTime }} -->
				</div>
				<div class="field">
					<label
						class="label"
						for="end_time"
					>
						END TIME
					</label>
					<modern-date-picker
						v-model="shift.endTime"
						class="time-input"
						:format="state.dateTimeOption.format"
						outputFormatString="HH:mm"
						time-picker
						:enable-time-picker="true"
						placeholder="When?"
						:disabled="shift.id > 0"
						:text-input="false"
						:clearable="false"
						hide-input-icon
					/>
					<vue-date-picker
						v-if="false"
						v-model="shift.endTime"
						:format="state.dateTimeOption.format"
						time-picker
						:enable-time-picker="true"
						class="time-input"
						:disabled="shift.id > 0"
						placeholder="When?"
					>
						<template #input-icon></template>
					</vue-date-picker>
					<!-- <date-picker
						:date="shift.endTime"
						:option="state.dateTimeOption"
						mode="hour"
					></date-picker> -->
				</div>
				<div class="field">
					<label class="label">&nbsp;</label>
					<span
						v-if="gPermissions.canDeleteDefaultShiftTimes"
						class="cancel"
						@click.stop="deleteRow(index)"
					>
						<img src="../../../../assets/image/icons/delete-larger.svg" />
					</span>
				</div>
			</div>
		</template>
		<span>
			<button
				v-if="gPermissions.canCreateDefaultShiftTimes"
				class="button is-generic-app-light-blue"
				:disabled="state.add_new"
				@click="addDefaultShiftRow"
			>
				<img src="../../../../assets/image/icons/Plus.svg" />
				<span>Add New</span>
			</button>
		</span>
		<div class="buttons-centered">
			<button
				class="button is-generic-app-blue is-caps-lock"
				type="submit"
				@click.prevent="defaultShiftsSubmit"
			>
				SAVE
			</button>
			<button
				class="button is-outlined is-caps-lock"
				@click.prevent="callModalClose"
			>
				CANCEL
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.details {
	// width: 100%;
	box-sizing: border-box;
	padding: 0 20px 20px;
	width: 564px;
}
.control {
	&.is-grouped {
		display: flex;
		justify-content: flex-start;
		margin-bottom: 15px;

		& > .control {
			flex-basis: 0;
			flex-shrink: 0;

			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: 0.75rem;
			}

			&.is-expanded {
				flex-grow: 1;
				flex-shrink: 1;
			}
		}

		&.is-grouped-centered {
			justify-content: center;
		}

		&.is-grouped-right {
			justify-content: flex-end;
		}
	}
}
.cancel {
	border-radius: 20px;
	width: 24px;
	height: 24px;
	font-size: 9px;
	line-height: 24px;
	// margin-top: 14px;
	margin-top: 6px;
	color: $background-basic; // white
	display: block;
	text-align: center;

	&:hover {
		cursor: pointer;
	}
}

.confirm {
	border-radius: 20px;
	background-color: $green;
	width: 24px;
	height: 24px;
	font-size: 9px;
	line-height: 24px;
	margin-top: 12px;
	color: $background-basic; // white
	display: block;
	text-align: center;

	&:hover {
		cursor: pointer;
	}
}

.field {
	margin-right: 5px;

	.input {
		height: 36px;
	}

	&.shift-name {
		flex-grow: 2;
		// padding-bottom: 4px;
		margin-right: auto;
		display: flex;
		align-items: flex-end;
	}
}
</style>
<style lang="scss">
.default-shift-modal {
	.datepickbox {
		.cov-datepicker {
			width: 90px;
			height: 36px;
		}
	}
}

.dp__main.time-input {
	width: 90px;
	.dp__time_col {
		padding: 0;
	}
	.dp--time-overlay-btn {
		line-height: normal;
		font-size: 1em;
		font-weight: 400;
	}
	.dp__btn,
	.dp--time-overlay-btn {
		// min-height: 20px;
		// min-width: unset; // Brakes teleport position
		padding: unset;
		background-color: unset !important;
		box-shadow: unset;
		color: unset !important;
	}

	.dp__action_button {
		&.dp__action_cancel,
		&.dp__action_select {
			min-height: 20px;
			min-width: unset;

			&.dp__action_cancel {
				background-color: unset;
			}
		}
	}

	.dp__input {
		padding: unset;
		// padding-left: var(--dp-input-icon-padding);
		text-indent: 0;
		font-size: unset !important;
		color: $text-color;
	}
	.dp__input_icon {
		inset-inline-start: auto;
		padding: 0 0 0 12px;
	}
}
</style>
