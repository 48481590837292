<script setup lang="ts">
// import { ref, computed, watch, onMounted, defineProps } from "vue";
// import { useStore } from "vuex";
// import MultiSelect from "vue-multiselect";
// import Treeselect from "@riophae/vue-treeselect";
// import PasswordPolicy from "@/components/forms/PasswordPolicyValidation.vue";
import { Errors } from "@/assets/js/errors";
import { parseErrors } from "@/assets/js/parseErrors";

// import { parseErrors } from "../lib/helpers/function";
// import { isArray } from "underscore";
import { useRoute } from "vue-router";
import { useUsersStore } from "@/store/users";
import { useDefaultStore } from "@/store/index.js";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import api from "@/api";

import TreeSelect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";

// Props (if any)
// defineProps({});

// if component is called as a modal
const emit = defineEmits(["close-modal", "confirm"]);
const toastStore = useToastStore();
// Store
// const store = useStore();
const userStore = useUsersStore();
const sessionStore = useSessionStore();
const defaultStore = useDefaultStore();
const can = sessionStore.can;

const route = useRoute();
// State
const passwordConformationMessage2 = `The "SAVE CHANGES" button will become available once the password criteria is met and the identical password is entered in both fields.`;
const passwordConformationMessage = `The "CREATE USER" button will become available once the password criteria is met and the identical password is entered in both fields.`;

const isShowMessagePasswordConfirmation = ref(false);
const confirmPasswordDisable = ref(false);
// const selectedClient = ref(null);
// const selectedUserclass = ref([]);
// const selectedLocations = ref([]);
const isLoading = ref(false);
const isLoadingManageUser = ref(false);

const allJobCategories = ref(null);
// const allLocations = ref([]);
// const locationsTreeselect = ref(null);

const user = ref<Record<string, any>>({
	name: "",
	email: "",
	password: "",
	confirmpass: "",
	userclass: {},
	jobs: [],
	locations: [],
	signoff: false,
	pin: "",
	pinArr: [],
	timesheet: false,
	canViewUnbilledShiftsReport: false,
	canViewClientAssignedReport: false,
	canViewClientActivityReport: false,
	canViewClientUnassignedActivityReport: false,
	canViewUnprocessedShiftsReport: false,
	canViewAnnualLeaveBalancesReport: false,
	canUseShiftPriceOverwrite: false,
	password_is_valid: false,
});
const locationsTreeselect = ref<any[]>([]);
const allLocations = ref<any[]>([]);
const touchedKeys = ref(new Set());
const errors = ref(new Errors());
// const editUserID = ref(null);
const isEditUser = ref(false);

const reportToggles = ref([
	{
		label: "Processed shifts report",
		key: "canViewUnbilledShiftsReport",
		system_permissions: [],
		permissions: [],
	},
	{
		label: "Client assigned report",
		key: "canViewClientAssignedReport",
		system_permissions: [],
		permissions: [],
	},
	{
		label: "Client activity report",
		key: "canViewClientActivityReport",
		system_permissions: [],
		permissions: [],
	},
	{
		label: "Client unassigned activity report",
		key: "canViewClientUnassignedActivityReport",
		system_permissions: [],
		permissions: [],
	},
	{
		label: "Holiday balances report",
		key: "canViewAnnualLeaveBalancesReport",
		system_permissions: [],
		permissions: [],
	},
	{
		label: "Unprocessed shifts report",
		key: "canViewUnprocessedShiftsReport",
		system_permissions: [],
		permissions: [],
		displayIfAdded: true,
	},
	{
		label: "Can use shift price overwrite",
		key: "canUseShiftPriceOverwrite",
		system_permissions: ["can_use_nexus_middleware"], //["canUseNexusMiddleware"],
		permissions: ["edit-can-use-shift-price-overwrite"],
	},
]);

// Computed
// const users = computed(() => store.getters.getUsers);
// const categories = computed(() => store.getters.getAllCategories);
// const roles = computed(() => store.getters.getMinRoles);
// const clients = computed(() => store.getters.getAllClients);

const categories = computed(() => defaultStore.allCategories);
const clients = computed(() => defaultStore.getAllClients());
const roles = computed(() => defaultStore.minRoles); // getMinRoles
const initialStatus = computed(() => defaultStore.initialStatus);

const disableConfirm = computed(() => {
	return user.value.password
		? !(
				user.value.password_is_valid &&
				user.value.confirmpass === user.value.password
			)
		: false;
	// if (!user.value.password) {
	// 	// isShowMessagePasswordConfirmation.value = false;
	// 	return false;
	// } else {
	// 	// isShowMessagePasswordConfirmation.value = true;
	// 	return !(
	// 		user.value.password_is_valid &&
	// 		user.value.confirmpass === user.value.password
	// 	);
	// }
});

const sectors = computed(() => [
	{
		name: "",
		id: 1,
		clients: clients.value,
	},
]);

const pinCode = computed(() => user.value.pinArr.join(""));

const reportTogglesFilter = computed(() => {
	// const thisComponent = getCurrentInstance();
	return reportToggles.value.filter((rt) => {
		let hasPermissions = true;

		if (
			rt.system_permissions &&
			Array.isArray(rt.system_permissions) &&
			rt.system_permissions.length > 0
		) {
			for (const element of rt.system_permissions) {
				if (!initialStatus.value[element]) hasPermissions = false;
			}
		}

		if (
			rt.permissions &&
			Array.isArray(rt.permissions) &&
			rt.permissions.length > 0
		) {
			for (const element of rt.permissions) {
				if (!can(element)) hasPermissions = false;
			}
		}

		if (!hasPermissions) return false;
		if (rt.displayIfAdded) {
			return Object.prototype.hasOwnProperty.call(user.value, rt.key);
		} else if (rt.displayIfAdded === false) {
			return false;
		}
		return true;
	});
});

// Watchers
// watch(
// 	() => editUserID.value,
// 	(id) => {
// 		if (id) {
// 			// store.dispatch("getUsers", { id }).then((res) => {
// 			// 	if (res.data) {
// 			// 		const data = res.data.data;
// 			// 		user.value.name = data.name;
// 			// 		user.value.email = data.email;
// 			// 		user.value.canViewUnbilledShiftsReport =
// 			// 			data.canViewUnbilledShiftsReport;
// 			// 		user.value.canViewClientAssignedReport =
// 			// 			data.canViewClientAssignedReport;
// 			// 		user.value.canViewClientActivityReport =
// 			// 			data.canViewClientActivityReport;
// 			// 		user.value.canViewClientUnassignedActivityReport =
// 			// 			data.canViewClientUnassignedActivityReport;
// 			// 		user.value.canViewAnnualLeaveBalancesReport =
// 			// 			data.canViewAnnualLeaveBalancesReport;
// 			// 		user.value.canViewUnprocessedShiftsReport =
// 			// 			data.canViewUnprocessedShiftsReport;
// 			// 		user.value.canUseShiftPriceOverwrite =
// 			// 			data.canUseShiftPriceOverwrite || false;
// 			// 		user.value.pinArr = data.pin ? data.pin.split("") : [];
// 			// 	} else {
// 			// 		console.error("Invalid response");
// 			// 	}
// 			// });
// 			getUserData(id);
// 		}
// 	},
// );
watch(
	() => route,
	(val) => {
		if (val.params.tempId) {
			isEditUser.value = true;
			getUserData(val.params.userId);
		}
	},
	{ deep: true },
);

// Lifecycle
onMounted(async () => {
	if (route.params.userId) {
		isEditUser.value = true;
		getUserData(route.params.userId);
	}

	// await defaultStore.fetchAllCategoriesList();

	if (!isEditUser.value) {
		await defaultStore.fetchAllCategoriesList();
		// store.dispatch("fetchAllCategoriesList").then(() => {
		allJobCategories.value = packForTreeselect(
			categories.value,
			"name",
			"subcategories",
		);
		// });

		await defaultStore.fetchClientsList({
			includes: "locations",
			per_page: 999,
		});

		// 	store
		// 		.dispatch("fetchClientsList", { includes: "locations", per_page: 999 })
		// 		.then(() => {
		allLocations.value = getLocationsFromSectors(sectors.value);
		locationsTreeselect.value = packForTreeselect(
			allLocations.value,
			"category",
			"locations",
		);
		isLoading.value = false;
		// 		})
		// 		.catch((err) => {
		// 			console.log("[error]", err.message);
		// 			isLoading.value = true;
		// 		});
	}
});

// Methods
async function getUserData(id: any) {
	try {
		const res = await userStore.getUsers({ id });
		if (res.data) {
			const data = res.data.data;
			user.value.name = data.name;
			user.value.email = data.email;
			user.value.canViewUnbilledShiftsReport = data.canViewUnbilledShiftsReport;
			user.value.canViewClientAssignedReport = data.canViewClientAssignedReport;
			user.value.canViewClientActivityReport = data.canViewClientActivityReport;
			user.value.canViewClientUnassignedActivityReport =
				data.canViewClientUnassignedActivityReport;
			user.value.canViewAnnualLeaveBalancesReport =
				data.canViewAnnualLeaveBalancesReport;
			user.value.canViewUnprocessedShiftsReport =
				data.canViewUnprocessedShiftsReport;
			user.value.canUseShiftPriceOverwrite =
				data.canUseShiftPriceOverwrite || false;
			user.value.pinArr = data.pin ? data.pin.split("") : [];
		}
	} catch (e) {
		console.log(e);
	}
}

function handlePasswordIsValid(data: { isPasswordValid: boolean }) {
	user.value.password_is_valid = data.isPasswordValid;
}

function packForTreeselect(
	rootParams: any[],
	parentName: string,
	childrenKey: string,
) {
	const treeselectData: any[] = [];
	for (const param of rootParams) {
		const id = param.id;
		const label = param[parentName];
		const children: any[] = [];
		const parent = { id, label, children };

		for (const rootChild of param[childrenKey]) {
			const childId = `${parent.id}$#${parent.label}$#${rootChild.id}`;
			const childLabel = rootChild.name;
			children.push({ id: childId, label: childLabel });
		}

		treeselectData.push(parent);
	}
	return treeselectData;
}

// function getLocationsFromSectors(sectors: any[]) {
// 	const usedClients: any[] = [];
// 	const locations: any[] = [];

// 	sectors.forEach((sector) => {
// 		sector.clients.forEach((client: any) => {
// 			if (!usedClients.includes(client.id)) {
// 				const groupingObj = {
// 					id: client.id,
// 					category: client.name,
// 					locations: client.locations,
// 				};
// 				locations.push(groupingObj);
// 				usedClients.push(client.id);
// 			}
// 		});
// 	});
// 	return locations;
// }

function getLocationsFromSectors(sectors: any[]) {
	const usedClients: any[] = [];
	const locations: any[] = [];

	for (const sector of sectors) {
		for (let j = 0; j < sector.clients.length; j++) {
			const client: any = sector.clients[j];

			if (!usedClients.includes(client.id)) {
				const groupingObj = {
					id: client.id,
					category: client.name,
					locations: client.locations,
				};
				locations.push(groupingObj);
				usedClients.push(client.id);
			}
		}
	}
	return locations;
}

const loadOptions = (obj: any) => {
	console.log("Loading dropdown", obj);
};

// const generateCategories = (callback: (err: null, options: any[]) => void) => {
// 	const rootOptions = store.state.allJobCategories;
// 	callback(null, rootOptions);
// };

// const generateLocations = (callback: (err: null, options: any[]) => void) => {
// 	const rootOptions = store.state.locationsTreeselect;
// 	callback(null, rootOptions);
// };

const toggleReport = (reportToggle: { key: string }) => {
	inputFieldChanged(reportToggle.key);
};

const manageUserFunc = () => {
	if (isEditUser.value) {
		editUserApi();
	} else {
		createNewUser();
	}
};

const editUserApi = async () => {
	isLoadingManageUser.value = true;
	if (user.value.password !== user.value.confirmpass) {
		isLoadingManageUser.value = false;
		errors.value.record({
			confirmpassword: ["The password and confirmed password do not match."],
		});

		return;
	}

	const preparedData: any = {
		id: route.params.userId,
		data: {},
	};

	// for (const key of touchedKeys.value) {
	// const map: { [key: string]: string } = {
	// 	confirmpass: "password_confirmation",
	// };
	// console.log(map, "map---- **** ");
	// let keyStr = key;
	// // @ts-ignore
	// if (map[key]) keyStr = map[key];
	// if (key === "pinArr") {
	// 	preparedData.data.pin = pinCode.value;
	// } else {
	// 	// @ts-ignore
	// 	preparedData.data[keyStr] = user.value; // [key as keyof typeof user];
	// }

	// }

	for (const key of touchedKeys.value) {
		const map = {
			confirmpass: "password_confirmation",
		};
		let keyStr = key;
		// @ts-ignore
		if (map[key]) {
			// @ts-ignore
			keyStr = map[key];
		}
		if (key === "pinArr") {
			preparedData.data.pin = pinCode.value;
		} else {
			// @ts-ignore
			preparedData.data[keyStr] = user.value[key];
		}
	}

	console.log(preparedData.data, "prepare data");
	if (!Object.keys(preparedData.data).length) {
		isLoadingManageUser.value = false;
		return;
	}

	try {
		// await store.dispatch("patchUsers", preparedData);
		await userStore.patchUsers(preparedData);
		touchedKeys.value.clear();
		toastStore.openToastSuccess("User edited successfully!");
		// emit("cancel-modal");
		// isLoadingManageUser.value = false;
	} catch (err) {
		console.warn(err);
		// isLoadingManageUser.value = false;
		handleAllErrorsToast(err);
	} finally {
		isLoadingManageUser.value = false;
	}
};

const createNewUser = async () => {
	if (user.value.password !== user.value.confirmpass) {
		errors.value.record({
			confirmpassword: ["The password and confirmed password do not match."],
		});

		return;
	}
	isLoadingManageUser.value = true;
	const preparedData = {
		data: {
			role_id: user.value.userclass.id,
			name: user.value.name,
			email: user.value.email,
			password: user.value.password,
			password_confirmation: user.value.confirmpass,
			subcategories: packFromTreeselect(
				user.value.jobs,
				categories.value,
				"subcategories",
			),
			locations: packFromTreeselect(
				user.value.locations,
				allLocations.value,
				"locations",
			),
			pin: pinCode.value,
			canViewUnbilledShiftsReport: user.value.canViewUnbilledShiftsReport,
			canViewClientAssignedReport: user.value.canViewClientAssignedReport,
			canViewClientActivityReport: user.value.canViewClientActivityReport,
			canViewClientUnassignedActivityReport:
				user.value.canViewClientUnassignedActivityReport,
			canViewAnnualLeaveBalancesReport:
				user.value.canViewAnnualLeaveBalancesReport,
			canViewUnprocessedShiftsReport: user.value.canViewUnprocessedShiftsReport,
			canUseShiftPriceOverwrite: user.value.canUseShiftPriceOverwrite,
		},
		query: "roles,locationCount,subcategoryCount",
	};
	// this approach doesn't work because
	//the error is not forwarded as it should be,
	// it just says [object Object] and I can't do anything with it... because when I try to get to messages or anything, it returns an error.
	// try {
	// 	// await store.dispatch("createUser", preparedData);
	// 	await userStore.createUser(preparedData);
	// 	resetFields();
	// 	emit("close-modal");
	// 	emit("confirm");
	// 	isLoadingManageUser.value = false;
	// } catch (errors) {
	// 	isLoadingManageUser.value = false;
	// 	console.dir(errors.message);
	// 	console.dir(errors, { depth: 5 });
	// 	handleAllErrorsToast(errors);
	// }

	try {
		await api.createUser(preparedData.data);
		await userStore.fetchUsers({ include: preparedData.query });
		resetFields();
		toastStore.openToastSuccess("User created successfully!");
		emit("close-modal");
		emit("confirm");
		// isLoadingManageUser.value = false;
	} catch (errors) {
		isLoadingManageUser.value = false;

		handleAllErrorsToast(errors.response.data);
	}
};

const handleAllErrorsToast = (e: any) => {
	console.log(e);
	if (e.errors) {
		const errs = parseErrors(e.errors);
		if (errs) toastStore.openToastError(errs);
		errors.value.record(e.errors);
	} else if (e.message) {
		toastStore.openToastError(e.message);
	} else {
		toastStore.openToastError("Unexpected error.");
	}
};

const resetFields = () => {
	user.value.name = "";
	user.value.email = "";
	user.value.password = "";
	user.value.confirmpass = "";
	user.value.userclass = {};
	user.value.jobs = [];
	user.value.locations = [];
	user.value.signoff = false;
	user.value.pin = "";
	user.value.pinArr = [];
	user.value.timesheet = false;
};

// const packFromTreeselect = (
// 	selectedCategory: any[],
// 	rootParams: any[],
// 	childrenKey: string,
// ) => {
// 	const selectedChildrenIds: any[] = [];
// 	const categoryIdMap: { [key: string]: any } = {};
// 	rootParams.forEach((category) => {
// 		categoryIdMap[category.id] = category;
// 	});
// 	selectedCategory.forEach((category) => {
// 		const isChild = (category + "").indexOf("$#") > 0;
// 		if (isChild) {
// 			selectedChildrenIds.push({ id: parseInt(category.split("$#")[2]) });
// 		} else {
// 			categoryIdMap[category][childrenKey].forEach((subcategory: any) => {
// 				selectedChildrenIds.push({ id: subcategory.id });
// 			});
// 		}
// 	});
// 	return selectedChildrenIds;
// };

// const packFromTreeselect = (
// 	selectedCategory: any[],
// 	rootParams: any[],
// 	childrenKey: string,
// ) => {
// 	const selectedChildrenIds: any[] = [];
// 	const categoryIdMap: { [key: string]: any } = {};

// 	// Build the category ID map
// 	for (let i = 0; i < rootParams.length; i++) {
// 		const category = rootParams[i];
// 		categoryIdMap[category.id] = category;
// 	}

// 	// Process selected categories
// 	for (let j = 0; j < selectedCategory.length; j++) {
// 		const category = selectedCategory[j];
// 		const isChild = (category + "").indexOf("$#") > 0;

// 		if (isChild) {
// 			selectedChildrenIds.push({ id: parseInt(category.split("$#")[2]) });
// 		} else {
// 			const subcategories = categoryIdMap[category][childrenKey];
// 			for (let k = 0; k < subcategories.length; k++) {
// 				const subcategory: any = subcategories[k];
// 				selectedChildrenIds.push({ id: subcategory.id });
// 			}
// 		}
// 	}

// 	return selectedChildrenIds;
// };

const packFromTreeselect = (
	selectedCategory: any[],
	rootParams: any[],
	childrenKey: string,
) => {
	const selectedChildrenIds: any[] = [];
	const categoryIdMap: { [key: string]: any } = {};

	// Build the category ID map
	for (const category of rootParams) {
		categoryIdMap[category.id] = category;
	}

	// Process selected categories
	for (const category of selectedCategory) {
		const isChild = (category + "").indexOf("$#") > 0;

		if (isChild) {
			selectedChildrenIds.push({ id: parseInt(category.split("$#")[2]) });
		} else {
			const subcategories = categoryIdMap[category][childrenKey];
			for (const subcategory of subcategories) {
				selectedChildrenIds.push({ id: subcategory.id });
			}
		}
	}

	return selectedChildrenIds;
};

const inputFieldChanged = (keyStr: string) => {
	console.log(keyStr);
	if (keyStr) touchedKeys.value.add(keyStr);
};
</script>

<template>
	<div
		class="manage-user"
		:class="{ 'edit-user': isEditUser, 'create-new': !isEditUser }"
	>
		<div class="section">
			<h1 class="title is-3">
				<div
					style="cursor: pointer"
					class="return"
					@click="$router.go(-1)"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="13"
						height="23"
						viewBox="0 0 13 23"
					>
						<path
							fill="#7591AE"
							stroke="#7991AE"
							d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z"
							transform="translate(1 1)"
						></path>
					</svg>
				</div>
				<span class="sep"></span>
				<i-cpl-user-management class="color-svg" />
				<span class="txt">Edit Profile</span>
			</h1>
		</div>

		<!-- <div
			class="loading-wrap"
			v-if="isLoading"
		>
			<div class="loading-wrapper">
				<img src="../assets/images/comps/loader.svg" />
			</div>
			<button
				class="button button is-fullwidth is-generic-app-teal is-caps-lock"
				v-if="!isEditUser"
				@click="closeModal"
			>
				Close
			</button>
		</div> -->
		<form
			v-if="!isLoading"
			class="mt client-management"
			autocomplete="off"
		>
			<div class="lost-shift">
				<p class="control name">
					<input
						v-model="user.name"
						class="input is-medium"
						type="text"
						placeholder="NAME"
						@input="inputFieldChanged('name')"
					/>
				</p>
				<span
					v-if="errors.has('name')"
					class="help is-danger"
					v-html="errors.get('name')"
				></span>
				<p class="control">
					<input
						v-model="user.email"
						class="input is-medium"
						type="text"
						placeholder="EMAIL"
						autocomplete="off"
						@input="inputFieldChanged('email')"
					/>
				</p>
				<span
					v-if="errors.has('email')"
					class="help is-danger"
					v-html="errors.get('email')"
				></span>
				<p class="control">
					<input
						v-model="user.password"
						class="input is-medium"
						type="password"
						placeholder="PASSWORD"
						autocomplete="new-password"
						@input="inputFieldChanged('password')"
					/>
				</p>
				<span
					v-if="errors.has('password')"
					class="help is-danger"
					v-html="errors.get('password')"
				></span>
				<password-policy-validation
					:passwordCharacters="user.password"
					@password-valid="handlePasswordIsValid"
				/>
				<p class="control">
					<input
						v-model="user.confirmpass"
						class="input is-medium"
						type="password"
						placeholder="CONFIRM PASSWORD"
						autocomplete="new-password"
						:class="{ disable: confirmPasswordDisable }"
						@input="inputFieldChanged('confirmpass')"
						@change="errors.clear('confirmpassword')"
					/>
				</p>
				<span
					v-if="errors.has('confirmpassword')"
					class="help is-danger"
					v-html="errors.get('confirmpassword')"
				></span>
				<template v-if="!isEditUser">
					<p class="control">
						<multiselect-form
							v-model="user.userclass"
							:options="roles"
							mode="single"
							:close-on-select="true"
							value-prop="id"
							label="label"
							placeholder="SELECT USER CLASS"
							@select="errors.clear('role_id')"
							@input="inputFieldChanged('userclass')"
						>
							<template #noResult>
								<span>Nothing found.</span>
							</template>
						</multiselect-form>
					</p>
					<span
						v-if="errors.has('role_id')"
						class="help is-danger"
						v-html="errors.get('role_id')"
					></span>
					<p class="control">
						<tree-select
							v-model="user.jobs"
							:load-options="loadOptions"
							:options="allJobCategories"
							:auto-load-root-options="false"
							:multiple="true"
							placeholder="SELECT APPLICABLE SUBCATEGORIES"
							@open="errors.clear('subcategories')"
							@input="inputFieldChanged('jobs')"
						>
							<template #noResult>
								<span>Nothing found.</span>
							</template>
						</tree-select>
					</p>
					<span
						v-if="errors.has('subcategories')"
						class="help is-danger"
						v-html="errors.get('subcategories')"
					></span>
					<p class="control">
						<tree-select
							v-model="user.locations"
							:load-options="loadOptions"
							:options="locationsTreeselect"
							:auto-load-root-options="false"
							:multiple="true"
							placeholder="SELECT APPLICABLE WARDS"
							@open="errors.clear('locations')"
							@input="inputFieldChanged('locations')"
						>
							<template #noResult>
								<span>Nothing found.</span>
							</template>
						</tree-select>
					</p>
					<span
						v-if="errors.has('locations')"
						class="help is-danger"
						v-html="errors.get('locations')"
					></span>
				</template>
				<!-- <template></template> -->
				<p class="control">
					<span>ASSIGN PIN NUMBER</span>
					<span class="rectangle group">
						<input
							v-model="user.pinArr[0]"
							type="text"
							maxlength="1"
							@keyup="
								// @ts-ignored
								$event.target.nextElementSibling.focus()
							"
							@input="inputFieldChanged('pinArr')"
							@focus="
								// @ts-ignored
								$event.target.value = ''
							"
						/>
						<input
							v-model="user.pinArr[1]"
							type="text"
							maxlength="1"
							@keyup="
								// @ts-ignored
								$event.target.nextElementSibling.focus()
							"
							@input="inputFieldChanged('pinArr')"
							@focus="
								// @ts-ignored
								$event.target.value = ''
							"
						/>
						<input
							v-model="user.pinArr[2]"
							type="text"
							maxlength="1"
							@keyup="
								// @ts-ignored
								$event.target.nextElementSibling.focus()
							"
							@input="inputFieldChanged('pinArr')"
							@focus="
								// @ts-ignored
								$event.target.value = ''
							"
						/>
						<input
							v-model="user.pinArr[3]"
							type="text"
							maxlength="1"
							@focus="
								// @ts-ignored
								$event.target.value = ''
							"
							@input="inputFieldChanged('pinArr')"
						/>
					</span>
				</p>
				<span
					v-if="errors.has('pin')"
					class="help is-danger"
					v-html="errors.get('pin')"
				></span>
				<p
					v-for="(reportToggle, index) in reportTogglesFilter"
					:key="index"
					class="control"
					data-cell="report-toggles"
				>
					<label style="text-transform: uppercase">
						{{ reportToggle.label }}
					</label>
					<!-- <span class="group"> -->
					<switch-component
						v-model="user[reportToggle.key]"
						@change="toggleReport(reportToggle)"
					/>
					<!-- </span> -->
				</p>
				<p
					v-if="isEditUser"
					:class="{
						showMessagePasswordConfirmation: disableConfirm,
						hideMessagePasswordConfirmation: true,
					}"
				>
					{{ passwordConformationMessage2 }}
				</p>
				<p
					v-else
					:class="{
						showMessagePasswordConfirmation: isShowMessagePasswordConfirmation,
						hideMessagePasswordConfirmation: true,
					}"
				>
					{{ passwordConformationMessage }}
				</p>
				<div class="columns">
					<div class="column is-12">
						<p class="control">
							<button
								class="button"
								:class="{ 'is-disabled': false }"
								:disabled="disableConfirm || isLoadingManageUser"
								@click.prevent="manageUserFunc"
							>
								<i class="fa fa-check"></i>
								{{ isEditUser ? "Save Changes" : "Create User" }}
							</button>
						</p>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<style lang="scss" scoped>
.manage-user {
	h1 {
		margin-bottom: 21px;
		display: flex;
		.return {
			height: 100% !important;
			align-self: center;
		}
		// .return {
		// 	display: inline-block !important;
		// 	width: fit-content;
		// 	height: fit-content;
		// 	// background: red;
		// }
		svg {
			align-self: center;
		}
		.txt {
			margin-left: 5px;
			align-self: center;
		}
	}
	.hideMessagePasswordConfirmation {
		color: $red-cpl;
		display: none;
	}
	.showMessagePasswordConfirmation {
		color: $red-cpl;
		display: block;
		text-align: center;
	}

	.loading-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.lost-shift {
		margin: auto;
		width: calc(99.9% * 2 / 3 - (0px - 81px * 2 / 3));
		// width: calc(66.6% + 54px);
		max-width: 850px;
		// margin-left: calc(
		// 	99.9% * (-1 / 6 * -1) - (63px - 31px * (-1 / 6 * -1)) + 30px
		// ) !important;

		input {
			width: 100%;
		}

		.rectangle {
			input {
				color: $blue-cpl;
				font-size: 20px;
				font-weight: bold;
				text-align: center;
				height: 40px;
				width: 40px;
				border: 1px solid $blue-cpl;
				border-radius: 3px;
				background-color: $background-color;

				&:not(:last-child) {
					margin-right: 10px;
				}
			}
		}
	}

	span.sep {
		border: 1px solid #d6e0e5;
		margin: 0 0.8em;
		display: inline !important;
	}
	.control {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
		text-align: left;
		margin-bottom: 5px !important;

		&:not(:last-child) {
			margin: 0.75rem 0;
		}
		.button {
			margin: 20px 0;
			width: 100%;
			font-family: Lato, sans-serif !important;
			font-size: 16px !important;
			font-weight: 600 !important;
			text-transform: uppercase;
		}
	}

	// &:not(.edit-user) {
	// 	& > .title {
	// 		display: none !important;
	// 	}
	// }
}
.edit-user {
	section {
		// display: flex;

		display: initial !important;
	}
	// .section {
	// 	display: initial !important;
	// }
}
.create-new {
	width: 600px !important;
	padding-bottom: 20px;
	height: 820px;

	// background-color: $background-color;
	overflow: auto;
	.section {
		// display: flex;

		display: none !important;
	}
	// .mt.client-management {
	// 	overflow: auto !important;
	// 	max-height: 820px;
	// }
}

@media screen and (min-width: 768px) {
	.lost-shift {
		width: calc(99.9% * 2 / 3 - (0px - 81px * 2 / 3));
	}
}
</style>

<style lang="scss">
.help.is-danger {
	p.msg {
		color: $error-color;
	}
}
.vue-treeselect {
	width: 100%;
}
.vue-treeselect__control {
	min-height: 36px;
	height: fit-content !important;
	// display: flex;
	// flex-wrap: wrap;
}
.vue-treeselect__placeholder {
	// color: #75757d;
	// font-size: 1.1rem !important;
	// font-family: Lato, sans-serif;
	// style of input
	// color: $text-color;
	color: $text-placeholder;
	transition: opacity 0.2s ease;
	opacity: 0.8;

	// text-overflow: ellipsis;
	// overflow: hidden;
	font-size: 12px;
}
input.vue-treeselect__input {
	border-color: transparent !important;
}

.vue-treeselect__multi-value-item {
	white-space: nowrap;
}
.vue-treeselect__menu {
	overflow-x: auto;
	width: 100%;
}
.vue-treeselect__option {
	margin-top: 10px;
	// align-items: center;
}
.vue-treeselect__label {
	margin-top: 0;
}
</style>
