<script setup lang="ts">
import type {
	TCheckboxCompleteLabels,
	TItemsDropdown,
	TSubcategory,
} from "@/assets/js/rateManagementTypes";
import api from "@/api";
// import { parseErrors } from "@/assets/js/parseErrors";
import type { IRateType } from "@/assets/js/rateManagementTypes";
import type { TCategory } from "@/assets/js/globalTypes";
import type {
	TRateColumnData,
	TRateType,
} from "@/assets/js/rateManagementHelpers";
import { useToastStore } from "@/store/toast";
import { parseErrors } from "@/assets/js/parseErrors";

const toastStore = useToastStore();
const props = defineProps<{
	client?: Record<string, any> | null;
	type: IRateType;
}>();
const emit = defineEmits(["cancel-modal", "rates-updated", "close-modal"]);

const isLoadingCats = ref(false);
const rateMultiItemsOptions = ref<TItemsDropdown[]>([]);
const rateMultiItemsSelection = ref<TItemsDropdown | null>(null);
const inputModel = ref("");
const checkboxData = reactive<{
	list: TCategory[];
	checkedList: { id: TSubcategory["id"] }[];
}>({
	list: [],
	checkedList: [],
});
function getColumnData<T extends TRateType>(
	key: keyof T["columns"],
	type: T,
	isModal = false,
): TRateColumnData["multi"] | TRateColumnData["single"] | null {
	if (isModal) {
		// @ts-expect-error
		return type.columns[key].multi;
	}
	if (key in type.columns) {
		// @ts-expect-error
		return type.columns[key].single;
	}
	return null;
}
const columnDataSubcats = computed(() => {
	return getColumnData("subcategory", props.type, true);
});
const canShowSubcats = computed(() => {
	return Boolean(rateMultiItemsSelection.value);
});

const categoryList = ref([]);
const labels = computed<TCheckboxCompleteLabels>(() => {
	const label = columnDataSubcats.value?.label || "";

	return {
		selectAll: label,
		filter: `Filter ${label}`,
	};
});

const submitClicked = ref<boolean>(false);
function onSubmit() {
	saveGlobalRatesMulti();
}
async function saveGlobalRatesMulti() {
	submitClicked.value = true;
	const params = {
		type: props.type.type,
		cli_id: props.client?.id,
		cit_id: rateMultiItemsSelection.value?.cit_id,
		subcategories: checkboxData.checkedList,
	};

	const inputStr = rateMultiItemsSelection.value?.input;
	if (inputStr) {
		(params as any)[inputStr] = inputModel.value;
	}

	try {
		const res = await api.patchGlobalRatesMulti(params);
		const msg = res.data.message;
		toastStore.openToastSuccess(msg ?? "Rates successfully updated");
		emit("rates-updated");
		// wait for triggering fetch rates
		setTimeout(() => {
			submitClicked.value = false;
			closeModal();
		}, 500);
	} catch (err: any) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		submitClicked.value = false;
	}
}
// function onReset() {
//   console.log("Reset");
// }
// function onClose() {
// 	emit("cancel-modal", false);
// }
function closeModal() {
	emit("close-modal");
}

async function fetchCategories() {
	const params = {
		type: props.type.type,
		cli_id: props.client?.id,
	};
	try {
		const res = await api.getGlobalRatesCategoriesModal(params);
		categoryList.value = res.data.data.categories || [];
	} catch (err: any) {
		console.error("Error", err.message);
	}
}

async function loadSubcategories() {
	isLoadingCats.value = true;
	await fetchCategories();
	checkboxData.list = categoryList.value;
	isLoadingCats.value = false;
}

function onChangeMultiItems() {
	// Reset values on change
	checkboxData.checkedList = [];
	inputModel.value = "";
}

async function fetchGlobalRatesCategoriesModal() {
	const params = {
		type: props.type.type,
		cli_id: props.client?.id,
	};
	try {
		const res = await api.getGlobalRatesMultiDropdown(params);
		rateMultiItemsOptions.value = res.data.data || [];
	} catch (err: any) {
		console.warn("Error", err.message);
	}
}

onMounted(() => {
	fetchGlobalRatesCategoriesModal();
	loadSubcategories();
});

const showInstructions = ref<boolean>(false);
</script>

<template>
	<div class="rates-change-value-modal">
		<div class="inner-content">
			<div>
				<button @click.prevent="showInstructions = !showInstructions">
					Important
					<i-fa4-chevron-down
						class="caret"
						:class="{
							'caret-up': !showInstructions,
							'caret-up caret-up-rotated': showInstructions,
							rotate: true,
						}"
					></i-fa4-chevron-down>
					<!-- <i
						class="fa"
						:class="{
							'fa-caret-up': !showInstructions,
							'fa-caret-up fa-caret-up-rotated': showInstructions,
							rotate: true,
						}"
						aria-hidden="true"
					></i> -->
				</button>
			</div>
			<transition name="fade">
				<ul v-if="showInstructions">
					<li>
						Please select the value you would like to input. After selecting the
						value you would like to update, select subcategory or subcategories
						you would like to update. Then, input the value.
					</li>
					<li>
						By pressing Update button, the inputted value will be inputted for
						every increment of the selected subcategory or subcategories. In
						case there are yet no yellow rates for the selected subcategory, the
						system will create a new yellow copy of the selected
						subcategory/increment with the inputted value being the only
						difference.
					</li>
					<li>
						If there are already existing yellow rates for the selected
						subcategory, the system will not create new ones, but rather update
						the existing yellow rates.
					</li>
					<li>
						For those values that are editable both from this pop-up and from
						the table, please note that the last change you perform will be the
						one that is applied.
					</li>
				</ul>
			</transition>
			<multiselect-form
				v-model="rateMultiItemsSelection"
				class="column-select"
				:options="rateMultiItemsOptions"
				:searchable="true"
				:options-limit="999"
				select-label=""
				selected-label=""
				deselect-label=""
				track-by="label"
				label="label"
				value-prop="id"
				:multiple="false"
				:can-clear="false"
				:can-deselect="true"
				:close-on-select="true"
				:close-on-deselect="true"
				placeholder="Select an option"
				@input="onChangeMultiItems"
				@deselect="rateMultiItemsSelection = null"
			></multiselect-form>
			<transition name="fade">
				<div
					v-if="rateMultiItemsSelection && !submitClicked"
					class="action"
				>
					<label class="cats">
						<span>Subcategories</span>
						<checkbox-complete
							v-if="canShowSubcats"
							class="parent-check-list"
							:labels="labels"
							:data="checkboxData"
							:useWholeCheckedList="true"
							key-for-transform="subcategories"
						></checkbox-complete>
					</label>
					<label class="input-section wd-200">
						<span>{{ rateMultiItemsSelection.label }}</span>
						<input
							v-model="inputModel"
							class="input"
							:placeholder="rateMultiItemsSelection.label"
						/>
					</label>
				</div>
				<div
					v-else-if="submitClicked"
					class="loading-wrapper flex-center"
				>
					<img
						class="wd-80"
						src="@/assets/image/loader.svg"
					/>
				</div>
			</transition>
		</div>
		<div class="buttons-centered mgb-20">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				type="submit"
				:disabled="!rateMultiItemsSelection || submitClicked"
				:class="{
					'is-loading': submitClicked,
				}"
				@click.prevent="onSubmit"
			>
				Input values
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.rates-change-value-modal {
	height: 700px;
	width: 800px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 5px 10px;

	ul {
		list-style-type: disc;
		padding-left: 20px;
		color: $label-color;
	}

	li {
		margin: 5px 0; /* Add spacing between list items */
	}

	.caret {
		transition: transform 0.3s ease;
	}

	.caret-up {
		transform: rotate(180deg);
	}

	.caret-up-rotated {
		transform: rotate(0deg);
	}
	.inner-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 10px;

		.action {
			display: flex;
			overflow: auto;
			max-height: 400px;
			gap: 10px;

			.checkbox-complete {
				overflow: auto;
				padding-right: 10px;
			}

			.cats {
				display: flex;
				flex-direction: column;
				gap: 5px;
				flex: 1;
			}

			.input-section {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}
}

@include fade-transition;
</style>
