<script lang="ts" setup>
import { Errors } from "@/assets/js/errors";
import { useShiftsStore } from "@/store/shifts";

const shiftsStore = useShiftsStore();
const props = defineProps<{
	modalProps: any;
	isSaveLoading: boolean;
}>();
const emit = defineEmits(["close-modal", "cancel-shift"]);
function closeModal() {
	emit("close-modal");
}

const selectedCancellationReason = ref<any>(null);
const optionsCancellationReasons = ref<any>([]);
const shiftIds = ref<any>(null);
const shiftObjs = ref<any>(null);
const isLoading = ref<boolean>(false);
const multipleCancelation = ref<boolean>(false);
const showMultipleCancelModal = ref<boolean>(false);
const errors = ref<any>(new Errors());
const cancellationReasons = computed(() => shiftsStore.cancellationReasons);

function cancelIt() {
	/**
	 * Send context along with event data,
	 * so that we can receive any error that might occur.
	 * This methods fires an event in both cases, when cancelling single shift
	 * as well when cancelling multiple shifts using CANCELL ALL button below the table
	 *
	 * CANCELL ALL button appears only when using select checkbox.
	 */
	emit("cancel-shift", {
		data: {
			shifts: shiftIds.value,
			shiftObjs: shiftObjs.value,
			shift_cancellation_reason_id: selectedCancellationReason.value.id,
			successfullMessage: props.modalProps.successfullMessage,
			multiple_cancelation: multipleCancelation.value,
		},
		showMultipleCancelModal: showMultipleCancelModal.value,
	});
	setTimeout(() => {
		emit("close-modal");
	}, 400);
}

onMounted(async () => {
	shiftIds.value = props.modalProps.shiftIds;
	if (props.modalProps?.shiftObjs) {
		shiftObjs.value = props.modalProps?.shiftObjs;
		showMultipleCancelModal.value = true;
	}
	multipleCancelation.value = props.modalProps.multipleCancelation;
	await shiftsStore.fetchCancellationReasons();
	optionsCancellationReasons.value = cancellationReasons.value;
});
</script>
<template>
	<div class="dialog-container cancel-reason-modal">
		<div class="inputs">
			<div class="field">
				<label class="label">Please Select Cancellation Reason</label>
				<p class="control">
					<multiselect-form
						v-model="selectedCancellationReason"
						:options="cancellationReasons"
						:close-on-select="true"
						:searchable="true"
						:loading="isLoading"
						value-prop="id"
						track-by="id"
						label="reason"
						placeholder="CANCELLATION REASON"
					/>
					<span
						v-if="errors.has('shift_cancellation_reason_id')"
						class="help is-danger"
						v-html="errors.get('shift_cancellation_reason_id')"
					></span>
				</p>
			</div>
		</div>
		<div class="buttons-centered">
			<button
				class="alt cancel-btn button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				:disabled="!selectedCancellationReason || isSaveLoading"
				@click.prevent="cancelIt"
			>
				Submit
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.dialog-container {
	width: 400px;
	padding: 20px;
}

.inputs {
	display: flex;
	justify-content: center;

	.field {
		width: 100%;
	}
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}
.label {
	color: $label-accent-color-dark;
}
</style>
