<script lang="ts" setup>
import { debounce } from "@/assets/js/helpers";
import { useDefaultStore } from "@/store";
import api from "@/api";
import type {
	TAdditionalTBodyConfig,
	TTableColumn,
} from "../table/ModernTable.vue";
import type { TFetchWorkersSeenMessageParams } from "@/assets/js/globalTypes";

const systemPermissions = useDefaultStore().initialStatus;
const canUseNexusMiddleware = systemPermissions?.can_use_nexus_middleware;
const props = defineProps<{
	ntfId: number | string;
}>();
const loading = ref<boolean>(false);
const searchWorkers = ref<string>("");
const listOfWorkers = ref<any[]>([]);
const listOfWorkersMeta = ref();
const currentPage = ref<number>(1);
const totalPages = computed(() => {
	if (listOfWorkersMeta.value.pagination) {
		return listOfWorkersMeta.value.pagination.total_pages;
	}
	return 1;
});
const externalIdColumnNameComputed = computed(() =>
	canUseNexusMiddleware ? "Worker Reference ID" : "External system id",
);
const message = ref("Please wait");
const searchWorkersInputRef = ref<HTMLElement | null>(null);

onMounted(() => {
	getWorkers();
	focusInputSearchWorkers();
});

async function getWorkers() {
	loading.value = true;

	const params: TFetchWorkersSeenMessageParams = {
		notification_batch: props.ntfId,
		name: searchWorkers.value,
		page: currentPage.value,
	};
	listOfWorkers.value = null;
	try {
		const res = await api.getWorkersListOpenedMessages(params);
		listOfWorkers.value = res.data.data;
		listOfWorkersMeta.value = res.data.meta;
		currentPage.value = res.data.meta.pagination.current_page;
		if (!res.data.meta.pagination.count) {
			message.value = "No temps viewed this message.";
		}
		loading.value = false;
	} catch (error) {
		loading.value = false;
		console.log(error);
	}
}

const workerSearch = debounce(
	async () => {
		currentPage.value = 1;
		message.value = "Please wait.";
		getWorkers();
	},
	400,
	false,
);

async function pageHasChanged(pageNumberOfPagination: number) {
	currentPage.value = pageNumberOfPagination;
	message.value = "Please wait.";
	getWorkers();
}
function focusInputSearchWorkers() {
	searchWorkersInputRef.value?.focus();
}
const headers: TTableColumn[] = [
	{
		id: "name",
		label: "Name",
	},
	{
		id: "externalId",
		label: externalIdColumnNameComputed.value,
	},
	{
		id: "seen_at",
		label: "Message seen at",
	},
];
const tableConfig: TAdditionalTBodyConfig = {
	displayLoader: true,
	emptyTBodyMessage: "No results found.",
};
</script>
<template>
	<div class="list-of-workers">
		<div class="content mgl-10 mgr-10">
			<h2>{{ "Search workers" }}</h2>
			<div class="input-bar">
				<input
					ref="searchWorkersInputRef"
					v-model="searchWorkers"
					class="input"
					placeholder="Search workers"
					title="Search workers"
					@input="workerSearch"
				/>
				<h4 v-if="listOfWorkersMeta">
					Total: {{ listOfWorkersMeta?.pagination?.total ?? 0 }}
				</h4>
			</div>
		</div>
		<div class="content mgl-10 mgr-10">
			<modern-table
				class="mgt-10 pd-b-20"
				:columns="headers"
				:rows="listOfWorkers"
				:has-border="false"
				:display-additional-row="false"
				:isInfiniteScroll="false"
				:isSticky="false"
				style="overflow: visible"
				:hasAdditionalTBody="tableConfig"
			>
				<template #table-body-cell="slotProps">
					<td v-if="!loading && slotProps.column.id === 'name'">
						{{ slotProps.row.name }}
					</td>
					<td v-else-if="!loading && slotProps.column.id === 'externalId'">
						{{
							canUseNexusMiddleware
								? slotProps.row.external_id
								: slotProps.row.tss_id
						}}
					</td>
					<td v-else-if="!loading && slotProps.column.id === 'seen_at'">
						{{ slotProps.row.message_seen_time }}
					</td>
				</template>
			</modern-table>
		</div>

		<div>
			<PaginationComp
				v-if="listOfWorkers && listOfWorkers.length > 0"
				:total-pages="totalPages"
				:current-page="currentPage"
				@page-changed="pageHasChanged"
			></PaginationComp>
			<div
				v-else-if="!listOfWorkers"
				class="loading-wrap"
			>
				<div class="loading-wrapper">
					<img
						class="wd-30 right"
						src="@/assets/image/loader.svg"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.list-of-workers {
	display: flex;
	flex-direction: column;
	padding: 15px;
	width: 700px;
	max-height: 700px;
	.section {
		padding-left: 0;
	}
	.content {
		text-align: center;
		h2 {
			font-size: 14px;
			font-weight: 600;
		}
		h4 {
			text-align: left;
			margin: 5px 0 -10px;
			font-size: 14px;
		}
		.input-bar {
			.input {
				width: 250px;
				font-size: 14px;
			}
		}
	}
	.loading-container.center {
		text-align: center;
	}
	.pagination {
		margin: 0 auto;
		margin-bottom: 5px;
		.is-centered:deep(.pagination-list) {
			list-style: none !important;
			margin: 0;
		}
	}
	.content-without-workers {
		h3 {
			font-size: 14px;
		}
	}
	td {
		text-align: left;
		font-size: 0.9rem;
		padding: 7px 10px;
		border-top: 1px solid #dde6eb;
		&:not(.extra-row) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
	}
}
</style>
