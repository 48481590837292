<script setup lang="ts">
import api from "@/api";
import type { Errors } from "@/assets/js/errors";

const emit = defineEmits([
	"select-gnib-doc",
	"input-gnib-hours",
	"remove-gnib-doc",
]);

const props = defineProps<{
	errors: Errors;
	form: {
		gnib_dtp_id: number | null;
		gnib_active_max_week_hours: number | null;
	};
	isModifiedGnibDocs: boolean;
	selectedGnibDocId: number | null;
	selectedGnibMaxWeekValue: number | null;
}>();

const gnibDocs = ref([]);
const selectedGnibDocument = computed({
	get: () => {
		if (props.selectedGnibDocId) {
			return gnibDocs.value.find((doc) => doc.id === props.selectedGnibDocId);
		} else if (props.form?.gnib_dtp_id && !props.isModifiedGnibDocs) {
			return gnibDocs.value.find((doc) => doc.id === props.form.gnib_dtp_id);
		}
		return null;
	},
	set: (evt?) => {
		if (!evt) {
			emit("remove-gnib-doc", true);
			return;
		}
		const payload = {
			doc: evt.id,
			hours: null,
		};

		const maxWeeksGnibArr = getGnibMaxWeek(evt);
		payload.hours =
			maxWeeksGnibArr.length === 1 ? maxWeeksGnibArr[0].hours : null;

		emit("select-gnib-doc", payload);
	},
});

const selectedGnibMaxWeek = computed({
	get: () => {
		if (selectedGnibDocument.value) {
			const maxWeekOptions = getGnibMaxWeek(selectedGnibDocument.value);
			if (props.selectedGnibMaxWeekValue) {
				return maxWeekOptions.find(
					(mw) => mw.hours === props.selectedGnibMaxWeekValue,
				);
			} else if (
				props.form?.gnib_active_max_week_hours &&
				!props.isModifiedGnibDocs
			) {
				return maxWeekOptions.find(
					(mw) => mw.hours === props.form.gnib_active_max_week_hours,
				);
			}
		}
		return null;
	},
	set: (evt) => {
		const payload = {
			doc: selectedGnibDocument.value.id,
			hours: evt?.hours,
		};
		emit("input-gnib-hours", payload);
	},
});

function getGnibMaxWeek(doc) {
	return (
		doc?.GNIB_config?.max_week_hours?.map((mw) => {
			return {
				...mw,
			};
		}) || []
	);
}

async function fetchGnibDocs() {
	try {
		const params = {
			includes: ["locations", "categories", "roles"].join(","),
			page: 1,
			optimized: true,
			compliance_type: "GNIB",
		};
		const res = await api.fetchCompliances(params);
		gnibDocs.value = res.data.data || [];
	} catch (err) {
		console.log("ERR", err.message);
	}
}

onMounted(() => {
	fetchGnibDocs();
});
</script>
<template>
	<div class="gnib-document-wrap">
		<multiselect-form
			v-model="selectedGnibDocument"
			:options="gnibDocs"
			value-prop="id"
			label="name"
			placeholder="No GNIB document selected"
			can-clear
			@deselect.stop.prevent="selectedGnibDocument = null"
		></multiselect-form>
		<multiselect-form
			v-if="selectedGnibDocument"
			v-model="selectedGnibMaxWeek"
			value-prop="hours"
			placeholder="Select GNIB max week hours"
			label="label"
			:options="getGnibMaxWeek(selectedGnibDocument)"
		></multiselect-form>
	</div>
</template>

<style scoped lang="scss">
.gnib-document-wrap {
	display: flex;
	gap: 5px;
	.multiselect {
		flex: 1 1;
	}
}
</style>
