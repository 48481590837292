<script setup lang="ts">
type TFilePickerProps = {
	title?: string;
	placeholder?: string;
	multiple?: boolean;
};
export type TFilePickerChange = {
	evt: Event;
	files: FileList | null;
	hasFiles: boolean;
};

const props = withDefaults(defineProps<TFilePickerProps>(), {
	title: "",
	placeholder: "",
});
const emit = defineEmits<{
	change: [value: TFilePickerChange];
}>();

const inputFileRef = ref<HTMLInputElement | null>(null);

function onClickPicker() {
	if (inputFileRef.value) {
		inputFileRef.value.click();
	}
}

function onClickFile() {
	inputFileRef.value && (inputFileRef.value.value = ""); // Clear input so we can reselect the same file/s
}
function onChangeFile(evt: Event) {
	const files = (evt.target as HTMLInputElement).files;
	const hasFiles = !!files?.length;
	emit("change", { evt, files, hasFiles });
}

defineExpose({
	onClickPicker,
});

defineSlots<{
	filePicker: () => any;
	button: () => any;
	buttonLabel: () => any;
}>();
</script>

<template>
	<slot name="filePicker">
		<span
			class="file-picker-wrap"
			tabindex="0"
			@click.prevent="onClickPicker"
			@keyup.enter.space.prevent="onClickPicker"
		>
			<slot name="button">
				<button
					class="file-picker-btn"
					:title="title"
					:placeholder="placeholder"
				>
					<slot name="buttonLabel"><span>Choose file</span></slot>
				</button>
			</slot>
		</span>
	</slot>
	<input
		v-bind="props"
		ref="inputFileRef"
		class="dummy"
		type="file"
		:multiple="multiple"
		@click="onClickFile"
		@change="onChangeFile"
	/>
</template>

<style lang="scss" scoped>
.file-picker-wrap {
	width: fit-content;
}

.dummy {
	visibility: collapse;
	height: 0;
	width: 0;
	position: absolute;
}
</style>
