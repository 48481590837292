<script lang="ts" setup>
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";

const store = useDefaultStore();
const sessionStore = useSessionStore();
// const canUseNexusMiddleware = store.systemPermissions.canUseNexusMiddleware;

const systemPermissions = store.initialStatus;
const canUseNexusMiddleware = systemPermissions?.can_use_nexus_middleware;
const canUseExpenses = systemPermissions?.can_use_expenses;

const can = sessionStore.can;

const links: {
	id: number;
	icon: any;
	permission: boolean;
	text: string;
	link: string | { name: string; params?: { [k: string]: any } };
}[] = [
	{
		id: 1,
		icon: ICplDashboard,
		permission: can("view-dashboard"),
		link: { name: "admin.dashboard" },
		text: "Dashboard",
	},
	{
		id: 2,
		icon: ICplCalendar,
		permission: can("view-calendar"),
		link: { name: "admin.calendar" },
		text: "Client Calendar",
	},
	{
		id: 3,
		icon: ICplCalendar,
		permission: can("view-temp-calendar"),
		link: can("view-calendar")
			? { name: "admin.temp-calendar" }
			: { name: "temp.calendar" },
		text: can("view-calendar") ? "Candidate Calendar" : "Calendar",
	},
	{
		id: 4,
		icon: ICplBookShifts,
		permission: can("create-shift-request"),
		link: { name: "admin.shift-submit" },
		text: "Book Shifts",
	},
	{
		id: 5,
		icon: ICplShiftSchedule,
		permission: can("view-shifts"),
		link: { name: "admin.shift-schedule.shifts" },
		text: "Shift Schedule",
	},
	{
		id: 6,
		icon: ICplShiftSchedule,
		permission: can("view-temp-shifts"),
		link: { name: "admin.shift-schedule" },
		text: "My Shifts",
	},
	{
		id: 7,
		icon: ICplExpenses,
		permission: canUseExpenses && can("view-expenses"),
		link: { name: "admin.expenses" },
		text: "Expenses",
	},
	{
		id: 8,
		icon: IFa4Calculator,
		permission: can("manage-rates"),
		link: { name: "admin.manage-rates" },
		text: "Rate management",
	},
	{
		id: 9,
		icon: ICplCompliance,
		permission: can("view-temps&view-documents-for-approval"),
		link: { name: "admin.compliance" },
		text: "Compliance",
	},
	{
		id: 10,
		icon: ICplDocuments,
		permission: can("view-temp-documents"),
		link: { name: "temp.documents" },
		text: "My Documents",
	},
	{
		id: 11,
		icon: ICplDocuments,
		permission: can("view-documents"),
		link: { name: "admin.documents" },
		text: "Documents",
	},
	{
		id: 12,
		icon: ICplAllCandidates,
		permission: can("view-all-candidates-page"),
		link: { name: "admin.all-candidates" },
		text: "All Candidates",
	},
	{
		id: 13,
		icon: ICplUserManagement,
		permission: can("create-user"),
		link: { name: "admin.user-management" },
		text: "User Management",
	},
	{
		id: 14,
		icon: ICplClientManagement,
		permission: can("view-client-management-page"),
		link: { name: "admin.client-management" },
		text: "Client Management",
	},
	{
		id: 15,
		icon: ICplPayNBill,
		permission: can(
			"view-billing|view-holiday-requests-billing|view-expenses-billing|release-premium-holiday-pay",
		),
		link: { name: "admin.invoices" },
		text: "Pay & Bill",
	},
	{
		id: 16,
		icon: ICplReports,
		permission: can("view-report|schedule-report"),
		link: { name: "admin.reports" },
		text: "Reports",
	},
	{
		id: 17,
		icon: ICplUserEdit,
		// edit profile used to identify if user is temp
		permission: can("edit-profile"),
		link: { name: "temp.profile" },
		text: "My Profile",
	},
	{
		id: 18,
		icon: IFa4Shield,
		permission: can("manage-security"),
		link: { name: "admin.security" },
		text: "Settings",
	},
	{
		id: 19,
		icon: IFa4CloudUpload,
		permission: can("bulk-upload-timesheet"),
		link: { name: "admin.file-upload" },
		text: "File uploader",
	},
	{
		id: 20,
		icon: IFa4Envelope,
		permission: can("view-notificationcentre-messages"),
		link: { name: "admin.notifications" },
		text: "Notification Centre",
	},
	{
		id: 21,
		icon: ICplRateManagement,
		permission: canUseNexusMiddleware && can("view-backpay-page"),
		link: { name: "admin.backpay" },
		text: "Backpay",
	},
	{
		id: 22,
		icon: IFa4CodeFork,
		permission: canUseNexusMiddleware && can("view-nexus-module-page"),
		link: { name: "admin.nexus" },
		text: "Nexus",
	},
];

const activeLinks = computed(() => links.filter((el) => el.permission));

function toggle() {
	store.sidebarOpen = !store.sidebarOpen;
}
</script>
<template>
	<div
		class="zap-slideout"
		:class="{ isOpen: store.sidebarOpen }"
	>
		<a
			class="zap-slideout-opener"
			@click="toggle"
		>
			<button class="native-button">☰</button>
		</a>
		<ul class="menu-list zap-slideout-menu">
			<li
				v-for="link in activeLinks"
				:key="link.id"
			>
				<router-link :to="link.link">
					<component :is="link.icon"></component>
					<span>{{ link.text }}</span>
				</router-link>
			</li>
		</ul>
	</div>
</template>
<style lang="scss">
$opener-color: #363636;
.zap-slideout-menu {
	font-weight: 600;
	color: $background-basic;
	overflow-y: auto;
	height: 100%;
	font-size: 0.75rem;
	padding-top: 1.2em;
	.fa {
		font-size: 33px;
	}
}

.corrections-sidebar-image {
	width: 40px;
	height: 40px;
}
.menu-title img {
	max-width: 65px;
}
.menu-list li {
	text-align: center;
}
.menu-list li a {
	text-transform: uppercase;
	min-height: 85px !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 1em 0;
	transition: color 0.3s ease;
	line-height: 15px;
	svg {
		width: 33px;
		height: 33px;

		path {
			fill: white;
			transition: fill 0.3s ease;
		}
	}
	&.router-link-active {
		background-color: inherit;
		// color: var(--color-secondary-40);
		color: var(--color-primary-60);

		svg {
			path {
				fill: var(--color-primary-60);
			}
		}
	}
	&:hover {
		color: var(--color-primary-90);
		svg {
			path {
				fill: var(--color-primary-90);
			}
		}
	}
}

.main-content {
	margin-top: 5.5em;
	position: relative;
	background: $background-basic;
	transition: margin-left 0.3s;
	transition-delay: 0.04s;
	// transform: translateX(0);
	width: 100%;

	&.isOpen {
		margin-left: 130px;
	}
	&.modern-main-content {
		width: auto;
		flex: 1;
	}
}

.zap-slideout {
	position: fixed;
	width: 130px;
	height: 100vh;
	top: 0;
	z-index: 101;
	background-color: var(--color-secondary);
	transform: translateX(-100%);
	transition: transform 0.3s;

	&.isOpen {
		transform: translateX(0);
	}
}

.zap-slideout-opener {
	position: absolute;
	top: 20px;
	left: 100%;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	cursor: pointer;
	padding: 0 1.4em;
	button {
		appearance: none;
		align-items: center;
		border-radius: 4px;
		box-shadow: none;
		display: inline-flex;
		font-size: 1rem;
		height: 2.285em;
		line-height: 1.5;
		font-weight: 400;
		position: relative;
		vertical-align: top;
		-webkit-touch-callout: none;
		background-color: $background-basic;
		border: 1px solid #dbdbdb;
		color: $opener-color;
		cursor: pointer;
		justify-content: center;
		padding-left: 0.75em;
		padding-right: 0.75em;
		text-align: center;
		white-space: nowrap;
		&:hover {
			border-color: $grey;
		}
	}
}

/* The famed Zap agency logo (TM) */
.zap-emoji {
	height: 120px;
}

// .no-flexbox .zap-slideout-menu {
//   .fa {
//     display: block;
//     font-size: 33px;
//   }
// }
</style>
