import type { AxiosError } from "axios";
import type { TGenericRecord } from "./globalTypes";

/**
 * Helper to prepare errors for printing via Toasted (notifications)
 */
export const parseErrors = (
	error: AxiosError<TGenericRecord>,
	defaultMessage = "",
	isMessageFirst = false,
) => {
	if (error?.response?.data) {
		if (error.response.data instanceof Blob) {
			// const msg = await error.response.data.text()
			// We didn't parse blob because it async, we neeparseErrorsd to refactor the whole codebase
			if (error.response.status === 400) {
				return "You're unauthorized for this action.";
			}
			return "No data for download";
		} else {
			const errs = error.response.data.errors;
			const msg = error.response.data.message;
			const getFirstErr = (errs) => {
				return errs ? Object.values(errs).map((e) => e[0]) : null;
			};

			if (error.response.status === 403) {
				// Hardcoded error because axios fails to parse
				return "You're unauthorized for this action.";
			}

			return isMessageFirst
				? msg || getFirstErr(errs)
				: getFirstErr(errs) || msg;
		}
	}
	return defaultMessage;
};
export const parseErrorKeys = (error: any) => {
	let errorKeys = [];
	if (error?.response?.data) {
		const errorsObj = error.response.data.errors;
		if (errorsObj) errorKeys = Object.keys(errorsObj);
	}
	return errorKeys;
};
export const findRecordErrors = (error: any) => {
	const errs = error?.response?.data?.errors;
	return errs || null;
};

export const parseAllErrors = (
	error: AxiosError<TGenericRecord>,
	defaultMessage = "",
	isMessageFirst = false,
) => {
	if (error?.response?.data) {
		if (error.response.data instanceof Blob) {
			return "No data for download";
		} else {
			const errorsArr = [];
			const errs = error.response.data.errors;
			const msg = error.response.data.message;
			const allErrors = Object.values(errs);
			if (Array.isArray(allErrors) && allErrors.length > 0) {
				for (const err of Object.values(errs)) {
					if (Array.isArray(err) && err.length > 0) {
						for (const er of err) {
							errorsArr.push(er);
						}
					}
				}
				return errorsArr;
			} else {
				const getFirstErr = (errs) => {
					return errs ? Object.values(errs).map((e) => e[0]) : null;
				};

				if (error.response.status === 403) {
					// Hardcoded error because axios fails to parse
					return "You're unauthorized for this action.";
				}

				return isMessageFirst
					? msg || getFirstErr(errs)
					: getFirstErr(errs) || msg;
			}
		}
	}
	return defaultMessage;
};
