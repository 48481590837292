<script lang="ts" setup>
import api from "@/api";
import { REPUSH_STATUS } from "@/assets/js/componentsConfig/shifts";
import { debounce } from "@/assets/js/helpers";
import { SHIFT_TYPES } from "@/assets/js/mapData";
import { parseErrors } from "@/assets/js/parseErrors";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
const shiftsStore = useShiftsStore();
const props = defineProps<{
	modalProps: {
		shift: any;
		index?: number;
	};
}>();
const emit = defineEmits(["close-modal", "is-save-loading"]);

const shift = ref<any>(null);
const isLoading = ref<boolean>(false);
const isButtonDisabled = ref<boolean>(false);
const selectedForPush = ref<any>([]);
const regardlessAvailability = ref<number>(2);
const searchField = ref<any>(null);

const tempsForShift = ref<any>([]);
async function fetchTempsForShift(params) {
	try {
		// get temps per shift (REPUSH action)
		const response = await api.fetchTempsForShift(params.id, params);
		// commit changed shift object into state (replace shift object)
		tempsForShift.value = response.data?.data;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}

watch(regardlessAvailability, () => {
	selectedForPush.value = [];
	fetchTemps(shift.value.id);
});

async function fetchTemps(id) {
	isLoading.value = true;
	try {
		await fetchTempsForShift({
			id: shift.value.id,
			availability: regardlessAvailability.value,
			name: searchField.value,
			has_on_call: props.modalProps.shift.type === SHIFT_TYPES.oncall.key,
			has_sleepover: props.modalProps.shift.type === SHIFT_TYPES.sleepover.key,
			shift_type: props.modalProps.shift.type,
		});
		isLoading.value = false;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		isLoading.value = false;
	}
}
function closeModal() {
	emit("close-modal");
}
const searchWorkers = debounce(
	async () => {
		try {
			await fetchTempsForShift({
				id: shift.value.id,
				availability: regardlessAvailability.value,
				name: searchField.value,
				shift_type: props.modalProps.shift.type,
			});
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
		}
	},
	400,
	false,
);
function repushStatus(status) {
	const statusObj = REPUSH_STATUS.find(
		(statusObj) => statusObj.status === status,
	);
	if (statusObj) {
		return {
			hex: statusObj.hex,
			label: statusObj.label,
		};
	}
	return {
		hex: "",
		label: "",
	};
}
async function push() {
	isButtonDisabled.value = true;
	// push to checked temps
	const temps = selectedForPush.value.map((id) => ({ id: id }));

	try {
		const res = await shiftsStore.pushToTemps(
			props.modalProps.shift.id,
			temps,
			regardlessAvailability.value,
			props.modalProps?.index,
		);
		if (res) toastStore.openToastSuccess("Repush successful!");
		isButtonDisabled.value = false;
		emit("is-save-loading", false);
		closeModal();
		return true;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		isButtonDisabled.value = false;
		emit("is-save-loading", false);
		return false;
	}
}
function selectAllAvailable() {
	const arr = [];
	for (const temp of tempsForShift.value) {
		if (temp.status !== 2) {
			arr.push(temp.id);
		}
	}
	selectedForPush.value = arr;
}

// const tempList = [
// 	{
// 		id: 8607,
// 		name: "Kinez kinez1 kinez1 (adasd)",
// 		first_name: "Kinez kinez1",
// 		last_name: "kinez1",
// 		is_temp_preferred: null,
// 	},
// ];
onMounted(() => {
	shift.value = props.modalProps.shift;
	fetchTemps(shift.value.id);
});
</script>
<template>
	<div class="dialog-container repush-for-temps">
		<label class="label checkbox">
			<input
				v-model="regardlessAvailability"
				type="checkbox"
				:true-value="0"
				:false-value="2"
			/>
			<span>All candidates regardless of availability</span>
		</label>
		<div class="flex-center fsb mgt-10 mgb-10">
			<label
				v-if="tempsForShift.length"
				class="mg-0"
			>
				<button
					class="button is-generic-app-teal"
					@click="selectAllAvailable"
				>
					Select all available
				</button>
			</label>
			<input
				v-model="searchField"
				class="input wd-100-p mg-0 mgl-5"
				placeholder="Search by name"
				title="Search by name"
				type="text"
				@input="searchWorkers"
			/>
		</div>
		<div class="inputs">
			<div class="field">
				<label class="label">
					<span>Select agency workers for booking</span>
					<span
						v-if="selectedForPush && selectedForPush.length"
						style="margin-left: 10px"
					>
						( Selected workers: {{ selectedForPush.length }} )
					</span>
				</label>
				<p class="control"></p>
			</div>
		</div>
		<div class="table-wrap">
			<table class="table is-striped">
				<thead>
					<tr>
						<th>Select</th>
						<th>Name</th>
						<th>Response status</th>
					</tr>
				</thead>
				<tbody class="repush-table-body">
					<template v-if="!isLoading && tempsForShift.length !== 0">
						<tr
							v-for="temp in tempsForShift"
							:key="temp.id"
						>
							<td>
								<input
									v-model="selectedForPush"
									type="checkbox"
									:value="temp.id"
								/>
							</td>
							<td :class="{ preferred: temp.is_temp_preferred == 1 }">
								{{ temp.name }}
							</td>
							<td>
								<span
									class="generic-app-tag"
									:style="{ backgroundColor: repushStatus(temp.response).hex }"
								>
									{{ temp.response ? repushStatus(temp.response).label : "-" }}
								</span>
							</td>
						</tr>

						<!-- <tr
							v-for="temp in tempList"
							:key="temp.id"
						>
							<td>
								<input
									v-model="selectedForPush"
									type="checkbox"
									:value="temp.id"
								/>
							</td>
							<td :class="{ preferred: temp.is_temp_preferred == 1 }">
								{{ temp.name }}
							</td>
							<td>
								<span
									class="generic-app-tag"
									:style="{ backgroundColor: repushStatus(temp.response).hex }"
								>
									{{ temp.response ? repushStatus(temp.response).label : "-" }}
								</span>
							</td>
						</tr> -->
					</template>
					<tr
						v-if="!isLoading && !tempsForShift.length"
						class="none-available"
					>
						<td colspan="3">None available</td>
					</tr>
					<tr v-if="isLoading">
						<td
							class="center-text"
							colspan="12"
						>
							<img
								class="mg-auto"
								src="@/assets/image/loader.svg"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="buttons-centered">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				:disabled="isButtonDisabled"
				@click.prevent="push"
			>
				Save
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$generic-app-tag: #dbe1e5;
$tr-hover-background: #f5f5f5;
$table-header-background: #f2f7fa;
$td-text-color: #405168;
.generic-app-tag {
	background-color: $generic-app-tag;
	border-radius: 3px;
	padding: 0.2em 0.5em;
}
.repush-for-temps {
	min-height: 360px;
	// align-self: end;
	display: flex;
	flex-direction: column;
}

.table-wrap {
	max-height: 40vh;
	// width: 610px;
	overflow: auto;
}
.buttons-centered {
	margin-top: auto;
}

.modal-content {
	overflow: visible;
}

table {
	overflow: auto;
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #dde6eb;
	th {
		color: $text-color-lighter;
		background: $table-header-background;
		text-align: left;
		padding: 7px 10px;
		z-index: 1;
		border-top: 1px solid #dde6eb;
		overflow: visible !important;
		position: sticky;
		top: 0;
	}

	td {
		font-size: 0.9rem;
		padding: 7px 10px;
		border-top: 1px solid #dde6eb;
		color: $td-text-color;
		overflow: visible !important;
		&:not(.extra-row) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
		.flexer {
			display: flex;
			align-items: center;

			span {
				display: flex;
				align-items: center;
			}
			span:first-child {
				margin-right: auto;
			}
		}
	}
	.none-available {
		height: 50px;
		td {
			text-align: center;
		}
	}
}

.label {
	color: $label-accent-color-dark;
}
</style>
