<script lang="ts" setup>
import api from "@/api";
import { debounce } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
// import { parseErrors } from "../../../lib/helpers/function";

const props = withDefaults(
	defineProps<{
		tempId?: number | null;
		initialSectors?: any[];
		manageAction?: boolean;
	}>(),
	{
		tempId: null,
		initialSectors: () => [],
		manageAction: false,
	},
);

const emit = defineEmits<{
	"close-modal": [void];
	"cost-centre-check-change": [{ id: number; value: boolean }];
	"temp-pref": [{ id: number; is_temp_preferred: boolean }];
	"reason-change": [{ id: number; reason: string }];
	confirm: [locations?: any[]];
}>();

// sectors: JSON.parse(JSON.stringify(this.modalProps.sectors)),
const sectorClientArray = ref([]);
const expandedNodes = ref({
	sectors: [],
	clients: [],
});
const tempId = computed(() => props.tempId);
const isLoadingSave = ref(false);
const searchText = ref("");
const searchTextLazy = ref("");

const sectors = computed(() =>
	JSON.parse(JSON.stringify(props.initialSectors)),
);

const filteredSectors = computed(() => {
	return props.initialSectors.filter((sector) => {
		const hasClient = Array.isArray(sector.clients) && sector.clients.length;
		if (hasClient) {
			return sector.clients.some((client) => {
				const hasLocs =
					Array.isArray(client.locations) && client.locations.length;
				if (hasLocs) {
					return searchTextLazy.value ? isClientOrLocFound(client) : true;
				}
			});
		}
		return false;
	});
});
function isClientOrLocFound(client) {
	const isClientFound = client.name
		.toLowerCase()
		.includes(searchTextLazy.value.toLowerCase());
	const isLocFound =
		Array.isArray(client.locations) &&
		client.locations.some((loc) =>
			loc.name.toLowerCase().includes(searchTextLazy.value.toLowerCase()),
		);
	return isClientFound || isLocFound;
}

const updateSearchTextLazy = () => {
	searchTextLazy.value = searchText.value;
};
const updateSearchTextLazyDebounced = debounce(
	updateSearchTextLazy,
	300,
	false,
);
watch(searchText, updateSearchTextLazyDebounced);
onMounted(() => {
	prepareData();
	// console.log("SECTORS :: ", JSON.stringify(props.modalProps.sectors));
	// searchInput.value.focus();
});
function filteredLocations(locations) {
	// if (this.searchTextLazy) {
	//   return locations.filter(loc => loc.name.includes(this.searchTextLazy))
	// }
	return locations;
}
function sectorClientsMapped(clients) {
	const clIds = new Set(clients.map((client) => client.id));
	return sectorClientArray.value.filter((mapClient) => {
		const hasMappedClient = clIds.has(mapClient.id);
		const isFilterText = searchTextLazy.value
			? isClientOrLocFound(mapClient)
			: true;
		return hasMappedClient && isFilterText;
	});
}
function sectorPreferenceChanged(sector, value) {
	console.log("Sector Preference Changed", value);
	onPreferenceChanged("sector", sector, value);
}
function clientPreferenceChanged(client, value) {
	console.log("Client Preference Changed");
	onPreferenceChanged("client", client, value);
}
function locationPreferenceChanged(location, value) {
	const isDisabled = isLocationDisabled(location);
	if (isDisabled) return;

	console.log(location.isTempPreferred, value);
	// location.isTempPreferred = value
	onPreferenceChanged("location", location, value);
}
function onPreferenceChanged(objectType, object, value) {
	console.log("ON PREFERENCE CHANGED ::", objectType, object, value);
	let i = null;
	let len = null;
	switch (objectType) {
		case "location": {
			object.isTempPreferred = value;

			const payload = {
				id: object.id,
				is_temp_preferred: value,
			};
			emit("temp-pref", payload);

			return;
		}
		case "sector": {
			const clients = sectorClientsMapped(object.clients) || [];
			len = clients.length;
			for (const client of clients) {
				onPreferenceChanged("client", client, value);
			}
			return;
		}
		case "client": {
			len = object.locations === undefined ? 0 : object.locations.length;
			for (i = 0; i < len; i++) {
				onPreferenceChanged("location", object.locations[i], value);
			}
			return;
		}
		// No default
	}
}
function prepareData() {
	let i = null;
	let j = null;
	const lenS = sectors.value.length;
	// Remove clients that have no locations for each sector
	for (i = lenS - 1; i >= 0; i--) {
		let lenC =
			sectors.value[i].clients === undefined
				? 0
				: sectors.value[i].clients.length;
		for (j = lenC - 1; j >= 0; j--) {
			const client = sectors.value[i].clients[j];
			if (client.locations === undefined || client.locations.length === 0) {
				sectors.value[i].clients.splice(j, 1);
			}
		}

		// Remove sectors which have no clients
		lenC =
			sectors.value[i].clients === undefined
				? 0
				: sectors.value[i].clients.length;
		if (lenC === 0) {
			sectors.value.splice(i, 1);
		}
	}

	// Set up clients array
	const tempArr = [];
	const map = new Map();
	for (const sector of sectors.value) {
		for (const client of sector.clients) {
			if (!map.has(client.id)) {
				map.set(client.id, true);
				tempArr.push(client);
			}
		}
	}
	sectorClientArray.value = tempArr;
}
function isLocationDisabled(location) {
	return Boolean(location.archived);
}
function invokeEmitChange(evt, location) {
	const isDisabled = isLocationDisabled(location);
	if (isDisabled) return;

	const payload = {
		id: location.id,
		value: evt.target.checked,
	};
	emit("cost-centre-check-change", payload);
}
function checkTriggered(objectType, parent, child, checkValue) {
	const checkAll = !checkValue;
	if (objectType === "sector") {
		const clients = sectorClientsMapped(child.clients);
		for (const client of clients) {
			checkTriggered("client", child, client, checkValue);
		}
		return;
	} else if (objectType === "client") {
		for (const location of child.locations) {
			location.checked = checkAll;

			const payload = {
				id: location.id,
				value: checkAll,
			};
			emit("cost-centre-check-change", payload);
		}
		return;
	}
}
function isAllChecked(objectType, object) {
	if (objectType === "client") {
		return (object.locations || []).every((loc) => loc.checked);
	}
	if (objectType === "sector") {
		const clients = sectorClientsMapped(object.clients) || [];
		return clients.every((cli) => ifAnyChecked("client", cli));
	}
}
function ifAnyChecked(objectType, object) {
	if (objectType === "client") {
		return (object.locations || []).some((loc) => loc.checked);
	} else if (objectType === "sector") {
		// If sector is object
		const clients = sectorClientsMapped(object.clients) || [];
		return clients.some((cli) => ifAnyChecked("client", cli));
	}
}
function getNumCheckedLeaves(objectType, object) {
	if (objectType === "client") {
		return Array.isArray(object.locations)
			? object.locations.filter((loc) => loc.checked).length
			: 0;
	} else if (objectType === "sector") {
		let sum = 0;
		const clients = sectorClientsMapped(object.clients) || [];
		for (const client of clients) {
			sum = sum + getNumCheckedLeaves("client", client);
		}
		return sum;
	}
}
function getPreferredValue(objectType, object) {
	let i = null;
	let len = null;
	let firstTypeFound;
	// If object is location
	if (objectType === "location") {
		return !Object.prototype.hasOwnProperty.call(object, "isTempPreferred") ||
			object.isTempPreferred === null
			? "mix"
			: object.isTempPreferred;
	}
	// If object is client
	if (objectType === "client") {
		len = object.locations === undefined ? 0 : object.locations.length;
		for (i = 0; i < len; i++) {
			const CC = object.locations[i];
			// Remember first value we find
			if (i === 0) {
				firstTypeFound = getPreferredValue("location", CC);
			} else {
				// Compare the first value to every other one
				// If it's different at any point, return 'mix'
				if (firstTypeFound !== getPreferredValue("location", CC)) {
					return "mix";
				}
			}
		}
		// If we hadn't returned mix, return the value
		return firstTypeFound;
	}
	// If sector is object
	if (objectType === "sector") {
		const clients = sectorClientsMapped(object.clients) || [];
		len = clients === undefined ? 0 : clients.length;
		for (i = 0; i < len; i++) {
			const client = clients[i];
			// Remember first value we find
			if (i === 0) {
				firstTypeFound = getPreferredValue("client", client);
			} else {
				// Compare the first value to every other one
				// If it's different at any point, return 'mix'
				if (firstTypeFound !== getPreferredValue("client", client)) {
					return "mix";
				}
			}
		}

		// If we hadn't returned mix, return the value
		return firstTypeFound;
	}
}

function shouldShowReason(objectType, object, parent) {
	// If object is location
	if (objectType === "location") {
		return getPreferredValue(objectType, object) === false && object.checked;
	}
}
function checkSectorFoldState(sector) {
	return expandedNodes.value.sectors.includes(sector.id);
}
function checkClientFoldState(client) {
	return expandedNodes.value.clients.includes(client.id);
}
async function saveAndClose() {
	isLoadingSave.value = true;
	const locationsToSend = [];
	// for (const sector of sectors.value) {
	for (const client of sectorClientArray.value) {
		for (const location of client.locations) {
			// Skip the unchecked ones
			if (location.checked === false) continue;
			const id = location["id"];
			let isTempPreferred;
			if (location["isTempPreferred"] === true) {
				isTempPreferred = true;
			}
			if (location["isTempPreferred"] === false) {
				isTempPreferred = false;
			}
			const costCtrObjForApi = {};
			costCtrObjForApi["id"] = id;
			costCtrObjForApi["reason"] = location.reason;
			if (isTempPreferred !== undefined) {
				costCtrObjForApi["is_temp_preferred"] = isTempPreferred;
			}
			locationsToSend.push(costCtrObjForApi);
		}
	}

	if (!tempId.value) {
		console.log(locationsToSend);
		emit("confirm", locationsToSend);
		return;
	}
	// }

	try {
		await api.patchTemp(
			tempId.value,
			{
				locations: locationsToSend,
			},
			{ include: "consultant,locations" },
		);

		toastStore.openToastSuccess("Wards updated successfully");
		emit("confirm");
		emit("close-modal");
	} catch (err) {
		const errs = parseErrors(err);
		toastStore.openToastError(errs);
	} finally {
		isLoadingSave.value = false;
	}
}
function expandElement(element, type) {
	if (type === "sector") {
		const index = expandedNodes.value.sectors.indexOf(element.id);
		if (index === -1) {
			expandedNodes.value.sectors.push(element.id);
		} else {
			expandedNodes.value.sectors.splice(index, 1);
		}
	} else if (type === "client") {
		const index = expandedNodes.value.clients.indexOf(element.id);
		if (index === -1) {
			expandedNodes.value.clients.push(element.id);
		} else {
			expandedNodes.value.clients.splice(index, 1);
		}
	} else {
		console.log(">>> undefined", element, type);
	}
}
function info(location) {
	const payload = {
		id: location.id,
		reason: location.reason,
	};
	emit("reason-change", payload);
}
</script>
<template>
	<div class="cc-pref-container">
		<div class="search-bar">
			<input
				ref="searchInput"
				v-model.trim="searchText"
				class="input"
				type="text"
				placeholder="Filter by client or ward"
				style="margin: 10px 0"
				autofocus
			/>
		</div>
		<div class="table-wrap">
			<table class="generic-app-cc-pref">
				<tbody>
					<tr>
						<th colspan="2">WARD</th>
						<th>
							<span class="preferred">PREFERRED</span>
							<br />
							<span class="nonpreferred">NON-PREFERRED</span>
						</th>
						<th style="width: 200px">REASON</th>
					</tr>
					<template
						v-for="sector in filteredSectors"
						:key="sector.id"
					>
						<tr>
							<td>
								<div class="ccp-name">
									<label
										class="ctrl ctrl--checkbox"
										@click.prevent="
											checkTriggered(
												'sector',
												undefined,
												sector,
												ifAnyChecked('sector', sector),
											)
										"
									>
										{{ sector.name }}
										<input
											class="ctrl--checkbox"
											type="checkbox"
											:checked="isAllChecked('sector', sector)"
										/>
										<span
											class="ctrl__status"
											:class="{
												'status--halfstate': ifAnyChecked('sector', sector),
											}"
										></span>
									</label>
								</div>
							</td>
							<td
								class="expandable"
								@click.prevent="expandElement(sector, 'sector')"
							>
								<span class="circle">
									{{ getNumCheckedLeaves("sector", sector) }}
								</span>
								<span style="margin-left: 15px">
									<i
										v-if="checkSectorFoldState(sector)"
										class="fa fa-caret-up"
									></i>
									<i
										v-if="!checkSectorFoldState(sector)"
										class="fa fa-caret-down"
									></i>
								</span>
							</td>
							<td class="align-state fixed-height">
								<tri-state-switch
									v-show="ifAnyChecked('sector', sector)"
									:value="getPreferredValue('sector', sector)"
									color="accent"
									@click.stop.prevent=""
									@input="sectorPreferenceChanged(sector, $event)"
								/>
							</td>
							<td>&nbsp;</td>
						</tr>
						<template v-for="client in sectorClientsMapped(sector.clients)">
							<tr
								v-if="checkSectorFoldState(sector)"
								:key="client.id"
							>
								<td>
									<div class="ccp-name pad-left">
										<label
											class="ctrl ctrl--checkbox"
											@click.prevent="
												checkTriggered(
													'client',
													sector,
													client,
													ifAnyChecked('client', client),
												)
											"
										>
											{{ client.name }}
											<input
												class="ctrl--checkbox"
												type="checkbox"
												:checked="isAllChecked('client', client)"
											/>
											<span
												class="ctrl__status"
												:class="{
													'status--halfstate': ifAnyChecked('client', client),
												}"
											></span>
										</label>
									</div>
								</td>
								<td
									class="expandable"
									@click.prevent="expandElement(client, 'client')"
								>
									<span class="circle">
										{{ getNumCheckedLeaves("client", client) }}
									</span>
									<span style="margin-left: 15px">
										<i
											v-if="checkClientFoldState(client)"
											class="fa fa-caret-up"
										></i>
										<i
											v-if="!checkClientFoldState(client)"
											class="fa fa-caret-down"
										></i>
									</span>
								</td>
								<td class="align-state fixed-height">
									<tri-state-switch
										v-show="ifAnyChecked('client', client)"
										:value="getPreferredValue('client', client)"
										color="accent"
										@click.stop.prevent=""
										@input="clientPreferenceChanged(client, $event)"
									/>
								</td>
								<td>&nbsp;</td>
							</tr>
							<transition-group
								v-if="
									checkClientFoldState(client) && checkSectorFoldState(sector)
								"
								:key="client.id"
								name="fade-sh"
							>
								<tr
									v-for="location in filteredLocations(client.locations)"
									:key="location.id"
									class="location-group"
									:class="{ disabled: isLocationDisabled(location) }"
								>
									<td>
										<div class="ccp-name pad-left-2">
											<label class="ctrl ctrl--checkbox">
												{{ location.name }}
												<input
													v-model="location.checked"
													class="ctrl--checkbox"
													type="checkbox"
													:disabled="isLocationDisabled(location)"
													@change="invokeEmitChange($event, location)"
												/>
												<span
													class="ctrl__status"
													:class="{ 'status--halfstate': false }"
												></span>
											</label>
										</div>
									</td>
									<td></td>
									<td class="align-state fixed-height">
										<tri-state-switch
											v-show="location.checked"
											:value="getPreferredValue('location', location)"
											:disabled="isLocationDisabled(location)"
											color="accent"
											@click.stop.prevent=""
											@input="locationPreferenceChanged(location, $event)"
										/>
									</td>
									<td>
										<input
											v-if="shouldShowReason('location', location, client)"
											v-model="location.reason"
											class="input"
											type="text"
											@change="info(location)"
										/>
									</td>
								</tr>
							</transition-group>
						</template>
					</template>
				</tbody>
			</table>
		</div>
		<div
			v-if="!manageAction"
			class="buttons-centered"
		>
			<button
				class="button is-generic-app-blue is-caps-lock"
				type="submit"
				:disabled="isLoadingSave"
				@click.prevent="saveAndClose"
			>
				SAVE
			</button>
			<button
				class="button inverted-button is-caps-lock"
				@click.prevent="emit('close-modal')"
			>
				CANCEL
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$color-white: #fff;
$color-light-grey: #e3ebed;
$color-dark-blue: #405168;
$color-dark-grey: #7b7b7b;
$color-primary: #2669b0;
$color-secondary: #7c9ec9;
$color-generic-app-unchecked: #3f5273;
$color-cpl: #30bb26;

.cc-pref-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 80vh;
	padding: 10px 0;
	.table-wrap {
		overflow: auto;
	}
}

table.generic-app-cc-pref {
	tr {
		th {
			text-align: center;
			color: $label-accent-color;
			font-size: 13px;
			font-weight: 900;
			border-width: 0 0 1px;

			span.preferred {
				color: $color-cpl;
			}

			span.nonpreferred {
				color: $color-generic-app-unchecked;
			}
		}

		td {
			padding-left: 10px;
			border-bottom: 0;
		}

		.ccp-name {
			width: 250px;
		}

		.pad-left {
			padding-left: 25px;
		}

		.pad-left-2 {
			padding-left: 50px;
		}

		td.expandable {
			cursor: pointer;
			min-width: 70px;
			display: flex;
			align-items: center;
			margin: auto 0;
			padding: 10px;
		}

		td.fixed-height {
			height: 37px;
		}

		td.align-state {
			text-align: center;
		}
	}
}

.ctrl {
	display: inline-block;
	position: relative;
	padding-left: 20px;
	cursor: pointer;

	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
}

.ctrl__status {
	position: absolute;
	top: 5px;
	left: 0;
	height: 12px;
	width: 12px;
	background: $color-white;
	border: 1px solid $color-secondary;
	border-radius: 2px;

	.ctrl input:checked ~ & {
		background: $color-primary;
	}

	.ctrl input:disabled ~ & {
		background: $color-light-grey;
		opacity: 0.6;
		pointer-events: none;
	}

	&::after {
		content: "";
		position: absolute;
		display: none;

		.ctrl--checkbox & {
			left: 3px;
			top: 1px;
			width: 4px;
			height: 8px;
			border: solid $color-white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}

		.ctrl input:checked ~ & {
			display: block;
		}

		.ctrl--checkbox input:disabled ~ & {
			border-color: $color-dark-grey;
		}
	}
}

.status--halfstate {
	background: linear-gradient(
		to right bottom,
		$color-white 50%,
		$color-primary 50%
	);
}

.location-group {
	&.disabled {
		opacity: 0.7;
	}
}

.circle {
	border-radius: 50%;
	background: $color-light-grey;
	width: 24px;
	height: 24px;
	line-height: 24px;
	color: $color-dark-blue;
	text-align: center;
	font-weight: 500;
	display: inline-block;
}

.fade-sh-enter-active,
.fade-sh-leave-active {
	transition: all 0.3s ease;
}

.fade-sh-enter,
.fade-sh-leave-to {
	opacity: 0;
}

.modal {
	label {
		margin-top: 0;
		word-break: break-all;
	}
}
</style>
