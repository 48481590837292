import JSZip from "jszip";
class Zip {
	zip: Record<string, any>;

	constructor() {
		this.zip = new JSZip();
	}

	// Method to add a file to the zip
	file(fileName: string, content: any) {
		this.zip.file(fileName, content);
	}

	// Method to add a folder to the zip
	folder(folderName: string) {
		return this.zip.folder(folderName);
	}

	// Method to generate the zip file
	async generateAsync(type = "blob") {
		try {
			// if I don't separate and do a return ... commit  throws an error
			const x = await this.zip.generateAsync({ type });
			return x;
		} catch (error) {
			console.error("Error generating zip:", error);
			throw error;
		}
	}

	// Custom method to log the content of the zip (for debugging)
	listFiles() {
		return Object.keys(this.zip.files);
	}

	// Example of extending or modifying functionality
	async addFileWithTimestamp(fileName: string, content: any) {
		const timestamp = new Date().toISOString();
		const modifiedContent = `${content}\n\nCreated on: ${timestamp}`;
		this.file(fileName, modifiedContent);
	}

	async createZipFromFiles(
		filesArr: { name: string; blob: Blob }[] = [],
	): Promise<Blob | null> {
		if (filesArr.length === 0) {
			console.warn("No files to zip");
			return null;
		}

		for (const file of filesArr) {
			this.zip.file(file.name, file.blob);
		}

		if (
			Object.keys(this.zip.files).length === 0 &&
			this.zip.files.constructor === Object
		) {
			console.log("no files", this.zip.files);
			return null;
		}

		try {
			const content = await this.zip.generateAsync({ type: "blob" });
			return content;
		} catch (err: any) {
			console.warn(err.message);
		}
		return null;
	}
}

export default Zip;
