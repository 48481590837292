<script lang="ts" setup>
const props = defineProps<{
	modalProps: any;
}>();
const emit = defineEmits([
	"close-modal",
	"cancel-single-shift",
	"cancel-multiple-shifts",
]);
const currentMessage = ref<string>("");
const infoMessageBefore2 = ref<boolean>(false);

function setCurrentMessage() {
	if (
		props.modalProps.singleCancelMessages &&
		props.modalProps.singleCancelMessages.before_api_1
	) {
		currentMessage.value = props.modalProps.singleCancelMessages.before_api_1;
	}
}
function showInfoMessageBefore2() {
	infoMessageBefore2.value = true;
	currentMessage.value = props.modalProps.singleCancelMessages.before_api_2;
}
function cancelMultipleShifts() {
	emit("cancel-multiple-shifts", props.modalProps.shiftIds);
}
function cancelSingleShift() {
	emit("cancel-single-shift", props.modalProps);
}
function callModalClose() {
	emit("close-modal");
}
onMounted(() => {
	setCurrentMessage();
});
</script>
<template>
	<div class="wrapper">
		<div>
			<img
				src="@/assets/image/warning.svg"
				style="width: 110px; margin: 0 auto"
				alt=""
			/>
			<div
				class="warningTxt text-centered"
				style="font-size: 2em"
			>
				Warning
			</div>
		</div>
		<div
			v-if="modalProps.isMultiple"
			class="dialog-container-multiple"
		>
			<div class="message">
				<p>{{ modalProps.multipleCancelMessage }}</p>
			</div>
			<div class="buttons">
				<button
					class="button"
					@click="cancelMultipleShifts"
				>
					OK
				</button>
			</div>
		</div>
		<div
			v-if="!modalProps.isMultiple &amp;&amp; modalProps.singleCancelMessages &amp;&amp; modalProps.singleCancelMessages.hasOwnProperty('before_api_1')"
			class="dialog-container-single"
		>
			<div class="message">
				<p>{{ currentMessage }}</p>
			</div>
			<div class="buttons">
				<button
					v-if="modalProps.singleCancelMessages.hasOwnProperty('before_api_1') &amp;&amp; !infoMessageBefore2"
					class="alt button is-outlined is-caps-lock"
					@click="showInfoMessageBefore2"
				>
					No
				</button>
				<button
					v-if="!infoMessageBefore2"
					class="button is-generic-app-blue is-caps-lock"
					@click="cancelSingleShift"
				>
					Yes
				</button>
				<button
					v-if="infoMessageBefore2"
					class="button"
					@click="callModalClose"
				>
					OK
				</button>
			</div>
		</div>
		<div
			v-if="modalProps.successfullMessage"
			class="dialog-container-success"
		>
			<div class="message">
				<p>{{ modalProps.successfullMessage }}</p>
			</div>
			<div class="buttons">
				<button
					class="button is-generic-app-blue is-caps-lock"
					@click="callModalClose"
				>
					OK
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.dialog-container-multiple,
.dialog-container-single,
.dialog-container-success {
	text-align: center;
	padding: 20px;
	width: 500px;
	.message {
		margin: 10px 0 20px;
		p {
			background-color: $white;
		}
	}
}
.buttons button {
	cursor: pointer;
	margin: 0 5px 10px;
}
</style>
