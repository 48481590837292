<script setup lang="ts">
import api from "@/api";
import { validateEmail } from "@/assets/js/helpers";
import { useDefaultStore } from "@/store";

const store = useDefaultStore();

const email = ref<string>();
const router = useRouter();

const invalid_email = ref(false);
const show_general_error = ref(false);

function checkEmail() {
	if (!email.value) {
		invalid_email.value = false;
		return;
	}
	invalid_email.value = !validateEmail(email.value);
}

async function forgotPassword() {
	try {
		const res = await api.authForgot({ email: email.value });
		if (res) {
			router.push({ name: "admin.dashboard" });
			passwordReset.value = true;
		} else {
			show_general_error.value = true;
		}
	} catch {
		// nothing to do here, setupLogin doesnt crash
	}
}

watch(email, () => {
	if (show_general_error.value) {
		show_general_error.value = false;
	}
});

const passwordReset = ref(false);
</script>

<template>
	<div class="signin">
		<div class="signin-container">
			<header>
				<div class="image-wrap">
					<img
						:src="store.getGlobalImage()"
						alt=""
					/>
				</div>
				<p>
					Please enter your registered Email address. A recovery link will be
					sent to the email provided.
				</p>
			</header>
			<hr />
			<div>
				<section class="section-input">
					<div class="control">
						<span
							v-if="passwordReset"
							class="success"
						>
							Reset password link successfully sent.
						</span>
						<input
							v-model="email"
							class="large"
							type="email"
							name="email"
							placeholder="Email"
							autocomplete="off"
							@blur="checkEmail"
							@focus="invalid_email = false"
						/>
						<span
							v-if="invalid_email && !show_general_error"
							class="error"
						>
							Please enter a valid email address
						</span>
					</div>

					<div class="right">
						<router-link :to="{ name: 'auth.signin' }">
							Back to Login
						</router-link>
					</div>
					<button
						class="large"
						:disabled="passwordReset"
						@click="forgotPassword"
					>
						SEND
					</button>
				</section>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.signin {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60%;
}
.signin-container {
	$signin-color: #536278;

	box-shadow: 0 30px 33px 2px hsla(0deg, 0%, 67%, 0.3);
	background-color: $white;
	width: 310px;
	margin: 5em auto;
	padding: 12px 0 29px;
	border-radius: 5px;
	color: $signin-color;
	header {
		text-align: center;
		padding: 1rem;
	}
	.image-wrap {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 7px;
	}
	.section-input {
		padding: 24px 29px 0;
		button {
			font-size: 1rem;
		}
	}
	.large {
		width: 100%;
	}
	.right {
		text-align: right;
		color: $green;
		margin-bottom: 4px;
		&:hover {
			color: $black;
		}
	}
	.control {
		margin-bottom: 0.75rem;
		.success {
			display: block;
			margin-bottom: 5px;
			color: $success-color;
		}
	}
	.error {
		color: $red;
	}
}
</style>
