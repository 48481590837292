<script lang="ts" setup>
import Zip from "@/assets/js/zip";
import { useToastStore } from "@/store/toast.js";
import api from "@/api";
import { parseErrors } from "@/assets/js/parseErrors.js";
import dayjs from "dayjs";

const emit = defineEmits(["close-modal"]);
const props = defineProps<{
	id: number;
}>();

const toastStore = useToastStore();
const selectedJobId = ref(null);
const jszip = ref(new Zip());
const zipNameBase = ref("");
const fileList = ref([]);
const currentProgress = ref(0);
const failedToBackup = ref<{ id: number; client: string }[]>([]);
const isBackupComplete = ref(false);

async function getBackpayInvoices(id: number) {
	resetBackupState();
	fileList.value = [];
	const params = { id };
	try {
		const res = await api.massRecalculationGetBackpayInvoicesOnJobId(params);
		fileList.value = res.data.invoices;
		if (!fileList.value) {
			toastStore.openToastError("Something went wrong. Please try again.");
			return;
		}
		if (fileList.value.length === 0) {
			toastStore.openToastError("There are no data.");
			return;
		}
		selectedJobId.value = id;
		// isModalVisible.value = true;
		await callBackup(fileList.value, 0);
	} catch (err) {
		console.log(err);
		const errs = parseErrors(err);
		toastStore.openToastError(errs);
	}
}

async function callBackup(fileList, indexFunc) {
	const i = indexFunc;
	zipNameBase.value = "backpay_client_excel";
	if (fileList.length === i) {
		finishBackup();
		return;
	}
	const id = fileList[i].id;
	const client = fileList[i].clientName;
	try {
		const params = {
			job_id: selectedJobId.value,
			invoice_id: id,
		};
		const res = await api.massRecalculationGetBackpayInvoiceFile(params);
		const blob = new Blob([res.data], { type: "application/csv" });
		const fileTitle = res.headers["x-multi-file-name"];

		jszip.value.file(fileTitle, blob);
		currentProgress.value = ((i + 1) * 100) / fileList.length;
		await callBackup(fileList, i + 1);
	} catch (error) {
		console.log(error);
		failedToBackup.value.push({ id, client });
		await callBackup(fileList, i + 1);
	}
}
async function finishBackup() {
	isBackupComplete.value = true;
	// if (!isModalVisible.value) {
	// 	resetBackupState();
	// 	return;
	// }
	await downloadZip();
	if (failedToBackup.value.length === 0) {
		// closeModalZip();
		emit("close-modal");
	}
}

async function downloadZip() {
	currentProgress.value = 100;

	// if (Object.keys(jszip.value.files).length === 0) {
	// 	console.log("no files", jszip.value.files);
	// 	toastStore.openToastError("No files");
	// 	return;
	// }
	try {
		const content = await jszip.value.generateAsync("blob");
		const blob = new Blob([content]);
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		document.body.append(a);
		a.href = url;
		const currDate = dayjs().format("DD-MM-YYYY");
		a.download = `${zipNameBase.value} - ${currDate}.zip`;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	} catch (err) {
		console.log("Downloading Zip Failed:", err.message);
	}
}

function resetBackupState() {
	jszip.value = new Zip();
	currentProgress.value = 0;
	isBackupComplete.value = false;
	failedToBackup.value = [];
}

onMounted(() => {
	getBackpayInvoices(props.id);
});
</script>
<template>
	<div class="modal-zip">
		<progress-bar :currentProgress="currentProgress"></progress-bar>
		<div
			v-if="isBackupComplete && failedToBackup.length > 0"
			class="backup-fail"
		>
			<!--  -->
			<p>Backpay Client Excel failed for the following invoices:</p>
			<table class="table">
				<thead>
					<tr>
						<th>#</th>
						<th>Invoice ID</th>
						<th>Client</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(invoice, index) in failedToBackup"
						:key="index"
					>
						<td>{{ index + 1 }}</td>
						<td>{{ invoice.id }}</td>
						<td>{{ invoice.client }}</td>
					</tr>
				</tbody>
			</table>
			<button
				class="btn is-danger"
				@click="emit('close-modal')"
			>
				Close
			</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.modal-zip {
	box-sizing: border-box;
	padding: 0 20px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 460px;
	// width: fit-content;
	min-height: 180px;
	gap: 10px;
	color: $text-color;
	// justify-content: center;

	$td-text-color: $blue-grey;
	$text-color-lighter: $label-color;
	$table-header-background: #f2f7fa;
	.backup-fail {
		margin: 10px auto;
		width: 90%;
		text-align: center;
	}
	.btn {
		margin: auto;
	}
	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #dde6eb;
		margin-bottom: 30px;
		td {
			font-size: 0.9rem;
			padding: 5px 10px;
			// border-top: 1px solid #dde6eb;
			border: 1px solid #dde6eb;
			color: $td-text-color;
		}
		thead {
			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}
		}
		tbody {
			tr {
				height: 30px;
			}
		}
	}
}
</style>
