// Updated 24-02
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import customInputFormats from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/sr";
import duration from "dayjs/plugin/duration";

// Format specs syntax - https://day.js.org/docs/en/display/format
/**
 * YYYY / YY - year | MM / M - month | DD / D - day | HH / H - hour | mm / m - minute | ss / s - second
 */

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customInputFormats);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
// dayjs.locale("sr");
dayjs.extend(duration);

export class DateInstance {
	dateInstance: dayjs.Dayjs;
	defaultOutputFormat = "DD/MM/YYYY";
	// defaultOutputFormat = "DD. MMM YYYY.";

	constructor(dateString = "", inFormat = "", isUtc = true) {
		if (inFormat) {
			const dateLogic = isUtc
				? dayjs.utc(dateString, inFormat)
				: dayjs(dateString, inFormat);
			const todayDateLogic = isUtc ? dayjs.utc() : dayjs();
			this.dateInstance = dateString ? dateLogic : todayDateLogic;
		} else {
			const dateLogic = isUtc ? dayjs.utc(dateString) : dayjs(dateString);
			const todayDateLogic = isUtc ? dayjs.utc() : dayjs();
			this.dateInstance = dateString ? dateLogic : todayDateLogic;
		}
	}

	formatDate(outFormat = this.defaultOutputFormat) {
		return getFormattedDate(this.dateInstance, outFormat);
	}

	formatIsoDate(timeFormat = "") {
		return getFormattedIsoDate(this.dateInstance, timeFormat);
	}

	changeGlobalLocale(locStr = ""): string {
		return dayjs.locale(locStr);
	}

	changeLocale(locStr = ""): dayjs.Dayjs {
		return dayjs(this.dateInstance).locale(locStr);
	}

	changeOutputFormat(outFormat = "") {
		if (outFormat) {
			this.defaultOutputFormat = outFormat;
			return this;
		}
		console.error("Output format needed");
	}

	isBefore(date: string | dayjs.Dayjs, isUtc = true) {
		if (typeof date === "string") {
			date = getDateInstance(date, isUtc);
		}
		return this.dateInstance.isBefore(date);
	}

	isAfter(date: string | dayjs.Dayjs, isUtc = true) {
		if (typeof date === "string") {
			date = getDateInstance(date, isUtc);
		}
		return this.dateInstance.isAfter(date);
	}

	static getFormattedDate(date = "", outFormat = "l", isUtc = true) {
		const dateObj = getDateInstance(date, isUtc);
		return getFormattedDate(dateObj, outFormat);
	}

	static getFormattedIsoDate(date = "", timeFormat = "", isUtc = true) {
		const dateObj = getDateInstance(date, isUtc);
		return getFormattedIsoDate(dateObj, timeFormat);
	}

	static getDateInstance(date = "", isUtc = true) {
		return getDateInstance(date, isUtc);
	}

	static getDateLocaleData(date = "", isUtc = true) {
		const dateObj = getDateInstance(date, isUtc);
		return getDateLocaleData(dateObj);
	}

	static getRelativeTimeFromNow(date = "", isUtc = true) {
		return getRelativeTimeFromNow(date, isUtc);
	}

	static isBefore(date = "") {
		return getDateInstance("").isBefore(getDateInstance(date));
	}

	/**
	 * Gets date difference from date2. If date2 is not provided, it will use current time
	 *
	 * @param date1 - The first date
	 * @param date2 - The second date, leave blank for today
	 * @param unit - The unit to be used for comparison, default is "day"
	 * @param isUtc - UTC date format, default is true
	 * @returns The date difference in the unit provided
	 *
	 */
	static getDateDiff(
		date1 = "",
		date2 = "",
		unit: dayjs.UnitType = "day",
		isUtc = true,
	) {
		if (date2) return getDateDiff(date1, date2, unit, isUtc);
		return getDateDiffToday(date1, unit, isUtc);
	}

	static isDateToday(date = "", unit: dayjs.UnitType = "day", isUtc = true) {
		return isDateToday(date, unit, isUtc);
	}

	static getFormatHasTime(format = "") {
		return getFormatHasTime(format);
	}
}

/**
 * Formats date with default output format specified in date instance
 */
export function dff(val: string): string {
	if (!val) return val;
	return new DateInstance(val).formatDate();
}

function getDateInstance(date = "", isUtc = true) {
	// Get date from string / today
	const dateLogic = isUtc ? dayjs.utc(date) : dayjs(date);
	const todayDateLogic = isUtc ? dayjs.utc() : dayjs();
	return date ? dateLogic : todayDateLogic;
}

function getFormattedDate(dateInstance: dayjs.Dayjs, outFormat = "l") {
	return dateInstance.format(outFormat);
}

function getFormattedIsoDate(dateInstance: dayjs.Dayjs, timeFormat = "") {
	return timeFormat
		? dateInstance.format(`YYYY-MM-DD ${timeFormat}`)
		: dateInstance.format("YYYY-MM-DD");
}

function getDateLocaleData(dateInstance: dayjs.Dayjs) {
	// https://day.js.org/docs/en/plugin/locale-data
	return dateInstance?.localeData();
}

function getRelativeTimeFromNow(date = "", isUtc = true) {
	return isUtc ? dayjs.utc(date).fromNow() : dayjs(date).fromNow();
}

function getDateDiffToday(
	date = "",
	unit: dayjs.UnitType = "day",
	isUtc = true,
) {
	if (isUtc) {
		const formattedLocal = dayjs.utc(date).local();
		return dayjs().diff(formattedLocal, unit);
	} else {
		const formattedLocal = dayjs(date).local();
		return dayjs().diff(formattedLocal, unit);
	}
}

function getDateDiff(
	date1 = "",
	date2 = "",
	unit: dayjs.UnitType = "day",
	isUtc = true,
) {
	// Dates must be same format
	return dayjs(date1).diff(date2, unit);
}

function isDateToday(date = "", unit: dayjs.UnitType = "day", isUtc = true) {
	return getDateDiffToday(date, unit, isUtc) < 1;
}

function getFormatHasTime(format: string) {
	const timeFormats = ["H", "h", "m", "s"];
	return timeFormats.some((tf) => format.includes(tf));
}

/**
* CPL FUNCTIONS

*/
const pad = (value) => (value < 10 ? `0${value}` : value);

/**
 * momentDiff Calculates time difference between given time boundaries
 *
 * @param startTime $startTime
 * @param endTime $endTime
 * @param default format='YYYY-MM-DD HH:mm', expected time format at input
 * @access public
 * @return void
 */

// tested
export function momentDiff(
	startTime: string,
	endTime: string,
	format = "YYYY-MM-DD HH:mm",
) {
	const ms = dayjs(endTime, format).diff(dayjs(startTime, format));
	const diff = dayjs.duration(ms);
	const hours = pad(Math.floor(diff.asHours()));
	const hMin = diff.asMinutes() - 60 * hours;
	let minutes = typeof hMin === "number" ? hMin : Number.parseInt(hMin);

	if (Number.isNaN(hours) || Number.isNaN(minutes)) return null;

	minutes = pad(minutes);
	return `${hours}h:${minutes}m`;
}

// tested
export function momentTimeFromApi(
	time: string,
	format = "YYYY-MM-DD HH:mm:ss",
) {
	const d = dayjs(time, format);
	return d.isValid() ? d.format("HH:mm") : null;
}
export function momentDateTimeFromApi(
	datetime,
	inputFormat = "YYYY-MM-DD HH:mm:ss",
	isIncludeSeconds = false,
) {
	const m = dayjs(datetime, inputFormat);
	let outputFormat = "DD/MM/YYYY HH:mm";
	if (isIncludeSeconds) {
		// outputFormat = "DD/MM/YYYY HH:mm:SS";
		outputFormat = "DD/MM/YYYY HH:mm:ss";
	}
	return m.isValid() ? m.format(outputFormat) : null;
}

// tested
export function parseDateObjWithTimeZone(inputObj: any) {
	// WIP [BE] Add timezones when BE support it
	// Example
	// { "date": "2017-12-03 20:00:00", "timezone_type": 3, "timezone": "Europe/London" }
	if (inputObj && typeof inputObj === "object") {
		return inputObj.date;
	} else if (inputObj) {
		return inputObj;
	}
	return "";
}

// tested
export function parseObjWithStartEnd(inputObj: any) {
	type TOutput = {
		startTime?: any;
		endTime?: any;
	};
	const output: TOutput = {};
	if (inputObj.startTime) {
		output.startTime = parseDateObjWithTimeZone(inputObj.startTime);
	}
	if (inputObj.endTime) {
		output.endTime = parseDateObjWithTimeZone(inputObj.endTime);
	}
	return output;
}
