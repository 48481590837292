<script setup lang="ts">
// import { parseErrors } from "../../lib/helpers/function.js";
import { useToastStore } from "@/store/toast";
import api from "@/api";
import { parseErrors } from "@/assets/js/parseErrors.js";

const props = defineProps<{ id: any }>();
const emit = defineEmits(["close-modal"]);

const toastStore = useToastStore();

// Data properties
// const breakList = ref([]);
const categoriesOptions = ref([]);
const selectedCategoryOption = ref(null);
const isAnyInputChanged = ref(false);
const intervalData = ref([]);
const isLoadingCats = ref(false);
const tempForm = ref({
	id: null as number | null,
	actionBased: false,
	interval: null as number | null,
});

const isDeleteDialogActive = ref(false);

// Computed properties
// const breakListSorted = computed(() =>
// 	[...breakList.value].sort((a, b) =>
// 		a.from > b.from ? 1 : a.from < b.from ? -1 : 0,
// 	),
// );

// const getBiggestFromObj = computed(() => {
// 	if (Array.isArray(breakList.value) && breakList.value.length) {
// 		return breakList.value.reduce((acc, curr) =>
// 			curr.shift_length_to > acc.shift_length_to ? curr : acc,
// 		);
// 	}
// 	return {};
// });

const clientId = computed(() => props.id);
const categoryId = computed(() => selectedCategoryOption.value?.id);

const selectedInterval = computed(() => {
	return intervalData.value.length && selectedCategoryOption.value?.id
		? intervalData.value.find(
				(item) => item.category_id === selectedCategoryOption.value.id,
			)
		: null;
});

const shouldCreateInterval = computed(() => !selectedInterval.value);

// Watchers
watch(
	selectedInterval,
	(val) => {
		if (val) {
			tempForm.value.id = val.id;
			tempForm.value.actionBased = val.action_based;
			tempForm.value.interval = val.interval;
		} else {
			tempForm.value.id = null;
			tempForm.value.actionBased = false;
			tempForm.value.interval = null;
		}
	},
	{ deep: true },
);

// Lifecycle hook
onMounted(() => {
	fetchCategories();
	fetchIntervals();
});

// Methods
const onChangeCategory = (evt: any) => {
	selectedCategoryOption.value = evt;
};

const onClose = () => {
	emit("close-modal");
};

const onRemoveInterval = async () => {
	isDeleteDialogActive.value = false;
	try {
		tempForm.value.interval = null;
		isAnyInputChanged.value = true;
		const params = { id: tempForm.value.id, client_id: clientId.value };
		await api.deleteClientNotifInterval(params);
		toastStore.openToastSuccess("Success");
		fetchIntervals();
	} catch (err) {
		toastStore.openToastError(parseErrors(err));
	}
};

const onClickUpdate = async () => {
	const isSuccess = shouldCreateInterval.value
		? await createInterval(true)
		: await updateInterval(true);
	if (isSuccess) {
		fetchIntervals();
	}
};

const createInterval = async (isSave = true) => {
	if (!categoryId.value || !clientId.value) {
		console.error("No client / category", clientId.value, categoryId.value);
		return;
	}

	const params = {
		category_id: categoryId.value,
		client_id: clientId.value,
		interval: tempForm.value.interval || 0,
		action_based: tempForm.value.actionBased,
	};

	try {
		await api.postClientNotifInterval(params);
		toastStore.openToastSuccess("Successfully created");
		isAnyInputChanged.value = false;
		if (isSave) onClose();
		return true;
	} catch (err) {
		// console.log(typeof parseErrors(err));
		const errors = parseErrors(err);
		toastStore.openToastError(typeof errors === "object" ? errors[0] : errors);
		return false;
	}
};

const updateInterval = async (isSave = true) => {
	const params = {
		id: tempForm.value.id,
		client_id: clientId.value,
		action_based: tempForm.value.actionBased,
		interval: tempForm.value.interval || 0,
	};

	try {
		await api.putClientNotifInterval(params);
		toastStore.openToastSuccess("Successfully changed");
		isAnyInputChanged.value = false;
		if (isSave) onClose();
		return true;
	} catch (err) {
		const errors = parseErrors(err);
		toastStore.openToastError(typeof errors === "object" ? errors[0] : errors);
		return false;
	}
};

const fetchCategories = async () => {
	isLoadingCats.value = true;
	const params = { client_id: clientId.value };
	try {
		const res = await api.getClientCategories(params);
		categoriesOptions.value = res.data.data;
	} catch (err) {
		console.error("Error", err.message);
	} finally {
		isLoadingCats.value = false;
	}
};

const fetchIntervals = async () => {
	const params = { client_id: clientId.value };
	try {
		const res = await api.getClientNotifInterval(params);
		intervalData.value = res.data.data;
	} catch (err) {
		console.warn(err.message);
	}
};

const confirmAlert = () => {
	console.log("see this.. Alert");
	isDeleteDialogActive.value = true;
};

const onClickActionBased = (evt: Event) => {
	isAnyInputChanged.value = true;
};
</script>

<template>
	<div class="shift-confirm-interval-form">
		<div
			v-if="isLoadingCats"
			class="loading-wrapper"
			style="text-align: center"
		>
			<img src="@/assets/image/loader.svg" />
		</div>
		<div
			v-else-if="!categoriesOptions.length"
			class="no-results"
		>
			<span>No categories found</span>
		</div>
		<template v-else>
			<div class="form-content">
				<p class="control">
					<label class="label">Category</label>
					<multiselect-form
						v-model="selectedCategoryOption"
						class="category-select"
						:options="categoriesOptions"
						value-prop="id"
						label="name"
						:multiple="false"
						placeholder="FILTER CATEGORY"
						@select="onChangeCategory"
					/>
				</p>
				<template v-if="selectedCategoryOption">
					<div class="rows">
						<div
							class="row"
							style="margin: auto"
						>
							<div class="group-input">
								<label title="Select to change to action based intervals">
									Action based
								</label>
								<switch-component
									v-model="tempForm.actionBased"
									style="margin: 19px 0 10px"
									theme="bulma"
									color="blue"
									@click="onClickActionBased"
								/>
							</div>
							<template v-if="!tempForm.actionBased">
								<div class="group-input">
									<label class="label">Interval (minutes)</label>
									<input
										v-model.number="tempForm.interval"
										class="input"
										type="number"
										min="1"
										:disabled="tempForm.actionBased"
										@input="isAnyInputChanged = true"
										@keyup.enter="onClickUpdate"
									/>
								</div>
							</template>
							<div class="remove-wrap">
								<img
									v-if="tempForm.id"
									src="../../../../assets/image/icons/remove.svg"
									title="Clear interval"
									@click="confirmAlert"
								/>
							</div>
						</div>
						<transition name="fade">
							<div
								v-if="isDeleteDialogActive"
								class="delete-section"
							>
								<span class="action-message">
									<span>Are you sure you want to delete this interval?</span>
								</span>
								<span class="action-buttons">
									<a
										class="is-confirm"
										data-cell="yes-button"
										@click="onRemoveInterval"
									>
										YES
									</a>
									<a
										class="is-deny"
										data-cell="no-button"
										@click="isDeleteDialogActive = false"
									>
										NO
									</a>
								</span>
							</div>
						</transition>
						<div class="row">
							<span>
								If neither of the two options is set, then the shift
								confirmation engine feature is not ON for this category for this
								client.
							</span>
						</div>
					</div>
				</template>
			</div>
		</template>
		<div class="action buttons-centered">
			<button
				v-if="categoriesOptions.length"
				class="button is-generic-app-blue is-caps-lock save"
				:disabled="!isAnyInputChanged"
				@click="onClickUpdate"
			>
				<span>Update</span>
			</button>
			<button
				class="button is-caps-lock cancel"
				@click="onClose"
			>
				<span>Close</span>
			</button>
		</div>
		<!-- <simplert
    :useRadius="true" :useIcon="true" ref="simplert"></simplert> -->
	</div>
</template>

<style lang="scss" scoped>
.shift-confirm-interval-form {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 500px;
	min-height: 260px;

	.form-content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		// max-height: 500px;
		// overflow: auto;

		.rows {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.row {
				display: flex;
				gap: 10px;
				align-items: flex-end;

				.group-input {
					display: flex;
					flex-direction: column;
					height: 82px;
					justify-content: space-between;
				}

				.info {
					margin: 0 20px 5px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.remove-wrap {
					align-self: flex-end;
					margin: 0 0 10px 10px;

					img {
						cursor: pointer;
					}
				}

				input {
					width: 100px;

					&.error {
						background: #ffedf0;
						border: 1px solid crimson;
					}
				}
			}
		}

		button {
			align-self: flex-start;
		}
	}

	.action {
		margin-top: auto;
		display: flex;
		gap: 10px;
		justify-content: center;
	}
}
</style>
