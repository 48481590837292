<script setup lang="ts">
import { useClientManagementStore } from "@/store/locationClientManagement";

// Props
const props = defineProps<{
	client: any;
}>();

// Emit events
const emit = defineEmits(["close-modal", "confirm"]);

// Vuex store actions
const clientStore = useClientManagementStore();
// const putClientStatus = store.dispatch(
// 	"locationClientManagement/putClientStatus",
// );
const putClientStatus = clientStore.putClientStatus;

// Data
const isLoading = ref(false);
const activeStatus = ref<number | null>(null);
const lastStatusSelected = ref<number | null>(null);
const isDeleteDialogActive = ref(false);
const statusElements = ref<Record<string, any>>([
	{
		id: 1,
		value: true,
		label: "Active",
		backColor: "#23d160",
	},
	{
		id: 3,
		value: false,
		label: "Inactive",
		backColor: "#ff2b56",
	},
]);

// Computed properties
const filteredStatuses = computed(() => {
	return statusElements.value.filter(() => true);
	// Adjust logic if filtering is needed
	// e.g., return statusElements.value.filter(status => status.id !== currentStatus);
});

const spinner = (button: number) => {
	return { "is-loading": isLoading.value && button === activeStatus.value };
};

// Methods
function callActionButton(statusId: number) {
	lastStatusSelected.value = statusId;
	isDeleteDialogActive.value = true;
}

function statusPreLogic(statusId?: number) {
	isDeleteDialogActive.value = false;

	const id = statusId || lastStatusSelected.value;
	if (!id) {
		console.error("Invalid ID", statusId, lastStatusSelected.value);
		return;
	}

	const foundObj = statusElements.value.find((el) => el.id === id);
	// NOTICE
	// I don't know where the action came from, but that's how it is in the old code
	// change
	if (foundObj && foundObj.action) {
		foundObj.action();
	} else {
		updateStatus(foundObj?.value ?? false);
	}
}

async function updateStatus(statusId: number) {
	isLoading.value = true;
	activeStatus.value = statusId ? 1 : 3;

	const apiParams = {
		clientId: props.client.id,
		params: {
			active: statusId,
		},
	};

	try {
		await putClientStatus(apiParams);
		emit("confirm");
		callModalClose();
		isLoading.value = false;
	} catch (err) {
		console.error(err.message);
	}
}

function callModalClose() {
	emit("close-modal");
}

function getButtonStyle(status: { backColor: string; color?: string }) {
	return {
		color: status.color || "white",
		backgroundColor: status.backColor,
	};
}
</script>

<template>
	<div
		class="dialog-container"
		@click.stop=""
	>
		<div class="columns spacer-align">
			<div
				v-for="statusEl in filteredStatuses"
				:key="statusEl.id"
				class="column"
			>
				<button
					class="button fullwidth"
					:disabled="isLoading"
					:style="getButtonStyle(statusEl)"
					:class="spinner(statusEl.id)"
					type="submit"
					@click.prevent="callActionButton(statusEl.id)"
				>
					{{ statusEl.label }}
				</button>
			</div>
		</div>
		<transition name="fade">
			<div
				v-if="isDeleteDialogActive"
				class="delete-section"
			>
				<span class="action-message">
					<span>Are you sure you want to change the client's status</span>
					<span
						v-if="props.client && props.client.name"
						class="client-name"
						:title="props.client.name"
					>
						{{ props.client.name }}
					</span>
					<span>?</span>
				</span>
				<span class="action-buttons">
					<a
						class="button is-small is-confirm"
						@click="statusPreLogic()"
					>
						YES
					</a>
					<a
						class="button is-small is-deny"
						@click="
							lastStatusSelected = null;
							isDeleteDialogActive = false;
						"
					>
						NO
					</a>
				</span>
			</div>
		</transition>
		<div class="buttons-centered">
			<button
				class="button is-small is-outlined caps-lock"
				data-cancel="data-cancel"
				@click.prevent="emit('close-modal')"
			>
				Cancel
			</button>
		</div>
	</div>
</template>

<style lang="scss">
.dialog-container {
	box-sizing: border-box;
	padding: 0 20px 20px;
	width: 564px;

	.columns {
		flex-direction: column;
	}

	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}
}
.fullwidth {
	width: 100%;
	text-transform: uppercase;
}

.inputs {
	display: flex;
	justify-content: center;

	.field {
		width: 100%;
	}
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}

label {
	margin-top: 0.5em;
}

.spacer-align {
	margin: 20px 0;
}

div.delete-section {
	display: flex;
	flex-direction: column;
	border-top: 1px solid rgba($blue-grey, 0.15);
	border-bottom: 1px solid rgba($blue-grey, 0.15);
	align-items: center;
	justify-content: center;

	.action-message {
		display: flex;
		gap: 5px;
		margin-right: 1em;
		color: $blue-grey;

		.client-name {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			max-width: 180px;
		}
	}

	.action-buttons {
		a.button {
			border: none;
			margin: 0 0.3em;
		}
	}

	.is-confirm,
	.is-deny {
		font-weight: 900;
		font-size: 1.1em;
		cursor: pointer;
	}

	.is-confirm {
		color: $red-cpl; // #ff3b3b;
		margin-right: 20px;
	}

	.is-deny {
		color: $blue-cpl;
	}
}

.fade-enter-to,
.fade-leave {
	max-height: 500px;
}

.fade-enter,
.fade-leave-to {
	max-height: 0;
}
</style>
