<script setup lang="ts">
import api from "@/api";
import type { TYear } from "@/assets/js/globalTypes";
import { isValidNumberInput, removeSubstring } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

const props = defineProps<{
	flatYears: number[];
	years: TYear[] | null | [];
	warnMsg: string;
}>();
const toastStore = useToastStore();
const emit = defineEmits(["cancel-modal", "ph-updated", "close-modal"]);
const phParams = ref<{
	from: string;
	to: string;
}>({
	from: "",
	to: "",
});
const selectedYear = ref<TYear>(null);
const submitClicked = ref<boolean>(false);
const errors = ref<string | string[] | null>(null);
async function onSubmit() {
	submitClicked.value = true;
	try {
		const res = await api.copyHolidays(phParams.value);
		emit("ph-updated", phParams.value.to);
		toastStore.openToastSuccess(res.data.message);
		isModalOpen.value = false;
		initialModalInModalOpen.value = false;
		setTimeout(() => {
			submitClicked.value = false;
			closeModal();
		}, 300);
	} catch (err) {
		errors.value = parseErrors(err);
		if (Array.isArray(errors.value) && errors.value.length > 0) {
			for (const [index, err] of errors.value.entries()) {
				errors.value[index] = removeSubstring(err, "hld ");
				toastStore.openToastError(errors.value[index]);
			}
		} else if (errors.value) {
			toastStore.openToastError(
				removeSubstring(errors.value as string, "hld "),
			);
		}
		isModalOpen.value = false;
		initialModalInModalOpen.value = false;
		submitClicked.value = false;
	}
}
function closeModal() {
	emit("close-modal");
}
const createButtonDisabled = computed(() => {
	return (
		submitClicked.value ||
		phParams.value.from.length === 0 ||
		phParams.value.to.length === 0 ||
		copyToYearIsInvalid.value ||
		yearAlreadyExists.value
	);
});
function confirmCreate() {
	isModalOpen.value = true;
}
const isModalOpen = ref<boolean>(false);
const initialModalInModalOpen = ref<boolean>(false);
const currentYear = new Date().getFullYear();
const copyToYearIsInvalid = computed(() => {
	return !isValidNumberInput(phParams.value.to, currentYear);
});
const yearAlreadyExists = computed(() =>
	phParams.value.to
		? props.flatYears.includes(parseInt(phParams.value.to))
		: false,
);
watch(yearAlreadyExists, (newVal) => {
	if (newVal) errors.value = "Selected Year already exists.";
});
watch(selectedYear, (newVal) => {
	if (!newVal) initialErrorTriggers.value.to = false;
});
watch(
	phParams,
	(newVal) => {
		if (parseInt(newVal.to) > 0) {
			initialErrorTriggers.value.to = true;
		}
	},
	{ deep: true },
);
function onChangeYearTo() {
	if (!yearAlreadyExists.value) errors.value = null;
}
const initialErrorTriggers = ref<{
	from: boolean;
	to: boolean;
}>({
	from: false,
	to: false,
});
</script>

<template>
	<div class="rates-change-value-modal">
		<modal-in-modal
			:isModalOpen="isModalOpen"
			:initialModalInModalOpen="initialModalInModalOpen"
			:confirmMessage="
				warnMsg ?? 'Changing holidays may affect shift calculations.'
			"
			@confirm-action="onSubmit"
			@close-modal="isModalOpen = false"
			@initial-model-open="initialModalInModalOpen = isModalOpen"
		/>
		<div class="inner-content">
			<ul>
				<li>
					Please select the year from which you wish to copy the list of public
					holiday dates. Then, enter the year which you wish to copy the public
					holidays to. This will create the new public holidays in the system,
					which can be edited if needed. You cannot copy public holidays to a
					past year.
				</li>
			</ul>
		</div>

		<div class="flex-center mgt-10">
			<div>
				<label class="label pdl-5 label-color">Copy from year</label>
				<multiselect-form
					v-model="selectedYear"
					:options="years"
					value-prop="id"
					track-by="id"
					label="label"
					placeholder="Select Year"
					:multiple="false"
					class="wd-130"
					:can-deselect="false"
					:class="{
						'input-err-bc': !selectedYear && initialErrorTriggers.from,
					}"
					@select="phParams.from = parseInt(selectedYear.label)"
				></multiselect-form>
			</div>
			<div class="mgl-5">
				<label class="label label-color">New Year</label>
				<div>
					<input
						v-model="phParams.to"
						type="number"
						placeholder="New Year"
						:min="currentYear"
						:class="{
							'err-b err-bc':
								(copyToYearIsInvalid || yearAlreadyExists) &&
								initialErrorTriggers.to,
						}"
						class="wd-130"
						style="font-size: 0.88em"
						@input="onChangeYearTo"
						@focus="errors = null"
					/>
				</div>
			</div>
		</div>
		<div class="flex-center">
			<transition name="slide-fade">
				<div v-if="errors">
					<div v-if="Array.isArray(errors)">
						<p
							v-for="(msg, idx) in errors"
							:key="idx"
							class="error-text"
						>
							{{ msg }}
							<br />
						</p>
					</div>
					<p
						v-else-if="typeof errors === 'string'"
						class="error-text"
					>
						{{ errors }}
					</p>
				</div>
			</transition>
		</div>
		<div class="buttons-centered mgb-20">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				type="submit"
				:disabled="createButtonDisabled"
				:class="{
					'is-loading': submitClicked,
					'not-allowed': createButtonDisabled,
				}"
				@click.prevent="confirmCreate"
			>
				Create
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.rates-change-value-modal {
	max-height: 500px;
	width: 620px;
	display: flex;
	flex-direction: column;
	overflow: visible;
	padding: 5px 10px;

	ul {
		padding: 10px;
		color: $label-color;
	}

	li {
		margin: 5px 0; /* Add spacing between list items */
	}

	.caret {
		transition: transform 0.3s ease;
	}

	.caret-up {
		transform: rotate(180deg);
	}

	.caret-up-rotated {
		transform: rotate(0deg);
	}
	.inner-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 10px;

		.action {
			display: flex;
			overflow: auto;
			max-height: 350px;
			gap: 10px;

			.checkbox-complete {
				overflow: auto;
				padding-right: 10px;
			}

			.cats {
				display: flex;
				flex-direction: column;
				gap: 5px;
				flex: 1;
			}

			.input-section {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}
}
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateY(20px);
	opacity: 0;
}
</style>
