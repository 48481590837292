import { defineStore } from "pinia";
import api from "@/api";

export const useUsersStore = defineStore("users", () => {
	// set data
	const users = ref<any[]>([]);
	const pagination = ref<any>();

	// set functions
	async function createUser(payload: any): Promise<any> {
		try {
			const res = await api.createUser(payload.data);
			await fetchUsers({ include: payload.query });
			return res;
		} catch (e) {
			console.log(e.response);
			if (e.response && e.response.data) {
				// Log the full response for debugging
				console.log("Error response:", e.response);

				throw new Error(e.response.data.message);
			}
		}
		// return api
		// 	.createUser(payload.data)
		// 	.then((response) => {
		// 		 //Removed for now, because pagination -> Old comment
		// 		 //commit('CREATE_USER', response)
		// 		 return dispatch("fetchUsers", { include: payload.query });
		// 	})
		// 	.catch((error) => {
		// 		// return Promise.reject(error.response.data);
		// 		throw new Error(error.response.data);
		// 	});
	}
	async function fetchUsers(query: any = "") {
		try {
			const res = await api.fetchUsers(query);
			// commit("FETCH_USERS", response.data);
			users.value = res.data?.data;
			pagination.value = res.data?.meta?.pagination;
			return res;
		} catch (e) {
			console.log("FetchUsers ACTION ::", e);
		}
	}
	async function getFetchUsers({ query, cancelTokenSource }) {
		return api.getUsersCancellable(query, cancelTokenSource);
	}
	async function fetchUsersCancellable(params) {
		try {
			const res = await api.fetchUsersCancellable(params);
			users.value = res.data?.data;
			pagination.value = res.data?.meta?.pagination;
			return res.data;
		} catch (e) {
			console.log(e);
		}
	}
	async function getUsers(payload: any) {
		return api.getUsers(payload);
	}
	async function patchUsers(payload) {
		const response = await api.patchUsers(
			payload.id,
			payload.data,
			payload.query,
		);
		if (payload.__isSlice) {
			// commit("REMOVE_USER_FROM_LIST", payload.id);
			REMOVE_USER_FROM_LIST(payload.id);
		} else {
			// commit("PATCH_USER", response);
			PATCH_USER(response);
		}
		return response;
	}

	function REMOVE_USER_FROM_LIST(id) {
		if (users.value && Array.isArray(users.value) && users.value.length) {
			const userIndex = users.value.findIndex((user) => user.id === id);
			if (userIndex !== -1) {
				users.value.splice(userIndex, 1);
			}
		}
	}

	function PATCH_USER(payload) {
		const apiObj = payload.data.data;
		if (Array.isArray(users.value) && users.value?.length) {
			const index = users.value.findIndex((user) => user.id === apiObj.id);
			console.log("PATCHING INDEX::", index);
			for (const [key, val] of Object.entries(apiObj)) {
				users.value[index][key] = val;
			}
		}
	}

	async function deleteUser(payload) {
		console.log(payload);
		try {
			await api.deleteUser(payload.id);
			DELETE_USER(payload);
			return;
		} catch (e) {
			console.log(e);
			// return Promise.reject(e.response.data.errors);
			throw new Error(e.response.data.errors);
		}

		// return api
		//   .deleteUser(payload.id)
		//   .then((response) => {
		//     commit("DELETE_USER", payload);
		//     return Promise.resolve();
		//   })
		//   .catch((error) => {
		//     return Promise.reject(error.response.data.errors);
		//   });
	}
	function DELETE_USER(data: Record<string, any>) {
		const who = users.value.findIndex((user) => user.id === data.id);
		users.value.splice(who, 1);
	}
	function clearListOfUsers() {
		users.value = [];
	}

	function clearUsers() {
		console.log("Cleared users.");
		// commit("CLEAR_USERS");
		users.value = [];
		pagination.value = null;
	}

	return {
		// return data
		users,
		pagination,
		// return  functions
		fetchUsers,
		getFetchUsers,
		fetchUsersCancellable,
		patchUsers,
		deleteUser,
		getUsers,
		createUser,
		clearUsers,
		clearListOfUsers,
	};
});
