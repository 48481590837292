<script setup lang="ts">
import { computed, reactive } from "vue";
// import router from "@/router";
// Dont use useRouter useRoute from "RouterAuto" as it disabled HMR
// https://github.com/posva/unplugin-vue-router/issues/132
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = withDefaults(
	defineProps<{
		currentPage: number;
		totalPages?: number;
		itemsPerPage?: number;
		totalItems?: number;
		visiblePages?: number;
		isForceChange?: boolean;
		useRouting?: boolean;
	}>(),
	{
		totalPages: undefined,
		itemsPerPage: undefined,
		totalItems: undefined,
		visiblePages: 5,
		isForceChange: true,
		useRouting: true,
	},
);
const emit = defineEmits<{
	(e: "page-clicked", pageNum: number): void;
	(e: "page-changed", pageNum: number): void;
}>();
// const route = computed<any>(() => {});
const labels = reactive({
	first: "First",
	last: "Last",
});

const lastPage = computed(() => {
	if (props.totalPages) {
		return props.totalPages;
	} else {
		if (props.totalItems && props.itemsPerPage) {
			return props.totalItems % props.itemsPerPage === 0
				? props.totalItems / props.itemsPerPage
				: Math.floor(props.totalItems / props.itemsPerPage) + 1; // we add one, for that last page with incomplete itemsPerPage set.
		}

		return 1;
	}
});
const paginationRange = computed<number[]>(() => {
	const start =
		props.currentPage - props.visiblePages / 2 <= 0
			? 1
			: props.currentPage + props.visiblePages / 2 > lastPage.value
				? lowerBound(lastPage.value - props.visiblePages + 1, 1)
				: Math.ceil(props.currentPage - props.visiblePages / 2);

	const range = [];

	for (let i = 0; i < props.visiblePages && i < lastPage.value; i++) {
		range.push(start + i);
	}

	return range;
});

function lowerBound(num: number, limit: number) {
	return Math.max(num, limit);
}
function activePage(pageNum: number) {
	return props.currentPage === pageNum ? "active-item" : "";
}
function pageChanged(pageNum: number) {
	// This shouldn't be listened normally [Only added for other logic]
	emit("page-clicked", pageNum);
	const currentQuery = route.query;
	const changePage = () => {
		emit("page-changed", pageNum);
		if (props.useRouting) {
			void router.replace({ query: { ...currentQuery, page: pageNum } });
		}
	};
	// Put the page num into the url query string
	const pageQuery =
		(currentQuery.page && parseInt(currentQuery.page as string)) || 1;
	if (pageNum !== pageQuery) {
		changePage();
	} else if (props.isForceChange) {
		console.log(">> Force changing page", pageNum);
		changePage();
	} else {
		console.log(">> Blocked page change", pageNum);
	}
}
</script>

<template>
	<nav class="pagination is-centered">
		<div
			class="pagination-previous"
			href="#"
			aria-label="Previous"
			@click.prevent="pageChanged(1)"
		>
			{{ labels.first }}
		</div>
		<ul class="pagination-list">
			<li
				v-for="n in paginationRange"
				:key="n"
			>
				<div
					class="pagination-link"
					:class="{ 'active-item': activePage(n) }"
					@click.prevent="pageChanged(n)"
				>
					{{ n }}
				</div>
			</li>
		</ul>
		<div
			class="pagination-next"
			href="#"
			aria-label="Next"
			@click.prevent="pageChanged(lastPage)"
		>
			{{ labels.last }}
		</div>
	</nav>
</template>

<style lang="scss">
$pagination-hover-border: #b5b5b5;
$pagination-hover-color: #222;
.pagination {
	$color: #405168;

	width: 100%;
	display: flex;
	justify-content: flex-end;
	// gap: 10px;
	margin: 10px;
	padding: 10px;

	a {
		color: $color;
	}

	.pagination-list {
		justify-content: flex-end;
		display: flex;
		list-style-type: none;
		// gap: 5px;

		li {
			margin: 0 !important;
			padding: 0;
			min-width: 30px;
			div {
				padding: 5px;
				text-align: center;
			}
		}
	}

	.pagination-previous {
		margin: 0;
		padding: 5px;
		border: 1px solid #dde6eb;
		border-radius: 4px 0 0 4px;
		cursor: pointer;
		&:hover {
			border-color: $pagination-hover-border;
			color: $pagination-hover-color;
		}
	}

	.pagination-next {
		margin: 0;
		padding: 5px;
		border: 1px solid #dde6eb;
		border-radius: 0 4px 4px 0;
		cursor: pointer;
		&:hover {
			border-color: $pagination-hover-border;
			color: $pagination-hover-color;
		}
	}

	.pagination-link {
		border-radius: 0;
		margin: 0;
		border: 1px solid #dde6eb;
		cursor: pointer;
		&:hover {
			border-color: $pagination-hover-border;
			color: $pagination-hover-color;
		}
	}

	.active-item {
		background: $app-accent-color1;
		color: $button-color1;
	}
}
</style>
