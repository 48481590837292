import type { DirectiveBinding } from "vue";

export default {
	mounted(el: HTMLInputElement, binding: DirectiveBinding) {
		// Define the input handler function
		const inputHandler = (event: Event) => {
			const cleanedValue = el.value.replace(/\s+/g, "");
			if (el.value !== cleanedValue) {
				el.value = cleanedValue;
				const inputEvent = new Event("input", { bubbles: true });
				el.dispatchEvent(inputEvent);
			}
		};

		// Add the event listener
		el.addEventListener("input", inputHandler);

		// Store the input handler for later removal
		(el as any)._inputHandler = inputHandler;
	},
	unmounted(el: HTMLInputElement) {
		// Check if the element still exists and remove the event listener
		if (el && (el as any)._inputHandler) {
			el.removeEventListener("input", (el as any)._inputHandler);
		}
	},
};
