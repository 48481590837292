<script setup lang="ts">
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";

import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import type { TRegisterIncrement } from "../shared/modals/allCandidates/RegisterNewTempModal.vue";

const emit = defineEmits<{
	"close-modal": [void];
	confirm: [{ increments: TRegisterIncrement[]; weekCounter: string | null }];
}>();

const store = useDefaultStore();
const toastStore = useToastStore();
const sessionStore = useSessionStore();

const gPermissions = computed(() => sessionStore.gPermissions);
const canUseIncrementCo = computed(
	() => store.initialStatus?.can_use_increment_counter,
);

const props = defineProps<{
	increments: TRegisterIncrement[];
	subcategory: { id: number; name: string; catParentId: number };
	currentIncrementWeek: number | null;
}>();

const incrementWeekList = ref([]);

const subCatId = computed(() => props.subcategory.id);
const categoryId = computed(() => props.subcategory.catParentId);

const allIncrementsPoints = ref([]);

const currentIncrementData = ref<TRegisterIncrement[]>([]);

const newRow = ref({
	startDate: "",
	name: "",
	incrementPoint: null,
	new: true,
});

watch(
	() => props.increments,
	(val) => {
		currentIncrementData.value = val;
		currentIncrementData.value.sort((a, b) => {
			if (a.startDate < b.startDate) {
				return 1;
			} else if (b.startDate < a.startDate) {
				return -1;
			}
			return 0;
		});
	},
	{ immediate: true },
);
const selectedIncrementWeek = ref(null);
watch(
	() => props.currentIncrementWeek,
	async (val) => {
		if (val) {
			selectedIncrementWeek.value = val ? { weeks_counter: val + "" } : null;
		}
	},
	{ immediate: true },
);

const incrementsListTable = computed(() => {
	return [newRow.value, ...currentIncrementData.value];
});

async function fetchIncrementWeeksRange() {
	try {
		const res = await api.getIncrementWeeksCounter();
		const { from, to } = res.data.increment_counter_range || {};
		const leng = to - from + 1;
		incrementWeekList.value = Array.from({ length: leng }).map((val, ind) => {
			return {
				subcategory_id: subCatId.value,
				weeks_counter: ind + from + "",
			};
		});
	} catch (err) {
		console.warn(err.message);
	}
}
const loadingWeeksCoutnerValue = ref(false);

async function fetchIncrementsPoints(catId) {
	try {
		const params = {
			category_id: catId,
		};
		const res = await api.getIncrements(params);
		const { data } = res.data;
		allIncrementsPoints.value = data;
	} catch (err) {
		console.warn(err.message);
	}
}

const table_columns = ref([
	{
		id: "startDate",
		label: "EFFECTIVE DATE",
	},
	{
		id: "name",
		label: "INCREMENT POINT",
	},
	{
		id: "action",
		label: "ACTION",
	},
]);

function addNewIncrement() {
	if (!newRow.value.startDate || !newRow.value.incrementPoint) {
		toastStore.openToastError("Please fill all fields");
		return;
	}
	const newInc = {
		...newRow.value,
		startDate: DateInstance.getFormattedDate(
			newRow.value.startDate,
			"YYYY-MM-DD",
			false,
		),
		name: newRow.value.incrementPoint.name,
	};
	delete newInc.new;
	currentIncrementData.value.push(newInc);
	newRow.value = {
		startDate: "",
		name: "",
		incrementPoint: null,
		new: true,
	};
}

async function deleteIncrement(increment) {
	const index = currentIncrementData.value.findIndex(
		(inc) => inc.startDate === increment.startDate,
	);
	if (index !== -1) {
		currentIncrementData.value.splice(index, 1);
	}
}

const filterIncrementsPoints = computed(() => {
	const usedIncrementsIds = new Set(
		currentIncrementData.value.map((inc) => inc.incrementPoint.id),
	);
	return allIncrementsPoints.value.filter((aip) => {
		return !usedIncrementsIds.has(aip.id);
	});
});

async function fetchRangesAndValues() {
	await fetchIncrementWeeksRange();
}

function confirm() {
	emit("confirm", {
		increments: currentIncrementData.value,
		weekCounter: selectedIncrementWeek.value?.weeks_counter || null,
	});
}

onBeforeMount(() => {
	fetchRangesAndValues();
	store.fetchAllCategoriesList();
	if (categoryId.value) {
		fetchIncrementsPoints(categoryId.value);
	}
});
</script>
<template>
	<div class="candidate-increment-modal-wrap">
		<div
			v-if="gPermissions.canViewIncrementWeekCounter && canUseIncrementCo"
			class="top-part"
		>
			<div class="input-wrap">
				<label>Weekly Increment Counter</label>
				<multiselect-form
					v-model="selectedIncrementWeek"
					:options="incrementWeekList"
					placeholder="Select increment week"
					label="weeks_counter"
					value-prop="weeks_counter"
					:loading="loadingWeeksCoutnerValue"
					:can-clear="true"
				/>
			</div>
		</div>
		<div class="data">
			<modern-table
				:columns="table_columns"
				:rows="incrementsListTable"
				:is-sticky="false"
				:overflow="false"
			>
				<template #table-body-cell="{ column, row }">
					<template v-if="row.new">
						<td
							v-if="column.id === 'startDate'"
							class="base-table-cell"
						>
							<modern-date-picker
								v-model="newRow.startDate"
								placeholder="Select date"
								:enable-time-picker="false"
								auto-apply
							/>
						</td>
						<td
							v-else-if="column.id === 'name'"
							class="base-table-cell"
							style="overflow: visible"
						>
							<multiselect-form
								v-model="newRow.incrementPoint"
								:options="filterIncrementsPoints"
								value-prop="id"
								label="name"
								placeholder="Increment Point"
							/>
						</td>
						<td
							v-else-if="column.id === 'action'"
							class="base-table-cell"
							style="overflow: visible"
						>
							<span
								class="center"
								@click="addNewIncrement"
							>
								<i-fa4-plus></i-fa4-plus>
							</span>
						</td>
					</template>
					<template v-else>
						<td
							v-if="column.id === 'action'"
							class="base-table-cell"
						>
							<span
								class="center"
								@click="deleteIncrement(row)"
							>
								<i-fa4-times></i-fa4-times>
							</span>
						</td>
					</template>
				</template>
			</modern-table>
		</div>
		<div class="close-modal">
			<button @click="confirm">CONFIRM</button>
			<button @click="emit('close-modal')">CLOSE</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.candidate-increment-modal-wrap {
	padding: 20px;
	.top-part {
		display: flex;
		gap: 20px;
		margin-bottom: 30px;
		padding-bottom: 10px;
		border-bottom: 1px solid $grey;
		.button-wrap {
			display: flex;
			gap: 10px;
			align-self: flex-end;
			button {
				height: 32px;
			}
		}
	}
	.sub-label {
		display: flex;
		justify-content: space-between;
		margin: 20px 0;
	}
	.data {
		margin: 20px 0;
		.created-at {
			display: flex;
			align-items: center;
			gap: 10px;
		}
		.center {
			text-align: center;
			display: flex;
			justify-content: center;
			cursor: pointer;
		}
	}
	.close-modal {
		display: flex;
		justify-content: center;
		gap: 10px;
	}
	.text-error {
		color: $red;
	}
}
</style>
