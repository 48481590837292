<script setup lang="ts">
import { Errors } from "@/assets/js/errors";
import { parseErrors } from "@/assets/js/parseErrors";
import api from "@/api";
import { useToastStore } from "@/store/toast";
import { useClientManagementStore } from "@/store/locationClientManagement";

interface ILocation {
	id: number;
	name: string;
}

interface IForm {
	client_id: number;
	contact_type_id: number | null;
	locations: number[];
	name?: string;
	phone?: string;
	email?: string;
	note?: string;
}

const props = defineProps<{
	modalProps: {
		client_id: number;
		contact: {
			id: number;
			contact_type_id: number;
			locations: ILocation[];
			[key: string]: any;
		};
		contactTypes?: any[];
	};
}>();

const emit = defineEmits(["confirm", "close-modal"]);
const toast = useToastStore();

const clientStore = useClientManagementStore();

const form = ref<IForm>({
	client_id: props.modalProps.client_id,
	contact_type_id: props.modalProps.contact.contact_type_id,
	...props.modalProps.contact,
	locations: [],
});

const contactTitles = ref<any[]>([]);
const locations = ref<ILocation[]>([]);
const errors = new Errors();
const isLoading = ref(false);

const unfilteredContactTitles = computed(
	() => props.modalProps.contactTypes || [],
);

const parseContactTitle = computed(() => {
	const foundCt = unfilteredContactTitles.value.find(
		(ct) => ct.id === form.value.contact_type_id,
	);
	return foundCt || null;
});

const mapExistingLocations = () => {
	if (props.modalProps.contact.locations) {
		for (let i = 0; i < props.modalProps.contact.locations.length; i++) {
			form.value.locations.push(props.modalProps.contact.locations[i].id);
		}
		// props.modalProps.contact.locations.forEach((loc: any) => {
		// 	form.value.locations.push(loc.id);
		// 	// form.value.locations.push({
		// 	// 	name: loc.name,
		// 	// 	id: loc.id,
		// 	// });
		// });
	}
};

const init = async () => {
	console.log(props.modalProps);
	await getClientLocations();
	await fetchContactTitles();
	mapExistingLocations();
};

const getClientLocations = async () => {
	try {
		const params = {
			id: props.modalProps.client_id,
			include: "locations",
		};
		const res = await clientStore.fetchClientDetails(params);
		locations.value = res.data.data.locations;
	} catch (err) {
		console.error(err.message);
	}
};

const fetchContactTitles = async (params = {}) => {
	try {
		// const res = await store.dispatch("getClientContactsTypesList", params);
		const res = await api.getClientContactsTypesList(params);
		contactTitles.value = res.data.data;
	} catch (err) {
		console.error(err.message);
	}
};

const onSave = () => {
	saveAction();
};

const onCancel = () => {
	emit("close-modal");
};

const onContactTitleSelect = (evt: { id: number }) => {
	errors.clear("contact_type_id");
	form.value.contact_type_id = evt.id;
};

const onLocationSelect = (evt: { id: number; name: string }) => {
	errors.clear("locations");
	// form.value.locations.push(evt.id);
};

// const onRemoveLocation = (evt: { id: number }) => {
// 	// removeLocation(evt);
// 	const index = form.value.locations.findIndex((loc) => loc.id === evt.id);
// 	if (index !== -1) {
// 		form.value.locations.splice(index, 1);
// 	}
// };

const saveAction = async () => {
	const payload: any = { ...form.value };
	delete payload.title; // removing 'title' if exists
	payload.locations = form.value.locations.map((id) => ({ id }));
	isLoading.value = true;
	try {
		await api.putClientContacts(payload);
		emit("confirm");
		emit("close-modal");
	} catch (err: any) {
		console.error("Error", err.message);
		if (err.response?.data?.errors) {
			errors.record(err.response.data.errors);
		} else if (err.response?.data?.message) {
			toast.openToastError(err.response.data.message);
		} else {
			const errs = parseErrors(err);
			toast.openToastError(errs);
		}
		isLoading.value = false;
	}
};

// Call init when component is mounted
onMounted(init);
</script>

<template>
	<div id="client-contact-edit">
		<form
			autocomplete="off"
			@submit.prevent="onSave"
			@keydown="
				errors.clear(
					// @ts-ignore
					$event.target.name,
				)
			"
		>
			<div class="field">
				<label>Name</label>
				<input
					v-model="form.name"
					class="input"
					placeholder="Name"
					name="name"
					type="text"
				/>
				<span
					v-if="errors.has('name')"
					class="help is-danger"
					v-html="errors.get('name')"
				></span>
			</div>
			<div class="field">
				<label>Title</label>
				<multiselect-form
					v-model="parseContactTitle"
					class="column-select"
					:options="contactTitles"
					:searchable="true"
					:options-limit="999"
					value-prop="id"
					label="name"
					mode="single"
					placeholder="Contact Title"
					name="contact_type_id"
					@select="onContactTitleSelect"
				/>
				<span
					v-if="errors.has('contact_type_id')"
					class="help is-danger"
					v-html="errors.get('contact_type_id')"
				></span>
			</div>
			<div class="field">
				<label>Phone Number</label>
				<input
					v-model="form.phone"
					class="input"
					placeholder="Phone number"
					name="phone"
					type="text"
				/>
				<span
					v-if="errors.has('phone')"
					class="help is-danger"
					v-html="errors.get('phone')"
				></span>
			</div>
			<div class="field">
				<label>Email</label>
				<input
					v-model="form.email"
					class="input"
					placeholder="Email"
					name="email"
					type="email"
				/>
				<span
					v-if="errors.has('email')"
					class="help is-danger"
					v-html="errors.get('email')"
				></span>
			</div>
			<div class="field">
				<label>Unit</label>
				<multiselect-form
					v-model="form.locations"
					class="column-select"
					:options="locations"
					:searchable="true"
					:options-limit="999"
					value-prop="id"
					label="name"
					mode="tags"
					placeholder="Unit"
					name="locations"
					@select="onLocationSelect"
				/>
				<span
					v-if="errors.has('locations')"
					class="help is-danger"
					v-html="errors.get('locations')"
				></span>
			</div>
			<div class="field">
				<label>Note</label>
				<textarea
					v-model="form.note"
					class="input"
					placeholder="Note"
					name="note"
					type="text"
				></textarea>
				<span
					v-if="errors.has('note')"
					class="help is-danger"
					v-html="errors.get('note')"
				></span>
			</div>
			<div class="buttons-centered">
				<button
					class="button is-small is-generic-app-blue is-caps-lock"
					:disabled="isLoading"
					@click.once.prevent="onSave"
				>
					<div
						v-if="isLoading"
						class="loading-wrapper"
					>
						<img src="@/assets/image/loader.svg" />
					</div>
					<span>Save</span>
				</button>
				<button
					class="button is-small is-outlined is-caps-lock"
					@click.prevent="onCancel"
				>
					Cancel
				</button>
			</div>
		</form>
	</div>
</template>

<style lang="scss" scoped>
#client-contact-edit {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 600px;
	padding: 0 60px 45px;

	form {
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 20px 5px 5px;

		.field {
			display: flex;
			flex-direction: column;
			gap: 5px;

			input,
			textarea {
				height: 40px;
			}
		}
	}
}
.buttons-centered {
	button {
		display: flex;
		justify-content: space-evenly;
	}
}
.loading-wrapper {
	padding-top: 3px;
	img {
		height: 20px;
		width: 20px;
	}
}
.help.is-danger {
	background: $background-color;
}
</style>
