<script lang="ts" setup>
import api from "@/api";
// import { Errors } from "@/assets/js/errors";
import { getFormattedTime } from "@/assets/js/helpers";
import { SHIFT_TYPES } from "@/assets/js/mapData";
import { parseErrors } from "@/assets/js/parseErrors";
// import { useDefaultStore } from "@/store";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const shiftsStore = useShiftsStore();
const toastStore = useToastStore();
// const store = useDefaultStore();
// const initialStatus = store.initialStatus;
const props = defineProps<{
	modalProps: any;
}>();
const emit = defineEmits(["close-modal", "has-changed", "multiple-submitted"]);

const localShifts = ref<any>([]);
const oldLocalShifts = ref<any>([]);
// const errors = ref<any>(new Errors());
const disableButton = ref<boolean>(false);
const isDataChanged = ref<boolean>(false);
const isAllShiftsCancelledSuccessfully = ref<boolean>(false);
const optionsCancellationReasons = ref<any>([]);
// const selectedCancellationReason = ref<any>(null);
const successShiftIds = ref<any>([]);

const isAnyShiftOnCall = computed(() => {
	return localShifts.value.some((shift) => shift.hasOnCall);
});
const isAnyShiftSleepover = computed(() => {
	return localShifts.value.some((shift) => shift.hasSleepover);
});
const isAnyShiftAwaken = computed(() => {
	return localShifts.value.some((shift) => shift.isAwaken);
});
const requiredParamDisable = computed(() => {
	let count = 0;
	for (const shift of localShifts.value) {
		if (
			!shift.selectedCancellationReason ||
			shift.selectedCancellationReason === null
		) {
			count++;
		}
	}
	return count > 0;
});
watch(
	isDataChanged,
	(newValue) => {
		emit("has-changed", newValue);
	},
	{ deep: true },
);
function init() {
	const editedShifts = props.modalProps.shiftObjs.map((el) => {
		// ONCALL
		if (el.type === SHIFT_TYPES.oncall.key) {
			const oncallPartObj = el.shiftParts.find(
				(partObj) => partObj.type === SHIFT_TYPES.oncall.key,
			);
			if (oncallPartObj) {
				el.hasOnCall = true;
			}
		}
		// SLEEPOVER / AWAKEN
		if (el.type === SHIFT_TYPES.sleepover.key) {
			const awakenPartObj = el.shiftParts.find(
				(partObj) => partObj.type === SHIFT_TYPES.awaken.key,
			);
			if (awakenPartObj) {
				el.isAwaken = true;
			}
			const sleepoverPartObj = el.shiftParts.find(
				(partObj) => partObj.type === SHIFT_TYPES.sleepover.key,
			);
			if (sleepoverPartObj) {
				el.hasSleepover = true;
			}
		}
		return el;
	});
	localShifts.value = JSON.parse(JSON.stringify(editedShifts));
	oldLocalShifts.value = JSON.parse(JSON.stringify(localShifts.value));
}
function formatDate(time: string, format = "DD/MM/YYYY HH:mm") {
	return getFormattedTime(time, format);
}
function parsePartDate(shift, key, shiftType) {
	const partTimeObj = shift.shiftParts.find(
		(partObj) => partObj.type === shiftType,
	);
	let dateTime;
	if (partTimeObj) {
		dateTime = partTimeObj[key].date;
	}
	return dateTime && formatDate(dateTime);
}
async function onSubmitShifts() {
	disableButton.value = true;
	await cancelShifts();
	disableButton.value = false;
}
async function cancelShifts() {
	const params = {
		shifts: [],
	};

	for (const obj of localShifts.value) {
		delete obj.success;
		delete obj.error;
		if (successShiftIds.value.length > 0) {
			for (const id of successShiftIds.value) {
				const index = localShifts.value.findIndex((obj) => obj.id === id);
				if (index !== -1) {
					localShifts.value.splice(index, 1);
				}
			}
		}
		const shiftData = {
			id: obj.id,
			reason: obj.selectedCancellationReason?.id ?? null,
		};
		params.shifts.push(shiftData);
	}
	let errors = 0;
	try {
		const res = await api.multipleCancelShifts(params);
		const shiftResponses = res?.data?.data ?? [];
		// shiftsStore.spliceCancelledShifts(shiftResponses ?? []);
		for (const element of shiftResponses) {
			const updatedShift = localShifts.value.find(
				(obj) => obj.id === element.id,
			);
			if (element.success) {
				successShiftIds.value.push(element.id);
				updatedShift.success = element.success;
				shiftsStore.spliceCancelledShifts([{ id: element.id }]);
			} else if (element.error) {
				errors++;
				updatedShift.error = element.error;
			}
		}
		emit("multiple-submitted");
		isAllShiftsCancelledSuccessfully.value = true;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}
function setDefaultCancellationReason() {
	const defaultReason = optionsCancellationReasons.value.find(
		(obj) => obj.id === props.modalProps.cancellation_reason,
	); // Set the selected cancellation reason from props;
	if (defaultReason) {
		for (const shift of localShifts.value) {
			// Ensure the reason exists in the options
			shift.selectedCancellationReason = defaultReason;
		}
	}
}

function addSelectedCancellationReason(reason: any, shift: any) {
	shift.selectedCancellationReason = reason;
}

onMounted(async () => {
	init();
	await shiftsStore.fetchCancellationReasons();
	optionsCancellationReasons.value = shiftsStore.cancellationReasons;
	setDefaultCancellationReason();
});
</script>
<template>
	<div class="multiple-wrap">
		<div
			ref="list"
			class="table-wrap"
		>
			<table class="table">
				<thead>
					<tr>
						<th><span>Shift ID</span></th>
						<th style="width: 280px"><span>Cancellation Reason</span></th>
						<th><span>Name</span></th>
						<th><span>Client</span></th>
						<th><span>Subcategories</span></th>
						<th><span>Start Time</span></th>
						<th><span>End Time</span></th>
						<template v-if="isAnyShiftOnCall">
							<th><span>On Call Start Time</span></th>
							<th><span>On Call End Time</span></th>
						</template>
						<template v-if="isAnyShiftSleepover">
							<th><span>Sleepover Start Time</span></th>
							<th><span>Sleepover End Time</span></th>
						</template>
						<template v-if="isAnyShiftAwaken">
							<th><span>Awaken Start Time</span></th>
							<th><span>Awaken End Time</span></th>
						</template>
						<th><span>Status</span></th>
					</tr>
				</thead>
				<tbody v-if="localShifts && localShifts.length > 0">
					<tr
						v-for="shift in localShifts"
						:key="shift.id"
					>
						<td>
							<span>{{ shift?.id }}</span>
						</td>
						<td style="overflow: visible; min-width: 200px">
							<p class="control">
								<multiselect-form
									v-model="shift.selectedCancellationReason"
									:options="optionsCancellationReasons"
									:close-on-select="true"
									:searchable="true"
									:loading="false"
									value-prop="id"
									track-by="reason"
									label="reason"
									placeholder="CANCELLATION REASON"
									@select="addSelectedCancellationReason($event, shift)"
								/>
							</p>
						</td>
						<td :title="shift?.temp?.name">
							<span>{{ shift?.temp?.name }}</span>
						</td>
						<td :title="shift?.location?.client?.name">
							<span>{{ shift?.location?.client?.name }}</span>
						</td>
						<td
							:title="
								Boolean(shift?.shiftRequest?.subcategories)
									? shift.shiftRequest.subcategories
											.map((subCat) => subCat.name)
											.join(', ')
									: ''
							"
						>
							<span>
								{{
									Boolean(shift?.shiftRequest?.subcategories)
										? shift.shiftRequest.subcategories
												.map((subCat) => subCat.name)
												.join(", ")
										: ""
								}}
							</span>
						</td>
						<td>
							<span class="date-time">
								{{ formatDate(shift.startTime.date) }}
							</span>
						</td>
						<td>
							<span class="date-time">
								{{ formatDate(shift.endTime.date) }}
							</span>
						</td>
						<template v-if="isAnyShiftOnCall">
							<td>
								<span
									v-if="
										shift.type === SHIFT_TYPES.oncall.key && shift.hasOnCall
									"
									class="date-time"
								>
									{{ parsePartDate(shift, "from", SHIFT_TYPES.oncall.key) }}
								</span>
							</td>
							<td>
								<span
									v-if="
										shift.type === SHIFT_TYPES.oncall.key && shift.hasOnCall
									"
									class="date-time"
								>
									{{ parsePartDate(shift, "to", SHIFT_TYPES.oncall.key) }}
								</span>
							</td>
						</template>
						<template v-if="isAnyShiftSleepover">
							<td>
								<span
									v-if="
										shift.type === SHIFT_TYPES.sleepover.key &&
										shift.hasSleepover
									"
									class="date-time"
								>
									{{ parsePartDate(shift, "from", SHIFT_TYPES.sleepover.key) }}
								</span>
							</td>
							<td>
								<span
									v-if="
										shift.type === SHIFT_TYPES.sleepover.key &&
										shift.hasSleepover
									"
									class="date-time"
								>
									{{ parsePartDate(shift, "to", SHIFT_TYPES.sleepover.key) }}
								</span>
							</td>
						</template>
						<template v-if="isAnyShiftAwaken">
							<td>
								<span
									v-if="shift.isAwaken"
									class="date-time"
								>
									{{ parsePartDate(shift, "from", SHIFT_TYPES.awaken.key) }}
								</span>
							</td>
							<td>
								<span
									v-if="shift.isAwaken"
									class="date-time"
								>
									{{ parsePartDate(shift, "to", SHIFT_TYPES.awaken.key) }}
								</span>
							</td>
						</template>
						<td>
							<span
								v-if="shift.error && !shift.cancelledAt"
								class="error-text"
								style="display: block; min-width: 150px"
							>
								{{ shift.error }}
							</span>
							<span
								v-if="shift.success && !shift.cancelledAt"
								class="success-text"
								style="display: block; min-width: 150px"
							>
								{{ shift.success }}
							</span>
						</td>
					</tr>
				</tbody>
				<tbody v-if="localShifts.length === 0">
					<tr>
						<td>No data in table</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="buttons-centered">
			<button
				v-if="!isAllShiftsCancelledSuccessfully"
				class="button is-generic-app-blue is-caps-lock"
				:disabled="disableButton || requiredParamDisable"
				@click.prevent="onSubmitShifts"
			>
				<img
					v-if="disableButton"
					class="btn-loader"
					src="@/assets/image/loader.svg"
				/>
				<span>SUBMIT</span>
			</button>
			<button
				class="button is-outlined is-caps-lock"
				@click.prevent="emit('close-modal')"
			>
				{{ isAllShiftsCancelledSuccessfully ? "OK" : "CANCEL" }}
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.multiple-wrap {
	width: 91vw;
	max-height: 80vh;
	padding: 20px;
	overflow: auto;
	.table-wrap {
		width: 100%;
		min-width: 1400px;
	}
}

.shift {
	display: flex;
	flex-direction: column;
	gap: 0.5em 0;

	.row {
		display: flex;
		align-items: center;
		gap: 0 0.5em;
		width: 100%;

		.control.error {
			color: $red;
		}

		.control.success {
			color: $green;
		}

		.field {
			width: 50%;
		}
	}
}

.is-suffix {
	white-space: nowrap;
}

thead {
	position: sticky;
	top: 0;
	z-index: 1;
}

.date-time {
	padding: 6px;
	background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
	border: 1px solid #e2e7ea !important;
	border-radius: 5px !important;
	box-shadow: unset !important;
	display: inline-block;
	white-space: nowrap;
	font-size: 15px;
	pointer-events: none;
	user-select: none;
}
</style>
<style lang="scss" scoped>
$border-top-color-dark: #00000034;
$text-color-lighter: $label-color;
$scrollbar-color: $scrollbar-color;
$scrollbar-color-thumb: $scrollbar-color-thumb;
$table-header-background: #f2f7fa;
$tr-hover-background: #f5f5f5;
$td-text-color: #405168;
.multiple-wrap {
	.cov-datepicker {
		width: 100%;
		min-width: 145px;
	}
}
table {
	margin: 5px 0;
	border: 1px solid #9999998f;
	border-radius: 5px;
	width: 100%;
	min-width: 1300px;
	table-layout: fixed;
	border-collapse: collapse;
	th {
		color: $text-color-lighter;
		background: $table-header-background;
		text-align: left;
		padding: 7px 10px;
		z-index: 1;
		border-top: 1px solid #dde6eb;
		overflow: visible !important;
		text-overflow: ellipsis;
	}
	td {
		width: 100%;
		font-size: 0.9rem;
		padding: 7px 10px;
		border-top: 1px solid #dde6eb;
		color: $td-text-color;
		overflow: hidden;
		text-overflow: ellipsis;
		.flexer {
			display: flex;
			align-items: center;

			span {
				display: flex;
				align-items: center;
			}
			span:first-child {
				margin-right: auto;
			}
		}
	}
	td,
	th {
		cursor: alias;
		border: 1px solid #9999998f;
		text-align: center;
		vertical-align: middle;
		height: 35px;
		input {
			width: 100%;
			height: 100%;
			margin: 0;
			border: none;
			text-align: center;
			outline: none;
			&:hover {
				background: #64626225;
			}
			&:disabled {
				background: none;
			}
		}
	}
	.time-td {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		/* stylelint-disable-next-line no-descending-specificity */
		input {
			text-align: center;
			height: 100%;
		}
		input[type="number"]::-webkit-inner-spin-button,
		input[type="number"]::-webkit-outer-spin-button {
			/* stylelint-disable-next-line property-no-vendor-prefix */
			-webkit-appearance: none;
			appearance: none;
			margin: 0;
		}
		input[type="number"] {
			/* stylelint-disable-next-line property-no-vendor-prefix */
			-moz-appearance: textfield;
		}
	}
	input[type="text"]:disabled,
	input[type="number"]:disabled {
		cursor: not-allowed;
		/* stylelint-disable-next-line property-no-vendor-prefix */
		-moz-appearance: textfield;
	}
	select {
		outline: none;
		border: none;
		background: none;
		width: 100%;
		height: 25px;
		margin: 0;
		text-align: center;
		&:hover {
			cursor: pointer;
			background: #00000010;
		}
	}
	.delete-row {
		text-align: center;
		width: 80px;
		i {
			border-radius: 290486px;
			&:hover {
				cursor: pointer;
				color: rgba(0, 0, 0, 0.534);
			}
		}
	}
}
</style>
