import {
	getLocalStorageReac,
	getSessionStorageReac,
} from "@/assets/js/helpers";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import axios, { type AxiosError, type InternalAxiosRequestConfig } from "axios";

const STATUS_CODE = {
	// eslint-disable-next-line unicorn/no-unused-properties
	Unathorized: 401,
	Forbidden: 403,
};

// Handles DEV and PROD
export const baseURL = import.meta.env.VITE_APP_API_URL_DEV;
const instance = axios.create({
	baseURL: baseURL + "/api",
});
const SHOW_DEBUG = false;
let isBusyLogout = false;

instance.interceptors.request.use((config) => {
	handleRequestInterceptor(config);

	return config;
});
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		handleResponseInterceptor(error);
		return Promise.reject(error);
	},
);

function handleRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
	try {
		const tokenSession = getSessionStorageReac("user-token")?.value as string;
		const tokenLocal = getLocalStorageReac("user-token")?.value as string;
		const tokenData = tokenSession || tokenLocal;
		if (tokenData) {
			// if (config.params) {
			// 	config.params.sid = tokenData;
			// } else {
			// 	config.params = {
			// 		sid: tokenData,
			// 	};
			// }
			config.headers.Authorization = "Bearer" + tokenData;
		}
	} catch (err: any) {
		console.error("ERRor", err.message);
	}
}
function handleResponseInterceptor(error: AxiosError<any, any>) {
	SHOW_DEBUG && console.warn("ERR", error);
	if (
		error.response?.status === STATUS_CODE.Forbidden && // Forbidden - Logout and return to main page
		!isBusyLogout
	) {
		const msg = error.response.data.error_message || error.message;
		const signoutAndToast = async (msg) => {
			await useSessionStore().logoutApi(false, true);
			// sessionStorage.showSignInMessage = msg;
			// Toast is displayed in sign in component once
			// Vue.toasted.error(msg).goAway(TIMEOUT_TIME);
			useToastStore().openToastError(msg);
		};

		switch (error.response.data.command) {
			case "red_dnr": {
				signoutAndToast(msg);

				break;
			}
			case "credentials_changed": {
				// msg = 'Your credentials have been changed. Please login using the new credentials.'
				signoutAndToast(msg);

				break;
			}
			case "force_logout": {
				signoutAndToast(msg);

				break;
			}
			default: {
				if (
					error.message === "Your account is permanently banned." ||
					error.message === "You must login with new credentials."
				) {
					signoutAndToast(msg);
				} else if (error.response.data.command) {
					console.warn("New API Command Config:", error.response.data.command);
				}
			}
		}
		isBusyLogout = true;

		setTimeout(() => {
			isBusyLogout = false;
		}, 1000);
	}
}

export { instance as axios };
