<script setup lang="ts">
interface IProps {
	subject: string;
	created_by_email: string;
	note?: string;
}
defineProps<{
	modalProps: IProps;
}>();

const emit = defineEmits(["close-modal"]);
</script>

<template>
	<div id="journal-entry-details">
		<div class="content-wrap">
			<label>
				<span>Subject:</span>
				<div class="input">{{ modalProps.subject }}</div>
			</label>
			<label>
				<span>Sent from:</span>
				<div class="input">{{ modalProps.created_by_email }}</div>
			</label>
			<label>
				<span>Note:</span>
				<div
					class="textarea"
					v-html="modalProps.note"
				></div>
			</label>
		</div>
		<div class="button-line">
			<button
				class="button is-generic-app-blue is-caps-lock"
				@click.prevent="emit('close-modal')"
			>
				Close
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
#journal-entry-details {
	display: flex;
	flex-direction: column;
	gap: 2em;
	min-height: 35vh;
	min-width: 600px;
	padding: 0 40px 40px;

	.content-wrap {
		display: flex;
		flex-direction: column;
		.textarea {
			height: 500px;
			overflow: auto;
		}
	}
	.button-line {
		display: flex;
		justify-content: center;
		gap: 2em;
	}
	h2 {
		text-align: center;
	}
}
</style>
