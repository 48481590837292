<script setup lang="ts">
// Updated 24-01
import { useInfiniteScroll } from "@vueuse/core";

export type TTableColumn = {
	id: string; // Unique id - used when combining column with rows data
	label: string;
	style?: Record<string, any>;
	class?: Record<string, any>;
	sortable?: boolean;
};
export type TTableRow = {
	_class?: string[];
	_style?: Record<string, string>;
	[k: string]: any;
};
export type TAdditionalTBodyConfig = {
	displayLoader?: boolean;
	emptyTBodyMessage?: string;
};

const props = withDefaults(
	defineProps<{
		columns?: TTableColumn[];
		rows?: TTableRow[];
		hasBorder?: boolean;
		isSticky?: boolean;
		isInfiniteScroll?: boolean;
		displayAdditionalRow?: boolean;
		overflow?: boolean;
		hasAdditionalTBody?: TAdditionalTBodyConfig | null;
	}>(),
	{
		columns: () => [],
		rows: () => [],
		isSticky: true,
		isInfiniteScroll: true,
		displayAdditionalRow: false,
		overflow: true,
		hasAdditionalTBody: null,
	},
);
const emit = defineEmits([
	"click-header",
	"click-row",
	"click-cell",
	"scroll-more",
	"sort",
]);
const tableWrapperRef = ref<HTMLElement | null>(null);

const sortValue = ref({
	column: "",
	direction: "",
});

watch(
	sortValue,
	(val) => {
		if (val.column) {
			emit("sort", { ...val });
		}
	},
	{ deep: true },
);

const tableBorder = computed(() => {
	return {
		border: props.hasBorder,
		sticky: props.isSticky,
	};
});

function onTableScrollInfi() {
	if (props.isInfiniteScroll) {
		emit("scroll-more");
	}
}

onMounted(() => {
	if (tableWrapperRef.value) {
		useInfiniteScroll(tableWrapperRef, () => onTableScrollInfi(), {
			canLoadMore: (el: HTMLElement | null) => {
				if (el && el.scrollHeight > el.clientHeight) {
					return true;
				}

				return false;
			},
			distance: 10,
		});
	}
});
</script>

<template>
	<div
		ref="tableWrapperRef"
		class="table-wrapper"
		:class="{ overflow: overflow }"
	>
		<table :class="tableBorder">
			<slot name="table-content">
				<thead>
					<slot
						name="table-header-columns"
						:columns="columns"
					>
						<tr>
							<template
								v-for="column in columns"
								:key="column.id"
							>
								<slot
									name="table-header-cell"
									:column="column"
								>
									<slot
										:name="'table-header-cell-' + column.id"
										:column="column"
									>
										<th
											:style="column.style"
											:class="column.class"
											@click="$emit('click-header', column)"
										>
											<span>{{ column.label }}</span>
											<sorts-buttons
												v-if="column.sortable"
												:column-name="column.id"
												:value="sortValue"
												@update:model-value="sortValue = $event"
											></sorts-buttons>
										</th>
									</slot>
								</slot>
							</template>
						</tr>
					</slot>
				</thead>
				<tbody :class="{ 'has-extra-row': displayAdditionalRow }">
					<slot
						name="table-body-rows"
						:rows="rows"
					>
						<template
							v-for="(row, _index) in rows"
							:key="_index"
						>
							<tr
								:style="row._style"
								:class="row._class"
								@click="$emit('click-row', { row })"
							>
								<template
									v-for="column in columns"
									:key="column.id"
								>
									<slot
										name="table-body-cell"
										:row="row"
										:column="column"
										:row-index="_index"
									>
										<slot
											:name="'table-body-cell-' + _index + '-' + column.id"
											:row="row"
											:column="column"
										>
											<td @click="$emit('click-cell', { row, column })">
												<span>{{ row[column.id] }}</span>
											</td>
										</slot>
									</slot>
								</template>
							</tr>
							<slot
								v-if="displayAdditionalRow"
								name="table-body-extra-row"
								:row="row"
							>
								<tr class="extra-row">
									<slot
										name="table-body-extra-row-content"
										:row="row"
									>
										<template
											v-for="column in columns"
											:key="column.id"
										>
											<slot
												name="table-body-cell"
												:row="row"
												:column="column"
												:row-index="_index"
											>
												<slot
													:name="'table-body-cell-' + _index + '-' + column.id"
													:row="row"
													:column="column"
												>
													<td @click="$emit('click-cell', { row, column })">
														<span>{{ row[column.id] }}</span>
													</td>
												</slot>
											</slot>
										</template>
									</slot>
								</tr>
							</slot>
						</template>
					</slot>
				</tbody>
				<tbody
					v-if="hasAdditionalTBody && (!rows || (rows && rows.length === 0))"
				>
					<slot name="table-additional-body-rows">
						<tr>
							<td
								colspan="100"
								class="text-centered pd-tb-20"
							>
								<div
									v-if="hasAdditionalTBody?.displayLoader && !rows"
									class="loading-wrapper flex-center"
								>
									<img
										class="wd-30"
										src="@/assets/image/loader.svg"
									/>
								</div>
								<p v-else-if="rows && rows.length === 0">
									{{
										hasAdditionalTBody?.emptyTBodyMessage ?? "No results found."
									}}
								</p>
							</td>
						</tr>
					</slot>
				</tbody>
			</slot>
		</table>
	</div>
</template>

<style lang="scss" scoped>
.table-wrapper {
	$text-color-lighter: $label-color;
	$scrollbar-color: $scrollbar-color;
	$scrollbar-color-thumb: $scrollbar-color-thumb;
	$table-header-background: #f2f7fa;
	$tr-hover-background: #f5f5f5;
	$td-text-color: #405168;

	--row-back: white;
	--row-back-odd: var(--vt-c-white-soft);

	display: flex;
	gap: 10px;
	width: 100%;

	&.overflow {
		overflow: auto;
	}

	@document url-prefix() {
		// Only for Firefox
		* {
			scrollbar-color: $scrollbar-color $scrollbar-color-thumb;
			scrollbar-width: thin;
		}
	}

	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color-thumb;
		border-radius: 5px;
	}

	table {
		overflow: auto;
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #dde6eb;
		td {
			font-size: 0.9rem;
			padding: 7px 10px;
			border-top: 1px solid #dde6eb;
			color: $td-text-color;
			&:not(.extra-row) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 300px;
			}
		}

		thead {
			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}
		}

		tbody {
			tr {
				height: 40px;
				&:hover {
					background: $tr-hover-background;
				}
			}

			&.has-extra-row {
				tr {
					&:nth-child(4n + 1),
					&:nth-child(4n + 2) {
						background-color: var(--row-back-odd);
					}
					&:hover {
						background: $tr-hover-background;
					}
				}
			}

			&:not(&.has-extra-row) {
				tr {
					&:nth-child(2n + 1) {
						background-color: var(--row-back-odd);
					}
					&:hover {
						background: $tr-hover-background;
					}
				}
			}
			.extra-row {
				height: unset;
			}
		}

		&.border {
			border: 1px solid gray;

			th,
			td {
				border: 1px solid gray;
			}
		}

		&.sticky {
			th {
				position: sticky;
				top: 0;
			}
		}
	}
}
</style>
