<script lang="ts" setup>
const props = defineProps<{
	modalProps: any;
}>();
const emit = defineEmits(["close-modal"]);

const errorList = ref<any>([]);
const successList = ref<any>([]);
const errorMessages = ref<any>({});
const errorGlobalMessage = ref<any>(null);
const isInvalidErrorListing = ref<boolean>(false);
const totalNumberOfShifts = computed(
	() => errorList.value.length + successList.value.length,
);

function displayList() {
	errorGlobalMessage.value = props.modalProps.data.response.data?.message;
	successList.value = props.modalProps.data.response.data?.success_shifts || [];
	if (props.modalProps.data.response.data.errors) {
		errorList.value = props.modalProps.data.response.data.errors || [];
		errorMessages.value =
			props.modalProps.data.response.data.error_messages || {};
	} else {
		isInvalidErrorListing.value = true;
	}
}
function getMessage(error) {
	return errorMessages.value[error.type];
}
function callModalClose() {
	emit("close-modal");
}

onMounted(() => {
	displayList();
});
</script>
<template>
	<div class="shift-schedule-invoice-errors">
		<template v-if="!isInvalidErrorListing">
			<span class="center">
				{{ `${successList.length} / ${totalNumberOfShifts}` }} shifts
				successfully added
			</span>
			<ul>
				<li class="header">
					<div class="id">Shift id</div>
					<div class="msg">Message</div>
				</li>
				<li
					v-for="(item, index) in successList"
					:key="index"
					class="item success"
				>
					<div class="id">{{ item }}</div>
					<div class="msg">Successfully added</div>
				</li>
				<li
					v-for="error in errorList"
					:key="error.shift_id"
					class="item error"
				>
					<div class="id">{{ error.shift_id }}</div>
					<div
						class="msg"
						:title="getMessage(error)"
					>
						{{ getMessage(error) }}
					</div>
				</li>
			</ul>
		</template>
		<div
			v-if="isInvalidErrorListing"
			class="error-listing center"
		>
			<span>
				{{
					errorGlobalMessage || "There was an error addings shift(s) to invoice"
				}}
			</span>
		</div>
		<div class="buttons-centered">
			<button
				class="button is-generic-app-blue is-caps-lock"
				@click.prevent="callModalClose"
			>
				Close
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.shift-schedule-invoice-errors {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
	width: 680px;
	ul {
		display: flex;
		flex-direction: column;
		width: 90%;
		margin: 5px 0;
		padding: 0;
		overflow: auto;
		max-height: 70vh;

		li {
			// display: flex;
			min-height: 30px;
			height: 30px;
			align-items: center;
			padding: 0 10px;
			display: inline-flex;
			white-space: nowrap;
			min-width: 100%;

			&.item {
				&.error {
					background: #f5003169;
					color: $black;
				}

				&.success {
					background: #00f51469;
					color: $black;
				}
			}

			&.header,
			&.item {
				div {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					&.id {
						min-width: 120px;
					}

					&.msg {
						width: 100%;
					}
				}
			}
		}
	}

	.center {
		text-align: center;
	}

	.buttons-centered {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;

		button.button {
			margin: 17px 0.3em;
			min-width: 90px;
			height: 30px;
		}
	}
}
</style>
