<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import api from "@/api";
import type {
	ISubcategory,
	ITempsForShift,
	TShiftQuoteResponse,
} from "@/api/types";
import { debounce, parseDate, parseTime } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import type { StyleValue } from "vue";

export interface IInitialStatusObject {
	color_1: string;
	color_2: string;
	color_toggle_off: string;
	color_toggle_on: string;
	monetary_symbol: string;
	servicefee_show_calcitems: boolean;
	servicefee_show_increments: boolean;
	servicefee_show_subcategories: boolean;
	servicefee_calcitems: string;
	timerange_calcitems: string;
	can_view_allowances: boolean;
	shift_sign_off_warning: boolean;
	can_copy_service_fees_from_rates: boolean;
	can_use_increment_counter: boolean;
	show_gnib_rules: boolean;
	can_use_holiday_requests: boolean;
	can_use_on_call: boolean;
	can_use_sleepover: boolean;
	can_use_expenses: boolean;
	can_manage_public_holidays: boolean;
	can_use_shift_activity_tags: boolean;
}

interface IShiftData {
	id: number;
	status: string;
	startTime: IStartTime;
	endTime: IStartTime;
	partOfTheDay: number;
	signedOffAt?: any;
	cancelledAt?: any;
	breakMinutes: number;
	isConfirmedByClient: boolean;
	isConfirmedByTemp: boolean;
	signOffNote?: any;
	arbitrationState?: any;
	type: string;
	createdAt: IStartTime;
	shiftParts: any[];
	shiftRequest: IShiftRequest;
	location: ILocation2;
	userCreated: IUserCreated;
	shiftLogs: IShiftLog[];
	responses: IResponse[];
	tags?: { id: number; name: string }[];
}

interface IResponse {
	id: number;
	name: string;
}

interface IShiftLog {
	id: number;
	type: string;
	note: string;
	createdAt: string;
	shift: IShift;
	user: IUser;
}

interface IUser {
	id: number;
	name: string;
	email: string;
	pin: string;
	shiftsSent: number;
	status: number;
	lastLogin: string;
	lastAction: string;
	canViewClientAssignedReport: boolean;
	canViewClientActivityReport: boolean;
	canViewUnbilledShiftsReport: boolean;
	canViewUnprocessedShiftsReport: boolean;
	canViewClientUnassignedActivityReport: boolean;
	canViewAnnualLeaveBalancesReport: boolean;
	canAssignPermissionPerEntities: boolean;
}

interface IShift {
	id: number;
	status: string;
	startTime: IStartTime;
	endTime: IStartTime;
	partOfTheDay: number;
	signedOffAt?: any;
	cancelledAt?: any;
	breakMinutes: number;
	isConfirmedByClient: boolean;
	isConfirmedByTemp: boolean;
	signOffNote?: any;
	arbitrationState?: any;
	type: string;
	createdAt: IStartTime;
	location: ILocation3;
}

interface ILocation3 {
	id: number;
	name: string;
	archived: boolean;
	hasLocationAllowance: boolean;
	hasSecureAllowance: boolean;
	hasCommunityAllowance: boolean;
}

interface IUserCreated {
	id: number;
	email: string;
	name: string;
}

interface ILocation2 {
	id: number;
	name: string;
	archived: boolean;
	hasLocationAllowance: boolean;
	hasSecureAllowance: boolean;
	hasCommunityAllowance: boolean;
	client: IClient;
}

interface IClient {
	id: number;
	name: string;
	type?: any;
	addressLine1: string;
	addressLine2: string;
	eircode: string;
	billingContactName: string;
	billingContactTitle: string;
	billingContactEmail: string;
	phone: string;
	active: boolean;
	useRatesFrom?: any;
	autorepushactive: number;
	autorepushminutes: number;
	custom_client_id: number;
	locations: ILocation[];
}

interface ILocation {
	loc_id: number;
	id: number;
	name: string;
	archived: number;
	hasLocationAllowance: number;
	hasSecureAllowance: number;
	hasCommunityAllowance: number;
}

interface IShiftRequest {
	id: number;
	type: number;
	specialShiftName: string;
	pushedToTemps: boolean;
	notes?: any;
	created_at: IStartTime;
	gender: string;
	timer: number;
	subcategories: ISubcategory[];
}

interface IStartTime {
	date: string;
	timezone_type: number;
	timezone: string;
}

interface IAgencyWorker {
	id: number;
	name: string;
	first_name: string;
	last_name: string;
	email: string;
	gender: string;
	homeAddress: string;
	homeAddress2: string;
	homeAddress3: string;
	eircode?: any;
	mobilePhone: string;
	homePhone: string;
	tssId: string;
	hasCommunityAllowance: boolean;
	canCreateOwnShift: boolean;
	dateOfBirth: string;
	status: number;
	isContractor: boolean;
	receiveNotification: boolean;
	lastAction?: any;
	response?: any;
	contract_start?: any;
	contract_hours?: any;
	nickname: string;
	canUseHolidayRequests: boolean;
}

const store = useDefaultStore();
const sessionStore = useSessionStore();
const toastStore = useToastStore();
const can = sessionStore.can;
const props = defineProps<{
	modalProps: {
		shift: IShiftData;
	};
}>();
const emit = defineEmits(["confirm-modal", "close-modal"]);

const form = reactive({
	percentage: "",
	incentive: "",
});
const canUseServiceFeePerc = ref(false);
const canUseIncentive = ref(false);
const selectedAgencyWorker = ref<IAgencyWorker | null>(null);
const isLoadingWorkers = ref(false);
const isGeneratedQuote = ref(false);
const quoteData = ref<TShiftQuoteResponse | null>(null);
const isLoadingBtn = reactive({
	generate: false,
	tempPdf: false,
	clientPdf: false,
	save: false,
});

const shiftData = computed(() => props.modalProps.shift);
const sfMonetaryValue = computed(
	() =>
		(store.initialStatus as unknown as IInitialStatusObject).monetary_symbol ||
		"eur",
);
const canViewReports = computed(
	() => can("view-shift-quote-report") && isGeneratedQuote.value,
);
const agencyWorkers = ref<ITempsForShift[]>([]);

const findWorkerFunc = debounce(
	async (query) => {
		console.log(typeof query, "query");
		if (query && query.length > 0) await fetchWorkers(query);
	},
	400,
	false,
);

async function fetchWorkers(name: string) {
	isLoadingWorkers.value = true;
	const params = {
		availability: 0,
		id: props.modalProps.shift.id,
		name,
	};

	try {
		const res = await api.getTempsShift(params);
		agencyWorkers.value = res.data.data;
	} catch (err: any) {
		console.warn(err.message);
	} finally {
		isLoadingWorkers.value = false;
	}
}

async function fetchShiftQuote() {
	// Generate
	if (!selectedAgencyWorker.value?.id) {
		console.warn("No temp selected");
		return;
	}
	if (!props.modalProps.shift.tags?.[0].id) {
		console.warn("No tags");
		return;
	}

	isLoadingBtn.generate = true;
	const params = {
		id: props.modalProps.shift.id,
		temp_id: selectedAgencyWorker.value.id,
		shift_activity_tag_id: props.modalProps.shift.tags?.[0].id,
		servicefee: canUseServiceFeePerc.value
			? form.percentage || undefined
			: undefined,
		incentive: canUseIncentive.value ? form.incentive || undefined : undefined,
	};
	try {
		const res = await api.getShiftQuote(params);
		quoteData.value = res.data.data;
		isGeneratedQuote.value = true;
		isLoadingBtn.generate = false;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		isGeneratedQuote.value = false;
		isLoadingBtn.generate = false;
	}
}
async function setShiftQuote(): Promise<boolean> {
	// Submit
	if (!selectedAgencyWorker.value?.id) {
		console.warn("No temp selected");
		return false;
	}
	if (!props.modalProps.shift.tags?.[0].id) {
		console.warn("No tags");
		return false;
	}

	const params = {
		id: props.modalProps.shift.id,
		temp_id: selectedAgencyWorker.value.id,
		shift_activity_tag_id: props.modalProps.shift.tags?.[0].id,
		servicefee: canUseServiceFeePerc.value
			? form.percentage || undefined
			: undefined,
		incentive: canUseIncentive.value ? form.incentive || undefined : undefined,
	};
	try {
		const res = await api.postShiftQuote(params);
		console.warn(res.data);
		return true;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
	return false;
}
async function fetchShiftQuoteReport(type: "temp_quote" | "client_quote") {
	// Reports
	if (!selectedAgencyWorker.value?.id) {
		console.warn("No temp selected");
		return false;
	}

	const params = {
		id: props.modalProps.shift.id,
		temp_id: selectedAgencyWorker.value.id,
		quote_for: type,
		shift_activity_tag_id: props.modalProps.shift.tags?.[0].id,
		servicefee: canUseServiceFeePerc.value
			? form.percentage || undefined
			: undefined,
		incentive: canUseIncentive.value ? form.incentive || undefined : undefined,
	};
	try {
		const res = await api.getShiftQuoteReport(params);
		const blob = new Blob([res.data], { type: "application/pdf" });
		const headerFileName = res.headers["x-multi-file-name"];
		const name = headerFileName || "round 3.pdf";
		clickDownload(blob, name);
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}

function clickDownload(blob: Blob, fileName: string) {
	const a = document.createElement("a");
	const url = URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	URL.revokeObjectURL(url);
}

function onGenerate() {
	if (selectedAgencyWorker.value) {
		fetchShiftQuote();
		// generateForm();
	} else {
		console.warn("No selected worker");
	}
}
async function onViewTempReport() {
	isLoadingBtn.tempPdf = true;
	await fetchShiftQuoteReport("temp_quote");
	isLoadingBtn.tempPdf = false;
}
async function onViewClientReport() {
	isLoadingBtn.clientPdf = true;
	await fetchShiftQuoteReport("client_quote");
	isLoadingBtn.clientPdf = false;
}
function onChangeServiceFee(evt: Event) {
	const isChecked = (evt.target as HTMLInputElement).checked;
	if (!isChecked) {
		form.percentage = "";
	}
}
function onChangeIncentive(evt: Event) {
	const isChecked = (evt.target as HTMLInputElement).checked;
	if (!isChecked) {
		form.incentive = "";
	}
}
function onCancel() {
	emit("close-modal");
}

const hasTableData = computed<boolean>(
	() =>
		!!(quoteData.value.difference.length && quoteData.value.original.length),
);
const dataParsed = computed(() => quoteData.value);

function getHeaderStyle(
	heading: (typeof dataParsed.value.headings)[0],
): StyleValue {
	return {
		width: "33%",
		"text-align": "center",
		...heading.style,
	};
}
function getCellStyle(
	dataStyle: (typeof dataParsed.value.original)[0],
): StyleValue {
	return {
		...dataStyle.style,
	};
}
function getCellHeaderStyle(
	dataStyle: (typeof dataParsed.value.original)[0],
): StyleValue {
	return {
		minWidth: "300px",
		...dataStyle.labelStyle,
	};
}
function onConfirm() {
	isModalOpen.value = true;
	warnMsg.value =
		"Are you sure you wish to confirm this quote? Please note that the core elements of this shift can still be edited as normal before sign off as with any other shift but the added Round 3 elements cannot be edited after this confirmation.";
}
const isModalOpen = ref<boolean>(false);
const initialModalInModalOpen = ref<boolean>(false);
const warnMsg = ref<string>("");
async function onSubmit() {
	if (selectedAgencyWorker.value) {
		try {
			isLoadingBtn.save = true;
			const isSuccess = await setShiftQuote();
			if (isSuccess) {
				emit("confirm-modal");
				emit("close-modal");
			}
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			isLoadingBtn.save = false;
		}
	} else {
		console.warn("No selected worker");
	}
}
</script>
<template>
	<div class="details-round-modal">
		<modal-in-modal
			:isModalOpen="isModalOpen"
			:initialModalInModalOpen="initialModalInModalOpen"
			:confirmMessage="warnMsg"
			@confirm-action="onSubmit"
			@close-modal="isModalOpen = false"
			@initial-model-open="initialModalInModalOpen = isModalOpen"
		/>
		<modern-table
			:isSticky="true"
			:has-border="false"
			:display-additional-row="false"
			class="mgb-10"
		>
			<template #table-content>
				<tbody>
					<tr>
						<th>Shift ID</th>
						<td class="value">{{ shiftData.id }}</td>
					</tr>
					<tr>
						<th>Shift Date</th>
						<td>
							{{ parseDate(shiftData.startTime.date, "/") }} -
							{{ parseDate(shiftData.endTime.date, "/") }}
						</td>
					</tr>
					<tr>
						<th>Shift Time</th>
						<td>
							{{ parseTime(shiftData.startTime.date) }} -
							{{ parseTime(shiftData.endTime.date) }}
						</td>
					</tr>
					<tr>
						<th>Location</th>
						<td>{{ shiftData.location.client.name }}</td>
					</tr>
					<tr>
						<th>Ward</th>
						<td>{{ shiftData.location.name }}</td>
					</tr>
					<tr>
						<th>Subcategory</th>
						<td>
							{{
								shiftData.shiftRequest.subcategories
									.map((sub) => sub.name)
									.join(",")
							}}
						</td>
					</tr>
				</tbody>
			</template>
		</modern-table>
		<div class="extra-form-fields">
			<div class="group">
				<div class="first-col">
					<div class="label">
						Would you like to enter a different service fee percentage?
					</div>
					<label class="checkbox-group">
						<input
							v-model="canUseServiceFeePerc"
							type="checkbox"
							@change="onChangeServiceFee"
						/>
						<span class="mgl-5">Yes</span>
					</label>
				</div>
				<div class="value">
					<template v-if="canUseServiceFeePerc">
						<input
							v-model.number="form.percentage"
							class="input"
							type="number"
							placeholder="Service fee percentage"
						/>
						<span>%</span>
					</template>
				</div>
			</div>
			<div class="group">
				<div class="first-col">
					<div class="label">Would you like to add an incentive?</div>
					<label class="checkbox-group">
						<input
							v-model="canUseIncentive"
							type="checkbox"
							@change="onChangeIncentive"
						/>
						<span class="mgl-5">Yes</span>
					</label>
				</div>
				<div class="value">
					<template v-if="canUseIncentive">
						<input
							v-model.number="form.incentive"
							class="input"
							type="number"
							placeholder="Incentive"
						/>
						<span>{{ sfMonetaryValue }}</span>
					</template>
				</div>
			</div>
			<div class="group">
				<div class="first-col">
					<div class="label">Agency Worker</div>
				</div>
				<div class="value">
					<multiselect-form
						id="agencyWorker"
						v-model="selectedAgencyWorker"
						:options="agencyWorkers"
						:close-on-select="true"
						style="border-radius: 21px"
						:searchable="true"
						:options-limit="50"
						select-label=""
						value-prop="id"
						label="name"
						:loading="isLoadingWorkers"
						mode="single"
						placeholder="Type to find agency worker"
						:internal-search="false"
						:max="1"
						:can-clear="true"
						:canDeselect="false"
						@search-change="findWorkerFunc"
					/>
				</div>
			</div>
		</div>
		<div
			v-if="isGeneratedQuote && quoteData"
			class="round-quote-calc mgt-10"
		>
			<div class="table-wrap">
				<div
					v-if="isLoadingBtn.generate"
					class="loading-wrapper flex-center"
				>
					<img src="@/assets/image/loader.svg" />
				</div>
				<table v-else-if="hasTableData && dataParsed && !isLoadingBtn.generate">
					<thead>
						<tr>
							<th style="min-width: 170px"></th>
							<th
								v-for="(heading, headingIndex) in dataParsed.headings"
								:key="headingIndex"
								:style="getHeaderStyle(heading)"
							>
								{{ heading.label }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="index in dataParsed.original.length"
							:key="index"
						>
							<th :style="getCellHeaderStyle(dataParsed.original[index - 1])">
								{{ dataParsed.original[index - 1].label }}
							</th>
							<td :style="getCellStyle(dataParsed.original[index - 1])">
								{{ dataParsed.original[index - 1].value }}
							</td>
							<td :style="getCellStyle(dataParsed.quoted[index - 1])">
								{{ dataParsed.quoted[index - 1].value }}
							</td>
							<td :style="getCellStyle(dataParsed.difference[index - 1])">
								{{ dataParsed.difference[index - 1].value }}
							</td>
						</tr>
					</tbody>
				</table>
				<div
					v-else
					class="no-data"
				>
					<span>No data found</span>
				</div>
			</div>
		</div>
		<div class="action mgt-10">
			<button
				class="button is-generic-app-blue is-caps-lock is-tiny copy-shift"
				:class="{ 'is-loading': isLoadingBtn.generate }"
				:disabled="!selectedAgencyWorker || isLoadingBtn.generate"
				@click="onGenerate"
			>
				Generate quote
			</button>
			<template v-if="canViewReports">
				<button
					class="button is-generic-app-blue is-caps-lock is-tiny copy-shift"
					:class="{ 'is-loading': isLoadingBtn.tempPdf }"
					:disabled="
						!selectedAgencyWorker || !isGeneratedQuote || isLoadingBtn.tempPdf
					"
					@click="onViewTempReport"
				>
					Export Temp Quote
				</button>
				<button
					class="button is-generic-app-blue is-caps-lock is-tiny copy-shift"
					:class="{ 'is-loading': isLoadingBtn.clientPdf }"
					:disabled="
						!selectedAgencyWorker || !isGeneratedQuote || isLoadingBtn.clientPdf
					"
					@click="onViewClientReport"
				>
					Export Client Quote
				</button>
			</template>
			<button
				class="button is-generic-app-blue is-caps-lock is-tiny copy-shift"
				:class="{ 'is-loading': isLoadingBtn.save }"
				:disabled="!selectedAgencyWorker || isLoadingBtn.save"
				@click="onConfirm"
			>
				Confirm Quote
			</button>
			<button
				class="alt button is-caps-lock is-tiny copy-shift"
				@click="onCancel"
			>
				Cancel
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.details-round-modal {
	width: 800px;
	height: 700px;
	overflow: auto;
	padding: 0 20px 20px;

	$text-color-lighter: $label-color;
	$tr-hover-background: #f5f5f5;
	$table-header-background: #f2f7fa;
	$td-text-color: #405168;
	$fa-color: #aeafb7;
	$generic-app-tag-color: #dbe1e5;

	font-size: 0.7rem;

	.label {
		color: $label-color;
		font-weight: bold;
	}
	tbody {
		tr {
			&:hover {
				background: $tr-hover-background;
			}
		}
	}
	th {
		color: $text-color-lighter;
		background: $table-header-background;
		text-align: left;
		padding: 5px 10px;
		z-index: 1;
		border: 1px solid #dde6eb;
		border-right: 1px solid #dde6eb;
		overflow: visible !important;
		height: 17px;
	}
	td {
		padding: 5px 10px;
		border: 1px solid #dde6eb;
		color: $td-text-color;
		&:not(.extra-row) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
	}

	.form-section {
		display: flex;
		flex-direction: column;
	}

	.extra-form-fields {
		display: flex;
		flex-direction: column;

		.label {
			min-width: 300px;
			width: 300px;
		}
	}

	.group {
		display: flex;

		> * {
			border-left: 1px solid silver;
			border-top: 1px solid silver;

			&:last-child {
				border-right: 1px solid silver;
			}
		}

		&:last-child {
			> * {
				border-bottom: 1px solid silver;
			}
		}
		.first-col {
			padding: 3px;
			width: 50%;
			.label {
				padding: 0;
			}

			.checkbox-group {
				color: unset;
			}
		}

		label {
			display: flex;
			align-items: center;
			margin: 0;
		}

		.value {
			flex: 1;
			padding: 3px;
			display: flex;
			align-items: center;
			// white-space: nowrap;
			// text-overflow: ellipsis;
			// overflow: hidden;
			gap: 5px;

			input {
				height: 30px;
				width: 100%;

				&::placeholder {
					font-weight: 500;
				}
			}
		}
	}

	.action {
		display: flex;
		justify-content: center;
		gap: 10px;
	}
	.round-quote-calc {
		display: flex;

		.table-wrap {
			width: 100%;
			font-size: 0.7rem;

			table {
				th {
					padding: 5px;
				}

				td {
					text-align: center;
				}
			}
		}

		$crimson-color: #dc143c;

		.no-data {
			text-align: center;
			color: $crimson-color;
			font-weight: bold;
		}

		.loading-wrapper {
			text-align: center;
		}
	}
}
</style>
