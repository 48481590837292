export const temp_routes = [
	{
		path: "/temp-calendar",
		name: "temp.calendar",
		component: () => import("@/pages/TempCalendarPage.vue"),
		// {
		// 	render() {
		// 		return h("h1", "Not yet implementedww");
		// 	},
		// },
		meta: {
			requiresAuth: true,
			permission: "view-temp-calendar",
			fail: "/error",
		},
	},
	{
		path: "/temp-documents",
		name: "temp.documents",
		component: () => import("@/pages/admin/compliances/TempCompliancePage.vue"),
		meta: {
			requiresAuth: true,
			permission: "view-temp-documents",
			fail: "/error",
		},
	},
	{
		path: "/temp-profile",
		name: "temp.profile",
		component: () => import("@/pages/temp/Edit.vue"),
		meta: {
			requiresAuth: true,
			// edit profile used to identify if user is temp
			permission: "edit-profile",
			fail: "/error",
		},
	},
];
