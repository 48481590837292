<script setup lang="ts">
export type TCheckboxListItem = {
	name: string;
	id: number;
	label: string;
	disabled?: boolean;
	hasComplianceAllowance?: boolean;
	children?: TCheckboxListItem[];
};
const props = withDefaults(
	defineProps<{
		list: TCheckboxListItem[];
		checkedList: number[];
		originalCheckedList?: number[];
		changedCheckedList?: number[];
		disabledItems?: number[];
		expandedItems?: number[];
		isDisabledList?: boolean;
		depth?: number;
		isSpecialKey?: "documents-locations";
		displayChildrenCount?: boolean;
		shouldSortList?: boolean;
		canSelectWholeNode?: boolean;
		isVerticalList?: boolean;
		depthClasses?: { [depth: number]: string[] };
		forceDisplayExpand?: number;
		hasCheckbox?: boolean;
		isSoloCheckValid?: boolean;
		hasArrow?: boolean;
		useWholeCheckedList?: boolean;
		useNewExpand?: boolean;
	}>(),
	{
		originalCheckedList: () => [],
		changedCheckedList: () => [],
		disabledItems: () => [],
		expandedItems: () => [],
		isDisabledList: false,
		depth: 0,
		isSpecialKey: undefined,
		shouldSortList: true,
		displayChildrenCount: false,
		canSelectWholeNode: true,
		isVerticalList: true,
		depthClasses: () => {
			return {};
		},
		forceDisplayExpand: -1,
		hasCheckbox: true,
		isSoloCheckValid: false,
		hasArrow: true,
		useWholeCheckedList: false,
		useNewExpand: false,
	},
);

const attrs = useAttrs();
const hasAnyInvalidId = computed(() => {
	// Checks nodes for invalid / duplicated ids
	for (let index = 0; index < props.list.length; index++) {
		const listItemId = props.list[index]?.id;
		if (listItemId) {
			for (let jIndex = index + 1; jIndex < props.list.length; jIndex++) {
				const jListItemId = props.list[jIndex].id;
				if (listItemId === jListItemId) {
					console.error(
						"Array has duplicated IDs",
						listItemId,
						index,
						props.list[index],
					);
					return true;
				}
			}
		} else {
			return true;
		}
	}
	return false;
});

const hasListAnyChildren = computed(() => {
	if (props.hasArrow) {
		if (props.depth === 0) {
			return props.list.some((item) => item.children?.length);
		}
		return true;
	}
	return false;
});

const emit = defineEmits<{
	(e: "click", value: { item: TCheckboxListItem }): void;
	(e: "click-children", value: { item: TCheckboxListItem }): void;
	(
		e: "checked",
		value: {
			item: TCheckboxListItem;
			checked: boolean;
			depth?: number;
		},
	);
	(
		e: "expand",
		value: {
			item: TCheckboxListItem;
			checked?: boolean;
			depth?: number;
		},
	);
	(e: "change-compliance-allowance", value: { id: number; val: boolean });
}>();

const sortedList = computed(() =>
	props.shouldSortList
		? [...props.list].sort((a, b) => ((a.name || "") < (b.name || "") ? -1 : 1))
		: props.list,
);

function getNodeKey(node: TCheckboxListItem, index: number) {
	return hasAnyInvalidId.value ? index : node.id;
}
function getChangedRow(node) {
	if (props.changedCheckedList.length) {
		return props.changedCheckedList.includes(node.id);
	} else if (props.originalCheckedList.length) {
		const firstChanged = props.originalCheckedList.filter(
			(id) => !props.checkedList.includes(id),
		);
		const secondChanged = props.checkedList.filter(
			(id) => !props.originalCheckedList.includes(id),
		);
		return firstChanged.concat(secondChanged).includes(node.id);
	} else {
		return false;
	}
}

function isNodeChecked(node: TCheckboxListItem) {
	const isMainChecked = props.checkedList.includes(node.id);
	if (props.isSoloCheckValid) {
		return isMainChecked; // Untested
		// return isMainChecked || isEveryChildrenChecked(); // Untested
	} else if (node.children) {
		return (
			(isMainChecked && isSomeChildrenChecked(node)) ||
			isEveryChildrenChecked(node)
		);
	}
	return isMainChecked;
}

function isEveryChildrenChecked(node: TCheckboxListItem) {
	if (node.children?.length) {
		const recSearch = (arr = []) => {
			return arr.every((item) => {
				return item.children?.length
					? recSearch(item.children)
					: props.checkedList.includes(item.id);
			});
		};

		return recSearch(node.children);
	}
	return false;
}

function isSomeChildrenChecked(node: TCheckboxListItem) {
	if (node.children?.length) {
		const recSearch = (arr = []) => {
			return arr.some((item) => {
				return item.children?.length
					? recSearch(item.children)
					: props.checkedList.includes(item.id);
			});
		};

		return recSearch(node.children);
	}
	return false;
}
function isDisabledItemList(node: TCheckboxListItem) {
	if (props.isDisabledList || node.disabled) {
		return true;
	}
	return props.disabledItems.includes(node.id);
}

function onInputCheckbox(evt: Event, node: TCheckboxListItem) {
	if (isDisabledItemList(node)) {
		evt.preventDefault();
		return false;
	}
	const val = (evt.target as HTMLInputElement).checked;
	emit("checked", {
		item: node,
		checked: val,
		depth: props.depth,
	});
}

function isNodeIndeterminate(node: TCheckboxListItem) {
	if (props.isSoloCheckValid) {
		const isMainChecked = props.checkedList.includes(node.id);
		return !isMainChecked && isSomeChildrenChecked(node);
		// return false;
	}
	return !isEveryChildrenChecked(node) && isSomeChildrenChecked(node);
}

function onChangeCompliance(evt: Event, node: TCheckboxListItem) {
	// SPECIFIC FEATURE
	const val = evt ? 1 : 0;
	// this.$set(node, "hasComplianceAllowance", val);
	const payload = {
		id: node.id,
		val: Boolean(val),
	};
	emit("change-compliance-allowance", payload);
}

function getCheckedList(node: TCheckboxListItem) {
	if (props.useWholeCheckedList) {
		// Whole list, but needs to make sure that parent and children have all unique keys
		return props.checkedList;
	} else {
		const nodeChildrenIds = node.children?.map((child) => child.id);
		return props.checkedList.filter((cItem) => nodeChildrenIds.includes(cItem));
	}
}

function isExpandedChild(node: TCheckboxListItem) {
	return props.expandedItems.includes(node.id);
}
function onClickNode(item: TCheckboxListItem, forceAllow = false) {
	if (!props.canSelectWholeNode && !forceAllow) {
		return false;
	}
	if (!item.children?.length) {
		if (isDisabledItemList(item)) {
			return false;
		}
	} else if (props.useNewExpand) {
		emit("expand", { item, depth: props.depth });
		return false;
	}
	emit("click", { item });
}
function onCheckedItem(item) {
	emit("checked", item);
}
</script>
<template>
	<div
		class="checkbox-list-menu"
		:class="[
			{ root: depth === 0, vertical: isVerticalList },
			depthClasses[depth],
		]"
	>
		<div
			v-for="(node, index) in sortedList"
			:key="getNodeKey(node, index)"
			class="node"
			:class="{ expanded: isExpandedChild(node) }"
		>
			<div
				class="node-content"
				:class="{ changed: getChangedRow(node) }"
				@click="onClickNode(node)"
			>
				<div
					v-if="hasListAnyChildren"
					class="arrow-wrap"
				>
					<div
						v-if="
							(node.children && node.children.length) ||
							forceDisplayExpand >= depth
						"
						class="arrow"
						@click.stop="onClickNode(node, true)"
					>
						<i-fa4-chevron-down></i-fa4-chevron-down>
					</div>
				</div>

				<input
					v-if="hasCheckbox"
					type="checkbox"
					:checked="isNodeChecked(node)"
					:indeterminate.prop="isNodeIndeterminate(node)"
					:disabled="isDisabledItemList(node)"
					@click.stop="onInputCheckbox($event, node)"
				/>

				<span>{{ node.name }}</span>

				<!-- props is a named slot that allows passing custom content -->
				<slot
					name="right-side"
					:props="node"
					:depth="depth"
				>
					<div class="right-side">
						<tri-state-switch
							v-if="
								isSpecialKey === 'documents-locations' &&
								depth === 1 &&
								isNodeChecked(node)
							"
							theme="bulma"
							color="blue"
							:emitOnMount="false"
							:value="node.hasComplianceAllowance"
							@input="onChangeCompliance($event, node)"
							@click.stop
						/>
					</div>
				</slot>

				<span
					v-if="displayChildrenCount && node.children && node.children.length"
				>
					( {{ getCheckedList(node).length }} / {{ node.children.length }} )
				</span>
			</div>

			<transition name="slide-down">
				<div
					v-if="isExpandedChild(node) && node.children && node.children.length"
					class="children"
				>
					<checkbox-list-menu
						:original-checked-list="originalCheckedList"
						:changed-checked-list="changedCheckedList"
						:expanded-items="expandedItems"
						:disabled-items="disabledItems"
						:has-arrow="hasArrow"
						:is-solo-check-valid="isSoloCheckValid"
						:list="node.children"
						:checked-list="getCheckedList(node)"
						:is-disabled-list="isDisabledList"
						:is-vertical-list="isVerticalList"
						:depth="depth + 1"
						:display-children-count="displayChildrenCount"
						:depth-classes="depthClasses"
						:force-display-expand="forceDisplayExpand"
						:isSpecialKey="isSpecialKey"
						:shouldSortList="shouldSortList"
						v-bind="attrs"
						@checked="onCheckedItem"
						v-on="attrs"
					/>
				</div>
			</transition>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.checkbox-list-menu {
	$gray-element: rgba(10, 10, 10, 0.2);
	$red-element: rgba(238, 96, 124, 0.733);

	display: flex;

	&:not(.flex-row) {
		flex-direction: column;
	}

	.node {
		display: flex;
		flex-direction: column;
		padding: 0 1px 0 0; // Because of scroll overlapping

		.node-content {
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 0 10px;
			min-height: 30px;
			height: auto;
			width: 100%;
			min-width: 200px;
			user-select: none;
			z-index: 1;
			cursor: pointer;
			&:hover {
				background: color.adjust($color: #e0e0e0ce, $lightness: -5%);
			}

			.right-side {
				margin-left: auto;
			}

			&.changed {
				color: $blue;
				font-weight: bold;
			}

			.arrow-wrap {
				width: 10px;
				display: flex;

				> .arrow {
					display: flex;
					transform: rotate(-90deg);
					transition: transform 0.3s ease;
					filter: grayscale(1);
				}
			}
		}
		&.expanded {
			> .node-content {
				.arrow-wrap {
					.arrow {
						transform: rotate(0deg);
					}
				}
			}
		}

		&:nth-child(even) {
			.node-content {
				background: rgb(248, 248, 248);
				&:hover {
					background: color.adjust($color: #e0e0e0ce, $lightness: -5%);
				}
			}
		}

		.action {
			margin: 0 0 0 auto;
			display: flex;
			align-items: center;
			gap: 10px;

			.btn {
				width: 20px;
				height: 20px;
				display: flex;
				justify-content: center;
				align-items: center;

				.fa {
					color: color.adjust($color: $gray-element, $lightness: -10%);
				}

				&:hover {
					.fa {
						color: color.adjust($color: royalblue, $lightness: 10%);
					}
				}
			}

			.delete {
				margin-left: 5px;
				background: $gray-element;

				&:hover {
					background: $red-element;
				}
			}
		}

		&.active {
			background: color.adjust($color: #e0e0e0ce, $lightness: -10%);
		}

		&.hidden {
			background: rgba(219, 219, 219, 0.335);
			opacity: 0.4;
			color: rgb(39, 39, 39);
		}
	}

	&.flex-row {
		flex-direction: row;

		.node {
			min-width: 300px; // Columns are not resized when width over specified
		}
	}

	.children {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0 0 0 40px;
		border-left: 1px solid #e0e0e0;
		width: 100%;
	}

	&:not(.vertical) {
		.node {
			// min-height: 30px;
		}

		.children {
			overflow: auto;
		}

		&:not(.root) {
			flex-wrap: wrap;
			height: 100%;
			align-content: baseline;

			.node {
				overflow: auto;
			}
		}
	}
}
.slide-down-enter-active {
	transition: all 0.4s;
	z-index: 0;
}

.slide-down-leave-active {
	transition: none;
}
.slide-down-enter-from {
	transform: translateY(-30px);
	opacity: 0;
}
</style>
