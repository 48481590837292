<script setup lang="ts">
import api from "@/api";

import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const password = ref("");
const passwordConfirm = ref("");

const route = useRoute();
const hash = computed(() => route.params.hash);

const invalid_password = ref(true);
const show_general_error = ref(false);
const password_matches = computed(
	() => password.value === passwordConfirm.value,
);

function checkPassword() {
	show_general_error.value = passwordConfirm.value !== password.value;
}

const passwordResetSuccessfully = ref(false);

async function resetPassword() {
	try {
		await api.passwordRecovery({
			password: password.value,
			token: hash.value as string,
			email: route.query.email as string,
			password_confirmation: passwordConfirm.value,
		});
		passwordResetSuccessfully.value = true;
	} catch {}
}

function isValidPassword(param: {
	password: string;
	isPasswordValid: boolean;
}) {
	invalid_password.value = !param.isPasswordValid;
}
</script>

<template>
	<div class="signin">
		<div class="signin-container">
			<header>
				<div class="image-wrap">
					<img
						:src="store.getGlobalImage()"
						alt=""
					/>
				</div>
				<p>Type in your new password.</p>
			</header>
			<hr />
			<div>
				<section class="section-input">
					<template v-if="!passwordResetSuccessfully">
						<div class="control">
							<input
								v-model="password"
								class="large"
								placeholder="Password"
								type="password"
							/>
							<password-policy-validation
								:password-characters="password"
								@password-valid="isValidPassword"
							></password-policy-validation>
						</div>
						<div class="control">
							<input
								v-model="passwordConfirm"
								class="large"
								type="password"
								name="password"
								placeholder="Password again"
								autocomplete="off"
								@blur="checkPassword"
								@focus="show_general_error = false"
							/>
							<span
								v-if="
									show_general_error && !password_matches && !invalid_password
								"
								class="error"
							>
								Passwords do not match
							</span>
						</div>

						<div
							v-if="!passwordResetSuccessfully"
							class="right"
						>
							<router-link :to="{ name: 'auth.signin' }">
								Back to Login
							</router-link>
						</div>

						<p
							v-if="invalid_password || !password_matches"
							class="reset-explanation"
						>
							The "RESET PASSWORD" button will become available once the
							password criteria is met and the identical password is entered in
							both fields.
						</p>
						<button
							class="large"
							:disabled="invalid_password || !password_matches"
							@click="resetPassword"
						>
							RESET PASSWORD
						</button>
					</template>
					<template v-else>
						<p class="success">
							Password reset successfully, click on the button below to return
							to the login page
						</p>
						<router-link :to="{ name: 'auth.signin' }">
							<button class="large">BACK TO LOGIN</button>
						</router-link>
					</template>
				</section>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.signin {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60%;
}
.signin-container {
	$signin-color: #536278;

	box-shadow: 0 30px 33px 2px hsla(0deg, 0%, 67%, 0.3);
	background-color: $white;
	margin: 5em auto;
	padding: 12px 0 29px;
	border-radius: 5px;
	color: $signin-color;
	header {
		text-align: center;
		padding: 1rem;
	}
	.image-wrap {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 7px;
		img {
			width: 200px;
		}
	}
	.section-input {
		padding: 24px 29px 0;
		button {
			font-size: 1rem;
		}
	}
	.large {
		width: 100%;
	}
	.right {
		text-align: right;
		color: $green;
		margin-bottom: 4px;
		&:hover {
			color: $black;
		}
	}
	.control {
		margin-bottom: 0.75rem;
	}
	.success {
		display: block;
		margin-bottom: 25px;
		color: $success-color;
	}
	.reset-explanation {
		width: 300px;
		text-align: center;
		margin: 20px auto;
	}
	.error {
		color: $red;
	}
}
</style>
