<script setup lang="ts">
import { getAllFlatRec, getAllIdsRec } from "@/assets/js/helpers";

export type TCheckboxCompleteLabels = {
	selectAll: string;
	filter: string;
};

const props = withDefaults(
	defineProps<{
		data: {
			list: any[];
			checkedList: { id: number }[];
		};
		keyForTransform: "categories" | "locations" | "subcategories"; // Hardcoding until checked if it's ok, later can be string
		labels?: TCheckboxCompleteLabels;
		includeParentNodeSearch?: boolean;
		useWholeCheckedList?: boolean;
		shouldShow?: {
			header: boolean;
			selectAll: boolean;
			search: boolean;
		};
	}>(),
	{
		labels: () => ({
			selectAll: "Select all",
			filter: "Filter items",
		}),
		includeParentNodeSearch: false,
		useWholeCheckedList: false,
		shouldShow: () => ({
			header: true,
			selectAll: false,
			search: true,
		}),
	},
);

const expandedItems = ref<number[]>([]);
const search = ref("");
const searchRef = ref<HTMLElement | null>(null);
const selectAll = ref(false);

const itemList = computed(() => props.data.list);
const checkListFormatted = computed(() =>
	props.data.checkedList.map((el) => el.id),
);
const transformedList = computed(() => {
	const recFilter = (
		arr: any[] = [],
		includeParentNode = true,
		hideEmptyPar = false,
	) => {
		// eslint-disable-next-line unicorn/no-array-reduce
		return arr.reduce((acc, item) => {
			if (includeParentNode) {
				const payload = {
					...item,
					children: item[props.keyForTransform],
					[props.keyForTransform]: undefined,
				};
				acc.push(payload);
			} else if (item[props.keyForTransform]?.length) {
				const filteredItems = recFilter(item[props.keyForTransform]);
				if (filteredItems.length || (!hideEmptyPar && !filteredItems.length)) {
					const newItem = {
						...item,
						id: "mykey-" + item.id,
						children: filteredItems,
						[props.keyForTransform]: undefined,
					};
					acc.push(newItem);
				}
			}
			return acc;
		}, []);
	};

	return recFilter(itemList.value, props.includeParentNodeSearch, true);
});
const filteredList = computed(() => {
	const searchLower = search.value.trim().toLowerCase();

	const recFilter = (
		arr: any[] = [],
		includeParentNode = true,
		hideEmptyPar = false,
	) => {
		// eslint-disable-next-line unicorn/no-array-reduce
		return arr.reduce((acc, item) => {
			if (includeParentNode) {
				if (item.name.toLowerCase().includes(searchLower)) {
					const payload = {
						...item,
						children: item[props.keyForTransform],
						[props.keyForTransform]: undefined,
					};
					acc.push(payload);
				}
			} else if (item[props.keyForTransform]?.length) {
				const filteredItems = recFilter(item[props.keyForTransform]);
				if (filteredItems.length || (!hideEmptyPar && !filteredItems.length)) {
					const newItem = {
						...item,
						id: "mykey-" + item.id,
						children: filteredItems,
						[props.keyForTransform]: undefined,
					};
					acc.push(newItem);
				}
			}
			return acc;
		}, []);
	};

	return recFilter(itemList.value, false, true);
});

function onClickedItem(evt: any) {
	const item = evt.item;
	if (item?.children?.length) {
		const itemIndex = expandedItems.value.indexOf(item.id);
		if (itemIndex === -1) {
			expandedItems.value.push(item.id);
		} else {
			expandedItems.value.splice(itemIndex, 1);
		}
	} else {
		onCheckedItem({
			item,
		});
	}
}
function onCheckedItem(evt: any) {
	const isItemChecked = props.data.checkedList.includes(evt.item.id);
	let childrenIds: number[] = [];
	const children = evt.item.children;
	if (children?.length) {
		childrenIds = getAllIdsRec(children as { id: number }[]);
	}
	const isAllChildrensChecked = childrenIds.every((childId) =>
		props.data.checkedList.map((item) => item.id).includes(childId),
	);

	const checkItemAndChildren = () => {
		const selSet = new Set(props.data.checkedList.map((item) => item.id));

		if (!childrenIds.length) {
			// NOTE: Don't select parents
			selSet.add(evt.item.id);
		}

		for (const id of childrenIds) {
			selSet.add(id);
		}
		const uniqueIds = Array.from(selSet);
		props.data.checkedList = uniqueIds.map((id) => ({ id: id }));
	};

	const uncheckItemAndChildren = () => {
		props.data.checkedList = props.data.checkedList.filter(
			(cItem) => cItem.id !== evt.item.id && !childrenIds.includes(cItem.id),
		);
	};

	if (evt.checked) {
		checkItemAndChildren();
	} else if (evt.checked === false) {
		uncheckItemAndChildren();
	} else if (isItemChecked) {
		uncheckItemAndChildren();
	} else {
		if (children?.length && !isAllChildrensChecked) {
			checkItemAndChildren();
		} else if (!children?.length) {
			checkItemAndChildren();
		}
	}
	selectAll.value = false;
}

function onSelectAll(evt: any) {
	selectAll.value = (evt.target as HTMLInputElement).checked;
	props.data.checkedList = selectAll.value
		? getAllFlatRec(transformedList.value)
		: [];
}
</script>
<template>
	<div class="checkbox-complete">
		<div
			v-if="shouldShow.header"
			class="header"
		>
			<div class="left">
				<label v-if="shouldShow.selectAll">
					<input
						type="checkbox"
						:checked="selectAll"
						@click="onSelectAll"
					/>
					<span>{{ labels.selectAll }}</span>
					<span v-if="checkListFormatted.length">
						<span>({{ checkListFormatted.length }} Checked)</span>
					</span>
				</label>
			</div>
			<input
				v-if="shouldShow.search"
				ref="searchRef"
				v-model="search"
				class="input wd-100-p"
				:placeholder="labels.filter"
			/>
		</div>
		<checkbox-list-menu
			:list="filteredList"
			:checkedList="checkListFormatted"
			:expandedItems="expandedItems"
			:shouldSortList="false"
			:useWholeCheckedList="useWholeCheckedList"
			@click="onClickedItem"
			@checked="onCheckedItem"
		></checkbox-list-menu>
	</div>
</template>

<style lang="scss" scoped>
.checkbox-complete {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
</style>
