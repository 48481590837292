<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors.js";
import apiTs from "@/api/index";

const releasedAnnualLeaveMessage = ref<string>("");
const releasedAnnualLeaveErrors = ref<string | string[]>("");
const releaseDate = ref<string>("");

const emit = defineEmits(["close-modal", "confirm", "fetch-invoices"]);

const props = withDefaults(
	defineProps<{
		confirmBtnTxt?: string;
		cancelBtnTxt?: string;
	}>(),
	{
		confirmBtnTxt: "Release",
		cancelBtnTxt: "Cancel",
	},
);

async function onClickConfirm() {
	try {
		const res = await apiTs.releaseAnnualLeave({ date: releaseDate.value });
		releasedAnnualLeaveMessage.value =
			res?.data?.data ?? "Annual leave successfully released.";
	} catch (err) {
		releasedAnnualLeaveErrors.value = parseErrors(err);
	}
	emit("fetch-invoices");
}
function onCloseModal() {
	emit("close-modal");
}
function confirmRelease() {
	emit("close-modal");
}
function resetData(): void {
	releasedAnnualLeaveMessage.value = "";
	releasedAnnualLeaveErrors.value = "";
	releaseDate.value = "";
}
</script>

<template>
	<div class="confirm-modal">
		<div v-if="releasedAnnualLeaveErrors.length === 0">
			<div
				v-if="releasedAnnualLeaveMessage.length === 0"
				class="content"
			>
				<div>Date</div>
				<div class="control">
					<modern-date-picker
						v-model="releaseDate"
						:enable-time-picker="false"
						:format="'dd/MM/yyyy'"
						style="width: 150px"
						placeholder="Date"
					/>
				</div>
			</div>
			<div
				v-if="releasedAnnualLeaveMessage.length === 0"
				class="wrapp-buttons"
			>
				<button
					class="alt"
					@click="onCloseModal()"
				>
					{{ props.cancelBtnTxt }}
				</button>
				<button
					:class="{ 'not-allowed': !releaseDate }"
					:disabled="!releaseDate"
					@click="onClickConfirm()"
				>
					{{ props.confirmBtnTxt }}
				</button>
			</div>

			<div v-else>
				<div class="content">
					{{ releasedAnnualLeaveMessage }}
				</div>
				<div class="wrapp-buttons">
					<button @click="confirmRelease">Okay</button>
				</div>
			</div>
		</div>
		<div v-else>
			<div>
				<div
					v-if="Array.isArray(releasedAnnualLeaveErrors)"
					class="flex-column-center error-text"
				>
					<p
						v-for="(err, idx) in releasedAnnualLeaveErrors"
						:key="idx"
					>
						{{ err }}
					</p>
				</div>
				<div
					v-else
					class="flex-column-center error-text"
				>
					{{ releasedAnnualLeaveErrors }}
				</div>
				<div class="wrapp-buttons">
					<button @click="resetData">Okay</button>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	min-height: 130px;
	gap: 10px;
	button {
		outline: none !important;
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		align-items: center;
		justify-content: center;
		gap: 10px;
	}
	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		> div {
			margin: 5px;
		}
	}
}
</style>
