export const RATE_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Use Global rates",
	},
	{
		id: 2,
		name: "Specific - copy from",
		// name: 'Client specific rate'
	},
	{
		id: 3,
		name: "Specific - blank",
		altName: "Specific",
	},
];
