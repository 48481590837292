import { baseURL } from "@/api/config";
import { type RemovableRef, useStorage } from "@vueuse/core";
import { type Ref, type UnwrapRef } from "vue";
import dayjs from "dayjs";
import type { TGenderValue, TIncrement, TShiftStatuses } from "./globalTypes";
import { DateInstance } from "./dateHelper";
import type { TTableColumn } from "@/components/shared/table/ModernTable.vue";
import { useSessionStore } from "@/store/session";

// #region Bounce / Throttle
export function debounce(func, waitTimer, immediate) {
	let timeout = null;
	return function () {
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;

		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}
// #endregion

// #region Simple crypt / decrypt
export function simpleCrypt(salt: string, text: string) {
	const textToChars = (text: string) =>
		text.split("").map((c) => c.codePointAt(0));
	const byteHex = (n: any) => ("0" + Number(n).toString(16)).slice(-2);
	const applySaltToChar = (code: any) =>
		textToChars(salt).reduce((a: any, b: any) => a ^ b, code);

	return text
		.split("")
		.map((element) => textToChars(element))
		.map((element) => applySaltToChar(element))
		.map((element) => byteHex(element))
		.join("");
}
export function simpleDecrypt(salt: string, encoded: string) {
	const textToChars = (text: any) =>
		text.split("").map((c: any) => c.codePointAt(0));
	const applySaltToChar = (code: any) =>
		textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
	return encoded
		.match(/.{1,2}/g)
		.map((hex) => parseInt(hex, 16))
		.map((element) => applySaltToChar(element))
		.map((charCode) => String.fromCodePoint(charCode))
		.join("");
}
// #endregion

export function focusFirstElement(
	parentEl: HTMLElement,
	isFocus = true,
	isDebug = false,
) {
	// Don't invoke more than once (Before a component is destroyed)
	if (parentEl) {
		const inputListToIncludeFocusable = [
			"input:not(:disabled):not(.hidden)",
			"textarea:not(:disabled):not(.hidden)",
			"button:not(:disabled):not(.hidden)",
			"span.focusable",
			"div.focusable",
			".multiselect[tabindex]",
			"*[tabindex]:not(:disabled):not(.hidden)",
		].join(",");

		const nodeList = Array.from(
			parentEl.querySelectorAll(inputListToIncludeFocusable),
		) as HTMLElement[];
		if (nodeList?.length) {
			const addInputTabHandling = (nodeList: HTMLElement[]) => {
				const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
					// Only for first / last element
					oppoEl.focus();
					evt.preventDefault();
				};

				// First el
				nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && evt.shiftKey) {
						focusEl(evt, nodeList.at(-1) as any);
					}
				});

				// Last el
				(nodeList.at(-1) as any).addEventListener(
					"keydown",
					(evt: KeyboardEvent) => {
						if (evt.key === "Tab" && !evt.shiftKey) {
							focusEl(evt, nodeList[0]);
						}
					},
				);
			};

			if (isFocus) {
				// console.log("➕ Focusing first el", nodeList[0]);
				nodeList[0].focus();

				if (isDebug) {
					console.error("➕ Focusing first el", nodeList[0]);
				}
			}

			addInputTabHandling(nodeList);
		} else if (isDebug) {
			console.warn("No child element found for focus");
		}
	} else if (isDebug) {
		console.warn("No parent element found for focus");
	}
}

export function getCurrentDomain() {
	let url = new URL(location.href);
	try {
		url = new URL(baseURL);
	} catch {
		// ignored - could break on prod because link is not valid
	}
	return url;
}
function getUseStorage<T>(
	key: string,
	shouldParse = false,
	storage: Storage,
	defaultVal: T | null = null, // Undefined is broken (creates invalid value in store)
): Ref<T | UnwrapRef<T> | string | null | undefined> {
	const state = useStorage(key, defaultVal, storage);
	if (!shouldParse) return state;

	try {
		return ref(JSON.parse(state.value as string));
	} catch {
		console.error("Error loading key", key);
		return ref(defaultVal);
	}
}

export function getLocalStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	return getUseStorage(key, shouldParse, localStorage, defaultVal);
}

export function getSessionStorageReac<T>(
	key: string,
	shouldParse = false,
	defaultVal?: T,
) {
	return getUseStorage(key, shouldParse, sessionStorage, defaultVal);
}

export function setLocalStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193

	const state = useStorage(key, null, localStorage);
	state.value = tempValue;

	if (localStorage[key] !== tempValue) {
		localStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function setSessionStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193

	const state = useStorage(key, null, sessionStorage);
	state.value = tempValue;

	if (sessionStorage[key] !== tempValue) {
		sessionStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export const InfoConsole = {
	/**
	 * Console log with colors
	 */
	l(msg: string, ...payload: any[]) {
		console.log(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console warn with colors
	 */
	w(msg: string, ...payload: any[]) {
		console.warn(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console error with colors
	 */
	e(msg: string, ...payload: any[]) {
		console.error(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
};

export function downloadFile(
	content: string,
	name: string,
	encoding: string,
): void;
export function downloadFile(content: Blob, name: string): void;
export function downloadFile(
	content: string | Blob,
	name: string,
	encoding?: string,
): void {
	const blob: Blob =
		typeof content === "string"
			? new Blob([content], { type: encoding })
			: content;
	const encodedUri = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", name);
	document.body.append(link);
	link.click();
	link.remove();
}

export function handleFileSize(size: number, base = 1000) {
	if (size > base ** 3) {
		return (size / base ** 3).toFixed(2) + "gb";
	}
	if (size > base ** 2) {
		return (size / base ** 2).toFixed(2) + "mb";
	}
	if (size > base) {
		return (size / base).toFixed(2) + "kb";
	}
	if (size < base) {
		return size.toFixed(2) + "b";
	}
	return size;
}

export function validateEmail(email: string) {
	return /[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?/g.test(
		email,
	);
}
/**
 *  packLaravelArray
 *
 *  Packs the array of objects for queryString best suited to Laravel validation,
 *  enumerating each of it's keys into an array.
 *  [{id: 1}, {id: 2}, {id: 3}] => name[][id]=1&name[][id]=2&name[][id]=3
 *  NOTE: DOES NOT PROCESS NESTING.
 */

export function packLaravelArray(name: string, objectArr: any) {
	const len = objectArr.length;
	if (len === 0) {
		return "";
	}
	let packedString = "";
	let packedSomething = false;
	for (let i = 0; i < len; i++) {
		const obj = objectArr[i];
		for (const key in obj) {
			if (typeof obj[key] === "function") {
				// Not serializable
				continue;
			}
			if (packedSomething === true) {
				packedString = packedString + "&";
			}
			packedString = packedString + (name + "[]" + "[" + key + "]=" + obj[key]);

			if (packedSomething === false) {
				packedSomething = true;
			}
		}
	}
	return packedString;
}

export function generateLaravelUrl(params: any, laravelArr: any, url: any) {
	let tempUrl = url;
	for (const larKey of laravelArr) {
		if (params[larKey]) {
			const packed = packLaravelArray(larKey, params[larKey]);
			if (packed !== "") {
				// If first
				tempUrl = tempUrl.includes("?")
					? tempUrl + "&" + packed
					: tempUrl + "?" + packed;
			}
			// Has to be called (delete) so it doesn't send duplicate params
			delete params[larKey];
		}
	}
	return tempUrl;
}

// #region _.omit

/**
 * this is to replace the lowdash omit function
 *
 * function creates a new object with no keys passed
 *
 * const newPerson = omit(person, ['name']);
 */

// export const omit = (obj, keys) =>
// 	Object.keys(obj).reduce((acc, key) => {
// 		if (!keys.includes(key)) {
// 			acc[key] = obj[key];
// 		}
// 		return acc;
// 	}, {});

export const omit = (obj: Record<string, any>, keys: string[]) => {
	const result: Record<string, any> = {};

	for (const key in obj) {
		if (obj.hasOwnProperty(key) && !keys.includes(key)) {
			result[key] = obj[key];
		}
	}

	return result;
};

// #ednregion
/**
 * getFormattedTime
 *
 * @param time $time Time from API.
 * @param outfmt $outfmt Desired output format for passed in datetime.
 * @param inputfmt='YYYY-MM-DD HH:mm:ss' API expected input format.
 * @access public
 * @return Formatted datetime in desired format.
 */
export function getFormattedTime(time, outfmt, infmt = "YYYY-MM-DD HH:mm:ss") {
	const m = dayjs(time, infmt);
	return m.isValid() ? m.format(outfmt) : null;
}
/**
 * flatten
 *
 * @param arr $arr nested arrays, any level
 * @access public
 * @return flatten array
 */
// export function flatten(arr: any[]) {
// 	return arr.reduce(function (flat, toFlatten) {
// 		return flat.concat(
// 			Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
// 		);
// 	}, []);
// }
// export function flatten(arr: any[]): any[] {
// 	const flat: any[] = [];

// 	for (const item of arr) {
// 		if (Array.isArray(item)) {
// 			flat.push(...flatten(item));
// 		} else {
// 			flat.push(item);
// 		}
// 	}

// 	return flat;
// }

export function downloadCsvFile(
	file: Blob,
	type: any,
	name: string = "file.csv",
): void {
	const blob = new Blob([file], {
		type: type,
	});
	const encodedUri = window.URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", name);
	document.body.append(link);
	link.click();
	link.remove();
}

export function formatDateForZipName(date: Date) {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
	const year = date.getFullYear();
	return `${day}-${month}-${year}`;
}

export function downloadExcelFile(
	file: Blob,
	type: any,
	name: string = "file.xlsx",
): void {
	const blob = new Blob([file], {
		type: type,
	});
	const encodedUri = window.URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", name);
	document.body.append(link);
	link.click();
	link.remove();
}

export function filterExpenseTypes(expenseTypes: any): any[] {
	let filteredExpenseTypes = [];
	if (expenseTypes.upt_selectable === 1) {
		filteredExpenseTypes.push(expenseTypes);
	}
	for (const child of expenseTypes.children) {
		filteredExpenseTypes = filteredExpenseTypes.concat(
			filterExpenseTypes(child),
		);
	}
	return filteredExpenseTypes;
}

export function parseDateTime(
	dateStr: string,
	dateSeparator: string | null = "-",
	includeSeconds: boolean | null = false,
): string {
	/**
	 * if dateStr length is === 10, date only is sent e.g. 2024-10-10
	 * else date time is sent e.g. 2024-10-10 03:03:03
	 */
	const date =
		dateStr.length === 10 ? new Date(`${dateStr}T00:00:00`) : new Date(dateStr);
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
	const year = date.getFullYear();
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	const seconds = `:${String(date.getSeconds()).padStart(2, "0")}`;
	return `${day}${dateSeparator}${month}${dateSeparator}${year} ${hours}:${minutes}${includeSeconds ? seconds : ""}`;
}

export function parseDate(
	dateStr: string,
	dateSeparator: string | null = "-",
): string {
	let date: Date;

	// Check if the string is in the format "DD-MM-YYYY"
	const customFormat = /^(\d{2})-(\d{2})-(\d{4})$/;
	if (customFormat.test(dateStr)) {
		const [day, month, year] = dateStr.split("-").map(Number);

		// Months are 0-based in JavaScript Date (0 = January, 11 = December)
		date = new Date(year, month - 1, day);
	} else {
		// Fall back to default Date parsing
		date = new Date(dateStr);
	}
	// Check if the date is valid
	if (isNaN(date.getTime())) return "";

	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}${dateSeparator}${month}${dateSeparator}${year}`;
}

export function parseTime(dateStr: string): string {
	if (dateStr.includes(" ")) {
		dateStr = dateStr.replace(" ", "T");
	}
	const date: Date = new Date(dateStr);
	if (isNaN(date.getTime())) return "";
	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");
	return `${hours}:${minutes}`;
}

export function parseExpenseValue(val: number, monetarySymbol: string) {
	const shouldAddDecimals = val % 1 !== 0;
	const formatedVal = shouldAddDecimals ? val.toFixed(2) : val;
	return `${monetarySymbol} ${formatedVal || 0}`;
}

export function filterArray(
	items: any[],
	search: string,
	childKey: string,
): any[] {
	const filtered: any[] = [];
	for (const item of items) {
		const matches = item.name.toLowerCase().includes(search.toLowerCase());
		// recursively filter children if present
		const children = item[childKey]
			? filterArray(item[childKey], search, childKey)
			: [];
		// if the item matches or has matching children, include it in the result
		if (matches || children.length > 0) {
			filtered.push({
				...item,
				[childKey]: children.length > 0 ? children : item[childKey], // logic is if there aren't any children that are checked display all children
			});
		}
	}
	return filtered;
}

export function flatten(arr: any[]): any[] {
	const res: any[] = [];
	for (const el of arr) {
		if (Array.isArray(el)) {
			res.push(...flatten(el));
		} else {
			res.push(el);
		}
	}
	return res;
}

type TArrayObject = {
	id?: number;
	name: string;
};

export function getStringOfNamesFromArrayOfObjects(
	arr: TArrayObject[],
): string {
	const namesArray: string[] = [];
	if (arr && Array.isArray(arr) && arr.length > 0) {
		arr.map((el) => {
			namesArray.push(el.name);
		});
	}
	return namesArray.length > 0 ? namesArray.join(", ") : "";
}

interface ISector {
	name: string;
	selectable?: boolean;
	subsectors?: ISector[];
	sectorParent?: { name: string };
}

interface IState {
	sectors: ISector[];
}

export function getSectors(state: IState) {
	const traverseSubs = (sectors: ISector[]): ISector[] => {
		const acc: ISector[] = [];
		for (const curr of sectors) {
			if (curr.selectable) {
				acc.push(curr);
			} else if (curr.subsectors) {
				const subSec = traverseSubs(curr.subsectors);
				acc.push(...subSec);
			}
		}
		return acc;
	};

	const createSectorParents = (subs: ISector[]): string[] => {
		const acc: string[] = [];
		for (const curr of subs) {
			const parentName = curr.sectorParent?.name;
			if (parentName && !acc.includes(parentName)) {
				acc.push(parentName);
			}
		}
		return acc;
	};

	const getSubsWithParent = (
		parents: string[],
	): { name: string; subs: ISector[] }[] => {
		const allSubs: { name: string; subs: ISector[] }[] = [];
		for (const parentName of parents) {
			const genSubs: ISector[] = [];
			for (const sub of subs) {
				if (sub.sectorParent?.name === parentName) {
					genSubs.push(sub);
				}
			}
			allSubs.push({ name: parentName, subs: genSubs });
		}
		return allSubs;
	};

	// Main function logic
	const subs = traverseSubs(state.sectors);
	const parentSubs = createSectorParents(subs);
	return getSubsWithParent(parentSubs);
}

export const pickNonFalsyKeepZero = (objParams, allowedNullableKeys = []) => {
	// Check if the key is allowed to have nullish or empty values
	return Object.fromEntries(
		Object.entries(objParams).filter(([key, value]) => {
			// Check if the key is allowed to have nullish or empty values
			if (allowedNullableKeys.includes(key)) {
				return true;
			}
			// Exclude keys with undefined, null, or empty string values
			return value !== undefined && value !== null && value !== "";
		}),
	);
};
// OLD
//   const pickNonFalsyKeepZero = (objParams, allowedNullableKeys = []) => {
// 	return _.omit(objParams, (v, key) => {
// 	  if (!allowedNullableKeys.includes(key)) {
// 		return _.isUndefined(v) || _.isNull(v) || v === "";
// 	  }
// 	});
//   };

/**
 * findPos
 *
 * FInds y value of given object
 */
export const findPos = (obj: any) => {
	let curtop = 0;
	if (obj.offsetParent) {
		do {
			curtop += obj.offsetTop;
		} while (obj === obj.offsetParent);
		console.log(curtop);
		return [curtop];
	}
};

export function commaSeparated(values: any[]) {
	return values.map((e) => e.name).join(", ");
}

export function getHoursAndMinutesFromString(timeString: string) {
	console.log(timeString);
	const timeParts = timeString.split(":");
	if (timeParts.length !== 2) {
		throw new Error("Time is not in valid format");
	}
	const hours = parseInt(timeParts[0]);
	const minutes = parseInt(timeParts[1]);
	if (isNaN(hours) || isNaN(minutes)) {
		throw new TypeError("Time is not in valid format");
	}
	return {
		hours,
		minutes,
	};
}

export function getDatesBetween(startDate, endDate, transformToDate = false) {
	console.log(startDate, "startDate");

	// Clone startDate to avoid modifying the original one
	const now = new Date(startDate);
	const dates = [];

	// Loop through each day until we reach the endDate
	while (now <= endDate) {
		// Push the date or the transformed date (if required)
		dates.push(transformToDate ? new Date(now) : now);

		// Move to the next day
		now.setDate(now.getDate() + 1);
	}

	return dates;
}
// function getDatesBetween(startDate, endDate, transformToDate = false) {
// 	console.log(startDate, "startDate");

// 	let now = startDate.clone();
// 	let dates = [];

// 	while (now.isBefore(endDate) || now.isSame(endDate)) {
// 		dates.push(transformToDate ? now.toDate() : now);
// 		now.add(1, "days");
// 	}
// 	return dates;
// }

export function convertTimeObjectInString(date: any) {
	// { "hours": 20, "minutes": 49, "seconds": 0 }
	if (
		typeof date === "object" &&
		date !== null &&
		date.hasOwnProperty("hours")
	) {
		const help =
			(date.hours.toString().length <= 1 ? "0" + date.hours : date.hours) +
			":" +
			(date.minutes.toString().length <= 1 ? "0" + date.minutes : date.minutes);
		return help;
	}
	return date;
}

export function arrayGroupBy<T>(arr: T[], key: string): Record<string, T[]> {
	const grouped: Record<string, T[]> = {};

	const parsedKeys = key.split(".");
	const getNestedValue = (obj: T, keys: string[]) => {
		const res = obj[keys[0]];
		if (res === undefined) {
			throw new Error('Key "' + keys[0] + '" not found in object');
		}
		if (res === null) {
			throw new Error('Key "' + keys[0] + '" is null');
		}
		if (keys.length === 1) {
			return res;
		}
		return getNestedValue(res, keys.slice(1));
	};

	for (const item of arr) {
		const group = getNestedValue(item, parsedKeys);
		if (!grouped[group]) {
			grouped[group] = [];
		}
		grouped[group].push(item);
	}
	return grouped;
}

export function getActiveInrementFromIncrementArray(
	increments: TIncrement[],
): TIncrement | null {
	let newestActiveIncrement: TIncrement;
	let newestIncrement: TIncrement;
	for (const el of increments) {
		if (el.deletedAt) {
			continue;
		}
		if (!newestActiveIncrement) {
			newestActiveIncrement = el;
			newestIncrement = el;
			continue;
		}
		const increment_start_date = new DateInstance(el.startDate);
		if (
			increment_start_date.isAfter(newestActiveIncrement.startDate) &&
			!increment_start_date.isAfter(new DateInstance().formatDate("YYYY-MM-DD"))
		) {
			newestActiveIncrement = el;
		}
		if (
			increment_start_date.isAfter(newestActiveIncrement.startDate) &&
			increment_start_date.isAfter(new DateInstance().formatDate("YYYY-MM-DD"))
		) {
			newestIncrement = el;
		}
	}

	// if (
	// 	newestActiveIncrement &&
	// 	DateInstance.getDateDiff(newestActiveIncrement.startDate) < 0
	// ) {
	// 	return null;
	// }

	return newestActiveIncrement || newestIncrement || null;
}

// export const GENDER_MAP = [
//   { key: "M", label: "Male" },
//   { key: "F", label: "Female" },
//   { key: "O", label: "Other" },
//   { key: "U", label: "Prefer not to say" },
//   { key: "N", label: "Non-Binary" },
// ] as const;

export const GENDER_MAP = {
	M: "Male",
	F: "Female",
	O: "Other",
	U: "Prefer not to say",
	N: "Non-Binary",
} as const;

export type TGender = keyof typeof GENDER_MAP;

export const GENDER_MAP_DROPDOWN_ARRAY: {
	label: TGenderValue;
	value: TGender;
}[] = Object.keys(GENDER_MAP).map((key: TGender) => {
	return { label: GENDER_MAP[key], value: key };
});
export function prepareGetQueryLaravelArrayParams(params: any) {
	const preparedParams: string[] = [];
	if (params?.sectors?.length) {
		params.sectors.map((id: number) =>
			preparedParams.push(`sectors[][id]=${id}`),
		);
	}
	if (params?.clients?.length) {
		params.clients.map((id: number) =>
			preparedParams.push(`clients[][id]=${id}`),
		);
	}
	if (params?.categories?.length) {
		params.categories.map((id: number) => {
			preparedParams.push(`categories[][id]=${id}`);
		});
	}
	if (params?.subcategories?.length) {
		params.subcategories.map((id: number) => {
			preparedParams.push(`subcategories[][id]=${id}`);
		});
	}
	if (params?.temps?.length) {
		params.temps.map((id: number) => {
			preparedParams.push(`temps[][id]=${id}`);
		});
	}
	if (params?.statuses?.length) {
		params.statuses.map((id: number) => {
			if (id === 0) return;
			preparedParams.push(`status=${id}`);
		});
	}
	return preparedParams.join("&");
}

export function removeLastOccurrence(
	mainStr: string,
	searchStr: string,
): string {
	const lastIndex: number = mainStr.lastIndexOf(searchStr);
	if (lastIndex === -1) {
		return mainStr;
	}
	return (
		mainStr.slice(0, lastIndex) + mainStr.slice(lastIndex + searchStr.length)
	);
}

export function capitalizeFirstWord(input: string): string {
	if (!input) return ""; // handle empty string
	input = input.toLowerCase(); // change to lower case
	return input.charAt(0).toUpperCase() + input.slice(1);
}

export const STATUS_COLORS: {
	status: number;
	label: string;
	hex: string;
	code: string;
}[] = [
	{ status: 1, label: "Not Uploaded", hex: "#DBE1E5", code: "NOUP" },
	{ status: 2, label: "Pending Validation", hex: "#00C6FF", code: "PEND" },
	{ status: 3, label: "Approved", hex: "#38B83F", code: "APPR" },
	{ status: 4, label: "Rejected", hex: "#FF0003", code: "REJE" },
	{ status: 5, label: "Near Expiry", hex: "#E8E90D", code: "NEXP" },
	{ status: 6, label: "Expired", hex: "#FFAD0E", code: "EXPI" },
];
export function stringContains(
	mainString: string | string[] | null | undefined,
	searchString: string | null | undefined,
): boolean {
	if (!mainString || !searchString) return false;
	// if main string is an array of strings return true
	return Array.isArray(mainString) ? true : mainString.includes(searchString);
}

export const TEMP_DOCUMENT_FILES_HEADERS: TTableColumn[] = [
	{ id: "name", label: "Name" },
	{ id: "size", label: "Size" },
	{ id: "uploadDate", label: "Upload Date" },
	{ id: "expiryDate", label: "Expiry Date" },
	{ id: "actions", label: "Actions" },
];

export function getBasePage() {
	const sessionStore = useSessionStore();
	if (sessionStore.can("view-dashboard")) {
		return { name: "admin.dashboard" };
	}
	return { name: "temp.calendar" };
}

export function truncateString(value: string, maxLength: number = 57): string {
	if (value.length > maxLength) {
		return value.slice(0, Math.max(0, maxLength)) + "...";
	}
	return value;
}

export function handlePaste(evt: ClipboardEvent): string | null {
	// Access clipboardData from the event
	const clipboardData = evt.clipboardData;
	if (clipboardData) {
		// Get the pasted text data
		return clipboardData.getData("text");
	}
	// Return null if clipboardData is unavailable
	return null;
}

export function getObjectWithOmittableKeys(
	obj: any,
	omittableKeys: string[],
): any {
	const res = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && !omittableKeys.includes(key)) {
			res[key] = obj[key];
		}
	}
	return res;
}

export function getAllIdsRec<T extends { id?: number; children?: T[] }>(
	arr: T[] = [],
): T["id"][] {
	// eslint-disable-next-line unicorn/no-array-reduce
	return arr.reduce<T["id"][]>((acc, curr) => {
		if (typeof curr.id === "number") {
			acc.push(curr.id);
		}
		if (curr.children?.length) {
			const ids = getAllIdsRec(curr.children);
			acc.push(...ids);
		}
		return acc;
	}, []);
}

export function getAllFlatRec<T extends { id?: number; children?: T[] }>(
	arr: T[] = [],
): T[] {
	// eslint-disable-next-line unicorn/no-array-reduce
	return arr.reduce<T[]>((acc, curr) => {
		if (typeof curr.id === "number") {
			acc.push(curr);
		}
		if (curr.children?.length) {
			const items = getAllFlatRec(curr.children);
			acc.push(...items);
		}
		return acc;
	}, []);
}

export function generateUniqueId(
	prefix: string = "id",
	length: number = 10,
): string {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let uniqueId = `${prefix}_`;

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		uniqueId += characters[randomIndex];
	}

	return uniqueId;
}

export function validateInteger(value: any, greaterThanZero = true): boolean {
	return (
		value === 0 ||
		(value &&
			typeof value !== "object" &&
			Number.isInteger(Number(value)) &&
			(value >= 0 || (!greaterThanZero && value < 0)))
	);
}

export type TSortOrder = "asc" | "desc";

export function sortArrayByKey<T>(
	array: T[],
	key: keyof T,
	order: TSortOrder = "asc",
): T[] {
	return array.sort((a, b) => {
		const valueA = a[key];
		const valueB = b[key];

		if (typeof valueA === "string" && typeof valueB === "string") {
			// String comparison (case-insensitive)
			return order === "asc"
				? valueA.localeCompare(valueB)
				: valueB.localeCompare(valueA);
		} else if (typeof valueA === "number" && typeof valueB === "number") {
			// Number comparison
			return order === "asc" ? valueA - valueB : valueB - valueA;
		} else {
			return 0; // If types don't match or are not comparable
		}
	});
}

export function isValidNumberInput(
	value: string | number,
	minValue: number = 0,
): boolean {
	if (value === "" || isNaN(Number(value))) {
		return false;
	}
	return Number(value) >= minValue;
}

export function removeSubstring(input: string, substring: string): string {
	return input.includes(substring) ? input.replace(substring, "") : input;
}

export const SHIFT_STATUSES: TShiftStatuses = {
	pending: "1",
	active: "2",
	completed: "3",
	never_filled: "4",
	cancelled: "5",
};

export function convertToggleApiDateLocal(
	d: string | null | undefined,
): string {
	if (!d) {
		return "";
	}
	const ddmmyyyyRegex = /^(?:\d{2}\/){2}\d{4}$/;
	if (ddmmyyyyRegex.test(d)) {
		const [day, month, year] = d.split("/").map(Number);
		if (day >= 1 && day <= 31 && month >= 1 && month <= 12) {
			return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
		}
	}
	const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/;
	if (yyyymmddRegex.test(d)) {
		const [year, month, day] = d.split("-").map(Number);
		if (day >= 1 && day <= 31 && month >= 1 && month <= 12) {
			return `${String(day).padStart(2, "0")}/${String(month).padStart(2, "0")}/${year}`;
		}
	}
	return d;
}

export function convertApiTime(d: string | null | undefined): string | null {
	if (!d) {
		return null;
	}

	// Regex patterns for different formats
	const dateTimeRegex = /^(?:\d{2}\/){2}\d{4} \d{2}:\d{2}$/; // DD/MM/YYYY HH:mm
	const dateRegex = /^(?:\d{2}\/){2}\d{4}$/; // DD/MM/YYYY
	const timeRegex = /^\d{2}:\d{2}$/; // HH:mm

	let result = "";

	if (dateTimeRegex.test(d)) {
		// Convert DD/MM/YYYY HH:mm to YYYY-MM-DD HH:mm:ss
		const [datePart, timePart] = d.split(" ");
		const [day, month, year] = datePart.split("/").map(Number);
		result = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")} ${timePart}:00`;
	} else if (dateRegex.test(d)) {
		// Convert DD/MM/YYYY to YYYY-MM-DD
		const [day, month, year] = d.split("/").map(Number);
		result = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
	} else if (timeRegex.test(d)) {
		// Convert HH:mm to HH:mm:ss
		result = `${d}:00`;
	}

	return result || null;
}

export interface IDateTimeOption {
	type: "min" | "max"; // If there are other valid values, add them here
	week: string[]; // Array of strings for week names
	month: string[]; // Array of strings for month names
	format: string; // Format for the date-time display
	placeholder: string; // Placeholder text
	inputStyle: Record<string, string>; // CSS styles as key-value pairs
	color: {
		header: string;
		headerText: string;
	};
	buttons: {
		ok: string;
		cancel: string;
	};
	overlayOpacity: number; // Opacity for the overlay
	dismissible: boolean; // Whether the overlay can be dismissed
}

export const calendarOptions = {
	dateTimeOption: {
		type: "min",
		week: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
		month: [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		],
		format: "DD/MM/YYYY HH:mm",
		placeholder: "When?",
		inputStyle: {
			display: "inline-block",
			padding: "6px",
			"line-height": "22px",
			"font-size": "16px",
			border: "2px solid #fff",
			"box-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
			"border-radius": "2px",
			color: "#5F5F5F",
		},
		color: {
			header: "#4da3ff",
			headerText: "#fff",
		},
		buttons: {
			ok: "Ok",
			cancel: "Cancel",
		},
		overlayOpacity: 0.5,
		dismissible: true,
	} as IDateTimeOption,
	MultiShift: false as boolean,
};

type TObjectWithId = { id: string | number };

export const diffArrayOfObjects = (
	o1: TObjectWithId[],
	o2: TObjectWithId[],
): TObjectWithId[] => {
	const ids: Record<string | number, boolean> = {};
	// Populate the ids object with keys from o2
	for (const item of o2) {
		ids[item.id] = true;
	}
	// Filter o1 to find items not present in o2
	const diff = o1.filter((item) => !ids[item.id]);

	return diff;
};

export function isFlatNumberArray(arr: any[]): arr is number[] {
	return arr.every((item) => typeof item === "number");
}
