<script setup lang="ts">
import type { TFilePickerChange } from "./FilePicker.vue";
import type FilePickerVue from "./FilePicker.vue";

type TFilePickerProps = {
	fileList?: File[] | null;
	canStoreLocally?: boolean;
	canRemoveFile?: boolean;
	placeholderText?: `'${string}'`;
	trimLongName?: boolean;
	isLoading?: boolean;
	hideFileItems?: boolean;
};

export type TFileDropzoneChange = {
	evt: Event;
	files: File[] | null;
	hasFiles: boolean;
};
export type TFileDropzoneRemove = {
	id: string;
	index: number; // Used mostly when list is external
	files: File[] | null;
	hasFiles: boolean;
};

defineOptions({
	inheritAttrs: false,
});

const props = withDefaults(defineProps<TFilePickerProps>(), {
	fileList: () => [],
	canStoreLocally: true,
	canRemoveFile: true,
	placeholderText: "'Click / Drag to upload'",
	trimLongName: true,
	hideFileItems: false,
});
const emit = defineEmits<{
	add: [value: TFileDropzoneChange];
	remove: [value: TFileDropzoneRemove];
}>();

class CDropzoneList {
	private list: CDropzoneFile[] = [];

	/**
	 * Creates a list of file objects
	 */
	constructor(...files: File[]) {
		this.replaceFiles(...files);
	}

	addFile(...files: File[]) {
		for (const file of files) {
			this.list.push(new CDropzoneFile(file));
		}
	}
	removeFile(fileObj: CDropzoneFile) {
		const index = this.list.findIndex(
			(item) => item.uniqueId === fileObj.uniqueId,
		);
		if (index === -1) {
			console.warn("Invalid file", fileObj);
		} else {
			this.list.splice(index, 1);
		}
	}
	replaceFiles(...files: File[]) {
		this.list = files.map((file) => new CDropzoneFile(file));
	}

	getAllFiles() {
		return this.list.map((item) => item.file);
	}

	get items(): CDropzoneFile[] {
		return this.list;
	}
	get hasFiles(): boolean {
		return Boolean(this.list.length);
	}
}

class CDropzoneFile {
	uniqueId = CDropzoneFile.createUniqueId();
	file: File;

	/**
	 * Add a file to the class so we can manipulate it
	 */
	constructor(file: File) {
		this.file = file;
	}

	renameFile(name: string, fileProps?: FilePropertyBag) {
		this.file = new File([this.file], name, fileProps);
	}

	static createUniqueId() {
		return Math.random()
			.toString(36)
			.replace(/[^a-z]+/g, "")
			.slice(0, 7);
	}
}

const dropzoneFileRef = ref<HTMLInputElement | null>(null);
const filePickerRef = ref<InstanceType<typeof FilePickerVue> | null>(null);
const localFileList = ref<CDropzoneList | null>(null);
const updatedFileList = computed<CDropzoneList>(() => {
	if (props.canStoreLocally && localFileList.value?.hasFiles) {
		return localFileList.value as CDropzoneList;
	}

	const propsList = new CDropzoneList(...(props.fileList || []));
	return propsList;
});
const getPlaceholderText = computed(() => {
	return updatedFileList.value.items.length && !props.hideFileItems
		? ""
		: props.placeholderText;
});

watch(
	() => props.fileList,
	(val) => {
		copyPropsToLocal(val);
	},
);

function onClickPicker() {
	if (filePickerRef.value) {
		filePickerRef.value.onClickPicker();
	}
}

const onDragover = (evt: DragEvent) => evt.preventDefault();
function onDropped(evt: DragEvent) {
	evt.preventDefault();
	const files = evt.dataTransfer?.files;
	if (files?.length) {
		const fileList = [...files];
		if (props.canStoreLocally) {
			localFileList.value?.addFile(...fileList);
		}

		const hasFiles = !!fileList?.length;
		emit("add", { evt, files: fileList, hasFiles });
	}
}
function onChangedFiles(evt: TFilePickerChange) {
	if (evt.hasFiles && evt.files?.length) {
		const fileList = [...evt.files];
		if (props.canStoreLocally) {
			localFileList.value?.addFile(...fileList);
		}

		const hasFiles = !!fileList?.length;
		emit("add", { evt: evt.evt, files: fileList, hasFiles });
	}
}
function onRemove(obj: CDropzoneFile, index: number) {
	if (props.canStoreLocally) {
		localFileList.value?.removeFile(obj);
	}

	const hasFiles = updatedFileList.value.hasFiles;
	const files = updatedFileList.value.getAllFiles();
	emit("remove", { id: obj.uniqueId, index, files, hasFiles });
}
function onClickZone() {
	onClickPicker();
}
function copyPropsToLocal(list = props.fileList) {
	if (props.canStoreLocally && list) {
		localFileList.value = new CDropzoneList(...list);
	}
}

onMounted(() => {
	copyPropsToLocal();
});

defineExpose({
	onClickZone,
});

defineSlots<{
	fileDropzone: () => any;
	fileItems: (props: { props: { fileList: CDropzoneList } }) => any;
	fileItem: (props: { props: { fileObj: CDropzoneFile } }) => any;
	fileRemove: (props: { props: { fileObj: CDropzoneFile } }) => any;
	loader: () => any;
}>();
</script>

<template>
	<template v-if="isLoading">
		<slot name="loader">
			<div class="loader-wrap">
				<div class="loader"></div>
			</div>
		</slot>
	</template>
	<template v-else>
		<slot name="fileDropzone">
			<div
				ref="dropzoneFileRef"
				class="dropzone"
				tabindex="0"
				@drop="onDropped"
				@dragover="onDragover"
				@click.self="onClickZone"
			>
				<slot
					v-if="!hideFileItems"
					name="fileItems"
					:props="{ fileList: updatedFileList }"
				>
					<div class="items">
						<template
							v-for="(item, _index) in updatedFileList.items"
							:key="_index"
						>
							<slot
								name="fileItem"
								:props="{ fileObj: item }"
							>
								<div
									class="item"
									:class="{ trim: trimLongName }"
								>
									<div
										class="name"
										:title="item.file.name"
									>
										{{ item.file.name }}
									</div>
									<slot
										name="fileRemove"
										:props="{ fileObj: item }"
									>
										<i-fa-xmark
											v-if="canRemoveFile"
											class="del"
											@click="onRemove(item, _index)"
										></i-fa-xmark>
									</slot>
								</div>
							</slot>
						</template>
					</div>
				</slot>
			</div>
		</slot>
		<file-picker
			ref="filePickerRef"
			:multiple="true"
			@change="onChangedFiles"
		>
			<template #filePicker><span></span></template>
		</file-picker>
	</template>
</template>

<style lang="scss" scoped>
$loading-spinner-color: #999;

.dropzone {
	display: flex;
	flex-direction: column;
	// height: 100%; // Don't use this
	flex: 1; // Parent must be flex
	width: 100%;
	position: relative;
	overflow: auto;

	&::before {
		content: v-bind(getPlaceholderText);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		text-align: center;
		pointer-events: none;
	}

	.items {
		display: flex;
		flex-direction: column;
		gap: 5px;
		padding: 10px 0;

		.item {
			display: flex;
			align-items: center;
			padding: 0 5px;
			justify-content: space-between;
			gap: 2px 8px;

			&.trim {
				.name {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			.del {
				cursor: pointer;
				font-size: 1rem;

				&:hover {
					filter: brightness(1.5);
				}
			}
		}
	}
}

.loader-wrap {
	display: grid;
	width: 100%;
	height: 100%;
	place-items: center;
	user-select: none;
	pointer-events: none;

	.loader {
		width: 48px;
		height: 48px;
		border: 5px solid $loading-spinner-color;
		border-bottom-color: transparent;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
</style>
