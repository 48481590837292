<script setup lang="ts">
import api from "@/api";
import type { TTemp } from "@/assets/js/globalTypes";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";

const emit = defineEmits<{
	confirm: [void];
	"close-modal": [void];
}>();

const props = defineProps<{
	dnrStatus: number;
	tempData: Partial<TTemp>;
}>();
const isDisplayedDatePicker = ref(false);
const statusElements = ref([
	{
		id: 1,
		label: "Active",
		backColor: "#23d160",
	},
	{
		id: 2,
		label: "Review",
		color: "black",
		backColor: "#ffdb4a",
	},
	{
		id: 3,
		label: "Inactive",
		backColor: "#ff2b56",
	},
	{
		id: 4,
		label: "Approval",
		color: "black",
		backColor: "#a8bc46",
		action: () => (isDisplayedDatePicker.value = true),
	},
] as const);

const isLoading = ref(false);

const startDate = ref<string | null>(null);

const dnrStatusChange = ref<number | null>(null);

const defaultStore = useDefaultStore();
const sessionStore = useSessionStore();
const canUseNexusMiddleware = computed(
	() => defaultStore.systemPermissions.canUseNexusMiddleware,
);
const $can = sessionStore.can;

const toastStore = useToastStore();

const filteredStatuses = computed(() => {
	return statusElements.value.filter((statusEl) => {
		if (statusEl.id === 4 && dnrStatusChange.value !== 1) {
			return false;
		}
		return statusEl.id !== dnrStatusChange.value;
	});
});

async function updateDnr(status: number, date: string) {
	try {
		isLoading.value = true;
		if (status === 4) {
			status = 1;
		}
		await api.patchTemp(
			props.tempData.id,
			{
				status: status as 1 | 2 | 3 | 4,
				compliance_exception_to: date,
				contract_start_date: props.tempData?.contract_start_date,
			},
			{
				include: "consultant,locations",
			},
		);
		toastStore.openToastSuccess("Status updated successfully.");
		emit("confirm");
		emit("close-modal");
	} catch (e) {
		toastStore.openToastError(e.response.data.message);
	} finally {
		isLoading.value = false;
	}
}

function callActionButton(statusId: number, date?: string) {
	const statusEl = statusElements.value.find((el) => el.id === statusId);
	if (statusEl && "action" in statusEl) {
		statusEl.action();
	} else {
		updateDnr(statusId, date);
	}
}

function checkDisableStatus(status) {
	if (
		status.id === 1 &&
		canUseNexusMiddleware.value &&
		!$can("edit-temp-active-status")
	) {
		return true;
	} else if (
		status.id === 2 &&
		canUseNexusMiddleware.value &&
		!$can("edit-temp-review-status")
	) {
		return true;
	}
	return false;
}

function checkApproval(statusEl) {
	return (
		(!$can("manager-approval-status") && statusEl.label == "Approval") ||
		(props.dnrStatus &&
			props.dnrStatus === 4 &&
			!$can("manager-approval-status"))
	);
}

onMounted(() => {
	dnrStatusChange.value = props.dnrStatus;
	startDate.value =
		props.dnrStatus === 4 ? props.tempData.compliance_exception_to : null;
});
</script>

<template>
	<div id="edit-temp-modal-wrap">
		<div class="status-wrap">
			<div
				v-for="statusEl of filteredStatuses"
				:key="statusEl.id"
				class="item"
			>
				<button
					:style="{
						backgroundColor: statusEl.backColor,
						color: 'color' in statusEl ? statusEl.color : 'white',
					}"
					class="wide"
					:disabled="
						isLoading || checkDisableStatus(statusEl) || checkApproval(statusEl)
					"
					@click.prevent="callActionButton(statusEl.id)"
				>
					{{ statusEl.label }}
				</button>
			</div>
		</div>
		<div
			v-if="(dnrStatus === 1 && isDisplayedDatePicker) || dnrStatus === 4"
			class="column"
		>
			<label>Choose an expiry date for this approval.</label>
			<modern-date-picker
				v-model="startDate"
				:esc-close="false"
				:clearable="false"
				:start-date="startDate"
				placeholder="When?"
				:enable-time-picker="false"
				format="dd/MM/yyyy"
				auto-apply
				@update:model-value="updateDnr(dnrStatusChange, $event as string)"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
#edit-temp-modal-wrap {
	padding: 2.5rem;
	min-width: 320px;
	.status-wrap {
		display: flex;
		gap: 15px;
		flex-direction: column;
		.item {
			button {
				width: 100%;
			}
		}
	}
}
</style>
