<script setup lang="ts">
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelper";
import { downloadFile } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
const emit = defineEmits(["cancel-modal", "rates-updated", "close-modal"]);
const props = defineProps<{
	cliId?: string | number;
	uniqueCode: string;
	type: string;
}>();
const ratesParams = ref<{
	valid_from: string;
	valid_to: string;
	isIndefinite: boolean;
}>({
	valid_from: "",
	valid_to: "",
	isIndefinite: true,
});
const submitClicked = ref<boolean>(false);

const validateIds = ref<any>([]);
const loadingGapsReport = ref<boolean>(false);
const loadingCitReport = ref<boolean>(false);
const canShowGapsReport = ref<boolean>(false);
const canShowCitReport = computed(() =>
	Boolean(Object.keys(validateIds.value).length),
);
const validateCheck = async () => {
	const params = {
		cli_id: props?.cliId,
		type: props.type,
	};
	const getApiActionKey =
		props.uniqueCode === "GLBNRML"
			? "getGlobalRatesValidate"
			: "getRatesValidate";
	try {
		const res = await api[getApiActionKey](params);
		validateIds.value = res.data.data;
		if (Object.keys(validateIds.value).length) {
			return false;
		}
	} catch (err) {
		console.log("err", err);
		// setErrors(parseErrors(err) ?? null);
	}
	return true;
};
const fetchGaps = async () => {
	const params = {
		cli_id: props?.cliId,
		valid_from: DateInstance.getFormattedIsoDate(ratesParams.value.valid_from),
		valid_to: ratesParams.value.isIndefinite
			? "2900-01-01"
			: DateInstance.getFormattedIsoDate(ratesParams.value.valid_to),
		type: props.type,
	};
	const getApiActionKey =
		props.uniqueCode === "GLBNRML" ? "getGlobalRatesGaps" : "getRatesGaps";

	try {
		const res = await api[getApiActionKey](params);
		canShowGapsReport.value = Boolean((res.data.data || []).length);
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
	return !canShowGapsReport.value;
};
const finalizeChanges = async () => {
	const params = {
		cli_id: props?.cliId,
		valid_from: DateInstance.getFormattedIsoDate(ratesParams.value.valid_from),
		valid_to: ratesParams.value.isIndefinite
			? "2900-01-01"
			: DateInstance.getFormattedIsoDate(ratesParams.value.valid_to),
		type: props.type,
	};
	const getApiActionKey =
		props.uniqueCode === "GLBNRML"
			? "postGlobalRatesFinalize"
			: "postRatesFinalize";
	try {
		const res = await api[getApiActionKey](params);
		toastStore.openToastSuccess(res.data.message);
		emit("rates-updated");
		closeModal();
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
};
async function onSubmit() {
	submitClicked.value = true;
	try {
		const [validateResult, gapsResult] = await Promise.all([
			validateCheck(),
			fetchGaps(),
		]);
		console.warn("Apply rates [Needs true both]", validateResult, gapsResult);
		if (validateResult !== false && gapsResult !== false) {
			// If both results are not false
			await finalizeChanges();
		} else {
			toastStore.openToastError(
				"Please review the report for any errors while applying rates",
			);
		}
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	} finally {
		submitClicked.value = false;
	}
}
function closeModal() {
	emit("close-modal");
}

// const showInstructions = ref<boolean>(false);
const activateRatesButtonDisabled = computed(() => {
	return (
		submitClicked.value ||
		ratesParams.value.valid_from.length === 0 ||
		(!ratesParams.value.isIndefinite && ratesParams.value.valid_to.length === 0)
	);
});
async function fetchBlankCitReport() {
	loadingCitReport.value = true;
	const params = {
		cli_id: props?.cliId,
		valid_from: DateInstance.getFormattedIsoDate(ratesParams.value.valid_from),
		valid_to: ratesParams.value.isIndefinite
			? "2900-01-01"
			: DateInstance.getFormattedIsoDate(ratesParams.value.valid_to),
		type: props.type,
	};
	const getApiActionKey =
		props.uniqueCode === "GLBNRML"
			? "getGlobalRatesCitReport"
			: "getRatesCitReport";
	try {
		const res = await api[getApiActionKey](params);
		const blob = new Blob([res.data], {
			type: res.headers["content-type"],
		});
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		const fileName = res.headers["x-multi-file-name"];
		downloadFile(blob, fileName);
	} catch (err: any) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		} else {
			toastStore.openToastError("Downloading CIT Report Failed.");
		}
	} finally {
		loadingCitReport.value = false;
	}
}
async function fetchGapsReport() {
	loadingGapsReport.value = true;
	const params = {
		cli_id: props?.cliId,
		valid_from: DateInstance.getFormattedIsoDate(ratesParams.value.valid_from),
		valid_to: ratesParams.value.isIndefinite
			? "2900-01-01"
			: DateInstance.getFormattedIsoDate(ratesParams.value.valid_to),
		type: props.type,
	};

	const getApiActionKey =
		props.uniqueCode === "GLBNRML"
			? "getGlobalRatesGapsReport"
			: "getRatesGapsReport";
	try {
		const res = await api[getApiActionKey](params);
		const blob = new Blob([res.data], {
			type: res.headers["content-type"],
		});
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		const fileName = res.headers["x-multi-file-name"];
		downloadFile(blob, fileName);
	} catch (err: any) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		} else {
			toastStore.openToastError("Downloading Gaps Report Failed.");
		}
	} finally {
		loadingGapsReport.value = false;
	}
}
</script>

<template>
	<div class="rates-change-value-modal">
		<div class="inner-content">
			<!-- <div>
				<button @click.prevent="showInstructions = !showInstructions">
					Important
					<i-fa4-chevron-down
						class="caret"
						:class="{
							'caret-up': !showInstructions,
							'caret-up caret-up-rotated': showInstructions,
							rotate: true,
						}"
					></i-fa4-chevron-down>
				</button>
			</div>
			<transition name="fade">
				<ul v-if="showInstructions">
					<li>
						When selecting dates using the date picker the start date you choose
						will be included in the date range. However, the end date you select
						will not be included in the range.
					</li>
					<li>
						This means that the date range you pick will cover all the dates
						starting from the chosen start date up to, but not including, the
						selected end date.
					</li>
				</ul>
			</transition> -->

			<ul>
				<li>
					When selecting dates using the date picker the start date you choose
					will be included in the date range. However, the end date you select
					will not be included in the range.
				</li>
				<li>
					This means that the date range you pick will cover all the dates
					starting from the chosen start date up to, but not including, the
					selected end date.
				</li>
			</ul>
			<div class="flex-column-center">
				<label>Choose date range to activate rates for:</label>
				<div class="flex-center mgt-10">
					<div class="h-70">
						<modern-date-picker
							v-model="ratesParams.valid_from"
							:enable-time-picker="false"
							class="wd-220"
							:format="'dd/MM/yyyy'"
							:textInput="true"
							:clearable="false"
							style="margin: 0 3px; font-size: 0.85em"
							placeholder="Start Date"
							auto-apply
						/>
					</div>

					<div>
						<modern-date-picker
							v-if="!ratesParams.isIndefinite"
							v-model="ratesParams.valid_to"
							:enable-time-picker="false"
							class="wd-220"
							:format="'dd/MM/yyyy'"
							:textInput="true"
							:clearable="false"
							style="margin: 0 3px; font-size: 0.85em"
							placeholder="End Date"
							:disabled="ratesParams.isIndefinite"
							:hideInputIcon="ratesParams.isIndefinite"
						/>
						<div
							v-else
							class="wd-220"
							style="margin: 0 3px"
						>
							<input
								type="text"
								style="
									width: 214px;
									height: 34px;
									margin: 0 2px;
									gap: 10px;
									background: #d8d8d8;
								"
								disabled
							/>
						</div>
						<div class="pointer">
							<input
								v-model="ratesParams.isIndefinite"
								type="checkbox"
								class="mgl-7"
							/>
							<label
								class="mgl-5 wd-130 pointer color-lca"
								@click="ratesParams.isIndefinite = !ratesParams.isIndefinite"
							>
								Set to indefinite
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="canShowGapsReport || canShowCitReport"
			class="buttons-centered"
		>
			<transition name="fade">
				<button
					v-if="canShowGapsReport"
					class="button is-small is-generic-app-blue is-caps-lock"
					type="submit"
					:disabled="loadingGapsReport && activateRatesButtonDisabled"
					:class="{
						'is-loading': loadingGapsReport,
						'not-allowed': loadingGapsReport,
					}"
					@click.prevent="fetchGapsReport"
				>
					Get Gap error report
				</button>
			</transition>
			<transition name="fade">
				<button
					v-if="canShowCitReport"
					:disabled="loadingCitReport && activateRatesButtonDisabled"
					:class="{
						'is-loading': loadingCitReport,
						'not-allowed': loadingCitReport,
					}"
					class="button is-small is-outlined is-caps-lock"
					@click.prevent="fetchBlankCitReport"
				>
					Get blank CIT error report
				</button>
			</transition>
		</div>
		<div class="buttons-centered mgb-20">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				type="submit"
				:disabled="activateRatesButtonDisabled"
				:class="{
					'is-loading': submitClicked,
					'not-allowed': activateRatesButtonDisabled,
				}"
				@click.prevent="onSubmit"
			>
				Activate Rates
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.rates-change-value-modal {
	height: 350px;
	width: 650px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 5px 10px;

	ul {
		// list-style-type: disc;
		// padding-left: 20px;
		padding-left: 5px;
		color: $label-color;
	}

	li {
		margin: 5px 0; /* Add spacing between list items */
	}

	.caret {
		transition: transform 0.3s ease;
	}

	.caret-up {
		transform: rotate(180deg);
	}

	.caret-up-rotated {
		transform: rotate(0deg);
	}
	.inner-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 10px;

		.action {
			display: flex;
			overflow: auto;
			max-height: 350px;
			gap: 10px;

			.checkbox-complete {
				overflow: auto;
				padding-right: 10px;
			}

			.cats {
				display: flex;
				flex-direction: column;
				gap: 5px;
				flex: 1;
			}

			.input-section {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}
}

@include fade-transition;
</style>
