export const useKeepAliveStore = defineStore("keep-alive", () => {
	const keepAlive = ref<string[]>([]);

	function preNavigationSetup(comp, oldComp) {
		let tempComps = [];
		if (comp.params.resetKeepAlive) {
			// Improve in future
			// Reset k-a

			tempComps = tempComps.filter((compKeep) => compKeep !== comp.name);
			nextTick(() => {
				// Re-add k-a
				tempComps.push(comp);
			});
		}

		const keepAliveComps = comp.meta["has-keep-alive"];
		if (Array.isArray(keepAliveComps)) {
			for (const comp of keepAliveComps) {
				tempComps.push(comp);
			}
		}
		keepAlive.value.push(...new Set(tempComps));
	}

	const keepAliveGetter = computed(() => keepAlive.value);

	function postNavigationSetup(from, to) {
		const toComp: string[] | undefined = to.meta["has-keep-alive"];
		const fromComp: string[] | undefined = from.meta["has-keep-alive"];
		if (fromComp) {
			if (toComp && toComp.toString() === fromComp.toString()) {
				return;
			}
			for (const el of fromComp) {
				keepAlive.value =
					toComp && !toComp.includes(el)
						? keepAlive.value.filter((comp) => comp !== el)
						: [];
			}
		}
	}

	return {
		keepAliveGetter,
		preNavigationSetup,
		keepAlive,
		postNavigationSetup,
	};
});
