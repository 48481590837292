<script setup lang="ts">
withDefaults(
	defineProps<{
		message?: string | string[];
		visible: boolean;
		isError?: boolean;
	}>(),
	{
		message: "",
		visible: true,
		isError: false,
	},
);
const emit = defineEmits(["confirm", "cancel"]);
const confirmAction = () => {
	emit("confirm");
};
const cancelAction = () => {
	emit("cancel");
};
</script>
<template>
	<div
		v-if="visible"
		class="modal"
	>
		<div
			class="modal-overlay"
			@click="cancelAction"
		></div>
		<button
			class="native-button exit-modal-button"
			@click="cancelAction"
		>
			X
		</button>
		<div class="modal-content">
			<div
				class="message-wrapper"
				:style="{
					'justify-content':
						typeof message === 'string' ||
						(Array.isArray(message) && message.length < 7)
							? 'center'
							: 'start',
				}"
			>
				<template v-if="Array.isArray(message)">
					<p
						v-for="(msg, idx) in message"
						:key="idx"
						:class="{ 'error-text': isError }"
					>
						{{ msg }}
						<br />
					</p>
				</template>
				<p
					v-if="typeof message === 'string'"
					:class="{ 'error-text': isError }"
				>
					{{ message }}
				</p>
			</div>
			<div v-if="!isError">
				<button
					class="button is-generic-app-blue"
					@click="confirmAction"
				>
					Confirm
				</button>
				<button
					class="alt button is-generic-app-blue"
					@click="cancelAction"
				>
					Cancel
				</button>
			</div>
			<div v-else>
				<button
					class="button is-generic-app-blue"
					@click="cancelAction"
				>
					Okay
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.modal {
	.modal-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.modal-content {
		z-index: 5000000;
		position: absolute;
		background-color: $white;
		padding: 15px;
		border-radius: 5px;
		width: 350px;
		height: 280px;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.03em;
		overflow: auto;
		.message-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			overflow: auto;
			height: 230px;
			width: 100%;
			padding: 3px;
			p {
				width: 95%;
				text-align: center;
			}
		}
		button {
			margin: 0 5px;
		}
	}
	.exit-modal-button {
		border-radius: 290486px;
		border: none;
		cursor: pointer;
		display: inline-block;
		font-size: 20px;
		color: $white;
		outline: none;
		transform-origin: center center;
		vertical-align: top;
		height: 40px;
		width: 40px;
		position: fixed;
		right: 20px;
		top: 20px;
		z-index: 5001;
		transition: background-color 0.2s ease;
		background: rgba(68, 67, 67, 0.404);
		&:hover {
			background: rgba(68, 67, 67, 0.664);
		}
	}
}
</style>
