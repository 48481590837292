<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";
import api from "@/api";
import { useRoute } from "vue-router";

interface IShift {
	id?: number;
}

const props = defineProps<{ shift: IShift }>();
const emit = defineEmits(["confirm", "close-modal"]);
const route = useRoute();
const loading = ref(false);
// const toast = useToastStore();

// const patchShift = store.dispatch.bind(store, "patchShift");

const bookTemp = async () => {
	loading.value = true;
	console.log(props);
	const tempId = route.params.tempId;
	if (!tempId || !props.shift.id) {
		return false;
	}

	// const params = {
	// 	id: props.shift.id,
	// 	data: {
	// 		temp_id: tempId,
	// 	},
	// };
	const params = {
		temp_id: tempId,
	};
	try {
		// await patchShift(params);
		await api.patchShift(props.shift.id, params);

		return true;
	} catch (error) {
		const errs = parseErrors(error);
		useToastStore().openToastError(errs);
		loading.value = false;
		return false;
	}
};

const confirm = async () => {
	const isDone = await bookTemp();
	if (isDone) {
		emit("confirm");
		emit("close-modal");
		loading.value = false;
	}
};

const decline = () => {
	emit("close-modal");
};
</script>

<template>
	<div class="confirm-wrap">
		<div class="text">
			Are you sure you want to assign candidate to this shift?
		</div>
		<div class="action">
			<button
				class="button is-generic-app-blue is-caps-lock is-tiny copy-shift"
				:disabled="loading"
				@click="confirm"
			>
				Accept
			</button>
			<button
				class="button is-caps-lock is-tiny copy-shift alt"
				@click="decline"
			>
				Cancel
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.confirm-wrap {
	width: 100%;
	min-width: 450px;
	height: 100%;
	padding: 0 20px 20px;
	display: flex;
	flex-direction: column;

	.text {
		margin-bottom: 20px;
		text-align: center;
	}

	.action {
		display: flex;
		justify-content: center;

		> * {
			margin-left: 1rem;
		}
	}
}
</style>
