<script setup lang="ts">
// import { useStore } from "vuex";
// import moment from "moment";

// new
import { useToastStore } from "@/store/toast";
import dayjs from "dayjs";
import api from "@/api";
import { parseErrors } from "@/assets/js/parseErrors.js";

const emit = defineEmits(["close-modal"]);
const props = defineProps({
	modalProps: {
		type: Object,
		required: true,
	},
});

// const store = useStore();
const toastStore = useToastStore();

const breakList = ref<any>([]);
const breakTimesCategories = ref<any>([]);
const selectedCategoryOption = ref<any>(null);
const maxBreakValue = ref<number | null>(null);
const isMaxBreakValueSet = ref<any>(false);
const isAnyInputChanged = ref<any>(false);
// const errorInputList = ref<any>([]);

const breakListSorted = computed(() => {
	return [...breakList.value].sort((a, b) =>
		a.from > b.from ? 1 : a.from < b.from ? -1 : 0,
	);
});

// const getBiggestFromObj = computed(() => {
// 	if (Array.isArray(breakList.value) && breakList.value.length) {
// 		return breakList.value.reduce((acc, curr) =>
// 			curr.shift_length_to > acc.shift_length_to ? curr : acc,
// 		);
// 	}
// 	return {};
// });
const getBiggestFromObj = computed(() => {
	if (Array.isArray(breakList.value) && breakList.value.length > 0) {
		let biggest = breakList.value[0];

		for (const item of breakList.value) {
			if (item.shift_length_to > biggest.shift_length_to) {
				biggest = item;
			}
		}

		return biggest;
	}
	return {};
});

const clientId = computed(() => props.modalProps.id);

onMounted(() => {
	fetchBreakTimes();
});

const fetchBreakTimes = async () => {
	try {
		const params = { id: clientId.value };
		// const res = await store.dispatch("getBreakTimes", params); not need store
		const res = await api.getBreakTimes(params);
		breakTimesCategories.value = res.data.data;
	} catch (err) {
		console.error("Error", err.message);
	}
};

const onChangeCategory = (evt) => {
	// console.log(evt);
	fetchBreakTimes();
	selectedCategoryOption.value = evt;
	const { list, maxBreakObj } = parseBreakTimesArr(evt.break_times);
	breakList.value = list;
	// @ts-ignore
	if (isNaN(parseInt(maxBreakObj.break_length))) {
		maxBreakValue.value = null;
		isMaxBreakValueSet.value = false;
	} else {
		// @ts-ignore
		maxBreakValue.value = parseInt(maxBreakObj.break_length);

		isMaxBreakValueSet.value = true;
	}
};

const onAddNewRow = () => {
	if (isMaxBreakValueSet.value) {
		addBlankRow();
	} else {
		isMaxBreakValueSet.value = true;
		// @ts-ignore if input set ""
		maxBreakValue.value = "";
	}
};

const onSave = async () => {
	await saveBreakTimes();
};

const onApply = () => {
	saveBreakTimes(false);
};

const onClose = () => {
	emit("close-modal");
};

const onRemoveRow = async (rowData, rowIndex) => {
	try {
		// await confirmAlert("remove break time");
		const value = breakList.value[rowIndex].shift_length_from;
		if (rowIndex < breakList.value.length - 1) {
			breakList.value[rowIndex + 1].shift_length_from = value;
		}
		breakList.value.splice(rowIndex, 1);
		isAnyInputChanged.value = true;
	} catch {
		// ignored
	}
};

const removeGeneralRow = async () => {
	// await confirmAlert("remove break time");
	maxBreakValue.value = null;
	isAnyInputChanged.value = true;
	isMaxBreakValueSet.value = false;
};

const onInputField = (evt, keyField, breakRow, index) => {
	const value = evt.target.value;
	let valueParsed = null;
	if (value) {
		valueParsed = parseInt(value, 10);
	}
	breakRow[keyField] = valueParsed;
	if (keyField === "shift_length_to" && index < breakList.value.length - 1) {
		breakList.value[index + 1].shift_length_from = valueParsed;
	}
	isAnyInputChanged.value = true;
};

const onChangeMaxBreakValue = (evt) => {
	maxBreakValue.value = evt.target.value;
	isAnyInputChanged.value = true;
};

const saveBreakTimes = async (isSave = true) => {
	const isValidInputs = checkInputsValidity();
	if (!isValidInputs) {
		toastStore.openToastError(
			"Some input values are not valid. Please fix before proceeding",
		);

		return;
	}

	const params = {
		client_id: clientId.value,
		break_categories: [
			{
				id: selectedCategoryOption.value.id,
				max_break_value: maxBreakValue.value,
				breaks: generateCategoriesForApi(),
			},
		],
	};
	try {
		// await store.dispatch("patchBreakTimes", params); not need store
		await api.patchBreakTimes(params);
		toastStore.openToastSuccess("Successfully changed");
		// store.$toasted.success("Successfully changed").goAway(1000);
		isAnyInputChanged.value = false;
		if (isSave) {
			onClose();
		}
	} catch (err) {
		const errs = parseErrors(err);
		// store.$toasted.error(errs).goAway(4500);
		toastStore.openToastError(errs);
	}
};

const addBlankRow = () => {
	const payload = {
		shift_length_from: parseMaxTo(getBiggestFromObj.value) || 0,
		shift_length_to: null,
		break_length: null,
	};
	breakList.value.push(payload);
};

const generateCategoriesForApi = () => {
	return breakList.value.map((oneBreak) => ({
		to: oneBreak.shift_length_to,
		value: oneBreak.break_length,
	}));
};

const generateTitle = (rowObj) => {
	const formatDate = (str) => dayjs(str).format(" DD/MM/YYYY HH:mm:ss");
	const formatKeys = (str) =>
		(str[0].toUpperCase() + str.slice(1)).replace("_", " ");
	const dateUsed = rowObj.updated || rowObj.created;
	const obj = {
		updated_by: rowObj.user && rowObj.user.name,
		updated_at: dateUsed && formatDate(dateUsed),
	};
	return Object.entries(obj)
		.filter((el) => el[1])
		.map((el) => `${formatKeys(el[0])}: ${el[1] || ""} `)
		.join("\n");
};

const checkInputsValidity = () => {
	const allErrorInputs = document.querySelectorAll("input.error");
	const isAnyInputInvalid = allErrorInputs.length > 0;
	if (isAnyInputInvalid) {
		return false;
	}
	const someFieldNotFilled = breakList.value.some(
		(oneBreak) => oneBreak.break_length === null,
	);
	// @ts-ignore if input set ""
	return !someFieldNotFilled && maxBreakValue.value !== "";
};

const parseBreakTimesArr = (breakTimesArr = []) => {
	const tempArr = [...breakTimesArr];
	const maxBreakObjIndex = tempArr.findIndex(
		(oneBreak) => oneBreak.shift_length_to === 100,
	);
	let maxBreakObj = {};
	if (maxBreakObjIndex !== -1) {
		maxBreakObj = tempArr.splice(maxBreakObjIndex, 1)[0];
	}
	return {
		list: tempArr,
		maxBreakObj: maxBreakObj,
	};
};

const parseMaxTo = (bigObj, strAppend = "") => {
	return bigObj.shift_length_to
		? `${bigObj.shift_length_to}${strAppend}`
		: `0${strAppend}`;
};

const isInputErrorValidation = (key, rowObj) => {
	const valueTo = rowObj[key];
	const valueFrom = rowObj.shift_length_from;
	switch (key) {
		case "shift_length_to": {
			return !valueTo || valueTo < 1 || valueTo > 99 || valueTo <= valueFrom;
		}
		case "break_length": {
			return false;
		}
		default: {
			return false;
		}
	}
};
</script>

<template>
	<div class="break-times-form">
		<div class="form-content">
			<p class="control">
				<label class="label">Category</label>
				<multiselect-form
					v-model="selectedCategoryOption"
					class="category-select"
					:options="breakTimesCategories"
					value-prop="id"
					:multiple="false"
					label="name"
					placeholder="FILTER CATEGORY"
					@select="onChangeCategory"
				/>
			</p>
			<template v-if="selectedCategoryOption">
				<div class="rows">
					<div
						v-for="(breakRow, index) in breakListSorted"
						:key="index"
						class="row"
					>
						<div class="group-input">
							<label
								v-if="index == 0"
								class="label"
							>
								From (hours)
							</label>
							<input
								class="input"
								:value="breakRow.shift_length_from"
								type="text"
								:disabled="true"
							/>
						</div>
						<div class="group-input">
							<label
								v-if="index == 0"
								class="label"
							>
								To (hours)
							</label>
							<input
								class="input"
								:value="breakRow.shift_length_to"
								:class="{
									error: isInputErrorValidation('shift_length_to', breakRow),
								}"
								type="number"
								min="1"
								max="99"
								@input="
									onInputField($event, 'shift_length_to', breakRow, index)
								"
							/>
						</div>
						<div class="group-input">
							<label
								v-if="index == 0"
								class="label"
							>
								Break (minutes)
							</label>
							<input
								class="input"
								:value="breakRow.break_length"
								:class="{
									error: isInputErrorValidation('break_length', breakRow),
								}"
								type="number"
								@input="onInputField($event, 'break_length', breakRow, index)"
							/>
						</div>
						<div
							v-tooltip:top.accent="generateTitle(breakRow)"
							class="info"
						>
							<span class="fa fa-info popup"></span>
						</div>
						<div class="remove-wrap">
							<img
								src="../../../../assets/image/icons/remove.svg"
								title="Remove"
								@click="onRemoveRow(breakRow, index)"
							/>
						</div>
					</div>
					<div
						v-if="isMaxBreakValueSet"
						class="row"
					>
						<div class="group-input">
							<label
								v-if="breakListSorted.length == 0"
								class="label"
							>
								From (hours)
							</label>
							<input
								class="input"
								:value="parseMaxTo(getBiggestFromObj, '+')"
								type="text"
								:disabled="true"
							/>
						</div>
						<div class="group-input"><input style="visibility: hidden" /></div>
						<div class="group-input">
							<label
								v-if="breakListSorted.length == 0"
								class="label"
							>
								Break (minutes)
							</label>
							<input
								class="input"
								:value="maxBreakValue"
								type="number"
								@input="onChangeMaxBreakValue($event)"
							/>
						</div>
						<div
							v-if="breakListSorted.length == 0"
							class="remove-wrap"
						>
							<img
								src="../../../../assets/image/icons/remove.svg"
								title="Remove"
								@click="removeGeneralRow"
							/>
						</div>
					</div>
					<div
						v-if="!isMaxBreakValueSet"
						class="row"
					>
						<p>This category is using the default break times.</p>
					</div>
				</div>
				<button
					class="button is-generic-app-blue is-caps-lock is-low add"
					@click="onAddNewRow"
				>
					<span>Add new row</span>
				</button>
			</template>
		</div>
		<div class="action buttons-centered">
			<button
				class="button is-generic-app-blue is-caps-lock save"
				:disabled="!isAnyInputChanged"
				@click="onSave"
			>
				<span>Save</span>
			</button>
			<button
				class="button is-generic-app-blue is-caps-lock save"
				:disabled="!isAnyInputChanged"
				@click="onApply"
			>
				<span>Apply</span>
			</button>
			<button
				class="button is-caps-lock cancel"
				@click="onClose"
			>
				<span>Cancel</span>
			</button>
		</div>
		<!-- <simplert
			:useRadius="true"
			:useIcon="true"
			ref="simplert"
		></simplert> -->
	</div>
</template>

<style lang="scss" scoped>
.break-times-form {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-width: 467px;
	min-height: 260px;

	.form-content {
		display: flex;
		flex-direction: column;
		gap: 20px;
		// max-height: 500px;
		// overflow: auto;

		.rows {
			display: flex;
			flex-direction: column;
			gap: 10px;

			.row {
				display: flex;
				gap: 10px;
				align-items: flex-end;
				// align-items: center;

				.group-input {
					display: flex;
					flex-direction: column;
				}

				.info {
					margin: 0 20px 7px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.remove-wrap {
					align-self: flex-end;
					margin: 0 0 10px 10px;

					img {
						cursor: pointer;
					}
				}

				input {
					width: 100px;

					&.error {
						background: #ffedf0;
						border: 1px solid crimson;
					}
				}
			}
		}

		button {
			align-self: flex-start;
		}
	}

	.action {
		margin-top: auto;
		display: flex;
		gap: 10px;
		justify-content: center;
	}
	.report-status-tooltip {
		min-width: 20px;
		// white-space: nowrap;
	}
}
</style>
