<template>
	<div class="wrapper">
		<div class="msg">
			<span>
				Client created. Please make sure the service fee is set before any
				shifts are created.
			</span>
		</div>
		<div class="button-wrap">
			<button
				class="button is-generic-app-blue"
				@click="redirectAndEmitModalClose"
			>
				<span>SET SERVICE FEES</span>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";

const props = defineProps<{ clientData: any }>();
const emit = defineEmits(["close-modal"]); // this component is imported in modal
const router = useRouter();

function redirectAndEmitModalClose() {
	router.push({
		name: "admin.client-details.finances",
		params: { id: props.clientData.id },
	});
	emit("close-modal");
}

// export default {
// 	props: {
// 		clientData: {
// 			type: Object,
// 			required: true,
// 		},
// 	},
// 	methods: {
// 		redirectAndEmitModalClose() {
// 			this.$router.push({
// 				name: "ClientFinancesInfo",
// 				params: { id: this.clientData.id },
// 			});
// 			this.$emit("modal-close");
// 		},
// 	},
// };
</script>

<style lang="scss" scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 1.5em;
	width: 590px;
	.msg {
		padding: 15px;
		text-align: center;
	}
	.button-wrap {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 15px;
		button {
			font-size: 0.6em;
		}
	}
}
</style>
