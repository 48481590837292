<script lang="ts" setup>
// import { parseDateObjWithTimeZone } from "@/assets/js/dateHelper";
import { Errors } from "@/assets/js/errors";
// import { getFormattedTime } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const shiftsStore = useShiftsStore();
const toastStore = useToastStore();
const props = defineProps<{
	modalProps: {
		shift: any;
		index?: number;
	};
}>();
const emit = defineEmits(["close-modal", "is-save-loading"]);

// const dateTimeOption = ref<any>(null);
// const MultiShift = ref<any>(null);
const isButtonDisabled = ref<boolean>(false);
const shiftId = ref<number | null>(null);
const dt = ref<{
	from: { time: string };
	to: { time: string };
}>({
	from: { time: "" },
	to: { time: "" },
});
const errors = ref<any>(new Errors());

function closeModal() {
	emit("close-modal");
}
// const customDateTimeOption = computed(() => {
// 	return Object.assign({}, dateTimeOption.value, { placeholder: "ESCORT?" });
// });
// const modalStartTime = computed(() => {
// 	// returns datepicker time format from server times
// 	const date = parseDateObjWithTimeZone(props.modalProps.shift.startTime);
// 	return getFormattedTime(date, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss");
// });
// const modalEndTime = computed(() => {
// 	// returns datepicker time format from server times
// 	const date = parseDateObjWithTimeZone(props.modalProps.shift.endTime);
// 	return getFormattedTime(date, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss");
// });
// const serverStartTime = computed(() => {
// 	return getFormattedTime(
// 		dt.value.from.time,
// 		"YYYY-MM-DD HH:mm:ss",
// 		"DD/MM/YYYY HH:mm",
// 	);
// });
// const serverEndTime = computed(() => {
// 	// returns null or valid date
// 	// null needed for the ability to send ESCORT in patch
// 	// const date = parseDateObjWithTimeZone(this.modalProps.endDateTime)
// 	return getFormattedTime(
// 		dt.value.to.time,
// 		"YYYY-MM-DD HH:mm:ss",
// 		"DD/MM/YYYY HH:mm",
// 	);
// });
// function setupDateTimePickerOpt() {
// 	dateTimeOption.value = Object.assign({}, calendarOptions.dateTimeOption);
// 	this.MultiShift = calendarOptions.MultiShift;
// 	this.dateTimeOption.type = "min";
// 	this.dateTimeOption.format = "DD/MM/YYYY HH:mm";
// }
async function saveTimes() {
	emit("is-save-loading", true);
	isButtonDisabled.value = true;

	try {
		const res = await shiftsStore.patchShift(
			props.modalProps.shift.id,
			{
				start_time: dt.value.from.time,
				end_time: dt.value.to.time,
			},
			props.modalProps?.index,
		);
		if (res) toastStore.openToastSuccess("Time changed.");
		else toastStore.openToastSuccess("Changing time failed.");
		isButtonDisabled.value = false;
		emit("is-save-loading", false);
		closeModal();
		return true;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		isButtonDisabled.value = false;
		emit("is-save-loading", false);
		return false;
	}
}
onMounted(() => {
	// setupDateTimePickerOpt();
	dt.value.from = { time: props.modalProps.shift.startTime?.date };
	dt.value.to = { time: props.modalProps.shift.endTime?.date };
	shiftId.value = props.modalProps.shift.id;
});
</script>
<template>
	<div class="inputs">
		<div class="field p-right10">
			<label class="label">Start time</label>
			<p class="control">
				<modern-date-picker
					v-model="dt.from.time"
					placeholder="Start Date/Time"
					:clearable="false"
					:enable-time-picker="true"
					format="dd/MM/yyyy, HH:mm"
					outputFormatString="YYYY-MM-DD HH:mm:ss"
				/>
			</p>
		</div>
		<div class="field p-left10">
			<label class="label">End time</label>
			<p class="control">
				<modern-date-picker
					v-model="dt.to.time"
					placeholder="End Date/Time"
					:clearable="false"
					:enable-time-picker="true"
					format="dd/MM/yyyy, HH:mm"
					outputFormatString="YYYY-MM-DD HH:mm:ss"
				/>
			</p>
			<p class="control"></p>
		</div>
	</div>
	<span
		v-if="errors.has('end_time')"
		class="help is-danger"
		v-html="errors.get('end_time')"
	></span>
	<div class="buttons-centered">
		<button
			class="alt button is-outlined is-caps-lock"
			@click.prevent="closeModal"
		>
			CANCEL
		</button>
		<button
			class="button is-generic-app-blue is-caps-lock"
			type="submit"
			:disabled="isButtonDisabled"
			:class="{
				'is-loading': isButtonDisabled,
			}"
			@click.prevent="saveTimes"
		>
			SUBMIT
		</button>
	</div>
</template>

<style scoped lang="scss">
.inputs {
	display: flex;
	padding: 20px;
}

.label {
	color: $label-accent-color-dark;
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}

.p-left10 {
	padding-left: 10px;
}

.p-right10 {
	padding-right: 10px;
}
</style>
