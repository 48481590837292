<script setup lang="ts">
// Define props
const props = defineProps<{
	columnName: string;
	value: {
		column: string;
		direction: string;
	};
}>();

// Define emits
const emit = defineEmits<{
	(e: "update:modelValue", value: { column: string; direction: string }): void;
}>();

// Local reactive state
const direction = ref<string>("");

// Constant values
const DIRECTIONS = {
	ASC: "asc",
	DESC: "desc",
};

// Computed properties
const isAsc = computed(() => {
	if (!props.value) return false;
	return (
		props.columnName === props.value.column &&
		props.value.direction === DIRECTIONS.ASC
	);
});

const isDesc = computed(() => {
	if (!props.value) return false;
	return (
		props.columnName === props.value.column &&
		props.value.direction === DIRECTIONS.DESC
	);
});

// Methods
function sort(dir: string) {
	direction.value =
		direction.value === dir && props.columnName === props.value.column
			? ""
			: dir;
	const column = direction.value === "" ? "" : props.columnName;
	emit("update:modelValue", {
		column,
		direction: direction.value,
	});
}
</script>

<template>
	<span class="sort">
		<i
			class="fa fa-long-arrow-up fa-rotate-180"
			:class="{ active: isAsc }"
			@click.stop="sort(DIRECTIONS.ASC)"
		></i>
		<i
			class="fa fa-long-arrow-up"
			:class="{ active: isDesc }"
			@click.stop="sort(DIRECTIONS.DESC)"
		></i>
	</span>
</template>

<style lang="scss" scoped>
$sort-color-no-active: #aebbcc;
.sort {
	display: inline-block;
	vertical-align: sub;
	margin-left: 2px;
	color: $sort-color-no-active;

	> i {
		font-size: 14px;
		cursor: pointer;
		font-weight: 600;
		width: 10px;

		&.active {
			color: var(--color-primary);
		}
	}

	> i:first-child {
		margin-top: 1px;
	}
}
</style>
