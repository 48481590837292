<script setup lang="ts">
import { useReportsStore } from "@/store/reports";

// Props
const props = defineProps<{ previewData: any }>();

// Emit for events
const emit = defineEmits(["close-modal"]);

//  store
const reportStore = useReportsStore();

// Data
const data = ref<any>();

// Computed properties
const getPagination = computed(() => reportStore.pagination);

const title = computed(() => getPagination.value.currentPageData.page_name);

const subtitles = computed(
	() => getPagination.value.currentPageData.page_header,
);

const tables = computed(() => getPagination.value.currentPageData.tables);

// Methods
const closeModal = () => {
	emit("close-modal");
};

const pageHasChanged = (pageNum: number) => {
	reportStore.fetchPageHasChanged(pageNum);
	// store.dispatch("reports/fetchPageHasChanged", pageNum);
};

const modalRef = ref<any>(null);
onMounted(() => {
	data.value = props.previewData;

	if (modalRef.value) {
		// modalRef.value.scrollTop = 0; // Scroll to top
		// nextTick(() => (modalRef.value.scrollTop = 0));
		setTimeout(() => {
			modalRef.value.scrollTop = 0;
			// modalRef.value.scrollTo({ top: 0, behavior: "smooth" });
		}, 1200);
	}
	// intervalX();

	// let id = document.getElementById("iidScroll");
	// // @ts-ignore
	// id.scrollTop = 0;
});

// const scrollInterval = ref<any>(null);
// function intervalX(timeout = 1000) {
// 	console.log(modalRef.value.scrollTop);
// 	console.dir(modalRef.value);
// 	if (!modalRef.value || modalRef.value?.scrollTop !== 0) {
// 		scrollInterval.value = setInterval(() => {
// 			intervalX();
// 		}, timeout);
// 	} else {
// 		// modalRef.value.scrollTop = 0;

// 		clearIntervalFunc();
// 	}
// }
// function clearIntervalFunc() {
// 	clearTimeout(scrollInterval.value);
// 	scrollInterval.value = null;
// }
</script>
<template>
	<div
		id="iidScroll"
		ref="modalRef"
		class="report-preview"
	>
		<h1 class="title is-3">
			{{ title }}
		</h1>
		<h4
			v-for="(subtitle, index) in subtitles"
			:key="index"
			class="subtitle"
		>
			{{ subtitle }}
		</h4>
		<!-- <div
			v-for="(table, index) in tables"
			:key="index"
		>
			{{ table }}
		</div> -->
		<report-preview-table
			v-for="(table, index) in tables"
			:key="index"
			:table="table"
			style="margin-bottom: 20px"
		></report-preview-table>
		<div style="width: 100%; height: 50px">
			<PaginationComp
				:total-pages="getPagination.totalPages"
				:current-page="getPagination.currentPage"
				@page-changed="pageHasChanged"
			></PaginationComp>
		</div>
		<button
			class="button is-danger"
			@click="closeModal"
		>
			Close
		</button>
	</div>
</template>

<style lang="scss" scoped>
.report-preview {
	padding: 0 30px 25px;
	// display: flex;
	// flex-direction: column;
	// align-items: center;
	width: 95vw;
	min-height: 130px;
	max-height: 90vh;
	overflow: auto;
	// scroll-behavior: auto !important;
	// overflow-anchor: none !important;

	button {
		float: right;
		margin-bottom: 15px;
	}

	.title {
		text-align: center;
		margin: 10px 0;
	}

	.subtitle {
		text-align: center;
		margin: 10px 0;
	}
}
</style>
