<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import type { TShiftPriceOverwriteData } from "@/assets/js/shiftPriceOverwriteType";
import { useDefaultStore } from "@/store";
import { useReportsStore } from "@/store/reports";

const store = useDefaultStore();
const reportsStore = useReportsStore();
const props = withDefaults(
	defineProps<{
		modalIsOpen: boolean;
		shiftPriceOverwritePromtError?: string | string[];
		shiftInitialData: TShiftPriceOverwriteData;
		shiftId?: number;
		errorKeys: string[];
		calcitemsMeta: any;
	}>(),
	{
		modalIsOpen: true,
		shiftPriceOverwritePromtError: null,
		shiftId: null,
	},
);

const emit = defineEmits([
	"close-modal",
	"close-prompt",
	"recalculate-shift-data",
	"override-shift",
]);
const handleCloseModal = (): void => {
	emit("close-modal");
};
const handleClosePromtEmit = (): void => {
	emit("close-prompt");
};
const handleRecalculateShift = (): void => {
	const validateCode = true;
	handleDropDown(validateCode);
	emit("recalculate-shift-data");
};
const confirmOverride = (): void => {
	openPrompt.value = false;
	emit("override-shift");
};
const handleOverride = (): void => {
	openPrompt.value = true;
};
const dataIsLoading = computed(
	() => Object.keys(props.shiftInitialData).length === 0,
);
const onChangeStartDate = (e: any) => {};
const parseTimeFromString = (
	time: string | number,
	unitIdx: number,
): number => {
	if (typeof time === "string") {
		return parseInt(time.split(":")[unitIdx]);
	}
	return time;
};
const handleDeletingRow = (idx: number, el: any): void => {
	const data = el.data;
	const title = el.title.label ?? "";
	const tableCanBeEmpty = el.meta.section_can_be_empty ?? false;
	if (data.length === 1 && tableCanBeEmpty === false) {
		promptMessage.value = `You cannot delete that row, table "${title}" need to have at least one row.`;
		openPrompt.value = true;
		isPromtError.value = true;
		return;
	}
	data.splice(idx, 1);
};
const handleAddingRow = (el: any): void => {
	const newRow: any = JSON.parse(
		JSON.stringify(el.meta.default_object_structure),
	);
	el.data.push(newRow);
};
const getInputTypeFromMeta = (metaType: string): string => {
	switch (metaType) {
		case "string": {
			return "text";
		}
		case "percentage": {
			return "text";
		}
		case "number": {
			return "number";
		}
		default: {
			return metaType;
		}
	}
};
const getInputStepFromMeta = (metaType: string): string => {
	let step = "any";
	switch (metaType) {
		case "number": {
			step = ".01";
			break;
		}
		default: {
			break;
		}
	}
	return step;
};
const updateTimeInput = (val: any, idx: number, event: Event): void => {
	const target = event.target as HTMLInputElement;
	const time = val.value;
	const parsedTime = time.split(":");
	parsedTime[idx] = target.value;
	val.value = parsedTime.join(":");
};
/**
 * handle disabling all the drop down elements that are already selected
 */
const selectedDropDownElements = ref<any[]>([]);
const handleDropDown = (validateCode: boolean = false): void => {
	if (!dataIsLoading.value && props?.shiftInitialData?.calculation_data) {
		selectedDropDownElements.value = [];
		for (const calculation_data of props.shiftInitialData.calculation_data) {
			for (const el of calculation_data.data) {
				if (
					validateCode &&
					el?.code &&
					(!el?.code?.value ||
						(typeof el?.code?.value === "string" &&
							el?.code?.value?.length === 0))
				) {
					promptMessage.value =
						"All codes need to be set. Please select a code.";
					openPrompt.value = true;
					isPromtError.value = true;
					el.code.codeError = true;
				}

				if (
					el?.code?.value &&
					!selectedDropDownElements.value.includes(el?.code?.value)
				) {
					selectedDropDownElements.value.push(el.code.value);
					if (props.calcitemsMeta && props.calcitemsMeta[el.code.value]) {
						for (const key in props.calcitemsMeta[el.code.value]) {
							if (
								el[key] &&
								props.calcitemsMeta[el.code.value][key].required_value !== null
							) {
								el[key].value =
									props.calcitemsMeta[el.code.value][key].required_value;
							}
							if (
								el[key] &&
								props.calcitemsMeta[el.code.value][key].editable !== null
							) {
								el[key].editable =
									props.calcitemsMeta[el.code.value][key].editable;
							}
						}
					}
				}
			}
			if (calculation_data?.meta?.dropdown) {
				for (const dropDownEl of calculation_data.meta.dropdown) {
					dropDownEl.disabled = selectedDropDownElements.value.includes(
						dropDownEl.value,
					)
						? true
						: false;
				}
			}
		}
	}
};
onUpdated(() => {
	handleDropDown();
	handleCalculationScenarios();
});
const openPrompt = ref<boolean>(false);
const handleClosePrompt = (): void => {
	openPrompt.value = false;
	isPromtError.value = false;
	if (props.shiftPriceOverwritePromtError) {
		handleClosePromtEmit();
	}
	if (Object.keys(props.shiftInitialData).length === 0) handleCloseModal();
};
function onDropDownSelect(option: any, val: any): void {
	option.disabled = true;
	val.value = option.value;
	val.label = option.label;
	val.codeError = false;
	handleDropDown();
}
// const canUseIncentive = ref<boolean>(false);
// function enableIncentive (): void {
//     canUseIncentive.value = !canUseIncentive.value;
// }
const promptMessage = ref<string>(
	"Are you sure that you want to confirm overriding this shift?",
);
const isPromtError = ref<boolean>(false);
const sfMonetaryValue = computed(
	() => store.initialStatus?.monetary_symbol || "eur",
);
const recalculationScenariosOptions = computed(
	() => reportsStore.recalculationScenario,
);
let initialCalcScenarioHandled: boolean = false;
const selectedRecalcScenario = ref<any>(recalculationScenariosOptions.value[0]);
function handleCalculationScenarios(): void {
	const clcCode2 =
		props.shiftInitialData?.shift_data?.clc_code2?.clc_code2.value ?? null;
	if (
		initialCalcScenarioHandled === false &&
		clcCode2 &&
		Array.isArray(recalculationScenariosOptions.value)
	) {
		initialCalcScenarioHandled = true;
		recalculationScenariosOptions.value.find((obj) => {
			if (obj.code === clcCode2) {
				selectedRecalcScenario.value = obj;
			}
		});
	} else {
		if (props.shiftInitialData?.shift_data?.clc_code2?.clc_code2.value) {
			props.shiftInitialData.shift_data.clc_code2.clc_code2.value =
				selectedRecalcScenario.value.code;
		}
	}
}
</script>
<template>
	<div
		v-if="props.modalIsOpen"
		class="modal"
	>
		<div
			class="modal-overlay"
			@click="handleCloseModal"
		></div>
		<prompt-modal
			:message="
				shiftPriceOverwritePromtError
					? shiftPriceOverwritePromtError
					: promptMessage
			"
			:visible="shiftPriceOverwritePromtError ? true : openPrompt"
			:isError="shiftPriceOverwritePromtError ? true : isPromtError"
			@cancel="handleClosePrompt"
			@confirm="confirmOverride"
		/>
		<button
			v-if="!openPrompt"
			class="native-button exit-modal-button"
			@click="handleCloseModal"
		>
			X
		</button>
		<!-- <div class="modal-content"></div> -->
		<div class="modal-content">
			<label class="label">SHIFT PRICE OVERWRITE</label>
			<div
				v-if="dataIsLoading"
				class="spinner-wrap"
			>
				<img src="@/assets/image/loader.svg" />
			</div>
			<div
				v-if="!dataIsLoading && shiftInitialData?.shift_data?.clc_code2"
				class="shift-break-wrapper"
			>
				<label class="label">
					{{ shiftInitialData.shift_data.clc_code2.clc_code2.label }}
				</label>

				<multiselect-form
					v-model="selectedRecalcScenario"
					:options="recalculationScenariosOptions"
					:close-on-select="true"
					:searchable="true"
					select-label=""
					value-prop="code"
					label="label"
					placeholder="Calculation types"
					track-by="order"
					deselect-label="Can't remove this value"
					mode="single"
					:internal-search="false"
					:canClear="false"
					:canDeselect="false"
					style="min-width: 250px"
				/>
			</div>
			<div
				v-if="!dataIsLoading && shiftInitialData?.shift_data?.shift_date_time"
				class="shift-date-time-wrapper"
			>
				<div
					v-for="el in shiftInitialData.shift_data.shift_date_time"
					:key="el.key"
				>
					<label class="label">{{ el.label }}</label>
					<p class="control">
						<modern-date-picker
							v-model="el.value"
							:isDisabled="!el.editable"
							:enable-time-picker="true"
							placeholder="Date from"
							:clearable="false"
							format="dd/MM/yyyy HH:mm"
							outputFormatString="YYYY-MM-DD HH:mm:ss"
							style="width: 250px; border-radius: 21px; height: 40px"
							@change="onChangeStartDate"
						/>
					</p>
				</div>
			</div>
			<div
				v-if="!dataIsLoading && shiftInitialData?.shift_data?.shift_break"
				class="shift-break-wrapper"
			>
				<div
					v-for="el in shiftInitialData.shift_data.shift_break"
					:key="el.key"
				>
					<label class="label">{{ el.label }}</label>
					<!-- <div class="has-suffix" :style="{background: errorKeys.includes(el.key) ? '#f4cccb' : ''}"> -->
					<div class="has-suffix">
						<p class="control">
							<input
								v-model="el.value"
								class="input"
								:disabled="!el.editable"
								:type="el.meta.type"
							/>
							<span class="is-suffix">in minutes</span>
						</p>
					</div>
				</div>
			</div>
			<div v-if="!dataIsLoading && shiftInitialData?.shift_data?.shift_parts">
				<div
					v-for="(part, partIdx) in shiftInitialData.shift_data.shift_parts"
					:key="partIdx"
					class="shift-date-time-wrapper"
				>
					<div
						v-for="(el, elIdx) in part"
						:key="elIdx"
					>
						<label class="label">{{ el.label }}</label>
						<p class="control">
							<modern-date-picker
								v-model="el.value"
								:isDisabled="!el.editable"
								:enable-time-picker="true"
								placeholder="Date from"
								:clearable="false"
								format="dd/MM/yyyy HH:mm"
								outputFormatString="YYYY-MM-DD HH:mm:ss"
								style="width: 250px; border-radius: 21px; height: 40px"
							/>
						</p>
					</div>
				</div>
			</div>
			<div
				v-if="!dataIsLoading && shiftInitialData?.shift_data?.service_fee"
				class="shift-break-wrapper"
			>
				<div>
					<label class="label">
						{{ shiftInitialData.shift_data.service_fee.svf_value.label }}
					</label>
				</div>
				<div>
					<input
						v-model.number="
							shiftInitialData.shift_data.service_fee.svf_value.value
						"
						type="number"
						:step="
							getInputStepFromMeta(
								shiftInitialData.shift_data.service_fee.svf_value.meta.type,
							)
						"
						min="0"
						:placeholder="
							shiftInitialData.shift_data.service_fee.svf_value.label ?? ''
						"
						class="input"
					/>
					<span>%</span>
				</div>
			</div>
			<div
				v-if="!dataIsLoading && shiftInitialData?.shift_data?.incentive"
				class="shift-break-wrapper"
			>
				<div>
					<!-- <label v-if="!shiftInitialData.shift_data.incentive.INCT.value || shiftInitialData.shift_data.incentive.INCT.value === 0" class="label">Would you like to add an incentive?</label>
                    <label v-else class="label">Would you like to change the incentive?</label> -->
					<label class="label">
						{{ shiftInitialData.shift_data.incentive.INCT.label }}
					</label>
				</div>
				<div>
					<input
						v-model.number="shiftInitialData.shift_data.incentive.INCT.value"
						type="number"
						min="0"
						:disabled="!shiftInitialData.shift_data.incentive.INCT.editable"
						:placeholder="
							shiftInitialData.shift_data.incentive.INCT.label ?? ''
						"
						class="input"
					/>
					<span>{{ sfMonetaryValue }}</span>
				</div>
			</div>
			<div
				v-if="
					!dataIsLoading &&
					shiftInitialData?.shift_data?.shf_price_overwrite_note
				"
				class="shift-break-wrapper"
			>
				<!-- <label class="label">{{ el.label }}</label> -->
				<label class="label">
					{{
						shiftInitialData.shift_data.shf_price_overwrite_note
							.shf_price_overwrite_note.label
					}}
				</label>
				<div class="has-suffix">
					<p class="control">
						<textarea
							v-model="
								shiftInitialData.shift_data.shf_price_overwrite_note
									.shf_price_overwrite_note.value
							"
							:disabled="
								!shiftInitialData.shift_data.shf_price_overwrite_note
									.shf_price_overwrite_note.editable
							"
							cols="30"
							rows="10"
						></textarea>
					</p>
				</div>
			</div>
			<template v-if="!dataIsLoading && shiftInitialData?.calculation_data">
				<div
					v-for="(el, idx) in shiftInitialData.calculation_data"
					:key="idx"
					class="section"
				>
					<div>
						<label class="label">{{ el.title.label }}</label>
						<table v-if="el.data">
							<thead>
								<tr>
									<template v-if="el.headers">
										<th
											v-for="(header, thIdx) in el.headers"
											:key="thIdx"
										>
											{{ header.label }}
										</th>
									</template>
									<th
										v-if="el?.meta?.can_delete_a_row"
										class="delete-row"
									>
										Actions
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(dataValues, trIdx) in el.data"
									:key="trIdx"
								>
									<td
										v-for="(val, tdIdx) in dataValues"
										:key="tdIdx"
									>
										<div
											v-if="val.meta.type === 'time'"
											class="time-td"
										>
											<input
												type="number"
												:disabled="!val.editable"
												:value="parseTimeFromString(val.value, 0)"
												@input="updateTimeInput(val, 0, $event)"
											/>
											:
											<input
												type="number"
												:disabled="!val.editable"
												:value="parseTimeFromString(val.value, 1)"
												@input="updateTimeInput(val, 1, $event)"
											/>
										</div>
										<div v-else-if="val.meta.type === 'checkbox'">
											<switch-component
												v-model="val.value"
												:disabled="!val.editable"
												theme="bulma"
												color="blue"
												:emitOnMount="false"
											></switch-component>
										</div>
										<div v-else-if="val.meta.type === 'dropdown'">
											<multiselect-form
												v-model="val.value"
												:title="val.label"
												deselect-label="Can't remove this value"
												select-label=""
												track-by="value"
												label="value"
												placeholder="Select code"
												:options="el.meta.dropdown"
												:searchable="false"
												:allow-empty="false"
												value-prop="value"
												mode="single"
												:internal-search="false"
												:canClear="false"
												:canDeselect="false"
												style="width: 120px; border: none"
												:class="
													val?.codeError
														? 'multiselect__tags_no_border_error'
														: 'multiselect__tags_no_border'
												"
												@select="onDropDownSelect($event, val)"
											>
												<template #option-single="slotProps">
													<strong>{{ slotProps.props.option.value }}</strong>
												</template>
											</multiselect-form>
										</div>
										<input
											v-else
											v-model="val.value"
											:title="val.label"
											:step="getInputStepFromMeta(val.meta.type)"
											:type="getInputTypeFromMeta(val.meta.type)"
											:disabled="!val.editable"
										/>
									</td>
									<td
										v-if="el?.meta?.can_delete_a_row"
										class="delete-row"
									>
										<i
											title="Click to delete a row"
											class="fa fa-trash"
											aria-hidden="true"
											@click="handleDeletingRow(trIdx, el)"
										></i>
									</td>
								</tr>
							</tbody>
						</table>
						<button
							v-if="
								el?.meta?.can_insert_new_row &&
								el.meta.dropdown.length > el.data.length
							"
							title="Click to add a new row"
							class="button is-generic-app-blue"
							@click="handleAddingRow(el)"
						>
							Add new row
						</button>
					</div>
				</div>
			</template>
			<div
				v-if="!dataIsLoading"
				class="actions"
			>
				<button
					title="Click to preview a calculation"
					class="button is-generic-app-blue"
					@click="handleRecalculateShift"
				>
					Preview Calculation
				</button>
				<button
					title="Click to confirm overridding a shift"
					class="button is-generic-app-blue"
					@click="handleOverride"
				>
					Override Shift
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$border-top-color-dark: #00000034;
$text-color-lighter: $label-color;
$scrollbar-color: $scrollbar-color;
$scrollbar-color-thumb: $scrollbar-color-thumb;
$table-header-background: #f2f7fa;
$tr-hover-background: #f5f5f5;
$td-text-color: #405168;
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	.exit-modal-button {
		border-radius: 290486px;
		border: none;
		cursor: pointer;
		display: inline-block;
		font-size: 20px;
		color: $white;
		outline: none;
		transform-origin: center center;
		vertical-align: top;
		height: 40px;
		width: 40px;
		position: fixed;
		right: 20px;
		top: 20px;
		z-index: 5000;
		transition: background-color 0.2s ease;
		background: rgba(68, 67, 67, 0.719);
		&:hover {
			background: rgba(68, 67, 67, 0.664);
		}
	}
	.modal-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.modal-content {
		position: absolute;
		background-color: $white;
		padding: 10px;
		border-radius: 5px;
		width: 1100px;
		height: 92vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: auto;
		overflow-x: auto;
		.spinner-wrap {
			margin: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			img {
				width: 100px;
			}
		}
		.section {
			position: relative;
			top: 0;
			margin: 5px 0;
			width: 950px;
			padding: 5px;
			border-top: 1px solid $border-top-color-dark;
			table {
				margin: 5px 0;
				border: 1px solid #9999998f;
				border-radius: 5px;
				width: auto;
				table-layout: fixed;
				border-collapse: collapse;
				th {
					color: $text-color-lighter;
					background: $table-header-background;
					text-align: left;
					padding: 7px 10px;
					z-index: 1;
					border-top: 1px solid #dde6eb;
					overflow: visible !important;
				}
				td {
					font-size: 0.9rem;
					padding: 7px 10px;
					border-top: 1px solid #dde6eb;
					color: $td-text-color;
					.flexer {
						display: flex;
						align-items: center;

						span {
							display: flex;
							align-items: center;
						}
						span:first-child {
							margin-right: auto;
						}
					}
				}
				td,
				th {
					cursor: alias;
					border: 1px solid #9999998f;
					text-align: center;
					vertical-align: middle;
					height: 35px;
					width: 120px;
					input {
						width: 100%;
						height: 100%;
						margin: 0;
						border: none;
						text-align: center;
						outline: none;
						&:hover {
							background: #64626225;
						}
						&:disabled {
							background: none;
						}
					}
				}
				.time-td {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					/* stylelint-disable-next-line no-descending-specificity */
					input {
						text-align: center;
						height: 100%;
					}
					input[type="number"]::-webkit-inner-spin-button,
					input[type="number"]::-webkit-outer-spin-button {
						/* stylelint-disable-next-line property-no-vendor-prefix */
						-webkit-appearance: none;
						appearance: none;
						margin: 0;
					}
					input[type="number"] {
						/* stylelint-disable-next-line property-no-vendor-prefix */
						-moz-appearance: textfield;
					}
				}
				input[type="text"]:disabled,
				input[type="number"]:disabled {
					cursor: not-allowed;
					/* stylelint-disable-next-line property-no-vendor-prefix */
					-moz-appearance: textfield;
				}
				select {
					outline: none;
					border: none;
					background: none;
					width: 100%;
					height: 25px;
					margin: 0;
					text-align: center;
					&:hover {
						cursor: pointer;
						background: #00000010;
					}
				}
				.delete-row {
					text-align: center;
					width: 80px;
					i {
						border-radius: 290486px;
						&:hover {
							cursor: pointer;
							color: rgba(0, 0, 0, 0.534);
						}
					}
				}
			}
			div {
				h3 {
					margin: 0 auto;
				}
			}
		}
		.actions {
			margin: 10px 0;
			display: flex;
			justify-content: center;
			align-items: center;
			button {
				margin: 0 5px;
			}
		}
		.shift-date-time-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 550px;
			div {
				margin: 3px 3px 3px 0;
			}
			/* stylelint-disable-next-line no-descending-specificity */
			input {
				width: 250px;
			}
		}
		.element-flex-wrapper {
			display: flex;
			align-items: center;
			width: 550px;
			input[type="checkbox"] {
				cursor: pointer;
			}
			/* stylelint-disable-next-line no-descending-specificity */
			input {
				width: 250px;
			}
			div {
				height: 100%;
				width: 50%;
				padding: 5px;
			}
		}
		.shift-break-wrapper {
			width: 550px;
			/* stylelint-disable-next-line no-descending-specificity */
			input {
				width: 250px;
				margin: 0 3px 0 0;
			}
		}
		textarea {
			border: 1px solid #9999998f;
			border-radius: 5px;
			width: 100%;
			max-width: 100%;
			height: 30px;
			max-height: 200px;
			margin: 0;
			outline: none;
			padding: 5px;
		}
	}
}

::v-deep(.vue-tooltip) {
	z-index: 105;
}
.label {
	color: $label-accent-color-dark;
}
</style>
