<script setup lang="ts">
import { parseDateTime, parseExpenseValue } from "@/assets/js/helpers";
import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const monetarySymbol = store.initialStatus.monetary_symbol;
const reasonForRejection = ref<string>("");
const MAX_CHARACTER_COUNT = ref<number>(255);
const CHARACTER_COUNT_FOR_REJECTION_REASON = ref<number>(
	MAX_CHARACTER_COUNT.value,
);

const emit = defineEmits([
	"close-modal",
	"update-rejection-reason",
	"reject-expense",
	"approve-expense",
]);

const props = withDefaults(
	defineProps<{
		expense: any;
		activeTab: any;
		rejectData?: any;
		cutOffData?: any;
		signOffData?: any;
		errors?: any; // expenseErrors.value it is passed through the modal from the store but it is not reactive
		confirmBtnTxt?: string;
		cancelBtnTxt?: string;
	}>(),
	{
		confirmBtnTxt: "Release",
		cancelBtnTxt: "Cancel",
		rejectData: null,
		cutOffData: null,
		signOffData: null,
		errors: null,
	},
);

// function resetState() {
// 	skipBefore.value = false;
// 	beforeRej.value = false;
// 	afterRej.value = false;
// 	confirmationRejectData.value = false;
// 	displayFinalMessage.value = false;
// }

function onCloseModal() {
	emit("close-modal");
	// if (expenseErrors.value) {
	// 	resetState();
	// } else {
	// 	emit("close-modal");
	// }
}

const expenseErrors = computed(() => store.getExpenseErrors());

const rejectionReasonBool = ref<boolean>(false);
function onReject() {
	rejectionReasonBool.value = true;
	// emit("close-modal");
}
// testing modal in modal
const isModalOpen = ref<boolean>(false);
const initialModalInModalOpen = ref<boolean>(false);
const closeModal = () => {
	isModalOpen.value = false;
	initialModalInModalOpen.value = false;
};
function onRejectPrompt() {
	isModalOpen.value = true;
	// document.addEventListener("click", handleClickOutside);
	// emit("close-modal");
}
const rejectButtonClickHandler = computed(() => {
	return rejectionReasonBool.value ? onRejectPrompt : onReject;
});
function parseDistance(distance: string | null): string {
	return distance ? `${distance} km` : "";
}
function onChangeReasonForRejection(event: Event): void {
	const target = event.target as HTMLInputElement;
	const value: string = target.value;
	CHARACTER_COUNT_FOR_REJECTION_REASON.value = MAX_CHARACTER_COUNT.value;
	CHARACTER_COUNT_FOR_REJECTION_REASON.value =
		CHARACTER_COUNT_FOR_REJECTION_REASON.value -
		reasonForRejection.value.length;
	emit("update-rejection-reason", value);
}

const getAmountLabelFromActiveTab = computed(() => {
	switch (props.activeTab.id) {
		case 1: {
			return "Amount";
		}
		case 2: {
			return "Approved amount";
		}
		case 3: {
			return "Rejected amount";
		}
		default: {
			return "Amount";
		}
	}
});

const skipBefore = ref<boolean>(false);
const beforeRej = ref<boolean>(false);
const afterRej = ref<boolean>(false);
const confirmationRejectData = ref<boolean>(false);
const showSignOffWarning = ref<boolean>(
	Boolean(props.signOffData?.before_api_1) ? false : true,
);
const checkRejectAfterBool = computed(() => props.rejectData?.after_api);
const canAcceptSignOff = computed(() => {
	// const validStatuses = ["Pending", "Cancelled"];
	const validStatuses = ["Pending"];
	return props.expense?.status && validStatuses.includes(props.expense?.status);
});
function beforeNo(): void {
	if (!props.rejectData?.before_api_2) {
		closeModal();
	}
	confirmationRejectData.value = true;
}
function beforeYes(): void {
	skipBefore.value = true;
	beforeRej.value = false;
	rejectSolutions();
}
function beforeSubmit(): void {
	beforeRej.value = true;
}
function afterSubmit(): void {
	afterRej.value = true;
}
function closeModalFinish(): void {
	// console.log(props.errors, "props.errors");
	displayFinalMessage.value = true;
	// if (props.errors || expenseErrors.value) {
	// 	displayFinalMessage.value = true;
	// } else {
	// 	displayFinalMessage.value = true;
	// 	setTimeout(() => {
	// 		emit("close-modal");
	// 	}, 2000);
	// }
}
async function submitRejection() {
	// placed here from ExpensesPage because it needs to be done asynchronously
	await store.expenseReject({
		id: props.expense.id,
		reject_reason: reasonForRejection.value,
	});
	// emit only refresh data
	emit("reject-expense", reasonForRejection.value);
	finishSignOffMessage.value = "Expense successfully rejected.";
	closeModalFinish();
}
function rejectSolutions(): void {
	if (!props.rejectData?.before_api_1 && !props.rejectData?.before_api_2) {
		// submitRejection();
	}
	closeModal();
	if (!skipBefore.value && !checkRejectAfterBool.value) {
		beforeSubmit();
		return;
	}
	submitRejection();
	if (checkRejectAfterBool.value) {
		afterSubmit();
		return;
	}
}
function submitAccept() {
	emit("approve-expense");
	finishSignOffMessage.value = "Expense successfully approved.";
	closeModalFinish();
}
async function onSubmitAccept(): Promise<void> {
	if (showSignOffWarning.value) {
		// accept is clicked, confirm even if there is a message
		submitAccept();
	} else if (props.signOffData?.before_api_1) {
		showSignOffWarning.value = true;
	}
}

const displayFinalMessage = ref<boolean>(false);
const finishSignOffMessage = ref<string>("");
</script>

<template>
	<div
		v-if="!displayFinalMessage"
		class="expense-sign-off-modal"
	>
		<div class="table-wrapper">
			<table>
				<tr v-if="props.expense.id">
					<th>ID</th>
					<td>{{ props.expense.id }}</td>
				</tr>
				<tr v-if="props.expense?.client && props.expense?.client?.name">
					<th>Client Name</th>
					<td>{{ props.expense.client.name }}</td>
				</tr>
				<tr v-if="props.expense?.temp && props.expense?.temp?.name">
					<th>Temp Name</th>
					<td>{{ props.expense.temp.name }}</td>
				</tr>
				<tr v-if="props.expense?.startTime || props.expense?.date">
					<th>Date of Expense</th>
					<td>
						{{ parseDateTime(props.expense.startTime || props.expense.date) }}
					</td>
				</tr>
				<tr
					v-if="props.expense?.expenseType && props.expense?.expenseType?.label"
				>
					<th>Type</th>
					<td>{{ props.expense.expenseType.label }}</td>
				</tr>
				<tr v-if="props.expense?.engine_type">
					<th>Engine type</th>
					<td>{{ props.expense.engine_type }}</td>
				</tr>
				<tr v-if="props.expense?.distance">
					<th>Distance</th>
					<td>{{ parseDistance(props.expense.distance) }}</td>
				</tr>
				<tr v-if="props.expense?.old_value">
					<th>Provisionally calculated amount</th>
					<td>
						{{ parseExpenseValue(props.expense.old_value, monetarySymbol) }}
					</td>
				</tr>
				<tr v-if="props.expense?.value">
					<th>{{ getAmountLabelFromActiveTab }}</th>
					<td>{{ parseExpenseValue(props.expense.value, monetarySymbol) }}</td>
				</tr>
			</table>
		</div>
		<div
			v-if="rejectionReasonBool && !beforeRej && !afterRej"
			class="flex-column-center wd-100-p"
		>
			<div class="flex-space-between wd-100-p">
				<label class="label">Reason for rejection</label>
				<label class="label">
					Characters left: {{ CHARACTER_COUNT_FOR_REJECTION_REASON }}
				</label>
			</div>
			<textarea
				v-model="reasonForRejection"
				class="input wd-100-p pd-3"
				placeholder="Reason for rejection (at least 3 characters)"
				:maxlength="MAX_CHARACTER_COUNT"
				style="height: 120px"
				@input="onChangeReasonForRejection"
			></textarea>
		</div>
		<div
			v-if="
				canAcceptSignOff &&
				!rejectionReasonBool &&
				!beforeRej &&
				!afterRej &&
				cutOffData?.before_api_1 &&
				!showSignOffWarning
			"
		>
			{{ cutOffData?.before_api_1 }}
		</div>
		<div
			v-if="
				canAcceptSignOff &&
				!rejectionReasonBool &&
				!beforeRej &&
				!afterRej &&
				signOffData?.before_api_1 &&
				showSignOffWarning
			"
		>
			{{ signOffData?.before_api_1 }}
		</div>
		<div
			v-if="!beforeRej && !afterRej"
			class="wrap-buttons"
		>
			<button
				v-if="!rejectionReasonBool && canAcceptSignOff"
				@click="onSubmitAccept"
			>
				{{ showSignOffWarning ? "Confirm" : "Accept" }}
			</button>
			<button
				:disabled="
					(rejectionReasonBool && reasonForRejection.length < 3) ||
					props.expense.reject_disabled
				"
				:class="{
					'not-allowed': rejectionReasonBool && reasonForRejection.length < 3,
				}"
				class="reject-button"
				@click="rejectButtonClickHandler"
			>
				Reject
			</button>
			<button
				v-if="rejectionReasonBool"
				@click="rejectionReasonBool = !rejectionReasonBool"
			>
				Cancel
			</button>
		</div>
		<modal-in-modal
			:isModalOpen="isModalOpen"
			:initialModalInModalOpen="initialModalInModalOpen"
			:confirmMessage="'Are you sure you want to reject this expense?'"
			@confirm-action="rejectSolutions"
			@close-modal="isModalOpen = false"
			@initial-model-open="initialModalInModalOpen = isModalOpen"
		>
			<template #modal-confirm-message>
				<p>
					<strong>Are you sure you want to reject this expense?</strong>
				</p>
			</template>
		</modal-in-modal>

		<div
			v-if="beforeRej && !afterRej"
			class="before-rej"
		>
			<div
				v-if="!confirmationRejectData"
				class="without-before-api-2"
			>
				<div
					v-if="rejectData?.before_api_1"
					class="flex-center mg-10 text-centered"
				>
					<p>
						<strong>{{ rejectData.before_api_1 }}</strong>
					</p>
				</div>
				<div
					class="wrap-buttons"
					style="margin: 10px"
				>
					<button
						class="button is-generic-app-blue"
						@click="beforeYes"
					>
						YES
					</button>
					<button
						class="button is-generic-app-blue"
						@click="beforeNo"
					>
						NO
					</button>
				</div>
			</div>
			<div
				v-if="rejectData?.before_api_2 && confirmationRejectData"
				class="if-exist-before-api-2"
			>
				<div class="flex-center mg-10 text-centered">
					<p>
						<strong>{{ rejectData.before_api_2 }}</strong>
					</p>
				</div>
				<div
					class="wrap-buttons"
					style="margin: 10px"
				>
					<button
						class="button is-generic-app-blue"
						@click="onCloseModal"
					>
						OK
					</button>
				</div>
			</div>
		</div>
		<div
			v-if="afterRej && rejectData?.after_api"
			class="after-rej"
		>
			<div class="flex-center mg-10 text-centered">
				<p>
					<strong>{{ rejectData.after_api }}</strong>
				</p>
			</div>
			<div
				class="wrap-buttons"
				style="margin: 10px"
			>
				<button
					class="button is-generic-app-blue"
					@click="onCloseModal"
				>
					OK
				</button>
			</div>
		</div>
	</div>
	<div
		v-else
		class="expense-sign-off-modal"
	>
		<div class="flex-center mg-10 text-centered">
			<div v-if="Array.isArray(expenseErrors)">
				<p
					v-for="(msg, idx) in expenseErrors"
					:key="idx"
					class="error-text"
				>
					{{ msg }}
					<br />
				</p>
			</div>
			<div v-else-if="expenseErrors">
				<p class="error-text">
					{{ expenseErrors }}
					<br />
				</p>
			</div>
			<p v-else>
				{{ finishSignOffMessage }}
			</p>
		</div>
		<div
			class="wrap-buttons"
			style="margin: 10px"
		>
			<button
				class="button is-generic-app-blue"
				@click="onCloseModal"
			>
				OK
			</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
$modal-overlay: $modal-overlay;
.expense-sign-off-modal {
	position: relative;
	padding: 0 30px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	min-height: 130px;
	max-height: 600px;
	textarea {
		resize: none;
	}
	.overlay {
		position: fixed;
		inset: 0;
		background: $modal-overlay;
		z-index: 101;

		&.transparent {
			background: transparent;
		}
	}
	.confirm-modal {
		position: absolute;
		padding: 10px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 300px;
		height: 300px;
		z-index: 102;
		background: $white-color;
		border-radius: 10px;
	}
	button {
		outline: none !important;
	}
	> div {
		margin: 5px 0;
	}
	.wrap-buttons {
		margin: 10px;
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		align-items: center;
		justify-content: center;
		button {
			margin: 0 5px;
		}
	}
	.content {
		display: flex;
		justify-content: center;
		align-items: center;
		> div {
			margin: 5px;
		}
	}

	.table-wrapper {
		$text-color-lighter: $label-color;
		$scrollbar-color: $scrollbar-color;
		$scrollbar-color-thumb: $scrollbar-color-thumb;
		$table-header-background: #f2f7fa;
		$tr-hover-background: #f5f5f5;
		$td-text-color: #405168;

		--row-back: white;
		--row-back-odd: var(--vt-c-white-soft);

		display: flex;
		gap: 10px;
		overflow: auto;
		width: 100%;

		@document url-prefix() {
			// Only for Firefox
			* {
				scrollbar-color: $scrollbar-color $scrollbar-color-thumb;
				scrollbar-width: thin;
			}
		}

		&::-webkit-scrollbar {
			background-color: $scrollbar-color;
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $scrollbar-color-thumb;
			border-radius: 5px;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			border: 1px solid #dde6eb;
			td {
				font-size: 0.9rem;
				padding: 7px 10px;
				border-top: 1px solid #dde6eb;
				color: $td-text-color;
				&:not(.extra-row) {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 300px;
				}
			}

			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}

			tr {
				height: 40px;
				&:hover {
					background: $tr-hover-background;
				}
			}

			&.border {
				border: 1px solid gray;

				th,
				td {
					border: 1px solid gray;
				}
			}

			&.sticky {
				th {
					position: sticky;
					top: 0;
				}
			}
		}
	}
}
</style>
