import { SHIFT_STATUSES } from "@/assets/js/helpers";
import { useDefaultStore } from ".";
import { useSessionStore } from "./session";
import type {
	TModalErrorParam,
	TTableColumnWithPermissionsAndTab,
} from "@/assets/js/globalTypes";
import { parseErrors } from "@/assets/js/parseErrors";
import api from "@/api";
import { useToastStore } from "./toast";

export const useShiftsStore = defineStore("shiftsStore", () => {
	const toastStore = useToastStore();
	const VISIBLE_COLUMNS = {
		selectAll: true,
		shiftType: true,
		status: true,
		name: true,
		categories: true,
		date: true,
		time: true,
		client: true,
		location: true,
		createdBy: true,
		shiftID: true,
		action: true,
		view: true,
	};
	const SIGN_OFF_IN_ARBITRATION = 1;
	const VISIBLE_TOOL_ICONS = {
		tempUnassignIcon: false,
		tempEditingIcon: false,
		timeEditingIcon: false,
	};
	const sessionStore = useSessionStore();
	const store = useDefaultStore();
	const can = sessionStore.can;
	const isUserTemp = sessionStore.isUserTemp;
	const activeTab = ref<any>(null);

	function getActiveTab(): void {
		return activeTab.value;
	}
	function setActiveTab(val: any): void {
		activeTab.value = val;
	}

	/** errors */
	const errors = ref<TModalErrorParam>(null);
	function getErrors(): TModalErrorParam {
		return errors.value;
	}
	function setErrors(err: TModalErrorParam): void {
		errors.value = err;
	}
	/** errors */

	const tabs = computed(() => {
		const resetParams: {
			filter: any;
			view: any;
			dash_filter: any;
			calendar: any;
			shift_id: any;
		} = {
			filter: undefined,
			view: undefined,
			dash_filter: undefined,
			calendar: undefined,
			shift_id: undefined,
		};
		return [
			{
				label: "PENDING",
				name: "pending",
				routeName: "admin.shift-schedule.shifts",
				routeQuery: {
					status: SHIFT_STATUSES.pending,
					page: 1,
					...resetParams,
				},
				conditions: null,
				permissions: null,
				systemPermissions: null,
			},
			{
				label: "ACTIVE",
				name: "active",
				routeName: "admin.shift-schedule.shifts",
				routeQuery: {
					status: SHIFT_STATUSES.active,
					page: 1,
					...resetParams,
				},
				conditions: null,
				permissions: null,
				systemPermissions: null,
			},
			{
				label: "COMPLETED",
				name: "completed",
				routeName: "admin.shift-schedule.shifts",
				routeQuery: {
					status: SHIFT_STATUSES.completed,
					page: 1,
					...resetParams,
				},
				conditions: null,
				permissions: null,
				systemPermissions: null,
			},
			{
				label: "NEVER FILLED",
				name: "never_filled",
				routeName: "admin.shift-schedule.shifts",
				routeQuery: {
					status: SHIFT_STATUSES.never_filled,
					page: 1,
					...resetParams,
				},
				conditions: null,
				permissions: null,
				systemPermissions: null,
			},
			{
				label: "CANCELLED",
				name: "cancelled",
				routeName: "admin.shift-schedule.shifts",
				routeQuery: {
					status: SHIFT_STATUSES.cancelled,
					page: 1,
					...resetParams,
				},
				conditions: null,
				permissions: null,
				systemPermissions: null,
			},
		];
	});
	const filteredTabs = computed(() => {
		return tabs.value.filter((tab) => {
			// Custom code exit
			const isBlockedTemp = ["never_filled", "cancelled"];
			if (isBlockedTemp.includes(tab.name) && isUserTemp) {
				return false;
			}

			const hasPerm = tab.permissions?.length
				? tab.permissions.every((perm) => can(perm))
				: true;
			const hasSysPerm = tab.systemPermissions?.length
				? tab.systemPermissions.every((tabPerm) =>
						store.initialStatus ? store.initialStatus[tabPerm] : false,
					)
				: true;
			const hasCondition = tab.conditions?.length
				? tab.conditions.every(Boolean)
				: true;
			return hasPerm && hasSysPerm && hasCondition;
		});
	});

	const shiftStatuses = ref<any>([]);
	async function fetchShiftStatues() {
		try {
			const res = await api.getShiftStatuses();
			shiftStatuses.value = res.data?.data?.payroll_shift_statuses ?? [];
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}
	const shifts = ref<any>(null);
	const pagination = ref<any>(null);
	const dataIsLoading = ref<boolean>(true);
	const isPaginationLoading = ref<boolean>(false);
	async function fetchShifts({ params, cancelTokenSource, raceObj }) {
		shifts.value = null;
		params.url = isUserTemp ? "/shifts" : "/admin/shifts";
		if (params.metaOnly === true) {
			pagination.value = null;
			isPaginationLoading.value = true;
		} else {
			shifts.value = null;
			dataIsLoading.value = true;
		}
		try {
			const res = await api.fetchShifts(params, cancelTokenSource);
			if (raceObj.curr === raceObj.arr.at(-1)) {
				if (params.metaOnly === true) {
					pagination.value = res?.data?.meta?.pagination;
					isPaginationLoading.value = false;
				} else {
					const resData = res?.data?.data ?? [];
					// if (params?.include && params.include.includes("tags")) {
					// 	for (const shf of resData) {
					// 		shf.selectedTag = shf?.tags[0] ?? null;
					// 	}
					// }
					shifts.value = resData;
					dataIsLoading.value = false;
				}
			}
			return res.data.data;
		} catch (err) {
			if (params.metaOnly === true) {
				shifts.value = null;
				return false;
			}
			if (err.code !== "ERR_CANCELED") {
				shifts.value = [];
			}

			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}
	const actionArr = ref<any>([]);
	const cancelTokenShifts = ref<any>(null);
	const cancelTokenShiftsPagination = ref<any>(null);

	const headerColumns = computed(() => {
		const headerColumns: TTableColumnWithPermissionsAndTab[] = [
			{
				id: "selectAllCheckbox",
				label: "selectAllCheckbox",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftType",
				label: "shiftType",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftActivity",
				label: "shiftActivity",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftStatus",
				label: "shiftStatus",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "tempName",
				label: "tempName",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftSubcategory",
				label: "shiftSubcategory",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftDate",
				label: "shiftDate",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftTime",
				label: "shiftTime",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftTotalHours",
				label: "shiftTotalHours",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftClient",
				label: "shiftClient",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftLocation",
				label: "shiftLocation",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftLastPushedBy",
				label: "shiftLastPushedBy",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftId",
				label: "shiftId",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftConfirmedStatus",
				label: "shiftConfirmedStatus",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftPayrollStatus",
				label: "shiftPayrollStatus",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftPayrollWeekNumber",
				label: "shiftPayrollWeekNumber",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftDetails",
				label: "shiftDetails",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
			{
				id: "shiftPayrollCalculationType",
				label: "shiftPayrollCalculationType",
				permissions: [],
				systemPermissions: [],
				tab: null,
			},
		];

		return headerColumns.filter((tab) => {
			const hasPerm = tab.permissions?.length
				? tab.permissions.every((perm) => can(perm))
				: true;
			const hasSysPerm = tab.systemPermissions?.length
				? tab.systemPermissions.every((tabPerm) =>
						store.initialStatus ? store.initialStatus[tabPerm] : false,
					)
				: true;
			const shouldAppearOnTab =
				tab.tab && Array.isArray(tab.tab)
					? (tab.tab as number[]).includes(activeTab.value?.id)
					: true;
			return hasPerm && hasSysPerm && shouldAppearOnTab;
		});
	});
	function dispatchReplaceShift(shift: any, index: number | null = null) {
		console.log("pozovi");
		if (index && shift.id === shifts.value[index]?.id) {
			return shifts.value.splice(index, 1, shift);
		}

		const foundIdx = shifts.value.findIndex((shf) => shf.id === shift.id);
		if (foundIdx !== -1) return shifts.value.splice(foundIdx, 1, shift);
	}
	const temporaryRow = ref<any>(null);
	async function patchShift(
		id: number,
		data: any,
		index: number | null = null,
		dispatchReplaceShiftBoolean: boolean = true,
	) {
		try {
			const res = await api.patchShift(id, data);
			if (dispatchReplaceShiftBoolean)
				dispatchReplaceShift(res.data.data, index);
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			if (id === temporaryRow.value?.id) {
				console.log(temporaryRow.value, "a");

				dispatchReplaceShift(temporaryRow.value, index);
			}
			return false;
		} finally {
			temporaryRow.value = null;
		}
	}
	async function switchAwaitingStatusForShift(
		id: number,
		data: any,
		index: number | null = null,
	) {
		try {
			const res = await api.switchShift(id, data);
			dispatchReplaceShift(res.data.data, index);
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}
	async function pushToTemps(
		id: number,
		temps: any,
		regardlessAvailability: any,
		index: number | null = null,
	) {
		try {
			const res = await api.pushToTemps(id, {
				id: id,
				temps: temps,
				availability: regardlessAvailability,
			});
			dispatchReplaceShift(res.data.data, index);
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}
	function getShift(id: number) {
		return shifts.value.find((shift) => shift.id === id);
	}

	async function clientRecalculateShift(
		url: string,
		params: any,
		index: number | null = null,
	) {
		try {
			const res = await api.clientRecalculateShift(url, params);
			dispatchReplaceShift(res.data.data, index);
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}

	async function clientSignOff(
		url: string,
		params: any,
		index: number | null = null,
	) {
		try {
			const res = await api.clientSignOff(url, params);
			dispatchReplaceShift(res.data.data, index);
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}

	async function confirmShiftPriceOverwriteCalculation(
		params: any,
		index: number | null = null,
	) {
		try {
			const res = await api.confirmShiftPriceOverwriteCalculation(params);
			dispatchReplaceShift(res.data.data, index);
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}
	const cancellationReasons = ref<any>([]);
	async function fetchCancellationReasons() {
		try {
			const res = await api.cancellationReasons();
			cancellationReasons.value = res.data?.data ?? [];
			return true;
		} catch (err) {
			const errors = parseErrors(err);
			if (Array.isArray(errors) && errors.length > 0) {
				errors.map((e) => {
					toastStore.openToastError(e);
				});
			} else if (errors) {
				toastStore.openToastError(errors);
			}
			return false;
		}
	}
	function spliceCancelledShifts(shiftsToBeRemoved: { id: number }[]) {
		for (const shift of shiftsToBeRemoved) {
			const foundIdx = shifts.value.findIndex((shf) => shf.id === shift.id);
			if (foundIdx !== -1) shifts.value.splice(foundIdx, 1);
		}
	}

	const triggerResetSelectedShifts = ref<boolean>(false);
	const threeDropFilterObject = ref<any>({
		"sectors[][id]": [],
		"clients[][id]": null,
		"categories[][id]": null,
	});
	const filterBy = ref<{
		selectedAgencyWorker: any;
		selectedClient: any;
		selectedClientTags: any;
		selectedLocation: any;
		selectedLocationTags: any;
		selectedSubcategory: any;
		selectedSubcategoryTags: any;
		selectedPayrollStatuses: any;
		selectedPayrollTags: any;
		shiftId: any;
		externalTssId: any;
		client_external_id: any;
		date_from?: string;
		date_to?: string;
	}>({
		selectedAgencyWorker: null,
		selectedClient: [],
		selectedClientTags: [],
		selectedLocation: null,
		selectedLocationTags: null,
		selectedSubcategory: [],
		selectedSubcategoryTags: [],
		selectedPayrollStatuses: [],
		selectedPayrollTags: [],
		shiftId: null,
		externalTssId: "",
		client_external_id: "",
		date_from: null,
		date_to: null,
	});

	const handleFirstLoadClicked = ref<boolean>(false);
	const predefinedDateFrom = ref<boolean>(true);
	function resetData() {
		activeTab.value = null;
		shifts.value = null;
		shiftStatuses.value = [];
		pagination.value = null;
		cancellationReasons.value = [];
		actionArr.value = [];
		cancelTokenShifts.value = null;
		cancelTokenShiftsPagination.value = null;
		resetFilterByParams();
		triggerResetSelectedShifts.value = false;
		handleFirstLoadClicked.value = false;
		predefinedDateFrom.value = true;
		threeDropFilterObject.value = {
			"sectors[][id]": [],
			"clients[][id]": null,
			"categories[][id]": null,
		};
	}
	function resetFilterByParams() {
		filterBy.value.selectedAgencyWorker = null;
		filterBy.value.selectedClient = [];
		filterBy.value.selectedClientTags = [];
		filterBy.value.selectedLocation = null;
		filterBy.value.selectedLocationTags = null;
		filterBy.value.selectedSubcategory = [];
		filterBy.value.selectedSubcategoryTags = [];
		filterBy.value.selectedPayrollStatuses = [];
		filterBy.value.selectedPayrollTags = [];
		filterBy.value.shiftId = null;
		filterBy.value.externalTssId = "";
		filterBy.value.client_external_id = "";
		filterBy.value.date_from = null;
		filterBy.value.date_to = null;
	}

	return {
		tabs,
		filteredTabs,
		activeTab,
		getActiveTab,
		setActiveTab,
		shiftStatuses,
		fetchShiftStatues,
		getErrors,
		setErrors,
		VISIBLE_COLUMNS,
		SIGN_OFF_IN_ARBITRATION,
		VISIBLE_TOOL_ICONS,
		shifts,
		pagination,
		dataIsLoading,
		isPaginationLoading,
		fetchShifts,
		actionArr,
		cancelTokenShifts,
		cancelTokenShiftsPagination,
		headerColumns,
		dispatchReplaceShift,
		patchShift,
		pushToTemps,
		getShift,
		clientRecalculateShift,
		clientSignOff,
		confirmShiftPriceOverwriteCalculation,
		cancellationReasons,
		fetchCancellationReasons,
		spliceCancelledShifts,
		triggerResetSelectedShifts,
		switchAwaitingStatusForShift,
		threeDropFilterObject,
		filterBy,
		handleFirstLoadClicked,
		predefinedDateFrom,
		resetData,
		resetFilterByParams,
		temporaryRow,
	};
});
