<script setup lang="ts">
import api from "@/api";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
type TModalProps = {
	shiftIds: {
		id: number;
	}[];
};

const props = defineProps<{
	modalProps: TModalProps;
}>();
const emit = defineEmits(["close-modal", "confirm-modal"]);

const form = reactive({
	note: "",
});

async function onSave() {
	const p = { id: props.modalProps.shiftIds[0].id, note: form.note };
	try {
		await api.postConfirmShift(p);
		toastStore.openToastSuccess("Shift confirmed successfully!");
		emit("confirm-modal");
		emit("close-modal");
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
	}
}

function onCancel() {
	emit("close-modal");
}
</script>

<template>
	<div class="shift-confirm-dialog">
		<span>Confirm Shift</span>
		<div class="group">
			<label class="label">Note</label>
			<textarea v-model="form.note"></textarea>
		</div>
		<div class="action buttons-centered">
			<button
				class="button is-generic-app-blue is-caps-lock save"
				@click="onSave"
			>
				<span>Save</span>
			</button>
			<button
				class="button is-caps-lock cancel"
				@click="onCancel"
			>
				<span>Cancel</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.shift-confirm-dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	width: 700px;
	gap: 10px;

	.group {
		width: 100%;
	}

	textarea {
		width: 100% !important;
		height: 150px !important;
		overflow: auto !important;
		resize: vertical !important;
		padding: 10px !important;
		font-size: 1.05rem !important;
		border: silver !important;
		outline: 1px solid silver !important;
	}

	.action {
		margin-top: 20px;
	}
	.label {
		color: $label-accent-color-dark;
	}
}
</style>
