<script setup lang="ts">
// import { $t } from "@/i18n";
// import { useRouter } from "vue-router";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
// import { type TLangOption } from "../shared/langSwitcher/LangSwitcher.vue";
import { openUserSettingsForm } from "../modals/modalDefinitions";

const defaultStore = useDefaultStore();
const sessionStore = useSessionStore();
// const router = useRouter();
const route = useRoute();
// const langSelected = ref<TLangOption | null>(null);
const isLoggedIn = computed(() => sessionStore.isLoggedIn);
const userActions = ref<boolean>(false);
const userData = sessionStore.userData;

// const hasLangSwitcher = computed(() => false);

function openModalUserSettings() {
	openUserSettingsForm();
}
</script>

<template>
	<nav class="nav has-shadow">
		<div class="nav-has-nothing"></div>
		<div class="nav-has-logo">
			<div class="nav-item">
				<a>
					<img
						:src="defaultStore.getGlobalImage()"
						alt="logo"
					/>
				</a>
			</div>
		</div>
		<div class="nav-right nav-menu">
			<a
				v-if="isLoggedIn && route.name != 'auth.token-verification'"
				class="nav-item expanded pointer"
				@click.prevent="userActions = !userActions"
			>
				{{ userData?.name ?? "user" }}
				<IFa4ChevronDown
					v-if="!userActions"
					class="drop-arrow"
				/>
				<IFa4ChevronUp
					v-else
					class="drop-arrow"
				/>
			</a>

			<router-link
				v-if="!isLoggedIn"
				:to="{ name: 'auth.signin' }"
				class="nav-item expanded"
			>
				Sign in
			</router-link>

			<div
				v-if="userActions && isLoggedIn"
				class="action-cont"
			>
				<!-- TODO implement setting modal -->
				<a
					class="nav-item pointer nav-item-inner"
					@click="
						userActions = !userActions;
						openModalUserSettings();
					"
				>
					Settings
					<i-fa4-cog></i-fa4-cog>
				</a>
				<a
					class="nav-item pointer nav-item-inner"
					@click="sessionStore.logoutApi(true, true)"
				>
					Logout
					<i-fa4-share />
				</a>
			</div>
		</div>
	</nav>
</template>

<style lang="scss" scoped>
.nav {
	// index: 3 used on button groups :focus
	// above dropdown menus
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	background: $white;
	z-index: 100;
	display: flex;
	box-shadow: 0 2px 3px hsla(0deg, 0%, 4%, 0.1);
	min-height: 5.5rem;
	a {
		color: $nav-light-text;
	}
}

.nav-has-logo {
	text-align: center;
	align-items: center;
	img {
		max-height: 60px;
		width: auto;
	}
}
.nav-right {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}

.nav-has-nothing {
	flex: 1;
}
.nav-item {
	display: flex;
	align-items: center;
	height: 100%;
	justify-content: space-between;

	&.expanded {
		padding: 0 2.5em;
	}
	&:hover {
		color: $nav-dark-text;
	}
}

.nav-item-inner {
	display: flex;
	padding: 0.5rem 0.75rem;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	.faico {
		opacity: 0.25;
		font-size: 16px;
		display: flex;
		justify-content: flex-end;
	}
}

.drop-arrow {
	opacity: 0.25;
	margin-left: 10px;
}
.action-cont {
	position: absolute;
	top: 60px;
	right: 15px;
	width: 180px;
	background: white;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
	border-radius: 5px;
	padding: 0 25px;
	z-index: 2;
}
</style>
