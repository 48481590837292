import NotFoundPage from "@/pages/NotFoundPage.vue";
import type { RouteRecordRaw } from "vue-router";

export const basic_routes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "home",
		redirect: "/dashboard",
	},
	{
		// edit profile used to identify if user is temp
		path: "/edit-profile",
		name: "basic.edit-profile",
		component: {
			render() {
				return h("h1", "Not yet implemented");
			},
		},
		meta: {
			requiresAuth: true,
			permission: "view-profile|update-profile",
			fail: "/error",
		},
	},
	{
		path: "/:pathMatch(.*)*",
		component: NotFoundPage,
		name: "not-found",
	},
];
