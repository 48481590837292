<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors";
import { Errors } from "@/assets/js/errors";
// import apiTs from "@/api/apiTs";
import api from "@/api";
import { useToastStore } from "@/store/toast";

// Define props
const props = defineProps<{
	modalProps: {
		client_id: number;
		category_id: number;
		contact: {
			contact_default?: boolean;
			[key: string]: any;
		};
		contactTypes?: any[];
	};
}>();

// Define emits
const emit = defineEmits(["confirm", "close-modal"]);

const toast = useToastStore();

// Form state
const form = ref({
	client_id: props.modalProps.client_id,
	// ...props.modalProps.contact,
	id: props.modalProps.contact.id,
	default: !!props.modalProps.contact.contact_default,
	category_id: props.modalProps.category_id,
	name: props.modalProps.contact.name,
	email: props.modalProps.contact.email,
});

// Error handling
const errors = ref(new Errors());

// Loading state
const isLoading = ref(false);

// Initialize logic
const init = async () => {
	// Placeholder for future logic if needed
	// For example, fetching additional data or processing props
};

onMounted(init);

// Save action
const saveAction = async () => {
	// Remove "title" property manually if necessary
	// @ts-ignore
	const { title, ...payload } = form.value;

	isLoading.value = true;
	console.log(payload);
	try {
		await api.apiTSputClientContacts(payload);
		emit("confirm");
		emit("close-modal");
	} catch (err: any) {
		console.error("Error", err.message);
		if (err.response?.data.errors) {
			errors.value.record(err.response.data.errors);
		} else if (err.response?.data.message) {
			toast.openToastError(err.response.data.message);
		} else {
			const errs = parseErrors(err);
			toast.openToastError(errs);
		}
	} finally {
		isLoading.value = false;
	}
};

// Cancel action
const onCancel = () => {
	emit("close-modal");
};
</script>

<template>
	<div id="client-contact-confirm-edit">
		<form
			autocomplete="off"
			@submit.prevent="saveAction"
			@keydown="
				errors.clear(
					// @ts-ignore
					event.target.name,
				)
			"
		>
			<div class="field">
				<label>Name</label>
				<input
					v-model="form.name"
					class="input"
					placeholder="Name"
					name="name"
					type="text"
				/>
				<span
					v-if="errors.has('name')"
					class="help is-danger"
					v-html="errors.get('name')"
				></span>
			</div>
			<div class="field">
				<label>Email</label>
				<input
					v-model="form.email"
					class="input"
					placeholder="Email"
					name="email"
					type="email"
				/>
				<span
					v-if="errors.has('email')"
					class="help is-danger"
					v-html="errors.get('email')"
				></span>
			</div>
			<div class="field">
				<label>Default contact</label>
				<switch-component
					v-model="form.default"
					theme="bulma"
					color="blue"
					name="default"
					placeholder="Default contact"
					style="margin-right: auto"
				/>
				<span
					v-if="errors.has('default')"
					class="help is-danger"
					v-html="errors.get('default')"
				></span>
			</div>
			<div class="buttons-centered">
				<button
					class="button is-small is-generic-app-blue is-caps-lock"
					:disabled="isLoading"
					@click.once.prevent="saveAction"
				>
					<div
						v-if="isLoading"
						class="loading-wrapper"
					>
						<img src="@/assets/image/loader.svg" />
					</div>
					<span>Save</span>
				</button>
				<button
					class="button is-small is-outlined is-caps-lock"
					@click.prevent="onCancel"
				>
					Cancel
				</button>
			</div>
		</form>
	</div>
</template>

<style lang="scss" scoped>
#client-contact-confirm-edit {
	display: flex;
	flex-direction: column;
	width: 500px;
	padding: 0 60px 45px;

	form {
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 20px 5px 5px;

		.field {
			display: flex;
			flex-direction: column;
			gap: 5px;

			input:not([type="checkbox"]),
			textarea {
				height: 40px;
			}
		}
	}
}
.buttons-centered {
	button {
		display: flex;
		justify-content: space-evenly;
	}
}
.loading-wrapper {
	padding-top: 3px;
	img {
		height: 20px;
		width: 20px;
	}
}
.help.is-danger {
	background: $background-color;
}
</style>
