<script lang="ts" setup>
import type { IErrorModal } from "@/assets/js/globalTypes";
import { parseErrors } from "@/assets/js/parseErrors.js";
import apiTs from "@/api/index";
import { openErrorsModal } from "@/components/modals/modalDefinitions";
import Zip from "@/assets/js/zip";
import { useToastStore } from "@/store/toast.js";
import dayjs from "dayjs";

const emit = defineEmits(["close-modal"]);

const props = defineProps<{
	invoiceFileType: string;
}>();

const errorModalObj = ref<IErrorModal>({
	content: "",
	onCloseModal: () => {
		errorModalObj.value.content = "";
	},
});

const invoiceFileTypeMap = {
	downloadAllCSV: {
		getZipName: () => "backup-excel-all",
		getFile: (params) => apiTs.getInvoicesBackup(params),
	},
	downloadAllExcel: {
		getZipName: () => "pay&bill-all",
		getFile: (params) => apiTs.getInvoicesPayAndBill(params),
	},
};

const toastStore = useToastStore();
const jszip = ref(new Zip());
const zipNameBase = ref("");
const fileList = ref([]);
const currentProgress = ref(0);
const failedToBackup = ref<{ id: number; client: string }[]>([]);
const isBackupComplete = ref(false);

async function getInvoices() {
	resetBackupState();
	fileList.value = [];
	try {
		const res = await apiTs.getShiftInvoicesForGroupDownload();
		fileList.value = res.data.invoices;
		if (!fileList.value) {
			errorModalObj.value.content = "Something went wrong. Please try again.";
			openErrorsModal(errorModalObj.value);
			return;
		}
		if (fileList.value.length === 0) {
			errorModalObj.value.content =
				"There are no invoiced shifts from the last week.";
			openErrorsModal(errorModalObj.value);
			return;
		}
		const invoiceTypeObj = invoiceFileTypeMap[props.invoiceFileType] ?? null;
		if (!invoiceTypeObj) {
			errorModalObj.value.content = "Something went wrong. Please try again.";
			openErrorsModal(errorModalObj.value);
			return;
		}

		await callBackup(fileList.value, 0, invoiceTypeObj);
	} catch (err) {
		const errs = parseErrors(err);
		toastStore.openToastError(errs);
	}
}
async function callBackup(fileList, indexFunc, invoiceTypeObj) {
	const i = indexFunc;
	if (zipNameBase.value === "") {
		zipNameBase.value = invoiceTypeObj.getZipName();
	}
	if (fileList.length === i) {
		finishBackup();
		return;
	}
	const id = fileList[i].id;
	const client = fileList[i].clientName;
	try {
		const params = {
			id: id,
		};
		const res = await invoiceTypeObj.getFile(params);
		const blob = new Blob([res.data], { type: "application/csv" });
		const fileTitle = res.headers["x-multi-file-name"];

		jszip.value.file(fileTitle, blob);
		currentProgress.value = ((i + 1) * 100) / fileList.length;
		await callBackup(fileList, i + 1, invoiceTypeObj);
	} catch (error) {
		console.error(error);
		failedToBackup.value.push({ id, client });
		await callBackup(fileList, i + 1, invoiceTypeObj);
	}
}
async function finishBackup() {
	isBackupComplete.value = true;
	await downloadZip();
	if (failedToBackup.value.length === 0) {
		emit("close-modal");
	}
}
async function downloadZip() {
	currentProgress.value = 100;
	try {
		const content = await jszip.value.generateAsync("blob");
		const blob = new Blob([content]);
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		document.body.append(a);
		a.href = url;
		const currDate = dayjs().format("DD-MM-YYYY");
		a.download = `${zipNameBase.value} - ${currDate}.zip`;
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	} catch (err) {
		console.log("Downloading Zip Failed:", err.message);
	}
}
function resetBackupState() {
	jszip.value = new Zip();
	currentProgress.value = 0;
	isBackupComplete.value = false;
	failedToBackup.value = [];
}
onMounted(() => {
	getInvoices();
});
</script>
<template>
	<div class="modal-zip">
		<progress-bar :currentProgress="currentProgress"></progress-bar>
	</div>
</template>
<style lang="scss" scoped>
.modal-zip {
	box-sizing: border-box;
	padding: 0 20px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 460px;
	// width: fit-content;
	min-height: 180px;
	gap: 10px;
	color: $text-color;
	// justify-content: center;

	$text-color-lighter: $label-color;
	$table-header-background: #f2f7fa;
	.backup-fail {
		margin: 10px auto;
		width: 90%;
		text-align: center;
	}
	.btn {
		margin: auto;
	}
	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #dde6eb;
		margin-bottom: 30px;
		td {
			font-size: 0.9rem;
			padding: 5px 10px;
			// border-top: 1px solid #dde6eb;
			border: 1px solid #dde6eb;
		}
		thead {
			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}
		}
		tbody {
			tr {
				height: 30px;
			}
		}
	}
}
</style>
