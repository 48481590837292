<script setup lang="ts">
import type { TTempDocument, TTempDocumentFile } from "@/assets/js/globalTypes";
import {
	parseDateTime,
	TEMP_DOCUMENT_FILES_HEADERS,
} from "@/assets/js/helpers";
import { useSessionStore } from "@/store/session";
import { useTempDocumentsComplianceStore } from "@/store/tempDocumentsComplianceStore";
import apiTs from "@/api/index";
import { parseErrors } from "@/assets/js/parseErrors";

const tempComplianceStore = useTempDocumentsComplianceStore();
const sessionStore = useSessionStore();
const can = sessionStore.can;
const emit = defineEmits([
	"close-modal",
	"confirm",
	"fetch-all-document-files",
]);
const props = withDefaults(
	defineProps<{
		doc: TTempDocument;
		confirmBtnTxt?: string;
		cancelBtnTxt?: string;
		showConfirmBtn?: boolean;
		showCancelBtn?: boolean;
		confirmBtnClass?: string;
		warning?: boolean;
		isError?: boolean;
	}>(),
	{
		confirmBtnTxt: "Confirm",
		cancelBtnTxt: "Cancel",
		showConfirmBtn: true,
		showCancelBtn: true,
		confirmBtnClass: "",
		warning: false,
		isError: false,
	},
);

const tempDocumentFiles = computed(() =>
	tempComplianceStore.getTempDocumentFiles(),
);
// function onClickConfirm() {
// 	emit("confirm");
// 	emit("close-modal");
// }
function onClickReturn() {
	emit("close-modal");
}
async function onOpenFile(docFile: TTempDocumentFile): Promise<void> {
	docFile.fileUrlIsLoading = true;
	const params = {
		link: docFile.fileUrl,
	};
	try {
		const res = await apiTs.getFileBlob(params);
		const file = res.data;
		const fileLink: string = URL.createObjectURL(file);
		window.open(fileLink);
		URL.revokeObjectURL(fileLink);
	} catch (err) {
		tempComplianceStore.setErrors(parseErrors(err));
	}
	docFile.fileUrlIsLoading = false;
}
async function downloadFile(docFile: TTempDocumentFile) {
	docFile.fileIsDownloading = true;
	const params = {
		link: docFile.fileUrl,
	};
	try {
		const res = await apiTs.getFileBlob(params);
		const data = res.data;
		const url = URL.createObjectURL(data);
		const a = document.createElement("a");
		document.body.append(a);
		a.href = url;
		a.download = docFile.fileName;
		a.click();
		a.remove();
	} catch (err) {
		tempComplianceStore.setErrors(parseErrors(err));
	}
	docFile.fileIsDownloading = false;
}
const isLoadingFiles = ref<boolean>(false);
const docCopy = ref<TTempDocument | null>(null);
async function onFetchAllDocumentFiles(e: Event): Promise<void> {
	const target = e.target as HTMLInputElement;
	const checked = target.checked;
	docCopy.value = JSON.parse(JSON.stringify(props.doc));
	docCopy.value.includeAll = checked;
	isLoadingFiles.value = true;
	await tempComplianceStore.fetchTempDocumentFiles(docCopy.value);
	isLoadingFiles.value = false;
}
const fileToBeDeleted = ref<TTempDocumentFile | null>(null);
function showDeleteDocumentFilesPrompt(f: TTempDocumentFile) {
	fileToBeDeleted.value = f;
	openDeleteDocumentFilesPrompt.value = true;
}
const openDeleteDocumentFilesPrompt = ref<boolean>(false);
function cancelDeletingFile(): void {
	const deleteIdx = tempDocumentFiles.value.findIndex(
		(f) => f.id === fileToBeDeleted.value.id,
	);
	tempDocumentFiles.value[deleteIdx].fileIsBeingDeleted = false;
	fileToBeDeleted.value = null;
	openDeleteDocumentFilesPrompt.value = false;
}
async function onDeleteFile() {
	const deleteIdx = tempDocumentFiles.value.findIndex(
		(f) => f.id === fileToBeDeleted.value.id,
	);
	tempDocumentFiles.value[deleteIdx].fileIsBeingDeleted = true;
	fileToBeDeleted.value.fileIsBeingDeleted = true;
	openDeleteDocumentFilesPrompt.value = false;
	// delete file
	await tempComplianceStore.deleteTempDocumentFiles(
		props.doc,
		fileToBeDeleted.value,
	);
	fileToBeDeleted.value = null;
	// reload files table check if empty
	isLoadingFiles.value = true;
	await tempComplianceStore.fetchTempDocumentFiles(docCopy.value);
	// reload temp documents and files table
	await tempComplianceStore.fetchTempDocuments();
	isLoadingFiles.value = false;
	if (
		!tempDocumentFiles.value ||
		(tempDocumentFiles.value && tempDocumentFiles.value.length === 0)
	) {
		// if files table is empty close modal
		emit("close-modal");
	}
}
</script>

<template>
	<div
		class="confirm-modal"
		:class="{ warning: warning }"
	>
		<div class="content">
			<div>
				<div>
					<label class="label mg-5">
						<input
							type="checkbox"
							@change="onFetchAllDocumentFiles($event)"
						/>
						View all files
					</label>
				</div>
				<div v-if="!isLoadingFiles">
					<modern-table
						:columns="TEMP_DOCUMENT_FILES_HEADERS"
						:rows="tempDocumentFiles"
						:has-border="false"
						:display-additional-row="false"
						:isInfiniteScroll="false"
						:isSticky="false"
					>
						<template #table-body-cell="slotProps">
							<td v-if="slotProps.column.id === 'name'">
								<span
									v-if="!slotProps.row?.fileUrlIsLoading"
									class="pointer opacity-07-h"
									:class="{
										'error-text': slotProps.row?.fileIsBeingDeleted,
									}"
									:style="[
										slotProps.row?.fileIsBeingDeleted
											? { 'text-decoration': 'line-through' }
											: {},
									]"
									@click="onOpenFile(slotProps.row as TTempDocumentFile)"
								>
									{{ slotProps.row.fileName }}
								</span>
								<div
									v-else
									class="loading-wrap"
								>
									<div class="loading-wrapper flex-center">
										<img
											class="wd-30"
											src="@/assets/image/loader.svg"
										/>
									</div>
								</div>
							</td>
							<td v-else-if="slotProps.column.id === 'size'">
								{{ slotProps.row.fileSize ?? 0 }} bytes
							</td>
							<td v-else-if="slotProps.column.id === 'uploadDate'">
								{{
									slotProps.row.documentCreated
										? parseDateTime(slotProps.row.documentCreated, "/")
										: ""
								}}
							</td>
							<td v-else-if="slotProps.column.id === 'expiryDate'">
								{{
									slotProps.row.documentExpiry
										? parseDateTime(slotProps.row.documentExpiry, "/")
										: ""
								}}
							</td>
							<td
								v-else-if="slotProps.column.id === 'actions'"
								style="width: 70px"
								class="flex-space-between"
							>
								<span
									v-if="!slotProps.row?.fileIsDownloading"
									class="blue-text pointer mg-5"
									@click.stop="downloadFile(slotProps.row as TTempDocumentFile)"
								>
									<i
										class="fa fa-cloud-download opacity-07-h"
										aria-hidden="true"
									></i>
								</span>
								<div
									v-else
									class="loading-wrap"
								>
									<div class="loading-wrapper flex-center">
										<img
											class="wd-30"
											src="@/assets/image/loader.svg"
										/>
									</div>
								</div>
								<span
									v-if="can('delete-document-files')"
									class="error-text pointer mg-5 opacity-07-h"
									@click.stop="
										showDeleteDocumentFilesPrompt(
											slotProps.row as TTempDocumentFile,
										)
									"
								>
									<i
										class="fa fa-trash"
										aria-hidden="true"
									></i>
								</span>
							</td>
						</template>
					</modern-table>
				</div>
				<div
					v-else
					class="loading-wrap"
				>
					<div class="loading-wrapper flex-center">
						<img
							class="wd-50"
							src="@/assets/image/loader.svg"
						/>
					</div>
				</div>
				<div
					v-if="openDeleteDocumentFilesPrompt && can('delete-document-files')"
					class="mgt-30 flex-column-center b-tb pd-10"
				>
					Are you sure you want to delete file: "{{
						fileToBeDeleted?.fileName ?? ""
					}}"?
					<div>
						<button
							class="alt mg-5"
							@click="cancelDeletingFile"
						>
							NO
						</button>
						<button
							class="btn mg-5"
							@click="onDeleteFile"
						>
							YES
						</button>
					</div>
				</div>
			</div>
			<div class="wrapp-buttons">
				<button
					v-if="showCancelBtn"
					class="alt"
					@click="onClickReturn()"
				>
					{{ cancelBtnTxt }}
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 800px;
	min-height: 130px;
	gap: 10px;
	color: $text-color;

	.content {
		margin-top: 25px;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		.warningTxt {
			font-size: 2em;
		}
		.message {
			text-align: center;
			font-size: 1rem;
			// color: $success-color;
			transition: 2s;
		}
		.isError {
			color: $error-color;
		}
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		// flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
	.label {
		color: var(--color-secondary-50) !important;
	}
	td {
		font-size: 0.9rem;
		padding: 10px;
		border-top: 1px solid #dde6eb;
		color: $blue-grey;
		&:not(.extra-row) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 300px;
		}
	}
}
.confirm-modal.warning {
	max-width: 400px;
}
</style>
