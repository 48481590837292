import type { TCandidateTag } from "@/pages/admin/settings/subpages/CandidateTagsPage.vue";
import { axios } from "./config.js";
import type {
	TAuthVerifyCreate,
	TAuthVerifyResend,
	TAxiosGenRes,
	TBasicType,
	TForgotPass,
	TLoginParam,
	TLoginRes,
	TResetPass,
	TGetStatusRes,
	TUpdateRolesAndPerms,
	TGetCandidatesParams,
	TGetSectorParams,
	TEditTempData,
	TShiftQuote,
	TShiftQuoteResponse,
	ITempsForShift,
	IAnnualLeaveTransactionPost,
} from "./types.js";
import {
	generateLaravelUrl,
	packLaravelArray,
	prepareGetQueryLaravelArrayParams,
} from "@/assets/js/helpers.js";
import type { TNoteType } from "@/components/security/journal/SecurityJournalTable.vue";
import { omit } from "@/assets/js/helpers.js";
import type {
	IReportParams,
	IBillingPeriods,
	IAnnualLeavePayBillGet,
	IMeta,
	TNotificationsParams,
	TSignatures,
	TNotificationCenterTemplate,
	TFetchWorkersSeenMessageParams,
	TFetchTempDocumentsParams,
	TGenericRecord,
} from "@/assets/js/globalTypes";
import type { AxiosResponse } from "axios";
import type { TGetRatesParams } from "@/assets/js/rateManagementTypes.js";
import type { IFormatedShiftPriceOverwriteDataInterface } from "@/assets/js/shiftPriceOverwriteType.js";
import type { IUserPaymentBalancesGet } from "@/components/candidates/CandidateAlTable.vue";

function appendUrl(p: Record<string, Record<"id", string>[]>): string {
	let url = "";
	const laravelArr = Object.keys(p);
	for (const larKey of laravelArr) {
		if (p[larKey]) {
			const packed = packLaravelArray(larKey, p[larKey]);
			if (packed !== "") {
				// If first
				url = url.includes("?") ? url + "&" + packed : url + "?" + packed;
			}
			// Has to be called (delete) so it doesn't send duplicate params
			delete p[larKey];
		}
	}
	return url;
}

export default {
	// #region basic
	getStatusInitialLoad: () => axios.get<TGetStatusRes>("get-status"),
	getCountries: (params?: { nationality_name?: string }) =>
		axios.get(`nationalities`, { params }),
	getSpecialities: () => axios.get("specialities"),
	getRelativeTypes: () => axios.get("relative-types"),

	// #endregion

	// #region auth
	authLogin: (params: TLoginParam) =>
		axios.post<TLoginRes>("auth/login", params),
	authRegister: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/register", params),
	authRenewSession: () => axios.post<{ token: string }>("auth/refresh"),
	authLogout: () => axios.post("auth/logout", { validateStatus: null }),
	authVerifyCreate: (params: TAuthVerifyCreate) =>
		axios.post<TLoginRes>("auth/verify", params),
	authVerifyResend: (params: TAuthVerifyResend) =>
		axios.put("auth/verify", params),
	authForgot: (params: TForgotPass) => axios.post("auth/recovery", params),
	authResetPass: (params: TResetPass) =>
		axios.put("auth/forgot-password", params),
	passwordRecovery: (params: {
		email: string;
		token: string;
		password: string;
		password_confirmation: string;
	}) => axios.post("auth/reset", params),

	// #endregion

	// #region dashboard
	getDynamicApi: (params: any) => axios.get(params.link),
	// [Don't add handlers here]
	fetchCards: () => {
		return axios.get("/dashboard");
	},
	// #endregion

	// #region profile settings
	editProfileSettings: (params: any) => axios.patch("settings", params),
	// #endregion

	// #region Settings password validation
	validatePassword: (params: any) => axios.get("validate-password", { params }),
	// #endregion

	// #region shifts
	/**
	 * : /shifts
	 *  {
	 *  "location_id": 0,
	 *  "start_time": "string",
	 *  "end_time": "string",
	 *  "temp_id": 0,
	 *  "notes": "string"
	 *  }
	 * Changes any of the above for shift identified by shiftId (Shift schedule view)
	 * params will get patch data, all EXCEPT shiftId which was extracted from object
	 * to be used in url.
	 */
	patchShift: (id: number, payload = {}) =>
		axios.patch(`/shifts/${id}`, payload, {
			params: {
				include: [
					"shiftRequest.subcategories",
					"shiftRequest.createdBy",
					"location.client.locations",
					"temp",
					"previousTemp",
					"shiftParts",
					"tags",
				].join(","),
			},
		}),
	getShifts: (params) => axios.get("/shifts", { params }),
	// #endregion

	// #region file-upload
	// # timesheets
	getBulkUploadProgressTimesheet: (params: any) =>
		axios.get(`bulk-upload/timesheet-shifts`, { params }),
	exportTimesheetCsv: () => axios.get("bulk-upload-errors/timesheet-shifts"),
	exportOpenShiftCsv: () => axios.get("bulk-upload-errors/open-shifts"),
	postFileShiftsTimesheet: (fd: FormData) =>
		axios.post(`bulk-upload/timesheet-shifts`, fd),
	// # openshifts
	getCsvDataOpenShifts: (params) =>
		axios.get(`bulk-upload-errors/open-shifts`, { params }),
	getBulkUploadProgressOpenShifts: (params) =>
		axios.get(`bulk-upload/open-shifts`, { params }),
	postFileShiftsOpenShifts: (fd: FormData) =>
		axios.post(`bulk-upload/open-shifts`, fd),
	//#endregion

	//#region settings
	getAdminInactivePeriod: () =>
		axios.get("admin/security/admin-inactive-script-interval"),
	putInactiveSecurity: (params: { interval: number }) =>
		axios.put("admin/security/admin-inactive-script-interval", params),

	getContactTypes: (params: { getAllContactTypes: boolean }) =>
		axios.get("contacttypes", { params }),
	putContactTypes: (params: { id: number; name?: string; hidden?: boolean }) =>
		axios.put(`contacttypes/${params.id}`, params),
	postContactTypes: (params: { id: number; name?: string; hidden?: boolean }) =>
		axios.post(`contacttypes`, params),

	getCandidateTags: (params?: { getAllContactTypes: boolean }) =>
		axios.get("tags", { params }),
	putCandidateTags: (params: Partial<TCandidateTag>) =>
		axios.put(`tags/${params.id}`, params),
	postCandidateTags: (params: Partial<TCandidateTag>) =>
		axios.post(`tags`, params),
	deleteCandidateTags: (params: Partial<TCandidateTag>) =>
		axios.delete(`/tags/${params.id}`),
	getNoteTypes: (params: { isClient?: boolean; hidden?: boolean }) =>
		axios.get("notetypes", { params }),
	postNoteType: (params: TNoteType) => axios.post(`/notetypes`, params),
	patchNoteType: (params: TNoteType) =>
		axios.patch(`/notetypes/${params.id}`, params),
	getEmailList: () =>
		axios.get("/admin/security/notification-centre/email-from"),
	postEmailList: (emails: { email: string }[]) =>
		axios.post("/admin/security/notification-centre/email-from", { emails }),
	getIncrementCounterRange: () =>
		axios.get("admin/security/increment-counter-range"),
	postIncrementCounterRange: (params: { from: number; to: number }) =>
		axios.post("admin/security/increment-counter-range", params),
	getGeneralReceipts: () => axios.get(`/admin/security/general-receipts`),
	postGeneralReceipts: (params) =>
		axios.post(`/admin/security/general-receipts`, params),
	putGeneralReceipts: (params) =>
		axios.put(`/admin/security/general-receipts/${params.id}`, params),
	deleteGeneralReceipts: (params) =>
		axios.delete(`/admin/security/general-receipts/${params.id}`, { params }),
	getShiftActivityClients: () =>
		axios.get("admin/security/shift-activity-tags/clients?per_page=999"),
	postShiftActivityClients: (params: { clients: number[] }) =>
		axios.post("admin/security/shift-activity-tags/clients", params),
	alCategoryGet: () => axios.get("admin/security/annual-leave/categories"),
	alCategoryPut: (params) =>
		axios.put("admin/security/annual-leave/categories", params),
	getRolesPermissions: (params) =>
		axios.get(`admin/security/roles-with-permissions`, { params }),
	postRolesPermissions: (params: TUpdateRolesAndPerms) =>
		axios.post("admin/security/roles-with-permissions", params),
	getAllCategories: () =>
		axios.get("categories", { params: { include: "subcategories" } }),
	getAllCategoriesForClient: (cli_id: number) =>
		axios.get("categories", { params: { client_id: cli_id } }),
	getAllClients: () => axios.get("clients", { params: { per_page: 999 } }),
	getSubcategoriesHolidayRequestStatus: () =>
		axios.get("admin/security/holiday-requests/subcategories"),
	postCategoriesHolidayRequest: (params: { subcategories: { id: number }[] }) =>
		axios.post("admin/security/holiday-requests/subcategories", params),
	getClientsHolidayRequest: () =>
		axios.get(`admin/security/holiday-requests/clients`),
	postClientHolidayRequest: (params: { clients: { id: number }[] }) =>
		axios.post(`admin/security/holiday-requests/clients`, params),
	//#endregion

	//#region candidates
	getCandidates: (params: TGetCandidatesParams, abortSignal?: AbortSignal) => {
		// for (const key of Object.keys(params)) {
		// 	if (Array.isArray(params[key])) {
		// 		serialize = true;
		// 		break;
		// 	}
		// }
		return axios.get("temps", {
			params,
			paramsSerializer: { indexes: false },
			signal: abortSignal,
		});
	},
	//#endregion

	// #region calendar
	/**
	 * @params:
	 *   dateFrom starting date
	 *   dateTo   ending date
	 *
	 * @return:
	 *   array of shift objects, for each date in a specified range
	 */
	getShiftsForDateRange: (url, params: any = {}, cancelTokenSource) => {
		const payload = {
			date_from: params.dateFrom,
			date_to: params.dateTo,
			"clients[][id]": params["clients[][id]"],
			locations: params.location,
			show_available_shifts: params.show_available_shifts,
		};

		const tempPayload = Object.assign({}, payload);
		let fullRequestUrl = url;

		const laravelArr = ["locations"];
		for (const larKey of laravelArr) {
			if (tempPayload[larKey]) {
				const packed = packLaravelArray(larKey, tempPayload[larKey]);
				if (packed !== "") {
					// If first
					fullRequestUrl =
						fullRequestUrl && !fullRequestUrl.includes("/?")
							? fullRequestUrl + "?" + packed
							: fullRequestUrl + "&" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete tempPayload[larKey];
			}
		}

		return axios.get(fullRequestUrl, {
			params: tempPayload,
			cancelToken: cancelTokenSource.token,
		});
	},

	/**
	 * @params:
	 *   date single day in YYYY-MM-DD format
	 *
	 * @return:
	 *   shift objects, for specific date, filter by Open, Filled & ApprovalNeeded
	 */
	getShiftsForDay: (url: string, params: Record<string, any> = {}) => {
		const payload = {
			date: params.date,
			"clients[][id]": params["clients[][id]"],
			locations: params.location,
		};

		const tempPayload = Object.assign({}, payload);
		let fullRequestUrl = url;

		const laravelArr = ["locations"];
		for (const larKey of laravelArr) {
			if (tempPayload[larKey]) {
				const packed = packLaravelArray(larKey, tempPayload[larKey]);
				if (packed !== "") {
					// If first
					fullRequestUrl =
						fullRequestUrl && !fullRequestUrl.includes("/?")
							? fullRequestUrl + "?" + packed
							: fullRequestUrl + "&" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete tempPayload[larKey];
			}
		}

		return axios.get(fullRequestUrl, {
			params: tempPayload,
		});
	},

	/**
	 * @params:
	 *   date starting date
	 *   is_available_day  boolean
	 *   is_available_night  boolean
	 *
	 * @return:
	 *   array of availabilities
	 */
	saveAvailability: (payload) => {
		const { date, is_available_day, is_available_night, tempId } = payload;
		let url = "availabilities";
		if (tempId) {
			url = `temps/${tempId}/availabilities`;
		}
		return axios.post(url, {
			date,
			is_available_day,
			is_available_night,
		});
	},
	saveMultiAvailabilities: (q) => {
		let url = "availabilities";
		if (q.tempId) {
			url = `temps/${q.tempId}/availabilities`;
		}
		return axios({
			url: url,
			method: "POST",
			data: q,
		});
	},
	getOneAvailability: (q: any) => {
		let url = "availabilities";
		if (q.tempId) {
			url = `temps/${q.tempId}/availabilities`;
		}
		delete q.tempId;
		return axios({
			url: url,
			params: q,
		});
	},

	// #endregion

	fetchAllClients: (q: any) => {
		return axios.get("/clients", {
			params: {
				include: q.includes,
				per_page: q.per_page,
				optimise: q.optimise,
			},
		});
	},

	// #region users temps

	// fetchWorkers1: (params: any) => axios.get("/temps", { params }),
	fetchWorkers: (params: any) => {
		// const cleanParams = _.omit(params, 'cancelTokenSource');
		const cleanParams = omit(params, ["cancelTokenSource"]);
		return axios({
			url: "/temps",
			params: cleanParams,
			cancelToken: params.cancelTokenSource.token,
		});
	},

	fetchTempProfile: (params: {
		tempId: number;
		include?: string;
		[k: string]: any;
	}) =>
		axios.get(`/temps/${params.tempId}`, {
			params: {
				...params.body,
				include: params.include,
			},
		}),
	patchTemp: (
		tempId: number,
		params: Partial<TEditTempData>,
		query?: TGenericRecord,
	) => axios.patch(`/temps/${tempId}`, params, { params: query }),
	fetchUsers: (params: any) => axios.get("/users", { params }),
	getUsers: (params) =>
		axios({
			url: `/users/${params.id}`,
			method: "GET",
			params: {
				include: "preferredRegions",
			},
		}),
	patchUsers: (id: number | string, payload = {}, query: any = "") => {
		return axios.patch(`/users/${id}`, payload, {
			params: {
				include: query.include,
			},
		});
	},
	fetchUsersCancellable: (params) => {
		// const cleanParams = _.omit(params, 'cancelTokenSource');
		const cleanParams = omit(params, ["cancelTokenSource"]);
		return axios({
			url: "/users",
			params: cleanParams,
			cancelToken: params.cancelTokenSource.token,
		});
	},
	getUsersCancellable: (params = {}, cancelTokenSource) => {
		// const queryParams = qs.stringify(params);
		const paramsObj = new URLSearchParams(params);
		const queryParams = paramsObj.toString();
		return axios.get(`/users?${queryParams}`, {
			cancelToken: cancelTokenSource.token,
		});
	},
	deleteUser: (id: any) => {
		return axios.delete(`/users/${id}`);
	},
	// #endregion
	fetchAllRoles: () =>
		axios.get("/roles", {
			params: {
				with_temp: 1,
			},
		}),
	fetchMinRoles: () => axios.get("/roles"),

	// Client specific categories
	fetchClientCategories: (params: any) =>
		axios.get("/categories", {
			params: {
				include: params.include,
				clients: params.clients,
			},
		}),
	// Check recalculation
	checkMassRecalculation: (params: any) =>
		axios.post(`shifts-mass-recalculation/preview-mark-shifts-result`, params),
	// Backpay progress
	progressBackpay: () => axios.get(`shifts-mass-recalculation/progress`),
	getListOfReports: (params: any) =>
		axios.get(`shifts-mass-recalculation/jobs`, { params }),
	//Backpay preview table
	getListOfPreviewReports: (params: any) =>
		axios.get(`shifts-mass-recalculation/preview-jobs`, { params }),
	//Backpay preview table confirm
	confirmPreviewReports: (params: any) =>
		axios.post(`shifts-mass-recalculation/jobs/${params.id}/confirm`, params),
	//Backpay preview table cancel
	cancelPreviewReports: (params: any) =>
		axios.post(`shifts-mass-recalculation/jobs/${params.id}/cancel`, params),
	shiftsMassRecalculationFilters: () =>
		axios.get("shifts-mass-recalculation/filters"),
	// zip recalculations
	massRecalculationGetBackpayInvoicesOnJobId: (params: any) =>
		axios.get(`shifts-mass-recalculation/${params.id}/get-backpay-invoices`),
	massRecalculationGetBackpayInvoiceFile: (params: any) =>
		axios.get(
			`shifts-mass-recalculation/jobs/${params.job_id}/${params.invoice_id}/backup.csv`,
		),
	// Start recalculation
	startMassRecalculation: (params: any) =>
		axios.post(`shifts-mass-recalculation`, params),

	// revert backpay
	revertCalculations: (params: any) =>
		axios.post(`shifts-mass-recalculation/revert`, params),
	fetchAllCategories: () =>
		axios.get("/categories", {
			params: {
				include: "subcategories",
			},
		}),

	alTempTransacationGet: (p: { id: string; year: string }) =>
		axios.get<{ data: IUserPaymentBalancesGet[]; total: number }>(
			`temps/${p.id}/user-payment-balances`,
			{
				params: p,
			},
		),
	alTempHolidayReqPost: (p: IAnnualLeaveTransactionPost) =>
		axios.post(`temps/${p.id}/holiday-requests`, p),
	alTempTypeGet: () => axios.get(`holiday-requests/user-payment-balance-types`),
	alTempTransacationPost: (p: IAnnualLeaveTransactionPost) =>
		axios.post(`temps/${p.id}/user-payment-balance`, p),
	alTempYears: (p: { id: string }) =>
		axios.get<{ data: string[]; message: string[] }>(
			`temps/${p.id}/user-payment-balance/years`,
		),
	getHolidayRequests: (params: { id: number; page: number }) =>
		axios.get(`temps/${params.id}/holiday-requests`, { params }),

	fetchReportList: (params: any) =>
		axios({
			url: "reports",
			params: {
				...params,
				timestamp: Date.now(),
			},
		}),
	fetchReports: (param: any) => {
		// var url = param.report.report.urlPreview
		const url = param.report.report.previewendpoint;

		let fullRequestUrl = url;
		// console.log("FULL REQUEST URL :: ", fullRequestUrl);
		const thingsToPack = [
			"clients",
			"categories",
			"subcategories",
			"locations",
			"temps",
		];

		for (const keyToPack of thingsToPack) {
			if (!param[keyToPack]) {
				continue;
			}
			const packed = packLaravelArray(keyToPack, param[keyToPack]);
			if (packed !== "") {
				// If first
				fullRequestUrl =
					fullRequestUrl === url
						? fullRequestUrl + "?" + packed
						: fullRequestUrl + "&" + packed;
			}
		}
		// console.log("FULL REQUEST URL :: ", fullRequestUrl);
		return axios.get(`${fullRequestUrl}`, {
			params: {
				date_from: param.date_from,
				date_to: param.date_to,
				timestamp: Date.now(),
				status: param.status,
			},
		});
	},

	getExternalServiceDisabledFields: () =>
		axios.get("/temps/get-external-service-disabled-fields"),

	downloadReport: (param: any) => {
		// var url = param.report.report.urlDownload
		const url = param.report.report.downloadendpoint;

		let fullRequestUrl = url;
		// console.log("FULL REQUEST URL :: ", fullRequestUrl);
		const thingsToPack = [
			"clients",
			"categories",
			"subcategories",
			"locations",
			"temps",
		];
		for (const keyToPack of thingsToPack) {
			if (!param[keyToPack]) {
				continue;
			}
			const packed = packLaravelArray(keyToPack, param[keyToPack]);
			if (packed !== "") {
				// If first
				fullRequestUrl =
					fullRequestUrl === url
						? fullRequestUrl + "?" + packed
						: fullRequestUrl + "&" + packed;
			}
		}

		return axios.get(`${fullRequestUrl}`, {
			params: {
				date_from: param.date_from,
				date_to: param.date_to,
				timestamp: Date.now(),
				status: param.status,
			},
			responseType: "arraybuffer",
		});
	},
	fetchSectors: (params: any = {}) =>
		axios.get("/sectors", {
			params: {
				include: params.includes,
				non_empty: params.nonEmpty,
				for_client: params.forClient,
			},
		}),
	getNexusReport: (params: IReportParams) =>
		axios.post("nexus/download-nexus-report", params),
	getShiftBillingPeriods: () =>
		axios.get("invoices/shifts/get-billing-periods"),
	getInvoicesShiftBilling: (params: IBillingPeriods) =>
		axios.get("invoices/shifts/get-invoices-for-billing-period", { params }),
	getInvoicesBackup: (params: { id: number }) =>
		axios.get(`invoices/${params.id}/backup.csv`, {
			responseType: "arraybuffer",
			params: {
				timestamp: new Date().toISOString(),
			},
		}),
	getInvoicesPayAndBill: (params: { id: number }) =>
		axios.get(`invoices/${params.id}/pay-and-bill.csv`, {
			responseType: "arraybuffer",
			params: {
				timestamp: new Date().toISOString(),
			},
		}),
	getShiftInvoicesForGroupDownload: () => axios.get("/invoices/get-latest"),
	deleteInvoice: (id: number) => axios.delete(`invoices/${id}`),
	fetchShiftInvoices: (params: {
		searchClient?: string | null;
		clientId?: number | null;
		inv_externalid?: string | null;
		page: number;
	}) => {
		for (const key in params) {
			if (!params[key]) {
				delete params[key];
			}
		}
		return axios.get("/invoices", {
			params,
		});
	},
	createInvoice: (clientId: number) => {
		return axios.post("/invoices", {
			client_id: clientId,
		});
	},
	getHolidayBalanceInvoices: (params: { page: number }) =>
		axios.get(`invoices/holiday-request`, { params }),
	getHolidayBalanceInvoicesBackup: (params: { id: number }) =>
		axios.get(`invoices/holiday-request/${params.id}/backup.csv`, {
			responseType: "arraybuffer",
			params: {
				timestamp: new Date().toISOString(),
			},
		}),
	createHolidayRequestInvoices: (params: object) =>
		axios.post(`invoices/holiday-request`, params),
	getExpenseInvoices: (params: {
		inv_externalid?: string | null;
		page: number;
	}) => {
		for (const key in params) {
			if (!params[key]) {
				delete params[key];
			}
		}
		return axios.get(`invoices/expenses`, { params });
	},
	getExpenseInvoicesForGroupDownload: (params: { id: number }) =>
		axios.get(`invoices/expenses/get-backup-invoices-for-group-download`, {
			params,
		}),
	getExpenseInvoicesBackup: (params: { id: number }) =>
		axios.get(`invoices/expenses/${params.id}/backup.csv`, {
			responseType: "arraybuffer",
			params: {
				timestamp: new Date().toISOString(),
			},
		}),
	getExpenseInvoicesPayAndBill: (params: { id: number }) =>
		axios.get(`invoices/expenses/${params.id}/pay-and-bill.csv`, {
			responseType: "arraybuffer",
			params: {
				timestamp: new Date().toISOString(),
			},
		}),
	createExpenseInvoices: (params: object) =>
		axios.post(`invoices/expenses`, params),
	getExpenseBillingPeriods: () =>
		axios.get("invoices/expenses/get-billing-periods"),
	getInvoicesExpenseBilling: (params: IBillingPeriods) =>
		axios.get("invoices/expenses/get-invoices-for-billing-period", { params }),
	releaseAnnualLeave: (p: any) =>
		axios.put(`holiday-requests/release-premium-holiday-pay`, p),
	getAnnualLeaveInvoices: (p: any) =>
		axios.get<{ data: IAnnualLeavePayBillGet[]; meta: IMeta }>(
			`holiday-requests/premium-holiday-pay`,
			{ params: p },
		),
	annualLeaveDownloadExcel: (date: string) =>
		axios.get("holiday-requests/premium-holiday-pay-report", {
			responseType: "arraybuffer",
			params: { date },
		}),
	// #region management user
	postUserSecCliLocPerm: (params) =>
		axios.post(`admin/${params.usr_id}/sct-cli-loc-permissions`, params),
	postUserCatPerm: (params) =>
		axios.post(`admin/${params.usr_id}/cat-permissions`, params),
	getUserSecCliLocPerm: (params) =>
		axios.get(`admin/${params.usr_id}/sct-cli-loc-permissions`, { params }),
	getUserCatPerm: (params) =>
		axios.get(`admin/${params.usr_id}/cat-permissions`, { params }),
	getWholeAdminRegions: (params, signal) =>
		axios.get(`admin/sectors`, { params, signal }),
	getWholeAdminCategories: (params, signal) =>
		axios.get(`admin/categories`, { params, signal }),
	createUser: (payload) => {
		// includes
		return axios.post(
			`/users`,
			payload,
			// this is not used, but just in case, let it stay here because it was in the old app
			//  {
			// 	params: {
			// 		include: includes,
			// 	},
			// }
		);
	},
	// #endregion

	// #region location Client Management
	fetchClients: (q: any) => {
		return axios.get("/clients", {
			params: { include: q.includes, page: q.page, optimise: q.optimise },
		});
	},
	fetchClientsOptimized: (params: any) => {
		return axios.get("/clients-optimized", {
			params: {
				include: "defaultShiftTimes",
				page: params.page,
				search: params.search,
				client_external_id: params.client_external_id,
				active: params.active,
			},
		});
	},

	fetchClientDetails: (q: any) => {
		return axios.get(`/clients/${q.id}/detail`, {
			params: { include: q.include },
		});
	},
	fetchClientInfo: (q: any) => {
		return axios.get(`/clients/${q.id}`, {
			params: { include: q.include },
		});
	},

	fetchRegions: (q: any) => {
		return axios.get("/regions", {
			params: { include: q.includes },
		});
	},
	// fetchSectors locationClientManagement in old app
	fetchSectorsCliManagement: (q: any) => {
		return axios.get("/sectors", {
			params: { include: q.includes, for_client: q.forClient },
		});
	},

	fetchAllowances: (q: any) => {
		return axios.get("/calc-engines", {
			params: { include: q.includes },
		});
	},

	fetchServiceFeeTypes: () => {
		return axios({
			url: "/service-fee-calculation-types",
			method: "GET",
		});
	},

	fetchCategoryFees: (id: any) => {
		return axios.get(`/clients/${id}/service-fees-per-category`);
	},

	fetchAgencies: (q: any) => {
		return axios.get("/agencies", {
			params: { include: q.includes },
		});
	},
	updateCostCenter: (ccId: any, payload: any, include = "") => {
		const defaultInclude = "locationOnCallInfo,locationSleepoverInfo" + include;
		return axios.put(`/locations/${ccId}`, payload, {
			params: {
				// include: 'users,temps,categories.subcategories,region'
				include: defaultInclude,
			},
		});
	},

	createCostCenter: (payload: any) => {
		return axios.post(`/locations`, payload, {
			params: {
				// include: 'users,temps,categories.subcategories,region'
				include:
					"categories.subcategories,locationOnCallInfo,locationSleepoverInfo",
			},
		});
	},

	// Client Management > Copy location
	postCloneLocation: (params: any) =>
		axios.post(`locations/${params.id}/copy-location`, params, {
			params: {
				include:
					"users,temps,categories.subcategories,locationOnCallInfo,locationSleepoverInfo,region,defaultShiftTimes",
			},
		}),

	createClient: (payload: any) => {
		return axios.post(`/clients`, payload, {
			params: {
				include: [
					"locations.users",
					"locations.temps",
					"locations.categories.subcategories",
					"locations.locationOnCallInfo",
					"locations.locationSleepoverInfo",
					"region",
					"sectors",
					"calcEngine",
				].join(","),
			},
		});
	},
	updateClient: (clientId: any, payload: any) => {
		return axios.put(`/clients/${clientId}`, payload, {
			params: {
				// This includes are required for updated values
				include: ["region", "calcEngine", "sectors"].join(","),
			},
		});
	},
	createDefaultShiftTime: (clientId: any, payload: any) => {
		console.log("Invoked createDefaultShiftTime API ::", clientId, payload);
		return axios.post(`/default-shift-times`, {
			client_id: clientId,
			name: payload.name,
			start_time: payload.startTime,
			end_time: payload.endTime,
		});
	},
	deleteDefaultShiftTime: (shiftId: any) => {
		return axios.delete(`/default-shift-times/${shiftId}`);
	},
	refreshClientFromExternalInfo: (params: any) =>
		axios.put(`clients/${params.id}/update-info-by-external-id`),

	// #endregion

	// #region Client break times
	getBreakTimes: (params) =>
		axios.get(`clients/${params.id}/breaktimes-per-category`, { params }),
	patchBreakTimes: (params) => axios.put(`clients/${params.client_id}`, params),
	// #endregion

	// #region Shift confirm INTERVAL
	deleteClientNotifInterval: (p: { id: number; client_id: number }) =>
		axios.delete(`clients/${p.client_id}/email-intervals/${p.id}`),
	postClientNotifInterval: (p: {
		client_id: number;
		category_id: number;
		interval: number;
		action_based: boolean;
	}) => axios.post(`clients/${p.client_id}/email-intervals`, p),

	putClientNotifInterval: (p: {
		id: number;
		client_id: number;
		interval: number;
		action_based: boolean;
	}) => axios.put(`clients/${p.client_id}/email-intervals/${p.id}`, p),
	getClientNotifInterval: (p: { client_id: number }) =>
		axios.get(`clients/${p.client_id}/email-intervals`, { params: p }),

	getClientCategories: (params) =>
		axios.get(`clients/${params.client_id}/categories`, { params }),
	// Client Invoice
	getClientLocations: (params) =>
		axios.get(`clients/${params.client_id}/locations`, { params }),

	// Config API [Meta]
	getConfig: (params) => axios.get(`config`, { params }),
	postConfig: (params) => axios.post(`config`, params),
	// #endregion

	// #region  Column Names (localization)
	getColumnNamesLocl: (params) => axios.get("get-column-names", { params }),
	// #endregion

	// #region Client External ID
	getClientInfoExternalId: (params) =>
		axios.get(`clients/get-info-by-external-id/${params.id}`),
	// check the client fields if they are disabled
	getExternalServiceClientsDisabledFields: () => {
		return axios({
			url: "/clients/get-external-service-disabled-fields",
			method: "GET",
		});
	},
	getRatesClientsForClientManagement: (params) =>
		axios.get("get-rate-clients", { params }),
	// #endregion

	// #region temps
	fetchAllTemps: (params = {}) => {
		let url = `/temps`;

		const laravelArr = ["clients", "categories", "subcategories", "sectors"];
		for (const larKey of laravelArr) {
			if (params[larKey]) {
				const packed = packLaravelArray(larKey, params[larKey]);
				if (packed !== "") {
					// If first
					url = url.includes("?") ? url + "&" + packed : url + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete params[larKey];
			}
		}

		return axios.get(url, { params });
	},

	// #endregion

	//#region sectors
	getSectors: (params: TGetSectorParams) =>
		axios.get("sectors", {
			params,
		}),
	// #endregion

	//#region tags
	getAllTags: () => axios.get("tags"),
	//#endregion

	//#region counties
	getCounties: (
		params: {
			include?: number | boolean;
			non_empty?: number | boolean;
		} = {},
	) =>
		axios.get("/regions", {
			params,
		}),
	getExpenseTypes: () => axios.get("expense-types"),
	getExpenses: (params, cancelTokenSource) => {
		const paramPack = ["clients", "temps", "types"];
		const tempUrl = "/expenses";
		const url = generateLaravelUrl(params, paramPack, tempUrl);
		return axios.get(url, {
			params,
			cancelToken: cancelTokenSource.token,
		});
	},
	getExpense: (params: { id: number; include: string[] }) => {
		return axios.get(`expenses/${params.id}`, { params });
	},
	getFileBlob: (params: { link: string; [key: string]: any }) =>
		axios.get(params.link, { params, responseType: "blob" }),
	postFileBlob: (params) =>
		axios.post(params.link, params, { responseType: "blob" }),
	getExpenseInitialPrice: (params: { id: number; [key: string]: any }) =>
		axios.post(`/expenses/${params.id}/sign-off-preview`, params),
	checkReject: (params: { id: number }) => {
		return axios.post(`expenses/${params.id}/reject-check`, params);
	},
	checkCutOff: () => {
		return axios.post(`expenses/cut-off-check`);
	},
	checkSignOff: (params) => axios.post(`expenses/${params.id}/sign-off-check`),
	expenseReject: (params: { id: number; reject_reason: string }) =>
		axios.post(`/expenses/${params.id}/reject`, params),
	expenseSignOff: (params: { id: number }) =>
		axios.post(`/expenses/${params.id}/sign-off`, params),

	// #region Compliances Client
	fetchCompliances: (q) => {
		const tempPayload = JSON.parse(JSON.stringify(q));
		let fullRequestUrl = "/documenttypes";

		const laravelArr = ["clients"];
		for (const larKey of laravelArr) {
			if (tempPayload[larKey]) {
				const packed = packLaravelArray(larKey, tempPayload[larKey]);
				if (packed !== "") {
					// If first
					fullRequestUrl = fullRequestUrl.includes("/?")
						? fullRequestUrl + "&" + packed
						: fullRequestUrl + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete tempPayload[larKey];
			}
		}

		const morePayload: any = {};
		if (q.compliance_type) {
			// GNIB only used for now
			morePayload.compliance_type = q.compliance_type;
		}
		if (q.search) {
			morePayload.search = q.search;
		}

		const config: any = {
			params: {
				...morePayload,
				include: q.includes,
				page: q.page,
				optimized: q.optimized || false,
				// sort_by_field: "dtp_id",
				// sort_by_direction: "desc",
				sort_by_field: "dtp_name",
				sort_by_direction: "asc",
			},
		};

		if (q?.cancelTokenSource) {
			config.cancelToken = q?.cancelTokenSource?.token;
		}

		return axios.get(fullRequestUrl, config);
	},
	patchDocument: (docId, params = {}) => {
		return axios.patch(`/documenttypes/${docId}`, params, {
			params: {
				include: "locations,categories,roles",
			},
		});
	},

	fetchDocumentLocationsPerClient: (q) => {
		const tempPayload = JSON.parse(JSON.stringify(q));
		let fullRequestUrl = `/documenttypes/${q.id}/locations`;

		const laravelArr = ["clients"];
		for (const larKey of laravelArr) {
			if (tempPayload[larKey]) {
				const packed = packLaravelArray(larKey, tempPayload[larKey]);
				if (packed !== "") {
					// If first
					fullRequestUrl = fullRequestUrl.includes("/?")
						? fullRequestUrl + "&" + packed
						: fullRequestUrl + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete tempPayload[larKey];
			}
		}
		return axios({
			url: fullRequestUrl,
		});
	},
	fetchDocumentCategoriesPerClient: (q) => {
		const tempPayload = JSON.parse(JSON.stringify(q));
		let fullRequestUrl = `/documenttypes/${q.id}/categories`;

		const laravelArr = ["clients"];
		for (const larKey of laravelArr) {
			if (tempPayload[larKey]) {
				const packed = packLaravelArray(larKey, tempPayload[larKey]);
				if (packed !== "") {
					// If first
					fullRequestUrl = fullRequestUrl.includes("/?")
						? fullRequestUrl + "&" + packed
						: fullRequestUrl + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete tempPayload[larKey];
			}
		}
		return axios({
			url: fullRequestUrl,
		});
	},
	getClientContactsList: (params) => axios.get("contacts", { params }),
	getClientContactsTypesList: (params) => axios.get("contacttypes", { params }),
	deleteClientContacts: (params) =>
		axios.delete(`contacts/${params.id}`, { params }),
	putClientContacts: (params) => axios.put(`contacts/${params.id}`, params),
	postClientContacts: (params) => axios.post("contacts", params),

	// Shift contacts

	apiTSgetShiftContacts: (p: any) => {
		if (p.categories?.length) {
			const url = appendUrl({
				categories: p.categories,
			});
			return axios.get(`shift-confirmation-contacts${url}`, {
				params: p,
			});
		}
		return axios.get("shift-confirmation-contacts", { params: p });
	},
	apiTSpostClientContacts: (p: any) =>
		axios.post(`shift-confirmation-contacts`, p),
	apiTSputClientContacts: (p: any) =>
		axios.put(`shift-confirmation-contacts/${p.id}`, p),
	apiTSdeleteClientContacts: (p: { id: number }) =>
		axios.delete(`shift-confirmation-contacts/${p.id}`),
	// #endregion

	// #region Service Fee Client Service Fee (Finances)
	getServiceFeeCalcItems: (params) =>
		axios.get("servicefees/calcitems", { params }),
	patchServiceFees: (params) => axios.post(`servicefees`, params),
	getServiceFees: (params) => axios.get(`servicefees`, { params }),
	deleteServiceFees: (params) =>
		axios.delete(`servicefees/${params.id}`, params),
	postMergeRatesServiceFees: (params) =>
		axios.post(`servicefees/copy-from-rates`, params),
	postActivateFees: (params) => axios.post(`servicefees/activate`, params),
	getCheckInactiveFees: (params) =>
		axios.get("servicefees/check-inactive", { params }),

	// #endregion
	// #region Journal
	getNoteTypes2: (params) => axios.get("/notetypes", { params }),

	systemNotes: (data) => {
		let url = "/system-notes";
		const laravelArr = ["temps", "clients", "created_by_ids", "notetypes"];
		for (const larKey of laravelArr) {
			if (data.params[larKey]) {
				const packed = packLaravelArray(larKey, data.params[larKey]);
				if (packed !== "") {
					// If first
					url = url.includes("?") ? url + "&" + packed : url + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete data.params[larKey];
			}
		}

		return axios.get(url, { params: data.params, signal: data.abortSignal });
	},
	// Client Journal Notes
	postNotes: (params: FormData) =>
		axios.post(`/notes`, params, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}), // Formdata
	postOneNote: (params: { id: any; fd: FormData }) =>
		axios.post(`/notes/${params.id}`, params.fd, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}), // Formdata
	getNotes: (data) => {
		let url = "/notes";
		const laravelArr = ["temps", "clients", "created_by_ids", "notetypes"];
		for (const larKey of laravelArr) {
			if (data.params[larKey]) {
				const packed = packLaravelArray(larKey, data.params[larKey]);
				if (packed !== "") {
					// If first
					url = url.includes("?") ? url + "&" + packed : url + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete data.params[larKey];
			}
		}

		return axios.get(url, { params: data.params, signal: data.abortSignal });
	},
	getNoteReport: (params) => {
		let url = "/notes/report";
		const laravelArr = ["temps", "clients", "notetypes"];
		for (const larKey of laravelArr) {
			if (params[larKey]) {
				const packed = packLaravelArray(larKey, params[larKey]);
				if (packed !== "") {
					// If first
					url = url.includes("?") ? url + "&" + packed : url + "?" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete params[larKey];
			}
		}

		return axios.get(url, { params, responseType: "blob" });
	},
	getNotificationCentreChildLogDetails: (params) =>
		axios.get("notes/notification-centre-child-log-details", { params }),
	deleteNoteFile: (params) =>
		axios.delete(`/notefiles/${params.id}`, { params }),
	// #endregion

	// #region Book Shift
	// fetchTemps in old application
	fetchTempsOldApp: (query = {}) => {
		// const arr = ['subcategories']
		// const queryParam = packLaravelArray(arr[0], query[arr[0]])
		return axios.get(`/temps-for-shift`, {
			params: {
				...query,
				include: "subcategories,specialities,preferredRegions",
			},
		});
	},
	findTempsForShift: (q) => {
		return axios({
			url: "/temps-for-shift",
			method: "POST",
			// params: {
			// },
			data: Object.assign(q, {
				sort_by_field: "id",
				sort_by_direction: "asc",
				include: "subcategories,specialities,preferredRegions",
				//   includes: 'subcategories,specialities,preferredRegions'
			}),
		});
	},
	fetchActiveClientsList: (q) =>
		axios({
			url: "clients",
			params: {
				active: q.active || true,
				include: q.includes,
				per_page: q.per_page,
			},
		}),
	shiftRequestReasons: () => {
		return axios.get("/shift-request-reasons");
	},
	fetchLocationsPerClient: (q) => {
		const includes = "?include=locationOnCallInfo,locationSleepoverInfo";
		const suffixParams = includes;
		return axios({
			url: `/clients/${q.id}/locations${suffixParams}`,
		});
	},
	shiftRequestPost: (params) =>
		axios({
			url: "shift-requests",
			method: "POST",
			data: params,
		}),
	// Shift Activity Tags => old apiTS file
	getShiftActivityTagsForClient: (p: { client_id: number }) =>
		axios.get(`clients/${p.client_id}/shift-activity-tags`, { params: p }),
	createTimesheetEntry: (params) =>
		axios({
			url: "shifts/create-timesheet-line",
			method: "POST",
			data: params,
		}),
	// shifts activity tags
	getShiftsActivityTags: () => {
		return axios.get(`shift-activity-tags`);
	},

	// #endregion

	updateDocumentExpiry: (docId, tempId, params = {}) => {
		return axios.post(
			`/documents/${docId}/temps/${tempId}/update-expiry`,
			params,
			{
				params: {
					include: "locations,categories,roles,filesCount",
				},
			},
		);
	},
	approveDocument: (docId, tempId, params = {}) => {
		return axios.post(`/documents/${docId}/temps/${tempId}/approve`, params, {
			params: {
				include: "locations,categories,roles,filesCount",
			},
		});
	},
	postDocument: (params = {}) => {
		return axios.post(`/documenttypes`, params);
	},
	fetchDocumentLocations: (docId: number) => {
		return axios.get(`/documenttypes/${docId}/locations`);
	},
	deleteDocument: (docId, params = {}) => {
		return axios.delete(`/documenttypes/${docId}`, params);
	},
	fetchDocumentsForApproval: (q: any) => {
		return axios.get(`/documents-for-approval`, {
			params: {
				include: q.includes,
			},
			cancelToken: q.cancelTokenSource,
		});
	},
	fetchTemps: (params: any) => {
		let url = "/temps";
		if (params?.include && typeof params.include !== "string") {
			params.include = params.include.join(", ");
		}
		if (params?.addOnToUrl) {
			url += `?${params.addOnToUrl}`;
		}
		delete params.addOnToUrl;
		return axios.get(url, {
			params,
		});
	},
	downloadTempsComplianceReport: (params: any) => {
		let url = "/documents/temps/report";
		if (params?.addOnToUrl) {
			url += `?${params.addOnToUrl}`;
		}
		delete params.addOnToUrl;
		return axios.get(url, {
			params,
			responseType: "blob",
		});
	},
	fetchDocumentRejectionReasons: () => axios.get("document-rejection-reasons"),
	fetchTempDocumentFiles: (params: any) => {
		let url = `documenttypes/${params.id}?temp_id=${params.temp.id}&docTypeId=${params.id}&include=files`;
		if (params?.includeAll) {
			url += ",viewOldFiles";
		}
		return axios.get(url);
	},
	fetchTempComplianceDocuments: (
		params: TFetchTempDocumentsParams = {},
	): Promise<AxiosResponse<any>> => {
		params.url = "/documenttypes";
		return axios.get(params.url, {
			params: {
				include: params.include,
				temp_id: params.tempId,
				page: params.page,
			},
		});
	},
	deleteTempDocumentFiles: (params: any) => {
		return axios.delete(
			`documents/${params.id}/temps/${params.temp.id}/delete-files`,
			{
				data: {
					files: params.files,
				},
			},
		);
	},
	approveTempDocumentFiles: (params: any) => {
		return axios.post(
			`documents/${params.id}/temps/${params.temp.id}/approve`,
			params,
		);
	},
	rejectTempDocumentFiles: (params: any) => {
		return axios.post(
			`documents/${params.id}/temps/${params.temp.id}/reject`,
			params,
		);
	},

	/** START NOTIFICATIONS CENTER APIS */
	fetchMessages: (params: TNotificationsParams) => {
		let url: string = `/notification-centre/messages`;
		const additionalUrlParams = prepareGetQueryLaravelArrayParams(params);
		url += `?${additionalUrlParams}`;
		return axios.get(url, { params });
	},
	fetchEmailFroms: () => {
		return axios.get("/admin/security/notification-centre/email-from");
	},
	fetchSignatures: () => {
		return axios.get("/notification-centre/messages/signatures");
	},
	fetchSignatureHTML: (id: number) => {
		return axios.get(`/notification-centre/messages/signatures/${id}`);
	},
	updateSignature: (signature: TSignatures) => {
		return axios.put(
			`/notification-centre/messages/signatures/${signature.id}`,
			signature,
		);
	},
	createSignature: (signature: TSignatures) => {
		return axios.post("/notification-centre/messages/signatures", signature);
	},
	deleteSignature: (signature: TSignatures) => {
		return axios.delete(
			`/notification-centre/messages/signatures/${signature.id}`,
		);
	},
	fetchTemplates: (type: string) => {
		return axios.get(`notification-centre/templates?type=${type}`);
	},
	fetchTemplate: (id: number) => {
		return axios.get(`/notification-centre/templates/${id}`);
	},
	createTemplate: (template: FormData) => {
		return axios.post("/notification-centre/templates", template);
	},
	updateTemplate: (template: TNotificationCenterTemplate) => {
		return axios.put(`/notification-centre/templates/${template.id}`, template);
	},
	createMessage: (url: string, params: FormData) => {
		return axios.post(url, params);
	},
	getWorkersListOpenedMessages: (params: TFetchWorkersSeenMessageParams) =>
		axios.get("temps/seen-message-batch", { params }),
	/** END NOTIFICATIONS CENTER APIS */
	/** START RATE MANAGEMENT APIs */
	getRatesFrontConfig: (params: { date: string }) =>
		axios.get("rates/frontend-rate-management-config", { params }),
	getRatesData: (params: TGetRatesParams) => axios.get("rates", { params }),
	getGlobalRatesData: (params: TGetRatesParams) =>
		axios.get("global-rates", { params }),
	postColumnForRates: (params) =>
		axios({
			url: `/rates/values/${params.cit_id}`,
			method: "POST",
			data: params,
		}),
	patchRates: (params) =>
		axios({
			url: "rates",
			method: "PATCH",
			data: params,
		}),
	deleteRates: (params) =>
		axios({
			url: "rates",
			method: "DELETE",
			params,
		}),
	postRates: (params) =>
		axios({
			url: "rates",
			method: "POST",
			data: params,
		}),
	reviewRates: (params) =>
		axios.post(`rates/sanity-check-report`, params, { responseType: "blob" }),
	reviewRatesGlobal: (params) =>
		axios.post(`global-rates/sanity-check-report`, params, {
			responseType: "blob",
		}),
	getGlobalRatesCategories: (params) =>
		axios.get("global-rates/categories", { params }),
	postGlobalIncrementsCategory: (params) =>
		axios.post("global-rates/increments-for-categories", params),
	getGlobalRates: (params) => axios.get("global-rates", { params }),
	patchGlobalRates: (params) => axios.patch("global-rates", params),
	postGlobalRates: (params) => axios.post("global-rates", params),
	deleteGlobalRates: (params) => axios.delete("global-rates", { params }),
	postGlobalColumnForRates: (params) =>
		axios.post(`global-rates/values/${params.cit_id}`, params),
	patchGlobalRatesMulti: (params) => axios.patch("global-rates/multi", params),
	getGlobalRatesCalcItems: (params) =>
		axios.get("global-rates/calcitems", { params }),
	getGlobalRatesClients: (params) =>
		axios.get("global-rates/clients", { params }),
	postGlobalRatesFinalize: (params) =>
		axios.post("global-rates/finalize", params),
	getGlobalRatesMultiDropdown: (params) =>
		axios.get("global-rates/multi/items", { params }),
	getGlobalRatesCategoriesModal: (params) =>
		axios.get("global-rates/categories-for-modal", { params }),
	getGlobalRatesValidate: (params) =>
		axios.get("global-rates/validate", { params }),
	getGlobalRatesGaps: (params) => axios.get("global-rates/gaps", { params }),
	getGlobalRatesGapsReport: (params) =>
		axios.post("global-rates/gaps-report", params, { responseType: "blob" }),
	getRatesValidate: (params) => axios.get("rates/validate", { params }),
	getRatesGaps: (params) => axios.get("rates/gaps", { params }),
	getRatesGapsReport: (params) =>
		axios.post("rates/gaps-report", params, { responseType: "blob" }),
	getRatesCitReport: (params) =>
		axios.post("rates/cit-report", params, { responseType: "blob" }),
	getGlobalRatesCitReport: (params) =>
		axios.post("global-rates/cit-report", params, { responseType: "blob" }),
	deleteMultipleSpecificRates: (params) => axios.post(`rates/delete`, params),
	deleteMultipleGlobalRates: (params) =>
		axios.post(`global-rates/delete`, params),
	// Rates other API
	// Rates categories
	getRatesCategories: (params) =>
		axios({
			url: "rates/categories",
			params,
		}),
	postRatesCategories: (params) =>
		axios({
			url: `rates/categories`,
			method: "POST",
			data: params,
		}),
	patchRatesCategories: (params) =>
		axios({
			url: `rates/categories/${params.catId}`,
			method: "PATCH",
			data: params,
		}),
	deleteRatesCategories: (params) =>
		axios({
			url: `rates/categories/${params.catId}`,
			method: "DELETE",
			params,
		}),
	getClientUsingRates: (p: { clientId: string; type: string }) =>
		axios.get(`global-rates/clients/${p.clientId}/clients-using-rates`, {
			params: p,
		}),
	// Rates increments
	getRatesIncrementsCategory: (params) =>
		axios({
			url: `rates/increments-for-categories`,
			method: "POST",
			data: params,
		}),
	getRatesIncrements: (params) =>
		axios({
			url: `rates/categories/${params.catId}/increments`,
			params,
		}),
	postRatesIncrements: (params) =>
		axios({
			url: `rates/categories/${params.catId}/increments`,
			method: "POST",
			data: params,
		}),
	deleteRatesIncrements: (params) =>
		axios({
			url: `rates/categories/${params.catId}/increments/${params.incId}`,
			method: "DELETE",
			params,
		}),
	patchRatesIncrements: (params) =>
		axios({
			url: `rates/categories/${params.catId}/increments/${params.incId}`,
			method: "PATCH",
			data: params,
		}),

	// Rates
	getRatesSectors: (params) =>
		axios({
			url: "rates/sectors",
			params,
		}),
	getRatesCalcItems: (params) =>
		axios({
			url: "rates/calcitems",
			params,
		}),
	getRatesClients: (params) =>
		axios({
			url: "rates/clients",
			params,
		}),
	postRatesFinalize: (params) =>
		axios({
			url: "rates/finalize",
			method: "POST",
			data: params,
		}),
	getMaxEffectiveDate: (params) =>
		axios.get("rates/max-effective-date", { params }),
	postCategory: (params) => axios.post("/rates/categories", params),
	patchCategory: (params) =>
		axios.patch(`/rates/categories/${params.catId}`, params),
	/** END RATE MANAGEMENT APIs */
	/** START EXPENSES RATE MANAGEMENT APIs */
	getUserPaymentRates: (params) => axios.get(`/userpayment-rates`, { params }),
	postUserPaymentRates: (params) => axios.post(`/userpayment-rates`, params),
	deleteUserPaymentRates: (params) =>
		axios.delete(`/userpayment-rates`, { params }),
	getUserPaymentRatesConfig: (params) =>
		axios.get(`/userpayment-rates/frontend-rate-management-config`, { params }),
	getUserPaymentRatesMaxEffectiveDate: (params) =>
		axios.get(`/userpayment-rates/max-effective-date`, { params }),
	postUserPaymentFinalize: (params) =>
		axios.post(`/userpayment-rates/finalize`, params),
	/** END EXPENSES RATE MANAGEMENT APIs */
	/** START PUBLIC HOLIDAYS RATE MANAGEMENT APIs */
	getPublicHolidaysYears: (params) =>
		axios.get(`public-holidays/metaInfo`, { params }),
	getPublicHolidaysByYear: (params) => axios.get(`public-holidays`, { params }),
	postCreatePublicHoliday: (params) => axios.post(`public-holidays`, params),
	updateHoliday: (params) => axios.put(`public-holidays`, params),
	deleteHoliday: (params) => axios.delete(`public-holidays`, { params }),
	copyHolidays: (params) => axios.post(`copy-public-holidays`, params),
	/** END PUBLIC HOLIDAYS RATE MANAGEMENT APIs */
	/** START SHIFT SCHEDULE APIs */
	getShiftStatuses: () => axios.get(`admin/shifts/payroll-statuses`),
	fetchShifts: (payload = {}, cancelTokenSource) => {
		const tempPayload: any = Object.assign({}, payload);
		let fullRequestUrl = tempPayload.url;

		const laravelArr = [
			"sectors",
			"clients",
			"categories",
			"subcategories",
			"payroll_shift_statuses",
		];
		for (const larKey of laravelArr) {
			if (tempPayload[larKey]) {
				const packed = packLaravelArray(larKey, tempPayload[larKey]);
				if (packed !== "") {
					// If first
					fullRequestUrl =
						fullRequestUrl === tempPayload.url && !fullRequestUrl.includes("/?")
							? fullRequestUrl + "?" + packed
							: fullRequestUrl + "&" + packed;
				}
				// Has to be called (delete) so it doesn't send duplicate params
				delete tempPayload[larKey];
			}
		}

		return axios.get(fullRequestUrl, {
			params: omit(tempPayload, ["url"]),
			cancelToken: cancelTokenSource.token,
		});
	},
	fetchTempsForShift: (id, params) => {
		return axios.get(`/temps-for-shift/${id}`, {
			params: {
				...params,
				// availability: showRegardlessAvailability,
				per_page: 1000,
			},
		});
	},
	pushToTemps: (id, payload = {}) => {
		return axios.post(`/shifts/${id}/push`, payload, {
			params: {
				include: [
					"shiftRequest.subcategories",
					"shiftRequest.createdBy",
					"location.client.locations",
					"temp",
					"previousTemp",
					"shiftParts",
					"tags",
				].join(","),
			},
		});
	}, // Shift Price Overwrite
	getShiftPriceOverwriteCheckInfo: (p: { shift_id: number }) =>
		axios.get(`admin/shifts/${p.shift_id}/check-override-shift`),
	getShiftPriceOverwriteData: (p: { shift_id: number }) =>
		axios.get(`admin/shifts/${p.shift_id}/get-shift-calculation-data`),
	getShiftPriceOverwriteCalcitemsMeta: (p: { shift_id: number }) =>
		axios.get(`admin/shifts/${p.shift_id}/override-config`),
	getShiftPriceOverwriteCalculationPreview: (
		p: IFormatedShiftPriceOverwriteDataInterface,
	) =>
		axios.post(`admin/shifts/${p.shift_id}/get-shift-calculation-preview`, p),
	confirmShiftPriceOverwriteCalculation: (
		p: IFormatedShiftPriceOverwriteDataInterface,
	) => {
		return axios.post(
			`admin/shifts/${p.shift_id}/override-shift-calculation`,
			p,
			{
				params: {
					include: [
						"shiftRequest.subcategories",
						"shiftRequest.createdBy",
						"location.client.locations",
						"temp",
						"previousTemp",
						"signedOffBy",
						"shiftParts",
					].join(","),
				},
			},
		);
	},
	getViableSubcategories: (params) =>
		axios.get(`shifts/${params.id}/get-viable-subcategories`, { params }),
	postMultipleShiftsDay: (params) =>
		axios.post(`shifts/${params.shift_id}/sign-off-check`, params),
	/**
	 * Client sign off. ('Awaiting client' button on ShiftSchedule COMPLETED tab)
	 */
	clientSignOff: (url, data) => {
		return axios.post(url, data, {
			params: {
				include: [
					"shiftRequest.subcategories",
					"shiftRequest.createdBy",
					"location.client.locations",
					"temp",
					"previousTemp",
					"signedOffBy",
					"shiftParts",
				].join(","),
			},
		});
	},
	clientRecalculateShift: (url, data) => {
		return axios.post(url, data, {
			params: {
				include: [
					"shiftRequest.subcategories",
					"shiftRequest.createdBy",
					"location.client.locations",
					"temp",
					"previousTemp",
					"signedOffBy",
					"shiftParts",
				].join(","),
			},
		});
	},
	switchShift: (id, payload) => {
		return axios.post(`shifts/${id}/toggle-sign-off`, payload);
	},
	addShiftsToInvoices: (payload) => {
		return axios.post(`shifts/add-to-invoice`, payload);
	},
	checkRecalculate: (payload) => {
		return axios.post(`shifts/${payload}/recalculate-check`, payload);
	},
	cancellationReasons: () => {
		return axios.get("/shift-cancellation-reasons");
	},
	checkCancellationShifts: (params) => {
		return axios.get(`/shifts/${params}/cancel-check`);
	},
	cancelShift: (params) => {
		return axios.post(`/shifts/cancel`, params);
	},
	// api for cancelling multiple shifts
	multipleCancelShifts: (payload) => {
		return axios.post(`shifts/multiple-cancel`, payload);
	},
	fetchShiftHistory: (params) => {
		return axios.get(params.url, {
			params: {
				include: params.include,
			},
		});
	},
	getShiftConfirmationLogs: (id) => {
		return axios.post(`shifts/${id}/shift-confirmation-logs`);
	},
	getShiftQuote: (p: TShiftQuote) =>
		axios.get<{ data: TShiftQuoteResponse }>(`shifts/${p.id}/get-quote`, {
			params: p,
		}),
	postShiftQuote: (p: TShiftQuote) =>
		axios.post(`shifts/${p.id}/confirm-quote`, p),
	getShiftQuoteReport: (p: { id: number }) =>
		axios.post(`shifts/${p.id}/get-quote-report`, p, {
			responseType: "arraybuffer",
		}),
	getTempsShift: (p: { id: number; name: string; availability: number }) =>
		axios.get<{ data: ITempsForShift[]; meta: IMeta }>(
			`temps-for-shift/${p.id}?name=${p.name}`,
			{ params: p },
		),
	postConfirmShift: (p: { id: number; note: string }) =>
		axios.post(`shifts/${p.id}/confirm-temp`, p),
	// Getting payroll shift logs on shift details Payment Logs tab
	getPayrollShiftLogs: (params) =>
		axios.get(`admin/shifts/${params.shift_id}/payroll-shift-logs`, { params }),
	getExternalHistoryLog: (params) =>
		axios.get(`admin/shifts/${params.shift_id}/calculations-external-info`, {
			params,
		}),
	/** END SHIFT SCHEDULE APIs */
	fetchTempShiftsLog: (params: {
		tempId: number;
		include?: string;
		page: number;
	}) => {
		return axios.get(`/temps/${params.tempId}/shift-logs`, { params });
	},
	getIncrementWeeksCounterValue: (params: any) =>
		axios.get(`temps/${params.id}/increment-weeks-counter`, { params }),
	postIncrementWeeksCounterValue: (params: any) =>
		axios.post(`temps/${params.temp_id}/increment-weeks-counter`, params),
	deleteIncrementTemp: (params: any) =>
		axios.delete(`temps/${params.temp_id}/increments`, { params }),
	getIncrementWeeksCounter: (params?) =>
		axios.get("admin/security/increment-counter-range", { params }),
	postIncrementTemp: (params) =>
		axios.post(`temps/${params.temp_id}/increments`, params),
	getIncrements: (params: any) => axios.get("increments", { params }),
	postUpdateForCategory: (params) =>
		axios.post(
			`temps/${params.temp_id}/increments/update-for-category`,
			params,
		),
	postTemp: (params) => axios.post("/temps", params),
	fetchTempInfoFromTss: (params: { id: string }) =>
		axios.get("/temps/get-info-by-external-id/" + params.id),
	externalTempInfo: (params: { tempId: number }) =>
		axios.get(`temps/${params.tempId}/external-info`),
	confirmRehire: (params: {
		tempId: number;
		autogenerate_password: boolean;
		contract_start_date: string;
	}) => axios.post(`temps/${params.tempId}/rehire`, params),
	getUserProfile: (query: Record<string, any>) =>
		axios.get("/profile", { params: query }),
	patchUserProfile: (data: any) => axios.patch("/profile", data),
};
