<script lang="ts" setup>
defineProps<{
	modalProps: any;
}>();
const emit = defineEmits(["close-modal"]);
function closeModal() {
	emit("close-modal");
}
</script>
<template>
	<div class="before-wrapper">
		<!-- <div>
			<img
				src="@/assets/image/warning.svg"
				style="width: 110px"
				alt=""
			/>
			<div class="warningTxt">Warning</div>
		</div> -->
		<div class="message mgb-20">
			{{ modalProps.afterApiData.checkRecalculateData.after_api }}
		</div>
		<div class="button">
			<button
				class="button is-generic-app-blue"
				@click.prevent="closeModal"
			>
				OK
			</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
.before-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px 20px;
	width: 550px;
	.message {
		background-color: $white;
		text-align: center;
	}
	.button {
		border: none;
		margin: 0 5px;
		button {
			width: 60px;
			height: 2.75em;
			border: none;
		}
	}
}
.warningTxt {
	font-size: 2em;
}
</style>
