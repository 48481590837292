<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";
import { useClientManagementStore } from "@/store/locationClientManagement";

const toast = useToastStore();
const clientStore = useClientManagementStore();

// Define props
const props = defineProps<{
	modalProps: {
		client: { id: number };
		location: {
			id: number;
			name: string;
			categories?: { subcategories: any[] }[];
		};
	};
}>();
console.log(props, "props..");
// Define emit
const emit = defineEmits(["close-modal"]);

// Parameters for the request
const params = ref({
	clientId: props.modalProps.client.id,
	id: props.modalProps.location.id,
	name: props.modalProps.location.name,
});
// Backend expects [has_allowance], but has [hasLocationAllowance]
// Backend expects snake case, gives camel case
// has_community_allowance: this.modalProps.location.hasCommunityAllowance || false,
// has_allowance: this.modalProps.location.hasLocationAllowance || false,
// has_secure_allowance: this.modalProps.location.hasSecureAllowance || false,
// subcategories: this.getSubcategories(this.modalProps.location.categories) || [],
// temps: this.modalProps.location.temps || [],
// users: this.modalProps.location.users || [],

// Helper function for extracting subcategories
// const getSubcategories = (categories?: Array<{ subcategories: any[] }>) => {
// 	return categories?.map((cat) => cat.subcategories).flat() || [];
// };

// Clone location handler
const onCreate = async () => {
	try {
		const payload = { ...params.value };

		// await store.dispatch("locationClientManagement/postCloneLocation", payload);
		await clientStore.postCloneLocation(payload);
		toast.openToastInfo("Ward copied successfully!");
		emit("close-modal");
	} catch (err: any) {
		console.warn(err.message);
		const errs = parseErrors(err);
		toast.openToastError(errs);
	}
};
</script>

<template>
	<div class="client-copy-cost-center">
		<div class="header-title">
			<span>Are you sure you want to copy ward?</span>
		</div>
		<div class="input-wrap">
			<label class="label">Enter a new name for ward</label>
			<input
				v-model="params.name"
				class="input"
				placeholder="Enter a new name for ward"
				type="text"
				@keyup.enter="onCreate"
			/>
		</div>
		<div class="button-wrap">
			<button
				class="button is-generic-app-blue"
				@click="onCreate"
			>
				SAVE
			</button>
			<button
				class="button is-generic-app-blue"
				@click="emit('close-modal')"
			>
				CANCEL
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
$label-col: #7c91ae;
.client-copy-cost-center {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 600px;
	padding: 0 60px 45px;

	.header-title {
		text-align: center;
	}
	.label {
		color: $label-col;
	}
	input {
		width: 100%;
	}

	.input-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}

	.button-wrap {
		display: flex;
		gap: 15px;
		justify-content: center;
	}
}
</style>
