<script setup lang="ts">
import { Errors } from "@/assets/js/errors";
import { parseErrors } from "@/assets/js/parseErrors.js";
import { RATE_TYPE_OPTIONS } from "./rateData";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import { useClientManagementStore } from "@/store/locationClientManagement";
import { useToastStore } from "@/store/toast";
import api from "@/api";
import { openFinancePageRedirect } from "@/components/modals/modalDefinitions";

const sessionStore = useSessionStore();
const systemPermissions = useDefaultStore().initialStatus;
const canUseNexusMiddleware = systemPermissions?.can_use_nexus_middleware;
const gPermissions = sessionStore.gPermissions;
const can = sessionStore.can;

type TSelected = {
	id: number;
	name: string;
};

const emit = defineEmits(["close-modal"]);

const clientManagementStore = useClientManagementStore();
const toast = useToastStore();

const clientName = ref("");
const customClientId = ref(null);
const addressLine1 = ref("");
const addressLine2 = ref("");
const selectedClientCounty = ref<TSelected>(null);
const selectedClientSector = ref<number[]>([]);
const selectedType = ref<TSelected>(null);
const selectedClientAllowance = ref<TSelected>(null);
const selectedServiceFeeCalculationType = ref<TSelected>(null);
const eircode = ref("");
const billingContactName = ref("");
const billingContactTitle = ref("");
const billingContactEmail = ref("");
const clientPhone = ref("");
const isRateClientLoading = ref(false);
const isExternalLoading = ref(false);
const isCreatingClient = ref(false);
const selectedRateType = ref<TSelected>(null);
const selectedRateClient = ref<TSelected>(null);
const rateTypeOptions = RATE_TYPE_OPTIONS;
const rateClientsOptions = ref([]);
const errors = ref(new Errors());
const disabledFields = ref([]);
const newClientData = ref(null);
const isClientSubmitted = ref(false);
const disabledFilds = ref([]);

// const counties = computed(
// 	() => store.getters["locationClientManagement/getCounties"],
// );
const counties = computed(() => clientManagementStore.getCounties());
// const sectors = computed(
// 	() => store.getters["locationClientManagement/getFlatSectors"],
// );
const sectors = computed(() => clientManagementStore.getFlatSectors());
// const allowances = computed(
// 	() => store.getters["locationClientManagement/getAllowances"],
// );
const allowances = computed(() => clientManagementStore.getAllowances());
// const agencies = computed(
// 	() => store.getters["locationClientManagement/getAgencies"],
// );
// const agencies = computed(() => clientManagementStore.getAgencies());
// const feeTypes = computed(
// 	() => store.getters["locationClientManagement/getServiceFeeTypes"],
// );
const feeTypes = computed(() => clientManagementStore.getServiceFeeTypes());

const isExternalDisabled = computed(() => !customClientId.value);

const getEirCodeLabelKey = computed(
	() => clientManagementStore.getColumnNameLocl("usr_eircode"),
	// store.getters.getColumnNameLocl("usr_eircode"),
);

const isRateTypeSelectedValid = computed(() => {
	if (selectedRateType.value && selectedRateType.value.id) {
		const validRateTypeIdsForNextSection = [1, 2];
		return validRateTypeIdsForNextSection.includes(selectedRateType.value.id);
	}
	return false;
});

const isDisabledClientId = computed(() => false); // Assuming no permission check
function isDisabled(key) {
	switch (key) {
		case "name": {
			return disabledFields.value.includes("name");
		}
		case "client_external_id": {
			const isBlocked = disabledFields.value.includes("client_external_id");
			return isBlocked || isDisabledClientId.value;
		}
		case "addressLine1": {
			return disabledFields.value.includes("addressLine1");
		}
		case "addressLine2": {
			return disabledFields.value.includes("addressLine2");
		}
		case "eircode": {
			return disabledFields.value.includes("eircode");
		}
		case "phone": {
			return disabledFields.value.includes("phone");
		}
		case "billingContactEmail": {
			return disabledFields.value.includes("billingContactEmail");
		}
	}
}
const hasPermission = computed(() => can("manage-rates"));

const getExternalDisabledFilds = async () => {
	try {
		// const res = await store.dispatch("getExternalServiceClientsDisabledFields");
		// it is unnecessary to use actions because it is not stored in the storage
		const res = await api.getExternalServiceClientsDisabledFields();
		disabledFilds.value = res.data?.data || res.data;
	} catch (error) {
		const errs = parseErrors(error);
		toast.openToastError(errs);
	}
};

const clientSubmit = async () => {
	const postParams: any = {
		region_id: selectedClientCounty.value?.id,
		sectors: Array.isArray(selectedClientSector.value)
			? selectedClientSector.value.map((sec) => ({ id: sec }))
			: [],
		type: selectedType.value?.id,
		calcengine_id: selectedClientAllowance.value?.id,
		name: clientName.value,
		address_line_1: addressLine1.value,
		address_line_2: addressLine2.value,
		eircode: eircode.value,
		billing_contact_name: billingContactName.value,
		billing_contact_title: billingContactTitle.value,
		billing_contact_email: billingContactEmail.value,
		phone: clientPhone.value,
		service_fee_calculation_type_id:
			selectedServiceFeeCalculationType.value?.id,
		rate_client_action: selectedRateType.value?.id,
		rate_client_id: selectedRateClient.value?.id,
	};

	if (customClientId.value) {
		postParams.client_external_id = customClientId.value;
	}

	isCreatingClient.value = true;
	try {
		// const res = await store.dispatch(
		// 	"locationClientManagement/createClient",
		// 	postParams,
		// );
		// { data: { id: 123 } };
		const res = await clientManagementStore.createClient(postParams);
		newClientData.value = res.data;
		openFinancePageRedirect(res.data);
		isClientSubmitted.value = true;
		isCreatingClient.value = false;
		toast.openToastSuccess("New client created");
		if (!hasPermission.value) {
			callModalClose();
		}
	} catch (error) {
		isCreatingClient.value = false;
		if (error.response?.data?.errors) {
			errors.value.record(error.response.data.errors);
		}
		const msg = parseErrors(error, "", true);
		toast.openToastError(msg);
	}
};

const fetchClientExternalInfo = async () => {
	isExternalLoading.value = true;
	const params = { id: customClientId.value };
	errors.value.clear("custom_client_id");
	try {
		// const res = await store.dispatch("getClientInfoExternalId", params);
		// it is unnecessary to use actions because it is not stored in the storage
		const res = await api.getClientInfoExternalId(params);
		const resForm = res.data.data;
		clientName.value = resForm.name;
		customClientId.value = resForm.client_external_id;
		addressLine1.value = resForm.addressLine1;
		addressLine2.value = resForm.addressLine2;
		billingContactEmail.value = resForm.billingContactEmail;
		billingContactName.value = resForm.billingContactName;
		eircode.value = resForm.eircode;
		clientPhone.value = resForm.phone;
		if (!canUseNexusMiddleware) {
			disabledFields.value = Object.keys(resForm);
		}
	} catch (err) {
		const msg = parseErrors(err);
		errors.value.record({ custom_client_id: [msg] });
	} finally {
		isExternalLoading.value = false;
	}
};

function onSelectRateType() {
	selectedRateClient.value = null;
	errors.value.clear("rate_client_action");
	nextTick(() => {
		// This is because the computed is done after the event
		if (isRateTypeSelectedValid.value) {
			fetchRateClients();
		}
	});
}
async function fetchRateClients() {
	const id = selectedRateType.value?.id;
	rateClientsOptions.value = [];
	isRateClientLoading.value = true;
	const params: any = {};
	if (id === 1) {
		params.system = 1;
	}
	try {
		// const res = await this.getRatesClientsForClientManagement(params);
		// it is unnecessary to use actions because it is not stored in the storage
		const res = await api.getRatesClientsForClientManagement(params);
		rateClientsOptions.value = res.data.data;
	} catch (err) {
		console.warn("Error >>", parseErrors(err));
	} finally {
		isRateClientLoading.value = false;
	}
}

function callModalClose() {
	emit("close-modal");
}

onMounted(async () => {
	if (canUseNexusMiddleware) {
		await getExternalDisabledFilds();
	}

	// store.dispatch("locationClientManagement/fetchRegions");
	// store.dispatch("locationClientManagement/fetchSectors");
	await clientManagementStore.fetchRegions();
	await clientManagementStore.fetchSectors();
	if (gPermissions.canViewRulesCalcEng) {
		// store.dispatch("locationClientManagement/fetchAllowances");
		await clientManagementStore.fetchAllowances();
	}
	// store.dispatch("locationClientManagement/fetchAgencies");
	// store.dispatch("locationClientManagement/fetchServiceFeeTypes");
	await clientManagementStore.fetchAgencies();
	await clientManagementStore.fetchServiceFeeTypes();

	// this used to be called in app vue
	await clientManagementStore.getColumnNamesLocl();
});
</script>
<template>
	<div class="wrapper">
		<div
			v-if="!isClientSubmitted"
			class="dialog-container"
		>
			<div class="form-input-wrap">
				<div class="control">
					<div class="field">
						<label class="label">Name</label>
						<div class="control">
							<input
								v-model="clientName"
								class="input"
								type="text"
								placeholder="Please enter Client name here"
								:disabled="isDisabled('name') || disabledFilds.includes('name')"
								@input="errors.clear('name')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('name')"
					class="help is-danger"
					data-cell="name_error"
					v-html="errors.get('name')"
				></span>
				<div
					v-if="can('view-client-custom-id')"
					class="client-reference-id"
				>
					<div class="control">
						<div class="field">
							<label class="label">
								{{
									canUseNexusMiddleware ? "Client Reference ID" : "Client ID"
								}}
							</label>
							<div class="control">
								<input
									v-model="customClientId"
									class="input"
									:placeholder="
										canUseNexusMiddleware
											? 'Please enter Client Reference ID here'
											: 'Please enter Client ID here'
									"
									:disabled="isDisabled('client_external_id')"
									@input="errors.clear('custom_client_id')"
								/>
							</div>
						</div>
					</div>
					<button
						v-if="canUseNexusMiddleware"
						class="button is-info"
						data-cell="tss-id-btn"
						:class="[
							{ 'is-loading': isExternalLoading },
							{ 'is-disabled': isExternalDisabled },
						]"
						@click.prevent="fetchClientExternalInfo"
					>
						Pull From ext. system
					</button>
				</div>
				<span
					v-if="errors.has('custom_client_id')"
					class="help is-danger"
					data-cell="custom_client_id"
					v-html="errors.get('custom_client_id')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Region</label>
						<multiselect-form
							v-model="selectedClientSector"
							:options="sectors"
							mode="tags"
							:close-on-select="false"
							value-prop="id"
							label="name"
							placeholder="SELECT CLIENT REGION"
							:disabled="disabledFilds.includes('sector_id')"
							@select="errors.clear('sector_id')"
						/>
					</div>
				</div>
				<span
					v-if="errors.has('sector_id')"
					class="help is-danger"
					v-html="errors.get('sector_id')"
				></span>
				<span
					v-if="errors.has('type')"
					class="help is-danger"
					v-html="errors.get('type')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Rate Type</label>
						<multiselect-form
							v-model="selectedRateType"
							:options="rateTypeOptions"
							value-prop="id"
							label="name"
							placeholder="SELECT RATE TYPE"
							@select="onSelectRateType"
						/>
					</div>
				</div>
				<span
					v-if="errors.has('rate_client_action')"
					class="help is-danger"
					v-html="errors.get('rate_client_action')"
				></span>
				<div
					v-if="isRateTypeSelectedValid"
					class="control"
				>
					<div class="field">
						<label class="label">Rate Clients</label>
						<multiselect-form
							v-model="selectedRateClient"
							:options="rateClientsOptions"
							:loading="isRateClientLoading"
							value-prop="id"
							label="name"
							placeholder="SELECT RATE CLIENT"
							@select="errors.clear('rate_client_id')"
						/>
					</div>
				</div>
				<span
					v-if="errors.has('rate_client_id')"
					class="help is-danger"
					v-html="errors.get('rate_client_id')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Service Fee Calculation Type</label>
						<multiselect-form
							v-model="selectedServiceFeeCalculationType"
							:options="feeTypes"
							value-prop="id"
							label="name"
							placeholder="SELECT SERVICE FEE CALCULATION TYPE"
							@select="errors.clear('service_fee_calculation_type_id')"
						/>
					</div>
				</div>
				<span
					v-if="errors.has('service_fee_calculation_type_id')"
					class="help is-danger"
					data-cell="service_fee_calculation_type_id_error"
					v-html="errors.get('service_fee_calculation_type_id')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Rules</label>
						<multiselect-form
							v-model="selectedClientAllowance"
							:options="allowances"
							value-prop="id"
							label="name"
							placeholder="SELECT CLIENT RULES"
							@select="errors.clear('calcengine_id')"
						/>
					</div>
				</div>
				<span
					v-if="errors.has('calcengine_id')"
					class="help is-danger"
					v-html="errors.get('calcengine_id')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Address Line 1</label>
						<div class="control">
							<input
								v-model="addressLine1"
								class="input"
								type="text"
								placeholder="Please enter Client address here"
								:disabled="
									isDisabled('addressLine1') ||
									disabledFilds.includes('addressLine1')
								"
								@input="errors.clear('address_line_1')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('address_line_1')"
					class="help is-danger"
					data-cell="address_line_1_error"
					v-html="errors.get('address_line_1')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Address Line 2</label>
						<div class="control">
							<input
								v-model="addressLine2"
								class="input"
								type="text"
								placeholder="Please enter Client address here"
								:disabled="
									isDisabled('addressLine2') ||
									disabledFilds.includes('addressLine2')
								"
								@input="errors.clear('address')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('address')"
					class="help is-danger"
					v-html="errors.get('address')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">County</label>
						<multiselect-form
							v-model="selectedClientCounty"
							:options="counties"
							value-prop="id"
							label="name"
							placeholder="SELECT CLIENT COUNTY"
							@select="errors.clear('region_id')"
						/>
					</div>
				</div>
				<span
					v-if="errors.has('region_id')"
					class="help is-danger"
					data-cell="region_id_error"
					v-html="errors.get('region_id')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">{{ getEirCodeLabelKey }}</label>
						<div class="control">
							<input
								v-model="eircode"
								class="input"
								type="text"
								:placeholder="
									'Please enter Client ' + getEirCodeLabelKey + ' here'
								"
								:disabled="
									isDisabled('eircode') || disabledFilds.includes('eircode')
								"
								@input="errors.clear('eircode')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('eircode')"
					class="help is-danger"
					data-cell="eircode_error"
					v-html="errors.get('eircode')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Billing Contact Name</label>
						<div class="control">
							<input
								v-model="billingContactName"
								class="input"
								type="text"
								placeholder="Please enter Client contact name here"
								:disabled="disabledFilds.includes('billingContactName')"
								@input="errors.clear('billing_contact_name')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('billing_contact_name')"
					class="help is-danger"
					data-cell="billing_contact_name_error"
					v-html="errors.get('billing_contact_name')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Billing Contact Title</label>
						<div class="control">
							<input
								v-model="billingContactTitle"
								class="input"
								type="text"
								placeholder="Please enter Client contact title here"
								:disabled="disabledFilds.includes('billingContactTitle')"
								@input="errors.clear('billing_contact_title')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('billing_contact_title')"
					class="help is-danger"
					data-cell="billing_contact_title_error"
					v-html="errors.get('billing_contact_title')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Billing Contact E-mail</label>
						<div class="control">
							<input
								v-model="billingContactEmail"
								class="input"
								type="text"
								placeholder="Please enter Client e-mail here"
								:disabled="
									isDisabled('billingContactEmail') ||
									disabledFilds.includes('billingContactEmail')
								"
								@input="errors.clear('billing_contact_email')"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('billing_contact_email')"
					class="help is-danger"
					data-cell="billing_contact_email_error"
					v-html="errors.get('billing_contact_email')"
				></span>
				<div class="control">
					<div class="field">
						<label class="label">Phone</label>
						<div class="control">
							<input
								v-model="clientPhone"
								class="input"
								type="text"
								placeholder="Please enter Client Phone here"
								:disabled="
									isDisabled('phone') || disabledFilds.includes('phone')
								"
							/>
						</div>
					</div>
				</div>
				<span
					v-if="errors.has('phone')"
					class="help is-danger"
					v-html="errors.get('phone')"
				></span>
			</div>
			<div class="buttons-centered">
				<button
					class="button is-generic-app-blue is-caps-lock"
					data-cell="submit-button"
					type="submit"
					:disabled="isCreatingClient"
					@click.prevent="clientSubmit"
				>
					<img
						v-if="isCreatingClient"
						class="btn-loader"
						src="@/assets/image/loader.svg"
					/>
					<span>CREATE</span>
				</button>
				<button
					class="button is-outlined is-caps-lock"
					data-cell="cancel-button"
					@click.prevent="callModalClose"
				>
					CANCEL
				</button>
			</div>
		</div>
		<finance-page-redirect
			v-if="isClientSubmitted"
			:clientData="newClientData"
			@modal-close="callModalClose"
		></finance-page-redirect>
	</div>
</template>

<style lang="scss">
// .location-client-management {
//   .modal {
//     .modal-content {
//       .panel-block {
//         padding: 0;
//       }
//     }
//   }
// }
</style>
<style lang="scss" scoped>
.dialog-container {
	width: 589px;
	// overflow: hidden;
	height: calc(100vh - 150px);
	overflow-y: auto;

	.form-input-wrap {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		overflow-x: hidden;
		// padding: 0 1.3em;

		label {
			margin-top: 0;
		}
	}
}

.control {
	margin: 0.75rem 0 0 !important;
	input {
		width: 100%;
	}
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}

table.no-border {
	border: 0;
}

.btn-loader {
	height: 18px;
	padding-right: 4px;
}

.client-reference-id {
	display: flex;
	gap: 5px;
	align-items: flex-end;

	.control:first-child {
		width: 100%;
	}

	button {
		// margin-bottom: 0.75rem;
		height: 2.285em;
	}
}

.help {
	margin: 0 0 10px;
}
.help.is-danger {
	background: $background-color;
}
</style>
