import api from "@/api";
import { jwtDecode } from "jwt-decode";
import {
	getLocalStorageReac,
	getSessionStorageReac,
	setLocalStorageReac,
	setSessionStorageReac,
} from "@/assets/js/helpers";
import { useToastStore } from "./toast";
import { baseURL } from "@/api/config";
import type { TUserData } from "@/api/types";
import type { TGlobalPermissions } from "@/assets/js/globalTypes";

export const useSessionStore = defineStore("session", () => {
	const router = useRouter();
	const userToken = ref("");
	const userData = ref<TUserData | null>();
	const tokenRefreshTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
	const isBusyRenewalToken = ref(false);

	const isLoggedIn = computed(() => !!userToken.value);
	const isUserAdmin = computed(
		() =>
			// userData.value?.rol_name === "admin" ||
			// userData.value?.rol_name === "super-admin",
			true,
	);
	const isUserTemp = computed(() => userData.value?.roles[0] === "temp");
	const isUserClientAdmin = computed(
		() => userData.value?.roles[0] === "client-admin",
	);
	const userId = computed(() => userData.value?.sub ?? -1);
	const userPermissions = computed<string[]>(
		() => userData.value?.permissions || [],
	);
	const userImage = computed(() => {
		return getImageUrl(userData.value?.img_id);
	});
	function getImageUrl(imageId?: number | string): string | null {
		if (imageId) {
			return `${baseURL}/users/images-get/${imageId}`;
		}
		return null;
	}

	function saveToken(token: string, isRemember = true) {
		userToken.value = token;
		userData.value = token ? jwtDecode(token) : null;

		if (token) {
			if (isRemember) {
				setLocalStorageReac("user-token", token);
			} else {
				setSessionStorageReac("user-token", token);
			}
		} else {
			const sesToken = getSessionStorageReac("user-token");
			const locToken = getLocalStorageReac("user-token");
			sesToken.value = null;
			locToken.value = null;
		}
	}
	async function loadSessionToken() {
		const sesToken =
			(getSessionStorageReac("user-token").value as string) || "";
		const locToken = (getLocalStorageReac("user-token").value as string) || "";

		const loadSession = async (token: string) => {
			if (typeof token === "string") {
				saveToken(token);
				if (!tokenRefreshTimeout.value) {
					await setupTokenRenewal(true);
				}
			} else {
				console.error("Invalid token format", token);
			}
		};

		if (sesToken) {
			return loadSession(sesToken);
		} else if (locToken) {
			return loadSession(locToken);
		}
	}

	async function setupTokenRenewal(isRefreshToken = false, debug = true) {
		if (isBusyRenewalToken.value) {
			// console.warn(":: Busy token, exiting");
			return;
		}

		isBusyRenewalToken.value = true;
		tokenRefreshTimeout.value && clearTimeout(tokenRefreshTimeout.value);
		tokenRefreshTimeout.value = null;

		if (isRefreshToken) {
			await renewToken();
		}

		const TIME_TO_REFRESH = 24 * 3600 * 1000;
		if (debug) {
			console.log(
				`:: Setup token refresh - ${TIME_TO_REFRESH / 60 / 1000}min`,
				isRefreshToken,
			);
		}
		tokenRefreshTimeout.value = setTimeout(() => {
			void setupTokenRenewal(true);
		}, TIME_TO_REFRESH);
		isBusyRenewalToken.value = false;
	}
	function cancelRenewToken() {
		if (tokenRefreshTimeout.value) {
			clearTimeout(tokenRefreshTimeout.value);
			tokenRefreshTimeout.value = null;
		}
	}
	async function renewToken() {
		if (!userToken.value && tokenRefreshTimeout.value) {
			return;
		}

		try {
			const res = await api.authRenewSession();
			const token = res.data.token;
			console.log(":: Token refreshed");
			saveToken(token);
		} catch {
			// ignored
		}
	}
	type TLoginErrors = {
		password: string | string[] | null;
		email: string | string[] | null;
	};
	const loginErrors = ref<TLoginErrors>({
		password: null,
		email: null,
	});
	function clearLoginErrors(key: string): void {
		if (loginErrors.value[key]) loginErrors.value[key] = null;
	}
	async function setupLogin(
		payload: Parameters<typeof api.authLogin>[0],
		isRemember = false,
	) {
		try {
			const res = await api.authLogin(payload);
			saveToken(res.data.token, isRemember);
			return true;
		} catch (err: any) {
			console.warn(err.message);
			if (err?.response?.data?.message === "401 Unauthorized") {
				useToastStore().openToastError(
					"You have entered the incorrect username/password",
				);
			} else if (err?.response?.data?.errors) {
				loginErrors.value = err?.response?.data?.errors ?? {
					password: "Invalid password.",
					email: "Invalid email.",
				};
			} else {
				useToastStore().openToastError("Login failed");
			}
			return false;
		}
	}
	async function logoutApi(
		showMsg = false,
		shouldRedirect = true,
	): Promise<boolean> {
		// Cleanup calendar shift params
		delete localStorage.shiftRowDetailsOnce;
		delete localStorage.shiftRowDetailsOnceTimestamp;

		const checkRedirectUser = () => {
			if (shouldRedirect) {
				void router.replace("/auth/signin");
			}
		};

		if (!userToken.value) {
			console.warn(":: User already logged out");
			// useToastStore().openToastWarning("User already logged out");
			checkRedirectUser();
			return false;
		}
		try {
			await api.authLogout();
			cancelRenewToken();

			if (showMsg) {
				useToastStore().openToastInfo("User logged out");
			}

			checkRedirectUser();

			return true;
		} catch {
			return false;
		} finally {
			saveToken("", false);
		}
	}

	/**
	 * This function is used to check if the current user has specific permission
	 * TODO possible that furhter rework needed because this does not support a mix of | and &
	 */
	function can(permissions: string) {
		const or_string = permissions.split("|");
		if (or_string.length !== 1) {
			return or_string.some((key) => userPermissions.value.includes(key));
		}
		const and_string = permissions.split("&");
		return and_string.every((key) => userPermissions.value.includes(key));
	}

	const gPermissions = computed<TGlobalPermissions>(() => {
		const futureKeys = new Set([]);
		const keys = {
			canEditBreakTimes: "edit-break-times",
			canManageRates: "manage-rates",
			canViewDefaultShiftTimes: "view-default-shift-times",
			canCreateDefaultShiftTimes: "create-default-shift-time",
			canDeleteDefaultShiftTimes: "delete-default-shift-time",
			canSetAutorepush: "set-autorepush-interval",
			canClientProfileCreate: "create-client",
			canClientProfileEdit: "edit-client",
			canClientProfileView: "view-client", // Was "edit-client"
			canAddNewWard: "create-location",
			canEditWard: "edit-location",
			canCopyWard: "copy-location",
			canViewRulesCalcEng: "view-calc-engines",
			canEditRulesCalcEng: "edit-client-calc-engine",
			canAddContact: "create-contacts",
			canEditContact: "edit-contacts",
			canDeleteContact: "delete-contacts",
			canHolidayView: "view-holiday-requests",
			canAnnualLeaveView: "view-accumulation-transactions",
			canJournalViewTempJournal: "view-temp-journal",
			canJournalClientSave: "create-client-journal",
			canJournalTempSave: "create-temp-journal",
			canJournalClientEdit: "edit-client-journal",
			canJournalTempEdit: "edit-temp-journal",
			canJournalClientExport: "export-client-journal-report",
			canJournalTempExport: "export-temp-journal-report",
			canJournalClientViewPrivate: "view-private-client-journal",
			canJournalTempViewPrivate: "view-private-temp-journal",
			canJournalClientCreatePrivate: "create-private-client-journal",
			canJournalTempCreatePrivate: "create-private-temp-journal",
			canJournalClientViewSystem: "view-system-client-journal",
			canJournalTempViewSystem: "view-system-temp-journal",
			canRelativeView: "view-relativetypes",
			canCreateTemp: "create-temp",
			canEditTemp: "edit-temp",
			canViewTempDetails: "view-temp-details",
			canViewIncrementWeekCounter: "view-increment-weeks-counter",
			canEditIncrementWeekCounter: "edit-increment-weeks-counter",
			canEditTempIncrement: "edit-temp-increment",
			canDeleteTempIncrement: "delete-temp-increment",
			canAssignTempToShift: "assign-temp-to-shift",
			canArchiveLocation: "archive-location",
			canViewTempContractedHours: "view-temp-contracted-hours",
			canEditContractedHours: "edit-temp-contracted-hours",
			canEditShiftConfirmInterval: "manage-client-intervals",
			canUseContactEmails: "view-shift-confirmation-contacts",
			canEditTempExternalId: "edit-temp-external-id",
			canEditShiftPriceOverwriteFeatureToggle:
				"edit-can-use-shift-price-overwrite",
		};

		const entries = Object.entries(keys).map(([key, val]) => {
			if (futureKeys.has(key)) {
				return [key, true];
			}

			return [key, can(val)];
		});

		return Object.fromEntries(entries);
	});

	const gPermissionsStrings = {
		canEditBreakTimes: "edit-break-times",
		canManageRates: "manage-rates",
		canViewDefaultShiftTimes: "view-default-shift-times",
		canCreateDefaultShiftTimes: "create-default-shift-time",
		canDeleteDefaultShiftTimes: "delete-default-shift-time",
		canSetAutorepush: "set-autorepush-interval",
		canClientProfileCreate: "create-client",
		canClientProfileEdit: "edit-client",
		canClientProfileView: "view-client", // Was "edit-client"
		canAddNewWard: "create-location",
		canEditWard: "edit-location",
		canCopyWard: "copy-location",
		canViewRulesCalcEng: "view-calc-engines",
		canEditRulesCalcEng: "edit-client-calc-engine",
		canAddContact: "create-contacts",
		canEditContact: "edit-contacts",
		canDeleteContact: "delete-contacts",
		canHolidayView: "view-holiday-requests",
		canAnnualLeaveView: "view-accumulation-transactions",
		canJournalViewTempJournal: "view-temp-journal",
		canJournalClientSave: "create-client-journal",
		canJournalTempSave: "create-temp-journal",
		canJournalClientEdit: "edit-client-journal",
		canJournalTempEdit: "edit-temp-journal",
		canJournalClientExport: "export-client-journal-report",
		canJournalTempExport: "export-temp-journal-report",
		canJournalClientViewPrivate: "view-private-client-journal",
		canJournalTempViewPrivate: "view-private-temp-journal",
		canJournalClientCreatePrivate: "create-private-client-journal",
		canJournalTempCreatePrivate: "create-private-temp-journal",
		canJournalClientViewSystem: "view-system-client-journal",
		canJournalTempViewSystem: "view-system-temp-journal",
		canRelativeView: "view-relativetypes",
		canCreateTemp: "create-temp",
		canEditTemp: "edit-temp",
		canViewTempDetails: "view-temp-details",
		canViewIncrementWeekCounter: "view-increment-weeks-counter",
		canEditIncrementWeekCounter: "edit-increment-weeks-counter",
		canEditTempIncrement: "edit-temp-increment",
		canDeleteTempIncrement: "delete-temp-increment",
		canAssignTempToShift: "assign-temp-to-shift",
		canArchiveLocation: "archive-location",
		canViewTempContractedHours: "view-temp-contracted-hours",
		canEditContractedHours: "edit-temp-contracted-hours",
		canEditShiftConfirmInterval: "manage-client-intervals",
		canUseContactEmails: "view-shift-confirmation-contacts",
		canEditTempExternalId: "edit-temp-external-id",
		canEditShiftPriceOverwriteFeatureToggle:
			"edit-can-use-shift-price-overwrite",
	};

	return {
		isLoggedIn,
		isUserTemp,
		isUserClientAdmin,
		isUserAdmin,
		userId,
		userData,
		userImage,
		userPermissions,
		can,
		setupLogin,
		saveToken,
		loadSessionToken,
		setupTokenRenewal,
		logoutApi,
		getImageUrl,
		gPermissions,
		loginErrors,
		clearLoginErrors,
		gPermissionsStrings,
	};
});
