<script setup lang="ts">
// Updated 24-2

type TSpinnerBlock = {
	isFullPage?: boolean;
	background?: string;
	color1?: string;
	color2?: string;
	size?: string;
};

const props = withDefaults(defineProps<TSpinnerBlock>(), {
	isFullPage: true,
	background: "#000000ad",
	color1: "white",
	color2: "white",
	size: "80px",
});

const backgroundPosition = computed(() =>
	props.isFullPage ? "fixed" : "absolute",
);
</script>

<template>
	<div class="spinner-block">
		<div class="loader"></div>
	</div>
</template>

<style lang="scss" scoped>
// $color1: v-bind(color1);
// $color2: v-bind(color2);
$color1: color.adjust(
	$color: $app-accent-color1,
	$lightness: 15%,
);
$color2: $app-accent-color1;

.spinner-block {
	position: v-bind(backgroundPosition);
	background: v-bind(background);
	inset: 0;
	z-index: 200;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader {
	width: v-bind(size);
	height: v-bind(size);
	border: 3px solid $color1;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
.loader::after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: calc(v-bind(size) + 15%);
	height: calc(v-bind(size) + 15%);
	border-radius: 50%;
	border: 3px solid;
	border-color: $color2 transparent;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
