import { useSessionStore } from "@/store/session";
import { createRouter, createWebHistory } from "vue-router";

import { auth_routes } from "./routes/auth";
import { admin_routes } from "./routes/admin";
import { temp_routes } from "./routes/temps";
import { basic_routes } from "./routes/basic";
import { useKeepAliveStore } from "@/store/keepalive";
import { getBasePage } from "@/assets/js/helpers";

const router = createRouter({
	history: createWebHistory(),
	routes: [...basic_routes, ...temp_routes, ...admin_routes, ...auth_routes],
});

router.beforeEach((to, from) => {
	// Forbid going to auth pages if user is logged in
	const authPage = "auth.signin";
	const sessionStore = useSessionStore();
	const isLoggedIn = sessionStore.isLoggedIn;

	if (to.matched.some((match) => match.name === authPage) && isLoggedIn) {
		return getBasePage();
	}
	if (!isLoggedIn && !to.meta.requiresGuest) {
		console.log(to.meta.requiresGuest);
		return "auth/signin";
	}
	if (
		to.meta.permission &&
		typeof to.meta.permission === "string" &&
		!sessionStore.can(to.meta.permission)
	) {
		return to.meta?.fail ?? false;
	}

	if (to.meta["has-keep-alive"]) {
		const keepAliveStore = useKeepAliveStore();
		keepAliveStore.preNavigationSetup(to, from);
	}
	return true;
});

router.afterEach(async (to, from) => {
	if (from.meta["has-keep-alive"]) {
		const keepAliveStore = useKeepAliveStore();
		await nextTick();
		console.log(from.meta["has-keep-alive"]);
		keepAliveStore.postNavigationSetup(from, to);
	}
});

export default router;
