<script setup lang="ts">
// import { ref, watch, computed } from "vue";
// import { useStore } from "vuex";
import { usePasswordValidationStore } from "@/store/password";

// Props
const props = defineProps<{ passwordCharacters: string | null }>();

// Emit
const emit = defineEmits<{
	(
		e: "password-valid",
		param: { password: string; isPasswordValid: boolean },
	): void;
}>();

// // State and Store
// const store = useStore();
const passwordStore = usePasswordValidationStore();

// const passwordValidationStore = computed(
// 	() => store.state.passwordValidationStore,
// );

// Reactive Data
const allValidate = ref(false);

// Watcher for passwordCharacters prop
watch(
	() => props.passwordCharacters,
	(val) => {
		if (val) {
			validatePasswordCriteria(val);
			allValidate.value = true;
		} else {
			allValidate.value = false;
		}
	},
);

// Methods
async function validatePasswordCriteria(val: string) {
	// const res = await store.dispatch("passwordValidation", val);
	const res = await passwordStore.checkPasswordValidate(val);
	const param = {
		password: val,
		isPasswordValid: res.data.data.passwordIsValid,
	};
	emit("password-valid", param);
}
</script>

<template>
	<div class="password-policy-wrapper">
		<span class="validation-new-password">
			<p
				:class="{
					correct:
						passwordStore.passwordValidation.length.isValid && allValidate,
					inInputNotCorrect:
						!passwordStore.passwordValidation.length.isValid && allValidate,
				}"
			>
				8 chars&nbsp;
			</p>
			<p
				:class="{
					correct:
						passwordStore.passwordValidation.upperCase.isValid && allValidate,
					inInputNotCorrect:
						!passwordStore.passwordValidation.upperCase.isValid && allValidate,
				}"
			>
				&nbsp;Uppercase letter&nbsp;
			</p>
			<p
				:class="{
					correct:
						passwordStore.passwordValidation.lowerCase.isValid && allValidate,
					inInputNotCorrect:
						!passwordStore.passwordValidation.lowerCase.isValid && allValidate,
				}"
			>
				&nbsp;Lowercase letter&nbsp;
			</p>
			<p
				:class="{
					correct:
						passwordStore.passwordValidation.number.isValid && allValidate,
					inInputNotCorrect:
						!passwordStore.passwordValidation.number.isValid && allValidate,
				}"
			>
				&nbsp;Number&nbsp;
			</p>
			<p
				:class="{
					correct:
						passwordStore.passwordValidation.specialCharacter.isValid &&
						allValidate,
					inInputNotCorrect:
						!passwordStore.passwordValidation.specialCharacter.isValid &&
						allValidate,
				}"
			>
				&nbsp;Special char
			</p>
		</span>
	</div>
</template>

<style lang="scss">
.password-policy-wrapper {
	width: 100%;
	.validation-new-password {
		display: flex;
		justify-content: center;
		padding: 5px 0; // 5px 0 0
		gap: 1%;
		color: $control-light-grey;
		p {
			text-wrap: nowrap;
			white-space: nowrap;
			//  font-size: 0.9em;
		}
		.correct {
			color: $success-color;
			text-wrap: nowrap;
		}
		.inInputNotCorrect {
			color: $error-color;
		}
	}
}
</style>
