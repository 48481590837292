<template>
	<div
		class="switch-wrapper pa-1"
		:class="{ disabled: disabled }"
	>
		<label class="switch">
			<input
				:checked="modelValue"
				type="checkbox"
				:disabled="disabled"
				@change="emitChange($event)"
			/>
			<span class="slider round"></span>
		</label>
	</div>
</template>

<script setup lang="ts">
// Define props and emits

withDefaults(defineProps<{ modelValue: any | boolean; disabled?: boolean }>(), {
	disabled: false,
});
const emit = defineEmits(["update:modelValue", "change"]);

// Emit the change event
const emitChange = (e: Event) => {
	const target = e.target as HTMLInputElement;
	emit("update:modelValue", target.checked);
	emit("change", target.checked);
};
</script>

<style lang="scss" scoped>
$bacground-switch: #c4cdde;
$background-slider: #dee2e9;
$transition-duration: 0.3s;

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $background-slider;
	transition: 0.4s;
}

.slider::before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: -5px;
	bottom: -5px;
	background-color: $background-color;
	transition: $transition-duration;
	box-shadow: 2px 3px 6px -1px rgba(0, 0, 0, 0.25);
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

input:checked + .slider {
	// background-color:  $app-accent-color1;
	background: color.adjust($app-accent-color1, $lightness: 25%);
}

input:focus + .slider {
	box-shadow: none;
}

input:checked + .slider::before {
	transform: translateX(20px);
	background-color: $app-accent-color1;
}

.switch-wrapper:hover {
	.slider {
		background-color: $bacground-switch;
	}
	input:checked ~ .slider {
		// background-color:  $app-accent-color1;
	}
}
.switch {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 10px;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round::before {
	border-radius: 50%;
}
.switch-wrapper {
	// width: 40px;
	// height: 18px;
	background: transparent;
	transition-duration: $transition-duration;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;

		.slider {
			cursor: not-allowed;
			background-color: $bacground-switch;
		}
		input:checked ~ .slider {
			// background-color:  $app-accent-color1;
		}
	}
}
</style>
