<script setup lang="ts">
import type { TTempDocument } from "@/assets/js/globalTypes";

const emit = defineEmits(["close-modal", "confirm"]);
const props = withDefaults(
	defineProps<{
		doc: TTempDocument;
		confirmBtnTxt?: string;
		cancelBtnTxt?: string;
		showConfirmBtn?: boolean;
		showCancelBtn?: boolean;
		confirmBtnClass?: string;
		warning?: boolean;
		isError?: boolean;
	}>(),
	{
		confirmBtnTxt: "Confirm",
		cancelBtnTxt: "Cancel",
		showConfirmBtn: true,
		showCancelBtn: true,
		confirmBtnClass: "",
		warning: false,
		isError: false,
	},
);

function onClickConfirm() {
	emit("confirm");
	emit("close-modal");
}
function onClickReturn() {
	emit("close-modal");
}
</script>

<template>
	<div
		class="confirm-modal"
		:class="{ warning: warning }"
	>
		<div class="content">
			<div>
				<label
					class="label"
					style="align-self: flex-start; padding-left: 5px"
				>
					Expiry date
				</label>
				<modern-date-picker
					v-model="props.doc.expiryDate"
					:enable-time-picker="false"
					style="width: 250px"
					placeholder="Expiry Date"
					:format="'dd/MM/yyyy'"
					:canClear="false"
					:canDeselect="false"
					auto-apply
				/>
			</div>
			<div class="wrapp-buttons">
				<button
					v-if="showCancelBtn"
					class="alt"
					@click="onClickReturn()"
				>
					{{ cancelBtnTxt }}
				</button>
				<button
					v-if="showConfirmBtn"
					class="btn"
					:class="confirmBtnClass"
					@click="onClickConfirm()"
				>
					{{ confirmBtnTxt }}
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	min-height: 130px;
	gap: 10px;
	color: $text-color;

	.content {
		margin-top: 25px;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		.warningTxt {
			font-size: 2em;
		}
		.message {
			text-align: center;
			font-size: 1rem;
			// color: $success-color;
			transition: 2s;
		}
		.isError {
			color: $error-color;
		}
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		// flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
}
.confirm-modal.warning {
	max-width: 400px;
}
</style>
