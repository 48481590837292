<script setup lang="ts">
import { getBasePage, validateEmail } from "@/assets/js/helpers";
import { useDefaultStore } from "@/store";

import { useSessionStore } from "@/store/session";
const store = useDefaultStore();

const email = ref<string>();
const password = ref<string>();
const router = useRouter();
const sessionStore = useSessionStore();

const invalid_email = ref(false);
const show_general_error = ref(false);

function checkEmail() {
	if (!email.value) {
		invalid_email.value = false;
		return;
	}
	invalid_email.value = !validateEmail(email.value);
}
const errorsMap = computed(() => sessionStore.loginErrors);
async function setupLogin() {
	try {
		const res = await sessionStore.setupLogin(
			{ email: email.value, password: password.value },
			true,
		);
		if (res) {
			router.push(getBasePage());
		} else {
			show_general_error.value = true;
		}
	} catch {
		// nothing to do here, setupLogin doesnt crash
	}
}
// watch(email, () => {
// 	if (show_general_error.value) {
// 		show_general_error.value = false;
// 	}
// });
</script>

<template>
	<div class="signin">
		<div class="signin-container">
			<header>
				<div class="image-wrap">
					<img
						:src="store.getGlobalImage()"
						alt=""
					/>
				</div>
				<p>Please login below.</p>
			</header>
			<hr />
			<div>
				<section class="section-input">
					<div class="control">
						<input
							v-model="email"
							class="large"
							:class="{ 'err-b': errorsMap.email }"
							type="email"
							name="email"
							placeholder="Email"
							autocomplete="off"
							@blur="checkEmail"
							@focus="sessionStore.clearLoginErrors('email')"
						/>
						<transition name="fade">
							<p
								v-if="errorsMap?.email"
								class="error-text"
							>
								{{ errorsMap.email[0] }}
							</p>
						</transition>
					</div>
					<div class="control">
						<input
							v-model="password"
							class="large"
							:class="{ 'err-b': errorsMap.password }"
							type="password"
							name="password"
							placeholder="Password"
							autocomplete="password"
							@focus="sessionStore.clearLoginErrors('password')"
							@keyup.enter="setupLogin"
						/>
						<transition name="fade">
							<p
								v-if="errorsMap?.password"
								class="error-text"
							>
								{{ errorsMap.password[0] }}
							</p>
						</transition>
					</div>
					<div class="right">
						<router-link :to="{ name: 'auth.forgot-password' }">
							Forgot Your Password?
						</router-link>
					</div>
					<button
						class="large"
						@click="setupLogin"
					>
						LOGIN
					</button>
				</section>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.signin {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60%;
}
.signin-container {
	$signin-color: #536278;

	box-shadow: 0 30px 33px 2px hsla(0deg, 0%, 67%, 0.3);
	background-color: $white;
	width: 310px;
	margin: 5em auto;
	padding: 12px 0 29px;
	border-radius: 5px;
	color: $signin-color;
	header {
		text-align: center;
		padding: 1rem;
	}
	.image-wrap {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 7px;
	}
	.section-input {
		padding: 24px 29px 0;
		button {
			font-size: 1rem;
		}
	}
	.large {
		width: 100%;
	}
	.right {
		text-align: right;
		color: $green;
		margin-bottom: 4px;
		&:hover {
			color: $black;
		}
	}
	.control {
		margin-bottom: 0.75rem;
	}
	.error {
		color: $red;
	}
}

@include fade-transition;
</style>
