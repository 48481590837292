<script setup lang="ts">
import api from "@/api";
import type { TExternalTempInfo } from "@/assets/js/globalTypes";
import { GENDER_MAP_DROPDOWN_ARRAY } from "@/assets/js/helpers";
import { parseErrors } from "@/assets/js/parseErrors";
import { useClientManagementStore } from "@/store/locationClientManagement";
import { useToastStore } from "@/store/toast";

const props = defineProps<{
	tempId: number;
	tempInfo: Partial<TExternalTempInfo>;
}>();
const emit = defineEmits<{
	"close-modal": [void];
	confirm: [void];
}>();

const confirmText =
	'Would you like the system to generate a new password and send the welcome email ? If "no" is selected details will be updated, but the password will not be reset and no email wiil be sent.';

const clientManagementStore = useClientManagementStore();

const getEirCodeLabelKey = computed(() =>
	clientManagementStore.getColumnNameLocl("usr_eircode"),
);

const modalInModalOpen = ref(false);
function callPasswordConfirmation() {
	modalInModalOpen.value = true;
}

const startDate = ref<string | null>(null);
watch(
	props.tempInfo,
	(newVal) => {
		if (newVal.contract_start_date) {
			startDate.value = newVal.contract_start_date;
		}
	},
	{ deep: true, immediate: true },
);

const toastStore = useToastStore();

const buttonDisabled = ref(false);
async function rehireTemp(sendPass: boolean) {
	modalInModalOpen.value = false;
	buttonDisabled.value = true;
	const params = {
		tempId: props.tempId,
		autogenerate_password: sendPass,
		contract_start_date: startDate.value,
		// include:
		//   "locations,region,subcategories,specialities,specialities.temps,clients.incrementRates,categories.increments,increments,wtd,preferredCounties",
	};
	try {
		await api.confirmRehire(params);
		emit("confirm");
		emit("close-modal");
	} catch (error) {
		const errs = parseErrors(error);
		if (errs) {
			toastStore.openToastError(errs);
		}
		emit("close-modal");
	} finally {
		buttonDisabled.value = false;
	}
}
</script>
<template>
	<div id="rehire-candidate-modal">
		<div>
			<div
				v-if="tempInfo.first_name"
				class="item"
			>
				<label>First Name:</label>
				<input
					v-model="tempInfo.first_name"
					disabled
					placeholder="First Name"
					type="text"
				/>
			</div>
			<div
				v-if="tempInfo.last_name"
				class="item"
			>
				<label>Last Name:</label>
				<input
					v-model="tempInfo.last_name"
					disabled
					placeholder="Last Name"
					type="text"
				/>
			</div>

			<div
				v-if="tempInfo.nickname"
				class="item"
			>
				<label>Known as:</label>
				<input
					v-model="tempInfo.nickname"
					disabled
					placeholder="Known as"
					type="text"
				/>
			</div>

			<div
				v-if="tempInfo.date_of_birth"
				class="item"
			>
				<label>Date of birth:</label>
				<modern-date-picker
					:model-value="tempInfo.date_of_birth"
					disabled
					placeholder="Date of birth"
					:enable-time-picker="false"
				/>
			</div>
			<div
				v-if="tempInfo.home_address"
				class="item"
			>
				<label>Home Address Line 1:</label>
				<input
					v-model="tempInfo.home_address"
					disabled
					placeholder="Home Address Line 1"
					type="text"
				/>
			</div>
			<div
				v-if="tempInfo.home_address_2"
				class="item"
			>
				<label>Home Address Line 2:</label>
				<input
					v-model="tempInfo.home_address_2"
					disabled
					placeholder="Home Address Line 2"
					type="text"
				/>
			</div>
			<div
				v-if="tempInfo.home_address_3"
				class="item"
			>
				<label>Home Address Line 3:</label>
				<input
					v-model="tempInfo.home_address_3"
					disabled
					placeholder="Home Address Line 3"
					type="text"
				/>
			</div>

			<div
				v-if="tempInfo.mobile_phone"
				class="item"
			>
				<label>Mobile:</label>
				<input
					v-model="tempInfo.mobile_phone"
					disabled
					placeholder="Mobile phone"
					type="text"
				/>
			</div>
			<div
				v-if="tempInfo.home_phone"
				class="item"
			>
				<label>Phone:</label>
				<input
					v-model="tempInfo.home_phone"
					disabled
					placeholder="Home phone"
					type="text"
				/>
			</div>
			<div
				v-if="tempInfo.email"
				class="item"
			>
				<label>Email:</label>
				<input
					v-model="tempInfo.email"
					type="text"
					placeholder="Email"
					disabled
				/>
			</div>
			<div
				v-if="tempInfo.gender"
				class="item"
			>
				<label>Gender:</label>
				<multiselect-form
					v-model="tempInfo.gender"
					searchable
					:preselectFirst="false"
					:mode="'single'"
					:options="GENDER_MAP_DROPDOWN_ARRAY"
					disabled
					placeholder="Select gender"
				></multiselect-form>
			</div>

			<div class="item">
				<label>Start Date:</label>
				<modern-date-picker
					v-model="startDate"
					:enable-time-picker="false"
					placeholder="Start Date"
					format="dd/MM/yyyy"
					type="text"
				/>
			</div>
			<div
				v-if="tempInfo.eircode"
				class="item"
			>
				<label>{{ getEirCodeLabelKey }}:</label>
				<input
					v-model="tempInfo.eircode"
					type="text"
					:placeholder="getEirCodeLabelKey"
					disabled
				/>
			</div>
			<div
				v-if="tempInfo.iban"
				class="item"
			>
				<label>Iban:</label>
				<input
					v-model="tempInfo.iban"
					type="text"
					disabled
				/>
			</div>
			<div
				v-if="tempInfo.bic"
				class="item"
			>
				<label>Bic:</label>
				<input
					v-model="tempInfo.bic"
					type="text"
					disabled
				/>
			</div>
			<div
				v-if="tempInfo.nationality_name"
				class="item"
			>
				<label>Nationality:</label>
				<input
					v-model="tempInfo.nationality_name"
					type="text"
					disabled
				/>
			</div>

			<div
				v-if="tempInfo.relatives && tempInfo.relatives.length"
				class="item"
			>
				<label>Next of Kin Name:</label>
				<input
					v-model="tempInfo.relatives[0].name"
					type="text"
					disabled
				/>
			</div>
			<div
				v-if="tempInfo.relatives && tempInfo.relatives.length"
				class="item"
			>
				<label>Next of Kin Contact Number:</label>
				<input
					v-model="tempInfo.relatives[0].phone"
					type="text"
					disabled
				/>
			</div>
			<div
				v-if="tempInfo.relatives && tempInfo.relatives.length"
				class="item"
			>
				<label>Next of Kin Relationship:</label>
				<input
					v-model="tempInfo.relatives[0].relativetype_name"
					type="text"
					disabled
				/>
			</div>

			<div class="save-button">
				<button
					class="button is-primary"
					:disabled="buttonDisabled"
					@click="callPasswordConfirmation"
				>
					<template v-if="buttonDisabled">
						<div class="loading-wrapper">
							<img src="@/assets/image/loader.svg" />
						</div>
					</template>
					<template v-else>CONFIRM</template>
				</button>
			</div>
			<modal-in-modal
				:is-modal-open="modalInModalOpen"
				:confirm-message="confirmText"
				:initial-modal-in-modal-open="false"
				cancel-button-text="No"
				confirm-button-text="Yes"
				@confirm-action="rehireTemp(true)"
				@cancel-action="rehireTemp(false)"
			></modal-in-modal>
		</div>
	</div>
</template>

<style scoped lang="scss">
#rehire-candidate-modal {
	padding: 0 20px;
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 800px;
	max-width: 90vh;
	max-height: 85vh;
	&.visible {
		overflow: visible;
	}
	label {
		display: block;
		color: $label-accent-color;
		font-size: 0.75rem;
		margin-bottom: 5px;
	}
	.item {
		input {
			width: 100%;
		}
		&.flex {
			display: flex;
			align-items: center;
		}
		&.grow {
			flex-grow: 1;
		}
		.with-icon {
			display: flex;
			gap: 5px;
			align-items: center;
			input {
				flex-grow: 1;
			}
		}
		.f {
			gap: 5px;
		}
	}
	.buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom: 10px;
	}
	.item-group {
		display: flex;
		gap: 10px;
		&.small {
			width: fit-content;
		}
		&.switches {
			justify-content: space-between;
		}
	}
	.save-button {
		width: 100%;
		margin: 20px 0;
		button {
			width: 100%;
		}
	}
	.shift-data {
		margin-top: 20px;
	}
	.danger-text {
		color: $red;
		text-align: center;
		margin-bottom: 10px;
		font-weight: 500;
		max-width: 360px;
	}
	:deep() {
		.highlight-button {
			width: unset;
			.highlight-button-text {
				padding: 5px 10px;
			}
		}
	}
}
</style>
