<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		text?: string;
		number?: string | number;
	}>(),
	{ text: "", number: undefined },
);

defineSlots<{
	text(props: { text: string }): any;
	number(props: { number: string | number }): any;
}>();
</script>
<template>
	<div class="highlight-button-wrap">
		<button class="highlight-button native-button">
			<div class="highlight-button-text">
				<slot
					name="text"
					:text="props.text"
				>
					{{ text }}
				</slot>
			</div>
			<div
				v-if="number"
				class="highlight-button-number"
			>
				<slot
					name="number"
					:number="props.number"
				>
					{{ number }}
				</slot>
			</div>
		</button>
	</div>
</template>
<style lang="scss" scoped>
$highlight-button-background: #4da3ff99;
$highlight-button-background-hover: #64c4ffb3;
.highlight-button {
	border-radius: 4px;
	background-color: $highlight-button-background;
	min-width: 185px;
	color: $blue-grey;
	font-size: 12px;
	font-weight: 700;
	display: inline-flex;
	justify-content: space-between;
	padding: 0;
	transition: background-color 0.2s;
	&:hover {
		background-color: $highlight-button-background-hover;
	}
	.highlight-button-text {
		padding: 5px 5px 5px 10px;
	}
	.highlight-button-number {
		background-color: $white;
		color: $black;
		padding: 5px 10px;
		display: inline-block;
		border: 1px solid $highlight-button-background;
		// max-width: 35%;
		min-width: 25%;
	}
}
</style>
