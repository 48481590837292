import dayjs from "dayjs";

export const STATUS = {
	PENDING: 1,
	ACTIVE: 2,
	COMPLETED: 3,
	NEVER_FILLED: 4,
	CANCELLED: 5,
};

export const REPUSH_STATUS = [
	{ status: 1, label: "Awaiting response", hex: "#93D98E" },
	{ status: 2, label: "Refused", hex: "#DF7F7F" },
	{ status: 3, label: "Newly available", hex: "#88E1FF" },
];

export const VISIBLE_COLUMNS = {
	selectAll: true,
	shiftType: true,
	status: true,
	name: true,
	categories: true,
	date: true,
	time: true,
	client: true,
	location: true,
	createdBy: true,
	shiftID: true,
	action: true,
	view: true,
};

export const SIGN_OFF_IN_ARBITRATION = 1;

export const VISIBLE_TOOL_ICONS = {
	tempUnassignIcon: false,
	tempEditingIcon: false,
	timeEditingIcon: false,
};

export const LOG_TYPES = {
	CREATED: { type: 1 },
	UPDATED: { type: 2 },
	"CONFIRMED BY CLIENT": { type: 3 },
	"CONFIRMED BY TEMP": { type: 4 },
	"REJECTED UPDATE": { type: 5 },
	ACCEPTED: { type: 6 },
	REFUSED: { type: 7 },
	CANCELLED: { type: 8 },
	"CONFIRMED CANCELLATION": { type: 9 },
	"SIGNED OFF BY TEMP": { type: 10 },
	"SIGNED OFF BY ADMIN": { type: 11 },
	"CONFIRMED SIGN OFF": { type: 12 },
	"VEND OFF SENT": { types: 13 },
	"VEND OFF CONFIRMED": { type: 14 },
	PUSHED: { type: 15 },
	REMOVED_FROM_SHIFT: { type: 16 },
	REASSIGNED: { type: 17 },
	ASSIGNED: { type: 18 },
	"TOGGLE SIGN OFF": { type: 19 },
	ARBITRATED: { type: 20 },
	"REMINDER SENT": { type: 21 },
	RECALCULATED: { type: 22 },
};

/* eslint no-template-curly-in-string: "off" */
export const LOG_TYPE_TEMPLATE_MSGS = function (ctx) {
	// Unused
	const prevStartTimeText =
		(ctx.previousShiftState.startTime &&
			" from " +
				dayjs(ctx.previousShiftState.startTime).format(
					"DD-MM-YYYY HH:mm:ss",
				)) ||
		"";
	const prevEndTimeText =
		(ctx.previousShiftState.endTime &&
			" from " +
				dayjs(ctx.previousShiftState.endTime).format("DD-MM-YYYY HH:mm:ss")) ||
		"";
	const currentStartTime =
		(ctx.currentShiftState.startTime &&
			dayjs(ctx.currentShiftState.startTime).format("DD-MM-YYYY HH:mm:ss")) ||
		"";
	const currentEndTime =
		(ctx.currentShiftState.endTime &&
			dayjs(ctx.currentShiftState.endTime).format("DD-MM-YYYY HH:mm:ss")) ||
		"";

	return {
		startTime:
			"Start time changed" + prevStartTimeText + " to " + currentStartTime,
		endTime: "End time changed" + prevEndTimeText + " to " + currentEndTime,
		cancelledBy:
			"Shift is cancelled by " +
			(ctx.user && ctx.user.name ? ctx.user.name : ""),
		// 'temp': 'Temp changed from ${ctx.previousShiftState.temp.name} to ${ctx.currentShiftState.temp.name}'
	};
};
