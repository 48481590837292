<script setup lang="ts">
import type { TTempDocument } from "@/assets/js/globalTypes";
import type { CancelTokenSource } from "axios";
import axiosAlias from "axios";
import { axios } from "@/api/config.js";
import { parseErrorKeys, parseErrors } from "@/assets/js/parseErrors";
import { generateUniqueId, truncateString } from "@/assets/js/helpers";
import { useTempDocumentsComplianceStore } from "@/store/tempDocumentsComplianceStore";

type TFileData = {
	id: string;
	name: string;
	file: File;
	uploadProgress: number;
	progressColor: string;
	isComplete: boolean;
	isError: boolean;
	isCancelled: boolean;
	cancelTokenSource: CancelTokenSource;
};
const props = defineProps<{
	doc: TTempDocument;
	isUserTemp?: boolean;
}>();

const tempComplianceStore = useTempDocumentsComplianceStore();
const allIsUploaded = ref<boolean>(false);
const files = ref<TFileData[] | null>([]);
const uploadFiles = async (): Promise<void> => {
	for (const file of files.value) {
		if (file?.isCancelled || file?.isComplete) continue;
		const formData = new FormData();
		formData.append("files[]", file.file);
		if (expiryDate.value) formData.append("expiry_date", expiryDate.value);
		const url = props.isUserTemp
			? `/documenttypes/${props.doc.id}/upload-files`
			: `/documents/${props.doc.id}/temps/${props.doc.temp.id}/upload-files`;

		try {
			await axios.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				cancelToken: file.cancelTokenSource
					? file.cancelTokenSource.token
					: null,
				onUploadProgress: (progressEvent) => {
					if (progressEvent.total) {
						const currentProgress = Math.round(
							(progressEvent.loaded / progressEvent.total) * 100,
						);
						// Prevent progress from going backward
						if (currentProgress >= file.uploadProgress) {
							file.uploadProgress = currentProgress;
						}
					}
				},
			});
			file.progressColor = "#23d160";
			file.isComplete = true;
		} catch (error) {
			const errors = parseErrors(error);
			const errorsKeys = parseErrorKeys(error);
			if (errorsKeys && errorsKeys.length > 0) {
				for (const [index, errKey] of errorsKeys.entries()) {
					if (errKey in errorsMap.value) {
						errorsMap.value[errKey] = Array.isArray(errors)
							? errors[index]
							: errors;
					}
				}
			}
			if (axiosAlias.isCancel(error)) {
				console.log(`Upload canceled for ${file.name}:`, error.message);
				file.progressColor = "#ECA0A8";
			} else {
				console.error(`Upload failed for ${file.name}:`, error);
				file.progressColor = "#ECA0A8";
			}
			console.error(`Upload failed for ${file.name}:`, error);
			file.uploadProgress = 100;
			file.isComplete = true;
			file.isError = true;
		}
		file.uploadProgress = 100;
	}
	allIsUploaded.value = true;
	await tempComplianceStore.fetchTempDocuments();
};

const emit = defineEmits(["close-modal", "confirm"]);

function onRemoveAll() {
	files.value = [];
}
function addFiles(evt) {
	if (!evt?.hasFiles) return;
	allIsUploaded.value = false;
	for (const file of evt.files) {
		files.value.push({
			// id: crypto.randomUUID(),
			id: generateUniqueId(),
			name: file.name,
			file: file,
			uploadProgress: 0,
			progressColor: "#23d160",
			isComplete: false,
			isError: false,
			isCancelled: false,
			cancelTokenSource: axiosAlias.CancelToken.source(),
		});
	}
}
function removeFile(evt) {
	if (Array.isArray(files.value)) {
		files.value.splice(evt.index, 1);
	}
}
function onRemoveFile(index: number) {
	if (Array.isArray(files.value)) {
		files.value.splice(index, 1);
	}
}
function cancelAllUploads() {
	for (const file of files.value) {
		if (!file || file.uploadProgress > 0 || file.isCancelled || file.isComplete)
			continue;
		if (file.cancelTokenSource) {
			file.cancelTokenSource.cancel("Upload canceled by the user.");
			file.isCancelled = true;
			file.progressColor = "#ECA0A8";
		}
	}
}
const hasDocExpiration = computed(() => props.doc.expiration_months > 0);
const expiryDate = ref<string | null>(
	hasDocExpiration ? props.doc.expiryDate : null,
);
function setExpiryDate(expDate: string): void {
	errorsMap.value.expiry_date = null;
	expiryDate.value = expDate;
}
const errorsMap = ref<{
	files: string | null;
	expiry_date: string | null;
}>({
	files: null,
	expiry_date: null,
});
function getFileStatusText(file: TFileData): string {
	if (file?.isCancelled) return "Cancelled";
	else if (file?.isError) return "Failed";
	else if (!file?.isCancelled && file?.isComplete) return "Completed";
	return "";
}
</script>

<template>
	<div class="confirm-modal">
		<div class="content">
			<div
				style="
					width: 500px;
					height: 200px;
					display: flex;
					border: 1px dashed black;
					outline: none;
				"
			>
				<file-dropzone
					:hideFileItems="true"
					@add="addFiles"
					@remove="removeFile"
				/>
			</div>
			<div v-if="files">
				<div
					v-for="(file, index) in files"
					:key="file.id"
					class="file-upload flex-center flex-space-between b mgb-5 b-r-5"
					style="width: 500px"
				>
					<div
						class="wd-100-p pd-5"
						style="position: relative"
						:title="file.name"
					>
						<div class="progress-bar">
							<!-- Dynamic background and width for progress -->
							<div
								class="progress"
								:style="{
									width: `${file.isCancelled ? 100 : file.uploadProgress}%`,
									backgroundColor: file.progressColor,
								}"
							></div>
						</div>
						<div
							class="file-info flex-space-between wd-100-p"
							:title="file.name"
						>
							{{ truncateString(file.name, 57) }}
							<span>
								{{
									getFileStatusText(file) === ""
										? `${file?.uploadProgress ?? 0}%`
										: getFileStatusText(file)
								}}
							</span>
						</div>
					</div>
					<div
						class="flex-center"
						style="padding: 5px 7px"
					>
						<i-fa-xmark
							class="del pointer opacity-07-h scale-1-3-h"
							@click.prevent="onRemoveFile(index)"
						></i-fa-xmark>
					</div>
				</div>
			</div>
			<div v-if="hasDocExpiration ? true : false">
				<div class="flex-space-between f-align-items-center">
					<span>Expiry date:</span>
					<modern-date-picker
						v-model="doc.expiryDate"
						:enable-time-picker="false"
						style="width: 410px; margin-left: 5px"
						placeholder="Expiry Date"
						:format="'dd/MM/yyyy'"
						:canClear="false"
						:canDeselect="false"
						:clearable="false"
						auto-apply
						@update:model-value="setExpiryDate"
					/>
				</div>
				<div
					v-if="errorsMap.expiry_date"
					class="error-text"
				>
					{{ errorsMap.expiry_date }}
				</div>
			</div>
			<div class="wrapp-buttons">
				<button
					:disabled="!files || files.length === 0 || allIsUploaded"
					:class="{
						'not-allowed': !files || files.length === 0 || allIsUploaded,
					}"
					@click.prevent="uploadFiles"
				>
					Upload all
				</button>
				<button
					:disabled="!files || files.length === 0 || allIsUploaded"
					:class="{
						'not-allowed': !files || files.length === 0 || allIsUploaded,
					}"
					@click.prevent="cancelAllUploads"
				>
					Cancel all
				</button>
				<button
					:disabled="!files || files.length === 0"
					:class="{
						'not-allowed': !files || files.length === 0,
					}"
					class="danger"
					@click.prevent="onRemoveAll"
				>
					Remove all
				</button>
			</div>
			<div class="b-t wd-100-p flex-center pd-20 mgt-10">
				<button
					class="alt"
					@click.prevent="emit('close-modal')"
				>
					Close
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 700px;
	min-height: 130px;
	max-height: 550px;
	gap: 10px;
	color: $text-color;
	overflow: auto;

	.content {
		margin-top: 25px;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		.warningTxt {
			font-size: 2em;
		}
		.message {
			text-align: center;
			font-size: 1rem;
			// color: $success-color;
			transition: 2s;
		}
		.isError {
			color: $error-color;
		}
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		// flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
}
.confirm-modal.warning {
	max-width: 400px;
}
.progress-bar {
	width: 100%;
	height: 100%;
	background-color: $grey-light;
	opacity: 0.2;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}

.progress {
	height: 100%;
	transition:
		width 0.2s ease,
		background-color 0.2s ease;
}
.file-upload {
	position: relative;
}
</style>
