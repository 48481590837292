<script setup lang="ts">
import type { TTableColumn } from "../../table/ModernTable.vue";
const props = defineProps<{ table: any }>();
// console.log(props.table, "props---");
// computed
const headers = computed(() => props.table.table_header);
const rows = computed(() => props.table.rows);
const keys = computed(() => props.table.keys);

// const columns = computed(() => {
// 	return keys.value.map((key) => {
// 		return {
// 			id: headers.value[key],
// 			label: headers.value[key],
// 		};
// 	});
// });

const columns = ref<TTableColumn[]>([]);
function setColumns() {
	columns.value = keys.value.map((key) => {
		return {
			id: key,
			label: headers.value[key],
		};
	});
}

function hasAllKeys(object, keys) {
	const len = keys.length;
	for (let i = 0; i < len; i++) {
		if (!Object.prototype.hasOwnProperty.call(object, keys[i])) {
			return false;
		}
	}
	return true;
}
onMounted(() => {
	setColumns();
});
</script>
<template>
	<div class="report-preview-table">
		<!-- <modern-table
			:columns="columns"
			:rows="rows"
			:has-border="false"
			:display-additional-row="false"
			:isInfiniteScroll="false"
		> -->
		<!-- <template #table-body-cell="slotProps">
				<td>
					{{ slotProps.row[slotProps.column.id] }}
				</td>
			</template> -->
		<!-- </modern-table> -->
		<table class="table">
			<thead>
				<tr>
					<th
						v-for="key in keys"
						:key="key"
					>
						{{ headers[key] }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(row, index) in rows"
					:key="index"
				>
					<template v-if="hasAllKeys(row, keys)">
						<td
							v-for="key in keys"
							:key="key"
						>
							{{ row[key] }}
						</td>
					</template>
					<template v-if="!hasAllKeys(row, keys)">
						<td
							v-for="item in row"
							:key="item"
						>
							{{ item }}
						</td>
					</template>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<style lang="scss" scoped>
.report-preview-table {
	$text-color-lighter: $label-color;
	$scrollbar-color: $scrollbar-color;
	$scrollbar-color-thumb: $scrollbar-color-thumb;
	$table-header-background: #f2f7fa;
	$tr-hover-background: #f5f5f5;
	$td-text-color: #405168;

	--row-back: white;
	--row-back-odd: var(--vt-c-white-soft);

	gap: 10px;
	width: 100%;
	display: flex;
	overflow: auto;

	.panel-block {
		margin: 0;
		padding: 0;
	}

	.modal-content {
		// width: 80%;
		// margin: 0 auto;
		padding: 0;
	}
	// .table {
	// 	margin-top: 20px;
	// 	width: 100%;
	// 	// width: fit-content;
	// }

	.table {
		margin-top: 20px;
		// overflow: auto;
		width: 100% !important;
		border-collapse: collapse;
		border: 1px solid #dde6eb;

		td {
			font-size: 0.9rem;
			padding: 7px 10px;
			border-top: 1px solid #dde6eb;
			color: $td-text-color;
			&:not(.extra-row) {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 300px;
			}
		}

		thead {
			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}
		}

		tbody {
			tr {
				height: 40px;
				&:hover {
					background: $tr-hover-background;
				}
			}

			&:not(&.has-extra-row) {
				tr {
					&:nth-child(2n + 1) {
						background-color: var(--row-back-odd);
					}
					&:hover {
						background: $tr-hover-background;
					}
				}
			}
		}

		&.border {
			border: 1px solid gray;

			th,
			td {
				border: 1px solid gray;
			}
		}

		&.sticky {
			th {
				position: sticky;
				top: 0;
			}
		}
	}
}
</style>
