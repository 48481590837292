<script setup lang="ts">
const emit = defineEmits(["close-modal"]);

const props = withDefaults(
	defineProps<{
		content?: string | string[];
	}>(),
	{
		content: "",
	},
);
function closeModal() {
	emit("close-modal");
}
</script>

<template>
	<div
		v-if="props.content"
		class="error-modal-wrap"
	>
		<div class="content">
			<div v-if="Array.isArray(props.content)">
				<p
					v-for="(msg, idx) in props.content"
					:key="idx"
					class="error-text"
				>
					{{ msg }}
					<br />
				</p>
			</div>
			<p
				v-if="typeof props.content === 'string'"
				class="error-text"
			>
				{{ props.content }}
			</p>
		</div>
		<div class="actions">
			<button
				class="button is-generic-app-blue"
				@click.prevent="closeModal"
			>
				Okay
			</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
.error-modal-wrap {
	padding: 9px;
	.content {
		font-size: 1.05em;
		// text-align: center;
		display: flex;
		justify-content: center;
		min-width: 400px;
		max-width: 800px;
	}
	.actions {
		margin-top: 20px;
		display: flex;
		justify-content: center;
		button {
			margin: 5px;
		}
	}
}
</style>
