<script lang="ts" setup>
import { parseErrors } from "@/assets/js/parseErrors";
import { useShiftsStore } from "@/store/shifts";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
const shiftsStore = useShiftsStore();
const props = defineProps<{
	modalProps: any;
}>();
const emit = defineEmits(["close-modal"]);

const beforeMessages = ref<any>({});
const messageNumber = ref<number>(0);
const disableButton = ref<boolean>(false);
function closeModal() {
	emit("close-modal");
}
function changeMessage() {
	messageNumber.value++;
}
async function recalc() {
	console.log("before recalc");

	disableButton.value = true;
	const params = {
		...props.modalProps.params,
	};

	try {
		const res = await shiftsStore.clientRecalculateShift(
			params.url,
			params.data,
		);
		if (res) toastStore.openToastSuccess("Shift recalculated.");
		closeModal();
		disableButton.value = false;
	} catch (err) {
		const errors = parseErrors(err);
		if (Array.isArray(errors) && errors.length > 0) {
			errors.map((e) => {
				toastStore.openToastError(e);
			});
		} else if (errors) {
			toastStore.openToastError(errors);
		}
		closeModal();
		disableButton.value = false;
	}
}

onMounted(() => {
	beforeMessages.value = props.modalProps.beforeApiData.checkRecalculateData;
});
</script>
<template>
	<div class="before-wrapper">
		<div>
			<img
				src="@/assets/image/warning.svg"
				style="width: 110px"
				alt=""
			/>
			<div class="warningTxt">Warning</div>
		</div>
		<div class="message mgb-20">
			{{ Object.values(beforeMessages)[messageNumber] }}
		</div>
		<div
			v-if="messageNumber == 0"
			class="button"
		>
			<button
				class="button is-generic-app-blue"
				:disabled="disableButton"
				@click="recalc"
			>
				Yes
			</button>
			<button
				class="alt mgl-5 button is-generic-app-blue"
				@click.prevent="changeMessage"
			>
				No
			</button>
		</div>
		<div
			v-if="messageNumber == 1"
			class="button"
		>
			<button
				class="button is-generic-app-blue"
				@click.prevent="closeModal"
			>
				OK
			</button>
		</div>
	</div>
</template>

<style scoped lang="scss">
.before-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px 20px;
	width: 550px;
	.message {
		background-color: $white;
		text-align: center;
	}
}
.warningTxt {
	font-size: 2em;
}
</style>
