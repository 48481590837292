<script setup lang="ts">
import { ref, computed } from "vue";
// import { useStore } from "vuex";

// const store = useStore();

const props = defineProps<{
	user: any;
}>();

// const emit = defineEmits(["deactivate", "activate", "cancel-modal"]);
const emit = defineEmits(["close-modal", "confirm"]);

// Reactive state
const isLoading = ref(false);
const activeStatus = ref<number | null>(null);
const lastStatusSelected = ref<number | null>(null);
const isDeleteDialogActive = ref(false);
const statusElements = ref<Record<string, any>>([
	{
		id: 1,
		value: true,
		label: "Active",
		backColor: "#23d160",
	},
	{
		id: 3,
		value: false,
		label: "Inactive",
		backColor: "#ff2b56",
	},
]);

// Computed properties
const filteredStatuses = computed(() => {
	return statusElements.value.filter(() => true);
	// Modify logic here as needed
});

const spinner = computed(() => {
	return (button: number | null) => ({
		"is-loading": isLoading.value && button === activeStatus.value,
	});
});

// Methods
const callActionButton = (statusId: number) => {
	lastStatusSelected.value = statusId;
	isDeleteDialogActive.value = true;
};

const statusPreLogic = () => {
	isDeleteDialogActive.value = false;
	const id = lastStatusSelected.value;
	if (!id) {
		console.error("Invalid ID", lastStatusSelected.value);
		return;
	}

	const foundObj = statusElements.value.find((el) => el.id === id);
	// NOTICE
	// I don't know where the action came from, but that's how it is in the old code
	// change
	if (foundObj && foundObj.action) {
		foundObj.action();
	} else if (foundObj) {
		updateStatus(foundObj);
	}
};

const updateStatus = async ({ id }: { id: number }) => {
	if (id === 3) {
		emit("confirm", props.user, 3); // deactivate
	} else if (id === 1) {
		emit("confirm", props.user, 1); // activate
	}
	emit("close-modal");
};

const getButtonStyle = (status: { color?: string; backColor: string }) => ({
	color: status.color || "white",
	backgroundColor: status.backColor,
});
</script>

<template>
	<div
		class="dialog-container"
		data-cell="edit-user-status"
		@click.stop=""
	>
		<div class="columns spacer-align">
			<div
				v-for="statusEl in filteredStatuses"
				:key="statusEl.id"
				class="column"
			>
				<button
					class="button fullwidth"
					data-cell="status-buttons"
					:disabled="isLoading"
					:style="getButtonStyle(statusEl)"
					:class="spinner(statusEl.id)"
					type="submit"
					@click.prevent="callActionButton(statusEl.id)"
				>
					{{ statusEl.label }}
				</button>
			</div>
		</div>
		<transition name="fade">
			<div
				v-if="isDeleteDialogActive"
				class="delete-section"
			>
				<span class="action-message">
					<span>Are you sure you want to change the user's status</span>
					<span
						v-if="props.user && props.user.name"
						class="user-name"
						:title="props.user.name"
					>
						{{ props.user.name }}
					</span>
					<span>?</span>
				</span>
				<span class="action-buttons">
					<a
						class="is-confirm"
						data-cell="yes-button"
						@click="statusPreLogic()"
					>
						YES
					</a>
					<a
						class="is-deny"
						data-cell="no-button"
						@click="
							lastStatusSelected = null;
							isDeleteDialogActive = false;
						"
					>
						NO
					</a>
				</span>
			</div>
		</transition>
		<div class="buttons-centered">
			<button
				class="button is-small is-outlined caps-lock"
				data-cell="cancel-button"
				@click.prevent="emit('close-modal')"
			>
				Cancel
			</button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.dialog-container {
	width: 100%;
	box-sizing: border-box;
	padding: 0 20px 20px;
	min-width: 564px;

	.columns {
		flex-direction: column;
	}

	.column {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}
}
.fullwidth {
	width: 100%;
	text-transform: uppercase;
}

.inputs {
	display: flex;
	justify-content: center;

	.field {
		width: 100%;
	}
}

.buttons-centered {
	display: flex;
	justify-content: center;
	align-items: center;

	button.button {
		margin: 17px 0.3em;
		min-width: 90px;
		height: 30px;
	}
}

label {
	margin-top: 0.5em;
}

.spacer-align {
	margin: 20px 0;
}

div.delete-section {
	display: flex;
	flex-direction: column;
	border-top: 1px solid rgba($blue-grey, 0.15);
	border-bottom: 1px solid rgba($blue-grey, 0.15);
	align-items: center;
	justify-content: center;

	.action-message {
		display: flex;
		gap: 5px;
		margin-right: 1em;
		color: $blue-grey;

		.user-name {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			max-width: 150px;
		}
	}

	.action-buttons {
		a.button {
			border: none;
			margin: 0 0.3em;
		}
	}

	.is-confirm,
	.is-deny {
		font-weight: 900;
		font-size: 1.1em;
		cursor: pointer;
	}

	.is-confirm {
		color: $red-cpl; // #ff3b3b;
		margin-right: 20px;
	}

	.is-deny {
		color: $blue-cpl;
	}
}

.fade-enter-to,
.fade-leave {
	max-height: 500px;
}

.fade-enter,
.fade-leave-to {
	max-height: 0;
}
</style>
