<script setup lang="ts">
import { useClientManagementStore } from "@/store/locationClientManagement";
import { useToastStore } from "@/store/toast";

const props = defineProps<{ client: any }>();

const emit = defineEmits(["close-modal"]);
const clientManagementStore = useClientManagementStore();

const isCompLoaded = ref(false);
const isEnabledAutoRepush = ref(false);
const isChangedOnceAutoRepushState = ref(false);
const isLoading = ref(false);
const limitValue = ref(null);
const toast = useToastStore();

const intervalOptions = computed(() => {
	const intervals = [30, 60, 120, 180, 240, 360];
	return intervals.map((min) => ({
		name: `${min} minutes`,
		value: min,
	}));
});

const isDisabledSave = computed(() => {
	return (
		(!limitValue.value && !isChangedOnceAutoRepushState.value) ||
		isLoading.value
	);
});

const fetchCurrentAutoRepush = async () => {
	if (props.client.autorepushactive) {
		isEnabledAutoRepush.value = props.client.autorepushactive;
	}
	const value = props.client.autorepushminutes;
	limitValue.value =
		intervalOptions.value.find((lv) => lv.value === value) || null;
};

const saveAutoRepushLimit = async () => {
	isLoading.value = true;
	const payload: any = {
		clientId: props.client.id,
		params: {
			autorepushactive: isEnabledAutoRepush.value,
		},
	};
	if (limitValue.value) {
		payload.params.autorepushminutes = limitValue.value.value;
	}
	try {
		// await store.dispatch(
		// 	"locationClientManagement/updateClientAutoRepush",
		// 	payload,
		// );
		await clientManagementStore.updateClientAutoRepush(payload);
		callModalClose();
	} catch (err) {
		toast.openToastError(
			err.response?.data?.message ??
				"There was an error setting Auto Repush interval!",
		);
		console.error("Error", err.response.data.message);
	} finally {
		isLoading.value = false;
	}
};

const onChangeAutoRepState = (evt: boolean) => {
	isEnabledAutoRepush.value = evt;
};

function callModalClose() {
	emit("close-modal");
}

onMounted(() => {
	isEnabledAutoRepush.value = props.client.autoRepush || false;
	fetchCurrentAutoRepush();
	setTimeout(() => {
		isCompLoaded.value = true;
	}, 200);
});
</script>

<template>
	<div class="auto-repush-modal">
		<div class="switch-group">
			<label>Off</label>
			<tri-state-switch
				color="accent"
				:value="isEnabledAutoRepush"
				@input="onChangeAutoRepState"
				@click.once="isChangedOnceAutoRepushState = true"
			/>
			<label>On</label>
		</div>
		<div
			class="bottom-content"
			:class="{ 'is-loaded': isCompLoaded }"
		>
			<transition
				name="fade-in"
				mode="out-in"
			>
				<div
					v-if="isEnabledAutoRepush"
					key="action"
					class="action-group"
				>
					<multiselect-form
						v-model="limitValue"
						:options="intervalOptions"
						:multiple="false"
						:close-on-select="true"
						track-by="name"
						style="width: 240px"
						label="name"
						placeholder="Select interval (minutes)"
					/>
				</div>
			</transition>
			<div class="action-wrap">
				<button
					class="button is-generic-app-blue is-caps-lock"
					:disabled="isDisabledSave"
					@click="saveAutoRepushLimit"
				>
					Save
				</button>
				<button
					class="button alt is-caps-lock"
					data-cancel="data-cancel"
					@click.prevent="callModalClose"
				>
					Cancel
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.auto-repush-modal {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-width: 500px;
	min-height: 180px;

	$transition-time: 0.2s;

	@mixin flex-col {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.switch-group {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;

		label {
			margin: 0;
		}

		.tri-state-switch {
			margin-bottom: 6px;
		}
	}

	.bottom-content {
		@include flex-col;

		// height: 100px;

		.action-wrap {
			display: flex;
			justify-content: center;
			gap: 15px;
		}
	}

	.action-group {
		@include flex-col;

		align-items: center;
	}

	.multiselect {
		width: 200px;
	}

	.is-loaded {
		.fade-in-enter-active,
		.fade-in-leave-active {
			transition: all $transition-time ease;
		}

		.fade-in-enter,
		.fade-in-leave-to {
			opacity: 0;
			transform: translateY(-10px);
		}
	}
}
</style>
