<script setup lang="ts">
import { useDefaultStore } from "./store";
import { useModalStore } from "./store/modal";
import ModernModal from "./components/shared/modal/ModernModal.vue";
import api from "./api";
import { useSessionStore } from "./store/session";
import { useKeepAliveStore } from "./store/keepalive";

const store = useDefaultStore();
const modalStore = useModalStore();
const sessionStore = useSessionStore();

const route = useRoute();
const router = useRouter();
const modernModalRef = ref<InstanceType<typeof ModernModal> | null>(null);
const isAppLoading = ref(false);

const keepAliveStore = useKeepAliveStore();

watch(
	() => store.isMobileView,
	(val) => {
		if (val) document.body.classList.add("is-mobile");
		else if (!val) document.body.classList.remove("is-mobile");
	},
);

function onCloseModalComp() {
	if (modernModalRef.value) {
		modernModalRef.value.onClickClose(null, true);
	} else {
		console.error("No modal instance");
	}
}

async function fetchStatusInitialLoad() {
	try {
		const res = await api.getStatusInitialLoad();
		// Setup global colors (icons are set automatically per img source)
		store.setupAppInitialColors(res.data);
		// setup initial status for agency
		store.setInitialStatus(res.data);
	} catch (err) {
		console.warn(err.message);
		setTimeout(() => {
			if (err.response.status === 429) {
				location.reload();
			}
		}, 5000);
	}
}

async function initAppAuth() {
	await sessionStore.loadSessionToken();
	if (!sessionStore.isLoggedIn) {
		if (route.meta.requiresGuest) return;
		// router.push({ name: "auth.signin" });
	} else if (route.matched.some((match) => match.name === "auth.signin")) {
		router.push({ name: "admin.dashboard" });
	}
}

onBeforeMount(async () => {
	isAppLoading.value = true;
	await initAppAuth();
	await fetchStatusInitialLoad();
	isAppLoading.value = false;
	checkSystemPermissionForRoute();
});

function checkAltPermissions(obj) {
	console.warn(obj);
	return obj.altPermissions.some((permGroup) => {
		if (permGroup.systemPermissions) {
			const hasSysPerm = permGroup.systemPermissions?.length
				? permGroup.systemPermissions.every((tabPerm) => {
						const hasPerm = store.initialStatus[tabPerm];
						// console.warn("[ROUTE] Check sys perm", tabPerm, hasPerm);
						return hasPerm;
					})
				: true;
			if (!hasSysPerm) return false;
		}

		if (permGroup.notSystemPermissions) {
			const notHasSysPerm = permGroup.notSystemPermissions?.length
				? !permGroup.notSystemPermissions.every((tabPerm) => {
						const hasPerm = store.initialStatus[tabPerm];
						// console.warn("[ROUTE] Check sys perm NOT", tabPerm, hasPerm);
						return hasPerm;
					})
				: true;
			if (!notHasSysPerm) return false;
		}

		if (permGroup.permissions) {
			const hasPerm = permGroup.permissions?.length
				? permGroup.permissions.every((tabPerm) => {
						const hasPerm = sessionStore.can(tabPerm);
						// console.warn("[ROUTE] Check perm", tabPerm, hasPerm);
						return hasPerm;
					})
				: true;
			if (!hasPerm) return false;
		}
		return true;
	});
}

function checkSystemPermissionForRoute() {
	// meta permission
	const permission = route.meta?.permission;

	if (typeof permission === "string" && permission.length > 0) {
		const canAccessFirstLink = sessionStore.can(permission);
		if (!canAccessFirstLink && route.meta.permission !== undefined) {
			router.replace("/");
			return;
		}
	}
	let canEnterPage = false;
	console.log(route, "route.meta.altPermissions");
	if (route.meta.altPermissions) {
		console.log(route.name, "route.meta.altPermissions");

		const hasPerm = checkAltPermissions(route.meta);
		canEnterPage = hasPerm;
	} else {
		const metaSystemPerms = route.meta.systemPermissions;
		canEnterPage = Array.isArray(metaSystemPerms)
			? metaSystemPerms.every((perm) => store.initialStatus[perm])
			: true;
	}

	// altPermissions
	if (!canEnterPage) {
		// Return to index
		router.replace("/");
	}
	//   this.canLoadContent = true;
}
</script>

<template>
	<spinner-block
		v-if="isAppLoading"
		background="#0000001a"
		color1="#f6a712"
		color2="black"
	></spinner-block>
	<template v-else>
		<div class="main-application-class page-wrap">
			<template v-if="sessionStore.isLoggedIn">
				<side-bar></side-bar>
				<header-bar></header-bar>
			</template>

			<router-view v-slot="{ Component }">
				<keep-alive :include="keepAliveStore.keepAlive">
					<component
						:is="Component"
						style="margin-top: 100px"
						class="page"
						:class="{
							'is-mobile': store.isMobileToggled,
							'sidebar-open':
								store.sidebarOpen &&
								![
									'auth.signin',
									'auth.forgot-password',
									'auth.password-recovery',
								].includes(route.name as string),
						}"
					></component>
				</keep-alive>
			</router-view>
		</div>
	</template>
	<enon-browser-detect></enon-browser-detect>
	<modern-modal
		v-if="modalStore.isGlobalModalVisible"
		:key="modalStore.getModalData('comp')"
		ref="modernModalRef"
		:config="modalStore.getModalData('config', {})"
		:form-style="modalStore.getModalData('formStyle', {})"
		@close-modal="modalStore.clearModalData"
	>
		<template #content="{ slotProps }">
			<component
				:is="modalStore.getModalData('comp')"
				v-bind="slotProps"
				@close-modal="onCloseModalComp"
			></component>
		</template>
	</modern-modal>
</template>

<style lang="scss">
@import "./assets/css/base.css";
@import "https://fonts.googleapis.com/css2?family=Anton&family=Bakbak+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800&display=swap";

body {
	display: flex;

	&.is-mobile {
		overflow-y: hidden;
	}
}

#app {
	width: 100vw;
	// height: 100%;

	&::-webkit-scrollbar {
		background-color: $scrollbar-color;
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $scrollbar-color-thumb;
		border-radius: 5px;
	}
}
.page-wrap {
	// height: 100%;
	height: calc(100vh - 100px);
}
</style>
