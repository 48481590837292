import { defineStore } from "pinia";
import api from "@/api";

export const useReportsStore = defineStore("reports", () => {
	const sectors = ref<Record<string, any>[]>([]);
	const counties = ref<any>([]);
	const previewData = ref<any>({});
	const pagination = ref<Record<string, any>>({
		totalPages: 0,
		currentPage: 0,
		currentPageData: {},
	});
	const reports = ref<Record<string, any>[]>([]);
	const recalculationScenario = ref([
		//in the future, it may be obtained from the backend
		{
			label: "Pay and then Bill",
			sticker: "P&B",
			order: 0,
			code: "PYBL",
		},
		{
			label: "Bill and then Pay",
			sticker: "B&P",
			order: 1,
			code: "BLPY",
		},
		{
			label: "Only Bill",
			sticker: "B",
			order: 2,
			code: "ONBL",
		},
		{
			label: "Only Pay",
			sticker: "P",
			order: 3,
			code: "ONPY",
		},
	]);

	// actions
	function fetchPageHasChanged(payload) {
		pagination.value.currentPage = payload;
		pagination.value.currentPageData =
			previewData.value?.data.pages[payload - 1];
	}

	async function fetchReportList(params?: any) {
		try {
			const res = await api.fetchReportList(params);
			reports.value = res.data.data;
			// commit("STORE_REPORTS", res.data);
		} catch (err) {
			console.log("Failed to fetch reports", err.message);
		}
	}

	async function fetchPreviewReports(params) {
		resetPreviewData();
		try {
			const response = await api.fetchReports(params);
			previewData.value = response.data;
			if (response.data.data.pages !== undefined) {
				// commit("STORE_PREVIEW_DATA", response.data);
				previewData.value = response.data;
				// commit("STORE_PAGINATION", response.data);
				const total = response.data.data.pages.length;
				pagination.value.totalPages = total;
				if (total !== 0) {
					pagination.value.currentPage = 1;
					pagination.value.currentPageData = response.data.data.pages[0];
				}
				return;
			}
		} catch (e) {
			// return Promise.reject();
			throw e;
		}
	}
	function resetPreviewData() {
		previewData.value = {};
		pagination.value.totalPages = 0;
		pagination.value.currentPage = 0;
		pagination.value.currentPageData = {};
	}

	// START get sectors
	function getSectors() {
		// Replacing reduce with forEach for traversing subsectors
		const traverseSubs = (sectors) => {
			const result = [];
			for (const curr of sectors) {
				if (curr.selectable) {
					// Add selectable sector to the result

					result.push(curr);
				} else if (curr.subsectors) {
					// Recursively traverse subsectors
					const subSec = traverseSubs(curr.subsectors);
					result.push(...subSec);
				}
			}
			return result;
		};

		// Replacing reduce with forEach for creating sector parents
		const createSectorParents = (subs) => {
			const result = [];
			for (const curr of subs) {
				// Add parent sector names without duplicates
				if (!result.includes(curr.sectorParent.name)) {
					result.push(curr.sectorParent.name);
				}
			}
			return result;
		};

		// Traversing the state's sectors
		const subs = traverseSubs(sectors.value);
		// Creating a list of sector parent names
		const parentSubs = createSectorParents(subs);

		// Generating subs grouped by parent names
		const getSubsWithParent = (parents) => {
			const allSubs = parents.map((parentName) => {
				// Collect all subs for a given parent name
				const genSubs = subs.filter(
					(sub) => sub.sectorParent.name === parentName,
				);
				return {
					name: parentName,
					parentName: parentName,
					options: genSubs,
				};
			});
			return allSubs;
		};

		// Returning the final result
		return getSubsWithParent(parentSubs);
	}
	// END get sectors

	async function fetchSectors(q: any = {}) {
		// includes: ""
		try {
			const res = await api.fetchSectors(q);
			sectors.value = res.data.data;
		} catch (e) {
			console.log(e, "LCM: Something went wrong while fetching sectors.");
		}
		// return api
		//   .fetchSectors(q)
		//   .then((response) => {
		// 	commit("STORE_SECTORS", response.data);
		//   })
		//   .catch(() =>
		// 	console.log("LCM: Something went wrong while fetching sectors.")
		//   );
	}

	return {
		sectors,
		counties,
		previewData,
		pagination,
		reports,
		recalculationScenario,
		//
		fetchPageHasChanged,
		fetchReportList,
		fetchPreviewReports,
		getSectors,
		fetchSectors,
		resetPreviewData,
	};
});
