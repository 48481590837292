import type { DirectiveBinding } from "vue";

export default {
	mounted(el: HTMLInputElement, binding: DirectiveBinding) {
		// Define the keydown handler function
		const keydownHandler = (event: KeyboardEvent) => {
			// Allow navigation keys (backspace, arrow keys, etc.)
			const allowedKeys = [
				"Backspace",
				"ArrowLeft",
				"ArrowRight",
				"ArrowUp",
				"ArrowDown",
				"Delete",
				"Tab",
			];

			// Allow Ctrl/Meta shortcuts (e.g., Ctrl+A, Ctrl+C, etc.)
			if (event.ctrlKey || event.metaKey) {
				const allowedCtrlKeys = ["a", "c", "v", "x", "z", "y"];
				if (allowedCtrlKeys.includes(event.key.toLowerCase())) {
					return; // Allow the shortcut
				}
			}

			// Allow only numbers and allowed keys
			if (!allowedKeys.includes(event.key) && !/^\d+$/.test(event.key)) {
				event.preventDefault();
			}
		};

		// Define the paste handler to clean the pasted content
		const pasteHandler = (event: ClipboardEvent) => {
			// Get the pasted text from the clipboard
			const pastedText = event.clipboardData?.getData("text") || "";

			// Clean the pasted text by removing all non-digits
			const cleanedValue = pastedText.replace(/\D/g, "");

			// Prevent the default paste action and set the cleaned value
			event.preventDefault();
			document.execCommand("insertText", false, cleanedValue);
		};

		// Add the event listeners
		el.addEventListener("keydown", keydownHandler);
		el.addEventListener("paste", pasteHandler);

		// Store the handlers for later removal
		(el as any)._keydownHandler = keydownHandler;
		(el as any)._pasteHandler = pasteHandler;
	},
	unmounted(el: HTMLInputElement) {
		// Retrieve and remove the stored event listeners
		const keydownHandler = (el as any)._keydownHandler;
		const pasteHandler = (el as any)._pasteHandler;

		if (keydownHandler) {
			el.removeEventListener("keydown", keydownHandler);
			delete (el as any)._keydownHandler;
		}

		if (pasteHandler) {
			el.removeEventListener("paste", pasteHandler);
			delete (el as any)._pasteHandler;
		}
	},
};
