import { defineStore } from "pinia";
import { useWindowSize } from "@vueuse/core";
import { useRouter, useRoute } from "vue-router";
import {
	downloadExcelFile,
	filterExpenseTypes,
	simpleCrypt,
	simpleDecrypt,
} from "@/assets/js/helpers";
import { cssVar, getHexColor, hexToHSLArr } from "@/assets/js/cssHelpers";
import type {
	TInitialStatusObject,
	TClients,
	TExpenseParams,
	TGenericRecord,
} from "@/assets/js/globalTypes";
import api from "@/api";
import axios from "axios";
import type { CancelToken, CancelTokenSource } from "axios";
import { parseAllErrors, parseErrors } from "@/assets/js/parseErrors";
import type { TDocumentRejectionReason } from "@/api/types";

// stays same till refresh
const imageTimestamp = Date.now();

let globalImageBaseUrl = "";
globalImageBaseUrl += import.meta.env.VITE_APP_API_URL_DEV
	? window.location.protocol + "//" + import.meta.env.VITE_APP_API_URL_DEV
	: window.location.origin;

const globalImageUrl = new URL(globalImageBaseUrl + "/api/get-logo");
globalImageUrl.searchParams.append("t", imageTimestamp.toString());

export const useDefaultStore = defineStore("main", () => {
	const { width } = useWindowSize();
	const isMobileToggled = ref(false);
	const router = useRouter();
	const route = useRoute();

	const initialStatus = ref<TInitialStatusObject>(null);
	const sidebarOpen = ref<boolean>(true);

	const clients = ref<TClients[]>([]);
	const costs = ref<any[]>([]);
	const agency_workers = ref<any[]>([]);
	const tempProfile = ref<any>(null);
	const categories = ref<any>([]);
	const allCategories = ref<any>([]);
	const allRoles = ref<any>([]);
	const minRoles = ref<any>([]);
	const shiftConfirmEnablement = ref(false);
	const canUseShiftConfirm = computed(
		() => initialStatus.value?.can_use_shift_confirmation_engine,
	);
	const expenseTypes = ref<any>([]);
	const expenses = ref<any>([]);
	const expense = ref<TGenericRecord>({});
	const checkRejectData = ref<TGenericRecord>({});
	const checkCutOffData = ref<TGenericRecord>({});
	const checkSignOffData = ref<TGenericRecord>({});
	const expensesMeta = ref<{
		total_pages: number;
		current_page: number;
	}>({
		total_pages: 1,
		current_page: 1,
	});
	const expenseErrors = ref<any>(null);

	const isMobileSize = computed(() => width.value < 768);
	const isMobileView = computed(
		() => isMobileSize.value && isMobileToggled.value,
	);

	watch(isMobileSize, (val) => {
		if (!val) {
			isMobileToggled.value = false;
		}
	});

	function getGlobalImage() {
		return globalImageUrl.toString();
	}

	function decodePath(name: string) {
		return simpleDecrypt("abc", name);
	}
	function encodePath(name: string) {
		return simpleCrypt("abc", name);
	}
	async function onClickLoginPage() {
		// Used to return user to the previous page
		const currentPageName = String(route.name);

		// Exceptions - Auth pages
		let ppn: string | undefined = encodePath(currentPageName);
		if (route.matched.some((rt) => rt.name === "AuthPage")) {
			ppn = undefined;
		}

		await router.push({
			name: "LoginPage",
			query: {
				ppn,
			},
		});
	}

	async function goToProfilePage() {
		await router.push({
			// name: "SettingsPage",
			// name: "ProfilePage",
			name: "LoginPage",
		});
	}
	function setInitialStatus(e: TInitialStatusObject) {
		initialStatus.value = e;
	}
	function setupAppInitialColors(colors) {
		if (!colors) {
			// Use default fallback:
			// 1 - blue
			// 2 - red
			colors = {
				color_1: "#0d6bbb",
				color_2: "#34495e",
				color_toggle_on: "#4ad862",
				color_toggle_off: "#bdbdbd",
			};
		}

		const colorHex1 = getHexColor(colors.color_1);
		const colorHex2 = getHexColor(colors.color_2);
		const colorHslArr1 = hexToHSLArr(colorHex1);
		const colorHslArr2 = hexToHSLArr(colorHex2);
		// Toggle
		const colorHexToggleOn = getHexColor(colors.color_toggle_on);
		const colorHexToggleOff = getHexColor(colors.color_toggle_off);
		const colorHslArrToggleOn = hexToHSLArr(colorHexToggleOn);
		const colorHslArrToggleOff = hexToHSLArr(colorHexToggleOff);
		// Primary
		cssVar("--color-primary-h", colorHslArr1[0]);
		cssVar("--color-primary-s", `${colorHslArr1[1]}%`);
		cssVar("--color-primary-l", `${colorHslArr1[2]}%`);

		// Secondary
		cssVar("--color-secondary-h", colorHslArr2[0]);
		cssVar("--color-secondary-s", `${colorHslArr2[1]}%`);
		cssVar("--color-secondary-l", `${colorHslArr2[2]}%`);

		// Css toggle
		cssVar("--color-toggle-on-h", colorHslArrToggleOn[0]);
		cssVar("--color-toggle-on-s", `${colorHslArrToggleOn[1]}%`);
		cssVar("--color-toggle-on-l", `${colorHslArrToggleOn[2]}%`);

		cssVar("--color-toggle-off-h", colorHslArrToggleOff[0]);
		cssVar("--color-toggle-off-s", `${colorHslArrToggleOff[1]}%`);
		cssVar("--color-toggle-off-l", `${colorHslArrToggleOff[2]}%`);
	}

	const systemPermissions = computed(
		(): {
			color1: string;
			color2: string;
			colorToggleOff: string;
			colorToggleOn: string;
			monetarySymbol: string;
			servicefeeShowCalcitems: boolean;
			servicefeeShowIncrements: boolean;
			servicefeeShowSubcategories: boolean;
			servicefeeCalcitems: string;
			timerangeCalcitems: boolean;
			canViewAllowances: boolean;
			shiftSignOffWarning: boolean;
			canCopyServiceFeesFromRates: boolean;
			canUseIncrementCounter: boolean;
			showGnibRules: boolean;
			canUseHolidayRequests: boolean;
			canUseOnCall: boolean;
			canUseSleepover: boolean;
			canUseExpenses: boolean;
			canManagePublicHolidays: boolean;
			canUseShiftActivityTags: boolean;
			canUseNexusMiddleware: boolean;
			canUseManualPremiumHolidayPayRelease: boolean;
		} => {
			const futureKeys = new Set([]);
			const keys = {
				color1: "color_1",
				color2: "color_2",
				colorToggleOff: "color_toggle_off",
				colorToggleOn: "color_toggle_on",
				monetarySymbol: "monetary_symbol",
				servicefeeShowCalcitems: "servicefee_show_calcitems",
				servicefeeShowIncrements: "servicefee_show_increments",
				servicefeeShowSubcategories: "servicefee_show_subcategories",
				servicefeeCalcitems: "servicefee_calcitems",
				timerangeCalcitems: "timerange_calcitems",
				canViewAllowances: "can_view_allowances",
				shiftSignOffWarning: "shift_sign_off_warning",
				canCopyServiceFeesFromRates: "can_copy_service_fees_from_rates",
				canUseIncrementCounter: "can_use_increment_counter",
				showGnibRules: "show_gnib_rules",
				canUseHolidayRequests: "can_use_holiday_requests",
				canUseOnCall: "can_use_on_call",
				canUseSleepover: "can_use_sleepover",
				canUseExpenses: "can_use_expenses",
				canManagePublicHolidays: "can_manage_public_holidays",
				canUseShiftActivityTags: "can_use_shift_activity_tags",
				canUseNexusMiddleware: "can_use_nexus_middleware",
				canUseManualPremiumHolidayPayRelease:
					"can_use_manual_premium_holiday_pay_release",
			};

			const entries = Object.entries(keys).map(([key, val]) => {
				if (futureKeys.has(key)) {
					return [key, true];
				}

				return [key, initialStatus.value[val]];
			});

			return Object.fromEntries(entries);
		},
	);

	// CLIENTS

	// Recieve list of Clients
	async function fetchClientsList(q: any = {}) {
		// commit("SET_CLIENTS", []);
		clients.value = [];

		try {
			const res = await api.fetchAllClients(q);
			clients.value = res.data.data;
		} catch (e) {
			console.log(e);
		}
	}
	/**
	 * Fetch all categories with subcategories
	 */
	async function fetchAllCategoriesList() {
		try {
			const res = await api.fetchAllCategories();
			allCategories.value = res.data.data;
		} catch (e) {
			console.warn(e);
		}
	}

	async function fetchAllRolesList() {
		try {
			const res = await api.fetchAllRoles();
			// commit("FETCH_ALL_ROLES", response.data);
			allRoles.value = res.data.data;
			return res;
		} catch (e) {
			console.log(e);
		}
	}
	async function fetchMinRoles() {
		try {
			const res = await api.fetchMinRoles();
			// commit("FETCH_ALL_ROLES", response.data);
			minRoles.value = res.data.data;
			return res;
		} catch (e) {
			console.log(e);
		}
		// return api
		//   .fetchMinRoles()
		//   .then((response) => {
		// 	commit("FETCH_MIN_ROLES", response.data);
		//   })
		//   .catch((error) => console.log(error));
	}

	/**
	 * Will fetch dependant lists for locations based on a selected Client
	 * SELF REMINDER you have ability to pass arguments to getter, by returning a function callback
	 */
	function fillRelatedFields(selected: any) {
		costs.value = [];

		return clients.value.map(({ id, locations }) => {
			if (selected.id === id) {
				costs.value = locations.map((c) => {
					return {
						name: c.name,
						id: c.id,
						categories: c.categories,
					};
				});
			}
		});
	}
	// bookshifts optimization
	async function fetchLocationsPerClient(q: any) {
		try {
			const res = await api.fetchLocationsPerClient(q);
			costs.value = res.data.data;
			return res;
		} catch (error) {
			console.log(error);
		}
		// return api.fetchLocationsPerClient(q).then((response) => {
		//   commit("STORE_LOCATIONS_PER_CLIENT", response.data);
		//   costs.value = response.data.data
		//   return Promise.resolve(response.data);
		// });
	}
	function CLEAR_LOCATIONS() {
		costs.value = [];
	}
	/**
	 * Will fetch dependant lists for Categories based on a selected Location
	 */
	function fillCategories(selected: any) {
		//  state.categories = _.findWhere(state.costs, {
		//    id: selected.id,
		//  }).categories;
		console.log(selected);
		const found = costs.value.find((cost) => cost.id === selected.id);
		categories.value = found ? found.categories : [];
		return categories.value;
	}
	/**
	 * Formatted list (custom) for vue-multiselect, categories->subcategories groups.
	 */
	function getCategories() {
		const options = [];
		// categories.value?.map((cat) => {
		// 	options.push({
		// 		category: cat.name,
		// 		subs: cat.subcategories.map((subcat) => {
		// 			return {
		// 				name: subcat.name,
		// 				id: subcat.id,
		// 			};
		// 		}),
		// 	});
		// });
		categories.value?.map((cat) => {
			options.push({
				category: cat.hasOwnProperty("name") ? cat.name : cat.category,
				subs: cat.hasOwnProperty("subcategories")
					? cat.subcategories.map((subcat) => {
							return {
								name: subcat.name,
								id: subcat.id,
							};
						})
					: cat.subs.map((subcat) => {
							return {
								name: subcat.name,
								id: subcat.id,
							};
						}),
			});
		});
		categories.value = options;
		return categories.value;
	}
	function CLEAR_CATEGORIES() {
		categories.value = [];
	}
	function getAllClients() {
		return clients.value;
	}
	// function getClientsWithLocations() {
	// 	// return clients.value;
	// 	const clientsFiltered = clients.value.reduce((acc, client) => {
	// 		// see why it throws error, gets called when clients do not include locations
	// 		// NOTE TO MYSELF this getter shoudn't trigger in Documents view, but it does, hence the error!
	// 		if (client.locations) {
	// 			acc.push({
	// 				client: client.name,
	// 				subs: client.locations.map((location) => {
	// 					return {
	// 						name: location.name,
	// 						id: location.id,
	// 					};
	// 				}),
	// 			});
	// 		}
	// 		return acc;
	// 	}, []);
	// 	return clientsFiltered;

	function getClientsWithLocations() {
		const clientsFiltered = clients.value
			.filter(
				(client) =>
					Array.isArray(client.locations) && client.locations.length > 0,
			)
			.map((client) => ({
				client: client.name,
				id: client.id,
				options: client.locations.map((location) => ({
					name: location.name,
					id: location.id,
				})),
			}));
		return clientsFiltered;
	}

	// }
	/**
	 * Formatted list (custom) for vue-multiselect, categories -> subcategories groups.
	 * This is practically variation of the above TODO refactor later
	 */
	function getCategoriesWithSubcategories() {
		const options = [];
		allCategories.value.map((cat) => {
			if (cat.subcategories && cat.subcategories.length > 0)
				options.push({
					id: cat.id,
					category: cat.name,
					options: cat.subcategories.map((subcat) => {
						return {
							catParentId: cat.id,
							name: subcat.name,
							id: subcat.id,
						};
					}),
				});
		});
		return options;
	}
	function getAllCategories() {
		return allCategories.value;
	}

	//
	/**
	 * Finds agency worker (for: search as you type box)
	 * keeps orignally selected as first option, to be able
	 * to remove it easily, otherwise it would be lost when user
	 * starts typing
	 */
	const cancelToken = ref<CancelTokenSource | null>(null);
	async function findAgencyWorker(payload) {
		if (cancelToken.value !== null) {
			cancelToken.value.cancel();
			cancelToken.value = null;
		}
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		cancelToken.value = source;

		const originallySelectedWorker = payload.originallySelectedWorker;
		const transformedPayload: Record<string, any> = {
			name: payload.query,
			per_page: 50,
			gender: payload.gender,
			has_on_call: payload.has_on_call,
			has_sleepover: payload.has_sleepover,
			cancelTokenSource: source,
		};

		payload.tempId && (transformedPayload.temp_id = payload.tempId);

		try {
			const res = await api.fetchWorkers(transformedPayload);
			let tempData = [...res.data.data];
			// console.log(tempData);

			if (
				originallySelectedWorker &&
				Object.keys(originallySelectedWorker).length
			) {
				// Makes originallySelectedWorker be in first position
				// Only add if not exists
				const elIndex = tempData.findIndex(
					(worker) => worker.id === originallySelectedWorker.id,
				);
				if (elIndex !== -1) {
					tempData.splice(elIndex, 1);
				}
				tempData = [originallySelectedWorker, ...tempData];
			}

			//   commit("FETCH_AGENCY_WORKERS", tempData);
			agency_workers.value = tempData;
		} catch (e) {
			console.log(e);
		}

		// return api.fetchWorkers(transformedPayload).then(({ data: { data } }) => {
		// 	let tempData = [...data];
		// 	if (
		// 		originallySelectedWorker &&
		// 		Object.keys(originallySelectedWorker).length
		// 	) {
		// 		// Makes originallySelectedWorker be in first position
		// 		// Only add if not exists
		// 		const elIndex = tempData.findIndex(
		// 			(worker) => worker.id === originallySelectedWorker.id,
		// 		);
		// 		if (elIndex !== -1) {
		// 			tempData.splice(elIndex, 1);
		// 		}
		// 		tempData = [originallySelectedWorker, ...tempData];
		// 	}

		// 	//   commit("FETCH_AGENCY_WORKERS", tempData);
		// 	agency_workers.value = tempData;
		// });
	}
	function getAgencyWorkers() {
		return agency_workers.value;
	}
	async function fetchTempProfile(query: {
		tempId: number;
		include?: string;
		[k: string]: any;
	}) {
		try {
			const res = await api.fetchTempProfile(query);
			tempProfile.value = res.data;
			return res;
			// .then((response) => {
			//   commit("FETCH_TEMP_PROFILE", response.data);
			//   return response;
			// })
			// .catch((error) => console.log(error));
		} catch (e) {
			console.log(e);
		}
	}

	async function fetchExpenseTypes(): Promise<void> {
		expenseErrors.value = null;
		try {
			const res = await api.getExpenseTypes();
			expenseTypes.value = filterExpenseTypes(res?.data?.data ?? []);
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	function getExpenseTypes(): any[] {
		return expenseTypes.value;
	}

	async function fetchExpenses(
		params: TExpenseParams,
		cancelToken: CancelToken | CancelTokenSource | null,
	): Promise<void> {
		resetExpenses();
		expenseErrors.value = null;
		try {
			const res = await api.getExpenses(params, cancelToken);
			expenses.value = res?.data?.data;
			expensesMeta.value = res?.data?.meta?.pagination ?? null;
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	function resetExpenses(): any[] {
		return (expenses.value = null);
	}

	function getExpenses(): any[] {
		return expenses.value;
	}

	function getExpensesMeta(): any {
		return expensesMeta.value;
	}

	async function fetchExpense(expenseId: number): Promise<void> {
		resetExpense();
		expenseErrors.value = null;
		try {
			const params = {
				id: expenseId,
				include: [
					"temp",
					"client",
					"expenseType",
					"details",
					"userRejected",
					"userProcessed",
					"userCancelled",
				],
			};
			const res = await api.getExpense(params);
			expense.value = res?.data?.data;
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	function resetExpense(): TGenericRecord {
		return (expense.value = {});
	}

	function getExpense(): any {
		return expense.value;
	}

	async function checkReject(expenseId: number): Promise<void> {
		expenseErrors.value = null;
		try {
			const params = {
				id: expenseId,
			};
			const res = await api.checkReject(params);
			checkRejectData.value = res?.data?.data;
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	async function checkCutOff(): Promise<void> {
		expenseErrors.value = null;
		try {
			const res = await api.checkCutOff();
			checkCutOffData.value = res?.data?.data;
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	async function checkSignOff(expenseId: number): Promise<void> {
		expenseErrors.value = null;
		try {
			const params = {
				id: expenseId,
			};
			const res = await api.checkSignOff(params);
			checkSignOffData.value = res?.data?.data;
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	function getCheckRejectData(): any {
		return checkRejectData.value;
	}

	function getCheckCutOffData(): any {
		return checkCutOffData.value;
	}

	function getCheckSignOffData(): any {
		return checkSignOffData.value;
	}

	async function expenseReject(params: {
		id: number;
		reject_reason: string;
	}): Promise<void> {
		expenseErrors.value = null;
		try {
			await api.expenseReject(params);
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	async function expenseSignOff(expenseId: number): Promise<void> {
		expenseErrors.value = null;
		try {
			const params = {
				id: expenseId,
			};
			await api.expenseSignOff(params);
		} catch (ex) {
			expenseErrors.value = parseErrors(ex);
		}
	}

	function getExpenseErrors() {
		return expenseErrors.value;
	}

	const documents = ref<any>(null);
	const documentsPagination = ref<any>([]);
	const documentsErrors = ref<any>(null);
	const documentLocations = ref<any>([]);
	async function fetchDocumentLocations(params): Promise<void> {
		try {
			const res = await api.fetchDocumentLocations(params.id);
			documentLocations.value = res?.data?.data;
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	function getDocumentWithLocations(): any[] {
		return documentLocations.value;
	}
	async function fetchDocumentsForApproval(params): Promise<void> {
		setDocuments(null);
		try {
			const res = await api.fetchDocumentsForApproval(params);
			setDocuments(res?.data?.data);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	async function fetchDocuments(params): Promise<void> {
		setDocuments(null);
		try {
			const res = await api.fetchCompliances(params);
			setDocuments(res?.data?.data);
			setDocumentsPagination(res?.data?.meta?.pagination);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	function getDocuments(): any[] {
		return documents.value;
	}
	function setDocuments(val: any): void {
		documents.value = val ?? null;
	}
	function insertNewDocument(val: any): void {
		documents.value.unshift(val);
	}
	function removeNewDocument(): void {
		documents.value.shift();
	}
	async function saveNewDocument(document: any): Promise<void> {
		try {
			await api.postDocument(document);
		} catch (error) {
			documentsErrors.value = parseAllErrors(error);
		}
	}
	function getDocumentsPagination(): any {
		return documentsPagination.value;
	}
	function setDocumentsPagination(val: any): void {
		documentsPagination.value = val ?? null;
	}
	async function updateDocument(document: any): Promise<void> {
		try {
			await api.patchDocument(document.id, document);
		} catch (error) {
			documentsErrors.value = parseAllErrors(error);
		}
	}
	async function updateDocumentExpiry(document: any): Promise<void> {
		try {
			await api.updateDocumentExpiry(
				document.id,
				document?.temp?.id ?? -1,
				document,
			);
		} catch (error) {
			documentsErrors.value = parseAllErrors(error);
		}
	}
	async function approveDocument(document: any): Promise<void> {
		try {
			await api.approveDocument(
				document.id,
				document?.temp?.id ?? -1,
				document,
			);
		} catch (error) {
			documentsErrors.value = parseAllErrors(error);
		}
	}
	async function deleteDocument(id: number): Promise<void> {
		try {
			await api.deleteDocument(id);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	function getDocumentsErrors(): any {
		return documentsErrors.value;
	}
	function resetDocumentsErrors(): any {
		return (documentsErrors.value = null);
	}
	const temps = ref<any>([]);
	const tempsPagination = ref<any>(null);
	function getTemps(config: { class: string[] } | null = null): any {
		if (config && "class" in config && config.class.length > 0) {
			for (const temp of temps.value) {
				temp._class = config.class.join(" ");
			}
		}
		return temps.value;
	}
	function setTemps(val: any): void {
		temps.value = val ?? null;
	}
	function getTempsPagination(): any {
		return tempsPagination.value;
	}
	function setTempsPagination(val: any): void {
		tempsPagination.value = val ?? null;
	}
	async function fetchTempsCompliance(params): Promise<void> {
		try {
			const res = await api.fetchTemps(params);
			setTemps(res?.data?.data);
			setTempsPagination(res?.data?.meta?.pagination);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	async function downloadTempsComplianceReport(params): Promise<void> {
		try {
			const res = await api.downloadTempsComplianceReport(params);
			const resType = "application/xlsx";
			const blob = new Blob([res.data], { type: resType });
			const headerFileName = res.headers["x-multi-file-name"];
			const name = headerFileName || "Report.xlsx";
			downloadExcelFile(blob, resType, name);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}

	const documentRejectionReasons = ref<TDocumentRejectionReason[] | null>(null);
	async function fetchDocumentRejectionReasons(): Promise<void> {
		try {
			const res = await api.fetchDocumentRejectionReasons();
			documentRejectionReasons.value = res?.data?.data;
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	function getDocumentRejectionReasons(): TDocumentRejectionReason[] | null {
		return documentRejectionReasons.value;
	}

	const tempDocumentFiles = ref<any | null>(null);
	async function fetchTempDocumentFiles(params: any): Promise<void> {
		try {
			const res = await api.fetchTempDocumentFiles(params);
			tempDocumentFiles.value = res?.data?.data;
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	async function deleteTempDocumentFiles(params: any): Promise<void> {
		try {
			await api.deleteTempDocumentFiles(params);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	async function approveTempDocumentFiles(params: any): Promise<void> {
		try {
			await api.approveTempDocumentFiles(params);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	async function rejectTempDocumentFiles(params: any): Promise<void> {
		try {
			await api.rejectTempDocumentFiles(params);
		} catch (error) {
			documentsErrors.value = parseErrors(error);
		}
	}
	function getTempDocumentFiles(): any | null {
		return tempDocumentFiles.value;
	}

	const sectors = ref<Record<string, any>[]>([]);
	// START get sectors
	function getSectors() {
		// Replacing reduce with forEach for traversing subsectors
		const traverseSubs = (sectors) => {
			const result = [];
			for (const curr of sectors) {
				if (curr.selectable) {
					// Add selectable sector to the result

					result.push(curr);
				} else if (curr.subsectors) {
					// Recursively traverse subsectors
					const subSec = traverseSubs(curr.subsectors);
					result.push(...subSec);
				}
			}
			return result;
		};

		// Replacing reduce with forEach for creating sector parents
		const createSectorParents = (subs) => {
			const result = [];
			for (const curr of subs) {
				// Add parent sector names without duplicates
				if (!result.includes(curr.sectorParent.name)) {
					result.push(curr.sectorParent.name);
				}
			}
			return result;
		};

		// Traversing the state's sectors
		const subs = traverseSubs(sectors.value);
		// Creating a list of sector parent names
		const parentSubs = createSectorParents(subs);

		// Generating subs grouped by parent names
		const getSubsWithParent = (parents) => {
			const allSubs = parents.map((parentName) => {
				// Collect all subs for a given parent name
				const genSubs = subs.filter(
					(sub) => sub.sectorParent.name === parentName,
				);
				return {
					name: parentName,
					parentName: parentName,
					options: genSubs,
				};
			});
			return allSubs;
		};

		// Returning the final result
		return getSubsWithParent(parentSubs);
	}
	// END get sectors

	async function fetchSectors(q: any = {}) {
		// includes: ""
		try {
			const res = await api.fetchSectors(q);
			sectors.value = res.data.data;
		} catch (e) {
			console.log(e, "LCM: Something went wrong while fetching sectors.");
		}
		// return api
		//   .fetchSectors(q)
		//   .then((response) => {
		// 	commit("STORE_SECTORS", response.data);
		//   })
		//   .catch(() =>
		// 	console.log("LCM: Something went wrong while fetching sectors.")
		//   );
	}
	return {
		isMobileToggled,
		isMobileView,
		decodePath,
		encodePath,
		onClickLoginPage,
		goToProfilePage,
		sidebarOpen,
		setupAppInitialColors,
		getGlobalImage,
		initialStatus,
		canUseShiftConfirm,
		shiftConfirmEnablement,
		setInitialStatus,
		systemPermissions,
		allCategories,
		// new
		clients,
		allRoles,
		minRoles,
		getAllClients,
		getExpenseTypes,
		getClientsWithLocations,
		fetchClientsList,
		getAgencyWorkers,
		agency_workers,
		findAgencyWorker,
		fetchTempProfile,
		fetchAllCategoriesList,
		getCategoriesWithSubcategories,
		getAllCategories,
		fetchAllRolesList,
		fetchExpenseTypes,
		fetchMinRoles,
		fetchExpenses,
		getExpenses,
		getExpensesMeta,
		fetchExpense,
		getExpense,
		checkReject,
		checkCutOff,
		checkSignOff,
		getCheckRejectData,
		getCheckCutOffData,
		getCheckSignOffData,
		expenseReject,
		expenseSignOff,
		getExpenseErrors,
		fetchDocuments,
		getDocuments,
		updateDocument,
		getDocumentsPagination,
		deleteDocument,
		insertNewDocument,
		removeNewDocument,
		saveNewDocument,
		getDocumentsErrors,
		resetDocumentsErrors,
		fetchDocumentLocations,
		getDocumentWithLocations,
		fetchDocumentsForApproval,
		setDocuments,
		fetchTempsCompliance,
		getTemps,
		getTempsPagination,
		updateDocumentExpiry,
		approveDocument,
		fetchDocumentRejectionReasons,
		getDocumentRejectionReasons,
		fetchTempDocumentFiles,
		getTempDocumentFiles,
		approveTempDocumentFiles,
		rejectTempDocumentFiles,
		deleteTempDocumentFiles,
		downloadTempsComplianceReport,
		fillRelatedFields,
		fillCategories,
		costs,
		CLEAR_LOCATIONS,
		CLEAR_CATEGORIES,
		fetchLocationsPerClient,
		getCategories,
		getSectors,
		fetchSectors,
	};
});
