type TShiftPriceOverwriteDataMeta = {
	type: string;
	format?: string | null;
	decimal_places?: string | null;
	thousand_separator?: string | null;
	decimal_separator?: string | null;
	symbol?: string | null;
};
type TShiftPriceOverwriteDataValue = {
	key: string;
	value: string | number;
	label?: string | null;
	editable: boolean;
	codeError?: boolean | null;
	order: number;
	style?: string | null;
	meta: TShiftPriceOverwriteDataMeta;
};
type TShiftPriceOverwriteCalculationDataTitle = {
	label?: string | null;
	labelStyle?: string | null;
};
type TShiftPriceOverwriteShiftParts = {
	key: string;
	label?: string | null;
	labelStyle?: string | null;
	placeholder?: string | null;
	order: number;
	value?: string | number | null;
	editable: boolean;
	details: TShiftPriceOverwriteDataValue[];
};
type TShiftPriceOverwriteServiceFee = {
	svf_value: TShiftPriceOverwriteDataValue;
};
type TShiftPriceOverwriteClcCode2 = {
	clc_code2: TShiftPriceOverwriteDataValue;
};
type TShiftPriceOverwriteSPONote = {
	shf_price_overwrite_note: TShiftPriceOverwriteDataValue;
};
type TShiftPriceOverwriteIncentive = {
	INCT: TShiftPriceOverwriteDataValue;
};
type TShiftPriceOverwriteCalculationDataHeaders = [
	{
		order: number;
		label?: string;
		labelStyle?: string;
	},
];
export type TCalculationDataDetailsKeys =
	| "code"
	| "increment"
	| "hours"
	| "base_rate"
	| "pay"
	| "bill"
	| "cvl_can_use_al_accumulation";
export type TShiftPriceOverwriteData = {
	shift_data: {
		shift_date_time: TShiftPriceOverwriteDataValue[];
		shift_break: TShiftPriceOverwriteDataValue[];
		shift_parts: [TShiftPriceOverwriteShiftParts[]];
		service_fee: TShiftPriceOverwriteServiceFee;
		incentive: TShiftPriceOverwriteIncentive;
		clc_code2: TShiftPriceOverwriteClcCode2;
		shf_price_overwrite_note: TShiftPriceOverwriteSPONote;
	};
	calculation_data: [
		{
			meta: {
				can_insert_new_row: boolean;
				can_delete_a_row: boolean;
				default_object_structure: TShiftPriceOverwriteDataValue;
				section_can_be_empty?: boolean | null;
				dropdown: [
					{
						label: string;
						value: string;
						disabled: boolean;
					},
				];
			};
			title: TShiftPriceOverwriteCalculationDataTitle;
			headers: TShiftPriceOverwriteCalculationDataHeaders;
			data: [
				{
					[key: string]: TShiftPriceOverwriteDataValue;
				},
			];
		},
	];
};

interface IRateCalcValueInterface {
	cit_code: string;
	amount: number;
	rate_value: number;
	cvl_can_use_al_accumulation: boolean | number;
}

interface IShiftPartsInterface {
	spt_from: string;
	spt_to: string;
	spt_type: string;
}

export interface IFormatedShiftPriceOverwriteDataInterface {
	shf_start?: string | null;
	shf_end?: string | null;
	shf_break?: number | null;
	svf_value?: number | null;
	rate: IRateCalcValueInterface[];
	shift_parts?: IShiftPartsInterface[] | null;
	[key: string]: any;
}
const SHIFT_PARTS_KEY: string = "shift_parts";
const INCENTIVE: string = "incentive";
const INCENTIVE_CODE: string = "INCT";

const POSSIBLE_CVL_KEYS: string[] = [
	"code",
	"hours",
	"base_rate",
	"cvl_can_use_al_accumulation",
];
const SHIFT_DATA_POSSIBLE_KEYS = new Set([
	"shift_date_time",
	"shift_break",
	"shift_parts",
	"service_fee",
	"incentive",
	"clc_code2",
	"shf_price_overwrite_note",
]);
function shiftPriceOverwriteFormatTimeForBackEnd(time: string): number {
	// time example "8:20"
	const timeArray: number | string[] = time.split(":");
	// hours are always at first place (0)
	const h: number = parseFloat(timeArray[0]) ?? 0;
	// convert minutes to hours
	const min: number = parseFloat(timeArray[1]) / 60;
	return h + min;
}
export function handleFormatingShiftPriceOverwriteParamsForBackend(
	data: TShiftPriceOverwriteData,
): IFormatedShiftPriceOverwriteDataInterface {
	const formatedData: IFormatedShiftPriceOverwriteDataInterface = {
		rate: [],
		shift_parts: [],
	};
	if (data.shift_data) {
		for (const key in data.shift_data) {
			if (SHIFT_DATA_POSSIBLE_KEYS.has(key)) {
				const shiftData: any =
					data.shift_data[key as keyof typeof data.shift_data];
				if (
					key === SHIFT_PARTS_KEY &&
					shiftData.length > 0 &&
					formatedData.shift_parts
				) {
					for (const shiftPartIndex in shiftData) {
						const spt = shiftData[shiftPartIndex];
						if (!spt.spt_to.value && !spt.spt_from.value) continue;
						formatedData.shift_parts.push({
							spt_from: spt.spt_from.value,
							spt_to: spt.spt_to.value,
							spt_type: spt.spt_from.spt_type,
						});
					}
				}
				if (key === INCENTIVE && shiftData) {
					const shiftDataVal = shiftData[INCENTIVE_CODE];
					if ((shiftDataVal && shiftDataVal.value) ?? 0 > 0) {
						formatedData.rate.push({
							cit_code: shiftDataVal.key,
							amount: 1,
							rate_value: shiftDataVal.value,
							cvl_can_use_al_accumulation: 0,
						});
					}
					continue;
				}
				for (const shiftDataKey in shiftData) {
					const shiftDataVal = shiftData[shiftDataKey];
					formatedData[shiftDataVal.key as string] = shiftDataVal.value;
				}
			}
		}
	}
	if (formatedData.shift_parts && formatedData.shift_parts.length === 0) {
		delete formatedData.shift_parts;
	}
	if (data.calculation_data) {
		for (const key in data.calculation_data) {
			const calculationSection = data.calculation_data[key] ?? null;
			const calculationSectionData = calculationSection.data ?? null;
			const calculationSectionMeta = calculationSection.meta ?? null;
			const calculationSectionCanBeEmpty =
				calculationSectionMeta.section_can_be_empty ?? false;
			if (
				!calculationSectionCanBeEmpty &&
				(!calculationSectionData ||
					(calculationSectionData as any[]).length === 0)
			) {
				// return error
				console.error(
					"->->-> calculationSectionCanBeEmpty cannot be empty <-<-<-",
				);
			}
			if (calculationSectionData && calculationSectionData.length > 0) {
				let hasAnyOfPossibleKeys = false;
				for (const cvlDataKey in calculationSectionData) {
					const cvlData = calculationSectionData[cvlDataKey] ?? null;
					let citCode = "";
					let amount = 1;
					let rateValue = 0;
					let cvlCanUseAlAccumulation = 0;
					for (const cvlKey of POSSIBLE_CVL_KEYS) {
						// const cvlEl = cvlData[cvlKey] ?? null;
						const cvlEl = (cvlData as Record<string, any>)[cvlKey] ?? null;
						if (cvlEl) {
							hasAnyOfPossibleKeys = true;
						} else {
							continue;
						}
						const cvlElMeta = cvlEl ? (cvlEl.meta ?? null) : null;
						const cvlElMetaType = cvlElMeta ? (cvlElMeta.type ?? "") : "";
						if (cvlKey === "code") {
							citCode = cvlEl.value ?? "";
						}
						if (cvlKey === "base_rate") {
							rateValue = parseFloat(cvlEl.value) ?? 0;
						}
						if (cvlKey === "cvl_can_use_al_accumulation") {
							cvlCanUseAlAccumulation = cvlEl.value ?? 0;
						}
						if (cvlElMetaType === "time") {
							amount = shiftPriceOverwriteFormatTimeForBackEnd(cvlEl.value);
						}
					}
					if (hasAnyOfPossibleKeys) {
						formatedData.rate.push({
							cit_code: citCode,
							amount: amount,
							rate_value: rateValue,
							cvl_can_use_al_accumulation: cvlCanUseAlAccumulation,
						});
					}
				}
			}
		}
	}
	return formatedData;
}
