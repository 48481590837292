import { defineStore } from "pinia";
import api from "@/api";
import type {
	TFetchTempDocumentsParams,
	TMeta,
	TModalErrorParam,
	TTempDocument,
	TTempDocumentFile,
} from "@/assets/js/globalTypes";
import { parseErrors } from "@/assets/js/parseErrors";
import type { TTableColumn } from "@/components/shared/table/ModernTable.vue";
import { STATUS_COLORS, stringContains } from "@/assets/js/helpers";
import type { TDocumentRejectionReason } from "@/api/types";

export const useTempDocumentsComplianceStore = defineStore(
	"tempDocumentsComplianceStore",
	() => {
		/** errors */
		const errors = ref<TModalErrorParam>(null);
		function getErrors(): TModalErrorParam {
			return errors.value;
		}
		function setErrors(err: TModalErrorParam): void {
			errors.value = err;
		}
		/** errors */
		/** temp documents */
		const tempDocumentParams = ref<TFetchTempDocumentsParams>({
			include: "filesCount",
			tempId: -1,
			page: 1,
		});
		function setTempId(tempId: number | string): void {
			tempDocumentParams.value.tempId = tempId;
		}
		const tempDocumentsAreLoading = ref<boolean>(false);
		function getTempDocumentsAreLoading(): boolean | null {
			return tempDocumentsAreLoading.value;
		}
		function setTempDocumentsAreLoading(loading: boolean | null): boolean {
			tempDocumentsAreLoading.value = loading;
			return true;
		}
		const tempDocuments = ref<TTempDocument[] | null>(null);
		function getTempDocuments(): TTempDocument[] | null {
			return tempDocuments.value;
		}
		function setTempDocuments(data: TTempDocument[] | null): void {
			tempDocuments.value = data;
		}
		const pagination = ref<TMeta | null>(null);
		function getPagination(): TMeta | null {
			return pagination.value;
		}
		function setPagination(p: TMeta | null): boolean {
			pagination.value = p;
			return true;
		}
		function setPage(pageNumber: number) {
			tempDocumentParams.value.page = pageNumber;
			fetchTempDocuments();
		}
		async function fetchTempDocuments(): Promise<void> {
			setTempDocumentsAreLoading(true);
			setTempDocuments(null);
			try {
				const res = await api.fetchTempComplianceDocuments(
					tempDocumentParams.value,
				);
				setTempDocuments(res?.data?.data ?? null);
				setPagination(res?.data?.meta?.pagination ?? null);
				setTempDocumentsAreLoading(false);
			} catch (err) {
				const invalidUserModelSentMsg = String.raw`must be of type App\Models\Temp`;
				const errors = parseErrors(err);
				setErrors(
					stringContains(errors, invalidUserModelSentMsg)
						? "Invalid temp."
						: errors,
				);
				setTempDocumentsAreLoading(false);
			}
		}
		async function updateDocumentExpiry(
			document: TTempDocument,
		): Promise<void> {
			try {
				await api.updateDocumentExpiry(document.id, document?.temp?.id ?? -1, {
					expiry_date: document.expiryDate,
				});
			} catch (err) {
				setErrors(parseErrors(err));
			}
			await fetchTempDocuments();
		}
		async function approveDocument(document: TTempDocument): Promise<void> {
			try {
				await api.approveTempDocumentFiles({
					id: document.id,
					temp: document.temp,
				});
			} catch (err) {
				setErrors(parseErrors(err));
			}
			await fetchTempDocuments();
		}

		/** temp documents */
		/** reject temp document */
		const documentRejectionReasons = ref<TDocumentRejectionReason[] | null>(
			null,
		);
		async function fetchDocumentRejectionReasons(): Promise<void> {
			try {
				const res = await api.fetchDocumentRejectionReasons();
				setDocumentRejectionReasons(res?.data?.data ?? null);
			} catch (err) {
				setErrors(parseErrors(err));
			}
		}
		function getDocumentRejectionReasons(): TDocumentRejectionReason[] | null {
			return documentRejectionReasons.value;
		}
		function setDocumentRejectionReasons(
			reasons: TDocumentRejectionReason[],
		): void | null {
			documentRejectionReasons.value = reasons;
		}
		async function rejectTempDocument(
			doc: TTempDocument,
			rejectionReason: TDocumentRejectionReason,
		): Promise<void> {
			try {
				await api.rejectTempDocumentFiles({
					id: doc.id,
					temp: doc.temp,
					document_rejection_reason_id: rejectionReason.id,
				});
			} catch (err) {
				setErrors(parseErrors(err));
			}
			await fetchTempDocuments();
		}
		/** reject temp document */
		/** temp document files */
		const tempDocumentFiles = ref<TTempDocumentFile[] | null>(null);
		function getTempDocumentFiles(): TTempDocumentFile[] | null {
			if (
				tempDocumentFiles.value &&
				tempDocumentFiles.value.length &&
				tempDocumentFiles.value.length > 0
			) {
				for (const file of tempDocumentFiles.value) {
					file._style = file.isLatest ? { backgroundColor: "#ffff2557" } : {};
				}
			}
			return tempDocumentFiles.value;
		}
		function setTempDocumentFiles(docFiles: TTempDocumentFile[]): void | null {
			tempDocumentFiles.value = docFiles;
		}
		async function fetchTempDocumentFiles(doc: TTempDocument): Promise<void> {
			try {
				setTempDocumentFiles(null);
				const res = await api.fetchTempDocumentFiles(doc);
				setTempDocumentFiles(res?.data?.data?.files ?? null);
			} catch (err) {
				setErrors(parseErrors(err));
			}
		}
		async function deleteTempDocumentFiles(
			doc: TTempDocument,
			docFile: TTempDocumentFile,
		): Promise<void> {
			try {
				await api.deleteTempDocumentFiles({
					id: doc.id,
					temp: doc.temp,
					files: [
						{
							id: docFile.id,
						},
					],
				});
			} catch (err) {
				setErrors(parseErrors(err));
			}
		}
		/** temp document files */
		/** table headers */
		function getTempDocumentsTableHeaderColumns(): TTableColumn[] {
			return [
				{
					id: "name",
					label: "Document",
				},
				{
					id: "expiryDate",
					label: "Expiry date",
				},
				{
					id: "createdAt",
					label: "Submission date",
				},
				{
					id: "uploaded",
					label: "Uploaded",
				},
				{
					id: "status",
					label: "Status",
				},
				{
					id: "actions",
					label: "Action",
				},
				{
					id: "checkFile",
					label: "View",
				},
			];
		}
		/** table headers */
		/** helpers */
		function getDocStatus(status) {
			return STATUS_COLORS.find((s) => s.code === status.code);
		}
		/** helpers */
		return {
			/** errors */
			getErrors,
			setErrors,
			/** errors */
			/** temp documents */
			getTempDocumentsAreLoading,
			setTempId,
			getTempDocuments,
			setTempDocuments,
			getPagination,
			setPagination,
			setPage,
			fetchTempDocuments,
			getTempDocumentsTableHeaderColumns,
			updateDocumentExpiry,
			approveDocument,
			getTempDocumentFiles,
			setTempDocumentFiles,
			fetchTempDocumentFiles,
			deleteTempDocumentFiles,
			/** temp documents */
			/** reject temp document */
			fetchDocumentRejectionReasons,
			getDocumentRejectionReasons,
			rejectTempDocument,
			/** reject temp document */
			/** helpers */
			getDocStatus,
			/** helpers */
		};
	},
);
