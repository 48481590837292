<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors.js";
import apiTs from "@/api/index";
import type { TGenericRecord } from "@/assets/js/globalTypes";
const clientSelected = ref<TGenericRecord>(null);
const clientNotSelectedError = ref<string>("");
const currentProgress = ref<number>(0);
const failedInvoices = ref<any[]>([]);
const showClientWithFailedInvoices = ref<boolean>(false);
const startCreatingInvoices = ref<boolean>(false);
const finishedCreatingInvoicesMessage = ref<string>("");
const finishedCreatingInvoicesErrorMessage = ref<string>("");

const emit = defineEmits(["close-modal", "confirm", "fetch-invoices"]);

const props = withDefaults(
	defineProps<{
		forAllClients: boolean;
		clients: [];
		confirmBtnTxt?: string;
		cancelBtnTxt?: string;
	}>(),
	{
		clients: () => [],
		forAllClients: false,
		confirmBtnTxt: "Confirm",
		cancelBtnTxt: "Cancel",
	},
);
const clientsLength = props.clients.length;

function resetData() {
	failedInvoices.value = [];
	finishedCreatingInvoicesMessage.value = "";
	finishedCreatingInvoicesErrorMessage.value = "";
}

async function createInvoicesForAllClients(clients) {
	resetData();
	startCreatingInvoices.value = true;
	let atLeastOneInvoiceCreated: boolean = false;
	for (const i in clients) {
		try {
			await apiTs.createInvoice(clients[i].id);
			currentProgress.value = ((parseInt(i) + 1) * 100) / clientsLength;
			atLeastOneInvoiceCreated = true;
		} catch (error) {
			currentProgress.value = ((parseInt(i) + 1) * 100) / clientsLength;
			failedInvoices.value.push(clients[i]);
			if (!props.forAllClients) {
				finishedCreatingInvoicesErrorMessage.value = parseErrors(error);
				return;
			}
		}
	}
	// if length is different at least one invoice is created successfully
	const failedInvoicesLength = failedInvoices.value.length;
	const numberOfCreatedInvoices = clientsLength - failedInvoicesLength;
	finishedCreatingInvoicesMessage.value =
		numberOfCreatedInvoices === 1
			? `Invoice for ${numberOfCreatedInvoices} client successfully created.`
			: `Invoices for ${numberOfCreatedInvoices} clients successfully created.`;
	startCreatingInvoices.value = false;
	if (atLeastOneInvoiceCreated) {
		emit("fetch-invoices");
	}
}
function onClickConfirm() {
	if (!props.forAllClients && !clientSelected.value) {
		clientNotSelectedError.value = "Please select a client";
		return;
	}
	const clientsForInvoices = props.forAllClients
		? props.clients
		: [clientSelected.value];
	createInvoicesForAllClients(clientsForInvoices);
}
function onCloseModal() {
	emit("close-modal");
}
function onClientSelect() {
	clientNotSelectedError.value = "";
}
</script>

<template>
	<div class="confirm-modal">
		<div
			v-if="finishedCreatingInvoicesErrorMessage.length === 0"
			class="content"
		>
			<div
				v-if="!forAllClients"
				class="flex-column-center"
			>
				<label class="label">Client</label>
				<multiselect-form
					v-model="clientSelected"
					:options="clients"
					label="name"
					placeholder="Select Hospital"
					value-prop="id"
					:multiple="false"
					:showLabels="true"
					:searchable="true"
					style="width: 300px"
					@select="onClientSelect"
				/>
				<span
					v-if="clientNotSelectedError"
					class="error-text"
				>
					{{ clientNotSelectedError }}
				</span>
			</div>
			<div
				v-else
				class="content"
			>
				<div class="control">
					Do you want to create an invoice for each of the
					{{ clients?.length ? clients.length : 0 }} clients?
				</div>
				<progress-bar
					v-if="startCreatingInvoices"
					:currentProgress="currentProgress"
				></progress-bar>
				<div
					v-if="failedInvoices.length > 0"
					class="control"
				>
					<span class="error-text">
						There are no unprocessed shifts for&nbsp;
					</span>
					<span
						class="span-text"
						@click="
							showClientWithFailedInvoices = !showClientWithFailedInvoices
						"
					>
						{{
							failedInvoices.length === 1
								? `${failedInvoices.length} client`
								: `${failedInvoices.length} clients`
						}}.
					</span>
				</div>

				<table v-if="showClientWithFailedInvoices && failedInvoices.length">
					<thead>
						<tr>
							<th class="first-td"></th>
							<th class="second-td">Client</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(invoice, index) in failedInvoices"
							:key="invoice.id"
						>
							<td class="first-td">{{ index + 1 }}</td>
							<td class="second-td">{{ invoice.name }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				v-if="finishedCreatingInvoicesMessage.length === 0"
				class="wrapp-buttons"
			>
				<button
					class="alt"
					@click="onCloseModal()"
				>
					{{ cancelBtnTxt }}
				</button>
				<button
					class="btn"
					@click="onClickConfirm()"
				>
					{{ confirmBtnTxt }}
				</button>
			</div>

			<div v-else>
				<div class="content">
					{{ finishedCreatingInvoicesMessage }}
				</div>
				<div class="wrapp-buttons">
					<button @click="onCloseModal">Okay</button>
				</div>
			</div>
		</div>
		<div v-else-if="finishedCreatingInvoicesErrorMessage.length > 0">
			<div class="content error-text text-centered">
				{{ finishedCreatingInvoicesErrorMessage }}
			</div>
			<div class="wrapp-buttons">
				<button @click="resetData">Okay</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
$span-text-color: #6591aa;
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 500px;
	min-height: 130px;
	gap: 10px;
	.span-text {
		font-weight: bold;
		color: $span-text-color;
		cursor: pointer;
	}
	button {
		outline: none !important;
	}
	.content {
		margin-top: 25px;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}

	$text-color-lighter: $label-color;
	$table-header-background: #f2f7fa;
	$tr-hover-background: #f5f5f5;
	table {
		width: 380px;
		border-collapse: collapse;
		border: 1px solid #dde6eb;
		display: block;
		padding: 0;
		height: 200px;
		overflow-y: scroll;
		overflow-x: hidden;
		td {
			font-size: 0.9rem;
			padding: 7px 10px;
			border-top: 1px solid #dde6eb;
			color: $blue-grey;
		}
		.first-td {
			width: 30px;
		}
		.second-td {
			width: 350px;
		}

		thead {
			th {
				color: $text-color-lighter;
				background: $table-header-background;
				text-align: left;
				padding: 7px 10px;
				z-index: 1;
				border-top: 1px solid #dde6eb;
			}
		}

		tbody {
			tr {
				height: 25px;
				&:hover {
					background: $tr-hover-background;
				}
			}
		}
	}
}
</style>
