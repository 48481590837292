<script setup lang="ts">
import api from "@/api";
import { parseErrors } from "@/assets/js/parseErrors";
import { useToastStore } from "@/store/toast";

const toastStore = useToastStore();
const emit = defineEmits(["cancel-modal", "rates-updated", "close-modal"]);
const props = defineProps<{
	uptId: string | number;
	uptCode: string;
}>();
const ratesParams = ref<{
	valid_from: string;
	valid_to: string;
	isIndefinite: boolean;
}>({
	valid_from: "",
	valid_to: "",
	isIndefinite: true,
});
const submitClicked = ref<boolean>(false);
const errors = ref<any>(null);
async function ratesUpdated() {
	emit("rates-updated");
}
async function onSubmit() {
	submitClicked.value = true;
	const params = {
		upt_id: props.uptId,
		upt_code: props.uptCode,
		upr_from: ratesParams.value.valid_from,
	};
	try {
		const res = await api.postUserPaymentFinalize(params);
		toastStore.openToastSuccess(
			res?.data?.message ?? "Expense Rates successfully updated",
		);
		await ratesUpdated();
		setTimeout(() => {
			submitClicked.value = false;
			closeModal();
		}, 700);
	} catch (err) {
		errors.value = parseErrors(err);
		if (Array.isArray(errors.value) && errors.value.length > 0) {
			errors.value.map((e) => {
				toastStore.openToastError(e);
			});
		} else {
			toastStore.openToastError(errors.value);
		}
		submitClicked.value = false;
	}
}
function closeModal() {
	emit("close-modal");
}
const activateRatesButtonDisabled = computed(() => {
	return submitClicked.value || ratesParams.value.valid_from.length === 0;
});
</script>

<template>
	<div class="rates-change-value-modal">
		<div class="inner-content">
			<div class="flex-column-center">
				<label>Choose a date to apply the rates from:</label>
				<div class="flex-center mgt-10">
					<div class="flex-column-center">
						<modern-date-picker
							v-model="ratesParams.valid_from"
							:clearable="false"
							:textInput="false"
							:format="'dd/MM/yyyy HH:mm:ss'"
							:enableTimePicker="true"
							:enableSeconds="true"
							:outputFormatString="'YYYY-MM-DD HH:mm:ss'"
							:is24="false"
							style="margin: 0 3px; width: 250px; font-size: 0.85em"
							placeholder="Select Date/Time"
							@open="errors = null"
						/>
						<div v-if="errors && Array.isArray(errors) && errors.length > 0">
							<p
								v-for="(err, idx) in errors"
								:key="idx"
								class="error-text"
							>
								{{ err }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons-centered mgb-20">
			<button
				class="alt button is-small is-outlined is-caps-lock"
				@click.prevent="closeModal"
			>
				Cancel
			</button>
			<button
				class="button is-small is-generic-app-blue is-caps-lock"
				type="submit"
				:disabled="activateRatesButtonDisabled"
				:class="{
					'is-loading': submitClicked,
					'not-allowed': activateRatesButtonDisabled,
				}"
				@click.prevent="onSubmit"
			>
				Save
			</button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.rates-change-value-modal {
	width: 450px;
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 5px 10px;

	.caret-up-rotated {
		transform: rotate(0deg);
	}
	.inner-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 10px;

		.action {
			display: flex;
			overflow: auto;
			gap: 10px;

			.checkbox-complete {
				overflow: auto;
				padding-right: 10px;
			}

			.cats {
				display: flex;
				flex-direction: column;
				gap: 5px;
				flex: 1;
			}

			.input-section {
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}
	}
}

@include fade-transition;
</style>
