<script setup lang="ts">
// Updated 24-02
import { DateInstance } from "@/assets/js/dateHelper";
import { validateInteger } from "@/assets/js/helpers";
import type { ModelValue, TimeModel } from "@vuepic/vue-datepicker";
import VueDatePicker, { type VueDatePickerProps } from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

type TModernDateProps = VueDatePickerProps & {
	inputIconRight?: boolean;
	outputFormatString?: string;
};
const emit = defineEmits(["close"]);
const props = withDefaults(defineProps<TModernDateProps>(), {
	teleport: true, // Maybe not needed
	state: undefined, // Required to be undefined - otherwise breaks validation
	autoPosition: true, // Mimic default
	enableTimePicker: true, // Mimic default
	escClose: true, // Mimic default
	spaceConfirm: true, // Mimic default
	monthChangeOnArrows: true, // Mimic default
	partialRange: true, // Mimic default
	clearable: true, // Mimic default
	is24: true, // Mimic default
	showLastInRange: true, // Mimic default
	inputClassName: "modern-date-input",
	menuClassName: "modern-date-menu",
	enableMinutes: true,
	startTime: () => ({ hours: 12, minutes: 0, seconds: 0 }),

	inputIconRight: true, // PROJECT SPECIFIC
	textInput: true, // PROJECT SPECIFIC
	outputFormatString: "YYYY-MM-DD", // PROJECT SPECIFIC
	locale: "en-IE", // PROJECT SPECIFIC
});
const computedPropsAttrs = computed(() => {
	const customProps: TModernDateProps = {};
	// if (parsedProps.value.teleport) {
	// 	customProps.altPosition = (el?: HTMLElement) => calcAltPosition(el);
	// }

	// PROJECT SPECIFIC

	const combined = Object.assign(customProps, props, attrs, {
		modelValue: model.value,
	});

	if (!("format" in props && props.format)) {
		combined.format = (() => {
			if (props.enableTimePicker) {
				if (props.enableSeconds) {
					return "dd/MM/yyyy HH:mm:ss";
				}
				return "dd/MM/yyyy HH:mm";
			} else {
				return "dd/MM/yyyy";
			}
		})();
	}

	return combined;
});

const model = defineModel<ModelValue>({
	set(value: ModelValue): ModelValue {
		if (computedPropsAttrs.value.outputFormatString) {
			if (!value) {
				return "";
			} else if (value instanceof Date) {
				const stringDate = value.toString();
				return DateInstance.getFormattedDate(
					stringDate,
					computedPropsAttrs.value.outputFormatString,
					false, // So the date doesn't change on timezone changes
				);
			} else {
				if (typeof value === "string") {
					return DateInstance.getFormattedDate(
						value,
						computedPropsAttrs.value.outputFormatString,
						false, // So the date doesn't change on timezone changes
					);
				}
				console.log(":: Not handled", value);
				return value;
			}
		} else {
			return value as Date;
		}
	},
});
const attrs = useAttrs();

const dynamicWrapClasses = computed(() => {
	return {
		"icon-right": computedPropsAttrs.value.inputIconRight,
	};
});

function padNumber(date: number | string) {
	if (typeof date === "string") {
		date = parseInt(date);
	}
	return date < 10 ? `0${date}` : date + "";
}

function setTime(
	event: Event,
	caller: (
		value: number | number[],
		isHours?: boolean,
		isSeconds?: boolean,
	) => void,
	time: TimeModel,
	isHours = true,
	isSeconds = false,
) {
	const value = (event.target as HTMLInputElement).value;
	if (value && !validateInteger(value)) {
		(event.target as HTMLInputElement).value = padNumber(
			isHours ? time.hours : isSeconds ? time.seconds : time.minutes,
		);
		return;
	}
	if (!value) {
		(event.target as HTMLInputElement).value = "00";
		caller(0, isHours, isSeconds);
		return;
	}
	if (isHours) {
		if (parseInt(value) > 23) {
			(event.target as HTMLInputElement).value = "23";
			caller(23, isHours, isSeconds);
			return;
		}
		caller(parseInt(value), true, false);
	} else if (isSeconds) {
		if (parseInt(value) > 59) {
			(event.target as HTMLInputElement).value = "59";
			caller(59, isHours, isSeconds);
			return;
		}
		caller(parseInt(value), false, true);
	} else {
		if (parseInt(value) > 59) {
			(event.target as HTMLInputElement).value = "59";
			caller(59, isHours, isSeconds);
			return;
		}
		caller(parseInt(value), false, false);
	}
}

// for focus
const refHours = ref<any>(null);
const refMinutes = ref<any>(null);
const refSeconds = ref<any>(null);
const refSelect = ref<any>(null);

const dateClicked = () => {
	if (refHours.value) {
		refHours.value.focus();
		refHours.value.select();
	}
	// console.log(`Selected ${date}`);
};
const focusMinutes = () => {
	if (refMinutes.value) {
		refMinutes.value.focus();
		refMinutes.value.select();
	}
	// console.log(`Selected ${date}`);
};
const focusSeconds = () => {
	if (props.enableSeconds) {
		refSeconds.value?.focus();
		refSeconds.value?.select();
		return;
	}
	refSelect.value.click();
};
const openPicker = (e: any) => {
	if (props.timePicker) {
		nextTick(() => {
			if (refHours.value) {
				refHours.value.focus();
				refHours.value.select();
			}
		});
	}
};
const closedPicker = (e: any) => {
	emit("close", e);
};
</script>
<!-- <script lang="ts">
function calcAltPosition(
	el?: HTMLElement,
): ReturnType<NonNullable<VueDatePickerProps["altPosition"]>> {
	// Autoposition doesn't work with this automatically
	if (el) {
		const width = el.offsetWidth;
		const height = el.offsetHeight;
		const popupWidth = getComputedStyle(
			document.documentElement,
		).getPropertyValue("--dp-menu-min-width");
		const rect = el.getBoundingClientRect();
		const left = rect.left + width / 2 - parseFloat(popupWidth) / 2;
		const top = rect.top + height + 10;

		return { top, left };
	}

	return { top: 0, left: 0 };
}
</script> -->

<template>
	<div
		class="modern-date-picker"
		:class="dynamicWrapClasses"
	>
		<vue-date-picker
			v-bind="computedPropsAttrs"
			v-model="model"
			class="native-input native-button"
			:class="{ 'has-value': !!model }"
			@date-update="dateClicked"
			@open="openPicker"
			@closed="closedPicker"
		>
			<template #input-icon>
				<svg
					class="input-icon-custom"
					width="11"
					height="11"
					viewbox="0 0 11 11"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.25072 2.63949C8.25072 2.69999 8.20122 2.74949 8.14072 2.74949H7.37072C7.31022 2.74949 7.26072 2.69999 7.26072 2.63949V1.97949H3.74072V2.63949C3.74072 2.69999 3.69122 2.74949 3.63072 2.74949H2.86072C2.80022 2.74949 2.75072 2.69999 2.75072 2.63949V1.97949H0.990723V3.84949H10.0107V1.97949H8.25072V2.63949Z"
						fill="#1E2548"
						fill-opacity="0.29"
					></path>
					<path
						d="M10.56 0.99H8.25V0.11C8.25 0.0495 8.2005 0 8.14 0H7.37C7.3095 0 7.26 0.0495 7.26 0.11V0.99H3.74V0.11C3.74 0.0495 3.6905 0 3.63 0H2.86C2.7995 0 2.75 0.0495 2.75 0.11V0.99H0.44C0.196625 0.99 0 1.18663 0 1.43V10.56C0 10.8034 0.196625 11 0.44 11H10.56C10.8034 11 11 10.8034 11 10.56V1.43C11 1.18663 10.8034 0.99 10.56 0.99ZM10.01 10.01H0.99V4.785H10.01V10.01ZM10.01 3.85H0.99V1.98H2.75V2.64C2.75 2.7005 2.7995 2.75 2.86 2.75H3.63C3.6905 2.75 3.74 2.7005 3.74 2.64V1.98H7.26V2.64C7.26 2.7005 7.3095 2.75 7.37 2.75H8.14C8.2005 2.75 8.25 2.7005 8.25 2.64V1.98H10.01V3.85Z"
						fill="#292929"
					></path>
				</svg>
			</template>
			<template #time-picker="{ time, updateTime }">
				<div class="__cpl-dp-time-input-wrap">
					<input
						ref="refHours"
						type="number"
						max="23"
						:value="padNumber(time.hours as number)"
						@input="setTime($event, updateTime, time as TimeModel)"
						@click="refHours.select()"
						@keydown.arrow-up="
							setTime(
								{
									target: {
										value: (
											(Array.isArray(time.hours) ? 0 : time.hours) + 1
										).toString(),
									},
								} as unknown as Event,
								updateTime,
								time as TimeModel,
							)
						"
						@keydown.arrow-down="
							setTime(
								{
									target: {
										value: (
											(Array.isArray(time.hours) ? 0 : time.hours) - 1
										).toString(),
									},
								} as unknown as Event,
								updateTime,
								time as TimeModel,
							)
						"
						@keyup.enter="focusMinutes"
					/>
					<span>:</span>
					<input
						ref="refMinutes"
						type="number"
						max="59"
						:value="padNumber(time.minutes as number)"
						@input="setTime($event, updateTime, time as TimeModel, false)"
						@click="refMinutes.select()"
						@keydown.arrow-up="
							setTime(
								{
									target: {
										value: (
											(Array.isArray(time.minutes) ? 0 : time.minutes) + 1
										).toString(),
									},
								} as unknown as Event,
								updateTime,
								time as TimeModel,
								false,
							)
						"
						@keydown.arrow-down="
							setTime(
								{
									target: {
										value: (
											(Array.isArray(time.minutes) ? 0 : time.minutes) - 1
										).toString(),
									},
								} as unknown as Event,
								updateTime,
								time as TimeModel,
								false,
							)
						"
						@keyup.enter="focusSeconds"
					/>
					<template v-if="enableSeconds">
						<span>:</span>
						<input
							ref="refSeconds"
							type="number"
							max="59"
							:value="padNumber(time.seconds as number)"
							@click="refSeconds.select()"
							@input="
								setTime($event, updateTime, time as TimeModel, false, true)
							"
							@keydown.arrow-up="
								setTime(
									{
										target: {
											value: (
												(Array.isArray(time.seconds) ? 0 : time.seconds) + 1
											).toString(),
										},
									} as unknown as Event,
									updateTime,
									time as TimeModel,
									false,
									true,
								)
							"
							@keydown.arrow-down="
								setTime(
									{
										target: {
											value: (
												(Array.isArray(time.seconds) ? 0 : time.seconds) - 1
											).toString(),
										},
									} as unknown as Event,
									updateTime,
									time as TimeModel,
									false,
									true,
								)
							"
							@keyup.enter="refSelect?.click()"
						/>
					</template>
				</div>
			</template>
			<template #time-picker-overlay="{ hours, minutes, setHours, setMinutes }">
				<div class="__cpl-dp-time-overlay-wrap">
					<input
						ref="refHours"
						type="number"
						max="23"
						:value="padNumber(hours as number)"
						@input="setTime($event, setHours, { hours } as TimeModel)"
						@click="refHours.select()"
						@keydown.arrow-up="
							setTime(
								{
									target: {
										value: ((Array.isArray(hours) ? 0 : hours) + 1).toString(),
									},
								} as unknown as Event,
								setHours,
								{ hours } as TimeModel,
							)
						"
						@keydown.arrow-down="
							setTime(
								{
									target: {
										value: ((Array.isArray(hours) ? 0 : hours) - 1).toString(),
									},
								} as unknown as Event,
								setHours,
								{ hours } as TimeModel,
							)
						"
						@keyup.enter="focusMinutes"
					/>
					<span>:</span>
					<input
						ref="refMinutes"
						type="number"
						max="59"
						:value="padNumber(minutes as number)"
						@input="
							setTime($event, setMinutes, { minutes } as TimeModel, false)
						"
						@click="refMinutes.select()"
						@keydown.arrow-up="
							setTime(
								{
									target: {
										value: (
											(Array.isArray(minutes) ? 0 : minutes) + 1
										).toString(),
									},
								} as unknown as Event,
								setMinutes,
								{ minutes } as TimeModel,
								false,
							)
						"
						@keydown.arrow-down="
							setTime(
								{
									target: {
										value: (
											(Array.isArray(minutes) ? 0 : minutes) - 1
										).toString(),
									},
								} as unknown as Event,
								setMinutes,
								{ minutes } as TimeModel,
								false,
							)
						"
						@keyup.enter="focusSeconds"
					/>
				</div>
			</template>
			<template #action-row="{ selectDate, closePicker, internalModelValue }">
				<div class="__cpl-dp-action-row">
					<button @click="closePicker">Close</button>
					<button
						ref="refSelect"
						class="action"
						:disabled="!internalModelValue"
						@click="selectDate"
					>
						Select
					</button>
				</div>
			</template>
		</vue-date-picker>
	</div>
</template>

<style lang="scss" scoped>
$app-accent-color1: $app-accent-color1;

.__cpl-dp-time-input-wrap {
	display: flex;
	justify-content: center;
	gap: 5px;
	margin: 7px 0;
	input {
		display: block;
		border: 1px solid $black;
		max-width: 3.5rem;
		border-radius: 5px;
		text-align: center;
		font-size: 1.2rem;
	}
	input[type="number"]::-webkit-inner-spin-button {
		opacity: 1;
	}
}
.__cpl-dp-time-overlay-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin: auto;
	input {
		display: block;
		border: 1px solid transparent;
		outline: none;
		max-width: 4.5rem;
		border-radius: 5px;
		text-align: center;
		font-size: 2.6rem;
	}
	span {
		font-size: 2.5rem;
	}
	input[type="number"]::-webkit-inner-spin-button {
		opacity: 1;
	}
}
.__cpl-dp-action-row {
	display: flex;
	justify-content: center;
	gap: 10px;
	width: 100%;
	button {
		padding: 5px 10px;
		border-radius: 5px;
		flex-grow: 1;
		&.action {
			background-color: var(--color-primary);
			color: $white;
			&:disabled {
				background-color: var(--color-primary-90);
			}
		}
	}
}
.modern-date-picker {
	display: flex;
	gap: 10px;
	width: 100%;
	height: 100%;

	:deep() {
		.dp__main {
			.dp__btn,
			.dp--time-overlay-btn {
				min-height: 20px;
				// min-width: unset; // Brakes teleport position
				padding: unset;
				background-color: unset;
				box-shadow: unset;
			}

			.dp__action_button {
				&.dp__action_cancel,
				&.dp__action_select {
					min-height: 20px;
					min-width: unset;

					&.dp__action_cancel {
						background-color: unset;
					}
				}
			}

			.dp__input {
				padding: unset;
				padding-left: var(--dp-input-icon-padding);
				text-indent: 0;
				font-size: 0.8rem;
				color: $text-color;
				background-color: $background-color;
			}
			.dp__input_icon {
				inset-inline-start: auto;
				padding: 0 0 0 12px;
			}
		}
	}

	&.icon-right {
		$indent: 12px;

		:deep() {
			.dp__input_icon {
				right: 20px;
				display: flex;
				justify-content: flex-end;
				inset-inline-start: auto;
			}

			.dp__input {
				padding-left: $indent;
			}
			.dp--clear-btn {
				right: 25px;
			}
		}
	}
}
</style>
<style lang="scss">
// Global styles because of teleport
.modern-date-menu {
	// Colors
	.dp__action_select,
	.dp__overlay_cell_active {
		background: $app-accent-color1;

		&:hover {
			background: color.adjust($color: $app-accent-color1, $lightness: 5%);
		}
	}

	.dp__action_cancel {
		&:hover {
			border-color: $app-accent-color1;
		}
	}

	.dp__range_end,
	.dp__range_start,
	.dp__active_date {
		background: $app-accent-color1;
	}

	.dp__today {
		border-color: $app-accent-color1;
	}

	// Layout
	.dp--arrow-btn-nav {
		outline: none !important;
	}
}
</style>
