<script setup lang="ts">
const emit = defineEmits(["close-modal", "confirm", "cancel-action"]);
const props = withDefaults(
	defineProps<{
		text: string;
		data?: any;
		confirmBtnTxt?: string;
		cancelBtnTxt?: string;
		showConfirmBtn?: boolean;
		showCancelBtn?: boolean;
		confirmBtnClass?: string;
		warning?: boolean;
		isError?: boolean;
	}>(),
	{
		data: {},
		confirmBtnTxt: "Confirm",
		cancelBtnTxt: "Cancel",
		showConfirmBtn: true,
		showCancelBtn: true,
		confirmBtnClass: "",
		warning: false,
		isError: false,
	},
);

function onClickConfirm() {
	emit("confirm", props.data);
	emit("close-modal");
}
function onClickReturn() {
	emit("close-modal");
	emit("cancel-action");
}
</script>

<template>
	<div
		class="confirm-modal"
		:class="{ warning: warning }"
	>
		<div class="content">
			<div v-if="warning">
				<img
					src="@/assets/image/warning.svg"
					style="width: 110px"
					alt=""
				/>
				<div class="warningTxt">Warning</div>
			</div>
			<div :class="{ message: true, isError: isError }">
				{{ props.text }}
			</div>
			<div class="wrapp-buttons">
				<button
					v-if="showCancelBtn"
					class="alt"
					@click="onClickReturn()"
				>
					{{ cancelBtnTxt }}
				</button>
				<button
					v-if="showConfirmBtn"
					class="btn"
					:class="confirmBtnClass"
					@click="onClickConfirm()"
				>
					{{ confirmBtnTxt }}
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.confirm-modal {
	padding: 0 60px 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 600px;
	min-height: 130px;
	gap: 10px;
	color: $text-color;

	.content {
		margin-top: 25px;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;

		.warningTxt {
			font-size: 2em;
		}
		.message {
			text-align: center;
			font-size: 1rem;
			// color: $success-color;
			transition: 2s;
		}
		.isError {
			color: $error-color;
		}
	}
	.wrapp-buttons {
		margin-top: 25px;
		display: flex;
		max-width: 100%;
		// flex-direction: column;
		align-items: center;
		justify-content: space-around;
		gap: 10px;
	}
}
.confirm-modal.warning {
	max-width: 400px;
}
</style>
