<script setup lang="ts">
import { parseErrors } from "@/assets/js/parseErrors";
import api from "@/api";
import { useToastStore } from "@/store/toast";

interface IItem {
	id: number | any;
	name: string;
	label: string;
	children?: IItem[];
	archived?: boolean;
}

const emit = defineEmits(["close-modal", "confirm"]);
const props = defineProps<{
	user: any;
}>();

const toast = useToastStore();

// const user = ref(modalUser.value || {});
const isLoadingSectors = ref(false);
const isLoadingCategories = ref(false);
const isSavingRegions = ref(false);
const isSavingCategories = ref(false);
const checkedItemIdsRegions = ref<any[]>([]);
const checkedItemIdsCategories = ref<any[]>([]);
const expandedItemsRegions = ref<any[]>([]);
const expandedItemsCategories = ref<any[]>([]);
const disabledItemsRegions = ref<any[]>([]);
const wholeSectorsData = ref<IItem[]>([]);
const wholeCategoriesData = ref<IItem[]>([]);

const canEditPermPerEntity = computed(() => true); // it may require modification in the future

const transformedListRegions = computed(() => {
	const recFilter = (
		arr: IItem[] = [],
		includeParentNode = true,
		hideEmptyPar = false,
	): IItem[] => {
		const result: IItem[] = [];

		for (const item of arr) {
			if (includeParentNode) {
				result.push({
					...item,
					children: item.children,
				});
			} else if (item.children?.length) {
				const filteredSubs = recFilter(item.children);
				if (filteredSubs.length || (!hideEmptyPar && !filteredSubs.length)) {
					result.push({
						...item,
						children: filteredSubs,
					});
				}
			}
		}

		return result;
	};

	return recFilter(wholeSectorsData.value, true, true);
});

const transformedListSubcategories = computed(() => {
	const recFilter = (
		arr: any[] = [],
		includeParentNode = true,
		hideEmptyPar = false,
	): any[] => {
		const result: any[] = [];

		for (const item of arr) {
			if (includeParentNode) {
				result.push({
					...item,
					children: item.children,
				});
			} else if (item.children?.length) {
				const filteredSubs = recFilter(item.children);
				if (filteredSubs.length || (!hideEmptyPar && !filteredSubs.length)) {
					result.push({
						...item,
						children: filteredSubs,
					});
				}
			}
		}

		return result;
	};

	return recFilter(wholeCategoriesData.value, true, true);
});

// methods

const onClickedItemRegions = (evt: any) => {
	if (evt.item.children?.length) {
		const itemIndex = expandedItemsRegions.value.indexOf(evt.item.id);
		if (itemIndex === -1) {
			expandedItemsRegions.value.push(evt.item.id);
		} else {
			expandedItemsRegions.value.splice(itemIndex, 1);
		}
	} else {
		// onCheckedItemRegions({ item: evt });
		onCheckedItemRegions(evt);
	}
};

const onCheckedItemRegions = (evt: any) => {
	console.log(evt);
	const isItemChecked = checkedItemIdsRegions.value.includes(evt.item.id);

	let childrenIds: string[] = [];
	if (evt.item.children?.length) {
		childrenIds = getAllIdsRec(evt.item.children);
	}
	const isAllChildrensChecked = childrenIds.every((childId) =>
		checkedItemIdsRegions.value.includes(childId),
	);

	const checkItemAndChildren = () => {
		const selSet = new Set(checkedItemIdsRegions.value);
		selSet.add(evt.item.id);
		for (const id of childrenIds) selSet.add(id);
		checkedItemIdsRegions.value = Array.from(selSet);
	};

	const uncheckItemAndChildren = () => {
		checkedItemIdsRegions.value = checkedItemIdsRegions.value.filter(
			(cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem),
		);
	};

	if (evt.checked) {
		checkItemAndChildren();
	} else if (evt.checked === false) {
		uncheckItemAndChildren();
	} else if (isItemChecked) {
		uncheckItemAndChildren();
	} else {
		if (evt.item.children?.length && !isAllChildrensChecked) {
			checkItemAndChildren();
		} else if (!evt.item.children?.length) {
			checkItemAndChildren();
		}
	}
};

const onClickedItemCategories = (evt: any) => {
	if (evt.item.children?.length) {
		const itemIndex = expandedItemsCategories.value.indexOf(evt.item.id);
		if (itemIndex === -1) {
			expandedItemsCategories.value.push(evt.item.id);
		} else {
			expandedItemsCategories.value.splice(itemIndex, 1);
		}
	} else {
		// onCheckedItemCategories({ item: evt });
		onCheckedItemCategories(evt);
	}
};

const onCheckedItemCategories = (evt: any) => {
	const isItemChecked = checkedItemIdsCategories.value.includes(evt.item.id);

	let childrenIds: string[] = [];
	if (evt.item.children?.length) {
		childrenIds = getAllIdsRec(evt.item.children);
	}
	const isAllChildrensChecked = childrenIds.every((childId) =>
		checkedItemIdsCategories.value.includes(childId),
	);

	const checkItemAndChildren = () => {
		const selSet = new Set(checkedItemIdsCategories.value);
		selSet.add(evt.item.id);
		for (const id of childrenIds) selSet.add(id);
		checkedItemIdsCategories.value = Array.from(selSet);
	};

	const uncheckItemAndChildren = () => {
		checkedItemIdsCategories.value = checkedItemIdsCategories.value.filter(
			(cItem) => cItem !== evt.item.id && !childrenIds.includes(cItem),
		);
	};

	if (evt.checked) {
		checkItemAndChildren();
	} else if (evt.checked === false) {
		uncheckItemAndChildren();
	} else if (isItemChecked) {
		uncheckItemAndChildren();
	} else {
		if (evt.item.children?.length && !isAllChildrensChecked) {
			checkItemAndChildren();
		} else if (!evt.item.children?.length) {
			checkItemAndChildren();
		}
	}
};

// const getAllIdsRec = (arr: any[]) => {
// 	return arr.reduce((acc: string[], curr) => {
// 		acc.push(curr.id);
// 		if (curr.children?.length) {
// 			acc.push(...getAllIdsRec(curr.children));
// 		}
// 		return acc;
// 	}, []);
// };
const getAllIdsRec = (arr: any[]): string[] => {
	const ids: string[] = [];

	for (const curr of arr) {
		ids.push(curr.id);

		if (curr.children?.length) {
			const childIds = getAllIdsRec(curr.children);
			for (const childId of childIds) {
				ids.push(childId);
			}
		}
	}

	return ids;
};

const showConfirmRegionsMsg = ref(false);
const onClickSaveRegions = async () => {
	try {
		// await confirmActionAlert("save region entities");
		const keys = {
			sec: "sectors",
			cli: "clients",
			loc: "locations",
		};
		const params = {
			...formatOutputParams(keys, checkedItemIdsRegions.value),
			usr_id: props.user.id,
		};
		isSavingRegions.value = true;
		// const res = await store.dispatch("postUserSecCliLocPerm", params);
		const res = await api.postUserSecCliLocPerm(params);
		emit("confirm", "save-modal-reg");
		toast.openToastSuccess(res.data.message || "Success");
		isSavingRegions.value = false;
		showConfirmRegionsMsg.value = false;
	} catch (err) {
		console.error(err);
		const errs = parseErrors(err);
		toast.openToastError(errs);
		isSavingRegions.value = false;
	}
};

const showConfirmCategoriesMsg = ref(false);
const onClickSaveCategories = async () => {
	try {
		// await confirmActionAlert("save category entities");
		const keys = {
			cat: "categories",
			sub: "subcategories",
		};
		const params = {
			...formatOutputParams(keys, checkedItemIdsCategories.value),
			usr_id: props.user.id,
		};
		isSavingCategories.value = true;
		// const res = await store.dispatch("postUserCatPerm", params);

		const res = await api.postUserCatPerm(params);
		emit("confirm", "save-modal-cat");
		toast.openToastSuccess(res.data.message || "Success");
		isSavingCategories.value = false;
		showConfirmCategoriesMsg.value = false;
	} catch (err) {
		console.error(err);
		const errs = parseErrors(err);
		toast.openToastError(errs);
		isSavingCategories.value = false;
	}
};

// const formatOutputParams = (keys: any, arrayId: string[]) => {
// 	const tempObj: Record<string, { id: number }[]> = {};
// 	Object.entries(keys).forEach(([key, val]) => {
// 		// @ts-ignore
// 		tempObj[val] = arrayId.reduce((arr: { id: number }[], curr) => {
// 			if (curr.includes(key)) {
// 				arr.push({ id: parseInt(curr, 10) });
// 			}
// 			return arr;
// 		}, []);
// 	});
// 	return tempObj;
// };
const formatOutputParams = (
	keys: any,
	arrayId: string[],
): Record<string, { id: number }[]> => {
	const tempObj: Record<string, { id: number }[]> = {};

	for (const key in keys) {
		if (keys.hasOwnProperty(key)) {
			const val = keys[key];
			const arr: { id: number }[] = [];

			for (const curr of arrayId) {
				if (curr.includes(key)) {
					arr.push({ id: parseInt(curr, 10) });
				}
			}

			tempObj[val] = arr;
		}
	}

	return tempObj;
};

const onClickClose = () => {
	emit("close-modal");
};
const fetchSectorsLists = async () => {
	isLoadingSectors.value = true;
	await fetchAdminSectors();
	await fetchUserSectors();
	isLoadingSectors.value = false;
};

const fetchCategoriesLists = async () => {
	isLoadingCategories.value = true;
	await fetchAdminCategories();
	await fetchUserCategories();
	isLoadingCategories.value = false;
};

const fetchUserSectors = async () => {
	const params = { usr_id: props.user.id };
	try {
		// const res = await store.dispatch("getUserSecCliLocPerm", params);
		const res = await api.getUserSecCliLocPerm(params);
		const keys = { clients: "cli", sectors: "sec", locations: "loc" };
		checkedItemIdsRegions.value = formatValuesForLocal(res.data.data, keys);
	} catch (err) {
		console.warn(err.message);
	}
};

const fetchUserCategories = async () => {
	const params = { usr_id: props.user.id };
	try {
		// const res = await store.dispatch("getUserCatPerm", params);
		const res = await api.getUserCatPerm(params);
		const keys = { categories: "cat", subcategories: "sub" };
		checkedItemIdsCategories.value = formatValuesForLocal(res.data.data, keys);
	} catch (err) {
		console.warn(err.message);
	}
};

let AbortControllerSectors: any = null;

const fetchAdminSectors = async () => {
	AbortControllerSectors = new AbortController();

	const params = { include: ["withArchived"].join(",") };
	try {
		// const res = await store.dispatch("getWholeAdminRegions", params);
		const res = await api.getWholeAdminRegions(
			params,
			AbortControllerSectors.signal,
		);
		const keys = [
			{ name: "clients-optimized", short: "sec" },
			{ name: "locations-optimized", short: "cli" },
			{ name: "", short: "loc" },
		];
		wholeSectorsData.value = formatWholeDataRec(keys, res.data.data);
	} catch (err) {
		console.warn(err.message);
	}
};

let AbortControllerCategories: any = null;

const fetchAdminCategories = async () => {
	AbortControllerCategories = new AbortController();
	const params = {};
	try {
		// const res = await store.dispatch("getWholeAdminCategories", params);
		const res = await api.getWholeAdminCategories(
			params,
			AbortControllerCategories.signal,
		);
		const keys = [
			{ name: "subcategories", short: "cat" },
			{ name: "", short: "sub" },
		];
		wholeCategoriesData.value = formatWholeDataRec(keys, res.data.data);
	} catch (err) {
		console.warn(err.message);
	} finally {
	}
};

const formatWholeDataRec = (keysArr: any[], array: any[], depth = 0): any[] => {
	return array.map((item) => {
		const keyEl = keysArr[depth];
		const idAppend = `${item.id}-${keyEl.short}`;
		return keyEl.name in item
			? {
					name: item.name,
					label: item.name,
					id: idAppend,
					children: formatWholeDataRec(keysArr, item[keyEl.name], depth + 1),
					disabled: item.archived,
				}
			: {
					name: item.name,
					id: idAppend,
					disabled: item.archived,
				};
	});
};

const formatValuesForLocal = (apiRes: any, keys: any): string[] => {
	const tempArr: string[] = [];
	for (const [key, val] of Object.entries(keys)) {
		tempArr.push(...apiRes[key].map((el: any) => `${el.id}-${val}`));
	}
	return tempArr;
};

onMounted(() => {
	fetchSectorsLists();
	fetchCategoriesLists();
});
onUnmounted(() => {
	if (AbortControllerCategories) {
		AbortControllerCategories.abort();
	}
	if (AbortControllerSectors) {
		AbortControllerSectors.abort();
	}
});
</script>

<template>
	<div class="manage-regions-modal">
		<div class="header">
			<span>
				Manage regions, client, locations, categories, and subcategories
			</span>
		</div>
		<div class="content">
			<div class="left-side">
				<span>Regions | Clients | Locations</span>
				<div
					v-if="isLoadingSectors"
					class="loading-wrap"
				>
					<img
						class="client-loading"
						src="@/assets/image/loader.svg"
					/>
				</div>
				<div
					v-if="!isLoadingSectors"
					class="chlist"
				>
					<checkbox-list-menu
						:list="transformedListRegions"
						:checked-list="checkedItemIdsRegions"
						:expanded-items="expandedItemsRegions"
						:disabled-items="disabledItemsRegions"
						:is-solo-check-valid="true"
						:is-disabled-list="isSavingRegions"
						:useWholeCheckedList="true"
						:click="onClickedItemRegions"
						:checked="onCheckedItemRegions"
						@click="onClickedItemRegions"
						@checked="onCheckedItemRegions"
					></checkbox-list-menu>
					<div
						v-if="!showConfirmRegionsMsg"
						class="buttons-centered"
					>
						<button
							v-if="canEditPermPerEntity"
							class="button is-small is-generic-app-blue is-caps-lock"
							:class="{ 'is-loading': isSavingRegions }"
							:disabled="isLoadingSectors || isSavingRegions"
							@click.prevent="showConfirmRegionsMsg = true"
						>
							Save
						</button>
					</div>
					<div
						v-else-if="showConfirmRegionsMsg"
						class="confirm"
					>
						<div class="txt">
							Are you sure you want to save region entities?
						</div>
						<div class="wrapp-btn">
							<button
								v-if="canEditPermPerEntity"
								class="button danger is-small is-generic-app-blue is-caps-lock"
								:class="{ 'is-loading': isSavingRegions }"
								:disabled="isLoadingSectors || isSavingRegions"
								@click.prevent="onClickSaveRegions"
							>
								Confirm
							</button>
							<button
								v-if="canEditPermPerEntity"
								class="button alt is-small is-generic-app-blue is-caps-lock"
								:class="{ 'is-loading': isSavingRegions }"
								:disabled="isLoadingSectors || isSavingRegions"
								@click.prevent="showConfirmRegionsMsg = false"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="right-side">
				<span>Categories | Subcategories</span>
				<div
					v-if="isLoadingCategories"
					class="loading-wrap"
				>
					<div class="loading-wrapper">
						<img
							class="client-loading"
							src="@/assets/image/loader.svg"
						/>
					</div>
				</div>
				<div
					v-if="!isLoadingCategories"
					class="chlist"
				>
					<checkbox-list-menu
						:list="transformedListSubcategories"
						:checked-list="checkedItemIdsCategories"
						:expanded-items="expandedItemsCategories"
						:is-solo-check-valid="true"
						:is-disabled-list="isSavingCategories"
						:is-vertical-list="true"
						:display-children-count="false"
						:click="onClickedItemCategories"
						:checked="onCheckedItemCategories"
						@click="onClickedItemCategories"
						@checked="onCheckedItemCategories"
					></checkbox-list-menu>
					<div
						v-if="!showConfirmCategoriesMsg"
						class="buttons-centered"
					>
						<button
							v-if="canEditPermPerEntity"
							class="button is-small is-generic-app-blue is-caps-lock"
							:class="{ 'is-loading': isSavingCategories }"
							:disabled="isLoadingCategories || isSavingCategories"
							@click.prevent="showConfirmCategoriesMsg = true"
						>
							Save
						</button>
					</div>
					<div
						v-else-if="showConfirmCategoriesMsg"
						class="confirm"
					>
						<div class="txt">
							Are you sure you want to save category entities?
						</div>
						<div class="wrapp-btn">
							<button
								v-if="canEditPermPerEntity"
								class="button danger is-small is-generic-app-blue is-caps-lock"
								:class="{ 'is-loading': isSavingCategories }"
								:disabled="isLoadingCategories || isSavingCategories"
								@click.prevent="onClickSaveCategories"
							>
								Confirm
							</button>
							<button
								v-if="canEditPermPerEntity"
								class="button alt is-small is-generic-app-blue is-caps-lock"
								:class="{ 'is-loading': isSavingCategories }"
								:disabled="isLoadingCategories || isSavingCategories"
								@click.prevent="showConfirmCategoriesMsg = false"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer>
			<button
				class="button is-generic-app-blue is-outlined is-low"
				@click="onClickClose"
			>
				Close
			</button>
		</footer>

		<!-- <simplert
			:useRadius="true"
			:useIcon="true"
			ref="simplert"
			key="simplert"
		></simplert> -->
	</div>
</template>
<style lang="scss" scoped>
.manage-regions-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	min-height: 860px;
	height: fit-content;
	width: 750px;

	.content {
		display: flex;
		gap: 30px 40px;
		margin: 20px 0;
		width: 100%;

		.left-side,
		.right-side {
			display: flex;
			flex-direction: column;
			gap: 10px;
			text-align: center;
			// width: 100%;
			// width: 50%;
		}
		.left-side {
			width: 315px;
		}
		.right-side {
			width: 355px;
		}
		.chlist {
			> .checkbox-list-menu {
				overflow: auto;
				// height: 100%;
				height: 60vh;
				padding-right: 4px;
				width: 100%;
				max-width: 100%;
			}
		}
	}
	.loading-wrap {
		display: flex;
		justify-content: center;
	}

	footer {
		display: flex;
		gap: 20px;
		margin: 20px 0 0;
	}
	.buttons-centered,
	.confirm {
		padding-top: 10px;
		height: 60px;
	}
	.wrapp-btn {
		display: flex;
		justify-content: space-around;
	}
}
</style>
